export interface RequiredSkill {
    skillId: number;
    skillName: string;
    referenceDateId: ReferenceDateId;
    referenceDateInfo: string;
    lastModifiedOn: Date;
}

export enum ReferenceDateId {
    OnRegistrationDate = 1,
    OnEventStart = 2,
    OnEventEnd = 3
}



<div class="container">
    <div class="row" *ngIf="rankingModel">
        <div class="col-12 p-2 mb-3 bg-primary d-flex flex-column flex-md-row gap-1">
            <div class="flex-grow-1 m-0">
                <h3 class="text-light">Gruppe {{rankingModel.groupPrefix}}{{rankingModel.groupNumber}}
                </h3>
            </div>
            <div>
                <a class="btn btn-default w-100"
                    *ngIf="tournamentEvent.additionLink && tournamentEvent.additionLinkTitle"
                    [href]="tournamentEvent.additionLink">{{tournamentEvent.additionLinkTitle}}
                </a>
            </div>
            <div>
                <a class="btn btn-default w-100"
                    [routerLink]="'/tournaments/'+tournamentPlanId+'/events/'+eventId+'/games'">{{'Tournaments.LinksToPages.Games' | transloco}}
                </a>
            </div>
            <div>
                <a class="btn btn-default w-100"
                    [routerLink]="'/tournaments/'+tournamentPlanId+'/availableSlots'">{{'Tournaments.LinksToPages.AvailableSlots' | transloco}}
                </a>
            </div>
            <div>
                <a class="btn btn-default w-100"
                    [routerLink]="'/tournaments/'+tournamentPlanId+'/events/'+eventId+'/groups'">{{'Tournaments.LinksToPages.Groups' | transloco}}</a>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="rankingModel">
        <div class="col-12 col-lg-8  mb-3">
            <div class="card border-bottom-0 ">
                <div class="card-header">
                    {{'Tournaments.Public.Ranking.Title' | transloco}}
                </div>
                <div class="card-body p-0">
                    <app-tournament-event-group-ranking-table [rankingModel]="rankingModel"></app-tournament-event-group-ranking-table>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-4">
            <div class="card">
                <div class="card-header">
                    {{'Tournaments.Public.Ranking.Calculation.Title' | transloco}}
                </div>
                <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                        1. {{'Tournaments.Public.Ranking.Calculation.HigherPoints' | transloco}}
                    </li>
                    <li class="list-group-item">
                        2. {{'Tournaments.Public.Ranking.Calculation.DirectEncounter' | transloco}}
                    </li>
                    <li class="list-group-item">
                        3. {{'Tournaments.Public.Ranking.Calculation.PointsRatio' | transloco}}
                    </li>
                    <li class="list-group-item">
                        4. {{'Tournaments.Public.Ranking.Calculation.PointsTotal' | transloco}}
                    </li>

                    <ng-container *ngIf="rankingModel?.hasMultipleSets">
                        <li class="list-group-item">
                            5. {{'Tournaments.Public.Ranking.Calculation.SetRatio' | transloco}}
                        </li>
                        <li class="list-group-item">
                            6. {{'Tournaments.Public.Ranking.Calculation.Lucky' | transloco}}
                        </li>
                    </ng-container>

                    <ng-container *ngIf="!rankingModel?.hasMultipleSets">
                        <li class="list-group-item">
                            5. {{'Tournaments.Public.Ranking.Calculation.Lucky' | transloco}}
                        </li>
                    </ng-container>
                </ul>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="!rankingModel">
        <app-spinner></app-spinner>
    </div>
</div>
<ag-grid-angular
        #agGrid
        style="width: 100%; height:600px;"
        class="ag-theme-alpine"
        [rowData]="applications"
        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [tooltipShowDelay]="0"
        [enableBrowserTooltips]="true"
        [enableCellTextSelection]=true>
</ag-grid-angular>

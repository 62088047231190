<div class="p-3">
    <h1 *ngIf="tournament">{{tournament.name}}</h1>

    <ul class="nav nav-tabs mb-3">
        <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" routerLink="configuration">
                {{"Tournaments.Configuration.Title" | transloco}}
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" routerLink="events">
                {{ "Tournaments.Events.Title" | transloco}}
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" routerLink="planning">
                {{ "Tournaments.PlanningData.Title" | transloco}}
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" routerLink="groups">
                {{ "Tournaments.Groups.Title" | transloco}}
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" routerLink="games">
                {{ "Tournaments.GamePlans.Title" | transloco}}
            </a>
        </li>
    </ul>

    <router-outlet></router-outlet>

</div>
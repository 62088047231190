export enum RegistrationStates {
  None = 0,
  WaitList = 1,
  Temporary = 2, // registration has a reserved seat
  ProcessPayment = 3, // registration has a reserved seat
  Completed = 4, // registration has a seat
  AdditionalPayment = 5,
  Canceled = 6,
  New = 90, // registration has a reserved seat
  Expired = 91,
  Error = 92, // registration has a reserved seat
  Deleted = 93,

  // NOTE: This is an alias Status and is not defined by IM
  //       This could cause problems if IM expands the status list to include this value
  UserData = 99,
  EventoCanceled = 331,
}

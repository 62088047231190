import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss']
})
export class SectionComponent implements OnInit {

  @Input() collapsedSidebar?: boolean
  collapsedItems: boolean = false;
  @Input() title?: string;
  @Input() iconCode?: string;
  @Input() collapseType?: string;

  constructor() { }

  ngOnInit(): void {}
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImpressumComponent } from './impressum/impressum.component';
import { WebAppInfoService } from './web-app-info.service';
import { FaqComponent } from './faq/faq.component';
import { RouterModule } from '@angular/router';
import {routes} from './home.routes';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  declarations: [ImpressumComponent, FaqComponent],
  providers: [WebAppInfoService]
})
export class HomeModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { HandHeldScannerNfcComponent } from '../handheldscanner/hand-held-scanner-nfc/hand-held-scanner-nfc.component';
import { HandHeldScannerQrComponent } from './hand-held-scanner-qr/hand-held-scanner-qr.component';
import { HandHeldScannerTextComponent } from './hand-held-scanner-text/hand-held-scanner-text.component';
import { HandHeldTypeButtonComponent } from './hand-held-type-button/hand-held-type-button.component';

const routes: Routes = [
    { path: '', component: HandHeldTypeButtonComponent, children: [
        { path: 'qr', component: HandHeldScannerQrComponent },
        { path: 'nfc', component: HandHeldScannerNfcComponent },
        { path: 'text', component: HandHeldScannerTextComponent },    
        { 'path': '', redirectTo: 'qr', pathMatch: 'full' }
    ]},
   
];


@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
    ],
    exports: [RouterModule],
})
export class HandheldscannerRoutingModule { }

<div class="container">
<div class="row">
  <div class="col-md-12">  
    <h1 *ngIf="tabId === 0">{{'MyUserData' | transloco}}</h1>
    <h1 *ngIf="tabId === 1">{{'Memberships.Title' | transloco}}</h1>

    <app-pass-photo-required-msg [account]="account!" [messageToken]="'PassportPhotoNeededDigitalCheckIn'" [checkProperty]="'hasPrintCard'"></app-pass-photo-required-msg>

    <ul class="nav nav-tabs">
      <li class="nav-item nav-pills-asvz" role="presentation"><a  routerLinkActive="active" class="nav-link" routerLink="/account">{{'MyUserData' | transloco }}</a></li>
      <li class="nav-item nav-pills-asvz" role="presentation"><a  routerLinkActive="active" class="nav-link" routerLink="/memberships">{{'Memberships.Title' | transloco }}</a></li>
    </ul>
    <div style="padding-top:1rem;">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
</div>

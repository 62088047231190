import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {map, catchError} from 'rxjs/operators';
import { HttpResponseErrorHandlerService } from '../shared/services/http-response-error-handler.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class WebAppInfoService {

  constructor(private httpService: HttpClient,
    private httpResponseErrorHandler: HttpResponseErrorHandlerService) { }

  getWebAppInfos(): Observable<any> {
    return this.httpService.get(`${environment.api}/WebAppInfos`)
      .pipe(
        map((info: any): any => info.data ),
        catchError(e => this.httpResponseErrorHandler.HandleResponseError(e))
      );
  }
}

import {Injectable} from '@angular/core';
import {RegistrationStates} from '../../events/registration/registration-states.enum';
import {StatusStep} from './status-step.class';
import { MembershipState } from '../../memberships/membership-state.enum';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root'
})
export class StatusesService {

  statusSet: StatusStep[] = [];

  constructor(private translocoService: TranslocoService) {
  }


  updateWorkflowStatuses(currentStatus: any): any {
    let activeStep : any;
    activeStep = this.statusSet.find(item => item.status === currentStatus);

    if (activeStep) {

      this.statusSet.forEach(item => {
        item.current = false;
        item.next = false;
        item.completed = false;

        if (item.sort < activeStep.sort) {
          item.completed = true;
        } else if (item.sort > activeStep.sort) {
          item.next = true;
        }
      });

      activeStep.current = true;
    }
  }

  createStatusList(shortList = false) {
    this.clearStatusSet();

    if (!shortList) {
      this.statusSet.push(new StatusStep(RegistrationStates.New, this.translocoService.translate(`Details`), 1));
    }
    const userDataStep = new StatusStep(RegistrationStates.UserData, this.translocoService.translate(`UserData`), 2);
    const summaryStep = new StatusStep(RegistrationStates.Temporary, this.translocoService.translate(`Summary`), 3);
    const checkoutStep = new StatusStep(RegistrationStates.ProcessPayment, this.translocoService.translate(`Checkout`), 4);

    this.statusSet.push(userDataStep, summaryStep, checkoutStep);

    return this.statusSet;
  }

  createStatusListMemberships() {
    this.clearStatusSet();

    const productStep = new StatusStep(MembershipState.None, this.translocoService.translate(`Products`), 1, true);
    const passPhotoStep = new StatusStep(MembershipState.PassPhoto, this.translocoService.translate(`PassportPhoto`), 2);
    const userDataStep = new StatusStep(MembershipState.New, this.translocoService.translate(`UserData`), 3);
    const summaryStep = new StatusStep(MembershipState.Temporary, this.translocoService.translate(`Summary`), 4);
    const checkoutStep = new StatusStep(MembershipState.ProcessPayment, this.translocoService.translate(`Checkout`), 5);

    this.statusSet.push(productStep, passPhotoStep, userDataStep, summaryStep, checkoutStep);

    return this.statusSet;
  }

  clearStatusSet() {
    if (this.statusSet != null) {
      this.statusSet.length = 0;
    }
  }

}

import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { GridApi, ICellRendererParams } from 'ag-grid-community';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { TournamentGameChangeSlotModalComponent } from '../tournament-game-change-slot-modal/tournament-game-change-slot-modal.component';
import { TournamentGameResultModalComponent } from '../tournament-game-result-modal/tournament-game-result-modal.component';
import { TournamentGamePreferenceStatusEnum } from '../enums/tournament-game-preference-status.enum';

@Component({
    templateUrl: './tournament-game-status-ag-grid.component.html',
    styleUrls: []
})
export class TournamentGameStatusAgGridComponent implements ICellRendererAngularComp {

    bsModalRef?: BsModalRef;
    params!: ICellRendererParams;
    value!: string;
    gridApi!: GridApi;
    gridData: any;

    preferenceStatus!: TournamentGamePreferenceStatusEnum | null;
    isSuccessivelyGame!: boolean;

    TournamentGamePreferenceStatusEnum = TournamentGamePreferenceStatusEnum;

    constructor(
    ) { }

    agInit(params: ICellRendererParams): void {
        this.params = params;
        this.value = params.value;
        this.gridApi = params.api;

        this.preferenceStatus = this.params.data.preferenceStatus;
        this.isSuccessivelyGame = this.params.data.isSuccessivelyGame;
    }


    refresh() {
        return false;
    }
}

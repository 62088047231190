import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ApplicationList } from '../models';
import { BaseComponent } from '@premotec/ngx-essentials';
import { MembershipApplicationAdminService } from '../services/membership-application-admin.service';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, timer } from 'rxjs';
import { ColDef } from 'ag-grid-community';
import { AgGridAngular } from 'ag-grid-angular';
import { TranslocoService } from '@ngneat/transloco';
import { AgGridDetailButtonComponent } from '../ag-grid-detail-button/ag-grid-detail-button.component';
import { MembershipApplicationProcessService } from '../services/membership-application-process.service';
import { Editor } from '../models/editor.model';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-ma-list',
  templateUrl: './ma-list.component.html',
  styleUrls: ['./ma-list.component.scss']
})
export class MaListComponent extends BaseComponent implements OnInit {

  applications!: ApplicationList[];
  isLoading = true;

  showDocumentsInPerson!: boolean;
  error = false;
  source = 1;
  editors: Editor[] = [];

  @ViewChild('agGrid') agGrid!: AgGridAngular;
  rowData: any = [];
  columnDefs: ColDef[] = [];
  defaultColDef = {
    sortable: true,
    filter: true,
    floatingFilter: true,
    resizable: true,
  }

  constructor(
    private membershipApplicationAdminService: MembershipApplicationAdminService,
    private activateRoute: ActivatedRoute,
    private translocoService: TranslocoService,
    private membershipApplicationProcessService: MembershipApplicationProcessService,
    private datepipe: DatePipe) {
    super();
  }

  ngOnInit() {
    this.whileImAlive(this.activateRoute.params).subscribe(params => {
      const showDocumentsInPerson = params['showDocumentsInPerson'];
      if (showDocumentsInPerson === 'online') {
        this.showDocumentsInPerson = false;
        this.source = 1;
      } else {
        this.showDocumentsInPerson = true;
        this.source = 2;
      }

      this.activateAutoRefresh();


    });
  }

  activateAutoRefresh() {
    const deltaTime = 60000 // 60'000 ms = 1min
    this.whileImAlive(timer(0, deltaTime)).subscribe(() => this.loadList());
  }

  loadList() {
    this.isLoading = true;
    this.error = false;
    forkJoin({
      membershipApplications: this.membershipApplicationProcessService.getMembershipApplicationsByStatus('VerificationInProcess'),
      editors: this.membershipApplicationAdminService.getEditors()
    }).subscribe({
        next: res => {
          if (res.membershipApplications.length) {
            this.applications = res.membershipApplications.filter(a => a.showDocumentsInPerson === this.showDocumentsInPerson);
            this.applications = this.membershipApplicationProcessService.sortByDateDesc(this.applications);
          }

          this.editors = res.editors;
          this.initGrid();
          this.isLoading = false;
        },
    error: (error) => {
      this.error = true;
      this.isLoading = false;
    }});
  }

  initGrid() {
    this.columnDefs = [
      { field: 'firstName', headerName: this.translocoService.translate('FirstName'), minWidth: 150, },
      { field: 'lastName', headerName: this.translocoService.translate('LastName'), minWidth: 150, },
      { field: 'birthdateString', headerName: this.translocoService.translate('Birthdate'), minWidth: 150, },
      { field: 'city', headerName: this.translocoService.translate('City'), minWidth: 150, },
      { field: 'membershipValidFromDate', headerName: this.translocoService.translate('MembershipValidFromDate'), minWidth: 150,
        valueGetter: (params: any) => this.dateToString(params.data.membershipValidFromDate)
      },
      {
        field: 'actions',
        headerName: '',
        filter: false,
        sortable: false,
        cellRenderer: AgGridDetailButtonComponent,
        cellEditorPopupPosition: 'under',
        tooltipField: "edit",
        minWidth: 75,
        maxWidth: 75,
        pinned: 'left',
        type: 'client'
      },
      { 
        field: 'statusChangeDate', headerName: this.translocoService.translate('MembershipApplication.LastChangeDate'), minWidth: 150,
        valueGetter: (params: any) => this.dateToString(params.data.statusChangeDate)
      },
      {
        field: 'editor', headerName: this.translocoService.translate('MembershipApplication.InProgressBy'), minWidth: 150,
        valueGetter: (params: any) => this.editorFormatter(params),
      },
      {
        field: 'membershipNameDe',
        headerName: this.translocoService.translate('Membership'),
        minWidth: 150,
      },
    ];
  }

  private dateToString(date: any): string {
    if (date == undefined || date == null || date == "Invalid Date") return "";
    return this.datepipe.transform(date, 'dd.MM.yyyy')!;
  }

  editorFormatter(params: any): string {
    const editor = this.membershipApplicationAdminService.getEditorModelByAsvzId(this.editors, params.data.editorAsvzId);
    if (editor != null) {
      return `${editor.firstName} ${editor.lastName} (${editor.asvzId})`;
    }
    return '';
  }
}

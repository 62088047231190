import{Pipe, PipeTransform} from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Pipe({
    name: 'displayRefundRequestStatus'
})
export class DisplayRefundRequestStatus implements PipeTransform{
    constructor(private translocoService: TranslocoService){

        this.refundRequestCreated = this.translocoService.translate('RefundRequestCreated');
        this.refundRequestRequested = this.translocoService.translate('RefundRequestRequested');
        this.refundRequestCompleted = this.translocoService.translate('RefundRequestCompleted');
        this.refundRequestError = this.translocoService.translate('RefundRequestError');
    }
    
    private refundRequestCreated!: string;
    private refundRequestRequested!: string;
    private refundRequestCompleted!: string;
    private refundRequestError!: string;


    transform(value: number | undefined): string {
        switch(value) { 
            case 1: { 
                return this.refundRequestCreated;
            } 
            case 2: { 
                return this.refundRequestRequested;
            } 
            case 3: { 
                return this.refundRequestCompleted;
            } 
            case 4: { 
                return this.refundRequestError;
            } 
            default: { 
               return "";
            } 
         }         
    }
    
}
<button type="button" class="btn btn-default btn-sm" (click)="openModal(modalTemplate)">{{ 'MembershipChangeValidFrom' | transloco }}</button>
<ng-template #modalTemplate>

  <div class="modal-header">
    <h4 class="modal-title float-start">{{ 'Membership_ValidFrom_Update' | transloco }}</h4>
  </div>

  <div class="modal-body">
    <div class="row col-sm-12">
      <div *ngIf="isValidFromOverlappingMembership" class="alert alert-danger">{{ 'MembershipExtension.ValidFromDateOverlapsAnExistingMembership' | transloco }}</div>
      <div *ngIf="isValidNoMembershipStartAfterNMonths" class="alert alert-danger">{{ 'MembershipExtension.ValidNoMembershipStartAfterNMonths' | transloco:translateParam }}</div>
      <div *ngIf="hasUpdateError" class="alert alert-danger">{{ 'GeneralErrorMsg' | transloco }}</div>
    </div>

    <div class="row">
      <div class="col-sm-12">
          <div class="form-group" [ngClass]="{'has-error': dateFormControl.errors && submitted}">
            <label class="control-label" for="validFrom">{{ "ValidFrom" | transloco }}</label>
            <pmt-datepicker [id]="'validFrom'"  [formControl]="dateFormControl"></pmt-datepicker>
            <span *ngIf="dateFormControl.hasError('date-minimum-error') && submitted" id="helpBlock" class="help-block" transloco="MessageValidFromIsInPastNotice"></span>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button class="btn btn-default" type="button" (click)="cancel()" [disabled]="isLoading">{{ "Cancel" | transloco }}</button>
    <button class="btn btn-primary" type="button" (click)="ok()" [disabled]="isLoading">
      <div *ngIf="isLoading" class="lds-dual-ring" style="margin-right:0.25em;"></div>
      <span>{{ "Ok" | transloco }}</span>
    </button>
  </div>
<ng-template>

<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-header">
                <div class="d-flex">
                    <div class="flex-grow-1"><b>{{'Tournaments.PlanningData.Slots' | transloco}}</b></div>
                    <div>
                        <button class="btn btn-success btn-sm ms-2" (click)="openAddSlotsModal()">
                            <i class="fa-solid fa-plus"></i>
                        </button>
                        
                        <button class="btn btn-primary btn-sm ms-2" (click)="openCopySlotsModal()"
                            [disabled]="!selectedSlotIds || !selectedSlotIds.length">
                            Auswahl kopieren <i class="fa-solid fa-copy"></i>
                        </button>

                        <button class="btn btn-danger btn-sm ms-2" (click)="openDeleteModal()"
                            [disabled]="!selectedSlotIds || !selectedSlotIds.length">
                            Auswahl löschen <i class="fa-solid fa-trash"></i>
                        </button>

                    </div>
                </div>
            </div>

            <div class="card-body p-3" *ngIf="!slots">
                <app-spinner></app-spinner>
            </div>

            <div class="alert alert-info m-3" *ngIf="slots && slots.length == 0">
                <p class="m-0" [innerHTML]="'Tournaments.PlanningData.NoSlots' | transloco">
                </p>
            </div>

            <div class="card-body" *ngIf="slots && slots.length" style="overflow-x: hidden;">
                <div class="calender pb-1">
                    <div *ngFor="let slot of slots" class="me-2 calender-day">
                        <app-tournament-planning-slots-calender [viewDate]="slot.day"
                            [selectedSlotIds]="selectedSlotIds" [rooms]="slot.rooms" [minHour]="minHour"
                            [maxHour]="maxHour" [roomOverviews]="rooms"></app-tournament-planning-slots-calender>
                    </div>
                </div>

                <div class="card d-inline-block mt-2">
                    <div class="card-header">
                        <div class="d-flex">
                            <div class="flex-grow-1 fw-bold">
                                {{'Tournaments.PlanningData.Rooms' | transloco}}
                            </div>

                            <div class="ps-3 fw-bold">
                                {{getRoomSlotsSum()}}
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="d-flex" *ngFor="let room of rooms">
                            <div class="pe-1" [ngStyle]="{'color': room.color}">
                                <i class="fa-solid fa-house"></i>
                            </div>
                            <div class="flex-grow-1">
                                {{room.imFacilityName}} - {{room.imRoomName}}
                            </div>

                            <div class="ps-3 fw-bold">
                                {{room.slotCount}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
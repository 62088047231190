import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de-CH';
import { Injectable, OnDestroy } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subject, takeUntil } from 'rxjs';
import * as XLSX from 'xlsx';

import { ExcelConfiguration } from '../models/excel-configuration.model';
import { LoadingModalComponent } from '../loading-modal/loading-modal.component';
import { ColumnDefinition } from '../models/column-definitions.model';

@Injectable({
  providedIn: 'root',
})
export class ExcelExportService implements OnDestroy {
  private excelTitle = "";
  private readonly destroyed$ = new Subject<void>();
  constructor(
    private modalService: BsModalService,
    private translateService: TranslocoService
  ) {
    registerLocaleData(localeDe);
  }
  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  /** Generates an Excel with specified sheetName, rowData and some configurations
   * @param agGrid - The AgGrid itself (`AgGridAngular`)
   * @param sheetName - Speficy the name for the excel and its sheet
   * @param rowData - The values to render inside of the sheet
   * @param configuration - Some configurations like `filterFor` to speficy which rows to exclude
   */
  generateExcel<T>(
    agGrid: AgGridAngular,
    sheetName: string,
    rowData: T[],
    configuration: ExcelConfiguration
  ) {
    if (agGrid?.columnDefs) {
      // Get needed translations
      this.getTranslations();

      // Get headersAndFields from ColDef to be able for filtering
      const headersAndFields = this.getHeaderNamesAndFields(
        agGrid.columnDefs,
        configuration
      );

      // Prepare Data for Excel generating
      const preparedData: unknown[][] = this.prepareDataForExcelExport<T>(
        headersAndFields,
        rowData
      );

      // Generate Excel
      this.createExcel(preparedData, sheetName);
    }
  }
  private getTranslations(): void {
    this.translateService.selectTranslate('Shared.ExcelExport').pipe(takeUntil(this.destroyed$)).subscribe((i18n) => {
      this.excelTitle = i18n;
    })
  }

  /** Retrieve the `headerName` and `field` from colDef
   * @param colDefs --> `this.agGrid?.columnDefs`
   * @param configuration some configurations like `filterFor` to speficy which rows to exclude
   * @returns Object array `{ "headerName": "value", "field": "value" }`
   */
  getHeaderNamesAndFields(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    colDefs: ColDef<any>[],
    configuration: ExcelConfiguration
  ): ColumnDefinition[] {
    const array: ColumnDefinition[] = [];

    colDefs.forEach((colDef) => {
      if (colDef.field != null && colDef.headerName != null) {
        if (!configuration.filterFor.includes(colDef.field)) {
          array.push({
            headerName: colDef.headerName,
            field: colDef.field,
          });
        }
      }
    });

    return array;
  }

  /** Creates a 2D array with headers from colDef and values from rowData
   * @param colDef - Object Array from `getHeaderNamesAndFields()`
   * @param rowData - The values itself
   */
  private prepareDataForExcelExport<T>(
    colDef: ColumnDefinition[],
    rowData: T[]
  ): unknown[][] {
    const array: unknown[][] = [];

    // Add header row
    array.push(colDef.map((colDef) => colDef?.headerName));

    // Add data rows with loop trough the data itself
    rowData.forEach((data: T) => {
      const dataRow: unknown[] = [];

      // Then loop trough the colDef to split the nested properties of 'field'
      colDef.forEach((colDef) => {
        const props = colDef.field?.split('.');

        // Then loop trough the properties to map the nested property
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let value: any = data;
        props?.forEach((prop) => {
          if (value[prop]) {
            value = value[prop];
          } else if (typeof value[prop] == 'number') {
            value = 0;
          }
        });

        // Push to array per rowData
        dataRow.push(value);
      });

      // Push values to complete array
      array.push(dataRow);
    });

    return array;
  }

  /** Generate an .xlsx sheet with the specified name and the specified data/headers.
   * @param worksheetData - the prepared data array from `prepareDataForExcelExport()`
   * @param fileName - The file- and sheetname
   */
  private createExcel(worksheetData: unknown[][], fileName: string): void {
    const loadingModal = this.modalService.show(LoadingModalComponent, {
      backdrop: false,
      keyboard: false,
      initialState: {
        title: this.excelTitle
      },
    });

    const keys = worksheetData[0];

    // Create a new workbook and worksheet with the values from data
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    const worksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(worksheetData);

    // Get headerCells ([A1, B1, ..]) with ASCII
    const headerCells = [];
    for (let i = 65; i < 65 + keys.length; i++) {
      headerCells.push(String.fromCharCode(i) + '1');
    }

    // Set filters
    worksheet['!autofilter'] = {
      ref: headerCells[0] + ':' + headerCells[headerCells.length - 1],
    };

    // Set headerNames
    headerCells.forEach((headerCell, index) => {
      worksheet[headerCell] = {
        t: 's',
        v: keys[index],
        s: { font: { bold: true } },
      };
    });

    // Set headerWidths
    this.setWidthByMaxCharacterLength(worksheet, worksheetData);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, this.translateService.translate('Tournaments.GamePlans.Title'));

    // Generate the Excel file
    XLSX.writeFile(workbook, `${fileName}.xlsx`);

    setTimeout(() => loadingModal.hide(), 2000);
  }

  /** 1.) Summarize an Array of objects to get the max character length per property.
   * 2.) And append the calculated values to the specified worksheet cols
   *
   * @param worksheet - The Worksheet itself
   * @param array - The object Array for calculating
   */
  private setWidthByMaxCharacterLength(
    worksheet: XLSX.WorkSheet,
    array: unknown[][]
  ): void {
    // Define an empty object to store the maximum length of each summarized property
    const maxLengths: { [key: string]: number } = {};

    array.forEach((obj) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Object.entries(obj).forEach(([key, value]: [string, any]) => {
        // Update the maxLengths object with the character-length of the property key or value
        maxLengths[key] = Math.max(
          maxLengths[key] || 0,
          value.toString().length,
          key?.toString().length
        );
      });
    });

    // Return an array with the calculated characterLength * 1.15 or 10 if the calculated value is smaller
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    worksheet['!cols'] = Object.entries(maxLengths).map(
      ([key, value]: [string, number]) =>
        value ? { wch: Math.max(value * 1.15, 10) } : { wch: 10 }
    );
  }
}
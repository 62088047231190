import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { take } from 'rxjs';

@Component({
  selector: 'app-delete-button',
  templateUrl: './delete-button.component.html',
  styleUrls: ['./delete-button.component.scss']
})
export class DeleteButtonComponent implements OnInit {

  @Input() size?: string;
  @Input() disabled = false;
  @Input() showIcon = true;
  @Input() showText = false;

  @Output() confirmed: EventEmitter<void> = new EventEmitter();

  constructor(private modalService: BsModalService,
    private translocoService: TranslocoService) { }

  ngOnInit(): void {
  }

  showModal() {

    const initialstate: ModalOptions = {
      initialState: {
        body: this.translocoService.translate('ConfirmAction'),
      }
    }

    const bsModalRef = this.modalService.show(ConfirmationModalComponent, initialstate);

    bsModalRef.content!.confirmed.pipe(take(1)).subscribe(() => {
      this.confirmed.next();
    })
  }

}

import {Injectable} from '@angular/core';
import { RequiredSkill, ReferenceDateId } from '../../events/required-skill.model';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
  })
export class PrerequisitesService {

    referenceDateId = ReferenceDateId;
    constructor() {}

    getMissingSkills(requiredSkills: RequiredSkill[], userSkills: any[], registerFrom: Date, eventStart: Date, eventEnd: Date): RequiredSkill[] {
        const missingSkills : any = [];
        requiredSkills.forEach(skill => {

            if (userSkills) {

                console.log('RequiredSkills: ', requiredSkills);
                console.log('UserSkills: ', userSkills);

                const currentUserSkills = userSkills.filter(userSkill => userSkill.skillId === skill.skillId);

                console.log(currentUserSkills);

                if (currentUserSkills) {

                    const isValid = currentUserSkills.some((currentUserSkill) => {
                        if (skill.referenceDateId === this.referenceDateId.OnRegistrationDate) {
                            return moment()
                                .isBetween(currentUserSkill.validFrom, currentUserSkill.validTo, 'day', '[]');
                        } else if (skill.referenceDateId === this.referenceDateId.OnEventStart) {
                            console.log('check-skill', skill.skillId)
                            return moment(eventStart)
                                .isBetween(currentUserSkill.validFrom, currentUserSkill.validTo, 'day', '[]');
                        } else if (skill.referenceDateId === this.referenceDateId.OnEventEnd) {
                            return moment(eventEnd)
                                .isBetween(currentUserSkill.validFrom, currentUserSkill.validTo, 'day', '[]');
                        }

                        return false;
                    });

                    if (!isValid) {
                        missingSkills.push(skill);
                    }

                } else {
                    missingSkills.push(skill);
                }
            } else {
                missingSkills.push(skill);
            }
        });
        return missingSkills;
    }
}

<h2>Sportfahrplan</h2>
<table class="table sports-schedules">
  <tbody>
    <tr *ngFor="let eventLesson of sportsSchedules.eventsAndLessons">
      <td style="width: 1px; white-space: nowrap;">
        <span class="font-normal">{{eventLesson.fromDateTime | dateFormat: "HH:mm"}}</span><br>
        <span class="font-small">bis {{eventLesson.toDateTime | dateFormat: "HH:mm"}}</span>
      </td>
      <td class="limited">
        <span class="font-big">{{ eventLesson.sportName }}</span><br>
        <span class="font-small">{{ eventLesson.title }}</span>
      </td>
      <td  class="limited">
        <div *ngIf="!eventLesson.cancelled">
          <span class="font-normal">{{eventLesson.room}}</span><br>
        </div>
        <div class="cancelled-container" *ngIf="eventLesson.cancelled">
            <svg class="cancelled-icon" style="fill:red;" viewBox="0 0 26 26">
              <g>
                <path
                  d="M 25.289063 20.132813 L 15.234375 2.71875 C 14.003906 0.589844 11.996094 0.589844 10.765625 2.71875 L 0.710938 20.132813 C -0.519531 22.261719 0.488281 24 2.945313 24 L 23.054688 24 C 25.511719 24 26.515625 22.261719 25.289063 20.132813 Z M 11.617188 7.660156 C 11.980469 7.269531 12.4375 7.074219 13 7.074219 C 13.5625 7.074219 14.019531 7.265625 14.382813 7.652344 C 14.738281 8.035156 14.921875 8.519531 14.921875 9.101563 C 14.921875 9.597656 14.171875 13.269531 13.921875 15.9375 L 12.113281 15.9375 C 11.894531 13.269531 11.078125 9.597656 11.078125 9.101563 C 11.078125 8.527344 11.261719 8.046875 11.617188 7.660156 Z M 14.355469 20.355469 C 13.976563 20.722656 13.523438 20.910156 13 20.910156 C 12.476563 20.910156 12.023438 20.722656 11.644531 20.355469 C 11.265625 19.984375 11.078125 19.539063 11.078125 19.011719 C 11.078125 18.488281 11.265625 18.035156 11.644531 17.65625 C 12.023438 17.277344 12.476563 17.089844 13 17.089844 C 13.523438 17.089844 13.976563 17.277344 14.355469 17.65625 C 14.734375 18.035156 14.921875 18.488281 14.921875 19.011719 C 14.921875 19.539063 14.734375 19.984375 14.355469 20.355469 Z ">
                </path>
              </g>
            </svg>
          <span class="cancelled-text">
            Abgesagt
          </span>
        </div>

      </td>
      <td>
        <div *ngIf="!eventLesson.cancelled">
          <span class="font-small" *ngFor="let instructor of eventLesson.instructors; let isLast=last">
            {{instructor}}{{isLast ? '' : ', '}}
          </span>
        </div>
      </td>
    </tr>
  </tbody>
</table>
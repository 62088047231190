import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MembershipApplicationsRoutingModule } from './membership-applications-routing.module';
import { MaOverviewComponent } from './ma-overview/ma-overview.component';
import { MaDetailsComponent } from './ma-details/ma-details.component';
import { MaBasicDataViewComponent } from './ma-basic-data-view/ma-basic-data-view.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MaImageCropperComponent } from './ma-image-cropper/ma-image-cropper.component';
import { MaListComponent } from './ma-list/ma-list.component';
import { MaFilterListComponent } from './ma-filter-list/ma-filter-list.component';
import { MaFileDisplayComponent } from './ma-file-display/ma-file-display.component';
import { MaFileListComponent } from './ma-file-list/ma-file-list.component';
import { IframeModalComponent } from './iframe-modal/iframe-modal.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ChangeMembershipModalComponent } from './ma-basic-data-view/change-membership-modal/change-membership-modal.component';
import { MembershipApplicationAdminService } from './services/membership-application-admin.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { TranslocoService } from '@ngneat/transloco';
import { NgxDatepickerModule } from '@premotec/ngx-datepicker';
import { AgGridModule } from 'ag-grid-angular';
import { AgGridDetailButtonComponent } from './ag-grid-detail-button/ag-grid-detail-button.component';
import { MaEditorSelectComponent } from './ma-editor-select/ma-editor-select.component';
import { MaEditorSelectModalComponent } from './ma-editor-select-modal/ma-editor-select-modal.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { MembershipApplicationProcessService } from './services/membership-application-process.service';
import { EditorDisplayNamePipe } from './pipes/editor-display-name.pipe';

@NgModule({
  imports: [
    CommonModule,
    MembershipApplicationsRoutingModule,
    ImageCropperModule,
    SharedModule,
    ReactiveFormsModule,
    NgxDatepickerModule,
    AgGridModule,
    NgSelectModule
  ],
  declarations: [
    MaOverviewComponent,
    MaDetailsComponent,
    MaBasicDataViewComponent,
    MaImageCropperComponent,
    MaListComponent,
    MaFilterListComponent,
    MaFileDisplayComponent,
    MaFileListComponent,
    IframeModalComponent,
    ChangeMembershipModalComponent,
    AgGridDetailButtonComponent,
    MaEditorSelectComponent,
    MaEditorSelectModalComponent,
    EditorDisplayNamePipe
  ],
  entryComponents: [
    IframeModalComponent
  ],
  providers: [
    MembershipApplicationAdminService,
    MembershipApplicationProcessService
  ]
})
export class MembershipApplicationsModule {
  constructor () {
  }
 }

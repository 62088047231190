<div class="border p-3 bg-light">
    <div>
        <b>{{tournamentDisplayService.getTimeSlotName(game.gameFrom, game.gameTo)}}</b>
    </div>
    <div>
        {{tournamentDisplayService.getLocationName(game.gameFacilityName, game.gameRoomName,
        game.gameField,true)}}
    </div>
</div>
<ng-container *ngIf="showResults">

    <div class="border py-3">
        <div class="d-flex">
            <div class="w-50 text-center">
                <b>{{game.homeTeamName}}</b>
            </div>
            <div class="w-50 text-center">
                <b>{{game.guestTeamName}}</b>
            </div>
        </div>

        <div *ngFor="let gameSet of game.sets ; let i=index" class="px-3">
            <div class="text-center mt-1" *ngIf="game.sets.length > 1">
                <label>Satz {{i+1}}</label>
            </div>
            <div class="d-flex mt-1">
                <div class="flex-grow-1">
                    <input class="form-control text-end" [value]="gameSet.homePoints ?? ''" [disabled]="true">
                </div>
                <div class="px-3">
                    :
                </div>
                <div class="flex-grow-1">
                    <input class="form-control" [value]="gameSet.guestPoints ?? ''" [disabled]="true">
                </div>
            </div>
        </div>
        <div class="px-3 mt-3" *ngIf="!game.isResultSet && allowCaptainEntry">
            <button class="btn btn-primary w-100" (click)="openResultModal(game.gameId)"
                [disabled]="!isDateTodayOrPast(game.gameFrom)">
                {{'Tournaments.Teams.TeamGames.EnterResult' | transloco}}
            </button>
        </div>
    </div>
</ng-container>
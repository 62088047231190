import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Title } from '@angular/platform-browser';
import { TranslocoService } from '@ngneat/transloco';
import { EnrollmentList } from '../../models/enrollment-list.model';
import { EnrollmentsService } from '../../services/enrollments.service';

@Component({
  selector: 'app-my-lessons-overview',
  templateUrl: './my-lessons-overview.component.html',
  styleUrls: ['./my-lessons-overview.component.scss']
})
export class MyLessonsOverviewComponent implements OnInit {
  myEnrollments!: Array<EnrollmentList>;
  dateToday: Date = new Date();
  noEntries = false;
  title!: string;
  constructor(private enrollmentsService: EnrollmentsService,
    private titleService: Title,
    private translocoService: TranslocoService) { }

  ngOnInit() {
    // set window title

    this.translocoService.selectTranslate('MyEnrollments').subscribe(x => {
      this.titleService.setTitle(x);
    });

    this.enrollmentsService.getAllEnrollments().subscribe((enrollments: Array<EnrollmentList>) => {
      this.myEnrollments = enrollments.filter(enrollment => {
        return enrollment.status === 4 &&
          moment(enrollment.lessonEnd).isSameOrAfter(moment(), 'day')
      });
      if (this.myEnrollments.length === 0) {
        this.noEntries = true;
      }
    });
  }
}

import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import { InvoiceType } from '../invoice-type.model';
import { InvoiceTypes } from '../invoice-types.enum';
import {InvoiceService} from '../invoice.service';
import {PspInstruction} from '../psp-instruction.model';

@Component({
  selector: 'app-payment-redirection',
  templateUrl: './payment-redirection.component.html',
  styleUrls: ['./payment-redirection.component.scss']
})
export class PaymentRedirectionComponent implements OnInit {
  pspInstruction!: PspInstruction;
  parameters: any;
  invoiceType!: InvoiceType;

  invoiceRegistrationType: InvoiceTypes = InvoiceTypes.Registration;
  invoiceTypes = InvoiceTypes;

  hasError = false;

  constructor(private activatedRoute: ActivatedRoute,
              private invoiceService: InvoiceService) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      const invoiceId: number = params['invoiceId'];
      this.loadInvoiceType(invoiceId);
      this.loadPspInstructions(invoiceId);
    });
  }

  private loadPspInstructions(invoiceId: number) {
    this.invoiceService.getPspInstructionByInvoiceId(invoiceId).subscribe((pspInstruction: PspInstruction) => {
      this.pspInstruction = pspInstruction;

      if (this.pspInstruction !== undefined) {
        this.parameters = this.generateArray(this.pspInstruction.parameters);
      }

      this.redirectionStart();
    },()=> {
      this.hasError = true;
    });
  }

  private loadInvoiceType(invoiceId: number) {
    this.invoiceService.getInvoiceType(invoiceId).subscribe((invoiceType: InvoiceType) => {
      this.invoiceType = invoiceType;
    });
  }

  private redirectionStart() {
    // automatically click on the form-button after 2 seconds in order to redirect the user to the PSP page
    window.setTimeout(() => document.getElementById('btn-submit')?.click(), 2000);
  }

  private generateArray(obj: any) {
    return Object.keys(obj).map((key) => {
      return {key: key.toUpperCase(), value: obj[key]}
    });
  }
}

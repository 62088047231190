import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import * as moment from 'moment-timezone';
import { NavigationModule } from './navigation/navigation.module';
import { HttpClientModule } from '@angular/common/http';
import { TranslocoRootModule } from './transloco/transloco-root.module';
import { SecurityModule } from './security/security.module';
import { CheckAppVersionChangeService } from './config/check-app-version-change.service';
import { ReactiveFormsModule } from '@angular/forms';
import { AccountModule } from './account/account.module';
import { EventsModule } from './events/events.module';
import { RegistrationsModule } from './registrations/registrations.module';
import { WaitingListModule } from './waiting-list/waiting-list.module';
import { InformationScreenModule } from './information-screen/information-screen.module';
import { PaymentModule } from './payment/payment.module';
import { LessonsModule } from './lessons/lessons.module';
import { HomeModule } from './home/home.module';
import { ReloadModalComponent } from './config/reload-modal/reload-modal.component';
import { CoreModule } from './core/core.module';
import { registerLocaleData } from '@angular/common';
import localeDECH from '@angular/common/locales/de-CH';
import localeDeExtra from '@angular/common/locales/extra/de';
import { ConfirmationModalComponent } from './shared/confirmation-modal/confirmation-modal.component';
import { NgxDatepickerModule } from '@premotec/ngx-datepicker';
import { AgGridModule } from 'ag-grid-angular';
import { RequestRateModalComponent } from './shared/request-rate/request-rate-modal/request-rate-modal.component';
import { AnnouncmentModalComponent } from './office/facilities/components/announcements/announcement-modal/announcement-modal.component';
registerLocaleData(localeDECH, 'de-DE', localeDeExtra);

@NgModule({
  declarations: [
    AppComponent,
    ReloadModalComponent,
    RequestRateModalComponent,
    ConfirmationModalComponent,
    AnnouncmentModalComponent
  ],
  imports: [
    WaitingListModule,
    PaymentModule,
    HomeModule,
    AccountModule,
    AppRoutingModule,
    InformationScreenModule,
    SharedModule,
    CoreModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgxDatepickerModule,
    NavigationModule,
    SecurityModule.forRoot(),
    TranslocoRootModule,
    BsDropdownModule.forRoot(),
    AgGridModule,
  ],
  providers: [
    CheckAppVersionChangeService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    moment.tz.setDefault('Europe/Zurich');
  }
}
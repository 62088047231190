<div *ngIf="data">
    <ul>
        <li>participants: {{participants()}}</li>
        <li>Enrolled: {{enrolled()}}</li>
        <li>No Seat: {{noSeat()}}</li>
        <li>Error: {{error()}}</li>

    </ul>
</div>


<div class="m-3">
    <ag-grid-angular #agGrid style="width: 100%; height:600px;" class="ag-theme-alpine" [rowData]="data"
    [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" (gridReady)="onGridReady($event)"
    [tooltipShowDelay]="0" [enableBrowserTooltips]="true" [enableCellTextSelection]=true>
</ag-grid-angular>
</div>

import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { Maintenance } from '../../maintenance.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MaintenanceService } from '../../maintenance.service';

import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { TranslocoService } from '@ngneat/transloco';


@Component({
  selector: 'app-maintenance-detail',
  templateUrl: './maintenance-detail.component.html',
  styleUrls: ['./maintenance-detail.component.scss']
})
export class MaintenanceDetailComponent implements OnInit, AfterViewInit {
  @Input()
  maintenance!: Maintenance | null;

  maintenanceForm!: FormGroup;


  constructor(private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private maintenanceService: MaintenanceService,
    private router: Router,
    private toastrService: ToastrService,
    private translocoService: TranslocoService
  ) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.initFormGroup();
      const maintenanceId: string = params['maintenanceId'];
      if (!this.isNew(maintenanceId)) {
        this.maintenanceService.getMaintenanceById(maintenanceId).subscribe((maintenance: Maintenance) => {
          this.maintenance = maintenance;

          this.maintenanceForm.patchValue({
            'id': this.maintenance.id,
            'publicationDate': moment(this.maintenance.publicationDate).toDate(),
            'startDate': moment(this.maintenance.startDate).toDate(),
            'endDate': moment(this.maintenance.endDate).toDate(),
            'startTime': moment(this.maintenance.startDate).format('HH:mm'),
            'endTime': moment(this.maintenance.endDate).format('HH:mm'),
            'active': this.maintenance.active,
            'body': this.maintenance.body
          });
        });
      } else {
        this.maintenanceForm.reset();
        this.maintenance = null;

        this.maintenanceForm.patchValue({
          'id': null,
          'publicationDate': moment().toDate(),
          'startDate': moment().toDate(),
          'endDate': moment().toDate(),
          'startTime': '00:00',
          'endTime': '00:00',
          'active': true,
          'body': ''
        });
      }

    });
  }

  ngAfterViewInit(): void {
    this.initFormGroup();
  }

  // Either saving or updating a maintenance
  save(maintenanceForm: FormGroup) {
    // Preparing for api Merging time with date
    const startDate = this.mergingTimeAndDate(maintenanceForm.get('startDate')?.value, maintenanceForm.get('startTime')?.value);
    const endDate = this.mergingTimeAndDate(maintenanceForm.get('endDate')?.value, maintenanceForm.get('endTime')?.value);

    // can happen when given time is invalid
    if (startDate === null || endDate === null) {
      return;
    }

    this.maintenanceForm.patchValue({
      startDate: startDate,
      endDate: endDate
    });

    const maintenanceModel: Maintenance = {
      id: maintenanceForm.value.id,
      publicationDate: maintenanceForm.value.publicationDate,
      startDate: startDate,
      endDate: endDate,
      active: maintenanceForm.value.active,
      body: maintenanceForm.value.body
    }

    // If its updating an existing maintenance
    if (this.maintenanceForm.value.id) {
      this.maintenanceService.update(maintenanceModel).subscribe((results) => {
        MaintenanceService.maintenanceChangedEvent.emit(results);
        this.toastrService.success(this.translocoService.translate('MaintenanceSaveSuccess'));
      }, (e) => {
        this.toastrService.error(this.translocoService.translate('MaintenanceSaveError'));
      });

    } else {
      this.maintenanceService.create(maintenanceModel).subscribe((results) => {
        MaintenanceService.maintenanceChangedEvent.emit(maintenanceForm.value);
        this.toastrService.success(this.translocoService.translate('MaintenanceCreateSuccess'));
        this.router.navigate([`/office/maintenance/${results.id}`]);
      }, (e) => {
        this.toastrService.error(this.translocoService.translate('MaintenanceCreateError'));
      });
    }
  }

  private mergingTimeAndDate(date: any, time: string): Date | null {
    // Validate if time has the following formats: H:mm or HH:mm
    const timePattern = /^([01]\d|2[0-3]):?([0-5]\d)$/gm
    if (!timePattern.test(time)) {
      this.toastrService.error(this.translocoService.translate('ErrorWrongTimeFormat'));
      return null;
    }

    const mergedDateTime = `${moment(date).format('YYYY-MM-DD')} ${time}`;
    return moment(mergedDateTime).toDate();
  }

  private initFormGroup() {
    if (!this.maintenanceForm) {
      this.maintenanceForm = this.formBuilder.group({
        id: [],
        publicationDate: [],
        startDate: [],
        startTime: [],
        endDate: [],
        endTime: [],
        active: [],
        body: []
      });
    }
  }

  private isNew(maintenanceId: any) {
    return (maintenanceId === 'new');
  }
}

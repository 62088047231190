import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { TournamentService } from '../services/tournament.service';
import { TournamentModel } from '../models/tournament.model';
import { TournamentUpdateModel } from '../models/tournament-update.model';
import { TournamentTypeEnum } from '../enums/tournament-type.enum';
import { TournamentEventUpdateModel } from '../models/tournament-event-update.model';
import { TournamentEventListItemModel } from '../models/tournament-event-list-item.model';

@Component({
    templateUrl: './tournament-events-add-modal.component.html',
    styleUrls: ['./tournament-events-add-modal.component.scss'],
})
export class TournamentEventsAddModalComponent implements OnInit {

    @Output() confirmed = new EventEmitter<boolean>();

    tournamentPlanId!: string;

    tournamentEventForm!: UntypedFormGroup;

    formSubmitted = false;

    tournamentEventListItems!: TournamentEventListItemModel[];

    constructor(
        private translocoService: TranslocoService,
        private activatedRoute: ActivatedRoute,
        private fb: FormBuilder,
        private datepipe: DatePipe,
        private modalService: BsModalService,
        private bsModalRef: BsModalRef,
        private tournamentService: TournamentService,
    ) {
    }

    ngOnInit() {
        this.initTournamentForm();
        this.loadAvailableEventsForTournament();
    }

    initTournamentForm() {
        this.tournamentEventForm = this.fb.group({
            eventId: [null, Validators.required],
        });
    }

    loadAvailableEventsForTournament() {
        this.tournamentService.getAllAvailableEventsForTournament(this.tournamentPlanId).subscribe(x => {
            x = x.sort((a, b) => a.eventNumber.localeCompare(b.eventNumber));
            this.tournamentEventListItems = x;
        });
    }

    add() {
        var model = new TournamentEventUpdateModel();
        model.eventId = this.tournamentEventForm.controls['eventId'].value;
        this.tournamentService.addTournamentEvent(this.tournamentPlanId, model).subscribe(x => {
            this.confirmed.emit(true);
            this.bsModalRef.hide();
        });
    }

    confirm() {
        this.formSubmitted = true;
        console.log(this.tournamentEventForm);
        if (!this.tournamentEventForm.valid) return;

        this.add();
    }

    decline() {
        this.bsModalRef.hide();
    }
}

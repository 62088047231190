import { Component, OnInit, EventEmitter, Output, TemplateRef, ViewChild, Input } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-reject-modal',
  templateUrl: './reject-modal.component.html',
  styleUrls: ['./reject-modal.component.scss']
})
export class RejectModalComponent implements OnInit {

  @Input() title!: string;
  @Input() body!: string;
  @Input() declineText!: string;

  modalRef!: BsModalRef;
  @ViewChild('rejectModal') rejectModal!: TemplateRef<any>;
  @Output() rejectAction: EventEmitter<string> = new EventEmitter<string>();
  @Output() onEventDeclined: EventEmitter<boolean> = new EventEmitter<boolean>();

  get rejectionReason() { return this.rejectionFormGroup.get('rejectionReason'); }

  rejectionFormGroup!: FormGroup;

  isLoading = false;
  submitted = false;
  @Input() config = {
    keyboard: false,
    ignoreBackdropClick: true
  };

  constructor(private modalService: BsModalService, private fb: FormBuilder) {}

  ngOnInit() {
    this.rejectionFormGroup = this.fb.group({
      rejectionReason: ['', Validators.required]
    });
  }

  open() {
    this.modalRef = this.modalService.show(this.rejectModal, this.config);
  }

  reject() {
    this.submitted = true;
    if (this.rejectionFormGroup.valid) {
      this.rejectAction.emit(this.rejectionReason!.value);
    }
  }

  cancel() {
    this.submitted = false;
    this.rejectionFormGroup.reset();
    this.onEventDeclined.emit(true);
    this.modalRef.hide();
  }

  close() {
    this.submitted = false;
    this.isLoading = false;
    this.rejectionFormGroup.reset();
    this.modalRef.hide();
  }

}

<div class="modal-header">
    <h2>{{ 'ChangePassword' | transloco}}</h2>
</div>
<div class="modal-body">
    <form *ngIf="!passwordChanged" [formGroup]="passwordForm">
        <div class="form-group">
            <div class="mb-3" [ngClass]="{'has-error': passwordForm.get('oldPassword')!.errors && isSubmitted}">
                <label for="oldPassword" class="control-label">{{'OldPassword' | transloco}} *</label>
                <input formControlName="oldPassword" id="oldPassword" name="oldPassword" class="form-control"
                    type="password">
                <span class="help-block" *ngIf="passwordForm.get('oldPassword')?.errors && isSubmitted">
                    <span *ngIf="passwordForm.get('oldPassword')?.errors!['required']">
                        Altes Passwort ist ein Muss-Feld
                    </span>
                </span>

            </div>
            <div class="mb-3" [ngClass]="{'has-error': passwordForm.get('newPassword')?.errors && isSubmitted}">
                <label for="newPassword" class="control-label">{{'NewPassword' | transloco}} *</label>
                <input formControlName="newPassword" id="newPassword" name="newPassword" class="form-control"
                    type="password">
                <span class="help-block" *ngIf="passwordForm.get('newPassword')?.errors && isSubmitted">
                    <span *ngIf="passwordForm.get('newPassword')?.errors!['required']">
                        Neues Passwort ist ein Muss-Feld
                    </span>
                    <span
                        *ngIf="passwordForm.get('newPassword')?.hasError('minlength') ||passwordForm.get('newPassword')?.hasError('maxlength')">
                        Das neue Passwort muss zwischen 8 und 100 Zeichen lang sein.
                    </span>
                </span>
            </div>

            <div class="mb-3" [ngClass]="{'has-error': passwordForm.get('newPasswordConfirmed')?.errors && isSubmitted}">
                <label for="newPasswordConfirmed" class="control-label">{{'ConfirmNewPassword' | transloco}} *</label>
                <input formControlName="newPasswordConfirmed" id="newPasswordConfirmed" name="newPasswordConfirmed"
                    class="form-control" type="password">
                <span class="help-block" *ngIf="passwordForm.get('newPasswordConfirmed')?.errors && isSubmitted">
                    <span *ngIf="passwordForm.get('newPasswordConfirmed')?.errors!['required']">
                        Neues Passwort bestätigen ist ein Muss-Feld
                    </span>
                    <span *ngIf="passwordForm.get('newPasswordConfirmed')!.hasError('notSame')">
                        Die Eingabe für ein neues Passwort und die Bestätigung des neuen Passworts sind nicht identisch.
                    </span>
                </span>
            </div>
        </div>
    </form>
    <div *ngIf="passwordChanged" class="alert alert-success" role="alert">
        Dein Passwort wurde erfolgreich geändert.
    </div>
</div>
<div class="modal-footer">
    <button *ngIf="!passwordChanged" class="btn btn-default" (click)="close()" [disabled]="isSaving">{{'Cancel' |
        transloco}}</button>
    <button *ngIf="!passwordChanged" class="btn btn-primary" (click)="changePassword()"
        [disabled]="this.passwordForm.errors || isSaving">{{'ChangePassword' | transloco}}</button>

    <button *ngIf="passwordChanged" class="btn btn-primary" (click)="close()">{{'Ok' | transloco}}</button>
</div>
<table class="table table-striped table-responsive table-bordered">
  <thead style="font-weight: bold;">
    <tr>
      <td style="width: 10px;"></td>
      <td>{{'Title' | transloco}}</td>
      <td>{{'Sport' | transloco}}</td>
      <td>{{'Date' | transloco}}</td>
      <td>{{'AvailablePlaces' | transloco}}</td>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let registration of myWaitingList" [ngClass]="registration.availablePositions > 0 ? 'success':''">
      <td>
        <button class="btn btn-default btn-sm" (click)="deleteRegistration(registration)"
          [tooltip]="'DeleteRegistration' | transloco">
          <i class="fa fa-trash-can" aria-hidden="true"></i>
        </button>
      </td>
      <td><a [routerLink]="'/events/'+registration.eventId">{{registration.eventName}}</a></td>
      <td>{{registration.sportName}}</td>
      <td>{{registration.eventStart | fromToDate: registration.eventEnd : 'DD.MM.YYYY': false}}</td>
      <td *ngIf="!registration.availablePositions && registration.availablePositions != 0">{{'Unlimited' | transloco}}
      </td>
      <td *ngIf="registration.availablePositions >= 0">{{registration.availablePositions}}</td>
    </tr>
  </tbody>
</table>
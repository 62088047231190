<div class="container-fluid mt-3" *ngIf="application">

  <h1>{{'Office.NewMembershipApplication' | transloco}} {{application.applicant.firstName}}
    {{application.applicant.lastName}}</h1>
  <h2>{{application.onlineMembership.nameDe}}</h2>

  <div class="alert alert-primary" *ngIf="cannotBeEdited">
    {{'Office.Msg.ApplicationCannotBeEdited' | transloco}}
  </div>
  <div class="alert alert-warning" *ngIf="application.isArchived">
    {{'Office.Msg.ApplicationIsArchived' | transloco}} ({{'Reason' | transloco}}: {{archiveReason}})
  </div>

  <div class="alert alert-primary" *ngIf="application.showDocumentsInPerson">{{'Office.Msg.ShowDocumentsInPerson' |
    transloco}}</div>

  <div class="well well-sm">
    <div class="d-flex justify-content-between">
      <div class="d-flex flex-wrap">
        <button class="btn btn-default me-3" (click)="navigateBack()">{{'BackToOverview' | transloco }}</button>
        <app-ma-editor-select [applicationId]="application.id"
          [editorAsvzId]="application.editorAsvzId"></app-ma-editor-select>
      </div>


      <div
        *ngIf="this.application.statusId === applicationStatus.verificationInProcess && !edited && !application.isArchived">
        <app-reject-modal #declineModal [body]="'Office.Msg.ConfirmApplicationDecline' | transloco"
          (rejectAction)="decline($event); declineModal.close()" (onEventDeclined)="isEditing = false;">
        </app-reject-modal>

        <button class="btn btn-danger" (click)="isEditing = true; declineModal.open()"
          [disabled]="isEditing">{{'Decline'
          | transloco }}</button>

        <app-reject-modal #rejectModal [body]="'Office.Msg.ConfirmApplicationSendBack' | transloco"
          [declineText]="'SendBack' | transloco" (rejectAction)="requestRevision($event); rejectModal.close()"
          (onEventDeclined)="isEditing = false;">
        </app-reject-modal>
        <button class="btn btn-default ms-2" (click)="isEditing = true; rejectModal.open()"
          [disabled]="isEditing">{{'SendBack' | transloco}}</button>
        <button class="btn btn-primary ms-2" (click)="eventconfirmShowModal()" [disabled]="isEditing">{{'Accept' |
          transloco }}</button>
      </div>
      <div
        *ngIf="(this.application.statusId === applicationStatus.declined || this.application.statusId === applicationStatus.revisionRequested) && !edited && !application.isArchived">
        <button class="btn btn-primary" (click)="isEditing = true; editingShowModal()"
          [disabled]="isEditing">{{'Office.SetToVerify' | transloco }}</button>
      </div>
    </div>
  </div>

  <div class="mt-3">
    <tabset>
      <tab heading="{{'BasicData' | transloco}}" id="tab1">
        <div style="padding-top:1rem;">
          <app-ma-basic-data-view [application]="application" (isEditingEvent)="childIsEditing($event)"
            (reloadApplication)="reloadApplication()"></app-ma-basic-data-view>
        </div>
      </tab>
      <tab heading="{{'Details' | transloco}}" style="padding-top:1em;" *ngIf="textConfigs.length">
        <div class="row" *ngFor="let config of textConfigs">

          <div class="col-md-6">
            <div class="form-group">
              <label>{{config.nameDe}}</label> <br>
              <!-- <app-details-view [details]="config.descriptionDe" *ngIf="config.descriptionDe"></app-details-view> -->
              <span>{{getDetailValue(config.id)}}</span>
            </div>
          </div>
        </div>

      </tab>
      <ng-container *ngIf="!application.showDocumentsInPerson && !application.isArchived">
        <tab heading="{{config.nameDe}}" *ngFor="let config of documents" style="padding-top:1em;">
          <app-ma-file-list [applicationFiles]="selectConfigurationFiles(config.id)"
            [onlineMembershipConfigId]="config.id" [applicationId]="application.id"></app-ma-file-list>
        </tab>
      </ng-container>
      <tab [heading]="'PassportPhoto' | transloco" style="padding-top:1em;"
        *ngIf="application.onlineMembership?.passPhotoRequired && !application.isArchived">
        <app-ma-image-cropper [applicationId]="application.id" [readonly]="readonly"></app-ma-image-cropper>
      </tab>

    </tabset>
  </div>

</div>
<app-spinner *ngIf="!application" margin="1rem auto"></app-spinner>
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-information-screen-annotations-component',
  templateUrl: './annotations.component.html',
  styleUrls: ['./annotations.component.scss']
})
export class AnnotationsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

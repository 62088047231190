<div class="container" style="min-height: 500px; margin-bottom:100px;" *ngIf="registrationSummary && event">
  <div style="margin-top: 34px;"></div>

  <app-status-workflow *ngIf="statuses" [statuses]="statuses"></app-status-workflow>

  <div class="row">
    <div class="col-sm-7 col-md-7">
      <app-registration-timer-display [registrationSummary]="registrationSummary"></app-registration-timer-display>

      <ng-container [ngSwitch]="currentRegistrationState$ | async">
        <ng-template [ngSwitchCase]="registrationStates.New">
          <app-registration-details #detailForm  [event]="event" [registrationSummary]="registrationSummary" (nextWizardState)="handleState(registrationStates.UserData)" (dirtyState)="setDetailFormDirtyState($event)"></app-registration-details>
        </ng-template>
        <ng-template [ngSwitchCase]="registrationStates.UserData">
          <app-registration-check-user-data [disableBackButton]="disableBackButton" [registrationSummary]="registrationSummary" (nextWizardState)="handleState($event)" (dirtyState)="setDetailFormDirtyState($event)"></app-registration-check-user-data>
        </ng-template>
        <ng-template [ngSwitchCase]="registrationStates.Temporary">
          <app-registration-temporary [event]="event" [registrationSummary]="registrationSummary" (nextWizardState)="handleState($event)"></app-registration-temporary>
        </ng-template>
      </ng-container>
    </div>


    <!-- right side bar where the informations are -->
    <div class="col-sm-5 col-md-5">
      <div class="panel-group">
        <div class="card">
          <div class="card-header">{{'Sportoffer' | transloco}}</div>
          <div class="card-body">
            <app-event-properties-display [event]="event" [additionalPrice]="getAdditionalPrice()"></app-event-properties-display>
          </div>
        </div>
      </div>
      <div class="panel-group mt-3">
        <div class="card">
          <div class="card-header">{{'Details' | transloco}}</div>
          <div class="card-body">
            <app-details-view [details]="event.details"></app-details-view>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-spinner  margin="100px auto" *ngIf="!registrationSummary && !event"></app-spinner>
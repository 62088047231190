  <select class="form-control" (change)="updateValue($event)" [formControl]="triStateControl" [attr.disabled]="readOnly">
    <option value="">
      - <span *ngIf="undefinedPrice != null && undefinedPrice > 0">(CHF {{undefinedPrice | number : '1.2-2'}})</span>
    </option>
    <option value="true"  [selected]="isSelected(1)">
      {{'Yes' | transloco}} <span *ngIf="yesPrice != null && yesPrice > 0">(CHF {{yesPrice | number : '1.2-2'}})</span>
    </option>
    <option value="false" [selected]="isSelected(0)">
      {{'No' | transloco}} <span *ngIf="noPrice != null && noPrice > 0">(CHF {{noPrice | number : '1.2-2'}})</span>
    </option>
  </select>

import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { TournamentEventGameSettingsUpdateModel } from '../models/tournament-event-game-settings-update.model';
import { TournamentEventModel } from '../models/tournament-event.model';
import { TournamentService } from '../services/tournament.service';
@Component({
    templateUrl: './tournament-event-game-configuration-modal.component.html',
    styleUrls: ['./tournament-event-game-configuration-modal.component.scss'],
})
export class TournamentEventGameConfigruationModalComponent implements OnInit {

    @Output() confirmed = new EventEmitter<boolean>();

    tournamentPlanId!: string;
    eventId!: string;

    tournamentGameSettingsForm!: UntypedFormGroup;

    formSubmitted = false;

    constructor(
        private fb: FormBuilder,
        private bsModalRef: BsModalRef,
        private tournamentService: TournamentService,
    ) {
    }

    ngOnInit() {
        if (this.tournamentPlanId && this.eventId) {
            this.loadTournamentEvent(this.tournamentPlanId, this.eventId);
        }
    }

    initGroupSettingsForm(tournamentEvent: TournamentEventModel) {

        this.tournamentGameSettingsForm = this.fb.group({
            setCount: [tournamentEvent.setCount, Validators.required],
            pointsPerWin: [tournamentEvent.pointsPerWin, Validators.required],
            pointsPerDraw: [tournamentEvent.pointsPerDraw, Validators.required],
            isCalculationPerSet: [tournamentEvent.isCalculationPerSet, Validators.required],
        });
    }

    loadTournamentEvent(tournamentPlanId: string, eventId: string) {
        this.tournamentService.getTournamentEventById(tournamentPlanId, eventId).subscribe(x => {
            this.initGroupSettingsForm(x);
        });
    }

    update() {
        this.tournamentService.updateTournamentGameSettings(this.tournamentPlanId, this.eventId, this.getTournamentGameSettingsUpdateModel()).subscribe(x => {
            this.confirmed.emit(true);
            this.bsModalRef.hide();
        });
    }

    getTournamentGameSettingsUpdateModel(): TournamentEventGameSettingsUpdateModel {
        var updateModel = new TournamentEventGameSettingsUpdateModel();

        updateModel.setCount = this.tournamentGameSettingsForm.controls['setCount'].value;
        updateModel.pointsPerWin = this.tournamentGameSettingsForm.controls['pointsPerWin'].value;
        updateModel.pointsPerDraw = this.tournamentGameSettingsForm.controls['pointsPerDraw'].value;
        updateModel.isCalculationPerSet = this.tournamentGameSettingsForm.controls['isCalculationPerSet'].value;

        return updateModel;
    }

    confirm() {
        this.formSubmitted = true;
        if (!this.tournamentGameSettingsForm.valid) return;

        this.update();
    }

    decline() {
        this.bsModalRef.hide();
    }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Country } from '../models/country.model';


@Injectable({
  providedIn: 'root'
})
export class CountryService {

  constructor(
    private httpService: HttpClient) {
  }

  getCountries(): Observable<Country[]> {
    return this.httpService.get(`${environment.api}/Countries`)
      .pipe(
        map((res: any): Country[] => res.data)
      );
  }
}

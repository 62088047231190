<div class="modal-header">
  <h4 class="modal-title float-start">{{'Preview' | transloco}}</h4>
</div>
<div class="modal-body modal-height">
   <ng-container *ngIf="!isLoading">
      <object [data]="base64Data" *ngIf="type !== 'image'" class="iframe" [type]="fileType" height="100%"></object>
      <img [src]="base64Data" *ngIf="type === 'image'" />
   </ng-container>
   <app-spinner *ngIf="isLoading" [margin]="'0 auto'"></app-spinner>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="closeModal()">{{'Close' | transloco }}</button>
</div>

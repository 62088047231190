<ng-container *ngIf="userIsLoggedIn">
    <div *ngIf="!requiredSkills.length">
        <i style="color:green;" class="fa fa-check me-1"></i> <span [innerHTML]="'SkillsAreFulfilledMsg' | transloco:translateParam"></span>
    </div>

    <div *ngIf="requiredSkills.length" style="margin-bottom:1rem;">
        <i style="color:red;" class="fa fa-times me-1"></i> <span [innerHTML]="'SkillsNotFulfilledMsg' | transloco:translateParam"></span>
    </div>
</ng-container>

<ul *ngIf="requiredSkills.length">
<li *ngFor='let skill of requiredSkills'>
    {{skill.skillName}} {{'By' | transloco}} {{skill.referenceDateInfo}}
</li>
</ul>

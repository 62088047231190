<div class="modal-header text-light">
    <h3>{{'Tournaments.Events.Game.EditLinks' | transloco}}</h3>
</div>

<div class="modal-body">

    <form *ngIf="tournamentGameLinksForm" [formGroup]="tournamentGameLinksForm">

        <div class="row">
            <div class="col-12 mb-3">
                <label for="additionLinkTitle" class="form-label">
                    {{'Tournaments.Events.Game.AdditionLinkTitle' | transloco}}
                </label>
                <div>
                    <input type="text" class="form-control" id="additionLinkTitle" formControlName="additionLinkTitle"
                        [ngClass]="{'border-danger':  tournamentGameLinksForm.controls['additionLink'].value && !tournamentGameLinksForm.controls['additionLinkTitle'].value && formSubmitted}">
                </div>
            </div>
            <div class="col-12 mb-3">
                <label for="additionLink" class="form-label">
                    {{'Tournaments.Events.Game.AdditionLink' | transloco}}
                </label>
                <div>
                    <input type="text" class="form-control" id="additionLink" formControlName="additionLink"
                    placeholder="www.asvz.ch"
                        [ngClass]="{'border-danger':  tournamentGameLinksForm.controls['additionLinkTitle'].value && !tournamentGameLinksForm.controls['additionLink'].value && formSubmitted}">
                </div>
            </div>
        </div>
    </form>

</div>

<div class="modal-footer  text-light">
    <button type="button" class="btn btn-default" (click)="decline()">{{'Cancel' | transloco}}</button>
    <button type="submit" class="btn btn-success" (click)="confirm()">{{'Save' | transloco}}</button>
</div>
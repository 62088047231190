import { Component, Input, OnInit } from '@angular/core';
import { isThisMinute } from 'date-fns';
import { RegistrationDetail } from 'src/app/events/registration-details.model';
import { Discount } from '../models/discount-giftcard/discount.model';
import { GiftCard } from '../models/discount-giftcard/giftcard.model';
import { HasDiscountGiftCard } from '../models/discount-giftcard/has-discount-giftcard.model';
import { ValidateDiscountPost } from '../models/discount-giftcard/validate-discount-post.model';
import { ValidateGiftCardPost } from '../models/discount-giftcard/validate-giftcard-post.model';
import { ValidateZkbDiscountPost } from '../models/discount-giftcard/validate-zkb-discount-post.model';
import { DiscountGiftcardService } from '../services/discount-giftcard.service';
import { PriceService } from '../services/price.service';

@Component({
  selector: 'app-discount-form',
  templateUrl: './discount-form.component.html',
  styleUrls: ['./discount-form.component.scss']
})
export class DiscountFormComponent implements OnInit {

  @Input() registrationId!: number;
  @Input() personMembershipId!: number;
  @Input() invoiceAmount!: number;
  @Input() registrationDetails!: RegistrationDetail[];

  hasDiscount!: HasDiscountGiftCard;
  discount?: Discount;
  giftCard?: GiftCard;

  showZkbCardNumberError = false;
  zkbCustomer = false;
  zkbCardNumber = '';

  showDiscountCodeError = false;
  discountCode = '';

  showGiftCardError = false;
  giftCardCode = '';

  constructor(private discountServcie: DiscountGiftcardService, private priceService: PriceService) { }

  ngOnInit() {

    if (this.isRegistration()) {
      this.discountServcie.getRegistrationHasDiscount(this.registrationId).subscribe((hasDiscount: HasDiscountGiftCard) => {
        this.hasDiscount = hasDiscount;
      });
    } else {
      this.discountServcie.getPersonMembershipHasDiscount(this.personMembershipId).subscribe((hasDiscount: HasDiscountGiftCard) => {
        this.hasDiscount = hasDiscount;
      });
    }
  }

  zkbCustomerChanged() {
    this.zkbCustomer = !this.zkbCustomer;

    if (!this.zkbCustomer) {
      this.showZkbCardNumberError = false;
    }
  }

  zkbCardNumberRedeem(): void {

    // remove discount
    this.discountCodeRemove();

    // validate card number
    const regEx = new RegExp('^[0-9]{5}$');

    if (regEx.test(this.zkbCardNumber)) {
      // validate on the backend
      const postModel = new ValidateZkbDiscountPost();
      postModel.cardNumber = this.zkbCardNumber;

      if (this.isRegistration()) {
        this.discountServcie.getRegistrationValidateZkbDiscount(this.registrationId, postModel).subscribe((discount: Discount) => {
          this.setValidateZkbDiscountResponse(discount);
        });
      } else {
        this.discountServcie.getPersonMembershipValidateZkbDiscount(this.personMembershipId, postModel).subscribe((discount: Discount) => {
          this.setValidateZkbDiscountResponse(discount);
        });
      }

    } else {
      this.showZkbCardNumberError = true;
    }
  }

  setValidateZkbDiscountResponse(discount: Discount): void {
    if (discount.valid) {
      this.discount = discount;
      this.showZkbCardNumberError = false;
    } else {
      this.showZkbCardNumberError = true;
    }
  }

  discountCodeRedeem(): void {

    // remove zkb discount
    this.zkbCardNumberRemove();

    if (this.discountCode.length > 0) {
      // validate on the backend
      const postModel = new ValidateDiscountPost();
      postModel.discountCode = this.discountCode;


      if (this.isRegistration()) {
        this.discountServcie.getRegistrationValidateDiscount(this.registrationId, postModel).subscribe((discount: Discount) => {
          this.setValidateDiscountResponse(discount);
        });
      } else {
        this.discountServcie.getPersonMembershipValidateDiscount(this.personMembershipId, postModel).subscribe((discount: Discount) => {
          this.setValidateDiscountResponse(discount);
        });
      }

    } else {
      this.showDiscountCodeError = true;
    }
  }

  setValidateDiscountResponse(discount: Discount): void {
    if (discount.valid) {
      this.discount = discount;
      this.showDiscountCodeError = false;
    } else {
      this.showDiscountCodeError = true;
    }
  }

  setValidateGiftCardResponse(giftCard: GiftCard): void {
    if (giftCard.isValid) {
      this.giftCard = giftCard;
      this.showGiftCardError = false;
    } else {
      this.showGiftCardError = true;
    }
  }

  zkbCardNumberRemove(): void {
    this.discount = undefined;
    this.showZkbCardNumberError = false;
    this.zkbCustomer = false;
    this.zkbCardNumber = '';
  }

  discountCodeRemove(): void {
    this.discount = undefined;
    this.showDiscountCodeError = false;
    this.discountCode = '';
  }

  giftCardRedeem(): void {

    if (this.giftCardCode.length > 0) {
      // validate on the backend
      const postModel = new ValidateGiftCardPost();
      postModel.giftcardCode = this.giftCardCode;

      if (this.isRegistration()) {
        this.discountServcie.getRegistrationValidateGiftCard(this.registrationId, postModel).subscribe((giftCard: GiftCard) => {
          this.setValidateGiftCardResponse(giftCard);
        });
      } else {
        this.discountServcie.getPersonMembershipValidateGiftCard(this.personMembershipId, postModel).subscribe((giftCard: GiftCard) => {
          this.setValidateGiftCardResponse(giftCard);
        });
      }

    } else {
      this.showGiftCardError = true;
    }
  }

  giftCardRemove(): void {
    this.giftCard = undefined;
    this.showGiftCardError = false;
    this.giftCardCode = '';
  }

  stripText(event: any): boolean {
    const seperator = '^([0-9])';
    const maskSeperator = new RegExp(seperator, 'g');
    return maskSeperator.test(event.key);
  }

  isRegistration(): boolean {
    if (this.registrationId !== undefined && this.registrationId !== null) {
      return true;
    }

    return false;
  }

  calcAdditionalAmmount(): number {
    if (!this.registrationDetails) return 0;
    return this.priceService.calcItemPrices(this.registrationDetails);
  }

  calcTotalAmount(): number {
    var invoiceAmount = this.invoiceAmount + this.calcAdditionalAmmount();
    var ammount = this.priceService.calcAmmount(invoiceAmount, this.discount, this.giftCard);

    return ammount!;
  }

  calcReductionAmount(): number {
    var invoiceAmount = this.invoiceAmount + this.calcAdditionalAmmount();

    var ammount = this.priceService.calcAmmount(invoiceAmount, this.discount!, this.giftCard!);
    ammount! -= invoiceAmount;
    if (ammount! < 0) ammount = ammount! * -1;
    return ammount!;
  }

  getNewGiftCardOutstandingAmount(): number {
    var invoiceAmount = this.invoiceAmount + this.calcAdditionalAmmount();
    if (this.giftCard !== null && this.giftCard !== undefined) {
      if (this.discount != null && this.discount.discountAmount != null) {
        invoiceAmount -= this.discount.discountAmount;
      }
      if (this.discount != null && this.discount.discountPercent != null) {
        invoiceAmount -= invoiceAmount / 100 * this.discount.discountPercent;
      }

      if (invoiceAmount < 0) invoiceAmount = 0;

      if (this.giftCard.outstandingAmount > invoiceAmount) {
        return this.giftCard.outstandingAmount - invoiceAmount;
      }

      return 0;
    }

    return 0;
  }

  commerciallyRound(number: number): number {
    return Math.round(number*100/5)*5/100;
  }
}

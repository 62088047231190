import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserAuthorizationService } from '../user-authorization.service';

@Injectable({
  providedIn: 'root'
})
export class AdminAuthGuard implements CanActivate {
  constructor(private authService: UserAuthorizationService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authService.getUser().hasRoleAdmin()) {
      return true;
    } else {
      if (this.authService.isLoggedIn()) {
        this.router.navigate(['error']);
      } else {
        this.authService.startAuthentication(state.url);
      }
      return false;
    }
  }
}


import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SharedModule} from '../shared/shared.module';
import {RouterModule} from '@angular/router';
import {routes} from './waiting-list.routes';
import {WaitingListMainComponent} from './waiting-list-main-layout/waiting-list-main-layout.component';
import {WaitingListOverviewComponent} from './waiting-list-overview/waiting-list-overview.component';
import { WaitingListTableComponent } from './waiting-list-table/waiting-list-table.component';
import {WaitingService} from './waiting.service';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forRoot(routes),
  ],
  declarations: [
    WaitingListMainComponent,
    WaitingListOverviewComponent,
    WaitingListTableComponent,
  ],
   providers: [
    WaitingService
  ]
})
export class WaitingListModule { }

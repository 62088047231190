import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MembershipApplicationComponent } from './components/membership-application/membership-application.component';
import { EmailConfirmationComponent } from './components/email-confirmation/email-confirmation.component';

const routes: Routes = [
  {
    path: 'new/:imMembershipId', component: MembershipApplicationComponent,
  },
  {
    path: ':membershipApplicationId/email-confirmation', component: EmailConfirmationComponent,
  },
  {
    path: ':membershipApplicationId', component: MembershipApplicationComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MembershipApplicationRoutingModule { }

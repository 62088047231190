import { Component, OnInit, OnDestroy } from '@angular/core';
import { RegistrationStates } from '../registration-states.enum';
import { RegistrationService } from '../registration.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { RegistrationSummary } from '../registration-summary.model';
import { Event } from '../../event.model';
import { EventsService } from '../../events.service';
import { StatusesService } from '../../../shared/status-workflow/statuses.service';
import { RegistrationGuardService } from '../registration-guard.service';
import { BaseComponent } from '@premotec/ngx-essentials';
import { BehaviorSubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { PriceService } from 'src/app/shared/services/price.service';
import { StatusStep } from 'src/app/shared/status-workflow/status-step.class';
import { RegistrationStateService } from '../services/registration-state.service';

@Component({
  selector: 'app-registration-main',
  templateUrl: './registration-main.component.html',
  styleUrls: ['./registration-main.component.scss'],
  providers: [RegistrationStateService]
})
export class RegistrationMainComponent extends BaseComponent implements OnInit, OnDestroy {

  registrationId?: string;

  statuses: StatusStep[] | null = [];
  event?: Event;
  currentStatus?: RegistrationStates;

  currentRegistrationState$: BehaviorSubject<RegistrationStates> = new BehaviorSubject<RegistrationStates>(RegistrationStates.None);
  registrationSummary?: RegistrationSummary;

  disableBackButton = false;

  registrationStates = RegistrationStates;

  constructor(private registrationService: RegistrationService,
    private priceService: PriceService,
    private eventsService: EventsService,
    private statusesService: StatusesService,
    private activatedRoute: ActivatedRoute,
    private registrationGuardService: RegistrationGuardService,
    private router: Router,
    private registrationStateService: RegistrationStateService) {
    super();
  }
  ngOnInit() {
    this.whileImAlive(this.activatedRoute.params).subscribe((params: Params) => {
      this.registrationId = params['registrationId'];
      this.loadRegistration(this.registrationId);
    });

  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.registrationStateService.stopStatusTimer();
    this.setDetailFormDirtyState(false);
  }

  loadRegistration(registrationId: any) {
    this.whileImAlive(this.registrationService.getById(registrationId))
      .pipe(
        switchMap(
          (res) => this.eventsService.getEventById(res.eventId!)
            .pipe(map(event => {
              return { registrationSummary: res, event: event };
            }))
        )
      ).subscribe((res) => {
        this.currentStatus = res.registrationSummary.status;

        this.registrationSummary = res.registrationSummary;
        this.event = res.event;
        this.setStatusWorkflowBar();

        this.handleState(this.currentStatus!);

        // reirect to payment page if status is ProcessPayment
        this.redirectProcessPaymentSate();
      });
  }

  getAdditionalPrice(): number {
    var registrationDetails = this.registrationService.getRegistrationDetails(this.event!, this.registrationSummary!);
    return this.priceService.calcItemPrices(registrationDetails);
  }

  handleState(registrationState: RegistrationStates): void {
    this.currentRegistrationState$.next(registrationState);
    this.currentStatus = registrationState;
    this.setDetailFormDirtyState(false);
    this.updateStatusWorkflowBar(registrationState);
    if (registrationState == null) {
      this.registrationStateService.stopStatusTimer();
      const redirectUrl = this.registrationService.getCorrespondingRegistrationViewUrl(this.registrationSummary!.status!, this.registrationSummary!.id!,this.registrationSummary!.invoiceId!,this.registrationSummary!.eventId!);
      this.router.navigateByUrl(redirectUrl);
    } else {
      this.registrationStateService.startStatusChangedTimer(this.registrationSummary?.id!, this.currentStatus!);
    }
    this.scrollToTop()
  }


  setDetailFormDirtyState(state: any) {
    this.registrationGuardService.formIsDirty = state;
  }

  updateWizardState(state: number) {
    this.setDetailFormDirtyState(false);
    this.handleState(state);
    this.scrollToTop();
  }

  scrollToTop() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  updateStatusWorkflowBar(status: any) {
    this.statusesService.updateWorkflowStatuses(status);
  }

  setStatusWorkflowBar() {
    this.statuses = null;
    this.statuses = [];
    if (!this.registrationService.getRegistrationDetails(this.event!, this.registrationSummary!).length) {
      if (this.registrationSummary?.status === RegistrationStates.New) {
        this.registrationSummary.status = RegistrationStates.UserData;
      }
      this.disableBackButton = true;
      this.statuses = this.statusesService.createStatusList(true);
    } else {
      this.statuses = this.statusesService.createStatusList(false);
    }

    this.updateStatusWorkflowBar(this.registrationSummary!.status);
  }

  redirectProcessPaymentSate() {
    if (this.registrationSummary!.status === RegistrationStates.ProcessPayment) {
      this.router.navigateByUrl('/invoice/' + this.registrationSummary!.invoiceId + '/payment');
    }
  }
}

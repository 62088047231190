import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { InvoiceService } from '../invoice.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { InvoiceType } from '../invoice-type.model';
import { InvoiceTypes } from '../invoice-types.enum';
import { TranslocoService } from '@ngneat/transloco';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-payment-processing',
  templateUrl: './payment-processing.component.html',
  styleUrls: ['./payment-processing.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PaymentProcessingComponent implements OnInit {
  invoiceType!: InvoiceType;
  invoiceId!: number;
  paymentStatus!: number;

  invoiceRegistrationType: InvoiceTypes = InvoiceTypes.Registration;
  invoiceTypes = InvoiceTypes;
  isPaymentDenied: Boolean = false;

  isCanceling: Boolean = false;

  private queryParams!: string;

  constructor(
    private invoiceService: InvoiceService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private translocoService: TranslocoService,
    private toastrService: ToastrService) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.invoiceId = params['invoiceId'];

      if (this.invoiceId) {
        this.loadInvoiceType(this.invoiceId);
      }
    });
    this.queryParams = this.router.url.split('?')[1];
    this.activatedRoute.queryParams.subscribe(queryParams => {
      this.paymentStatus = +queryParams['STATUS'];
      this.setPaymentStatus() // NOTE: param key from PSP are uppercase
    });
  }

  private setPaymentStatus() {
    if (this.paymentStatus === 93) {
      this.isPaymentDenied = true;
    }

    // When payment status is canceled (0, 1, 2), send cancel payment call to backend
    // Same request will be sent directly from psp to the backend
    if (this.paymentStatus === 1
      || this.paymentStatus === 2
      || this.paymentStatus === 0) {
      this.isPaymentDenied = true;
      this.isCanceling = true;
      this.cancelPspPayment();
    }

    // When sucessfull (9), send response to backend
    // Same request will be sent directly from psp to the backend
    if (this.paymentStatus === 9) {
      this.completePspPayment();
    }
  }

  private loadInvoiceType(invoiceId: number) {
    this.invoiceService.getInvoiceType(this.invoiceId).subscribe((invoiceType: InvoiceType) => this.invoiceType = invoiceType);
  }

  cancelPspPayment(): void {
    this.invoiceService.putPaymentCancelByInvoiceId(this.invoiceId).subscribe((invoiceType: InvoiceType) => {
      this.isCanceling = false;
    },
      (error) => {
        // TODO: Notify an error?
        this.toastrService.error(this.translocoService.translate('ErrorCancelPayment'));
      });
  }

  completePspPayment(): void {
    var params = "?" + this.queryParams;

    this.invoiceService.passOnPaymentResponse(params).subscribe(() => {
    },
      (error) => {
        // TODO: Notify an error?
        this.toastrService.error(this.translocoService.translate('ErrorCompletePayment'));
      });
  }
}

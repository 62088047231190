import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { LotteryHeaderGridComponent } from './components/lottery-header-grid/lottery-header-grid.component';
import { LotteryItemsGridComponent } from './components/lottery-items-grid/lottery-items-grid.component';

// import { FacilityResolverService } from './facilities/services/facility-resolver.service';

const routes: Routes = [
  {
    path: '', 
    component: LotteryHeaderGridComponent,
  },
  {
    path: ':id', 
    component: LotteryItemsGridComponent,
  }
];


@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
  ],
  exports: [RouterModule],
})
export class LotteryRoutingModule { }

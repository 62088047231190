import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { TournamentListItemModel } from '../models/tournament-list-item.model';
import { TournamentService } from '../services/tournament.service';
import { TournamentConfigruationModalComponent } from '../tournament-configuration-modal/tournament-configuration-modal.component';

@Component({
    selector: 'app-tournament-list',
    templateUrl: './tournament-list.component.html',
    styleUrls: ['./tournament-list.component.scss'],
})
export class TournamentListComponent implements OnInit {

    @Input() tournaments!: TournamentListItemModel[];

    showInactive!: boolean;
    constructor(
        private translocoService: TranslocoService,
        private activatedRoute: ActivatedRoute,
        private fb: FormBuilder,
        private datepipe: DatePipe,
        private tournamentService: TournamentService,
        private titleService: Title,
        private modalService: BsModalService,
        private router: Router
    ) {
    }

    newTournament() {
        this.createNewTournament();
    }

    getTournamentsLength() {
        return (this.showInactive ? this.tournaments.length : this.tournaments.filter((tournament) => tournament.isActive).length);
    }

    ngOnInit() {
        this.translocoService.selectTranslate('Tournaments.Title').subscribe((x: any) => {
            x += ` | ${this.translocoService.translate('Tournaments.Management')}`;
            this.titleService.setTitle(x);
        });

        this.loadTournaments();
    }

    loadTournaments() {
        this.tournamentService.getAllTournaments().subscribe(x => {
            this.tournaments = x.sort((a, b) => a.name.localeCompare(b.name));
        });
    }

    createNewTournament() {
        const initialState: ModalOptions = {
            backdrop: 'static',
            // class: 'modal-lg',
            keyboard: false,
            initialState: {

            },
        };

        const bsModalRef = this.modalService.show(TournamentConfigruationModalComponent, initialState);
        bsModalRef.content?.confirmed.subscribe(x => {
            this.router.navigateByUrl(`tournaments/admin/${x}/configuration`);
        });
    }
}

import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription, timer } from 'rxjs';
import { BaseComponent } from '@premotec/ngx-essentials';
import * as moment from 'moment';

import { EventState } from '../event-state.enum';
import { RequiredSkill } from '../required-skill.model';
import { Registration } from '../registration/registration.model';
import { RegistrationSummary } from '../registration/registration-summary.model';
import { RegistrationStates } from '../registration/registration-states.enum';
import { RegistrationService } from '../registration/registration.service';
import { Event } from '../event.model';
import { UserAuthorizationService } from '../../security/user-authorization.service';
import { EventsService } from '../events.service';
import { Account } from '../../account/account.model';
import { RegistrationKey } from '../registration/RegistrationKey';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-event-registration-button',
  templateUrl: './event-registration-button.component.html',
  styleUrls: ['./event-registration-button.component.scss']
})
export class EventRegistrationButtonComponent extends BaseComponent implements OnInit {

  // event as property so we know when it changes
  // this is needed as the event will be reloaded when there is an error for registering on the event
  private _event!: Event;

  @Input()
  set event(event: Event) {
    if (event) {
    this._event = event;
    this.setRegistrationProperties();
    }
  }
  get event(): Event {
    return this._event;
  }

  @Input() missingSkills: RequiredSkill[] = [];
  @Input() account!: Account;
  @Output() eventNeedsReload: EventEmitter<number> = new EventEmitter();
  @Output() liveStreamShow: EventEmitter<boolean> = new EventEmitter<boolean>();

  registrationKeys?: RegistrationKey;

  eventState = EventState;

  isLoaded = false;
  registering = false;

  showRegistrationCancelledMsg = false;
  showRegistrationErrorMsg = false;
  showRegisterFailMsg = false;
  showValidationError = false;

  validationError!: string;

  registrationUntilDateIsExpired!: boolean;
  registrationFromDateIsOpen!: boolean;
  registrationIsOpen!: boolean;


  hasSeats = true;
  continueRegistration = false;
  alreadyRegistered = false;
  needsAdditionalPayment = false;
  isOnWaitlist = false;
  canRegister!: boolean;
  userIsLoggedIn!: boolean;
  eventHasUserPriceClass = false;
  userHasValidMembership = false;
  priceOnRequest = false;

  private timerSubscription!: Subscription;
  myDate: any;

  constructor(
      private registrationService: RegistrationService,
      private router: Router,
      private authenticationService: UserAuthorizationService,
      private eventService: EventsService,
      private activatedRoute: ActivatedRoute
  ) {
    super();
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(() => {
      const action = this.activatedRoute.snapshot.queryParams['action'];
      if (action) {
        switch (action) {
          case 'cancelled':
            this.showRegistrationCancelledMsg = true;
            break;
          case 'error':
            this.showRegistrationErrorMsg = true;
            break;
        }
      }
    });

    this.setRegistrationProperties();
    this.userIsLoggedIn = this.authenticationService.isLoggedIn()
    if (this.userIsLoggedIn) {
      this.loadRegistrationByEventId(this.event.id);
    } else {
      this.isLoaded = true;
    }
  }


  setRegistrationProperties(): void {
    this.hasSeats = this.eventService.hasFreeSeats(this.event);
    this.registrationUntilDateIsExpired = this.eventService.registrationDateIsExpired(this.event.registrationUntil);
    this.registrationFromDateIsOpen = this.eventService.registrationDateIsOpen(this.event.registrationFrom);
    this.registrationIsOpen = !this.registrationUntilDateIsExpired && this.registrationFromDateIsOpen;
  }


  private userIsAllowedToRegister(): void {
    // if the user has no active membership but the event.externeAnmeldung is true, the user is able to register (priceClass is Extern)
    this.userHasValidMembership = (this.account.hasActiveMembership || this.event.externeAnmeldung);
    this.eventHasUserPriceClass = this.eventService.eventHasUserPriceClass(this.event, this.account);
    this.priceOnRequest = this.eventService.priceOnRequest(this.event);

    this.canRegister = this.event.registrationEnabled
    && (this.account.hasActiveMembership || this.event.externeAnmeldung)
    && this.eventHasUserPriceClass
    && this.eventService.userHasPermissionToRegister(this.event, this.missingSkills);

    if (!this.registrationIsOpen) {
      this.openRegistrationTimer();
    }

    this.isLoaded = true;

  }

  private loadRegistrationByEventId(eventId: number) {
    this.whileImAlive(this.registrationService.getRegistrationByEventId(eventId)).subscribe((registrationKeys: RegistrationKey) => {
      this.registrationKeys = registrationKeys;
      this.setRegistrationStates(this.registrationKeys);
      this.userIsAllowedToRegister();
    },
    () => {
      // not found so set it to null
      this.registrationKeys = undefined;
      this.setRegistrationStates(this.registrationKeys!);
      this.userIsAllowedToRegister();
    });
  }

  openRegistrationTimer() {

    console.log('openRegistrationTimer');

    const testTimer = false;
    let delay;

    if (testTimer) {
      this.myDate = moment().add(10, 'seconds');
      delay = moment(this.myDate).diff(moment());
    } else {
      delay = moment(this.event.registrationFrom).diff(moment());
    }

    // start timer if difference is <= 1day
    if (delay <= 8.64e+7) {
      const registrationTimer = timer(delay, 1000);
      this.timerSubscription = this.whileImAlive(registrationTimer).subscribe(t => this.checkRegisterFromDate(t));
    }
  }

  checkRegisterFromDate(t: number) {
    this.setRegistrationProperties();

    if (this.registrationIsOpen) {
      this.timerSubscription.unsubscribe();
    }
  }

  private setRegistrationStates(registration: Registration | RegistrationSummary | RegistrationKey) {
    if (registration != null) {

      console.log('Registration Status:', registration.status);

      // Sets whether or not the registration should be continued
      if (registration.status !== RegistrationStates.Completed
        && registration.status !== RegistrationStates.Error) {
        this.continueRegistration = true;
      } else {
        this.continueRegistration = false;
      }


      // Sets whether the registration needs aditional payment
      if (registration.status === RegistrationStates.AdditionalPayment) {
        this.needsAdditionalPayment = true;
      } else {
        this.needsAdditionalPayment = false;
      }

      // Sets whether the user has registered already
      if (registration.status === RegistrationStates.Completed) {
        this.alreadyRegistered = true;
      } else {
        this.alreadyRegistered = false;
      }

      // Sets whether the user has put event on waitlist already
      if (registration.status === RegistrationStates.WaitList) {
        this.isOnWaitlist = true;
      } else {
        this.isOnWaitlist = false;
      }
    } else {
      this.continueRegistration = false;
      this.alreadyRegistered = false;
      this.isOnWaitlist = false;
    }
  }

  login() {
    this.router.navigateByUrl(`/registration/prove/${this.event.id}`); // we need to call the secured url so that oidc will initiate the login
  }

  register(eventId: number) {
    if (!this.registering) {
      this.registering = true;
      this.resetMessages();

      this.registrationService.registerForEvent(eventId).subscribe(
        (registration) => {
          this.registering = false;
          this.router.navigateByUrl(`/registration/${registration.id}/wizard`);
        },
        (error: HttpErrorResponse) => {
          this.registering = false;

          // get the error from the response
          switch (error.error.errorStatus) {
            case 'ValidationFailure':
            case 'NotUpdateable':
              if (error.error && error.error.errors.length > 0) {
                this.showValidationError = true;
                this.validationError = error.error.errors[0].message;
              } else {
                // we didn't get a validation error. Show the general one
                this.showRegisterFailMsg = true;
              }
              break;
            default:
              this.showRegisterFailMsg = true;
          }

          // the event must be reloaded to show the changes
          this.eventNeedsReload.emit(this.event.id);
          this.loadRegistrationByEventId(this.event.id);
        }
      );
    }
  }

  resetMessages() {
    this.showRegistrationCancelledMsg = false;
    this.showRegistrationErrorMsg = false;
    this.showRegisterFailMsg = false;
    this.showValidationError = false;
  }

  registerForWaitlist(eventId: number) {
    if (!this.registering) {
      this.registering = true;
      this.resetMessages();

      this.registrationService.registerForWaitlist(eventId).subscribe(
        () => {
          this.registering = false;

          this.loadRegistrationByEventId(this.event.id);
        },
        (error: any) => {
          this.registering = false;
          this.showRegisterFailMsg = true;
        }
      );
    }
  }

  deleteWaitlistRegistration(registrationId: number) {
    this.registering = true;
    this.resetMessages();

    this.registrationService.setStateDelete(registrationId).subscribe(
      () => {
        this.registering = false;

        this.loadRegistrationByEventId(this.event.id);
      },
      (e) => {
        // TODO: Handle error cases!
        this.registering = false;
        this.showRegisterFailMsg = true;
      });
  }

  public showLiveStream() {
    this.liveStreamShow.emit(true);
  }
}

<table class="table table-striped tournament-group m-0">
    <thead>
        <tr>
            <th style="width: 1px;">#</th>
            <th>{{'Tournaments.Public.Ranking.Team' | transloco}}</th>
            <th style="width: 1px; white-space: nowrap;">
                {{'Tournaments.Public.Ranking.GameCountShort' | transloco}}</th>
            <th style="width: 1px; white-space: nowrap;">
                {{'Tournaments.Public.Ranking.DifferenceShort' | transloco}}</th>
            <th style="width: 1px; white-space: nowrap;">{{'Tournaments.Public.Ranking.PointsShort'
                | transloco}}</th>
        </tr>
    </thead>
    <tbody>
        <tr class="tournament-group-row" *ngFor="let team of rankingModel.teams ; let i = index"
            [ngClass]="{'border-bottom-1 border-danger':  i+1 == rankingModel.qualifyingTeamCount, 'alert-primary' : team.isMarked}">
            <td class="">{{team.rank}}</td>
            <td>{{team.teamName}}</td>
            <td class="text-end" style="white-space: nowrap;">
                {{team.gameCount}}
            </td>
            <td class="text-end text-center" style="white-space: nowrap;">
                {{team.scoreMade - team.scoreReceived}}
            </td>
            <td class="text-end" style="white-space: nowrap;">
                {{team.points}}
            </td>
        </tr>
    </tbody>
    <tfoot>
        <tr>
            <td colspan="5">
                <div class="d-flex flex-md-row flex-column gap-2">
                    <div>
                        <b>{{'Tournaments.Public.Ranking.GameCountShort' | transloco}}</b>
                        {{'Tournaments.Public.Ranking.GameCount' | transloco}}
                    </div>
                    <div>
                        <b>{{'Tournaments.Public.Ranking.DifferenceShort' | transloco}}</b>
                        {{'Tournaments.Public.Ranking.Difference' | transloco}}
                    </div>
                    <div>
                        <b>{{'Tournaments.Public.Ranking.PointsShort' | transloco}}</b>
                        {{'Tournaments.Public.Ranking.Points' | transloco}}
                    </div>
                </div>
            </td>
        </tr>
    </tfoot>
</table>
import { Component, OnInit, Input, Output, EventEmitter, ViewChildren, AfterViewInit, QueryList, ViewEncapsulation } from '@angular/core';
import { Application, ApplicationDocument, ApplicationDetails, Configuration, ApplicationPut } from '../../models';
import { MembershipConfigType, MemebershipApplicationStatus } from '../../enums';
import { FormBuilder, FormGroup, Validators, AbstractControl, FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { MembershipApplicationsService } from '../../membership-applications.service';
import { BaseComponent } from '@premotec/ngx-essentials';
import { FileUploaderComponent } from '../file-uploader/file-uploader.component';

function hasAtLeastOneDocument(c: AbstractControl) {
  if (c.value.length !== 0) {
    return null;
  }

  return { 'documentRequired': true };
}

@Component({
  selector: 'app-application-configurations',
  templateUrl: './application-configurations.component.html',
  styleUrls: ['./application-configurations.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ApplicationConfigurationsComponent extends BaseComponent implements OnInit {

  @ViewChildren(FileUploaderComponent) fileUploader !: QueryList<FileUploaderComponent>;

  configType = MembershipConfigType;
  formGroup!: FormGroup;
  submitted!: boolean;
  membershipApplicationStatus = MemebershipApplicationStatus;
  readonly = true;

  @Input() application!: Application;
  @Input() applicationId!: string;

  @Output() currentStepChanged: EventEmitter<number> = new EventEmitter();
  @Output() applicationChanged: EventEmitter<Application> = new EventEmitter();

  configurationDetailControls: FormControl[] = new Array<FormControl>(); // controls which are defined in the configuration

  constructor(private fb: FormBuilder, private applicationService: MembershipApplicationsService) {
    super();
  }

  ngOnInit() {
    this.sortConfigurations();
    this.initForm();
  }

  initForm() {
    if (this.formGroup != null) {
      this.formGroup.reset();
    }

    if (this.application.statusId === MemebershipApplicationStatus.new || this.application.statusId === MemebershipApplicationStatus.revisionRequested) {
      this.readonly = false;
    }

    this.formGroup = this.fb.group({
      commentFromApplicant: { value: this.application.commentFromApplicant, disabled: this.readonly },
      showDocumentsInPerson: { value: this.application.showDocumentsInPerson, disabled: this.readonly }
    });

    if (this.application.onlineMembership.passPhotoRequired) {
      const passPhotoControl = this.fb.control({ value: '', disabled: this.readonly }, Validators.required);
      this.formGroup.addControl('passportPhoto', passPhotoControl);
    }

    this.application.onlineMembership.configurations.forEach(c => {

      if (c.type !== this.configType.Document) {
        const detail = this.application.details.find(d => d.onlineMembershipConfigId === c.id);

        let controlValue = '';
        if (detail != null) {
          controlValue = detail.valueJson;
        }

        const control = this.fb.control({ value: controlValue, disabled: this.readonly }, Validators.required);
        this.formGroup.addControl(c.id, control);
        this.configurationDetailControls.push(control);

        this.whileImAlive(this.formGroup.get(c.id)!.valueChanges.pipe(debounceTime(2000))).subscribe(value => {
          this.whileImAlive(this.applicationService.updateConfigDetailValue(this.application.id, c.id, value)).subscribe(() => {
            this.updateApplicationDetail(c.id, value);
          });
        });
      }

      if (c.type === this.configType.Document) {
        const control = this.fb.control({ value: this.selectConfigurationFiles(c.id), disabled: this.readonly }, hasAtLeastOneDocument);
        this.formGroup.addControl(c.id, control);
        this.configurationDetailControls.push(control);
      }
    });

    this.whileImAlive(this.formGroup.get('commentFromApplicant')!.valueChanges).subscribe(value => this.application.commentFromApplicant = value);

    this.changedShowDocumentsInPerson(); // initialize the form when we come back from the basic data form

  }

  submit() {
    if (this.formIsValid()) {
      this.currentStepChanged.emit(2);
    } else {
      this.submitted = true;
      window.scrollTo(0, 0);
    }
  }

  formIsValid(): boolean {
    let filesInvalid = false;

    // just check the files if the user uploads the files by himself
    if (!this.application.showDocumentsInPerson) {
      // TODO for Nicolas from Shahid: Is this check needed? What is the difference between this and the function hasAtLeastOneDocument?
      this.application.onlineMembership.configurations.forEach(c => {
        const uploader = this.fileUploader.find(u => u.componentId === c.id);
        if (uploader != null) {
          if (!uploader.files.length && !uploader.applicationFiles.length) {
            filesInvalid = true;
          }
        }
      });
    }

    if (this.formGroup.invalid || filesInvalid) {
      return false;
    } else {
      return true;
    }
  }

  sortConfigurations() {
    if (this.application.onlineMembership.configurations != null) {
      this.application.onlineMembership.configurations.sort((a, b) => {
        return a.sort - b.sort;
      });
    }
  }

  updateApplicationDetail(configId: any, value: any) {
    const detail = this.application.details.find(d => d.onlineMembershipConfigId === configId);
    detail.valueJson = value;
    this.updateApplication();
  }

  updateApplication() {
    this.applicationChanged.emit(this.application);
  }

  selectConfigurationFiles(onlineMembershipConfigId: string): ApplicationDocument[] {
    const config: ApplicationDetails = this.application.details.find(d => {
      return d.onlineMembershipConfigId === onlineMembershipConfigId;
    });

    if (config != null) {
      return config.documents;
    } else {
      return [];
    }
  }

  getOptions(config: any) {
    if (config != null) {
      const options = JSON.parse(config);
      return options;
    }
    return null;
  }

  changedShowDocumentsInPerson() {
    this.application.showDocumentsInPerson = this.formGroup.get('showDocumentsInPerson')?.value;

    // when disabling the control, the validator will not be checked
    this.configurationDetailControls.forEach(ctrl => {
      if (this.application.showDocumentsInPerson) {
        ctrl.disable();
      } else {
        ctrl.enable();
      }
    })
  }
}

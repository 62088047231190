<div class="container">
  <div class="row">
    <div class="col-sm-12">
      <h1>Impressum/Datenschutz</h1>
      <markdown path="assets/markdown/impressum.md"></markdown>
    </div>
  </div>
  <p class="text-muted text-end">
    <i>
      <small>
        Version {{assemblyVersion}} / Server: {{serverName}}
      </small>
    </i>
  </p>
</div>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { TranslocoService } from '@ngneat/transloco';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TournamentDetailsComponent } from './tournament-details/tournament-details.component';
import { TournamentConfigruationModalComponent } from './tournament-configuration-modal/tournament-configuration-modal.component';
import { TournamentsRoutingModule } from './tournaments-routing.module';
import { TournamentListComponent } from './tournament-list/tournament-list.component';
import { TournamentConfigurationDetailsComponent } from './tournament-configuration-details/tournament-configuration-details.component';
import { TournamentPlanningDetailsComponent } from './tournament-planning-details/tournament-planning-details.component';
import { TournamentEventsDetailsComponent } from './tournament-events-details/tournament-events-details.component';
import { TournamentEventsAddModalComponent } from './tournament-events-add-modal/tournament-events-add-modal.component';
import { TournamentPlanningSlotsAddModalComponent } from './tournament-planning-slots-add-modal/tournament-planning-slots-add-modal.component';
import { NgxDatepickerModule } from '@premotec/ngx-datepicker';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { TournamentPlanningSlotsCalenderComponent } from './tournament-planning-slots-calender/tournament-planning-slots-calender.component';
import { TournamentPlanningSlotsCalenderDayComponent } from './tournament-planning-slots-calender-day/tournament-planning-slots-calender-day.component';
import { TournamentPlanningSlotsCopyModalComponent } from './tournament-planning-slots-copy-modal/tournament-planning-slots-copy-modal.component';
import { TournamentEventGroupConfigruationModalComponent } from './tournament-event-group-configuration-modal/tournament-event-group-configuration-modal.component';
import { TournamentEventGameConfigruationModalComponent } from './tournament-event-game-configuration-modal/tournament-event-game-configuration-modal.component';
import { TournamentGroupsDetailsComponent } from './tournament-groups-details/tournament-groups-details.component';
import { TournamentGamePlansDetailsComponent } from './tournament-game-plans-details/tournament-game-plans-details.component';
import { AgGridModule } from 'ag-grid-angular';
import { TournamentTeamChangeGroupModalComponent } from './tournament-team-change-group-modal/tournament-team-change-group-modal.component';
import { TournamentGroupAddRoomModalComponent } from './tournament-group-add-room-modal/tournament-group-add-room-modal.component';
import { TournamentAdministratorsModalComponent } from './tournament-administrators-modal/tournament-administrators-modal.component';
import { TournamentEventDataMappingModalComponent } from './tournament-event-data-mapping-modal/tournament-event-data-mapping-modal.component';
import { TournamentEventDataMappingSlotFormComponent } from './tournament-event-data-mapping-slot-form/tournament-event-data-mapping-slot-form.component';
import { TournamentTeamComponent } from './tournament-team/tournament-team.component';
import { TournamentTeamDataComponent } from './tournament-team-data/tournament-team-data.component';
import { TournamentPublicEventGroupsComponent } from './tournament-public-event-groups/tournament-public-event-groups.component';
import { TournamentPublicEventGamesComponent } from './tournament-public-event-games/tournament-public-event-games.component';
import { TournamentGameChangeSlotModalComponent } from './tournament-game-change-slot-modal/tournament-game-change-slot-modal.component';
import { TournamentGameResultModalComponent } from './tournament-game-result-modal/tournament-game-result-modal.component';
import { TournamentTeamGamesComponent } from './tournament-team-games/tournament-team-games.component';
import { TournamentAdminGamesComponent } from './tournament-admin-games/tournament-admin-games.component';
import { TournamentPublicEventGroupRankingComponent } from './tournament-public-event-group-ranking/tournament-public-event-group-ranking.component';
import { TournamentTeamRefereeGamesComponent } from './tournament-team-referee-games/tournament-team-referee-games.component';
import { TournamentEventGameLinksModalComponent } from './tournament-event-game-links-modal/tournament-event-game-links-modal.component';
import { TournamentPublicAvailableSlotsComponent } from './tournament-public-available-slots/tournament-public-available-slots.component';
import { TournamentTeamGameComponent } from './tournament-team-game/tournament-team-game.component';
import { TournamentCaptainMailTextModalComponent } from './tournament-captain-mail-text-modal/tournament-captain-mail-text-modal.component';
import { QuillModule } from 'ngx-quill';
import { TournamentGameStatusAgGridComponent } from './tournament-game-status-ag-grid.component/tournament-game-status-ag-grid.component';
import { TournamentEventGroupRankingTableComponent } from './tournament-event-group-ranking-table/tournament-event-group-ranking-table.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        QuillModule.forRoot(),
        NgSelectModule,
        FormsModule,
        TournamentsRoutingModule,
        ReactiveFormsModule,
        NgxDatepickerModule,
        AgGridModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
        })
    ],
    declarations: [
        TournamentListComponent,
        TournamentDetailsComponent,
        TournamentConfigruationModalComponent,
        TournamentAdministratorsModalComponent,
        TournamentCaptainMailTextModalComponent,
        TournamentEventDataMappingModalComponent,
        TournamentEventDataMappingSlotFormComponent,
        TournamentEventGroupConfigruationModalComponent,
        TournamentEventGameConfigruationModalComponent,
        TournamentEventGameLinksModalComponent,
        TournamentEventsAddModalComponent,
        TournamentConfigurationDetailsComponent,
        TournamentPlanningDetailsComponent,
        TournamentEventsDetailsComponent,
        TournamentPlanningSlotsAddModalComponent,
        TournamentPlanningSlotsCopyModalComponent,
        TournamentPlanningSlotsCalenderComponent,
        TournamentPlanningSlotsCalenderDayComponent,
        TournamentGroupsDetailsComponent,
        TournamentGamePlansDetailsComponent,
        TournamentTeamChangeGroupModalComponent,
        TournamentGroupAddRoomModalComponent,
        TournamentTeamComponent,
        TournamentTeamDataComponent,
        TournamentTeamGameComponent,
        TournamentTeamGamesComponent,
        TournamentTeamRefereeGamesComponent,
        TournamentPublicEventGroupsComponent,
        TournamentPublicAvailableSlotsComponent,
        TournamentPublicEventGamesComponent,
        TournamentPublicEventGroupRankingComponent,
        TournamentGameChangeSlotModalComponent,
        TournamentGameResultModalComponent,
        TournamentAdminGamesComponent,
        TournamentGameStatusAgGridComponent,
        TournamentEventGroupRankingTableComponent
    ],
    exports: [],
})
export class TournamentsModule {
    constructor(translate: TranslocoService) {
        translate.setDefaultLang('de');
    }
}

import { Component, OnInit } from '@angular/core';
import { interval, startWith, map, takeWhile, Subscription } from 'rxjs';
import { EnrollmentStateService } from '../../services/enrollment-state.service';

@Component({
  selector: 'app-lottery-countdown',
  templateUrl: './lottery-countdown.component.html',
  styleUrls: ['./lottery-countdown.component.scss']
})
export class LotteryCountdownComponent implements OnInit {

  remainingSeconds!: number;
  private timerSubscription!: Subscription;

  constructor(private enrollmentStateService: EnrollmentStateService) {
  }

  ngOnInit(): void {
    this.startTimer();
  }

  startTimer(): void {
    this.timerSubscription = interval(1000).pipe(
      startWith(0),
      map(() => {
        return this.enrollmentStateService.getLotteryDurationInSeconds();
      }),
      takeWhile(remainingSeconds => remainingSeconds >= 0)
    ).subscribe({
      next: (remainingSeconds) => {
        this.remainingSeconds = remainingSeconds;
      },
      complete: () => { 
      }
    });
  }

  ngOnDestroy(): void {
    this.timerSubscription.unsubscribe();
  }
}
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EnrollmentMessageStateService {
  private showEnrollSuccessMsgSubject = new BehaviorSubject<boolean>(false);
  private showEnrollErrorMsgSubject = new BehaviorSubject<boolean>(false);
  private showWithdrawSuccessMsgSubject = new BehaviorSubject<boolean>(false);
  private showWithdrawErrorMsgSubject = new BehaviorSubject<boolean>(false);
  private showLotteryErrorMsgSubject = new BehaviorSubject<boolean>(false);
  private validationErrorSubject = new BehaviorSubject<string | null>(null);

  // Observables for components to subscribe
  showEnrollSuccessMsg$ = this.showEnrollSuccessMsgSubject.asObservable();
  showEnrollErrorMsg$ = this.showEnrollErrorMsgSubject.asObservable();
  showWithdrawSuccessMsg$ = this.showWithdrawSuccessMsgSubject.asObservable();
  showWithdrawErrorMsg$ = this.showWithdrawErrorMsgSubject.asObservable();
  showLotteryErrorMsg$ = this.showLotteryErrorMsgSubject.asObservable();
  validationError$ = this.validationErrorSubject.asObservable();

  // Methods to update the state
  setShowEnrollSuccessMsg(value: boolean) {
    this.showEnrollSuccessMsgSubject.next(value);
  }

  setShowEnrollErrorMsg(value: boolean) {
    this.showEnrollErrorMsgSubject.next(value);
  }

  setShowWithdrawSuccessMsg(value: boolean) {
    this.showWithdrawSuccessMsgSubject.next(value);
  }

  setShowWithdrawErrorMsg(value: boolean) {
    this.showWithdrawErrorMsgSubject.next(value);
  }

  setValidationError(message: string) {
    this.validationErrorSubject.next(message);
  }

  setShowLotteryErrorMsg(value: boolean) {
    this.showLotteryErrorMsgSubject.next(value);
  }

  resetMessages() {
    this.showEnrollSuccessMsgSubject.next(false);
    this.showEnrollErrorMsgSubject.next(false);
    this.showWithdrawSuccessMsgSubject.next(false);
    this.showWithdrawErrorMsgSubject.next(false);
    this.showWithdrawErrorMsgSubject.next(false);
    this.showLotteryErrorMsgSubject.next(false);
    this.validationErrorSubject.next(null);
  }

}
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaintenanceOverviewComponent} from './maintenance-overview/maintenance-overview.component';
import {SharedModule} from '../../shared/shared.module';
import {MaintenanceListComponent} from './maintenance-overview/maintenance-list/maintenance-list.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {MaintenanceService} from './maintenance.service';
import {MaintenanceDetailComponent} from './maintenance-overview/maintenance-detail/maintenance-detail.component';
import { NgxDatepickerModule } from '@premotec/ngx-datepicker';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    NgxDatepickerModule
  ],
  declarations: [MaintenanceOverviewComponent, MaintenanceListComponent, MaintenanceDetailComponent],
  exports: [RouterModule, MaintenanceOverviewComponent],
  providers: [MaintenanceService]
})
export class MaintenanceModule {
}

<div *ngIf="!showScreen && !isAdminView" class="flex-wrapper">
  <div>
    <app-navigation-bar></app-navigation-bar>
  </div>

  <div style="margin-top: 100px; flex:1;">
    <router-outlet></router-outlet>
  </div>
  <app-footer *ngIf="showFooter"></app-footer>
</div>


<div *ngIf="!showScreen && isAdminView">
  <admin-navbar></admin-navbar>
  <div class="main-wrapper">
    <div class="side-menu">
      <app-side-navbar (isCollapsedSidebar)="toggleCollapse(!$event)"></app-side-navbar>
    </div>
    <div class="main-content" [ngClass]="collapsedSidebar ? 'sidebar-collapsed-width' : 'sidebar-width'">
      <div class="mw-100">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
<router-outlet *ngIf="showScreen"></router-outlet>
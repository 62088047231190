import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { TournamentGroupListModel } from '../models/tournament-group-list.model';
import { TournamentService } from '../services/tournament.service';
import { TournamentGroupAddTeamModel } from '../models/tournament-group-add-team.model';
import { TournamentGameChangeSlotModel } from '../models/tournament-game-change-slot.model';
import * as moment from 'moment';
import { TournamentGameChangeSlotOptionModel } from '../models/tournament-game-change-slot-option.model';
import { TournamentGameNewSlotModel } from '../models/tournament-game-new-slot.model';
import { TournamentDisplayService } from '../services/tournament-display.service';

@Component({
  templateUrl: './tournament-game-change-slot-modal.component.html',
  styleUrls: ['./tournament-game-change-slot-modal.component.scss']
})
export class TournamentGameChangeSlotModalComponent implements OnInit {

  @Output() confirmed = new EventEmitter<boolean>();

  changeSlotForm!: UntypedFormGroup;

  tournamentPlanId!: string;
  gameId!: string;

  slotData!: TournamentGameChangeSlotModel;

  optionsFacilities: TournamentGameChangeSlotOptionModel[] = [];
  optionsRooms: TournamentGameChangeSlotOptionModel[] = [];
  optionsFields: TournamentGameChangeSlotOptionModel[] = [];
  optionsSlots: TournamentGameChangeSlotOptionModel[] = [];

  formSubmitted = false;

  constructor(
    private fb: FormBuilder,
    private bsModalRef: BsModalRef,
    private tournamentService: TournamentService,
    public tournamentDisplayService: TournamentDisplayService,
    private toastrService: ToastrService,
    private translocoService: TranslocoService
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.loadData();
  }

  initForm() {
    this.changeSlotForm = this.fb.group({
      newFacilityId: [null, Validators.required],
      newRoomId: [null, Validators.required],
      newFieldId: [null, Validators.required],
      newSlotId: [null, Validators.required],
    });
  }

  loadData() {
    this.tournamentService.getGamePlanChangeSlotOptions(this.tournamentPlanId, this.gameId).subscribe(x => {
      this.slotData = x;
      this.setOptionFacilities();
    });
  }

  onFacilityChanged() {
    var facilityId = this.changeSlotForm.controls['newFacilityId'].value;

    this.changeSlotForm.controls['newRoomId'].setValue(null);
    this.onRoomChanged();

    this.setOptionRooms(facilityId);
  }

  onRoomChanged() {
    var facilityId = this.changeSlotForm.controls['newFacilityId'].value;
    var roomId = this.changeSlotForm.controls['newRoomId'].value;

    this.changeSlotForm.controls['newFieldId'].setValue(null);
    this.onFieldChanged();

    this.setOptionFields(facilityId, roomId);
  }

  onFieldChanged() {
    var facilityId = this.changeSlotForm.controls['newFacilityId'].value;
    var roomId = this.changeSlotForm.controls['newRoomId'].value;
    var fieldId = this.changeSlotForm.controls['newFieldId'].value;

    this.changeSlotForm.controls['newSlotId'].setValue(null);

    this.setOptionSlots(facilityId, roomId, fieldId);
  }

  setOptionFacilities() {
    var optionsFacilities: TournamentGameChangeSlotOptionModel[] = [];

    this.slotData.options.forEach(x => {
      if (!optionsFacilities.find(o => o.facilityId == x.facilityId)) optionsFacilities.push(x);
    });

    optionsFacilities = optionsFacilities.sort((a, b) => a.facilityName.localeCompare(b.facilityName));

    this.optionsFacilities = optionsFacilities;
    if (this.optionsFacilities.length == 1) {
      var facilityId = this.optionsFacilities[0].facilityId;
      this.changeSlotForm.controls['newFacilityId'].setValue(facilityId);
      this.setOptionRooms(facilityId);
    }
  }

  setOptionRooms(facilityId?: number) {
    var optionsRooms: TournamentGameChangeSlotOptionModel[] = [];

    if (facilityId == undefined) this.optionsRooms = optionsRooms;

    var facilityOptions = this.slotData.options.filter(x => x.facilityId == facilityId);

    facilityOptions.forEach(x => {
      if (!optionsRooms.find(o => o.roomId == x.roomId)) optionsRooms.push(x);
    });

    optionsRooms = optionsRooms.sort((a, b) => a.roomName.localeCompare(b.roomName));

    this.optionsRooms = optionsRooms;

    if (this.optionsRooms.length == 1) {
      var roomId = this.optionsRooms[0].roomId;
      this.changeSlotForm.controls['newRoomId'].setValue(roomId);
      this.setOptionFields(facilityId, roomId);
    }
  }

  setOptionFields(facilityId?: number, roomId?: number) {
    var optionsFields: TournamentGameChangeSlotOptionModel[] = [];

    if (facilityId == undefined || roomId == undefined) this.optionsFields = optionsFields;

    var fieldOptions = this.slotData.options.filter(x => x.facilityId == facilityId && x.roomId == roomId);

    fieldOptions.forEach(x => {
      if (!optionsFields.find(o => o.field == x.field)) optionsFields.push(x);
    });

    optionsFields = optionsFields.sort((a, b) => a.field - b.field);

    this.optionsFields = optionsFields;

    if (this.optionsFields.length == 1) {
      var fieldId = this.optionsFields[0].field;
      this.changeSlotForm.controls['newFieldId'].setValue(fieldId);
      this.setOptionSlots(facilityId, roomId, fieldId);
    }
  }

  setOptionSlots(facilityId?: number, roomId?: number, fieldId?: number) {
    var optionsSlots: TournamentGameChangeSlotOptionModel[] = [];

    if (facilityId == undefined || roomId == undefined || fieldId == undefined) this.optionsSlots = optionsSlots;

    var slotOptions = this.slotData.options.filter(x => x.facilityId == facilityId && x.roomId == roomId && x.field == fieldId);

    slotOptions.forEach(x => {
      if (!optionsSlots.find(o => o.slotId == x.slotId)) optionsSlots.push(x);
    });

    optionsSlots = optionsSlots.sort((a, b) => new Date(a.from).getTime() - new Date(b.from).getTime());

    this.optionsSlots = optionsSlots;

    if (this.optionsSlots.length == 1) {
      var slotId = this.optionsFields[0].slotId;
      this.changeSlotForm.controls['newSlotId'].setValue(slotId);
    }
  }

  confirm() {
    this.formSubmitted = true;

    var model = this.getChangeModel();
    this.tournamentService.changeGamePlanSlot(this.tournamentPlanId, this.gameId, model).subscribe(x => {
      this.confirmed.emit(true);
      this.bsModalRef.hide();
    });
  }

  getChangeModel(): TournamentGameNewSlotModel {
    var model = new TournamentGameNewSlotModel();
    model.slotId = this.changeSlotForm.controls['newSlotId'].value;
    return model;
  }

  decline() {
    this.bsModalRef.hide();
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { LotteryHeaderGridComponent } from './components/lottery-header-grid/lottery-header-grid.component';
import { LotteryRoutingModule } from './lottery-routing.module';
import { AgGridModule } from 'ag-grid-angular';
import { LotteryService } from './services/lottery.service';
import { AgGridDetailButtonComponent } from './components/ag-grid-detail-button/ag-grid-detail-button.component';
import { LotteryItemsGridComponent } from './components/lottery-items-grid/lottery-items-grid.component';



@NgModule({
  declarations: [
    LotteryHeaderGridComponent,
    AgGridDetailButtonComponent,
    LotteryItemsGridComponent
  ],
  providers: [LotteryService],
  imports: [
    CommonModule,
    SharedModule,
    LotteryRoutingModule,
    AgGridModule,

  ]
})
export class LotteryModule { }

export enum MembershipState {
    None = 0,
    WaitList = 1,
    Temporary = 2,
    ProcessPayment = 3,
    Completed = 4,
    AdditionalPayment = 5,
    Canceled = 6,
    PassPhoto = 80,
    New = 90,
    Expired = 91,
    Error = 92,
    Deleted = 93,

  // NOTE: This is an alias Status and is not defined by IM
  //       This could cause problems if IM expands the status list to include this value
    UserData = 99,


    EventoCanceled = 331 // Evento Canceled
}

import { Component, Input, OnInit } from '@angular/core';
import { MembershipApplicationsService } from 'src/app/membership-application/membership-applications.service';
import { Editor } from '../models/editor.model';
import { BsModalService } from 'ngx-bootstrap/modal';
import { MaEditorSelectModalComponent } from '../ma-editor-select-modal/ma-editor-select-modal.component';
import { MembershipApplicationAdminService } from '../services/membership-application-admin.service';

@Component({
  selector: 'app-ma-editor-select',
  templateUrl: './ma-editor-select.component.html',
})
export class MaEditorSelectComponent implements OnInit {

  @Input() editorAsvzId?: number;
  @Input() applicationId!: string;

  editor: Editor | undefined;
  editors: Editor[] = [];

  constructor(
    private membershipApplicationsService: MembershipApplicationAdminService,
    private modalService: BsModalService) { }

  ngOnInit(): void {
    this.membershipApplicationsService.getEditors().subscribe((res: any) => {
      this.editors = res.data;
    });
  }

  openEditModal(): void {
    const ref = this.modalService.show(MaEditorSelectModalComponent, {
      initialState: {editorAsvzId: this.editorAsvzId, applicationId: this.applicationId}
    });

    ref.content?.editorAsvzIdChanged.subscribe((editorAsvzId: number | undefined) => {
      this.editorAsvzId = editorAsvzId;
    });
  }

}

import {Routes} from '@angular/router';
import { AuthGuard } from '../security/guards';
import {LessonDetailsComponent} from './components/lesson-details/lesson-details.component';
import { MyLessonsOverviewComponent } from './components/my-lessons-overview/my-lessons-overview.component';

export const lessonsRoutes: Routes = [
  {
    path: 'my',
    component: MyLessonsOverviewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: ':lessonId',
    component: LessonDetailsComponent
  }
];

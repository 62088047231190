<div class="container" style="min-height: 500px;" *ngIf="registrationSummary">
  <div class="page-header">
    <h1>{{'CancelPayment' | transloco}}</h1>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="well well-sm">
        <button (click)="cancelPayment()" class="btn btn-info" style="margin-right: 2rem;">
          {{'CancelPayment' | transloco}}
        </button>
        <button (click)="deletePayment()" class="btn btn-danger" style="margin-right: 2rem;">
          {{'DeleteRegistration' | transloco}}
        </button>
        <span>
          <a [routerLink]="'/registration/' + registrationSummary.id+'/wizard'">{{'BackToRegistration' | transloco}}</a>
        </span>
      </div>
    </div>
    <div class="col-md-4">
      <div class="panel-group" id="accordDescr_details">
        <div class="card">
          <div class="card-header">{{'Sportoffer' | transloco}}</div>
          <div class="card-body">
            <app-event-properties-display *ngIf="event"
                                          [event]="event"></app-event-properties-display>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-8">
      <div class="panel-group">
        <div class="card">
          <div class="card-header">{{'Details' | transloco}}</div>
          <div class="card-body">
            <app-details-view *ngIf="event" [details]="event.details"></app-details-view>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<footer class="hidden-print">
  <app-footer-sponsors></app-footer-sponsors>

  <aside class="footer-section">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-md-4">
          <div class="footer-address">
            Akademischer Sportverband Zürich<br>
            ETH Zürich<br>
            8092 Zürich
            <div class="phone-text">
              <a href="tel:+41 44 632 42 10">+41 44 632 42 10</a>
            </div>
            <div class="email-text">
              <a href="mailto:info@asvz.ch">info&#64;asvz.ch</a>
            </div>

          </div>
        </div>

        <div class="col-sm-6 col-md-4">
          <div class="footer-menu">
            <h5 class="footer-menu-title">Quicklinks</h5>
            <ul>
              <li class="footer-menu-item">
                <a href="https://asvz.ch/59666-willkommen-im-asvz" target="_blank">Member&#96;s Manual</a>
              </li>
              <li class="footer-menu-item">
                <a href="https://asvz.dfshop.com/produkte/" target="_blank">Merchandise</a>
              </li>
              <li class="footer-menu-item">
                <a href="https://asvz.ch/59443-teilnahmeberechtigung" target="_blank">Teilnahme&shy;<wbr>berechtigungen</a>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-sm-6 col-md-4">
          <div class="footer-menu">
            <h5 class="footer-menu-title">Information</h5>
            <ul>
              <li class="footer-menu-item">
                <a href="https://asvz.ch/avb" target="_blank">AVB</a>
              </li>
              <li class="footer-menu-item">
                <a [routerLink]="'/faq'">FAQ</a>
              </li>
              <li class="footer-menu-item">
                <a [routerLink]="'/impressum'">Impressum</a>
              </li>
            </ul>
          </div>

        </div>
      </div>


      <div class="footer-bottom row">
        <div class="col-sm-12">
          <p>© Copyright ASVZ. Alle Rechte vorbehalten.</p>
        </div>
      </div>
    </div>
  </aside>
</footer>
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { GridApi, ICellRendererParams } from 'ag-grid-community';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { TournamentGameChangeSlotModalComponent } from '../tournament-game-change-slot-modal/tournament-game-change-slot-modal.component';
import { TournamentGameResultModalComponent } from '../tournament-game-result-modal/tournament-game-result-modal.component';
import { TranslocoService } from '@ngneat/transloco';
import { TournamentService } from '../services/tournament.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationModalComponent } from 'src/app/shared/confirmation-modal/confirmation-modal.component';

@Component({
    templateUrl: './tournament-game-admin-options-ag-grid.component.html',
    styleUrls: []
})
export class TournamentGameAdminOptionsAgGridComponent implements ICellRendererAngularComp {

    bsModalRef?: BsModalRef;
    params!: ICellRendererParams;
    value!: string;
    gridApi!: GridApi;
    gridData: any;

    gameId!: string;
    tournamentPlanId!: string;

    showDeleteResult!: boolean;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private modalService: BsModalService,
        private translocoService: TranslocoService,
        private tournamentService: TournamentService,
        private toastrService: ToastrService,
    ) { }

    agInit(params: ICellRendererParams): void {
        this.params = params;
        this.value = params.value;
        this.gridApi = params.api;

        this.gameId = this.params.data.id;
        this.tournamentPlanId = this.params.data.tournamentPlanId;

        this.showDeleteResult = this.params.data.result != undefined && this.params.data.result != '';
    }

    openResultModal() {
        const initialState: ModalOptions = {
            backdrop: 'static',
            keyboard: false,
            initialState: {
                gameId: this.gameId,
                tournamentPlanId: this.tournamentPlanId,
            },
        };

        const bsModalRef = this.modalService.show(TournamentGameResultModalComponent, initialState);
        bsModalRef.content?.confirmed.subscribe(x => {
            this.params.context.loadData();
        });
    }

    openDeleteResultModal() {
        const initialstate: ModalOptions = {
            initialState: {
                color: 'danger',
                confirmText: this.translocoService.translate('Delete'),
                body: this.translocoService.translate('Tournaments.GamePlans.DeleteResults.Question'),
            }
        }

        const bsModalRef = this.modalService.show(ConfirmationModalComponent, initialstate);

        bsModalRef.content!.confirmed.subscribe(() => {
            this.deleteResult();
        })
    }

    deleteResult() {
        this.tournamentService.deleteGameResults(this.tournamentPlanId, this.gameId).subscribe(x => {
            this.toastrService.success(this.translocoService.translate('Tournaments.GamePlans.DeleteResults.Success'));
            this.params.context.loadData();
        }, err => {
            this.toastrService.error(this.translocoService.translate('Tournaments.GamePlans.DeleteResults.Error'));
        });
    }


    refresh() {
        return false;
    }
}

import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { TournamentService } from '../services/tournament.service';
import { TournamentFacilityModel } from '../models/tournament-facility.model';
import { TournamentRoomModel } from '../models/tournament-room.model';
import * as moment from 'moment';
import { TournamentSlotValidationModel } from '../models/tournament-slot-validation.model';
import { TournamentSlotDisplayModel } from '../models/tournament-slot-display.model';
import { TournamentSlotsCopyModel } from '../models/tournament-slots-copy.model';
import { TournamentSlotValidationTypeEnum } from '../enums/tournament-slot-validation-type.enum';
import { TournamentDisplayService } from '../services/tournament-display.service';

@Component({
    templateUrl: './tournament-planning-slots-copy-modal.component.html',
    styleUrls: ['./tournament-planning-slots-copy-modal.component.scss'],
})
export class TournamentPlanningSlotsCopyModalComponent implements OnInit {

    @Output() confirmed = new EventEmitter<boolean>();

    tournamentPlanId!: string;
    slots!: TournamentSlotDisplayModel[];

    todaySlots!: TournamentSlotValidationModel[];

    tournamentFacilities!: TournamentFacilityModel[];
    tournamentRooms!: TournamentRoomModel[];

    formSubmitted = false;
    checked = false;
    processing = false;

    tournamentSlotCopyForm!: UntypedFormGroup;

    TournamentSlotValidationTypeEnum = TournamentSlotValidationTypeEnum;

    constructor(
        private fb: FormBuilder,
        private bsModalRef: BsModalRef,
        private tournamentService: TournamentService,
        public tournamentdisplayService: TournamentDisplayService,
        private datePipe: DatePipe
    ) {
    }

    ngOnInit() {
        if (this.tournamentPlanId) {
            this.loadFacilties();
        }

        var todaySlots: TournamentSlotValidationModel[] = [];

        this.slots.forEach(s => {
            let todaySlot = new TournamentSlotValidationModel();
            todaySlot.slotId = s.id;
            todaySlot.validationType = TournamentSlotValidationTypeEnum.Unknown;
            todaySlot.from = new Date();
            todaySlot.to = new Date();

            todaySlot.from.setHours(new Date(s.from).getHours());
            todaySlot.from.setMinutes(new Date(s.from).getMinutes());

            todaySlot.to.setHours(new Date(s.to).getHours());
            todaySlot.to.setMinutes(new Date(s.to).getMinutes());

            todaySlots.push(todaySlot);
        });

        this.todaySlots = todaySlots.sort((a: TournamentSlotValidationModel, b: TournamentSlotValidationModel) => {
            return a.from.getTime() - b.from.getTime()
        });
    }

    initForms() {
        var defaultFacility = this.getFacilityIdIfSingle();

        this.tournamentSlotCopyForm = this.fb.group({
            facilityId: [defaultFacility, Validators.required],
            roomId: [null, Validators.required],
            fieldId: [this.getFieldIdIfSingle(), Validators.required],
            date: [this.getDateIfSingle(), Validators.required],
        });

        if (defaultFacility != null) this.updateRooms();
    }

    loadFacilties() {
        this.tournamentService.getTournamentFacilities(this.tournamentPlanId).subscribe(x => {
            x = x.sort((a, b) => a.name.localeCompare(b.name));
            x.forEach(f => {
                f.rooms = f.rooms.sort((a, b) => a.name.localeCompare(b.name));
            });

            this.tournamentFacilities = x;

            this.initForms();
        })
    }

    confirm() {
        this.confirmed.next(true);
        this.bsModalRef.hide();
    }

    decline() {
        this.bsModalRef.hide();
    }

    back() {
        this.updateValidation([]);
        this.checked = false;
    }

    copy() {
        var copyModel = this.getCopyModel();
        this.processing = true;
        this.tournamentService.copyTournamentSlots(this.tournamentPlanId, copyModel).subscribe(x => {
            this.processing = false;
            this.confirm();
        },
            err => {
                this.processing = false;
            }
        )
    }

    check() {
        this.formSubmitted = true;

        if (!this.tournamentSlotCopyForm.valid) return;

        var copyModel = this.getCopyModel();

        this.processing = true;
        this.tournamentService.validateCopyTournamentSlots(this.tournamentPlanId, copyModel).subscribe(x => {
            this.updateValidation(x);
            this.checked = true;
            this.processing = false;
        }, err => {
            this.processing = false;
        })
    }

    getCopyModel(): TournamentSlotsCopyModel {
        var copyModel = new TournamentSlotsCopyModel();
        copyModel.imFacilityId = this.tournamentSlotCopyForm.controls['facilityId'].value;
        copyModel.imRoomId = this.tournamentSlotCopyForm.controls['roomId'].value;
        copyModel.fieldId = this.tournamentSlotCopyForm.controls['fieldId'].value;
        copyModel.date = this.tournamentSlotCopyForm.controls['date'].value;
        copyModel.slotIds = this.slots.map(({ id }) => id);

        return copyModel;
    }

    updateValidation(validatedSlots: TournamentSlotValidationModel[]) {
        this.todaySlots.forEach(x => {
            let validatedSlot = validatedSlots.find(v => v.slotId == x.slotId);
            x.validationType = validatedSlot?.validationType ?? TournamentSlotValidationTypeEnum.Unknown;
        });
    }

    onFacilityChange() {
        this.updateRooms();
    }

    updateRooms() {
        this.tournamentRooms = [];
        this.tournamentSlotCopyForm.controls['roomId'].setValue(null);
        let facilityId = this.tournamentSlotCopyForm.controls['facilityId'].value;
        if (facilityId == null) return;

        var facility = this.tournamentFacilities.find(x => x.id == facilityId);
        this.tournamentRooms = facility!.rooms;

        if (facility!.rooms.length == 1) {
            this.tournamentSlotCopyForm.controls['roomId'].setValue(facility!.rooms[0].id);
            return;
        }

        var defaultRoomId = this.getRoomIdIfSingle();
        if (defaultRoomId != null && this.tournamentRooms.some(x => x.id == defaultRoomId)) {
            this.tournamentSlotCopyForm.controls['roomId'].setValue(defaultRoomId);
        }
    }

    getDateIfSingle(): Date | null {
        if (!this.slots || this.slots.length == 0) return null;

        var firstDate = new Date(this.slots[0].from)
        var momentDate = moment(firstDate);


        if (this.slots.length == 1) return firstDate;

        var isSameDate = true;

        this.slots.forEach(x => {
            var momentCurrent = moment(x.from);
            if (momentDate.dayOfYear() != momentCurrent.dayOfYear() || momentDate.year() != momentCurrent.year()) isSameDate = false;
        })

        return isSameDate ? firstDate : null;
    }

    getRoomIdIfSingle(): number | null {
        if (!this.slots || this.slots.length == 0) return null;

        var firstRoomId = this.slots[0].imRoomId;

        if (this.slots.length == 1) return firstRoomId;

        var isSameRoom = true;

        this.slots.forEach(x => {
            if (firstRoomId != x.imRoomId) isSameRoom = false;
        })

        return isSameRoom ? firstRoomId : null;
    }

    getFieldIdIfSingle(): number | null {
        if (!this.slots || this.slots.length == 0) return null;

        var firstFieldId = this.slots[0].fieldId;

        if (this.slots.length == 1) return firstFieldId;

        var isSame = true;

        this.slots.forEach(x => {
            if (firstFieldId != x.fieldId) isSame = false;
        })

        return isSame ? firstFieldId : null;
    }

    getFacilityIdIfSingle(): number | null {
        if (!this.slots || this.slots.length == 0) return null;

        var firstFacilityId = this.slots[0].imFacilityId;

        if (this.slots.length == 1) return firstFacilityId;

        var isSame = true;

        this.slots.forEach(x => {
            if (firstFacilityId != x.imFacilityId) isSame = false;
        })

        return isSame ? firstFacilityId : null;
    }

}

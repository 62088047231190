import { UserAuthorizationService } from './user-authorization.service';
import { NgModule, Optional, SkipSelf, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { LogoutComponent } from './logout/logout.component';
import { AuthorizationInterceptor } from './authorization.interceptor';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { AuthGuard, OfficeAuthGuard } from './guards';
import { RequestRateInterceptor } from '../shared/request-rate/services/reqest-rate.interceptor';
import { AdminAuthGuard } from './guards/admin-auth-guard.service';
import { FacilityManagementAuthGuard } from './guards/facility-management-auth-guard.service';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [LogoutComponent, AuthCallbackComponent],
})
export class SecurityModule {

  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: SecurityModule,
      providers: [
        UserAuthorizationService,
        AuthGuard,
        OfficeAuthGuard,
        AdminAuthGuard,
        FacilityManagementAuthGuard,
        { provide: HTTP_INTERCEPTORS, useClass: RequestRateInterceptor, multi: true },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthorizationInterceptor,
          multi: true
        },
        
      ]
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: SecurityModule) {
    if (parentModule) {
      throw new Error(
        'SecurityModule is already loaded. Import it in the AppModule only');
    }
  }

}

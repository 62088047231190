
<div class="modal-header">
    <h4 class="modal-title pull-left">{{ 'RequestRate.ToMuchRequests' | transloco }}</h4>
</div>

<div class="modal-body">
    <p style="margin: 0;">{{'RequestRate.Hint' | transloco}}</p>
</div>

<div class="modal-footer text-light">
    <button type="button" class="btn btn-primary" (click)="confirm()" [disabled]="timerSeconds > 0">
        {{'RequestRate.Confirm' | transloco}} <span *ngIf="timerSeconds > 0">({{timerSeconds}})</span>
    </button>
</div>

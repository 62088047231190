<div class="alert alert-danger" *ngIf="hasError">
    <b>{{'Tournaments.Groups.LoadingError' | transloco}}</b>
</div>

<div class="alert alert-warning" *ngIf="tournamentEventGroups != undefined && !tournamentEventGroups.hasMapping">
    <b>{{'Tournaments.Events.Mapping.Required' | transloco}}</b>
</div>

<div class="well mb-3 text-end" *ngIf="tournamentEventGroups != undefined && tournamentEventGroups.hasMapping">
    <button class="btn btn-success btn-sm" (click)="openGroupCreationModal()"
        [disabled]="loadingData || !tournamentEventGroups.canFormGroups">
        {{'Tournaments.Groups.TriggerCreate' | transloco}} <i class="fa-solid fa-shuffle"></i>
    </button>
    <button class="btn btn-danger btn-sm" (click)="openGroupDeleteModal()"
        [disabled]="loadingData">
        {{'Tournaments.Groups.TriggerDelete' | transloco}} <i class="fa-solid fa-trash"></i>
    </button>
    <button class="btn btn-sm btn-primary" (click)="openSendEmailToCaptainsModal()"  [disabled]="loadingData">
        <i style="color: white;" class="fa fa-envelope" aria-hidden="true"></i>
        {{'Tournaments.CaptainEmail.SendEmails' | transloco}}
    </button>
</div>

<div class="card-body p-3" *ngIf="loadingData">
    <app-spinner></app-spinner>
</div>

<div class="row" *ngIf="tournamentEventGroups">
    <div class="col-12 mb-3" *ngFor="let event of tournamentEventGroups.eventGroups">
        <div class="card">
            <div class="card-header">
                <div class="d-flex">
                    <div class="flex-grow-1"><b>{{event.eventNumber}} - {{event.eventTitle}} - {{event.category}}</b>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-3" *ngIf="event.unallocatedTeams.length > 0">
                        <div class="card mb-3">
                            <div class="card-header">
                                <div class="d-flex">
                                    <div class="flex-grow-1"><b>
                                        {{ 'Tournaments.Teams.UnallocatedTeams' | transloco}}</b> ({{event.unallocatedTeams.length}})
                                    </div>
                                </div>
                            </div>
                            <div class="card-body" >
                                <div class="row">
                                    <div class="col-md-12">
                                        <h4>{{ 'Tournaments.Teams.Title' | transloco }}</h4>
                                    </div>
                                </div>
                                <div class="row group-teams">
                                    <div class="col-md-12">
                                        <table class="table">
                                            <tbody>
                                                <tr *ngFor="let team of event.unallocatedTeams">
                                                    <td> {{ team.teamName }}</td>
                                                    <td style="width: 20px;" *ngIf="event.groups?.length">
                                                        <button class="btn btn-sm btn-primary"
                                                            (click)="openChangeTeamGroupModal(team.registrationId, null, event.id)"
                                                            tooltip="{{'Tournaments.Teams.ChangeGroup' | transloco}}">
                                                            <i class="fa-solid fa-arrows-left-right"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3" *ngFor="let group of event.groups">
                        <div class="card mb-3">
                            <div class="card-header">
                                <div class="d-flex">
                                    <div class="flex-grow-1"><b>{{event.prefix}} {{ group.number}}</b>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <h4>{{ 'Tournaments.Teams.Title' | transloco }}</h4>
                                    </div>
                                </div>
                                <div class="row group-teams">
                                    <div class="col-md-12">
                                        <table class="table">
                                            <tbody>
                                                <tr *ngFor="let team of group.teams">
                                                    <td> {{ team.teamName }}</td>
                                                    <td style="width: 20px;">
                                                        <div class="btn-group" role="group">
                                                            <a class="btn btn-sm btn-secondary"
                                                                tooltip="{{'Tournaments.Teams.ShowTeamPage' | transloco}}"
                                                                [routerLink]="['/tournaments/'+tournamentPlanId+'/teams/'+team.teamId]"
                                                                [queryParams]="{secret:team.secret}"
                                                                [target]="'_blank'">
                                                                <i class="fa-solid fa-link"></i>
                                                            </a>
                                                            <button class="btn btn-sm btn-primary"
                                                                (click)="openChangeTeamGroupModal(team.registrationId, group.id, event.id)"
                                                                tooltip="{{'Tournaments.Teams.ChangeGroup' | transloco}}">
                                                                <i class="fa-solid fa-arrows-left-right"></i>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="row mt-5">
                                    <div class="col-md-12">
                                        <table class="table">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <h4>{{'Tournaments.Teams.Rooms' | transloco}}</h4>
                                                    </td>
                                                    <td style="width: 20px;">
                                                        <button type="button" class="btn btn-success btn-sm float-end"
                                                            (click)="openGroupAddRoomModal(group.id);"
                                                            tooltip="{{ 'Tournaments.Teams.AddRoom' | transloco }}"><i
                                                                class="fa-solid fa-plus"></i></button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <table class="table">
                                            <tbody>
                                                <tr *ngFor="let room of sortRooms(group.rooms)">
                                                    <td *ngIf="room.facilityName !== room.roomName">{{ room.facilityName
                                                        }} | {{ room.roomName }}</td>
                                                    <td *ngIf="room.facilityName === room.roomName">{{ room.facilityName
                                                        }}</td>
                                                    <td style="width: 20px;">
                                                        <button class="btn btn-sm btn-danger"
                                                            (click)="deleteGroupRoom(group.id, room.id)"
                                                            tooltip="{{'Delete' | transloco}}">
                                                            <i class="fa-solid fa-trash"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
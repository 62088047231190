import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { TournamentService } from '../services/tournament.service';
import { TournamentModel } from '../models/tournament.model';
import { TournamentTypeEnum } from '../enums/tournament-type.enum';
import { TournamentPlanningSlotsAddModalComponent } from '../tournament-planning-slots-add-modal/tournament-planning-slots-add-modal.component';
import { TournamentSlotDisplayDayModel } from '../models/tournament-slot-display-day.model';
import { TournamentSlotDisplayModel } from '../models/tournament-slot-display.model';
import { TournamentSlotDeleteModel } from '../models/tournament-slot-delete.model';
import { TournamentPlanningSlotsCopyModalComponent } from '../tournament-planning-slots-copy-modal/tournament-planning-slots-copy-modal.component';
import { TournamentSlotRoomOverviewModel } from '../models/tournament-slot-room-overview.model';
import { ConfirmationModalComponent } from 'src/app/shared/confirmation-modal/confirmation-modal.component';
import { TournamentDisplayService } from '../services/tournament-display.service';

@Component({
    selector: 'app-tournament-planning-details',
    templateUrl: './tournament-planning-details.component.html',
    styleUrls: ['./tournament-planning-details.component.scss'],
})
export class TournamentPlanningDetailsComponent implements OnInit {

    @Input() tournamentPlanId!: string;

    tournament!: TournamentModel | null;

    slots!: TournamentSlotDisplayDayModel[] | undefined;
    selectedSlotIds!: string[];
    rooms!: TournamentSlotRoomOverviewModel[];

    minHour: number = 0;
    maxHour: number = 24;

    roomColors!: string[];
    
    TournamentTypeEnum = TournamentTypeEnum;
    constructor(
        private translocoService: TranslocoService,
        private activatedRoute: ActivatedRoute,
        private tournamentService: TournamentService,
        private tournamentDisplayService: TournamentDisplayService,
        private fb: FormBuilder,
        private datepipe: DatePipe,
        private modalService: BsModalService) {
    }

    ngOnInit() {
        this.roomColors = this.tournamentDisplayService.getRoomColors();
        if (this.activatedRoute.parent) {
            this.activatedRoute.parent.params.subscribe((params: Params) => {
                this.tournamentPlanId = params['tournamentPlanId'];
                this.loadTournamentSlots();
            });
        }
    }

    loadTournamentSlots() {
        this.slots = undefined;
        this.selectedSlotIds = [];
        this.tournamentService.getTournamentSlotsDisplay(this.tournamentPlanId).subscribe(x => {
            x = x.sort((a: TournamentSlotDisplayDayModel, b: TournamentSlotDisplayDayModel) => {
                return new Date(a.day).getTime() - new Date(b.day).getTime();
            });
            this.calcMinHour(x);
            this.calcMaxHour(x);
            this.defineRoomOverview(x);

            this.slots = x;
            this.selectedSlotIds = [];

        });
    }

    openAddSlotsModal() {
        const initialState: ModalOptions = {
            backdrop: 'static',
            class: 'modal-xl',
            keyboard: false,
            initialState: {
                tournamentPlanId: this.tournamentPlanId,
            },
        };

        const bsModalRef = this.modalService.show(TournamentPlanningSlotsAddModalComponent, initialState);
        bsModalRef.content?.confirmed.subscribe(x => {
            this.loadTournamentSlots();
        });
    }

    openCopySlotsModal() {
        var slots: TournamentSlotDisplayModel[] = [];

        this.slots!.forEach(d => {
            d.rooms.forEach(r => r.slots.forEach(s => {
                if (this.selectedSlotIds.some(x => x == s.id)) slots.push(s);
            }));
        });

        const initialState: ModalOptions = {
            backdrop: 'static',
            class: 'modal-lg',
            keyboard: false,
            initialState: {
                tournamentPlanId: this.tournamentPlanId,
                slots: slots,
            },
        };

        const bsModalRef = this.modalService.show(TournamentPlanningSlotsCopyModalComponent, initialState);
        bsModalRef.content?.confirmed.subscribe(x => {
            this.loadTournamentSlots();
        });
    }

    openDeleteModal() {
        const initialstate: ModalOptions = {
            initialState: {
                body: this.translocoService.translate('Tournaments.PlanningData.DeleteSlots'),
            }
        }

        const bsModalRef = this.modalService.show(ConfirmationModalComponent, initialstate);

        bsModalRef.content!.confirmed.subscribe(() => {
            this.deleteSelectedSlots();
        })
    }

    deleteSelectedSlots() {
        var model = new TournamentSlotDeleteModel();
        model.slotIds = this.selectedSlotIds;

        this.tournamentService.deleteTournamentSlots(this.tournamentPlanId, model).subscribe(x => {
            this.slots = [];
            this.selectedSlotIds = [];
            this.loadTournamentSlots();
        });
    }

    calcMinHour(slots: TournamentSlotDisplayDayModel[]) {
        var min = 24

        slots.forEach(sl => {
            sl.rooms.forEach(x => {
                x.slots.forEach(s => {
                    var hours = new Date(s.from).getHours();
                    if (hours < min) min = hours;
                })
            });
        });

        if (min > 0) min -= 1;

        this.minHour = min;
    }

    calcMaxHour(slots: TournamentSlotDisplayDayModel[]) {
        var max = 0

        slots.forEach(sl => {
            sl.rooms.forEach(x => {
                x.slots.forEach(s => {
                    var hours = new Date(s.to).getHours();
                    if (hours > max) max = hours;
                })
            });
        });

        if (max < 24) max += 1;

        this.maxHour = max;
    }

    defineRoomOverview(slots: TournamentSlotDisplayDayModel[]) {

        var rooms: TournamentSlotRoomOverviewModel[] = [];

        slots.forEach(sl => {
            sl.rooms.forEach(r => {

                var room = rooms.find(x => x.imRoomId == r.imRoomId);
                if (room == null) {
                    room = new TournamentSlotRoomOverviewModel();
                    room.imFacilityId = r.imFacilityId;
                    room.imFacilityName = r.imFacilityName;
                    room.imRoomName = r.imRoomName;
                    room.imRoomId = r.imRoomId;
                    room.slotCount = 0;

                    rooms.push(room);
                }

                room.slotCount += r.slots.length;
            });
        });

        rooms = rooms.sort((a, b) => `${a.imFacilityName}${a.imRoomName}`.localeCompare(`${b.imFacilityName}${b.imRoomName}`))

        rooms.forEach((room, index) => {
            var colorIndex = index;

            while (colorIndex >= 10) {
                colorIndex -= 10;
            }

            room.color = this.roomColors[colorIndex];
        });

        this.rooms = rooms;

    }

    getRoomSlotsSum(): number {
        var sum = 0;

        if (this.rooms == undefined || this.rooms == null) return sum;

        this.rooms.forEach(x => {
            sum += x.slotCount;
        });

        return sum;
    }
}

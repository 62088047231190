import { Component, OnInit, Input } from '@angular/core';
import { BaseComponent } from '@premotec/ngx-essentials';
import { BsModalService } from 'ngx-bootstrap/modal';
import { MembershipApplicationsService } from 'src/app/membership-application/membership-applications.service';
import { environment } from 'src/environments/environment';
import { IframeModalComponent } from '../iframe-modal/iframe-modal.component';

@Component({
  selector: 'app-ma-file-list',
  templateUrl: './ma-file-list.component.html',
  styleUrls: ['./ma-file-list.component.scss']
})
export class MaFileListComponent extends BaseComponent implements OnInit {

  @Input() onlineMembershipConfigId: any;
  @Input() applicationId: any;
  @Input() applicationFiles: any[] = [];

  constructor(private applicationService: MembershipApplicationsService,  private modalService: BsModalService,) {
    super();
   }

  ngOnInit() {
  }

  downloadFile(file: any) {
    this.whileImAlive(this.applicationService.getConfigFileDownloadId(this.applicationId, file.id))
      .subscribe(res => {
        window.open(`${environment.api}/FileDownloads/${res.downloadId}`, '_blank')
    });
  }

  openIframeModal(id: any) {
      const initialState = {
        applicationId: this.applicationId,
        documentId: id
      };

      this.modalService.show(IframeModalComponent, {initialState, class: 'modal-lg'});
  }
}

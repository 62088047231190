<ng-template #rejectModal>
<div class="modal-header">
  <h4 class="modal-title float-start">
    <span>{{'Confirm' | transloco}}</span>
  </h4>
</div>
<div class="modal-body">
  <app-spinner *ngIf="isLoading" [margin]="'0 auto'"></app-spinner>
  <form [formGroup]="rejectionFormGroup" *ngIf="!isLoading">
    <div class="form-group" [ngClass]="{'has-error': !rejectionReason!.valid && submitted}">
      <label class="control-label" *ngIf="!body">{{'RejectionModal.PleaseEnterRejectionReason' | transloco}}</label>
      <label class="control-label" *ngIf="body">{{body}}</label>
      <textarea class="form-control" rows="3" formControlName="rejectionReason"></textarea>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button class="btn btn-default" [disabled]="isLoading" (click)="cancel()">{{'Cancel' | transloco }}</button>
  <button class="btn btn-primary" [disabled]="isLoading" (click)="reject()">
    <span *ngIf="declineText">{{declineText}}</span>
    <span *ngIf="!declineText">{{'Decline' | transloco }}</span>
  </button>
</div>
</ng-template>
import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import * as moment from 'moment';

export class CustomValidators {
  static dateMinimum(date: Date): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value == null) return null;

      const controlDate = moment(control.value);

      if (!controlDate.isValid()) return null;

      const validationDate = moment(date);

      return controlDate.isSameOrAfter(validationDate, 'D') ? null : {
        'date-minimum-error': true };
    };
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'facilityClosed'
})
export class FacilityClosedPipe implements PipeTransform {

  transform(fromTime: string, toTime: string): string {
    if (fromTime === '00:00:00' && toTime === '00:00:00') {
      return 'Geschlossen';
    }
    return `${fromTime.substring(0, 5)} - ${toTime.substring(0, 5)} Uhr`;
  }

}

<h2>{{ 'Facility_OpeningHours' | transloco }}</h2>

<div *ngIf="openingHours.openingHoursSports">
  <h3 class="font-big">Sportbetrieb</h3>
  <table class="table information-table bottom-space">
    <tr *ngFor="let displayedOpeningHours of displayOpeningHoursSports()">
      <td class="font-normal" style="width:auto">{{ ("Day_" + displayedOpeningHours.firstDay) | transloco }} <span *ngIf="displayedOpeningHours.secondDay">- {{ ("Day_" + displayedOpeningHours.secondDay) | transloco }}</span></td>
      <td class="font-normal nowrap" style="width:1px; white-space: nowrap">{{displayedOpeningHours.from | facilityClosed: displayedOpeningHours.to}}</td>
    </tr>
  </table>
</div>

<div *ngIf="openingHours.openingHoursCounter">
  <h3 class="font-big">Info-Desk</h3>
  <table class="table information-table">
    <tr *ngFor="let displayedOpeningHours of displayOpeningHoursCounter()">
      <td class="font-normal" style="width:auto">{{ ("Day_" + displayedOpeningHours.firstDay) | transloco }} <span *ngIf="displayedOpeningHours.secondDay">- {{ ("Day_" + displayedOpeningHours.secondDay) | transloco }}</span></td>
      <td class="font-normal nowrap" style="width:1px; white-space: nowrap">{{displayedOpeningHours.from | facilityClosed: displayedOpeningHours.to}}</td>
    </tr>
  </table>
</div>

<div *ngIf="!openingHours.openingHoursSports && !openingHours.openingHoursCounter">
  <p class="font-normal">{{ 'Facility_No_OpeningHours' | transloco }}</p>
</div>

import { Component, OnInit, Input, OnDestroy, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { FormGroup, FormBuilder, Validators, AbstractControl, FormControl, ValidatorFn } from '@angular/forms';
import { Account } from '../../account/account.model';
import { BaseComponent } from '@premotec/ngx-essentials';
import { AccountService } from '../../account/account.service';
import { CountryService } from '../../shared/services/country.service';
import { Country } from '../../shared/models/country.model';
import { AccountPut } from '../../account/account-put.model';
import { RegistrationGuardService } from '../../events/registration/registration-guard.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslocoService } from '@ngneat/transloco';
import { MembershipExtension } from 'src/app/memberships/models';
import { MembershipService } from 'src/app/memberships/membership.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

function phoneIsDefined(c: AbstractControl) {
  const phonePrivate = c.get('phonePrivate');
  const phoneOffice = c.get('phoneOffice');
  const mobile = c.get('mobile');

  if (phonePrivate?.value || phoneOffice?.value || mobile?.value) {
    return null;
  }

  return { 'phone': true };
}

@Component({
  selector: 'app-account-change-data-form',
  templateUrl: './account-change-data-form.component.html',
  styleUrls: ['./account-change-data-form.component.scss']
})
export class AccountChangeDataFormComponent extends BaseComponent implements OnInit, OnDestroy {

  @ViewChild('changeEmailModal') changeEmailModal: any;
  @ViewChild('changePasswordModal') changePasswordModal: any;

  @Input() passPhotoUploadEnabled?: boolean;
  @Input() passwordChangeEnabled?: boolean;
  @Input() validatePicture?: boolean;
  @Input() membershipExtension?: MembershipExtension;

  modalRef?: BsModalRef;

  accountForm!: FormGroup;
  public account!: Account;
  userData?: AccountPut;
  countries?: Country[];

  public currentPicture: any;

  showPictureUpload? = true;

  requiredPropertyIndicator = '*';

  constructor(private translocoService: TranslocoService,
    private titleService: Title,
    private accountService: AccountService,
    private membershipService: MembershipService,
    private countryService: CountryService,
    private modalService: BsModalService,
    private registrationGuardService: RegistrationGuardService,
    private fb: FormBuilder) {
    super();
  }

  ngOnInit() {
    this.translocoService.selectTranslate('UserData').subscribe(x => {
      this.titleService.setTitle(x);
    });

    this.whileImAlive(this.countryService.getCountries()).subscribe(data => {
      this.countries = data.sort((a, b) => {
        return a.nameLong.localeCompare(b.nameLong);
      });
    });

    this.getAcccountData(true);

    // if the component is used to extend a membership, try to load the new membership extension picture
    if (this.membershipExtension !== undefined && this.membershipExtension != null) {
      this.getCurrentMembershipExtensionPicture();
    } else {
      this.getCurrentPicture();
    }
  }

  override ngOnDestroy() {
    this.registrationGuardService.formIsDirty = false;
  }
  initForm() {

    let userEmail: string;

    if (this.account?.emailAsvz || this.account?.emailAai) {
      userEmail = this.account.emailAsvz || this.account.emailAai!;
    } else {
      userEmail = this.account!.emailPrivate!;
    }

    this.accountForm = this.fb.group({
      email: [{ value: userEmail, disabled: true }, [Validators.required, Validators.email, Validators.maxLength(250)]],
      street1: [this.account.street1, [Validators.required, Validators.maxLength(250)]],
      street2: [this.account.street2, Validators.maxLength(250)],
      postcode: [this.account.postcode, [Validators.required, Validators.maxLength(10)]],
      city: [this.account.city, [Validators.required, Validators.maxLength(50)]],
      country: [this.account.countryCode, [Validators.required, Validators.minLength(2)]],
      phoneGroup: this.fb.group({
        phonePrivate: [this.account.phonePrivate, Validators.maxLength(50)],
        phoneOffice: [this.account.phoneOffice, Validators.maxLength(50)],
        mobile: [this.account.mobile, Validators.maxLength(50)]
      }, { validator: phoneIsDefined })
    });

    // validate passphoto if needed
    if (this.validatePicture) {
      this.accountForm.addControl('picture', new FormControl(this.account!.hasPicture, [this.pictureValidator(this.account.passPhotoRequired!)]));
    }

    this.accountForm.statusChanges.subscribe(() => {
      this.registrationGuardService.formIsDirty = this.accountForm.dirty;
    })
  }

  pictureValidator(passPhotoRequired: boolean): ValidatorFn {
    // user has no picture => invalid
    return (currentControl: AbstractControl): { [key: string]: any } | null => {

      // check if picture ist required
      if (!passPhotoRequired) {
        // not required
        return null;
      }

      // check if user has a picture
      if (this.account.hasPicture) {
        // user has a picture
        return null;
      }

      // no picture => not valid
      return { missingPicture: 'missingPicture' };
    }
  }

  public updateValueAndValidityPictureControl() {
    // check if picture control exists
    if (this.accountForm.contains('picture')) {
      this.accountForm.get('picture')!.updateValueAndValidity();
    }
  }

  resetForm() {
    this.accountForm.reset();
    this.initForm();
  }

  hasRequiredError(formControlName: string): boolean {
    return this.accountForm.get(formControlName)!.errors!['required'];
  }

  formControlHasError(formControlName: string) {
    return this.accountForm.get(formControlName)!.errors;
  }

  emailIsInvalid(formControlName: string): boolean {
    return this.accountForm.get(formControlName)!.errors!['email'];
  }

  getForm(): FormGroup {
    return this.accountForm;
  }

  getUpdatedUserData(): AccountPut {
    return {
      street1: this.accountForm.get('street1')?.value,
      street2: this.accountForm.get('street2')?.value,
      postcode: this.accountForm.get('postcode')?.value,
      city: this.accountForm.get('city')?.value,
      country: this.accountForm.get('country')?.value,
      phonePrivate: this.accountForm.get('phoneGroup.phonePrivate')?.value,
      phoneOffice: this.accountForm.get('phoneGroup.phoneOffice')?.value,
      mobile: this.accountForm.get('phoneGroup.mobile')?.value,
    }
  }

  getCurrentPicture() {
    this.whileImAlive(this.accountService.getPicture()).subscribe(pictureBlob => {

      this.currentPicture = pictureBlob;

      this.showPictureUpload = this.passPhotoUploadEnabled;
    },
      (error: HttpErrorResponse) => {
        // user has no picture
        if (error.status === 404 && this.passPhotoUploadEnabled) {
          this.showPictureUpload = this.passPhotoUploadEnabled;
        }
      });
  }

  getCurrentMembershipExtensionPicture() {
    this.whileImAlive(this.membershipService.getMembershipExtensionPicture(this.membershipExtension!.id)).subscribe(pictureBlob => {
      this.currentPicture = pictureBlob;
      this.showPictureUpload = false;
    },
      (error: HttpErrorResponse) => {
        // user has no membership extension picture, load current picture
        this.getCurrentPicture();
      });
  }

  getAcccountData(initForm: boolean) {
    this.whileImAlive(
      this.accountService.getAccountDetails()
    ).subscribe((data) => {
      this.account = data;

      if (initForm) {
        this.initForm();
      }

      this.updateValueAndValidityPictureControl();
    });
  }

  passPhotoChanged() {
    this.getAcccountData(false);
    this.getCurrentPicture();
    AccountService.accountChangedEvent.emit(this.account);
  }

  showChangeEmailModal() {
    const modalConfig = {
      ignoreBackdropClick: true,
      keyboard: false,
    }

    this.modalRef = this.modalService.show(this.changeEmailModal, modalConfig);
  }

  showChangePasswordModal() {
    const modalConfig = {
      ignoreBackdropClick: true,
      keyboard: false,
    }

    this.modalRef = this.modalService.show(this.changePasswordModal, modalConfig);
  }

  closeChangeModal(): void {
    if (this.modalRef !== null && this.modalRef !== undefined) {
      this.modalRef.hide();
    }
  }
}


import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { debounceTime, delay, distinct, distinctUntilChanged, switchMap, tap } from 'rxjs';
import { HandHeldScannerService } from '../services/hand-held-scanner.service';
import { HandHeldPerson } from '../models/hand-held-person.model';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { HandHeldScannerResultModalComponent } from '../hand-held-scanner-result-modal/hand-held-scanner-result-modal.component';
import { HandHeldPersonDetailModalComponent } from '../hand-held-person-detail-modal/hand-held-person-detail-modal.component';
import { ActivatedRoute } from '@angular/router';
import { HandHeldScannerCheckType } from '../models/hand-held-scanner-check-type.enum';
import { HandHeldScannerValidatePostModel } from '../models/hand-held-scanner-validate-post.model';
import { HandHeldScannerValidateQrCodePost } from '../models/hand-held-scanner-validate-qr-code-post.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-hand-held-scanner-text',
  templateUrl: './hand-held-scanner-text.component.html',
  styleUrls: ['./hand-held-scanner-text.component.scss']
})
export class HandHeldScannerTextComponent implements OnInit {

  scannerFormControl = new FormControl<number|null>(null, Validators.required);
  filterFormControl = new FormControl('', {nonNullable: true});
  people: HandHeldPerson[] = [];
  isSearching = false;

  constructor(
    private handHeldScannerService: HandHeldScannerService,
    private modalService: BsModalService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.filterFormControl.valueChanges.pipe(
      tap(() => this.isSearching = true),
      distinctUntilChanged(),
      debounceTime(500),
    ).subscribe(val => {
      this.getPeople(val);
    });

    this.route.queryParams.subscribe((params) => {
      // set scanner
      if (params['selectedAccessSystemScannerId'] != null) {
          this.scannerFormControl.patchValue(+params['selectedAccessSystemScannerId']);
      }
  });
  }

  getPeople(filterValue: string) {
    if (filterValue.length == 0) {
      this.people = [];
      this.isSearching = false;
      return;
    }

    this.handHeldScannerService.getPeopleByFilter(filterValue).subscribe({
      next: (res) => 
      {
        this.people = res;
        this.isSearching = false;
      }
    });

  }

  openPersonDetailModal(person: HandHeldPerson) {
    if (this.scannerFormControl.invalid) return;

    let validateModel = new HandHeldScannerValidatePostModel();
    let validateQrModel = new HandHeldScannerValidateQrCodePost();
    validateQrModel.addressId = person.id;
    validateQrModel.dev = environment.production;
    validateModel.qrCode = validateQrModel;

    validateModel.checkOnly = true;
    validateModel.checkType = HandHeldScannerCheckType.Text;
    validateModel.scannerId = this.scannerFormControl.value!;

    const modalOptions: ModalOptions<Partial<HandHeldPersonDetailModalComponent>> = {
      backdrop: 'static',
      keyboard: false,
      class: 'hand-held-scanner-modal',
      initialState: {
        validateModel: validateModel,
        person: person,
        checkType: HandHeldScannerCheckType.Text,
        picturePostModel: {addressId: person.id}
      },
  };
  
    this.modalService.show(HandHeldPersonDetailModalComponent, modalOptions);

    
  }

}

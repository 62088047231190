// Source: https://msdn.microsoft.com/en-us/library/aa383887.aspx
export enum HttpResponseStatus {
    CODE_100_CONTINUE = 100,
    CODE_101_SWITCH_PROTOCOLS = 101,
    CODE_200_OK = 200,
    CODE_201_CREATED = 201,
    CODE_202_ACCEPTED = 202,
    CODE_203_PARTIAL = 203,
    CODE_204_NO_CONTENT = 204,
    CODE_205_RESET_CONTENT = 205,
    CODE_206_PARTIAL_CONTENT = 206,
    CODE_207_WEBDAV_MULTI_STATUS = 207,
    CODE_300_AMBIGUOUS = 300,
    CODE_301_MOVED = 301,
    CODE_302_REDIRECT = 302,
    CODE_303_REDIRECT_METHOD = 303,
    CODE_304_NOT_MODIFIED = 304,
    CODE_305_USE_PROXY = 305,
    CODE_307_REDIRECT_KEEP_VERB = 307,
    CODE_400_BAD_REQUEST = 400,
    CODE_401_DENIED = 401,
    CODE_402_PAYMENT_REQ = 402,
    CODE_403_FORBIDDEN = 403,
    CODE_404_NOT_FOUND = 404,
    CODE_405_BAD_METHOD = 405,
    CODE_406_NONE_ACCEPTABLE = 406,
    CODE_407_PROXY_AUTH_REQ = 407,
    CODE_408_REQUEST_TIMEOUT = 408,
    CODE_409_CONFLICT = 409,
    CODE_410_GONE = 410,
    CODE_411_LENGTH_REQUIRED = 411,
    CODE_412_PRECOND_FAILED = 412,
    CODE_413_REQUEST_TOO_LARGE = 413,
    CODE_414_URI_TOO_LONG = 414,
    CODE_415_UNSUPPORTED_MEDIA = 415,
    CODE_449_RETRY_WITH = 449,
    CODE_500_SERVER_ERROR = 500,
    CODE_501_NOT_SUPPORTED = 501,
    CODE_502_BAD_GATEWAY = 502,
    CODE_503_SERVICE_UNAVAIL = 503,
    CODE_504_GATEWAY_TIMEOUT = 504,
    CODE_505_VERSION_NOT_SUPPOPRTED = 505
}

<div class="modal-header text-light">
    <h3>{{'Tournaments.PlanningData.CreateSlots' | transloco}}</h3>
</div>

<div class="modal-body" *ngIf="state == SlotAddStateEnum.SelectFacility">
    <form *ngIf="tournamentSlotFacilityForm" [formGroup]="tournamentSlotFacilityForm">
        <div class="row">
            <div class="col-6">
                <h4>{{'Tournaments.PlanningData.Location.Select'| transloco}}</h4>
                <div class="mb-3">
                    <label for="facilityId" class="form-label">{{'Tournaments.PlanningData.Location.Facility' |
                        transloco}} *</label>
                    <div>
                        <ng-select formControlName="facilityId" id="facilityId" (change)="onFacilityChange()"
                            [readonly]="!tournamentFacilities">
                            <ng-option *ngFor="let facility of tournamentFacilities" [value]="facility.id">
                                {{facility.name}} ({{facility.rooms.length}})
                            </ng-option>
                        </ng-select>
                    </div>
                </div>
                <div class="mb-3">
                    <label for="roomId" class="form-label">{{'Tournaments.PlanningData.Location.Room' | transloco}}
                        *</label>
                    <div>
                        <ng-select formControlName="roomId" id="roomId"
                            [readonly]="!tournamentRooms || tournamentRooms.length <= 1">
                            <ng-option *ngFor="let room of tournamentRooms" [value]="room.id">
                                {{room.name}}
                            </ng-option>
                        </ng-select>
                    </div>
                </div>
                <div class="mb-3">
                    <label for="fieldId" class="form-label">
                        {{'Tournaments.PlanningData.Location.Field' | transloco}} *
                    </label>
                    <div>
                        <ng-select formControlName="fieldId" id="fieldId">
                            <ng-option [value]="0">
                                {{tournamentdisplayService.getFieldName(0)}}
                            </ng-option>

                            <ng-option *ngFor="let item of [].constructor(12); let i = index" [value]="i + 1">
                                {{tournamentdisplayService.getFieldName(i+1)}}
                            </ng-option>

                        </ng-select>
                    </div>
                </div>
                <button class="btn btn-primary" (click)="addLocation()" [disabled]="!canAddLocation()">
                    Ort hinzufügen
                </button>
            </div>

            <div class="col-6">
                <h4>{{'Tournaments.PlanningData.Location.Selected'| transloco}}</h4>
                <div class="card" *ngIf="locations && locations.length">
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item" *ngFor="let location of locations">
                            <div class="d-flex">
                                <div class="flex-grow-1">
                                    {{location.facilityName}} | {{location.roomName}} |
                                    <span>
                                        {{tournamentdisplayService.getFieldName(location.fieldId)}}
                                    </span>
                                </div>
                                <div>
                                    <button class="btn btn-sm btn-danger" (click)="removeLocation(location)">
                                        <i class="fa-solid fa-trash-can"></i>
                                    </button>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="alert alert-info m-3" *ngIf="!locations || !locations.length">
                    <p class="m-0">Es wurden noch keine Orte ausgewählt.</p>
                </div>
            </div>
        </div>
        <div class="alert alert-danger m-0 mt-3" *ngIf="!locations.length && formSubmitted">
            <p class="m-0" [innerHTML]="'Tournaments.PlanningData.Location.Required' | transloco">
            </p>
        </div>
    </form>
</div>

<div class="modal-body" *ngIf="state == SlotAddStateEnum.BasicSettings">

    <h4>{{'Tournaments.PlanningData.ReferenceDay.Title'| transloco}}</h4>

    <form *ngIf="tournamentSlotsForm" [formGroup]="tournamentSlotsForm">
        <div class="row">
            <div class="col-3 mb-3">
                <label for="startDate" class="form-label">{{'Tournaments.PlanningData.ReferenceDay.Date' |
                    transloco}} *</label>
                <div>
                    <pmt-datepicker [id]="'startDate'" formControlName="startDate">
                    </pmt-datepicker>
                </div>
            </div>
            <div class="col-3 mb-3">
                <label for="startTime" class="form-label">{{'Tournaments.PlanningData.ReferenceDay.Time' |
                    transloco}} *</label>
                <div>
                    <input type="text" class="form-control" id="startTime" formControlName="startTime"
                        placeholder="HH:MM">
                </div>
                <p class="text-danger m-0"
                    *ngIf="tournamentSlotsForm.controls['startTime'].hasError('pattern') && formSubmitted">
                    {{'TimeFormatHelp' | transloco}}</p>
            </div>
            <div class="col-2 mb-3">
                <label for="length" class="form-label">{{'Tournaments.PlanningData.ReferenceDay.Length' |
                    transloco}} *</label>
                <div>
                    <input type="number" min="1" class="form-control" id="length" formControlName="length">
                </div>
            </div>
            <div class="col-2 mb-3">
                <label for="breakLength" class="form-label">{{'Tournaments.PlanningData.ReferenceDay.BreakLength' |
                    transloco}}</label>
                <div>
                    <input type="number" min="0" class="form-control" id="breakLength" formControlName="breakLength">
                </div>
            </div>
            <div class="col-2 mb-3">
                <label for="count" class="form-label">{{'Tournaments.PlanningData.ReferenceDay.Count' |
                    transloco}} *</label>
                <div>
                    <input type="number" min="1" class="form-control" id="count" formControlName="count">
                </div>
            </div>
        </div>
    </form>

    <div class="alert alert-danger m-0 mb-3" *ngIf="!tournamentSlotsForm.valid && formSubmitted">
        <p class="m-0" [innerHTML]="'FillRequired' | transloco">
        </p>
    </div>

    <div class="alert alert-secondary m-0" role="alert">

        <p class="fw-bold" [innerHTML]="'Tournaments.PlanningData.ReferenceDay.SeriesDescription' | transloco">
        </p>

        <button type="button" class="btn btn-primary"
            (click)="createSeries()">{{'Tournaments.PlanningData.ReferenceDay.CreateSeries'
            |
            transloco}}</button>
    </div>


</div>

<div class="modal-body" *ngIf="state == SlotAddStateEnum.SeriesSettings">
    <form *ngIf="tournamentSlotSeriesForm" [formGroup]="tournamentSlotSeriesForm">
        <div class="row">
            <div class="col-6">
                <h4>{{'Tournaments.PlanningData.Series.Pattern'| transloco}}</h4>
                <div class="col-12 mb-3">
                    <div class="d-flex">
                        <div class="pe-3 border border-start-0 border-top-0 border-bottom-0">
                            <div class="form-check">
                                <input class="form-check-input" type="radio" formControlName="type" id="type_1"
                                    [value]="1" (change)="seriesTypeUpdated()">
                                <label class="form-check-label" for="type_1">
                                    {{'Tournaments.PlanningData.Series.Daily' | transloco}}
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" formControlName="type" id="type_2"
                                    [value]="2" (change)="seriesTypeUpdated()">
                                <label class="form-check-label" for="type_2">
                                    {{'Tournaments.PlanningData.Series.Weekly' | transloco}}
                                </label>
                            </div>
                        </div>
                        <div class="flex-grow-1 ps-3">

                            <ng-container *ngIf="tournamentSlotSeriesForm.controls['type'].value == '1'">
                                <label for="dayInterval" class="form-label">
                                    {{'Tournaments.PlanningData.Series.DayInterval' | transloco}} *
                                </label>
                                <div>
                                    <input type="number" min="1" class="form-control" id="dayInterval"
                                        formControlName="dayInterval">
                                </div>
                            </ng-container>

                            <ng-container *ngIf="tournamentSlotSeriesForm.controls['type'].value == '2'">
                                <div class="mb-3">
                                    <label for="weekInterval" class="form-label">
                                        {{'Tournaments.PlanningData.Series.WeekInterval' | transloco}} *
                                    </label>
                                    <div>
                                        <input type="number" min="1" class="form-control" id="weekInterval"
                                            formControlName="weekInterval">
                                    </div>
                                </div>

                                <label class="form-label">{{'Tournaments.PlanningData.Series.Weekdays' |
                                    transloco}}</label>

                                <div class="">
                                    <div class="form-check form-switch">
                                        <input class="form-check-input" type="checkbox" id="weekMon"
                                            formControlName="weekMon">
                                        <label class="form-check-label" for="weekMon">{{'Day_mon' | transloco}}</label>
                                    </div>
                                    <div class="form-check form-switch">
                                        <input class="form-check-input" type="checkbox" id="weekTue"
                                            formControlName="weekTue">
                                        <label class="form-check-label" for="weekTue">{{'Day_tue' | transloco}}</label>
                                    </div>
                                    <div class="form-check form-switch">
                                        <input class="form-check-input" type="checkbox" id="weekWed"
                                            formControlName="weekWed">
                                        <label class="form-check-label" for="weekWed">{{'Day_wed' | transloco}}</label>
                                    </div>
                                    <div class="form-check form-switch">
                                        <input class="form-check-input" type="checkbox" id="weekThu"
                                            formControlName="weekThu">
                                        <label class="form-check-label" for="weekThu">{{'Day_thu' | transloco}}</label>
                                    </div>
                                    <div class="form-check form-switch">
                                        <input class="form-check-input" type="checkbox" id="weekFri"
                                            formControlName="weekFri">
                                        <label class="form-check-label" for="weekFri">{{'Day_fri' | transloco}}</label>
                                    </div>
                                    <div class="form-check form-switch">
                                        <input class="form-check-input" type="checkbox" id="weekSat"
                                            formControlName="weekSat">
                                        <label class="form-check-label" for="weekSat">{{'Day_sat' | transloco}}</label>
                                    </div>
                                    <div class="form-check form-switch">
                                        <input class="form-check-input" type="checkbox" id="weekSun"
                                            formControlName="weekSun">
                                        <label class="form-check-label" for="weekSun">{{'Day_sun' | transloco}}</label>
                                    </div>
                                </div>

                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <h4>{{'Tournaments.PlanningData.Series.End'| transloco}}</h4>
                <div class="col-12 mb-3">
                    <div class="d-flex">
                        <div class="pe-3 border border-start-0 border-top-0 border-bottom-0">
                            <div class="form-check">
                                <input class="form-check-input" type="radio" formControlName="endType" id="endType_1"
                                    [value]="1" (change)="seriesEndTypeUpdated()">
                                <label class="form-check-label" for="endType_1">
                                    {{'Tournaments.PlanningData.Series.EndCount' | transloco}}
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" formControlName="endType" id="endType_2"
                                    [value]="2" (change)="seriesEndTypeUpdated()">
                                <label class="form-check-label" for="endType_2">
                                    {{'Tournaments.PlanningData.Series.EndDate' | transloco}}
                                </label>
                            </div>
                        </div>
                        <div class="flex-grow-1 ps-3">

                            <ng-container *ngIf="tournamentSlotSeriesForm.controls['endType'].value == '1'">
                                <label for="endIntervalCount" class="form-label">
                                    {{'Tournaments.PlanningData.Series.EndIntervalCount' | transloco}} *
                                </label>
                                <div>
                                    <input type="number" min="1" class="form-control" id="endIntervalCount"
                                        formControlName="endIntervalCount">
                                </div>
                            </ng-container>

                            <ng-container *ngIf="tournamentSlotSeriesForm.controls['endType'].value == '2'">
                                <label for="endIntervalDate" class="form-label">
                                    {{'Tournaments.PlanningData.Series.EndIntervalDate' | transloco}} *
                                </label>
                                <div>
                                    <pmt-datepicker [id]="'endIntervalDate'" formControlName="endIntervalDate">
                                    </pmt-datepicker>
                                </div>

                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <div class="alert alert-danger m-0 mb-3" *ngIf="!tournamentSlotSeriesForm.valid && formSubmitted">
        <p class="m-0" [innerHTML]="'FillRequired' | transloco">
        </p>
    </div>
</div>

<div class="modal-body" *ngIf="state == SlotAddStateEnum.Overview">
    <h4>{{'Tournaments.PlanningData.Overview.Title'| transloco}}</h4>

    <div style="max-height: 50vh; overflow-x: hidden; overflow-y: auto;">

        <div class="row" *ngFor="let location of getAllSlotLocations()">
            <h5>
                {{location.facilityName}} | {{location.roomName}} |
                <span>
                    {{tournamentdisplayService.getFieldName(location.fieldId)}}
                </span>
            </h5>
            <hr>
            <div class="col-2 mb-3"
                *ngFor="let day of getAllSlotDays(location.facilityId, location.roomId, location.fieldId)">
                <b>{{'Day_' + day.getDay() | transloco}}, {{day | date: 'dd.MM.yyyy'}}</b>

                <div
                    *ngFor="let slot of getAllSlotFromDay(day, location.facilityId, location.roomId, location.fieldId)">
                    <span *ngIf="slot.validationType ==TournamentSlotValidationTypeEnum.Valid" class="text-success">
                        <i class="fa-solid fa-circle-check"
                            [tooltip]="'Tournaments.PlanningData.Status.Valid' | transloco"></i></span>
                    <span *ngIf="slot.validationType ==TournamentSlotValidationTypeEnum.Overlapping" class="text-danger"
                        [tooltip]="'ASDF'">
                        <i [tooltip]="'Tournaments.PlanningData.Status.Overlapping' | transloco"
                            class="fa-solid fa-circle-xmark"></i></span>
                    <span *ngIf="slot.validationType ==TournamentSlotValidationTypeEnum.OverlappingClosing"
                        [tooltip]="'ASDF'" class="text-danger">
                        <i class="fa-solid fa-circle-xmark"
                            [tooltip]="'Tournaments.PlanningData.Status.OverlappingClosing' | transloco"></i></span>
                    {{slot.from| date : 'HH:mm'}} - {{slot.to| date : 'HH:mm'}}
                </div>
            </div>
        </div>
    </div>
    <h4>{{'Tournaments.PlanningData.Legend' | transloco}}</h4>
    <div class="text-success">
        <i class="fa-solid fa-circle-check"></i> {{'Tournaments.PlanningData.Status.Valid' | transloco}}
    </div>
    <div class="text-danger">
        <i class="fa-solid fa-circle-exclamation"></i> {{'Tournaments.PlanningData.Status.Overlapping' |
        transloco}}
    </div>
    <div class="text-danger">
        <i class="fa-solid fa-circle-xmark"></i> {{'Tournaments.PlanningData.Status.OverlappingClosing' |
        transloco}}
    </div>
</div>

<div class="modal-footer text-light">
    <div class="w-100 d-flex">
        <button type="button" class="btn btn-link" (click)="back()"
            *ngIf="state != SlotAddStateEnum.SelectFacility">{{'Back' | transloco}}</button>

        <div class="flex-grow-1"> </div>

        <button type="button" class="btn btn-default ms-2" (click)="decline()" [disabled]="isLoading">
            {{'Cancel' | transloco}}
        </button>
        <button type="submit" class="btn btn-success ms-2" (click)="checkCreate()"
            *ngIf="state == SlotAddStateEnum.BasicSettings" [disabled]="isLoading">
            {{'Check' | transloco}}
        </button>
        <button type="submit" class="btn btn-success ms-2" (click)="create()" *ngIf="state == SlotAddStateEnum.Overview"
            [disabled]="isLoading">
            {{'Add' | transloco}}
        </button>
        <button type="submit" class="btn btn-success ms-2" (click)="next()"
            *ngIf="state == SlotAddStateEnum.SelectFacility || state == SlotAddStateEnum.SeriesSettings"
            [disabled]="isLoading">
            {{'Next' | transloco}}
        </button>
    </div>
</div>
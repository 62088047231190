<div *ngIf="viewDateIntern">
    <div class="card">
        <div class="card-header">
            <b>{{'Day_' + viewDateIntern.getDay() | transloco}}, {{viewDateIntern |date : 'dd.MM.yyyy'}}</b>
        </div>
        <div class="card-body">
            <div [ngStyle]="{'width.px': width}">
                <mwl-tournament-slot-calender [viewDate]="viewDateIntern" [events]="events" [users]="users"
                    [selectedSlotIds]="selectedSlotIds" [dayStartHour]="minHour" [dayEndHour]="maxHour">
                </mwl-tournament-slot-calender>
            </div>
        </div>
    </div>
</div>
<li class="mb-2 cursor-pointer">
    <a *ngIf="!useHref"
      [routerLink]="routerLinkInput"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{exact:false}"
      class="nav-link text-light" 
      [tooltip]="collapsedSidebar ? (translocoText | transloco) : ''" 
      placement="bottom">
        <i class="{{iconCode}}"> </i> 
        <span [ngStyle]="{'margin-left': collapsedSidebar ? '0rem' : '0.6rem'}" *ngIf="!collapsedSidebar">{{ translocoText | transloco }}</span>
    </a>

    <a *ngIf="useHref"
      href="{{hrefLinkInput}}"
      target="_blank"
      class="nav-link text-light" 
      [tooltip]="collapsedSidebar ? (translocoText | transloco) : ''" 
      placement="bottom">
        <i class="{{iconCode}}"> </i> 
        <span [ngStyle]="{'margin-left': collapsedSidebar ? '0rem' : '0.6rem'}" *ngIf="!collapsedSidebar">{{ translocoText | transloco }}</span>
    </a>
  </li>
<ng-container>
  <div class="row">
    <div class="col-sm-3">
      <dl>
        <dt>{{'Sport' | transloco}}</dt>
        <dd>{{sportName}}</dd>
      </dl>
    </div>
    <div class="col-sm-3">
      <dl>
        <dt>{{'Title' | transloco}}</dt>
        <dd>{{title}}</dd>
      </dl>
    </div>
    <div class="col-sm-3">
      <dl>
        <dt>{{'DateTime' | transloco}}</dt>
        <dd>{{starts | fromToDate: ends : 'DD.MM.YYYY': false}}</dd>
      </dl>
    </div>
    <div class="col-sm-3">
      <dl>
        <dt>{{'Instructors' | transloco}}</dt>
        <dd *ngFor="let instructor of instructors" class="mb-0">
          {{instructor.name}}
        </dd>
      </dl>
    </div>
  </div>
  <div class="row" *ngIf="this._liveStream">
    <div class="col-sm-12" *ngIf="this.safeUrl">
      <div style="padding:56.25% 0 0 0;position:relative;">
        <iframe [src]="this.safeUrl" frameborder="0" *ngIf="!hasEnded && !showStillImage"
          allow="autoplay; fullscreen; picture-in-picture" allowfullscreen
          style="position:absolute;top:0;left:0;width:100%;height:100%;">
        </iframe>
        <div *ngIf="showStillImage && !hasEnded" style="position:absolute;top:0;left:0;width:100%;height:100%;display: block;">
          <img style="height: 100%; width: 100%; object-fit: cover;" [src]="this.safeUrlStillImage">
        </div>
      </div>
    </div>
    <div *ngIf="!this.safeUrl" class="col-sm-12">
      <app-spinner></app-spinner>
    </div>
  </div>
</ng-container>

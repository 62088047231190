import { Component, OnInit } from '@angular/core';
import { HandHeldScannerService } from '../services/hand-held-scanner.service';
import { CheckOutValidationHistory } from '../models/check-out-validation-history.model';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '@premotec/ngx-essentials';
import { HandHeldPerson } from '../models/hand-held-person.model';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { HandHeldPersonDetailModalComponent } from '../hand-held-person-detail-modal/hand-held-person-detail-modal.component';
import { TranslocoService } from '@ngneat/transloco';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs';

@Component({
  selector: 'app-checkout-validation-history',
  templateUrl: './checkout-validation-history.component.html',
  styleUrls: ['./checkout-validation-history.component.scss']
})
export class CheckoutValidationHistoryComponent extends BaseComponent implements OnInit {
  checkoutValidationHistories?: CheckOutValidationHistory[];
  imFacilityId?: number;
  isLoading = false;

  constructor(
    private handHeldScannerService: HandHeldScannerService,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private toastrService: ToastrService,
    private translateService: TranslocoService,) {
    super();
  }

  ngOnInit(): void {
    this.whileImAlive(this.route.queryParams).subscribe((params) => {
      // set scanner
      if (params['selectedAccessSystemScannerId'] != null) {
        this.imFacilityId = +params['selectedAccessSystemScannerId'];
        this.getData();
      }
    });

    this.whileImAlive(this.handHeldScannerService.newValidationTriggered).subscribe(() => this.getData());
  }

  getData(): void {
    if (this.imFacilityId) {
      this.isLoading = true;
      this.handHeldScannerService
        .getCheckoutValidationHistory(this.imFacilityId)
        .pipe(
          map((res) => {
            return res.sort((a, b) => {
              return new Date(b.createDate).getTime() - new Date(a.createDate).getTime();
            });
          })
        )
        .subscribe({
        next: (res: CheckOutValidationHistory[]) => {

          this.checkoutValidationHistories = res;
          this.isLoading = false;
        },
        error: () => {
          this.toastrService.error(this.translateService.translate('Msg.Error'));
        }
      });
    }
  }

  openPersonDetailModal(history: CheckOutValidationHistory) {
    if (history.person == null) return;


    const modalOptions: ModalOptions<Partial<HandHeldPersonDetailModalComponent>> = {
      backdrop: 'static',
      keyboard: false,
      class: 'hand-held-scanner-modal',
      initialState: {
        accessSystemScannerId: history.imFacilityId,
        person: history.person,
        runValidation: false,
        checkOutValidationHistory: history,
        checkType: history.checkType,
        picturePostModel: { addressId: history.person.id }
      },
    };

    this.modalService.show(HandHeldPersonDetailModalComponent, modalOptions);


  }
}

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { ToastrService } from 'ngx-toastr';
import { MembershipService } from '../../membership.service';
import { LastSchoolModel } from '../../models/last-school.model';

@Component({
    selector: 'app-membership-check-past-school',
    templateUrl: './membership-check-past-school.component.html',
    styleUrls: ['./membership-check-past-school.component.scss']
})
export class MembershipCheckPastSchoolComponent implements OnInit {

    selectItems!: LastSchoolModel[];
    formControl: FormControl = this.fb.control(null, Validators.required);
    submitted = false;
    loading = false;

    @Output() lastSchoolChanged = new EventEmitter<number | undefined>();

    constructor(
        private translateService: TranslocoService,
        private toastr: ToastrService,
        private fb: FormBuilder,
        private membershipService: MembershipService,
    ) { }

    ngOnInit(): void {
        this.loadData();
    }

    loadData(): void {
        this.loading = true;
        this.membershipService.getAvailableLastSchools().subscribe({
            next: x => this.selectItems = x,
            complete: () => this.loading = false
        })
    }

    validate(): void {
        this.submitted = true;
        this.formControl.markAsDirty();
    }

    isValid(): boolean {
        if(!this.submitted) return false;
        return this.formControl.valid;
    }

    getValue(): number {
        return this.formControl.value as number;
    }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {paymentRoutes} from './payment.routes';
import {PaymentRedirectionComponent} from './payment-redirection/payment-redirection.component';
import {SharedModule} from '../shared/shared.module';
import {InvoiceService} from './invoice.service';
import { PaymentProcessingComponent } from './payment-processing/payment-processing.component';
import { CheckoutPaymentProcessingComponent } from './checkout-payment-processing/checkout-payment-processing.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forRoot(paymentRoutes)
  ],
  declarations: [
    PaymentRedirectionComponent,
    PaymentProcessingComponent,
    CheckoutPaymentProcessingComponent,
  ],
  exports: [
    RouterModule
  ],
  providers: [
    InvoiceService
  ]
})
export class PaymentModule {
}

<nav class="navbar navbar-expand-xxl fixed-top bg-white pt-3">
  <div *ngIf="!isProductionEnvironment" style="background-color: red; height: 5px;"></div>
  <div class="container-fluid">
    <a class="navbar-brand" href="https://asvz.ch" appExternalUrl><img src="assets/img/logo-white-on-blue.jpg"
        style="height: 40px;"></a>

    <button class="navbar-toggler btn btn-light" type="button" data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
      aria-label="Toggle navigation" (click)="collapse = !collapse">
      <i class="fa-solid fa-bars navbar-toggle-icon"></i>
    </button>

    <div class="collapse navbar-collapse" [ngClass]="{'show': !collapse}" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item top-level">
          <a href="https://asvz.ch/sportfahrplan" appExternalUrl (click)="closeMenu()"><i
              class="fa-solid fa-magnifying-glass"></i> Suchen</a>
        </li>
        <li class="nav-item  top-level" *ngIf="isLoggedIn()">
          <a routerLink="/my-lessons" (click)="closeMenu()"><i class="fa-regular fa-clock"></i> {{'Lessons' |
            transloco}}</a>
        </li>

        <li class="nav-item  top-level" routerLinkActive="active" *ngIf="isLoggedIn()">
          <a [routerLink]="'my-registrations'" (click)="closeMenu()"><i class="fa fa-list"></i> {{'CourseCampEvents' |
            transloco}}</a>
        </li>

        <li class="nav-item  top-level" routerLinkActive="active" *ngIf="isLoggedIn()">
          <a [routerLink]="'waiting-list/overview'" (click)="closeMenu()"><i class="fa fa-flag"></i> {{'WaitingList' |
            transloco}}</a>
        </li>

        <li class="nav-item  top-level" routerLinkActive="active" *ngIf="isLoggedIn() && (isNavFacilityManagementVisible)">
          <a [routerLink]="'handheldscanner/qr'" (click)="closeMenu()"><i class="fas fa-qrcode"></i> {{'Navigation.Houseservice.Handheldscanner' |
            transloco}}</a>
        </li>

        <li class="nav-item  top-level" routerLinkActive="active"
          *ngIf="isLoggedIn() && (isNavOfficeVisible)">
          <a (click)="activateAdminMode()" style="cursor: pointer;"><i class="fa fa-gear"></i> {{'Adminmode' |
            transloco}}</a>
        </li>

        <li class="nav-item  top-level" routerLinkActive="active" *ngIf="isLoggedIn() && isNavTLToolVisible">
          <a href="{{toolTapUrl}}" target="blank" (click)="closeMenu()"><i class="fas fa-exclamation skew"></i> {{'TlToolTab' |
            transloco}}</a>
        </li>

        <li class="nav-item top-level" routerLinkActive="active"
          *ngIf="isLoggedIn() && account && account.hasPrintCard && !account.hasPicture && router.url != '/account/user-data'">
          <a style="background-color: rgb(251, 215, 157); color: rgb(94, 58, 0) !important;" [routerLink]="'account/user-data'" (click)="closeMenu()"><i
              class="fa-solid fa-user"></i> {{'PassportPhotoUpload' | transloco}}</a>
        </li>

      </ul>
      <div class="d-flex">
        <ul class=" navbar-nav me-auto mb-2 mb-lg-0" role="navigation">
          <!-- user is not logged in -->
          <li class="nav-item top-level" *ngIf="!isLoggedIn()">
            <a style="cursor:pointer" [routerLink]="" (click)="login();" title="{{'Login' | transloco}}"
              role="menuitem"><i class="fa fa-sign-in"></i> {{'Login' | transloco }}</a>
          </li>
          <li class="dropdown top-level" dropdown *ngIf="isLoggedIn()">
            <a style="cursor:pointer" dropdownToggle class="nav-item top-level">
              <i class="fa-solid fa-user"></i> {{userName}} <i class="fa fa-chevron-down"></i></a>
            <ul *dropdownMenu class="dropdown-menu" role="menu">
              <li role="menuitem"><a class="dropdown-item" routerLink="account/user-data">{{'UserData' | transloco}}</a>
              </li>
              <li *ngIf="hasNewRelease()">
                <hr class="dropdown-divider">
              </li>
              <li *ngIf="hasNewRelease()" role="menuitem"><a class="dropdown-item"
                  (click)="reloadNewRelease()">{{'AppAssembly.RefreshApplication' |
                  transloco}}</a></li>
              <li>
                <hr class="dropdown-divider">
              </li>
              <li role="menuitem"><a class="dropdown-item" [routerLink]="'logout'"><i class="fa fa-sign-out"></i>
                  Logout</a></li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>
<div class="alert alert-primary">
  <strong>{{'StateMessages.State_'+registrationSummary.status+'.Name' | transloco}}</strong>
  <br>{{'RegistrationTemporary' | transloco}}


  <p style="margin-top: 10px;">
    <button (click)="refreshTimer()"
            type="button"
            class="btn btn-default btn-sm"
            [disabled]="refreshingTimerSubmitted"
            [tooltip]="'ResetTimerBtnTooltip' | transloco">
      <i class="fa fa-refresh"></i><span style="margin-left: 1rem;">{{registrationExpirationTimerDisplay}}</span>
    </button>
  </p>
  <p><span class="text-justify">{{'ResetTimerInfo' | transloco}}</span></p>
</div>

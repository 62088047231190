import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { TournamentService } from '../services/tournament.service';
import { TournamentDisplayService } from '../services/tournament-display.service';
import { TournamentTeamGamesModel } from '../models/tournament-team-games.model';
import { TournamentTeamGameModel } from '../models/tournament-team-game.model';
import { TournamentGameResultModalComponent } from '../tournament-game-result-modal/tournament-game-result-modal.component';
import { ToastrService } from 'ngx-toastr';
import { TranslocoService } from '@ngneat/transloco';
import * as moment from 'moment';

@Component({
    selector: 'app-tournament-team-games',
    templateUrl: './tournament-team-games.component.html',
    styleUrls: ['./tournament-team-games.component.scss']
})
export class TournamentTeamGamesComponent implements OnInit {

    tournamentPlanId!: string;
    teamId!: string;
    secret!: string;

    teamGames!: TournamentTeamGamesModel;
    filteredGames!: TournamentTeamGameModel[];

    showPast = false;

    constructor(
        public tournamentDisplayService: TournamentDisplayService,
        private tournamentService: TournamentService,
        private modalService: BsModalService,
        private activatedRoute: ActivatedRoute,
        private toastrService: ToastrService,
        private translocoService: TranslocoService,
    ) {
    }

    ngOnInit(): void {
        // get params
        this.activatedRoute.params.subscribe((params: Params) => {

            this.tournamentPlanId = params['tournamentPlanId'];
            this.teamId = params['teamId'];
            this.secret = params['secret'];

            // get secret
            this.activatedRoute.queryParams.subscribe((params: Params) => {
                this.secret = params['secret'];
                this.loadTeamGames();
            });
        });
    }

    loadTeamGames() {
        this.tournamentService.getTournamentTeamGames(this.tournamentPlanId, this.teamId, this.secret).subscribe({
            next: (teamDataModel: TournamentTeamGamesModel) => {
                this.teamGames = teamDataModel;
                this.filterGames();
            },
            error: (error) => {

            }
        });
    }

    isDateTodayOrPast(date: Date): boolean {
        var checkDate = moment(date);
        if (checkDate.isSame(new Date(), "day")) return true;
        if (checkDate.diff(new Date()) < 0) return true;
        return false;
    }

    filterGames() {
        if (this.teamGames == undefined) return;
        var currentDate = new Date();

        var games = this.teamGames.games.sort((a, b) => new Date(a.gameFrom).getTime() - new Date(b.gameFrom).getTime());

        if (!this.showPast) {
            games = games.filter(x => new Date(x.gameTo) > currentDate);
        }

        this.filteredGames = games;
    }

    openResultModal(gameId: string) {
        const initialState: ModalOptions = {
            backdrop: 'static',
            keyboard: false,
            initialState: {
                gameId: gameId,
                tournamentPlanId: this.tournamentPlanId,
                teamSecret: this.secret,
            },
        };

        const bsModalRef = this.modalService.show(TournamentGameResultModalComponent, initialState);
        bsModalRef.content?.confirmed.subscribe(x => {
            this.loadTeamGames();
            if (x) {
                this.toastrService.success(this.translocoService.translate('Tournaments.Teams.TeamGames.EnterResultSuccess'));
            } else {
                this.toastrService.error(this.translocoService.translate('Tournaments.Teams.TeamGames.EnterResultError'));
            }
        });
    }
}

import {Routes} from '@angular/router';
import {PaymentRedirectionComponent} from './payment-redirection/payment-redirection.component';
import {PaymentProcessingComponent} from './payment-processing/payment-processing.component';
import { CheckoutPaymentProcessingComponent } from './checkout-payment-processing/checkout-payment-processing.component';

export const paymentRoutes: Routes = [
  {
    path: 'invoice',
    children: [
      {path: ':invoiceId/payment', component: PaymentRedirectionComponent},
      {path: ':invoiceId/paymentProcessing', component: PaymentProcessingComponent},
      {path: ':invoiceId/cancelPayment', component: PaymentProcessingComponent},
      {path: ':invoiceId/checkout/paymentProcessing', component: CheckoutPaymentProcessingComponent},
    ]
  }
];

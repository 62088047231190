<div class="d-flex flex-column flex-shrink-0 p-3 text-light bg-blue custom-sidebar" [ngClass]="collapsedSidebar ? 'sidebar-collapsed-width' : 'sidebar-width'" pmtAutoWindowHeight>
    <ul class="nav nav-pills flex-column mb-auto">

      <!-- Office -->
      <app-section [collapsedSidebar]="collapsedSidebar" title="Office" iconCode="fas fa-university fa-xs" collapseType="office" *ngIf="isNavOfficeVisible">
        <app-link-item
          routerLinkInput="office/users"
          [translocoText]="'Users'"
          iconCode="fas fa-users"
          [collapsedSidebar]="collapsedSidebar">
        </app-link-item>
        <app-link-item
          routerLinkInput="office/refunds"
          [translocoText]="'Navigation.Office.Refunds'"
          iconCode="fas fa-undo"
          [collapsedSidebar]="collapsedSidebar">
        </app-link-item>
        <app-link-item
          routerLinkInput="office/facilities"
          [translocoText]="'Navigation.Office.Facilities'"
          iconCode="fas fa-building"
          [collapsedSidebar]="collapsedSidebar">
        </app-link-item>
        <app-link-item
          routerLinkInput="office/occupancyplanviews"
          [translocoText]="'Navigation.Office.OccupancyPlanViews'"
          iconCode="fas fa-map"
          [collapsedSidebar]="collapsedSidebar">
        </app-link-item>
        <app-link-item
          routerLinkInput="office/membership-applications/current/online"
          [translocoText]="'Office.NewMembershipApplications'"
          iconCode="fas fa-id-card"
          [collapsedSidebar]="collapsedSidebar">
        </app-link-item>
        <app-link-item
          routerLinkInput="office/maintenance"
          [translocoText]="'Navigation.Office.Maintenance'"
          iconCode="fas fa-bullhorn"
          [collapsedSidebar]="collapsedSidebar">
        </app-link-item>
      </app-section>

      <!-- Admin -->
      <app-section [collapsedSidebar]="collapsedSidebar" title="Admin" iconCode="fas fa-gear fa-xs" collapseType="admin" *ngIf="isNavOfficeVisible || isNavAdminVisible || isNavTLToolVisible">
        <app-link-item *ngIf="isNavAdminVisible"
          [hrefLinkInput]="hangfireUrl"
          [useHref]="true"
          [translocoText]="'Navigation.Hangfire'"
          iconCode="fas fa-cubes"
          [collapsedSidebar]="collapsedSidebar">
        </app-link-item>
        <app-link-item *ngIf="isNavOfficeVisible"
          [hrefLinkInput]="papercutUrl"
          [useHref]="true"
          [translocoText]="'Navigation.Office.Mails'"
          iconCode="fas fa-envelope"
          [collapsedSidebar]="collapsedSidebar">
        </app-link-item>
      </app-section>

      <!-- Turnierplanung -->
      <app-section [collapsedSidebar]="collapsedSidebar" title="Turnierplanung" iconCode="fas fa-trophy fa-xs" collapseType="admin">
        <app-link-item  *ngIf="isNavOfficeVisible"
          routerLinkInput="tournaments/admin"
          [translocoText]="'Tournaments.Management'"
          iconCode="fas fa-gear"
          [collapsedSidebar]="collapsedSidebar">
        </app-link-item>
      </app-section>
      </ul>
  
    <div [ngClass]="!collapsedSidebar ? 'custom-position' : 'custom-position-collapsed'">
      <hr>
      <a (click)="toggleNavbar()" class="cursor-pointer text-light text-center" style="text-decoration: none;">
        <div *ngIf="!collapsedSidebar">
          <i class="fas fa-chevron-left custom-margin-right"></i>
          <i class="fas fa-chevron-left"></i>
          <span *ngIf="!collapsedSidebar"> Einklappen</span>
        </div>
        <div *ngIf="collapsedSidebar">
          <i class="fas fa-chevron-right custom-margin-right"></i>
          <i class="fas fa-chevron-right"></i>
        </div>
      </a>
    </div>
  </div>
import { Component, OnInit, TemplateRef, Input } from '@angular/core';
import { FormBuilder, Validators, FormGroup, AbstractControl } from '@angular/forms';
import { UsersService } from '../../users.service';
import { BaseComponent } from '@premotec/ngx-essentials';
import { User } from '../../models';
import { UserPasswordPut } from '../../models/user-password-put.model';
import { TranslocoService } from '@ngneat/transloco';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

function passwordsAreEqual(c: AbstractControl) {
  const password = c.get('password');
  const passwordConfirm = c.get('passwordConfirm');

  if (password!.value === passwordConfirm!.value) {
    return null;
  }

  return { 'password': true};
}

@Component({
  selector: 'app-user-set-password-modal',
  templateUrl: './user-set-password-modal.component.html',
  styleUrls: ['./user-set-password-modal.component.scss']
})
export class UserSetPasswordModalComponent extends BaseComponent implements OnInit {

  @Input() user!: User;
  passwordFormGroup!: FormGroup;
  submitted = false;

  modalRef!: BsModalRef;

  labelChangePassword: any;

  constructor(private modalService: BsModalService, private fb: FormBuilder, private translocoService: TranslocoService, private userService: UsersService) {
    super();
  }

  ngOnInit() {
    this.passwordFormGroup = this.fb.group({
      'password': ['', [Validators.required, Validators.minLength(8), Validators.maxLength(100)]],
      'passwordConfirm': ['', [Validators.required, Validators.minLength(8), Validators.maxLength(100)]]
    }, { validator: passwordsAreEqual});

    this.labelChangePassword = this.translocoService.translate('Save');
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  savePassword() {
    this.submitted = true;
    if (!this.passwordFormGroup.invalid) {
      const passwordPut: UserPasswordPut = {
        newPassword: this.passwordFormGroup.get('password')?.value
      };

      this.whileImAlive(this.userService.setUserPassword(this.user.id, passwordPut)
      ).subscribe(res => {
        this.modalRef.hide();
      });
    } else {
      console.log('invalid');
    }
  }
}

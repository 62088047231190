import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../shared/shared.module';
import { SportsService } from './sports.service';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],
  declarations: [
  ],
  providers: [
    SportsService
  ]
})
export class SportsModule {
}

import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, AsyncSubject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { OpeningHours } from '../models/opening-hours.model';
import { HttpResponseErrorHandlerService } from 'src/app/shared/services/http-response-error-handler.service';
import { SportSimpleInfo } from 'src/app/sports/sport-simple-info.model';
import { Facility } from '../models/facility.model';
import { OpeningHoursTypeEnum } from '../models/opening-hours-type.enum';
import { Closing } from '../models/closing.model';
import { FacilityLabel } from '../models/facility-label.model';
import { FacilityAnnouncementCreate } from '../components/announcements/models/facility-announcement-create.model';
import { FacilityAnnouncementUpdate } from '../components/announcements/models/facility-announcement-update.model';
import { FacilityAnnouncement } from '../components/announcements/models/facility-announcement.model';
import { FacilityAnnouncementList } from '../components/announcements/models/facility-announcements-list.model';

@Injectable({
  providedIn: 'root'
})
export class FacilitiesService {

  constructor(private httpService: HttpClient,
    private httpResponseErrorHandler: HttpResponseErrorHandlerService) {
  }

  getFacilityById(id: string): Observable<Facility> {
    return this.httpService.get<Facility>(`${environment.api}/Facilities/${id}`);
  }

  getAllFacilities(): Observable<Facility[]> {
    return this.httpService.get<Facility[]>(`${environment.api}/Facilities`)
      .pipe(
        catchError(e => this.httpResponseErrorHandler.HandleResponseError(e))
      );
  }

  getFacilitySportFilter(facilityId: any): Observable<Array<SportSimpleInfo>> {
    return this.httpService.get(`${environment.api}/Facilities/${facilityId}/SportFilter`)
      .pipe(
        map((results: any): Array<SportSimpleInfo> => results.data),
        catchError(e => this.httpResponseErrorHandler.HandleResponseError(e))
      );
  }

  createOpeningHours(facilityId: string, model: OpeningHours): Observable<OpeningHours> {
    return this.httpService.post<OpeningHours>(`${environment.api}/Facilities/${facilityId}/OpeningHours`, model)
    .pipe(
      catchError(e => this.httpResponseErrorHandler.HandleResponseError(e))
    );
  }

  updateOpeningHours(facilityId: string, model: OpeningHours): Observable<OpeningHours> {
    return this.httpService.put<OpeningHours>(`${environment.api}/Facilities/${facilityId}/OpeningHours`, model)
    .pipe(
      catchError(e => this.httpResponseErrorHandler.HandleResponseError(e))
    );
  }

  getOpeningHours(facilityId: string, type: OpeningHoursTypeEnum): Observable<OpeningHours> {
    return this.httpService.get<OpeningHours>(`${environment.api}/Facilities/${facilityId}/OpeningHours?type=${type}`)
    .pipe(
      catchError(e => this.httpResponseErrorHandler.HandleResponseError(e))
    );
  }

  deleteOpeningHours(facilityId: string, type: OpeningHoursTypeEnum): Observable<void> {
    return this.httpService.delete<OpeningHours>(`${environment.api}/Facilities/${facilityId}/OpeningHours?type=${type}`)
    .pipe(
      catchError(e => this.httpResponseErrorHandler.HandleResponseError(e))
    );
  }

  getClosingsByFacilityId(facilityId: string): Observable<Closing[]> {
    return this.httpService.get<Closing[]>(`${environment.api}/Facilities/${facilityId}/Closings`)
    .pipe(
      catchError(e => this.httpResponseErrorHandler.HandleResponseError(e))
    );
  }

  createClosings(facilityId: string, model: Closing): Observable<Closing> {
    return this.httpService.post<Closing>(`${environment.api}/Facilities/${facilityId}/Closings`, model)
    .pipe(
      catchError(e => this.httpResponseErrorHandler.HandleResponseError(e))
    );
  }

  updateClosings(facilityId: string, closingId: string, model: Closing): Observable<Closing> {
    return this.httpService.put<Closing>(`${environment.api}/Facilities/${facilityId}/Closings/${closingId}`, model)
    .pipe(
      catchError(e => this.httpResponseErrorHandler.HandleResponseError(e))
    );
  }

  deleteClosings(facilityId: string, closingId: string): Observable<void> {
    return this.httpService.delete<Closing>(`${environment.api}/Facilities/${facilityId}/Closings/${closingId}`)
    .pipe(
      catchError(e => this.httpResponseErrorHandler.HandleResponseError(e))
    );
  }

  getLabelsByFacilityId(id: string): Observable<string[]> {
    return this.httpService.get<string[]>(`${environment.api}/Facilities/${id}/labels`);
  }

  updateLabelsByFacilityId(id: string, labelIds: string[]): Observable<string[]> {
    return this.httpService.put<string[]>(`${environment.api}/Facilities/${id}/labels`, labelIds)
    .pipe(
      catchError(e => this.httpResponseErrorHandler.HandleResponseError(e))
    );
  }

  update(id: string, facility: Facility): Observable<Facility> {
    return this.httpService.put<Facility>(`${environment.api}/Facilities/${id}`, facility)
  }

  create(facility: Facility): Observable<Facility> {
    return this.httpService.post<Facility>(`${environment.api}/Facilities`, facility)
      .pipe(
        catchError(e => this.httpResponseErrorHandler.HandleResponseError(e))
      );
  }

  deleteFacility(id: string): Observable<void> {
    return this.httpService.delete(`${environment.api}/Facilities/${id}`)
      .pipe(
        catchError(e => this.httpResponseErrorHandler.HandleResponseError(e))
      );
  }

  getLabels(): Observable<FacilityLabel[]> {
    return this.httpService.get(`${environment.api}/FacilityLabels`)
      .pipe(
        map((labels: any): FacilityLabel[] => labels.data),
        catchError(e => this.httpResponseErrorHandler.HandleResponseError(e))
      );
  };

  updateFacilitySportFilter(facilityId: any, sportsIds: any): Observable<SportSimpleInfo[]> {
    return this.httpService.put<SportSimpleInfo[]>(`${environment.api}/Facilities/${facilityId}/SportFilter`, sportsIds).pipe(
      catchError((e) => this.httpResponseErrorHandler.HandleResponseError(e))
    )
  }

  getFacilityAnnouncements(facilityId: string, fromAfter: string): Observable<FacilityAnnouncementList[]> {
    return this.httpService.get(`${environment.api}/Facilities/${facilityId}/Announcements?FromAfter=${fromAfter}`)
      .pipe(
        map((results: any): FacilityAnnouncementList[] => results),
      );
  }

  getFacilityAnnouncement(facilityId: string, announcementId: string): Observable<FacilityAnnouncement> {
    return this.httpService.get(`${environment.api}/Facilities/${facilityId}/Announcements/${announcementId}`)
      .pipe(
        map((results: any): FacilityAnnouncement => results),
      );
  }

  createFacilityAnnouncement(facilityId: string, announcement: FacilityAnnouncementCreate): Observable<FacilityAnnouncement> {
    return this.httpService.post(`${environment.api}/Facilities/${facilityId}/Announcements`, announcement).pipe(
      map((res: any): FacilityAnnouncement => res)
    )
  }

  updateFacilityAnnouncement(facilityId: string, facilityAnnouncementId: string, announcement: FacilityAnnouncementUpdate): Observable<FacilityAnnouncement> {
    return this.httpService.put(`${environment.api}/Facilities/${facilityId}/Announcements/${facilityAnnouncementId}`, announcement).pipe(
      map((res: any): FacilityAnnouncement => res),
    )
  }

  deleteFacilityAnnouncement(facilityId: string, facilityAnnouncementId: string): Observable<any> {
    return this.httpService.delete(`${environment.api}/Facilities/${facilityId}/Announcements/${facilityAnnouncementId}`)
  }
}

<div class="card">
    <div class="card-header">
        <div class="d-flex">
            <div class="flex-grow-1">
                <b>{{'Tournaments.Teams.TeamGames.Title' | transloco}}</b>
            </div>
            <div>
                <div class="form-check form-switch">
                    <label class="form-check-label text-end" for="showOld">{{'Tournaments.Teams.TeamGames.ShowOld' |
                        transloco}}</label>
                </div>
            </div>
            <div class="ps-1">
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" id="showOld" [(ngModel)]="showPast"
                        (ngModelChange)="filterGames()">
                </div>
            </div>
        </div>
    </div>
    <div class="card-body pb-0">
        <div *ngIf="filteredGames">
            <div *ngFor="let game of filteredGames" class="mb-3">
                <app-tournament-team-game [allowCaptainEntry]="teamGames.allowResultEntry" [showResults]="teamGames.showResults" [game]="game"
                    [tournamentPlanId]="tournamentPlanId" [teamSecret]="secret" (resultChanges)="loadTeamGames()">
                </app-tournament-team-game>
            </div>

            <div *ngIf="filteredGames.length == 0" class="well mb-3">
                {{'Tournaments.Teams.TeamGames.NoMoreGames' | transloco}}
            </div>
        </div>

        <div *ngIf="!filteredGames" class="p-3">
            <app-spinner></app-spinner>
        </div>
    </div>
</div>
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, NgForm, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { TournamentEventGroupSettingsUpdateModel } from '../models/tournament-event-group-settings-update.model';
import { TournamentEventModel } from '../models/tournament-event.model';
import { TournamentService } from '../services/tournament.service';
import { forkJoin } from 'rxjs';
import { TournamentEventDataMappingImFieldModel } from '../models/tournament-event-data-mapping-im-field.model';
import { TournamentEventDataMappingImFieldOptionModel } from '../models/tournament-event-data-mapping-im-field-option.model';
import { ar, da } from 'date-fns/locale';
import { TournamentEventDataMappingModel } from '../models/tournament-event-data-mapping.model';
import { TournamentEventDataMappingSlotModel } from '../models/tournament-event-data-mapping-slot.model';

@Component({
    selector: 'app-tournament-event-data-mapping-slot-form',
    templateUrl: './tournament-event-data-mapping-slot-form.component.html',
    styleUrls: ['./tournament-event-data-mapping-slot-form.component.scss'],
})
export class TournamentEventDataMappingSlotFormComponent implements OnInit {

    @Input() slotGroup!: FormGroup;
    @Input() formSubmitted!: boolean;
    @Input() dayMappingFields!: TournamentEventDataMappingImFieldModel[];
    @Input() slots!: TournamentEventDataMappingSlotModel[];
    @Input() slotName!: string;
    @Input() dayOfWeek!: number;
    @Input() fullForm!: UntypedFormGroup;


    get monSlotGroup() { return this.fullForm.get("monGroup") as FormGroup; }
    get tueSlotGroup() { return this.fullForm.get("tueGroup") as FormGroup; }
    get wedSlotGroup() { return this.fullForm.get("wedGroup") as FormGroup; }
    get thuSlotGroup() { return this.fullForm.get("thuGroup") as FormGroup; }
    get friSlotGroup() { return this.fullForm.get("friGroup") as FormGroup; }
    get satSlotGroup() { return this.fullForm.get("satGroup") as FormGroup; }
    get sunSlotGroup() { return this.fullForm.get("sunGroup") as FormGroup; }

    get slotConfig() { return this.slotGroup.controls["slotConfig"] as FormArray; }

    constructor(
        private fb: FormBuilder,
    ) {

    }

    ngOnInit() {
        this.setDayOptions();
    }


    setDayOptions() {
        this.slotConfig.clear();

        var eventId = this.slotGroup.controls['slot'].value
        if (eventId == null) return;

        var event = this.dayMappingFields.find(x => x.imId == eventId);
        if (event == undefined || event == null) return;


        var slotConfig = this.getDayForm(event.options);
        this.slotConfig.clear();
        slotConfig.forEach(x => this.slotConfig.push(x));
    }

    copyFromDay(dayOfWeek: number) {
        var sourceForm = this.getDayFormGroup(dayOfWeek);
        if (sourceForm == null) return;

        var sourceFormArray = sourceForm!.controls["slotConfig"] as FormArray;

        this.slotConfig.controls.forEach((target, index) => {
            if (sourceFormArray.controls.length < index + 1) return;
            var sourceConfig = sourceFormArray.controls[index] as FormGroup;
            var targetConfig = target as FormGroup;

            targetConfig.controls['from'].setValue(sourceConfig.controls['from'].value);
            targetConfig.controls['to'].setValue(sourceConfig.controls['to'].value);
        });
    }

    getDayFormGroup(dayOfWeek: number): FormGroup | null {
        switch (dayOfWeek) {
            case 1: return this.monSlotGroup;
            case 2: return this.tueSlotGroup;
            case 3: return this.wedSlotGroup;
            case 4: return this.thuSlotGroup;
            case 5: return this.friSlotGroup;
            case 6: return this.satSlotGroup;
            case 0: return this.sunSlotGroup;
            default: return null;
        }
    }

    private getDayForm(options: TournamentEventDataMappingImFieldOptionModel[]): UntypedFormGroup[] {
        var dayArray: UntypedFormGroup[] = [];
        if (options == undefined || options == null) return dayArray;

        options.forEach(option => {
            var slot = this.getSlotFromDay(option.imId);
            var dayForm = this.fb.group({
                id: [option.imId, Validators.required],
                name: [option.imName, Validators.required],
                from: [slot?.fromTime, [Validators.required, Validators.pattern('^([0-1]?[0-9]|2[0-3])[:.][0-5][0-9]$')]],
                to: [slot?.toTime, [Validators.required, Validators.pattern('^([0-1]?[0-9]|2[0-4])[:.][0-5][0-9]$')]],
            });

            dayArray.push(dayForm);
        });



        return dayArray;
    }

    private getSlotFromDay(slotId: string): TournamentEventDataMappingSlotModel | undefined {
        var slot = this.slots?.find(x => x.optionId == slotId);
        return slot;
    }

}

<div *ngFor="let detail of registrationDetails" class="mb-3">
  <div class="card">
    <div class="card-body">
      <app-details-view *ngIf="!(detail.type == controlType.Checkbox && !changeCheckboxToSwitch(detail))"
        [details]="detail.description"><span *ngIf="detail.isRequired" class="field-required">*</span>
      </app-details-view>
      <ng-container [ngSwitch]="detail.type">
        <div *ngSwitchCase="controlType.Text">
          <input class="form-control" value="{{detail.defaultValue}}" [attr.disabled]="true">
        </div>
        <div *ngSwitchCase="controlType.Number">
          <input class="form-control" value="{{detail.defaultValue}}" [attr.disabled]="true">
        </div>
        <div *ngSwitchCase="controlType.Dropdown">
          <input class="form-control" value="{{getDropDownvalue(detail)}}" [attr.disabled]="true">
        </div>

        <div *ngSwitchCase="controlType.Date">
          <input class="form-control" value="{{stringToDate(detail.defaultValue) | dateFormat: 'DD.MM.YYYY'}}"
            [disabled]="true">
        </div>

        <div *ngSwitchCase="controlType.TriState">
          <app-tri-state [value]="detail.defaultValue" [readOnly]="true" [yesPrice]="detail.yesPrice"
            [noPrice]="detail.noPrice" [undefinedPrice]="detail.undefinedPrice"></app-tri-state>
        </div>

        <div *ngSwitchCase="controlType.Checkbox">
          <div class="checkbox" style="margin-top: 0; margin-bottom:0;" *ngIf="!changeCheckboxToSwitch(detail)">
            <app-checkbox [value]="detail.defaultValue" [readOnly]="true" style="display: inline-block;"></app-checkbox>
            <span class="ms-1">
              {{detail.description}}
            </span>
          </div>

          <div *ngIf="changeCheckboxToSwitch(detail)">
            <app-switch [value]="detail.defaultValue" [readOnly]="true" [yesPrice]="detail.yesPrice"
              [noPrice]="detail.noPrice"></app-switch>
          </div>
        </div>
      </ng-container>
    </div>
    <div *ngIf="detail.footer" class="card-footer">{{detail.footer}}</div>
  </div>
</div>

<div class="form-group">
  <div class="checkbox">
    <label>
      <input type="checkbox" [attr.disabled]="true" checked />
      &nbsp;
      <span [innerHTML]="'YesIAcceptAGB' | transloco"></span>
    </label>
  </div>
</div>
<div class="form-group">
  <div class="checkbox">
    <label>
      <input type="checkbox" [attr.disabled]="true" checked />
      &nbsp;
      <span [innerHTML]="'YesIAcceptInformation' | transloco"></span>
    </label>
  </div>
</div>
<div class="form-group">
  <div class="checkbox">
    <label>
      <input type="checkbox" [attr.disabled]="true" checked />
      &nbsp;
      <span [innerHTML]="'YesIAcceptEnrollment' | transloco"></span>
    </label>
  </div>
</div>
<div class="form-group">
  <div class="checkbox">
    <label>
      <input type="checkbox" [attr.disabled]="true" checked />
      &nbsp;
      <span [innerHTML]="'YesIAcceptCorrectContactDetails' | transloco"></span>
    </label>
  </div>
</div>
<button *ngIf="enrollmentProperties.enrollmentIsOpen" class="btn btn-primary" (click)="enrollForLesson()" id="btnRegister">
  <i class="fa fa-user-plus color-white" aria-hidden="true"></i> {{'LessonActions.EnrollForLesson' | transloco
  | uppercase}}
</button>

<div *ngIf="!enrollmentProperties.enrollmentIsOpen">
  <button class="btn btn-default disabled" id="btnRegister">
    <i class="fa fa-user-plus" aria-hidden="true"></i> {{'LessonActions.EnrollForLesson' | transloco |
    uppercase}}
  </button>
</div>
<div class="row">
  <div class="col offset-md-2 col-md-8">
    <h1>{{'ChangePassword' | transloco}}</h1>

    <div class="row">

      <div class="col-sm-9">
        <div class="form-group">
          <label for="OldPassword">{{ 'OldPassword' | transloco }}</label>
          <input type="password" class="form-control" id="OldPassword" required />
        </div>
      </div>
      <div class="col-sm-9">
        <div class="form-group">
          <label for="NewPassword">{{ 'NewPassword' | transloco }}</label>
          <input type="password" class="form-control" id="NewPassword" required />
        </div>
      </div>
      <div class="col-sm-9">
        <div class="form-group">
          <label for="ConfirmNewPassword">{{ 'ConfirmNewPassword' | transloco }}</label>
          <input type="password" class="form-control" id="ConfirmNewPassword" />
        </div>
      </div>
    </div>
    <div class="well well-sm col-md-9">
      <button id="formSubmit" type="submit" class="btn btn-primary" title="" data-toggle="tooltip" data-original-title="Ok"><i class="glyphicon glyphicon-ok"></i></button>
      <a id="formCancel" href="/" class="btn btn-default" title="" data-toggle="tooltip" data-original-title="Abbrechen"><i class="glyphicon glyphicon-remove"></i></a>
    </div>
  </div>
</div>

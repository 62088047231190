import { Component, OnInit, Input, OnChanges, ViewChild, EventEmitter, Output } from '@angular/core';
import { AbstractControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BaseComponent } from '@premotec/ngx-essentials';
import { ApplicantUpdate } from '../models/applicant-update.model';
import { MembershipApplicationAdminService } from '../services/membership-application-admin.service';
import * as moment from 'moment';
import { ChangeMembershipModalComponent } from './change-membership-modal/change-membership-modal.component';
import { OnlineMembershipUpdate } from '../models/online-membership-update.model';
import { Applicant, Application } from 'src/app/membership-application/models';
import { ToastrService } from 'ngx-toastr';
import { TranslocoService } from '@ngneat/transloco';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ConfirmationModalComponent } from 'src/app/shared/confirmation-modal/confirmation-modal.component';
import { OnlineMembership } from '../models/online-membership.model';

function phoneIsDefined(c: AbstractControl) {
  const phonePrivate = c.get('privatePhone');
  const phoneOffice = c.get('businessPhone');
  const mobile = c.get('mobilePhone');

  if (phonePrivate?.value || phoneOffice?.value || mobile?.value) {
    return null;
  }

  return { 'phone': true };
}

function dateIsTodayOrInFuture(c: AbstractControl) {

  if (moment(c.value).isSameOrAfter(new Date(), 'day')) {
    return null;
  }

  return { 'wrongDate': true };
}

function dateIsAtLeast14YearsAgo(c: AbstractControl) {

  if (!c.value) {
    return null;
  }
  if (moment(c.value).isSameOrBefore(moment().subtract(14, 'years'), 'day')) {
    return null;
  }

  return { 'wrongDate': true };
}

@Component({
  selector: 'app-ma-basic-data-view',
  templateUrl: './ma-basic-data-view.component.html',
  styleUrls: ['./ma-basic-data-view.component.scss']
})

export class MaBasicDataViewComponent extends BaseComponent implements OnInit, OnChanges {

  @Input() application!: Application;

  applicant!: Applicant;

  @Output() reloadApplication: EventEmitter<any> = new EventEmitter<any>();
  @Output() isEditingEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  isSaving = false;
  submitted = false;

  basicDataForm!: FormGroup;

  memberships!: OnlineMembership[];

  constructor(
    private fb: FormBuilder,
    private membershipApplicationAdminService: MembershipApplicationAdminService,
    private toastrService: ToastrService,
    private translocoService: TranslocoService,
    private modalService: BsModalService
  ) {
    super();
  }

  ngOnInit() {
    this.initForm();
    this.getOnlineMemberships();
  }

  initForm() {
    let dateOfBirth, membershipValidFromDate = null;

    if (this.application.membershipValidFromDate != null) {
      membershipValidFromDate = new Date(this.application.membershipValidFromDate);
    } else {
      membershipValidFromDate = new Date();
    }

    if (this.applicant.birthdate != null) {
      dateOfBirth = new Date(this.applicant.birthdate);
    }

    this.basicDataForm = this.fb.group({
      salutation: [{ value: this.applicant.salutation, disabled: true }, [Validators.required, Validators.maxLength(250)]],
      firstName: [{ value: this.applicant.firstName, disabled: true }, [Validators.required, Validators.maxLength(250)]],
      lastName: [{ value: this.applicant.lastName, disabled: true }, [Validators.required, Validators.maxLength(250)]],
      dateOfBirth: [{ value: dateOfBirth, disabled: true }, [Validators.required, dateIsAtLeast14YearsAgo]],
      email: [{ value: this.application.applicant.email, disabled: true }, [Validators.required, Validators.email, Validators.maxLength(250)]],
      address: [{ value: this.applicant.address, disabled: true }, [Validators.required, Validators.maxLength(250)]],
      additionalAddress: [{ value: this.applicant.additionalAddress, disabled: true }, [Validators.maxLength(250)]],
      postCode: [{ value: this.applicant.postCode, disabled: true }, [Validators.required, Validators.maxLength(10)]],
      city: [{ value: this.applicant.city, disabled: true }, [Validators.required, Validators.maxLength(50)]],
      phoneGroup: this.fb.group({
        privatePhone: [{ value: this.applicant.privatePhone, disabled: false }, Validators.maxLength(50)],
        businessPhone: [{ value: this.applicant.businessPhone, disabled: false }, Validators.maxLength(50)],
        mobilePhone: [{ value: this.applicant.mobilePhone, disabled: false }, Validators.maxLength(50)]
      }, { validator: phoneIsDefined }),
      membershipValidFromDate: [{ value: membershipValidFromDate, disabled: true }, [Validators.required, dateIsTodayOrInFuture]]
    });

    this.basicDataForm.disable();
  }

  toggleForm() {
    if (this.basicDataForm.disabled) {
      this.basicDataForm.enable();
      this.isEditingEvent.emit(true);
    } else {
      this.basicDataForm.disable();
      this.isEditingEvent.emit(false);
    }
  }

  ngOnChanges() {
    this.applicant = this.application.applicant;
  }

  submitForm() {
    if (this.basicDataForm.valid) {
      this.isSaving = true;
      const data = this.getUpdatedApplicantData();

      this.whileImAlive(this.membershipApplicationAdminService.updateApplicant(this.application.id, data)).subscribe(res => {
        this.toggleForm();
        this.toastrService.success(this.translocoService.translate('SaveSuccessful'));
        this.isSaving = false;
      },
        () => {
          this.toastrService.error(this.translocoService.translate('SaveError'));
          this.isSaving = false;
        });

    } else {
      this.submitted = true;
    }
  }

  getUpdatedApplicantData(): ApplicantUpdate {
    return {
      address: this.basicDataForm.get('address')?.value,
      additionalAddress: this.basicDataForm.get('additionalAddress')?.value,
      postCode: this.basicDataForm.get('postCode')?.value,
      city: this.basicDataForm.get('city')?.value,
      privatePhone: this.basicDataForm.get('phoneGroup.privatePhone')?.value,
      businessPhone: this.basicDataForm.get('phoneGroup.businessPhone')?.value,
      mobilePhone: this.basicDataForm.get('phoneGroup.mobilePhone')?.value,
      email: this.basicDataForm.get('email')?.value,
      birthdate: this.basicDataForm.get('dateOfBirth')?.value,
      firstName: this.basicDataForm.get('firstName')?.value,
      lastName: this.basicDataForm.get('lastName')?.value,
      salutation: this.basicDataForm.get('salutation')?.value,
      MembershipValidFromDate: this.basicDataForm.get('membershipValidFromDate')?.value
    }
  }

  changeMembership() {
    const initialstate: ModalOptions = {
      initialState: {
        imMembershipId: this.application.onlineMembership.imMembershipId,
        onlineMemberships: this.memberships
      },
      class: "modal-lg"
    }

    const bsModalRef = this.modalService.show(ChangeMembershipModalComponent, initialstate);
    bsModalRef.content!.onlineMembershipChanged.subscribe(x => {
      this.onlineMembershipChanged(x);
    });
  }

  getOnlineMemberships() {
    this.whileImAlive(this.membershipApplicationAdminService.getOnlineMemberships()).subscribe(res => {
      this.memberships = res;
    },
      () => {

      });
  }

  onlineMembershipChanged(newImMembershipId: number) {
    this.isSaving = true;

    const updateModel = new OnlineMembershipUpdate();
    updateModel.imMembershipId = newImMembershipId;

    this.whileImAlive(this.membershipApplicationAdminService.updateOnlineMembership(this.application.id, updateModel)).subscribe(res => {
      this.toastrService.success(this.translocoService.translate('SaveSuccessful'));

      // reload application on parent
      this.reloadApplication.emit();

      this.isSaving = false;
    },
      () => {
        this.toastrService.error(this.translocoService.translate('SaveError'));
        this.isSaving = false;
      });
  }

  verifyUserEmail(): void {
    this.whileImAlive(this.membershipApplicationAdminService.verifyEmail(this.application.id)).subscribe(() => {
      const url = `/office/membership-applications/${this.application.id}?source=3`;
      this.reloadApplication.next(true);
    }, () => {
      this.toastrService.error(this.translocoService.translate('SaveError'));
    });
  }


  verificationShowModal() {
    const initialstate: ModalOptions = {
      initialState: {
        body: this.translocoService.translate('ManageUsers.ConfirmVerification'),
        icon: 'fa-solid fa-circle-info',
      }
    }

    const bsModalRef = this.modalService.show(ConfirmationModalComponent, initialstate);

    bsModalRef.content!.confirmed.subscribe(() => {
      this.verifyUserEmail();
    })
  }
}

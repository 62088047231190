import { Component, OnInit, Output, EventEmitter, Input, TemplateRef } from '@angular/core';
import { BaseComponent } from '@premotec/ngx-essentials';
import { FormControl, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MembershipExtension } from '../../models';
import { MembershipService } from '../../membership.service';
import * as moment from 'moment';
import { CustomValidators } from 'src/app/shared/custom-validators.class';

@Component({
  selector: 'app-membership-change-valid-from-modal',
  templateUrl: './membership-change-valid-from-modal.component.html',
  styleUrls: ['./membership-change-valid-from-modal.component.scss']
})
export class MembershipChangeValidFromModalComponent extends BaseComponent {
  @Input() membershipExtension!: MembershipExtension;
  @Output() membershipExtensionChange: EventEmitter<MembershipExtension> = new EventEmitter<MembershipExtension>();

  modalRef!: BsModalRef;
  modalConfig = {
    ignoreBackdropClick: true,
    keyboard: false
  }

  dateFormControl!: FormControl;

  isLoading!: boolean;
  submitted!: boolean;
  hasUpdateError!: boolean;
  isValidFromOverlappingMembership!: boolean;
  isValidNoMembershipStartAfterNMonths!: boolean;
  translateParam: any;


  constructor(
    private membershipService: MembershipService,
    private modalService: BsModalService) {
      super();
  }

  ok() {
    this.submitted = false;
    this.hasUpdateError = false;
    this.isValidFromOverlappingMembership = false;
    this.isValidNoMembershipStartAfterNMonths = false;

    if (this.dateFormControl.invalid) {
      this.submitted = true;
      return;
    }

    this.dateFormControl.disable();
    this.isLoading = true;
    const timelessDate = moment.utc(this.dateFormControl.value).toDate();
    console.log('Timeless Date:', timelessDate);

    const newValidFromDate = moment(timelessDate).format('YYYY-MM-DD');

    this.whileImAlive(
      this.membershipService.modifyNewMembership(this.membershipExtension.id, newValidFromDate)
    ).subscribe((result: MembershipExtension) => {
      this.membershipExtensionChange.emit(result);
      this.modalRef.hide();
    }, (response: any) => {
      console.log('Error Message:', response);
      if (response.status === 422) {
        // Show the specific Validation error message
        this.setValidationMessages(response.error.errors);
      } else {
        // Show a general error message
        this.hasUpdateError = true;
      }
    }).add(() => {
      this.dateFormControl.enable();
      this.isLoading = false;
    });
  };

  // Displaying the modal with a prefilled item
  openModal(template: TemplateRef<any>) {
    var today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    today.setMilliseconds(0);

    this.dateFormControl = new FormControl(new Date(this.membershipExtension.validFrom), [Validators.required, CustomValidators.dateMinimum(today)]);
    this.modalRef = this.modalService.show(template, this.modalConfig);

     // get NoMembershipStartAfterNMonths from the configuration
     this.whileImAlive(this.membershipService.getNoMembershipStartAfterNMonths()).subscribe(
      (noMembershipStartAfterNMonths) => {
        this.translateParam = {
          'noMembershipStartAfterNMonths': noMembershipStartAfterNMonths.noMembershipStartAfterNMonths
        };
      },
      (errResponse) => {
      }
    );
  }

  cancel() {
    this.resetModal();
    this.modalRef.hide();
  };

  resetModal() {
    this.submitted = false;
    this.hasUpdateError = false;
    this.isValidFromOverlappingMembership = false;
    this.isValidNoMembershipStartAfterNMonths = false;
  }

  setValidationMessages(errors: any[]): void {
    errors.forEach((err) => {

      if (err.message === 'MembershipExtension.ValidNoMembershipStartAfterNMonths') {
        this.isValidNoMembershipStartAfterNMonths = true;
        return;
      }
      this.isValidFromOverlappingMembership = true;
    })
  }
}

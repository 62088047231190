import {Component, Input, OnInit, AfterViewInit, ViewChild, ElementRef} from '@angular/core';
import { NgxMdService } from 'ngx-md';

@Component({
  selector: 'app-details-view',
  templateUrl: './details-view.component.html',
  styleUrls: ['./details-view.component.scss']
})
export class DetailsViewComponent implements OnInit, AfterViewInit {
  @Input() details: any;
  @ViewChild('markdown') markdownElementRef!: ElementRef;

  constructor(private markdownService: NgxMdService) {
      this.markdownService.setMarkedOptions({
        breaks: true
    });
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.addTargetBlankToAllLinks();
  }

  addTargetBlankToAllLinks() {
    if (this.markdownElementRef != null) {
      if (this.markdownElementRef.nativeElement != null) {
        const links = this.markdownElementRef.nativeElement.querySelectorAll('a')
        for (let i = 0; i < links.length; i++) {
            links[i].target = '_blank';
        }
      }
    }
  }

}

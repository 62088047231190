import { TournamentEventDataMappingSlotModel } from "./tournament-event-data-mapping-slot.model";

export class TournamentEventDataMappingModel {
    public teamNameFieldId!: number;
    public teamCaptainNameFieldId!: number;
    public teamCaptainPhoneFieldId!: number;
    public additionFieldId?: number;
    public additionName?: string;

    public mondayFieldId!: number;
    public mondaySlots!: TournamentEventDataMappingSlotModel[];
    public tuesdayFieldId!: number;
    public tuesdaySlots!: TournamentEventDataMappingSlotModel[];
    public wednesdayFieldId!: number;
    public wednesdaySlots!: TournamentEventDataMappingSlotModel[];
    public thursdayFieldId!: number;
    public thursdaySlots!: TournamentEventDataMappingSlotModel[];
    public fridayFieldId!: number;
    public fridaySlots!: TournamentEventDataMappingSlotModel[];
    public saturdayFieldId!: number;
    public saturdaySlots!: TournamentEventDataMappingSlotModel[];
    public sundayFieldId!: number;
    public sundaySlots!: TournamentEventDataMappingSlotModel[];
}
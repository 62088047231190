import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { RegistrationsMainLayoutComponent } from './registrations-main-layout/registrations-main-layout.component';
import { RegistrationsOverviewComponent } from './registrations-overview/registrations-overview.component';
import { routes } from './registrations.routes';
import { RegistrationsTableComponent } from './registrations-table/registrations-table.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { EventsService } from '../events/events.service';
import { FacilitiesService } from '../office/facilities/services/facilities.service';
import { RegistrationService } from '../events/registration/registration.service';
import { RegistrationGuardService } from '../events/registration/registration-guard.service';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(routes),
  ],
  declarations: [
    RegistrationsMainLayoutComponent,
    RegistrationsOverviewComponent,
    RegistrationsTableComponent
  ],
  providers: [
    DecimalPipe,
    EventsService,
    FacilitiesService,
    RegistrationService,
    RegistrationGuardService,
  ]
})
export class RegistrationsModule {
}

<div class="well mb-3 text-end">
    <button class="btn btn-success btn-sm" (click)="openAddEventModal()">
        <i class="fa-solid fa-plus"></i> Turnier hinzufügen
    </button>
    <span>

    </span>
</div>

<div class="card-body p-3" *ngIf="!tournamentEvents">
    <app-spinner></app-spinner>
</div>

<div class="row" *ngIf="tournamentEvents">

    <div class="col-12 mb-3" *ngFor="let event of tournamentEvents">
        <div class="card">
            <div class="card-header">
                <div class="d-flex">
                    <div class="flex-grow-1"><b>{{event.eventNumber}} - {{event.eventTitle}} - {{event.category}}</b>
                    </div>
                    <div>
                        <button class="btn btn-danger btn-sm" (click)="openRemoveEventConfirmModal(event.id)">
                            <i class="fa fa-trash text-light"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-4">
                        <h5 class="flex-grow-1">{{'Tournaments.Events.Links.Title' | transloco}}</h5>
                        <div class="row">
                            <div class="col-12 mb-3">
                                <label class="form-label">
                                    {{'Tournaments.Events.Links.Groups' | transloco}}
                                </label>
                                <div class="input-group">
                                    <a class="btn btn-outline-secondary"
                                        [routerLink]="'/tournaments/'+tournamentPlanId+'/events/'+event.id+'/groups'"
                                        [target]="'_blank'"><i class="fa-solid fa-link"></i></a>
                                    <input class="form-control"
                                        [value]="baseUrl+'/tournaments/'+tournamentPlanId+'/events/'+event.id+'/groups'"
                                        disabled="true" />

                                </div>
                            </div>
                            <div class="col-12 mb-3">
                                <label class="form-label">
                                    {{'Tournaments.Events.Links.GamePlans' | transloco}}
                                </label>
                                <div class="input-group">
                                    <a class="btn btn-outline-secondary"
                                        [routerLink]="'/tournaments/'+tournamentPlanId+'/events/'+event.id+'/games'"
                                        [target]="'_blank'"><i class="fa-solid fa-link"></i></a>
                                    <input class="form-control"
                                        [value]="baseUrl+'/tournaments/'+tournamentPlanId+'/events/'+event.id+'/games'"
                                        disabled="true" />

                                </div>
                            </div>
                            <div class="col-12 mb-3">
                                <label class="form-label">
                                    {{'Tournaments.Events.Links.Addition' | transloco}}
                                    <span *ngIf="event.additionLinkTitle">({{event.additionLinkTitle}})</span>
                                </label>
                                <div class="input-group">
                                    <a class="btn btn-outline-secondary" href="{{event.additionLink}}" target="_blank"
                                        *ngIf="event.additionLink"><i class="fa-solid fa-link"></i></a>
                                    <input class="form-control" [value]="event.additionLink ?? ''" disabled="true" />
                                    <button class="btn btn-secondary" (click)="openAdditionalLinkModal(event.id)"><i
                                            class="fa-solid fa-pen-to-square"></i></button>
                                </div>
                            </div>

                        </div>
                        <h5 class="flex-grow-1">{{'Tournaments.Events.Mapping.Title' | transloco}}</h5>
                        <div class="d-flex alert m-0" [ngClass]="event.isMapped ? 'alert-secondary' : 'alert-warning'">
                            <div class="flex-grow-1">
                                <span *ngIf="event.isMapped">{{'Tournaments.Events.Mapping.Assigned' |
                                    transloco}}</span>
                                <b *ngIf="!event.isMapped">{{'Tournaments.Events.Mapping.Required' | transloco}}</b>
                            </div>
                            <div class="ps-2">
                                <button class="btn btn-secondary btn-sm" (click)="openMappingModal(event.id)">
                                    <i class="fa-solid fa-signs-post text-light"></i>
                                    {{'Tournaments.Events.Mapping.MappShort' | transloco}}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="d-flex">
                            <h5 class="flex-grow-1">{{'Tournaments.Events.Groups.Title' | transloco}}</h5>
                            <div class="">
                                <button class="btn btn-secondary btn-sm" (click)="openGroupSettingsModal(event.id)">
                                    <i class="fa-solid fa-pen-to-square"></i>
                                </button>
                            </div>
                            <hr>
                        </div>
                        <div class="row">
                            <div class="col-12 mb-3">
                                <label class="form-label">
                                    {{'Tournaments.Events.Groups.GroupCount' | transloco}}
                                </label>
                                <input class="form-control" [(ngModel)]="event.groupCount" disabled="true" />
                            </div>
                            <div class="col-12 mb-3">
                                <label class="form-label">
                                    {{'Tournaments.Events.Groups.QualifyingTeamCount' | transloco}}
                                </label>
                                <input class="form-control" [(ngModel)]="event.qualifyingTeamCount" disabled="true" />
                            </div>
                            <div class="col-12 mb-3">
                                <label class="form-label">
                                    {{'Tournaments.Events.Groups.Prefix' | transloco}}
                                </label>
                                <input class="form-control" [(ngModel)]="event.prefix" disabled="true" />
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="d-flex">
                            <h5 class="flex-grow-1">{{'Tournaments.Events.Game.Title' | transloco}}</h5>
                            <div class="">
                                <button class="btn btn-secondary btn-sm" (click)="openGameSettingsModal(event.id)">
                                    <i class="fa-solid fa-pen-to-square"></i>
                                </button>
                            </div>
                            <hr>
                        </div>
                        <div class="row">
                            <div class="col-12 mb-3">
                                <label class="form-label">
                                    {{'Tournaments.Events.Game.SetCount' | transloco}}
                                </label>
                                <input class="form-control" [(ngModel)]="event.setCount" disabled="true" />
                            </div>
                            <div class="col-12 mb-3">
                                <label class="form-label">
                                    {{'Tournaments.Events.Game.PointsPerWin' | transloco}}
                                </label>
                                <input class="form-control" [(ngModel)]="event.pointsPerWin" disabled="true" />
                            </div>
                            <div class="col-12 mb-3">
                                <label class="form-label">
                                    {{'Tournaments.Events.Game.PointsPerDraw' | transloco}}

                                </label>
                                <input class="form-control" [(ngModel)]="event.pointsPerDraw" disabled="true" />
                            </div>
                            <div class="col-12 mb-3">
                                <label class="form-label">
                                    {{'Tournaments.Events.Game.IsCalculationPerSet' | transloco}}

                                </label>
                                <div *ngIf="event.isCalculationPerSet">
                                    <i class="fa-solid fa-toggle-on text-success"></i> Aktiviert
                                </div>
                                <div *ngIf="!event.isCalculationPerSet">
                                    <i class="fa-solid fa-toggle-off"></i> Aktiviert
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { MembershipApplicationAdminService } from '../services/membership-application-admin.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Location } from '@angular/common';
import { BaseComponent } from '@premotec/ngx-essentials';
import { Application, ApplicationDetails, ApplicationDocument, Configuration } from 'src/app/membership-application/models';
import { MemebershipApplicationStatus } from 'src/app/membership-application/enums';
import { MembershipApplicationsService } from 'src/app/membership-application/membership-applications.service';
import { TranslocoService } from '@ngneat/transloco';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ConfirmationModalComponent } from 'src/app/shared/confirmation-modal/confirmation-modal.component';
import { Title } from '@angular/platform-browser';
import { ConfirmWithMessageModalComponent } from 'src/app/shared/modals/confirm-with-message-modal/confirm-with-message-modal.component';
import { MembershipApplicationProcessService } from '../services/membership-application-process.service';

@Component({
  selector: 'app-ma-details',
  templateUrl: './ma-details.component.html',
  styleUrls: ['./ma-details.component.scss']
})
export class MaDetailsComponent extends BaseComponent implements OnInit {

  application!: Application;
  documents!: Configuration[];
  textConfigs!: Configuration[];
  applicationStatus = MemebershipApplicationStatus;
  source!: number;
  _readonly = true;
  _edited = false;
  _isEditing = false;

  archiveReason!: string;

  private _cannotBeEdited!: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private applicationService: MembershipApplicationsService,
    private membershipApplicationProcessService: MembershipApplicationProcessService,
    private translocoService: TranslocoService,
    private toastrService: ToastrService,
    private location: Location,
    private modalService: BsModalService,
    private titleService: Title) {
    super();
  }

  ngOnInit() {
    this.translocoService.selectTranslate('Office.NewMembershipApplications').subscribe((x: any) => {
      this.titleService.setTitle(x);
    });

    this.whileImAlive(this.route.params).subscribe(params => {
      const membershipApplicationId = params['membershipApplicationId'];

      if (membershipApplicationId != null) {
        if (membershipApplicationId.length !== 36) { // length of guid = 36
        }
        this.getById(membershipApplicationId);
      }
    });

    this.whileImAlive(this.route.queryParams).subscribe(params => {
      if (params['source'] != null) {
        this.source = + params['source'];
      }
    });
  }

  get edited() {
    return this._edited;
  }

  set edited(value) {
    this._edited = value;
  }

  get isEditing() {
    return this._isEditing;
  }

  set isEditing(value) {
    if (value === true) {
      this.readonly = true;
    }
    this._isEditing = value;
  }

  get readonly() {
    return this._readonly;
  }

  set readonly(value) {
    this._readonly = value;
  }

  get cannotBeEdited() {
    return this._cannotBeEdited;
  }

  setCannotBeEditedFlag(application: Application): void {
    this._cannotBeEdited = application.statusId !== this.applicationStatus.verificationInProcess
      && this.application.statusId !== this.applicationStatus.declined
      && this.application.statusId !== this.applicationStatus.revisionRequested
      && !application.isArchived
  }


  getById(id: any) {
    this.whileImAlive(this.applicationService.getById(id)).subscribe(res => {
      this.application = res;
      this.archiveReason = this.translocoService.translate(`MembershipApplication.ArchivedReason.${this.application.archiveReason}`);
      this.setCannotBeEditedFlag(res);
      if (this.application.statusId === this.applicationStatus.verificationInProcess) {
        this.readonly = false;
      }
      this.documents = this.getDocumentsConfigurations(this.application.onlineMembership.configurations);
      this.textConfigs = this.getTextAndDropDownConfigurations(this.application.onlineMembership.configurations)
    });
  }

  getDocumentsConfigurations(configurations: Configuration[]): Configuration[] {
    return configurations.filter(c => c.type === 1);
  }

  getTextAndDropDownConfigurations(configurations: Configuration[]): Configuration[] {
    return configurations.filter(c => c.type === 2 || c.type === 3);
  }

  accept(message: string) {
    this.whileImAlive(this.membershipApplicationProcessService.acceptMembershipApplication(this.application.id, this.application.timestamp, message)).subscribe(res => {
      this.edited = true;
      this.showSuccessNotification();
      this.navigateBack();
    },
      (error) => {
        this.errorHandling(error);
      }).add(() => {
        this.isEditing = false;
      });
  }

  verify() {
    this.whileImAlive(this.membershipApplicationProcessService.setToVerifyMembershipApplication(this.application.id, this.application.timestamp)).subscribe(res => {
      // this.edited = true;
      this.showSuccessNotification();
      this.reloadApplication();
    },
      (error) => {
        this.errorHandling(error);
      }).add(() => {
        this.isEditing = false;
      });
  }

  decline(message: any) {
    this.whileImAlive(this.membershipApplicationProcessService.declineMembershipApplication(this.application.id, message, this.application.timestamp)).subscribe(res => {
      this.edited = true;
      this.showSuccessNotification();
      this.navigateBack();
    },
      (error) => {
        this.errorHandling(error);
      }).add(() => {
        this.isEditing = false;
      });
  }

  requestRevision(message: any) {
    this.whileImAlive(this.membershipApplicationProcessService.requestRevisionMembershipApplication(this.application.id, message, this.application.timestamp)).subscribe(res => {
      this.edited = true;
      this.showSuccessNotification();
      this.navigateBack();
    },
      (error) => {
        this.errorHandling(error);
      }).add(() => {
        this.isEditing = false;
      });
  }

  navigateBack() {
    let path;
    switch (this.source) {
      case 1:
        path = '/office/membership-applications/current/online';
        break;
      case 2:
        path = '/office/membership-applications/current/physical';
        break;
      case 3:
        path = '/office/membership-applications/all';
        break;
      case 4:
        path = '/office/membership-applications/archived';
        break;
      default:
        path = '/office/membership-applications/current/online';
    }
    this.router.navigateByUrl(path);
  }

  selectConfigurationFiles(onlineMembershipConfigId: string): ApplicationDocument[] {
    const config: ApplicationDetails = this.application.details.find((c: any) => c.onlineMembershipConfigId === onlineMembershipConfigId);
    if (config != null) {
      return config.documents;
    } else {
      return [];
    }
  }

  getDetailValue(id: any) {
    const detail = this.application.details.find((d: any) => d.onlineMembershipConfigId === id);
    if (detail) {
      return detail.valueJson;
    }
    return '';
  }

  showErrorNotification() {
    this.toastrService.error(this.translocoService.translate('Msg.SaveError'));
  }

  showConcurrencyErrorNotification() {
    this.toastrService.error(this.translocoService.translate('Office.Msg.ConcurrencyError'));
  }

  showSuccessNotification() {
    this.toastrService.success(this.translocoService.translate('Msg.SaveSuccess'));
  }

  errorHandling(error: HttpErrorResponse) {
    if (error.error.errorStatus === 'EntityUpdateConcurrencyError') {
      this.edited = true;
      this.showConcurrencyErrorNotification();
    } else {
      this.showErrorNotification();
    }
  }

  reloadApplication() {
    this.getById(this.application.id);
  }

  childIsEditing(isEditing: any) {
    this.isEditing = isEditing;
  }

  eventconfirmShowModal() {
    const initialstate: ModalOptions = {
      initialState: {
        confirmMessage: this.translocoService.translate('Office.Msg.ConfirmApplicationConfirmation'),
        confirmButtonText: this.translocoService.translate('Accept'),
        icon: 'fa-solid fa-circle-info',
        messageIsRequired: false
      }
    }

    const bsModalRef = this.modalService.show(ConfirmWithMessageModalComponent, initialstate);

    bsModalRef.content!.confirmedMessage.subscribe((message: string) => {
      this.isEditing = true;
      this.accept(message);
    });
  }


  editingShowModal() {
    const initialstate: ModalOptions = {
      initialState: {
        body: this.translocoService.translate('Office.Msg.ConfirmApplicationSetToVerify'),
        icon: 'fa-solid fa-circle-info',
      }
    }

    const bsModalRef = this.modalService.show(ConfirmationModalComponent, initialstate);

    bsModalRef.content!.confirmed.subscribe(() => {
      this.verify();
    });

    bsModalRef.content!.declined.subscribe(() => {
      this.isEditing = false;
    });
  }
}

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-confirm-with-message-modal',
  templateUrl: './confirm-with-message-modal.component.html',
  styleUrls: ['./confirm-with-message-modal.component.scss']
})
export class ConfirmWithMessageModalComponent implements OnInit {

  @Output() confirmedMessage = new EventEmitter<string>();

  title?: string;
  confirmMessage: string = '';
  confirmButtonText?: string = this.transloco.translate('Confirm');
  messageIsRequired: boolean = true;

  formGroup: FormGroup = new FormGroup({
    message: new FormControl('')
  });

  submitted = false;
  isSaving = false;

  constructor(
    private bsModalRef: BsModalRef,
    private transloco: TranslocoService) { }

  ngOnInit(): void {
    if (this.messageIsRequired === true) {
      this.formGroup.get('message')?.setValidators([Validators.required]);
    } 
  }

  save(): void {
    this.submitted = true;
    if (this.formGroup.valid) {
      this.confirmedMessage.emit(this.formGroup.value.message);
      this.bsModalRef.hide();
      this.submitted = false;
    }
  }

  close(): void {
    this.bsModalRef.hide();
  }

}

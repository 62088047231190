<div class="well" style="margin-bottom:0;">

  <div class="row">
    <div class="col-md-12">
      {{ 'PassPhoto.UploadPassPhoto' | transloco }}<br>
      <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;{{ 'PassPhoto.IntendedUse' | transloco }}
    </div>
  </div>
  <!-- spacer -->
  <div class="row">
    <div class="col-md-12">&nbsp;</div>
  </div>
  <div class="row">
    <div class="col-md-5 col-sm-12" style="margin-bottom: 5px;">
      <label class="btn btn-primary" *ngIf="!isDisabled">
        <i class="fa fa-image" aria-hidden="true" style="color: white;"></i>
        <span class="ms-1">{{'PassportPhotoChoose' | transloco}}</span>
        <input type="file" name="myFile" class="form-control" style="display: none;" multiple
          (change)="onFileChange($event)">
      </label>
    </div>
    <div class="col-md-5 col-sm-12">
      <button *ngIf="base64Data && !isDisabled" class="btn btn-primary" (click)="saveChanges()"><i
          class="fa fa-cloud-upload" aria-hidden="true" style="color: white;"></i>&nbsp;{{'PassportPhotoUpload' |
        transloco}}</button>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <image-cropper *ngIf="base64Data" [imageBase64]="base64Data" [maintainAspectRatio]="true" [aspectRatio]="3 / 4"
        [resizeToWidth]="300" [onlyScaleDown]="true" (format)="originalFileFormat" (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"
        alignImage="left" style="padding:none" [cropperMinWidth]="150"></image-cropper>
    </div>
  </div>
</div>
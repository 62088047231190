import {Pipe, PipeTransform} from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Pipe({
  name: 'undated'
})
export class UndatedPipe implements PipeTransform {

  infinityDate = new Date(9999, 11, 31);

  constructor(private translocoService: TranslocoService) {
  }

  transform(value: any, args?: any): any {

    if (value && value === '31.12.9999') {
      /*      return `${value} ${this.translocoService.translate('Undated')}`;*/
      return `${this.translocoService.translate('Undated')}`;
    }

    if (value && typeof value === 'object' && value.getDate && value.getDate() === this.infinityDate.getDate()) {
      return `${this.translocoService.translate('Undated')}`;
    }

    return value;
  }

}

import { Component, Directive, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { forkJoin } from 'rxjs';
import { BaseComponent } from '@premotec/ngx-essentials';

import { environment } from '../../../environments/environment';
import { EventsService } from '../events.service';
import { UserAuthorizationService } from '../../security/user-authorization.service';
import { Event } from '../event.model';
import { RequiredSkill } from '../required-skill.model';
import { AccountService } from '../../account/account.service';
import { MembershipService } from '../../memberships/membership.service';
import { Account } from '../../account/account.model';
import { EventRegistrationDetail } from '../event-registration-detail.model';
import { Membership } from '../../memberships/models';
import { EventState } from '../event-state.enum';
import { TabDirective, TabsetComponent } from 'ngx-bootstrap/tabs';
import { LiveStream } from 'src/app/shared/models/live-stream.model';
import { PrerequisitesService } from 'src/app/shared/prerequisites-view/prerequisites.service';
import { ControlType } from '../registration/registration-details/control-type.enum';
import { Instructor } from '../instructor.model';
import { LiveStreamStatus } from 'src/app/shared/enum/live-stream-status.enum';
import { TranslocoService } from '@ngneat/transloco';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ConfirmationModalComponent } from 'src/app/shared/confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss']
})
export class EventDetailsComponent extends BaseComponent implements OnInit {

  @ViewChild('eventTabs') eventTabs!: TabsetComponent;

  event!: Event;
  account!: Account;
  missingSkills: RequiredSkill[] = [];
  memberships!: Membership[];
  liveStream!: LiveStream;

  eventState = EventState;
  activeTab = "offer";
  dataLoaded = false;

  translateParam: any;

  loginPageUrl = environment.loginPage;

  userIsLoggedIn!: boolean;
  priceOnRequest = false;

  displayTlToolTap!: boolean;

  constructor(private activatedRoute: ActivatedRoute,
    private eventsService: EventsService,
    private authenticationService: UserAuthorizationService,
    private prerequisitesService: PrerequisitesService,
    private accountService: AccountService,
    private titleService: Title,
    private location: Location,
    private translocoService: TranslocoService,
    private membershipService: MembershipService,
    private modalService: BsModalService) {
      super();
  }

  ngOnInit() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.activatedRoute.params.subscribe((params) => {
      const eventId = + params['eventId'];
      if (eventId) {
        this.dataLoaded = false;
        this.loadEvent(eventId);
      }

      if (this.authenticationService.isLoggedIn()) {
        this.userIsLoggedIn = true;
      } else {
        this.userIsLoggedIn = false;
      }
    });
  }

  // TODO: Move to EventRegistrationDetail model?
  public isRegistrationDetailDropDownType(eventDescription: EventRegistrationDetail): boolean {
    return eventDescription.type === ControlType.Dropdown;
  }

  public getRegistrationDetailInfoLabel(eventDescription: EventRegistrationDetail): string {
    return 'EventRegistrationResponse.Type_' + eventDescription.type + '_Detail';
  }

  public getRegistrationDetailDescription(eventDescription: EventRegistrationDetail): string {
    if (eventDescription.isRequired) {
      return eventDescription.description + ' *';
    }

    return eventDescription.description;
  }

  public hasRequiredRegistrationDetail(): boolean {
    if (this.event === null
      || this.event.registrationDetails === null
      || this.event.registrationDetails.length === 0) {
      return false;
    }

    let hasRequired = false;
    this.event.registrationDetails.forEach(item => {
      if (item.isRequired) {
        hasRequired = true;
      }
    })

    return hasRequired;
  }


  openTlTool() {
    // Opening the tl tool in a new tab
    window.open(`${environment.tlToolBaseUrl}${this.event.tlToolUrl}`, '_blank');
  }

  public loadEvent(eventId: number) {
    this.whileImAlive(this.eventsService.getEventById(eventId)).subscribe(
      (event: Event) => {
        this.displayTlToolTap = this.getTlToolTapPermission(event.instructors);
        this.event = event;
        this.event.registrationDetails = this.event.registrationDetails.sort((a, b) => a.sort - b.sort);
        this.event.subEvents = this.event.subEvents.sort((a, b) => new Date(a.starts).getTime() - new Date(b.starts).getTime());

        // set window title
        this.titleService.setTitle(`${event.sportName}: ${event.title}`);

        if (this.authenticationService.isLoggedIn()) {
          this.whileImAlive(
            forkJoin(
              this.accountService.getAccountDetails(),
              this.membershipService.getMyMemberships(),
            )).subscribe((data) => {
            this.account = data[0];
            this.memberships = data[1].memberships;
            this.missingSkills = this.prerequisitesService.getMissingSkills(
              event.requiredSkills,
              this.account.skills!,
              event.registrationFrom,
              event.starts,
              event.ends
            );
            this.dataLoaded = true;
          });

          // load live stream if query param is present
          if (this.activatedRoute.snapshot.queryParamMap.get('livestream')) {
            this.loadLiveStream();
          }
        } else {
          this.missingSkills = this.event.requiredSkills;
          this.dataLoaded = true;
        }
      },
      (error) => {
        if (error.status === 404) {
          this.location.replaceState('/notFound');
        }
      });
  }

  private getTlToolTapPermission(instructors: Instructor[]): boolean {
    const currentUser = this.authenticationService.getUser();

    if (currentUser.hasRoleAdmin()  || currentUser.hasRoleHspl() || currentUser.hasRoleOffice()) {
      return true;
    } else if (currentUser.hasRoleTrainingsleiter() && instructors) {
      return !!instructors.find(i => (i.asvzId === currentUser.asvzId));
    } else {
      return false;
    }
  }

  public loadLiveStream() {
    this.eventsService.getLiveStream(this.event.id).subscribe(
      (liveStream: LiveStream) => {
        this.liveStream = liveStream

        // checks status and act accordingly
        if (this.liveStream.status === LiveStreamStatus.NotStartedYet) {
          this.activeTab = 'offer';

          // show modal

          const initialstate: ModalOptions = {
            initialState: {
              body: this.translocoService.translate('LiveStream.NotStartedYet', {'ShowBeforeStartMinutes': this.liveStream.showBeforeStartInSeconds / 60}),
              title: this.translocoService.translate('LiveStream.LiveStream'),
              icon: 'fa-solid fa-circle-info',
              onlyConfirm: true
            }
          }

          const bsModalRef = this.modalService.show(ConfirmationModalComponent, initialstate);

        } else if (this.liveStream.status === LiveStreamStatus.Ended) {
          this.activeTab = 'offer';

          // show modal
          const initialstate: ModalOptions = {
            initialState: {
              body: this.translocoService.translate('LiveStream.Ended'),
              title: this.translocoService.translate('LiveStream.LiveStream'),
              icon: 'fa-solid fa-circle-info',
              onlyConfirm: true
            }
          }

          const bsModalRef = this.modalService.show(ConfirmationModalComponent, initialstate);

        } else if (this.liveStream.status === LiveStreamStatus.Live || this.liveStream.status === LiveStreamStatus.StartSoon) {
          this.activeTab = 'livestream';
        }
      },
      (error => {
        this.activeTab = 'offer';
      })
    );
  }

  public showLiveStreamTab() {
    this.loadLiveStream();
  }
}

/*
 Tab order directive to resolve problems with they Tab order:
 https://github.com/valor-software/ngx-bootstrap/issues/823
*/
@Directive({
  selector: '[tabOrder]'
})
export class TabOrderDirective implements OnChanges {

  @Input() tabOrder = 0;

  constructor(private tab: TabDirective) { }

  ngOnChanges() {
      (this.tab as any).__tabOrder = +this.tabOrder;
      this.tab.tabset.tabs.sort((a: any, b: any) => a.__tabOrder - b.__tabOrder);
  }
}

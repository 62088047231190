import { Component, Input } from '@angular/core';

@Component({
  selector: 'pmt-loading-modal',
  templateUrl: './loading-modal.component.html',
  styleUrls: [],
})
export class LoadingModalComponent {
  /** The text which shows up above the Loading-Indicator */
  @Input() title?: string;
}

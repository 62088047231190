import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { TournamentService } from '../services/tournament.service';
import { Title } from '@angular/platform-browser';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TournamentGamePlanModel } from '../models/tournament-game-plan.model';
import { forkJoin } from 'rxjs';
import { TournamentModel } from '../models/tournament.model';
import { AgGridAngular } from 'ag-grid-angular';
import { GridReadyEvent } from 'ag-grid-community';
import * as moment from 'moment';
import { TournamentDisplayService } from '../services/tournament-display.service';
import { GridService } from 'src/app/shared/ag-grid/grid.service';
import { TournamentGameAdminOptionsAgGridComponent } from '../tournament-game-admin-options-ag-grid/tournament-game-admin-options-ag-grid.component';

@Component({
    templateUrl: './tournament-admin-games.component.html',
    styleUrls: ['./tournament-admin-games.component.scss'],
    providers: [GridService]
})
export class TournamentAdminGamesComponent implements OnInit {

    @ViewChild('agGrid') agGrid!: AgGridAngular;
    rowData: any = [];
    columnDefs: any = [];
    defaultColDef = {
        sortable: true,
        filter: true,
        floatingFilter: true,
        resizable: true,
    }

    context!: TournamentAdminGamesComponent;

    tournamentPlanId!: string;

    tournamentPlan!: TournamentModel;
    gamePlan!: TournamentGamePlanModel;

    constructor(
        private translocoService: TranslocoService,
        private activatedRoute: ActivatedRoute,
        private tournamentService: TournamentService,
        private tournamentDisplayService: TournamentDisplayService,
        private gridService: GridService,
        private modalService: BsModalService
    ) { }

    ngOnInit(): void {
        this.context = this;

        this.activatedRoute.params.subscribe((params: Params) => {
            this.tournamentPlanId = params['tournamentPlanId'];

            this.translocoService.selectTranslate('Tournaments.Events.Game.ResultEntry.Title').subscribe(x => {
                this.initGrid();
                this.loadData();
            });
        });
    }

    loadData() {
        forkJoin({
            gamePlan: this.tournamentService.getGamePlan(this.tournamentPlanId),
            tournamentPlan: this.tournamentService.getTournamentById(this.tournamentPlanId)
        }).subscribe({
            next: (res) => {
                this.tournamentPlan = res.tournamentPlan;

                res.gamePlan.games.forEach(g => {
                    g.teams = `${g.homeTeamName} vs. ${g.guestTeamName}`;
                    g.referee = g.refereeTeamName ?? '';
                    g.fullLocation = `${g.facilityName} - ${g.roomName} - ${this.tournamentDisplayService.getFieldName(g.fieldId)}`;
                })

                this.gamePlan = res.gamePlan;
                this.gridService.processGrid(this.agGrid, this.gamePlan.games, this.constructor.name);
                this.agGrid.api.sizeColumnsToFit();
            }
        });
    }

    onGridReady(parameters: GridReadyEvent) {

    }

    dateFromFormatter(params: any) {
        return moment(params.data.from).format('DD.MM.yy HH:mm');
    }

    dateToFormatter(params: any) {
        return moment(params.data.to).format('DD.MM.yy HH:mm');
    }

    initGrid() {
        this.columnDefs = [
            { field: 'imEventNumber', headerName: this.translocoService.translate('Tournaments.GamePlans.Event'), maxWidth: 150, minWidth: 150, },
            { field: 'groupName', headerName: this.translocoService.translate('Tournaments.GamePlans.Group'), minWidth: 100, },
            {
                field: 'from',
                headerName: this.translocoService.translate('Tournaments.GamePlans.From'),
                minWidth: 150,
                maxWidth: 150,
                valueFormatter: this.dateFromFormatter,
                filterValueGetter: this.dateFromFormatter,
                comparator: (valueA: Date, valueB: Date) => {
                    if (valueA == valueB) return 0;
                    return new Date(valueA).getTime() - new Date(valueB).getTime()
                }
            },
            {
                field: 'to',
                headerName: this.translocoService.translate('Tournaments.GamePlans.To'),
                minWidth: 150,
                maxWidth: 150,
                valueFormatter: this.dateToFormatter,
                filterValueGetter: this.dateToFormatter,
                comparator: (valueA: Date, valueB: Date) => {
                    if (valueA == valueB) return 0;
                    return new Date(valueA).getTime() - new Date(valueB).getTime()
                }
            },
            {
                field: 'teams',
                headerName: this.translocoService.translate('Tournaments.GamePlans.Teams'),
                minWidth: 100,
                comparator: (valueA: string, valueB: string) => {
                    if (valueA == valueB) return 0;
                    return (valueA.toLowerCase() > valueB.toLowerCase()) ? 1 : -1;
                }
            },
            { field: 'result', headerName: this.translocoService.translate('Tournaments.GamePlans.Result'), minWidth: 100, },
            {
                field: 'referee',
                headerName: this.translocoService.translate('Tournaments.GamePlans.Referee'),
                minWidth: 100,
                comparator: (valueA: string, valueB: string) => {
                    if (valueA == valueB) return 0;
                    return (valueA.toLowerCase() > valueB.toLowerCase()) ? 1 : -1;
                }
            },
            { field: 'fullLocation', headerName: this.translocoService.translate('Tournaments.GamePlans.Room'), minWidth: 100, },
            {
                field: 'actions',
                headerName: '',
                filter: false,
                sortable: false,
                cellRenderer: TournamentGameAdminOptionsAgGridComponent,
                cellEditorPopupPosition: 'under',
                tooltipField: "edit",
                minWidth: 105,
                maxWidth: 105,
                pinned: 'right',
                type: 'client'
            },
        ];

    }

}

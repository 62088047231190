import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';

import { Membership } from './models';
import { BaseComponent } from '@premotec/ngx-essentials';
import { MembershipService } from './membership.service';
import { MembershipState } from './membership-state.enum';
import { MembershipOverview } from './models/membership-overview.model';
import { Router } from '@angular/router';
import { MembershipPriceClass } from './membership-price-class.enum';
import { PersonWithMembership } from './models/person-with-membership.model';
import { TranslocoService } from '@ngneat/transloco';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { MembershipsHealthInsuranceRequestModalComponent } from './memberships-health-insurance-request/memberships-health-insurance-request-modal/memberships-health-insurance-request-modal.component';
import { MembershipsHealthInsuranceResponseTypeEnum } from './enums/memberships-health-insurance-response-type.enum';
import { ConfirmationModalComponent } from '../shared/confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-memberships',
  templateUrl: './memberships.component.html',
  styleUrls: ['./memberships.component.scss']
})
export class MembershipsComponent extends BaseComponent implements OnInit {

  myMembershipsDefinite!: MembershipOverview[];
  myMembershipNonComplete!: MembershipOverview | null; // membership which is not definite (should just be one)

  membershipState = MembershipState;
  membershipPriceClass = MembershipPriceClass;
  noMemberships = true;
  hasNonCompleteStatusMemberships = false;
  canDeleteMembership = false;
  membershipIdToDelete!: number;
  personWithMemberships!: PersonWithMembership;

  constructor(
    private membershipService: MembershipService,
    private translocoService: TranslocoService,
    private toastrService: ToastrService,
    private bsModalService: BsModalService
  ) {
    super();
  }

  ngOnInit() {
    this.whileImAlive(this.membershipService.getMyMemberships()).subscribe(personWithMemberships => {
      this.personWithMemberships = personWithMemberships;

      this.noMemberships = (personWithMemberships.memberships == null) || personWithMemberships.memberships.length === 0;
      this.myMembershipNonComplete = null;
      this.myMembershipsDefinite = this.initDisplayProperties(personWithMemberships.memberships);

      this.myMembershipsDefinite.sort(function (a, b) {
        return a.validUntil > b.validUntil ? -1 : a.validUntil < b.validUntil ? 1 : 0;
      });
    })
  }

  setMembershipToDelete(personMembershipId: number) {
    this.membershipIdToDelete = personMembershipId;
  }

  registrationDeleteConfirmed() {
    // Delete the requested personMembership and refresh display
    this.membershipService.deleteTemporaryMembership(this.membershipIdToDelete).subscribe(() => {

      this.toastrService.success(this.translocoService.translate('MembershipDeleteSuccess'));
      this.ngOnInit();
    }, (errorResponse) => {

      this.toastrService.error(this.translocoService.translate('MembershipDeleteError'));
    })
  }

  // NOTE: Initial implementation using method calls in HTML generated continuous calls to the method
  private initDisplayProperties(memberships: Membership[]): MembershipOverview[] {
    let hasNonCompletedMembership = false;

    const membershipOverviews = new Array<MembershipOverview>();
    memberships.forEach((x) => {
      const overview = new MembershipOverview();
      Object.assign(overview, x);

      const membershipStatusLabel = this.getMembershipStatusLabel(x);
      overview.statusInfo = this.translocoService.translate(membershipStatusLabel + '.Name');
      overview.statusTooltip = this.translocoService.translate(membershipStatusLabel + '.Msg');
      overview.statusTooltipClass = this.getCorrespondingIcon(x.status);

      overview.canDelete = this.membershipService.canMembershipBeDeleted(x.status);

      if (this.membershipService.isStatusInProcess(x.status)) {
        hasNonCompletedMembership = true;
        this.myMembershipNonComplete = overview;
      } else {
        membershipOverviews.push(overview);
      }
    });

    this.hasNonCompleteStatusMemberships = hasNonCompletedMembership;
    return membershipOverviews;
  }

  private getMembershipStatusLabel(membership: Membership): string {
    // NOTE: When a Membership is finalized, it may not actually be 'Active' (eg, is in the past or is in the future)
    if (membership.status === MembershipState.Completed) {

      const now = moment();
      const startsOn = moment(membership.validFrom);
      const endsOn = moment(membership.validUntil);

      if (startsOn.isAfter(now, 'day')) {
        // Indicate this membership exists in the future
        return 'Memberships.StateMessages.State_InTheFuture';
      }

      if (now.isAfter(endsOn, 'day')) {
        // Indicate this membership exists in the past
        return 'Memberships.StateMessages.State_InThePast';
      }
    }

    return 'Memberships.StateMessages.State_' + membership.status;
  }

  private getCorrespondingIcon(value: MembershipState): string {
    switch (value) {
      case MembershipState.Completed:
        return 'fa fa-circle-check';
      case MembershipState.Canceled:
      case MembershipState.EventoCanceled: // Evento Canceled
        return 'fa fa-circle-xmark';
      case MembershipState.Temporary:
        return 'fa fa-file';
      case MembershipState.New:
        return 'fa fa-file';
      case MembershipState.Expired:
        return 'fa fa-clock';
      case MembershipState.ProcessPayment:
        return 'fa fa-dollar-sign';
      default:
        return '';
    }
  }

  checkHasActiveStudentMembership(): boolean {
    let result = this.myMembershipsDefinite.filter(function (mem) {
      return mem.status === MembershipState.Completed && mem.priceClass === MembershipPriceClass.Student;
    });

    return result.length > 0;
  }

  isAnyMembershipisHealthInsuranceEligible(): boolean {
    if (this.myMembershipsDefinite == null || this.myMembershipsDefinite == undefined) return false;
    return (this.myMembershipsDefinite.filter(x => x.isHealthInsuranceEligible).length > 0);
  }

  requestHealthInsuranceSummary(personMembershipId: number): void {
    const initialstate: ModalOptions = {
      initialState: {
        personMembershipId: personMembershipId
      }
    }
    var bsModal = this.bsModalService.show(MembershipsHealthInsuranceRequestModalComponent, initialstate);

    bsModal.content?.confirmed.subscribe(x => {
      this.toastrService.success(this.translocoService.translate('Memberships.HealthInsuranceRequest.Success'));
    });

    bsModal.content?.error.subscribe(x => {
      switch (x) {
        case MembershipsHealthInsuranceResponseTypeEnum.MembershipError:
          this.displayRequestHealthInsuranceSummaryError(this.translocoService.translate('Memberships.HealthInsuranceRequest.MembershipError'))
          break;
        case MembershipsHealthInsuranceResponseTypeEnum.LessonsError:
          this.displayRequestHealthInsuranceSummaryError(this.translocoService.translate('Memberships.HealthInsuranceRequest.LessonsError'))
          break;
        case MembershipsHealthInsuranceResponseTypeEnum.RequestCountError:
          this.displayRequestHealthInsuranceSummaryError(this.translocoService.translate('Memberships.HealthInsuranceRequest.RequestCountError'))
          break;
        default:
          this.toastrService.error(this.translocoService.translate('Memberships.HealthInsuranceRequest.Error'));
      }
    });
  }


  displayRequestHealthInsuranceSummaryError(errorText: string) {
    const initialstate: ModalOptions = {
      initialState: {
        onlyConfirm: true,
        body: errorText,
        icon: 'fa-solid fa-circle-exclamation'
      }
    }
    var bsModal = this.bsModalService.show(ConfirmationModalComponent, initialstate);

  }
}
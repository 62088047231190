<ng-container *ngIf="gamePlan != undefined">
    <div class="alert alert-warning" *ngIf="!gamePlan.hasGroups">
        <b>{{'Tournaments.GamePlans.NoGroups' | transloco}}</b>
    </div>

    <div class="alert alert-warning" *ngIf="!gamePlan.hasSlots">
        <b>{{'Tournaments.PlanningData.NoSlots' | transloco}}</b>
    </div>

</ng-container>

<div [ngClass]="{'d-none': gamePlan == undefined || !gamePlan.hasSlots || !gamePlan.hasGroups}">
    <div class="well mb-3 text-end">
        <button class="btn btn-success btn-sm" (click)="openGameCreationModal()"
            [disabled]="loadingData || gamePlan.gamePlanStatus != TournamentGamePlanStatusEnum.Empty">
            {{'Tournaments.GamePlans.TriggerCreate' | transloco}} <i class="fa-solid fa-shuffle"></i>
        </button>
        <button class="btn btn-primary btn-sm" (click)="gamesExchange()"
            [disabled]="loadingData || !enableGamesExchange">
            {{'Tournaments.GamePlans.TriggerGamesExchange' | transloco}} <i class="fa-solid fa-right-left"></i>
        </button>
        <button class="btn btn-primary btn-sm" (click)="exportListToExcel()"
            [disabled]="loadingData">
            {{'Tournaments.GamePlans.TriggerExport' | transloco}} <i class="fas fa-file-export"></i>
        </button>
        <button class="btn btn-danger btn-sm" (click)="openGameDeleteModal()"
            [disabled]="loadingData || gamePlan.gamePlanStatus == TournamentGamePlanStatusEnum.Creating">
            {{'Tournaments.GamePlans.TriggerDelete' | transloco}} <i class="fa-solid fa-trash"></i>
        </button>
        <button class="btn btn-primary btn-sm" (click)="triggeGameSimulation()"
            [disabled]="loadingData || gamePlan.gamePlanStatus != TournamentGamePlanStatusEnum.Created">
            Spiele Simulieren <i class="fa-solid fa-hippo"></i>
        </button>
    </div>
    <div [ngClass]="{'d-none': gamePlan == undefined ||  gamePlan.gamePlanStatus != TournamentGamePlanStatusEnum.Created}">
        <ag-grid-angular #agGrid style="width: 100%; height: calc(100vh - 270px);" class="ag-theme-alpine"
            [rowData]="rowData" [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" [rowSelection]="'multiple'"
            (gridReady)="onGridReady($event)" [context]="context" [tooltipShowDelay]="0" [enableBrowserTooltips]="true"
            [enableCellTextSelection]=true  (selectionChanged)="onSelectionChanged()">
        </ag-grid-angular>
    </div>
    <div class="alert alert-secondary" *ngIf="gamePlan?.gamePlanStatus == TournamentGamePlanStatusEnum.Empty">
        <b>{{'Tournaments.GamePlans.Status.Empty' | transloco}}</b>
    </div>
    <div class="alert alert-warning" *ngIf="gamePlan?.gamePlanStatus == TournamentGamePlanStatusEnum.Creating">
        <b>{{'Tournaments.GamePlans.Status.Creating' | transloco}}</b> <span class="ms-1"><i class="fa-solid fa-spinner fa-spin"></i></span>
    </div>
    <div class="alert alert-danger" *ngIf="gamePlan?.gamePlanStatus == TournamentGamePlanStatusEnum.Error">
        <b>{{'Tournaments.GamePlans.Status.Error' | transloco}}</b>
    </div>
</div>

<div class="card-body p-3" *ngIf="!gamePlan">
    <app-spinner></app-spinner>
</div>
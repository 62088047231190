<div *ngIf="this.maintenanceForm">
  <div class="well well-sm">
    <button (click)="save(maintenanceForm)" class="btn btn-default"><i class="fa fa-save"></i> {{ 'SaveChanges' |
      transloco }}
    </button>
  </div>
  <div>
    <div>

      <h1 *ngIf="maintenance?.id !== null">{{'MaintenanceWindow' | transloco}}</h1>
      <h1 *ngIf="maintenance?.id === null">{{'CreateNewMaintenance' | transloco}}</h1>


      <form [formGroup]="maintenanceForm">

        <div class="checkbox">
          <label>
            <input type="checkbox" formControlName="active">&nbsp;{{'Active' | transloco}}
          </label>
        </div>

        <div class="row mt-2">
          <div class="col-sm-2">
            <div class="form-group">
              <label for="publicationDate">{{'PublicationDate' | transloco}}</label>
              <pmt-datepicker [id]="'publicationDate'"  formControlName="publicationDate" >
              </pmt-datepicker>
            </div>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-sm-2">
            <div class="form-group">
              <label for="startDate">{{'StartDate' | transloco}}</label>
              <pmt-datepicker [id]="'startDate'" formControlName="startDate" >
              </pmt-datepicker>
            </div>
          </div>
          <div class="col-sm-2">
            <div class="form-group">
              <label for="startTime">{{'StartTime' | transloco}}</label>
              <input class="form-control" formControlName="startTime" placeholder="HH:mm" />
            </div>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-sm-2">
            <div class="form-group">
              <label for="endDate">{{'EndDate' | transloco}}</label>
              <pmt-datepicker  [id]="'endDate'" formControlName="endDate" >
              </pmt-datepicker>
            </div>
          </div>
          <div class="col-sm-2">
            <div class="form-group">
              <label for="endTime">{{'EndTime' | transloco}}</label>
              <input class="form-control" formControlName="endTime" placeholder="HH:mm" />
            </div>
          </div>
        </div>
      </form>

    </div>
  </div>
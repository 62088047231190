
<ng-container *ngIf="!isLoading && !userDeleted">
    <div class="well well-sm">
        <app-user-set-password-modal [user]="user"></app-user-set-password-modal>
        <button class="btn btn-danger float-end" (click)="deleteShowModal()"><i class="fa fa-trash text-light"></i> {{'ManageUsers.DeleteUser' | transloco }}</button>
        <button id="sendPasswordResetRequestMail" (click)="passwordResetMailShowModal()" type="button" class="btn btn-default"><i class="fa fa-key"></i> {{'TriggerPasswordResetMail' | transloco}}</button>
    </div>

    <h1>{{'EditUser' | transloco}}</h1>
    <div>
        <div class="row">
        <div class="col-md-6">
            <div class="card">
            <div class="card-header">{{'Users' | transloco}}</div>
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-4">
                        <dl>
                            <dt>{{'ASVZID' | transloco}}</dt>
                            <dd>{{user.asvzId}} <app-user-set-asvz-id-modal [user]="user" (userChange)="updateUser($event)"></app-user-set-asvz-id-modal>
                            </dd>
                        </dl>
                    </div>
                    <div class="col-sm-4"><dl><dt>{{'Name' | transloco}}</dt><dd>{{user.firstName + ' ' + user.lastName}}</dd></dl></div>
                </div>
                <div class="row">
                    <div class="col-sm-4"><dl>
                        <dt>{{'Verified' | transloco }}</dt>
                        <dd>
                            <input [checked]="user.emailConfirmed" class="check-box me-1" disabled="disabled" type="checkbox">
                            <button [disabled]="user.emailConfirmed" class="btn btn-default btn-sm" (click)="verificationShowModal()">{{'Verify' | transloco}}</button>
                        </dd>
                    </dl>
                    </div>
                    <div class="col-sm-8"><dl><dt>{{'Email' | transloco}}</dt><dd><a href="mailto:{{user.email}}">{{user.email}}</a></dd></dl></div>


                </div>
                <div class="row">
                    <div class="col-sm-4"><dl><dt>{{'LastLogin' | transloco }}</dt><dd>{{user.lastLoginDate | dateFormat: "DD.MM.YYYY HH:mm"}}</dd></dl></div>
                    <div class="col-sm-4"><dl><dt>{{'FailedLogins' | transloco }} </dt><dd>{{user.accessFailedCount}}</dd></dl></div>
                    <div class="col-sm-4">
                        <dl *ngIf="userIsLockedOut"><dt>{{'LockedUntil' | transloco}}</dt>
                            <dd>{{user.lockoutEnd | dateFormat: "DD.MM.YYYY HH:mm"}}</dd>
                            <dd>
                                <button class="btn btn-default btn-sm" (click)="unlockingShowModal()">{{'ManageUsers.UnlockUser' | transloco}}</button>
                            </dd>
                        </dl>

                    </div>
                </div>

            </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="card">
                <div class="card-header">{{'Roles' | transloco}}</div>
                <div class="list-group">
                    <div class="list-group-item" *ngFor="let role of roles" >
                        <input type="checkbox" [checked]="userHasRole(role.id)" disabled> {{role.name}}
                    </div>
                </div>
            </div>
            </div>
            <div class="col-md-3">
                <div class="card">
                <div class="card-header">{{'Logins' | transloco}}</div>
                <div class="card-body">
                    <div *ngFor="let login of logins">
                        <i class="fa fa-check-circle" style="color: green;"></i>
                        <span class="mx-1">{{login.loginProvider}}</span>
                            <button class="btn btn-default btn-sm" [tooltip]="'Delete' | transloco" (click)="loginDeleteShowModal(login)"><i class="fa fa-trash"></i></button>
                    </div>
                    <div *ngIf="!logins?.length">
                        {{'NoLogins' | transloco}}
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<div *ngIf="userDeleted" class="alert alert-primary">
    {{'ManageUsers.DeleteUserSuccess' | transloco}}
</div>
<app-spinner *ngIf="isLoading"></app-spinner>

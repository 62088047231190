import { Component, OnInit, ElementRef } from '@angular/core';
import { UsersService } from '../../users.service';
import { delay, debounceTime, distinctUntilChanged, map, filter } from 'rxjs/operators';
import { User } from '../../models';
import { fromEvent, timer } from 'rxjs';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent implements OnInit {
  private _currentSearch!: string;

  users?: User[];
  inputValue!: string;
  isLoading!: boolean;

  constructor(private userService: UsersService,
    private elementRef: ElementRef) {
  }
  ngOnInit() {
  }


  timeSubscriber: any;

  onTextChange() {
    if (this.timeSubscriber) this.timeSubscriber.unsubscribe();

    let source = timer(1500);
    this.timeSubscriber = source.subscribe(val => {
      this.searchUser(this.inputValue);
      this.timeSubscriber.unsubscribe();
    });
  }

  searchUser(event: any) {
    if (event == null || event === '') {
      return;
    }

    if (event == this._currentSearch) {
      return;
    }

    this._currentSearch = event;

    this.isLoading = true;

    this.userService.getUsers(event).subscribe((users: User[]) => {
      this.users = users;
      this.isLoading = false;
    });
  }

}

import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { forkJoin } from 'rxjs';
import { BaseComponent } from '@premotec/ngx-essentials';
import { UsersService } from '../../users.service';
import { UserDetail, UserLogin, Role, UserRole } from '../../models';
import * as moment from 'moment';
import { TranslocoService } from '@ngneat/transloco';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ConfirmationModalComponent } from 'src/app/shared/confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent extends BaseComponent implements OnInit {

  user!: UserDetail;
  logins!: UserLogin[];
  roles!: Role[];
  userRoles!: UserRole[];
  userDeleted = false;

  isLoading!: boolean;

  // True when locked-out date is provided and is in the future
  userIsLockedOut = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private userService: UsersService,
    private translocoService: TranslocoService,
    private toastrService: ToastrService,
    private modalService: BsModalService
  ) {
    super();
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params['userId']) {
        this.userDeleted = false;
        this.isLoading = true;
        this.getManageUserData(params['userId']);
      }
    }
    );
  }

  getManageUserData(userId: string) {
    this.whileImAlive(
      forkJoin(
        this.userService.getUserById(userId),
        this.userService.getUserLoginsByUserId(userId),
        this.userService.getRoles()
      )
    ).subscribe((data) => {
      this.user = data[0];
      this.userIsLockedOut = this.getUserLockoutStatus(this.user);

      this.userRoles = this.user.roles;
      this.logins = data[1];
      this.roles = this.sortRoles(data[2]);
      this.isLoading = false;
    });
  }

  getUserLockoutStatus(user: UserDetail): boolean {
    if (user == null) { return false; }
    if (!user.lockoutEnabled) { return false; }
    if (user.lockoutEnd == null) { return false; }

    return moment().isBefore(user.lockoutEnd);
  }

  sortRoles(roles: Role[]) {
    return roles.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      } else if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  }

  updateUser(user: UserDetail) {
    this.user = user;
  }

  deleteUser(): void {
    this.whileImAlive(this.userService.deleteUser(this.user.id)).subscribe(() => {
      this.userDeleted = true;
    },
      () => {
        this.toastrService.success(this.translocoService.translate('ManageUsers.DeleteUserError'));
      }
    );
  }

  deleteUserLogin(login: UserLogin): void {
    this.userService.deleteUserLoginLog(this.user.id, login).subscribe(() => {
      const index = this.logins.indexOf(login);
      this.logins.splice(index, 1);
    })
  }

  userHasRole(roleId: string): boolean {
    if (this.userRoles.length) {
      const role = this.userRoles.find(r => r.id.toLowerCase() === roleId.toLowerCase());
      return !!role;
    }
    return false;
  }

  sendPasswordResetRequestMail(): void {
    this.whileImAlive(
      this.userService.sendPasswordResetRequestMail(this.user.id)
    ).subscribe(() => {
      this.toastrService.success(this.translocoService.translate('ManageUsers.PasswordResetRequestMailSuccess'));
    },
      () => {
        this.toastrService.success(this.translocoService.translate('ManageUsers.PasswordResetRequestMailError'));
      });
  }

  resetLockoutDate(): void {
    this.whileImAlive(
      this.userService.resetLockoutDate(this.user.id)
    ).subscribe((user) => {
      this.user = user;
      this.toastrService.success(this.translocoService.translate('ManageUsers.ResetLockoutDateSuccess'));
    },
      () => {
        this.toastrService.error(this.translocoService.translate('ManageUsers.ResetLockoutDateError'));
      });
  }

  verifyUserEmail(): void {
    this.whileImAlive(
      this.userService.verifyUserEmail(this.user.id)
    ).subscribe((user) => {
      this.user = user;
      this.toastrService.success(this.translocoService.translate('ManageUsers.VerifySuccess'));
    },
      () => {
        this.toastrService.error(this.translocoService.translate('ManageUsers.VerifyError'));
      });
  }

  deleteShowModal() {
    const initialstate: ModalOptions = {
      initialState: {
        body: this.translocoService.translate('ManageUsers.ConfirmDelete'),
      }
    }

    const bsModalRef = this.modalService.show(ConfirmationModalComponent, initialstate);

    bsModalRef.content!.confirmed.subscribe(() => {
      this.deleteUser();
    })
  }

  passwordResetMailShowModal() {
    const initialstate: ModalOptions = {
      initialState: {
        body: this.translocoService.translate('ManageUsers.ConfirmMail'),
      }
    }

    const bsModalRef = this.modalService.show(ConfirmationModalComponent, initialstate);

    bsModalRef.content!.confirmed.subscribe(() => {
      this.sendPasswordResetRequestMail();
    })
  }

  unlockingShowModal() {
    const initialstate: ModalOptions = {
      initialState: {
        body: this.translocoService.translate('ManageUsers.ConfirmUnlocking'),
      }
    }

    const bsModalRef = this.modalService.show(ConfirmationModalComponent, initialstate);

    bsModalRef.content!.confirmed.subscribe(() => {
      this.resetLockoutDate();
    })
  }

  loginDeleteShowModal(login: UserLogin) {
    const initialstate: ModalOptions = {
      initialState: {
        body: this.translocoService.translate('ManageUsers.ConfirmLoginDelete'),
      }
    }

    const bsModalRef = this.modalService.show(ConfirmationModalComponent, initialstate);

    bsModalRef.content!.confirmed.subscribe(() => {
      this.deleteUserLogin(login);
    })
  }

  verificationShowModal() {
    const initialstate: ModalOptions = {
      initialState: {
        body: this.translocoService.translate('ManageUsers.ConfirmVerification'),
      }
    }

    const bsModalRef = this.modalService.show(ConfirmationModalComponent, initialstate);
    bsModalRef.content!.confirmed.subscribe(() => {
      this.verifyUserEmail();
    })
  }

}

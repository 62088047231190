<table class="table table-striped table-responsive table-bordered" *ngIf="refundList?.length">
  <thead style="font-weight: bold;">
    <tr>
      <td>{{'BillingID' | transloco}}</td>
      <td>{{'BillingNumberASVZ' | transloco}}</td>
      <td>{{'FullName' | transloco}}</td>
      <td>{{'State' | transloco}}</td>
      <td>{{'Cancelation' | transloco}}</td>
      <td>{{'RefundValue' | transloco}}</td>
      <td *ngIf="isProcessing">{{'ProcessDate' | transloco}}</td>
      <td>{{'RefundInformation' | transloco}}</td>
      <td colspan="2" *ngIf="!isProcessing"></td>
      <td *ngIf="isProcessing"></td>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let refund of refundList">
      <td>{{refund.paymentId}}</td>
      <td>{{refund.invoiceId}}</td>
      <td nowrap="true">{{refund.lastname}}, {{refund.firstname}} ({{refund.asvzId}})</td>
      <td *ngIf="isProcessing">{{refund?.pspPaymentRefund?.status | displayRefundRequestStatus}}</td>
      <td *ngIf="!isProcessing">{{refund.imStatus | displayRefundStatus}}</td>
      <td>{{refund.canellationOnDate | date}} <br>{{refund.cancellationBy}}</td>
      <td class="text-end">{{refund.amountRefund  | currency:'CHF' }}</td>
      <td *ngIf="isProcessing">{{refund?.pspPaymentRefund?.requestedDateTime  | date }}</td>
      <td>{{refund.refundInfoText}}</td>
      <td >
        <button class="btn btn-default btn-sm" (click)="refundDetailModal.open(refund)"
        [tooltip]="'MoreInformations' | transloco" placement="left" [disabled]="isLoading">
        <i class="fa fa-info" aria-hidden="true" ></i>
      </button>
    </td>
      <td class="text-end" *ngIf="!isProcessing">
        <button class="btn btn-danger btn-sm" *ngIf="refund.isSelected" (click)="toggleSelection(refund)"
          [tooltip]="'Deselect' | transloco" placement="left" [disabled]="isLoading">
          <i class="fa fa-minus" aria-hidden="true" style="color: #FFF;"></i>
        </button>
        <button class="btn btn-primary btn-sm" *ngIf="!refund.isSelected" (click)="toggleSelection(refund)"
          [tooltip]="'Select' | transloco" placement="left" [disabled]="isLoading">
          <i class="fa fa-plus" aria-hidden="true" style="color: #FFF;"></i>
        </button>
      </td>
    </tr>
  </tbody>
</table>
<div class="mt-3" *ngIf="refundList">
  <div *ngIf="refundList && !refundList.length" class="lead">
    {{'NoEntries' | transloco}}
  </div>
</div>
<div style="margin-bottom: 3rem;">
  <app-spinner *ngIf="!refundList" [margin]="'1rem auto'"></app-spinner>
</div>
<refund-detail-modal #refundDetailModal [isProcessing]="isProcessing">
</refund-detail-modal>
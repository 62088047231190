import { Component, Input, OnInit } from '@angular/core';
import { OccupancyPlanView } from '../../occupancy-plan-view.model';

@Component({
  selector: 'app-list',
  templateUrl: './occupancy-plan-views-list.component.html',
  styleUrls: ['./occupancy-plan-views-list.component.scss']
})
export class ListComponent implements OnInit {

  @Input()
  occupancyPlanViews!: Array<OccupancyPlanView>;

  query!: string;

  constructor() {
  }

  ngOnInit() {
  }
}

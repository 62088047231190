import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { MaintenanceOverviewComponent } from './maintenance/maintenance-overview/maintenance-overview.component';
import { MaintenanceDetailComponent } from './maintenance/maintenance-overview/maintenance-detail/maintenance-detail.component';
import { UsersOverviewComponent } from './users/users-overview/users-overview.component';
import { UserDetailComponent } from './users/users-overview/user-detail/user-detail.component';
import { RefundListComponent } from './refunds/components/refund-list/refund-list.component';
import { OccupancyPlanViewsOverviewComponent } from './occupancy-plan-views-admin/occupancy-plan-views-overview/occupancy-plan-views-overview.component';
import { OccupancyPlanViewDetailComponent } from './occupancy-plan-views-admin/occupancy-plan-views-overview/occupancy-plan-view-detail/occupancy-plan-view-detail.component';
import { HandHeldScannerNfcComponent } from '../handheldscanner/hand-held-scanner-nfc/hand-held-scanner-nfc.component';
import { AdminAuthGuard } from '../security/guards/admin-auth-guard.service';
import { MembershipApplicationsModule } from './membership-applications/membership-applications.module';
import { LotteryModule } from './lottery/lottery.module';
// import { FacilityResolverService } from './facilities/services/facility-resolver.service';

const routes: Routes = [
  {
    path: 'facilities', 
    loadChildren: () => import('./facilities/facilities.module').then(m => m.FacilitiesModule),
  },
  {
    path: 'occupancyplanviews', component: OccupancyPlanViewsOverviewComponent,
    children: [
      { path: ':occupancyPlanViewId', component: OccupancyPlanViewDetailComponent }
    ]
  },
  {
    path: 'users', component: UsersOverviewComponent,
    children: [
      { path: ':userId', component: UserDetailComponent }
    ]
  },
  {
    path: 'maintenance', component: MaintenanceOverviewComponent,
    children: [
      // {path: 'new', component: MaintenanceDetailComponent},
      { path: ':maintenanceId', component: MaintenanceDetailComponent }
    ]
  },
  {
    path: 'refunds',
    component: RefundListComponent,
    canActivate: [AdminAuthGuard], // role administrator
  },
  {
    path: 'membership-applications',
    loadChildren: () => import('./membership-applications/membership-applications.module').then(m => MembershipApplicationsModule),
  },
  {
    path: 'lottery',
    loadChildren: () => import('./lottery/lottery.module').then(m => LotteryModule),
  }
];


@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
  ],
  exports: [RouterModule],
})
export class OfficeRoutingModule { }

import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, UrlTree } from '@angular/router';
import { UserAuthorizationService } from '../user-authorization.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FacilityManagementAuthGuard implements CanActivate, CanActivateChild {
  constructor(private authService: UserAuthorizationService, private router: Router) { }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivateFn(route, state);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.canActivateFn(childRoute, state);
  }

  private canActivateFn(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authService.getUser().hasRoleFacilityManagement()) return true;
    if (this.authService.getUser().hasRoleOffice()) return true;
    if (this.authService.getUser().hasRoleAdmin()) return true;

    if (this.authService.isLoggedIn()) {
      this.router.navigate(['error']);
    } else {
      this.authService.startAuthentication(state.url);
    }
    return false;
  }
}


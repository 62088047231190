<div [ngClass]="{'mb-3' : !collapsedSidebar}">
    <button *ngIf="!collapsedSidebar" class="btn btn-toggle ps-0 mb-1 d-inline-flex align-items-center border-0" [attr.aria-expanded]="!collapsedItems" (click)="collapsedItems = !collapsedItems">
        <span class="h5 mb-0 text-light">
        <i class="{{iconCode}}"></i>
        {{ title | transloco }}
        </span>
    </button>
    <div  [collapse]="collapsedItems && !collapsedSidebar" [isAnimated]="true">
        <div [ngClass]="collapsedSidebar ? 'link-item-width-collapsed' : 'link-item-width'">
            <ng-content></ng-content>
        </div>
    </div>
</div>
<div class="bg-blue custom-navbar">
    <div class="d-flex align-items-center mb-2">
        <div class="me-auto">
            <div class="text-white text-decoration-none px-3">
                <a href="https://asvz.ch" appExternalUrl><img src="assets/img/logo.png" class="navbar-brand img-fluid" style="height: 50px;"></a>
            </div>
        </div>

        <div class="d-flex align-items-center mb-2">
            <div class=" top-level  mt-1 pe-3">
                <a class="nav-item top-level" style="cursor: pointer;" (click)="leaveAdminMode()" ><i class="fa-solid fa-right-from-bracket"></i> {{'LeaveAdminmode' |  transloco}}</a>
            </div>
        </div>

        <div class="d-flex align-items-center  mb-2">
            <div class="dropdown top-level mt-1 pe-3" dropdown *ngIf="isLoggedIn()">
               
                <a style="cursor:pointer" dropdownToggle class="nav-item top-level">
                    <i class="fa-solid fa-user"></i> {{userName}} <i class="fa fa-chevron-down"></i></a>
                <ul *dropdownMenu class="dropdown-menu dropdown-menu-end" role="menu">
                    <li *ngIf="hasNewRelease()">
                        <hr class="dropdown-divider">
                    </li>
                    <li *ngIf="hasNewRelease()" role="menuitem"><a class="dropdown-item" (click)="reloadNewRelease()">{{'AppAssembly.RefreshApplication' | transloco}}</a></li>
                    <li *ngIf="hasNewRelease()">
                        <hr class="dropdown-divider">
                    </li>
                    <li role="menuitem"><a class="dropdown-item" [routerLink]="'logout'" (click)="logout()"><i class="fa fa-sign-out"></i> Logout</a></li>
                </ul>
            </div>
        </div>
    </div>
</div>
<div class="p-3">
    <div class="row" *ngIf="!tournamentPlan && !gamePlan">
        <div class="col-12 p-3">
            <app-spinner></app-spinner>
        </div>
    </div>

    <div class="row" *ngIf="tournamentPlan">
        <div class="col-12">
            <h1 class="mb-3">{{tournamentPlan.name}}</h1>
        </div>
    </div>
    <div class="row" [ngClass]="{'d-none': gamePlan == undefined || !gamePlan.hasSlots || !gamePlan.hasGroups}">
        <div class="col-12">
            <ag-grid-angular #agGrid style="width: 100%; height: calc(100vh - 200px);" class="ag-theme-alpine"
                [rowData]="rowData" [columnDefs]="columnDefs" [defaultColDef]="defaultColDef"
                (gridReady)="onGridReady($event)" [context]="context" [tooltipShowDelay]="0"
                [enableBrowserTooltips]="true" [enableCellTextSelection]=true>
            </ag-grid-angular>
        </div>
    </div>
</div>
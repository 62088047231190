import { Component, OnInit, Input } from '@angular/core';
import { BaseComponent } from '@premotec/ngx-essentials';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { TranslocoService } from '@ngneat/transloco';
import { ConfirmationModalComponent } from 'src/app/shared/confirmation-modal/confirmation-modal.component';
import { HttpErrorResponse } from '@angular/common/http';
import { MembershipApplicationsService } from '../../membership-applications.service';
import { Application, Configuration } from '../../models';

@Component({
  selector: 'app-pass-photo-uploader',
  templateUrl: './pass-photo-uploader.component.html',
  styleUrls: ['./pass-photo-uploader.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: PassPhotoUploaderComponent,
      multi: true
    }
  ]
})
export class PassPhotoUploaderComponent extends BaseComponent implements OnInit, ControlValueAccessor {

  @Input() application!: Application;
  @Input() config!: Configuration;

  photo: any;

  onChange: any;
  isDisabled!: boolean;

  passPhotoHasValidationError!: boolean;
  passPhotoUploadFailed!: boolean;

  passPhotoDownloadId!: string;

  allowedFileTypes = [
    'image/png',
    'image/jpg',
    'image/jpeg'
  ];

  constructor(
    private applicationService: MembershipApplicationsService,
    private modalService: BsModalService,
    private translocoService: TranslocoService) {
    super();
  }

  ngOnInit() {
    this.getPassPhotoDownloadId();
  }

  writeValue(obj: any): void {
    this.passPhotoDownloadId = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onFileChange(event: any) {
    if (event.target.files.length > 0) {
      const files = event.target.files;
      for (let i = 0; i < files.length; i++) {
        this.uploadFile(files[i]);
      }
    }
  }

  uploadFile(data: File) {

    // determine whether the file type can be uploaded
    if (this.allowedFileTypes.indexOf(data.type) === -1) {
      this.passPhotoHasValidationError = true;
      return;
    }

    const formData = new FormData();
    formData.append('formFile', data);

    this.passPhotoHasValidationError = false;
    this.passPhotoUploadFailed = false;

    this.whileImAlive(this.applicationService.uploadPassPhoto(this.application.id, formData)).subscribe({
      next: () => {
        this.getPassPhotoDownloadId();
      },
      error: (error: HttpErrorResponse) => {
        // handle the error for invalid image format
        switch (error.error.errorStatus) {
          case 'ValidationFailure':
            this.passPhotoHasValidationError = true;
            break;
          default:
            this.passPhotoUploadFailed = true;
        }
      }
    });
  }

  getPassPhotoDownloadId() {
    this.whileImAlive(this.applicationService.getPassPhotoDownloadId(this.application.id)).subscribe(res => {
      this.writeValue(res.downloadId);
      this.onChange(res);
    })
  }

  deletePassPhoto() {
    this.whileImAlive(this.applicationService.deletePassPhoto(this.application.id)).subscribe(res => {
      this.writeValue(null);
      this.onChange(null);
    })
  }

  showModal() {
    const initialstate: ModalOptions = {
      initialState: {
        body: this.translocoService.translate('ConfirmAction'),
        confirmText: this.translocoService.translate('Yes'),
        declineText: this.translocoService.translate('No'),
      }
    }

    const bsModalRef = this.modalService.show(ConfirmationModalComponent, initialstate);

    bsModalRef.content!.confirmed.subscribe(() => {
      this.deletePassPhoto();
    })
  }

}

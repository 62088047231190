<div>
  <div class="card">
    <div class="card-header">
      <span>{{'Users' | transloco }}</span>
      <span class="badge bg-secondary float-end">{{users?.length}}</span>
    </div>

    <div class="card-body">
      <div class="input-group">
        <input type="text" class="form-control" [(ngModel)]="inputValue" (keyup)="onTextChange()" placeholder="{{'Search' | transloco}}" />
        <span class="input-group-text"><i class="fa fa-search form-control-feedback" style="font-size:16px;"></i></span>
      </div>
      <span class="help-block">{{ 'UsersListSearchHelp' | transloco }}</span>
    </div>

    <div class="list-group" *ngIf="!isLoading">
      <a class="list-group-item list-group-item-action" *ngFor="let user of users" [routerLinkActive]="'list-group-item-info'"
        [routerLink]="['/office/users/', user.id]">
        <span>{{user.firstName + ' ' + user.lastName}} ({{user.userName}})</span> <br />
        <span>{{user.email}}</span>
      </a>
    </div>
    <app-spinner *ngIf="isLoading"></app-spinner>
  </div>
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-link-item',
  templateUrl: './link-item.component.html',
  styleUrls: ['./link-item.component.scss']
})
export class LinkItemComponent implements OnInit {

  @Input() hrefLinkInput?: string;
  @Input() useHref: boolean = false;
  @Input() routerLinkInput?: string;
  @Input() collapsedSidebar?: boolean;
  @Input() translocoText?: string;
  @Input() iconCode?: string;

  constructor() { }

  ngOnInit(): void {
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HandHeldScannerService } from './services/hand-held-scanner.service';
import { FormsModule } from '@angular/forms';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { HandHeldScannerNfcComponent } from './hand-held-scanner-nfc/hand-held-scanner-nfc.component';
import { HandHeldScannerQrComponent } from './hand-held-scanner-qr/hand-held-scanner-qr.component';
import { HandHeldScannerResultModalComponent } from './hand-held-scanner-result-modal/hand-held-scanner-result-modal.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { HandheldscannerRoutingModule } from './handheldscanner-routing.module';
import { HandHeldScannerTextComponent } from './hand-held-scanner-text/hand-held-scanner-text.component';
import { HandHeldTypeButtonComponent } from './hand-held-type-button/hand-held-type-button.component';
import { HandHeldPersonDetailModalComponent } from './hand-held-person-detail-modal/hand-held-person-detail-modal.component';
import { ScannerSelectorComponent } from './scanner-selector/scanner-selector.component';
import { CheckoutValidationHistoryComponent } from './checkout-validation-history/checkout-validation-history.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ZXingScannerModule,
    HandheldscannerRoutingModule
  ],
  providers: [
    HandHeldScannerService
  ],
  declarations: [HandHeldScannerQrComponent, HandHeldScannerNfcComponent, HandHeldScannerResultModalComponent, HandHeldScannerTextComponent, HandHeldTypeButtonComponent, HandHeldPersonDetailModalComponent, ScannerSelectorComponent, CheckoutValidationHistoryComponent]
})
export class HandHeldScannerModule { }

<ng-template #template>
    <div class="modal-header">
      <h4 class="modal-title float-start">{{'ManageUsers.ChangeAsvzId' | transloco}}</h4>
      <button type="button" class="btn close float-end" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <app-user-set-asvz-id [asvzIdFormGroup]="asvzIdFormGroup" [user]="user" [submitted]="submitted"></app-user-set-asvz-id>
    </div>
    <div class="modal-footer">
        <button class="btn btn-defaul" (click)="cancel()">{{'Cancel' | transloco}}</button>
        <button class="btn btn-default" (click)="savePassword()">{{'SaveChanges' | transloco}}</button>
    </div>
</ng-template>
<i class="fa fa-edit" (click)="openModal(template)"></i>

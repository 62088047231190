import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ConfirmationModalComponent } from 'src/app/shared/confirmation-modal/confirmation-modal.component';
import { MembershipService } from '../../membership.service';

import { Membership } from '../../models';

@Component({
  selector: 'app-membership-cancel-modal',
  templateUrl: './membership-cancel-modal.component.html',
  styleUrls: ['./membership-cancel-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MembershipCancelModalComponent implements OnInit {

  @Input() membership!: Membership;
  constructor(
    private membershipService: MembershipService,
    private router: Router,
    private modalService: BsModalService,
    private translocoService: TranslocoService) { }

  ngOnInit() {
  }

  cancel() {
    this.router.navigateByUrl('/memberships');
  }

  showModal() {
    const initialstate: ModalOptions = {
      initialState: {
        body: this.translocoService.translate('Memberships.ConfirmCancellation'),
        confirmText: this.translocoService.translate('Yes'),
        declineText: this.translocoService.translate('No'),
      }
    }

    const bsModalRef = this.modalService.show(ConfirmationModalComponent, initialstate);

    bsModalRef.content!.confirmed.subscribe(() => {
      this.cancel();
    })
  }

}

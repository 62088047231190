import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { RegistrationService } from '../registration.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { EventsService } from '../../events.service';
import { RegistrationStates } from '../registration-states.enum';
import { RequiredSkill } from '../../required-skill.model';
import { AccountService } from '../../../account/account.service';
import { PrerequisitesService } from '../../../shared/prerequisites-view/prerequisites.service';
import { Account } from '../../../account/account.model';
import { RegistrationSummary } from '../registration-summary.model';

@Component({
  selector: 'app-registration-prove-permission',
  templateUrl: './registration-prove-permission.component.html',
  styleUrls: ['./registration-prove-permission.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RegistrationProvePermissionComponent implements OnInit {

  eventId!: number;
  registration!: RegistrationSummary;
  registrationState = RegistrationStates;
  missingSkills: RequiredSkill[] = [];

  constructor(private registrationService: RegistrationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private eventService: EventsService,
    private accountService: AccountService,
    private prerequisitesService: PrerequisitesService) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.eventId = + params['eventId'];
      this.proveRegistrationPermission();
    });
  }

  proveRegistrationPermission(): void {
    this.eventService.getEventById(this.eventId).subscribe(event => {

      this.accountService.getAccountDetails().subscribe((account: Account) => {

        this.missingSkills = this.prerequisitesService.getMissingSkills(
            event.requiredSkills,
            account.skills!,
            event.registrationFrom,
            event.starts,
            event.ends
        );

        if (this.eventService.userCanRegisterToEvent(event, this.missingSkills)) {
          this.registrationService.registerForEvent(this.eventId).subscribe(
            registration => {
              const redirectUrl = this.registrationService.getCorrespondingRegistrationViewUrl(registration.status, registration.id, registration. invoiceId, registration.eventId);
              this.router.navigateByUrl(redirectUrl);
            },
            error => {
              this.router.navigateByUrl(`/events/${this.eventId}`);
          });
        } else {
          this.router.navigateByUrl(`/events/${this.eventId}`);
        }
      });
    });
  }
}


<div class="well mb-3 text-end">
    <button class="btn btn-sm btn-danger" (click)="openDelteTournamentPlanConfirmModal()" [disabled]="isLoading">
        <i class="fa-solid fa-trash"></i> {{'Tournaments.Delete' | transloco}}
    </button>
    <!-- <button class="btn btn-sm btn-primary" (click)="delteTournamentPlan()" [disabled]="true">
        <i class="fa-solid fa-box-archive"></i> {{'Tournaments.Archive' | transloco}}
    </button> -->
</div>

<div class="row">
    <div class="col-4">
        <div class="card mb-3">
            <div class="card-header">
                <div class="d-flex">
                    <div class="flex-grow-1"><b>{{'Tournaments.Configuration.BasicData.Title' | transloco}}</b></div>
                    <div>
                        <button class="btn btn-secondary btn-sm" (click)="openBasicDataModal()">
                            <i class="fa-solid fa-pen-to-square"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="card-body p-3" *ngIf="!tournament">
                <app-spinner></app-spinner>
            </div>
            <div class="card-body" *ngIf="tournament">
                <div class="row">
                    <div class="col-12 mb-3">
                        <label class="form-label">{{'Tournaments.Configuration.BasicData.Name' | transloco}}</label>
                        <input class="form-control" [(ngModel)]="tournament.name" disabled="true" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 mb-3">
                        <label for="eventId"
                            class="form-label d-block mb-2">{{'Tournaments.Configuration.BasicData.Types' |
                            transloco}}</label>
                        <div class="d-inline p-2 border bg-secondary text-light border-secondary rounded"
                            *ngIf="tournament.type == TournamentTypeEnum.Day">
                            {{'Tournaments.Configuration.BasicData.Type_Day' | transloco}}
                        </div>
                        <div class="d-inline p-2 border bg-secondary text-light border-secondary rounded"
                            *ngIf="tournament.type == TournamentTypeEnum.Semester">
                            {{'Tournaments.Configuration.BasicData.Type_Semester' | transloco}}
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <label class="form-label">{{'Tournaments.Configuration.BasicData.ResultEntry' |
                            transloco}}</label>
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" disabled="true"
                                [(ngModel)]="tournament.allowCaptainEntry" />
                            <span class="form-check-label fw-normal" style="opacity: 1;">
                                {{'Tournaments.Configuration.BasicData.AllowCaptainResultEntry' | transloco}}
                            </span>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <label class="form-label">{{'Tournaments.Configuration.BasicData.UseTeamAsReferee' |
                            transloco}}</label>
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" disabled="true"
                                [(ngModel)]="tournament.useTeamAsReferee" />
                            <span class="form-check-label fw-normal" style="opacity: 1;">
                                {{'Tournaments.Configuration.BasicData.AllowCaptainResultEntry' | transloco}}
                            </span>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <label class="form-label">{{'Tournaments.Configuration.BasicData.TeamRefereeResultentry' |
                            transloco}}</label>
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" disabled="true"
                                [(ngModel)]="tournament.allowTeamRefereeEntry" />
                            <span class="form-check-label fw-normal" style="opacity: 1;">
                                {{'Tournaments.Configuration.BasicData.AllowTeamRefereeResultentry' | transloco}}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-4">
        <div class="card mb-3">
            <div class="card-header">
                <div class="d-flex">
                    <div class="flex-grow-1"><b>{{'Tournaments.CaptainMailText.Title' | transloco}}</b></div>
                    <div>
                        <button class="btn btn-secondary btn-sm" (click)="openCaptainMailTextModal()">
                            <i class="fa-solid fa-pen-to-square"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="card-body p-3" *ngIf="!captainMailText">
                <app-spinner></app-spinner>
            </div>
            <div class="card-body p-3" *ngIf="captainMailText && captainMailText.text == null">
                <div class="alert alert-secondary m-0">
                    {{'Tournaments.CaptainMailText.Empty' | transloco}}
                </div>
            </div>
            <div class="card-body p-3" *ngIf="captainMailText && captainMailText.text != null">
                <div [innerHTML]="captainMailText.text" class="quill-editor-content-display">
                </div>
                <div>
                    <p>
                        <a href="">Gesamtspielplan</a>
                        <span *ngIf="captainMailText.isLinkEnabled"> | <a href="">{{captainMailText.linkText}}</a>
                        </span>
                    </p>
                </div>
                <div>
                    <p class="m-0">
                        Sportliche Grüsse<br>
                        Dein ASVZ-Team<br>
                        ………………………………………………………………
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div class="col-4">
        <div class="card mb-3">
            <div class="card-header">
                <div class="d-flex">
                    <div class="flex-grow-1"><b>{{'Tournaments.Administrators.Title' | transloco}}</b></div>
                    <div>
                        <button class="btn btn-success btn-sm" (click)="openAdministratorModal()">
                            <i class="fa-solid fa-plus"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="card-body p-3" *ngIf="administrators == undefined">
                <app-spinner></app-spinner>
            </div>
            <ul class="list-group list-group-flush" *ngIf="administrators != undefined && administrators.length">
                <li class="list-group-item" *ngFor="let administrator of administrators">
                    <div class="d-flex">
                        <div class="flex-grow-1">
                            <b>{{administrator.firstName}} {{administrator.lastName}}</b> - {{administrator.asvzId}}
                        </div>
                        <div>
                            <button class="btn btn-sm btn-danger"
                                (click)="openRemoveAdministratorModal(administrator.personId)">
                                <i class="fa-solid fa-trash"></i>
                            </button>
                        </div>
                    </div>


                </li>
            </ul>
            <div class="card-body p-3 border-bottom" *ngIf="administrators != undefined && !administrators.length">
                <div class="alert alert-secondary m-0">
                    {{'Tournaments.Administrators.NoAdmins' | transloco}}
                </div>
            </div>
            <div class="card-body p-3">
                <label class="form-label">
                    {{'Tournaments.Events.Links.ResultEntry' | transloco}}
                </label>
                <div class="input-group">
                    <a class="btn btn-outline-secondary" [routerLink]="'/tournaments/'+tournamentPlanId+'/admin/games'"
                        [target]="'_blank'">
                        <i class="fa-solid fa-link"></i>
                    </a>
                    <input class="form-control" [value]="baseUrl+'/tournaments/'+tournamentPlanId+'/admin/games'"
                        disabled="true" />

                </div>
            </div>
        </div>
    </div>
</div>
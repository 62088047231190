import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { SharedModule } from '../shared/shared.module';
import { TranslocoService } from '@ngneat/transloco';
import { MembershipsComponent } from './memberships.component';
import { MembershipsWizardComponent } from './memberships-wizard/memberships-wizard.component';
import { MembershipProductsComponent } from './memberships-wizard/membership-products/membership-products.component';
import { MembershipTemporaryComponent } from './memberships-wizard/membership-temporary/membership-temporary.component';
import { MembershipCheckUserDataComponent } from './memberships-wizard/membership-check-user-data/membership-check-user-data.component';
import { MembershipCancelModalComponent } from './memberships-wizard/membership-cancel-modal/membership-cancel-modal.component';
import { MembershipChangeValidFromModalComponent } from './memberships-wizard/membership-change-valid-from-modal/membership-change-valid-from-modal.component';
import { MembershipCheckPassPhotoComponent } from './memberships-wizard/membership-check-pass-photo/membership-check-pass-photo.component';
import { MembershipsRoutingModule } from './memberships-routing.module';
import { NgxDatepickerModule } from '@premotec/ngx-datepicker';
import { MembershipProductsInfoModalComponent } from './memberships-wizard/membership-products-info-modal/membership-products-info-modal.component';
import { MembershipCheckPastSchoolComponent } from './memberships-wizard/membership-check-past-school/membership-check-past-school.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { MembershipsHealthInsuranceRequestModalComponent } from './memberships-health-insurance-request/memberships-health-insurance-request-modal/memberships-health-insurance-request-modal.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    MembershipsRoutingModule,
    ReactiveFormsModule,
    NgxDatepickerModule,
    FormsModule,
    NgSelectModule
  ],
  declarations: [
    MembershipsComponent,
    MembershipsWizardComponent,
    MembershipProductsComponent,
    MembershipTemporaryComponent,
    MembershipCheckUserDataComponent,
    MembershipCancelModalComponent,
    MembershipChangeValidFromModalComponent,
    MembershipCheckPassPhotoComponent,
    MembershipProductsInfoModalComponent,
    MembershipCheckPastSchoolComponent,
    MembershipsHealthInsuranceRequestModalComponent
  ],
  exports: [
    MembershipsComponent
  ],
  providers: [
    DecimalPipe
  ]
})
export class MembershipsModule {
  constructor(translate: TranslocoService) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('de');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    // translate.use('de');
  }
}

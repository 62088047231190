import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Account } from './account.model';
import { AccountPut } from './account-put.model';
import { HttpClient } from '@angular/common/http';
import { DateHandlerService } from '../shared/date-handler.service';
import { UpdatePassword } from './update-password.model';
import { HttpResponseErrorHandlerService } from '../shared/services/http-response-error-handler.service';

@Injectable({
    providedIn: 'root'
  })
export class AccountService {

    public static accountChangedEvent: EventEmitter<Account> = new EventEmitter<Account>();

    constructor(private httpService: HttpClient,
        private httpResponseErrorHandler: HttpResponseErrorHandlerService,
        private dateHandler: DateHandlerService) {
    }

    getAccountDetails(): Observable<Account> {
        return this.httpService.get(`${environment.api}/MemberPerson`)
            .pipe(
                map((account: any): Account => {
                    const response = account as Account;

                    response.birthDate = this.dateHandler.takeAsUtcWithLocalTimeZone(response.birthDate!).toDate();

                    return response;
                }),
                catchError(e => this.httpResponseErrorHandler.HandleResponseError(e))
            );
    }

    updateAccountDetails(updatedData: AccountPut): Observable<Account> {
        return this.httpService.put(`${environment.api}/MemberPerson`, updatedData)
            .pipe(
                map((account: any): Account => account.data)
            )
    }

    getPicture(): Observable<Blob> {
        return this.httpService.get(`${environment.api}/MemberPerson/Picture`)
            .pipe(
                map((picture: any): Blob => picture)
            )
    }

    uploadPicture(file: FormData): Observable<any> {
        return this.httpService.post(`${environment.api}/MemberPerson/Picture`, file).pipe(
            map((res: any) => res)
        );
    }

    updateEmail(newEmail: string): Observable<any> {
        return this.httpService.put(`${environment.identity.api}/Users/Email`, { NewEmail: newEmail }).pipe(
            map((res: any) => res)
        );
    }

    updatePassword(model: UpdatePassword): Observable<any> {
        return this.httpService.put(`${environment.identity.api}/Users/Password`, model).pipe(
            map((res: any) => res)
        );
    }
}

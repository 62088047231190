import { Injectable } from "@angular/core";
import { RegistrationDetail } from "src/app/events/registration-details.model";
import { ControlType } from "src/app/events/registration/registration-details/control-type.enum";
import { Discount } from "../models/discount-giftcard/discount.model";
import { GiftCard } from "../models/discount-giftcard/giftcard.model";

@Injectable({
    providedIn: 'root'
})
export class PriceService {

    calcItemPrices(registrationDetails: RegistrationDetail[]): number {
        if (!registrationDetails) return 0;
        var total = 0;

        total += this.calcDropDownItems(registrationDetails);
        total += this.calcCheckboxItems(registrationDetails);
        total += this.calcTriStatieItems(registrationDetails);

        return total;
    }

    private calcDropDownItems(registrationDetails: RegistrationDetail[]): number {
        var calcDropdownItems = registrationDetails.filter((obj) => {
            return obj.type == ControlType.Dropdown;
        });

        var ammount = 0;

        calcDropdownItems.forEach(x => {

            const option = x.dropdownOptions.find((obj: any) => {
                return obj.option == x.defaultValue;
            });
            if (option) ammount += option.price;
        });

        return ammount;
    }

    private calcCheckboxItems(registrationDetails: RegistrationDetail[]): number {
        var calcDropdownItems = registrationDetails.filter((obj) => {
            return obj.type == ControlType.Checkbox;
        });

        var ammount = 0;

        calcDropdownItems.forEach(x => {

            var value = 0;

            if (x.defaultValue == 'true') value = x.yesPrice;
            if (x.defaultValue == 'false') value = x.noPrice;

            if (value) ammount += value;
        });

        return ammount;
    }

    private calcTriStatieItems(registrationDetails: RegistrationDetail[]): number {
        var calcDropdownItems = registrationDetails.filter((obj) => {
            return obj.type == ControlType.TriState;
        });

        var ammount = 0;

        calcDropdownItems.forEach(x => {

            var value = 0;

            if (x.defaultValue == 'true') value = x.yesPrice;
            if (x.defaultValue == 'false') value = x.noPrice;
            if (x.defaultValue == null) value = x.undefinedPrice;

            if (value) ammount += value;
        });

        return ammount;
    }

    calcAmmount(invoiceAmount: number, discount: Discount | undefined, giftCard: GiftCard | undefined): number | undefined {
        if ((discount === null || discount === undefined) && (giftCard === null || giftCard === undefined)) {
            // no discount and no gift card
            return invoiceAmount;
        } else if ((discount !== null && discount !== undefined) && (giftCard === null || giftCard === undefined)) {
            // only discount

            if (discount.discountPercent) {
                var ammount = invoiceAmount / 100 * discount.discountPercent;

                return invoiceAmount - ammount;
            }
            var discountedAmmount = invoiceAmount - discount.discountAmount;

            if (discountedAmmount < 0) discountedAmmount = 0;

            return discountedAmmount;

        } else if ((discount === null || discount === undefined) && (giftCard !== null && giftCard !== undefined)) {
            // only gift card

            const totalAmount: number = invoiceAmount - giftCard.outstandingAmount;

            if (totalAmount < 0) {
                return 0;
            }

            return totalAmount;

        } else if ((discount !== null && discount !== undefined) && (giftCard !== null && giftCard !== undefined)) {
            if (discount.discountPercent) {
                var ammount = invoiceAmount / 100 * discount.discountPercent;

                var reduced = invoiceAmount - ammount - giftCard.outstandingAmount;
                if (reduced < 0) reduced = 0;
                return reduced;
            }
            // discount and gift card
            // calc
            const totalAmount = invoiceAmount - discount.discountAmount - giftCard.outstandingAmount;

            if (totalAmount < 0) {
                return 0;
            }

            return totalAmount;
        }

        return undefined;
    }

}

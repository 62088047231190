<app-spinner margin="100px auto" *ngIf="isLoading"></app-spinner>

<!-- Scan QrCode -->
<div class="row mt-6" *ngIf="selectedAccessSystemScannerId?.value">
    <div class="col-md-2">
        <zxing-scanner #qrScanner (camerasFound)="camerasFoundHandler($event)"
            (scanSuccess)="qrCodeScanSuccessHandler($event)"></zxing-scanner>
    </div>
</div>

<!-- select scanner -->
<div class="row">
    <div class="col-md-4">
        <label>{{ 'HandHeldScanner.Scanner' | transloco }}</label>
        <app-scanner-selector [formControl]="selectedAccessSystemScannerId"></app-scanner-selector>
    </div>
</div>

<!-- select camera -->
<div class="row mt-3 mb-3" *ngIf="selectedAccessSystemScannerId?.value">
    <div class="col-md-4">
        <label>{{ 'HandHeldScanner.Camera' | transloco }}</label>
        <select id="selectedCamera" class="form-control" [(ngModel)]="selectedCamera" (change)="cameraChanged()">
            <option *ngFor="let camera of cameras" [ngValue]="camera">
                {{ camera.label }}
            </option>
        </select>
    </div>
</div>
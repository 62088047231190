import { Component, OnInit, TemplateRef, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators, FormGroup, AbstractControl } from '@angular/forms';
import { UsersService } from '../../users.service';
import { BaseComponent } from '@premotec/ngx-essentials';
import { User, UserNamePutModel, UserDetail } from '../../models';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TranslocoService } from '@ngneat/transloco';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-user-set-asvz-id-modal',
  templateUrl: './user-set-asvz-id-modal.component.html',
  styleUrls: ['./user-set-asvz-id-modal.component.scss']
})
export class UserSetAsvzIdModalComponent extends BaseComponent implements OnInit {

  @Input() user!: UserDetail;
  @Output() userChange: EventEmitter<UserDetail> = new EventEmitter<UserDetail>();
  asvzIdFormGroup!: FormGroup;
  submitted = false;

  modalRef!: BsModalRef;

  labelChangePassword: any;

  constructor(
    private modalService: BsModalService,
    private fb: FormBuilder,
    private translocoService: TranslocoService,
    private toastrService: ToastrService,
    private userService: UsersService) {
    super();
  }

  ngOnInit() {
    this.labelChangePassword = this.translocoService.translate('Save');
    this.initForm();
  }

  initForm() {
    this.asvzIdFormGroup = this.fb.group({
      'asvzId': [this.user.asvzId, [Validators.required, Validators.minLength(3), Validators.maxLength(100)]],
      'addressId': [this.user.imAddressId, [Validators.required, Validators.minLength(3), Validators.maxLength(100)]],
    });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  cancel() {
    this.modalRef.hide();
  }
  savePassword() {
    this.submitted = true;
    if (!this.asvzIdFormGroup.invalid) {
      const userNamePut: UserNamePutModel = {
        asvzId: +this.asvzIdFormGroup.get('asvzId')?.value,
        addressId: +this.asvzIdFormGroup.get('addressId')?.value,
        userName: `${this.asvzIdFormGroup.get('asvzId')?.value}`
      };

      this.whileImAlive(this.userService.updateUserName(this.user.id, userNamePut)
      ).subscribe((user: UserDetail) => {
        this.userChange.emit(user);
        this.modalRef.hide();
        this.toastrService.success(this.translocoService.translate('ManageUsers.UpdateUserNameSuccess'));
      },
        () => {
          this.modalRef.hide();
          this.toastrService.error(this.translocoService.translate('ManageUsers.UpdateUserNameError'));
        });
    } else {
      console.log('invalid');
    }
  }
}

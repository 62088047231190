<div [formGroup]="slotGroup">
    <label for="slot" class="form-label">{{slotName}}</label>

    <div>
        <ng-select formControlName="slot" id="slot"
            [ngClass]="{'border-danger':  !slotGroup.controls['slot'].valid && formSubmitted}"
            (ngModelChange)="setDayOptions()">
            <ng-option *ngFor="let option of dayMappingFields" [value]="option.imId">
                {{option.imName}}
            </ng-option>
        </ng-select>
    </div>
    <div *ngIf="slotGroup.controls['slot'].value != null">
        <div class="dropdown mt-3 text-end" dropdown>
            <button dropdownToggle class="btn btn-sm btn-secondary">
                <i class="fa-solid fa-copy"></i> Kopieren <i class="fa fa-chevron-down text-light"></i>
            </button>
            <ul *dropdownMenu class="dropdown-menu dropdown-menu-end" role="menu">
                <li role="menuitem" *ngIf="dayOfWeek != 1">
                    <button class="dropdown-item" (click)="copyFromDay(1)">
                        {{'Day_mon' | transloco}}
                    </button>
                </li>
                <li role="menuitem" *ngIf="dayOfWeek != 2">
                    <button class="dropdown-item" (click)="copyFromDay(2)">
                        {{'Day_tue'| transloco}}
                    </button>
                </li>
                <li role="menuitem" *ngIf="dayOfWeek != 3">
                    <button class="dropdown-item" (click)="copyFromDay(3)">
                        {{'Day_wed'| transloco}}
                    </button>
                </li>
                <li role="menuitem" *ngIf="dayOfWeek != 4">
                    <button class="dropdown-item" (click)="copyFromDay(4)">
                        {{'Day_thu'| transloco}}
                    </button>
                </li>
                <li role="menuitem" *ngIf="dayOfWeek != 5">
                    <button class="dropdown-item" (click)="copyFromDay(5)">
                        {{'Day_fri'| transloco}}
                    </button>
                </li>
                <li role="menuitem" *ngIf="dayOfWeek != 6">
                    <button class="dropdown-item" (click)="copyFromDay(6)">
                        {{'Day_sat'| transloco}}
                    </button>
                </li>
                <li role="menuitem" *ngIf="dayOfWeek != 0">
                    <button class="dropdown-item" (click)="copyFromDay(0)">
                        {{'Day_sun'| transloco}}
                    </button>
                </li>
            </ul>
        </div>
    </div>
    <div formArrayName="slotConfig" class="mt-3">
        <div class="row" *ngFor="let slotConfigForm of slotConfig.controls let i = index" [formGroupName]="i">
            <div class="d-flex">
                <div class="flex-grow-1">
                    {{slotConfig.at(i).get('name')?.value}}
                </div>
                <div>
                    <div class="input-group mb-3" style="white-space: nowrap">
                        <span class="input-group-text" id="basic-addon1" style="max-width: 50px;">Von:</span>
                        <input type="text" class="form-control" placeholder="00:00" style="max-width: 65px;"
                            [ngClass]="{'border-danger':  !slotConfig.at(i).get('from')?.valid && formSubmitted}"
                            formControlName="from">
                        <span class="input-group-text" id="basic-addon1" style="max-width: 50px;">Bis:</span>
                        <input type="text" class="form-control" placeholder="00:00" style="max-width: 65px;"
                            [ngClass]="{'border-danger':  !slotConfig.at(i).get('to')?.valid && formSubmitted}"
                            formControlName="to">
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<div class="modal-header text-light">
    <h3>{{'Tournaments.Events.Game.ResultEntry.Title' | transloco}}</h3>
</div>
<div class="modal-body" *ngIf="!results">
    <app-spinner [margin]="'25px'"></app-spinner>
</div>

<div class="modal-body" *ngIf="results">
    <div class="well">
        <div>
            <b>{{tournamentDisplayService.getTimeSlotName(results.gameFrom, results.gameTo)}}</b>
        </div>
        <div>
            <i class="fa-solid fa-location-dot text-secondary"></i> {{results.gameFacilityName}}
            {{results.gameRoomName}}
            {{tournamentDisplayService.getFieldName(results.gameField)}}
        </div>
    </div>
    <div class="d-flex mt-3">
        <div class="w-50 text-center">
            <b>{{results.homeTeamName}}</b>
        </div>
        <div class="w-50 text-center">
            <b>{{results.guestTeamName}}</b>
        </div>
    </div>
    <form [formGroup]="resultsForm" (ngSubmit)="confirm()">
        <div formArrayName="gameSets">
            <div *ngFor="let gameSet of getGameSets().controls; let i=index">
                <div class="text-center" *ngIf="results.sets.length > 1">
                    <label>Satz {{i+1}}</label>
                </div>
                <div class="d-flex mt-1" [formGroupName]="i">
                    <div class="flex-grow-1">
                        <input id="currentHomeTeamName" class="form-control text-end"
                            [ngClass]="{'border-danger':  !isResultFormGroupValid(getGameSets().controls[i], 'homePoints') && formSubmitted}"
                            formControlName="homePoints" type="number" min="0">
                    </div>
                    <div class="px-3">
                        :
                    </div>
                    <div class="flex-grow-1">
                        <input id="currentHomeTeamName" class="form-control"
                            [ngClass]="{'border-danger':  !isResultFormGroupValid(getGameSets().controls[i], 'guestPoints') && formSubmitted}"
                            formControlName="guestPoints" type="number" min="0">
                    </div>
                </div>
            </div>
        </div>
    </form>

    <div class="alert alert-danger m-0 mt-3" *ngIf="!resultsForm.valid && formSubmitted">
        {{'Tournaments.Events.Game.ResultEntry.Requred' | transloco}}
    </div>
</div>

<div class="modal-footer text-light">
    <button type="button" class="btn btn-default ms-2" (click)="decline()" [disabled]="processing">
        {{'Cancel' | transloco}}
    </button>

    <button type="submit" class="btn btn-success ms-2" (click)="confirm()" [disabled]="processing">
        {{'Save' | transloco}}
    </button>
</div>
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { TournamentService } from '../services/tournament.service';
import { TournamentModel } from '../models/tournament.model';
import { TournamentTypeEnum } from '../enums/tournament-type.enum';
import { TournamentConfigruationModalComponent } from '../tournament-configuration-modal/tournament-configuration-modal.component';
import { TournamentAdministratorsModalComponent } from '../tournament-administrators-modal/tournament-administrators-modal.component';
import { TournamentAdministratorModel } from '../models/tournament-administrator.model';
import { ConfirmationModalComponent } from 'src/app/shared/confirmation-modal/confirmation-modal.component';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { TournamentCaptainMailTextModel } from '../models/tournament-captain-mail-text.model';
import { TournamentCaptainMailTextModalComponent } from '../tournament-captain-mail-text-modal/tournament-captain-mail-text-modal.component';

@Component({
    selector: 'app-tournament-configuration-details',
    templateUrl: './tournament-configuration-details.component.html',
    styleUrls: ['./tournament-configuration-details.component.scss'],
})
export class TournamentConfigurationDetailsComponent implements OnInit {

    @Input() tournamentPlanId!: string;

    tournament!: TournamentModel | null;
    administrators!: TournamentAdministratorModel[];
    captainMailText!: TournamentCaptainMailTextModel;

    baseUrl: string = environment.localSite;

    TournamentTypeEnum = TournamentTypeEnum;

    isLoading!: boolean;

    constructor(
        private translocoService: TranslocoService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private tournamentService: TournamentService,
        private fb: FormBuilder,
        private datepipe: DatePipe,
        private toastrService: ToastrService,
        private modalService: BsModalService
    ) {
    }

    ngOnInit() {
        if (this.activatedRoute.parent) {
            this.activatedRoute.parent.params.subscribe((params: Params) => {
                this.tournament = null;
                this.tournamentPlanId = params['tournamentPlanId'];
                this.loadTournament();
                this.loadAdministrators();
                this.loadCaptainMailText();
            });
        }
    }

    loadTournament() {
        this.tournamentService.getTournamentById(this.tournamentPlanId).subscribe(x => {
            this.tournament = x;
        });
    }

    loadAdministrators() {
        this.tournamentService.getAdministrators(this.tournamentPlanId).subscribe(x => {
            x = x.sort((a, b) => (a.firstName + a.lastName + a.asvzId).localeCompare(b.firstName + b.lastName + b.asvzId));
            this.administrators = x;
        });
    }

    loadCaptainMailText() {
        this.tournamentService.getCaptainMailText(this.tournamentPlanId).subscribe(x => {
            this.captainMailText = x;
        });
    }

    openDelteTournamentPlanConfirmModal() {
        const initialstate: ModalOptions = {
            initialState: {
                body: this.translocoService.translate('Tournaments.ConfirmDelete'),
                color: 'danger',
                confirmText: this.translocoService.translate('Delete')
            }
        }

        const bsModalRef = this.modalService.show(ConfirmationModalComponent, initialstate);

        bsModalRef.content!.confirmed.subscribe(() => {
            this.delteTournamentPlan();
        })
    }

    delteTournamentPlan() {
        this.tournamentService.deleteTournamentById(this.tournamentPlanId).subscribe(x => {
            if (x) {
                this.router.navigateByUrl('/tournaments/admin');
                this.toastrService.success(this.translocoService.translate('Tournaments.DeleteSuccess'));
            } else {
                this.toastrService.error(this.translocoService.translate('Tournaments.DeleteError'));
            }
        });
    }

    openRemoveAdministratorModal(personId: number) {
        const initialstate: ModalOptions = {
            initialState: {
                body: this.translocoService.translate('Tournaments.Administrators.Delete'),
                color: 'danger',
                confirmText: this.translocoService.translate('Delete')
            }
        }

        const bsModalRef = this.modalService.show(ConfirmationModalComponent, initialstate);

        bsModalRef.content!.confirmed.subscribe(() => {
            this.removeAdministrator(personId);
        })
    }

    removeAdministrator(personId: number) {
        this.tournamentService.removeAsAdministrator(this.tournamentPlanId, personId).subscribe(x => {
            this.loadAdministrators();
        });
    }

    openBasicDataModal() {
        const initialState: ModalOptions = {
            backdrop: 'static',
            // class: 'modal-lg',
            keyboard: false,
            initialState: {
                tournamentPlanId: this.tournamentPlanId,
            },
        };

        const bsModalRef = this.modalService.show(TournamentConfigruationModalComponent, initialState);
        bsModalRef.content?.confirmed.subscribe(x => {
            this.loadTournament();
        });
    }

    openAdministratorModal() {
        const initialState: ModalOptions = {
            backdrop: 'static',
            // class: 'modal-lg',
            keyboard: false,
            initialState: {
                tournamentPlanId: this.tournamentPlanId,
            },
        };

        const bsModalRef = this.modalService.show(TournamentAdministratorsModalComponent, initialState);
        bsModalRef.content?.confirmed.subscribe(x => {
            if (x) this.loadAdministrators();
        });
    }

    openCaptainMailTextModal() {
        const initialState: ModalOptions = {
            backdrop: 'static',
            class: 'modal-lg',
            keyboard: false,
            initialState: {
                tournamentPlanId: this.tournamentPlanId,
            },
        };

        const bsModalRef = this.modalService.show(TournamentCaptainMailTextModalComponent, initialState);
        bsModalRef.content?.confirmed.subscribe(x => {
            if (x) this.loadCaptainMailText();
        });
    }


}

import { Component, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-confirm-leave',
  templateUrl: './confirm-leave.html',
  encapsulation: ViewEncapsulation.None
})
export class ConfirmLeaveComponent {

  modalText = 'ConfirmLeaveText';

  subject?: Subject<boolean>;

  constructor(public bsModalRef: BsModalRef) { }

  action(value: boolean) {
    this.bsModalRef.hide();
    this.subject!.next(value);
    this.subject!.complete();
  }

}

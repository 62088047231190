<h2 class="text-light">Informationen</h2>
<div>
    <table class="table information-table">
      <ng-container *ngFor="let announcement of announcements">
        <tr>
          <td><h3  class="font-big text-light">{{ announcement.title }}</h3></td>
        </tr>
        <tr>
          <td class="font-normal text-light">{{ announcement.text }}</td>
        </tr>
      </ng-container>
    </table>
</div>
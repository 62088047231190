import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-user-set-password',
  templateUrl: './user-set-password.component.html',
  styleUrls: ['./user-set-password.component.scss']
})
export class UserSetPasswordComponent implements OnInit {

  @Input() passwordFormGroup!: FormGroup;
  @Input() submitted!: boolean;

  constructor() { }

  ngOnInit() {
  }

}

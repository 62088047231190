import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { RegistrationMainComponent } from './registration-main/registration-main.component';
import { Subject, of } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmLeaveComponent } from '../../shared/confirm-leave/confirm-leave.component';

@Injectable({
    providedIn: 'root'
  })
export class RegistrationGuardService implements CanDeactivate<RegistrationMainComponent> {

    private _outOfTime = false;
    private _formIsDirty = false;

    set outOfTime(state: any) {
        this._outOfTime = state;
    }

    set formIsDirty(state: any) {
        this._formIsDirty = state;
    }

    config = {
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: true
    };

    constructor(private modalService: BsModalService) {

    }

    canDeactivate() {
        if (this._formIsDirty && !this._outOfTime) {
            const subject = new Subject<boolean>();
            const modal = this.modalService.show(ConfirmLeaveComponent, this.config);
            modal.content!.subject = subject;

            return subject.asObservable();
        }
        return of(true);
    }

}

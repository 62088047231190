import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MembershipTemporaryComponent } from './memberships-wizard/membership-temporary/membership-temporary.component';
import { MembershipsWizardComponent } from './memberships-wizard/memberships-wizard.component';
import { MembershipsComponent } from './memberships.component';


const routes: Routes = [
    { path: '', component: MembershipsComponent},
    { path: 'new', component: MembershipsWizardComponent},
    { path: ':membershipId/wizard', component: MembershipsWizardComponent},
    { path: 'temporary', component: MembershipTemporaryComponent},
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(routes)]
})
export class MembershipsRoutingModule { }

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '@premotec/ngx-essentials';
import { MembershipApplicationsService } from '../../membership-applications.service';
import { Application } from '../../models';
import { Location } from '@angular/common';
import { MemebershipApplicationStatus } from '../../enums/membership-application-status.enum';

@Component({
  selector: 'app-membership-application',
  templateUrl: './membership-application.component.html',
  styleUrls: ['./membership-application.component.scss']
})
export class MembershipApplicationComponent extends BaseComponent implements OnInit {

  application?: Application;
  currentStep = 1;
  applicationStatus = MemebershipApplicationStatus;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private applicationService: MembershipApplicationsService,
    private router: Router) {
    super();
  }
  ngOnInit() {
    this.whileImAlive(this.route.params).subscribe(params => {

      const membershipApplicationId = params['membershipApplicationId'];

      if (membershipApplicationId != null) {
        if (membershipApplicationId.length !== 36) { // length of guid = 36
          this.router.navigateByUrl('notFound');
        }

        this.getById(membershipApplicationId);
      } else {
        const imMembershipId = +params['imMembershipId'];
        this.createNewMemebershipApplication(imMembershipId);
      }
    });
  }

  getById(id: string): void {
    this.whileImAlive(this.applicationService.getById(id)).subscribe({
      next: (res: Application) => {
        this.application = res;
      },
      error: () => {
        this.router.navigateByUrl('error');
      }
    });
  }

  createNewMemebershipApplication(id: number): void {
    this.whileImAlive(this.applicationService.createNewFromMembershipId(id)).subscribe({
      next: (res: Application) => {
        this.application = res;
        this.location.replaceState(`membership-application/${this.application.id}`);
      },
      error: () => {
        this.router.navigateByUrl('error');
      }
    });
  }

  nextStep(step: any) {
    this.currentStep = step;
    window.scrollTo(0, 0);
  }

  updateApplication(application: any): void {
    this.application = application;
  }

}

<div class="container-fluid" *ngIf="this.occupancyPlanViewForm">
    <h1 *ngIf="occupancyPlanView?.id !== null" class="mt-3">{{'Office.OccupancyPlanView.OccupancyPlanView' | transloco}}</h1>
    <h1 *ngIf="occupancyPlanView?.id === null" class="mt-3">{{'Office.OccupancyPlanView.CreateOccupancyPlanView' | transloco}}</h1>
    <div class="row" *ngIf="occupancyPlanView">
      <div class="col">
        <div class="well well-sm">
          <button type="button" (click)="showModal()" class="btn btn-danger"><i class="fa fa-trash text-light"></i> {{
            'Delete' | transloco }}
          </button>
          <a routerLink="/occupancyplanview/{{occupancyPlanView.id}}" target="_blank"><button type="button"
              class="btn btn-default ms-2"><i class="fa fa-external-link"></i> {{ 'Office.OccupancyPlanView.OpenView' |
              transloco }}</button></a>
          <button type="button" (click)="copyLinkToClipboard()" class="btn btn-default"><i
              class="glyphicon glyphicon-share"></i> {{ 'CopyLink' | transloco }}
          </button>
        </div>
      </div>
    </div>

    <form [formGroup]="occupancyPlanViewForm">
      <div class="row">
        <div class="col">
          <label for="name">{{'Name' | transloco}}</label>
          <div class="input-group">
            <input formControlName="name" class="form-control" id="Name" />
            <div class="input-group-btn" style="vertical-align:bottom;">
              <button (click)="save(occupancyPlanViewForm)" class="btn btn-primary" style="border-radius: 0 0.25rem 0.25rem 0;"><i class="fa fa-save text-light"></i>
                <span *ngIf="occupancyPlanView"> {{ 'Save' | transloco }}</span>
                <span *ngIf="!occupancyPlanView"> {{ 'Office.OccupancyPlanView.Create' | transloco }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>

    <div *ngIf="occupancyPlanView">
      
      <h1>{{'Office.OccupancyPlanView.FacilityRooms' | transloco}}</h1>
      <div class="row">
        <div class="col">
          <div class="d-flex">
            <ng-select class="w-100" #selectRoom [searchable]="true"  (change)="selectedFacilityRoomChanged($event)">
              <ng-option *ngFor="let selectItem of ngSelectItems" [value]="selectItem.id">{{selectItem.text}}</ng-option>
            </ng-select>
            <div class="input-group-btn  flex-shrink-1" style="vertical-align:bottom;">
              <button (click)="addRoom()" class="btn btn-default" style="border-radius: 0 0.25rem 0.25rem 0;">
                <span class="no-wrap">
                <i class="fa fa-plus-circle"></i>
                {{ 'Office.OccupancyPlanView.AddRoom' | transloco }}
              </span>
              </button>
            </div>

          </div>
        </div>

      </div>
      <div class="row">
        <div class="col-sm-12">
          &nbsp;
        </div>
      </div>
      <div class="row">
        <div class="col">
          <ul class="list-group">
            <li *ngFor="let occupancyPlanViewRoom of occupancyPlanViewRooms; let i = index;" class="list-group-item">
              <button [disabled]="i === (occupancyPlanViewRooms.length - 1)"
                (click)="moveRoomDown(occupancyPlanViewRoom)" class="btn btn-default btn-sm float-start margin-right"><i
                  class="fa-solid fa-caret-down"></i>
              </button>
              <button [disabled]="i === 0" (click)="moveRoomUp(occupancyPlanViewRoom)"
                class="btn btn-default btn-sm float-start  margin-right"><i class="fa-solid fa-caret-up"></i>
              </button>
              <span>{{occupancyPlanViewRoom.name }} | {{occupancyPlanViewRoom.facilityName}}</span>
              <button (click)="removeRoom(occupancyPlanViewRoom)" class="btn btn-danger btn-sm float-end"><i
                  class="fa fa-trash text-light"></i>
              </button>
            </li>
          </ul>
        </div>
      </div>
  </div>
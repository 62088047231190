import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { TournamentService } from '../services/tournament.service';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { TournamentDisplayEventModel } from '../models/tournament-display-event.model';
import { TournamentDisplayGamePlanModel } from '../models/tournament-display-game-plan.model';
import * as moment from 'moment';
import { TournamentDisplayGameModel } from '../models/tournament-display-game.model';
import { TournamentDisplayGamePlansModel } from '../models/tournament-display-game-plans.model';
import { TournamentDisplayGamePlanDayModel } from '../models/tournament-display-game-plan-day.model';
import { TournamentDisplayService } from '../services/tournament-display.service';

@Component({
    templateUrl: './tournament-public-event-games.component.html',
    styleUrls: ['./tournament-public-event-games.component.scss']
})
export class TournamentPublicEventGamesComponent implements OnInit {

    tournamentPlanId!: string;
    eventId!: string;

    gamePlanModels!: TournamentDisplayGamePlansModel;

    hiddenGroupIds: string[] = [];

    constructor(
        private translocoService: TranslocoService,
        private tournamentDisplayService: TournamentDisplayService,
        private activatedRoute: ActivatedRoute,
        private tournamentService: TournamentService,
    ) {
    }
    ngOnInit() {
        this.translocoService.selectTranslate('Tournaments.Title').subscribe((x: any) => {
        });

        this.activatedRoute.params.subscribe((params: Params) => {
            this.tournamentPlanId = params['tournamentPlanId'];
            this.eventId = params['eventId'];
            this.loadTournamentEventGamePlans();
        });
    }

    loadTournamentEventGamePlans() {
        this.tournamentService.getDisplayEventGames(this.tournamentPlanId, this.eventId).subscribe(x => {
            if (x.additionLink) x.additionLink = this.tournamentDisplayService.getAdditionLink(x.additionLink);
            this.gamePlanModels = x;

            this.gamePlanModels.gameDays.forEach(x => {
                x.gameLocations = x.gameLocations.sort((a, b) => this.getGameLocationName(a).localeCompare(this.getGameLocationName(b)));

                x.gameLocations.forEach(gd => {
                    gd.games = gd.games.sort((a, b) => this.getTime(a.from) - this.getTime(b.from));
                    gd.games.forEach(g => {
                        g.sets = g.sets.sort((a, b) => a.number - b.number);
                    });
                });
            });

        });
    }

    private getTime(date?: Date) {
        return date != null ? new Date(date).getTime() : 0;
    }

    toggleGroupVisbibility(groupId: string) {
        var groupItem = this.hiddenGroupIds.find(x => x == groupId);

        if (groupItem) {
            this.hiddenGroupIds = this.hiddenGroupIds.filter(x => x != groupId);
        } else {
            this.hiddenGroupIds.push(groupId)
        }
    }

    isGroupVisible(groupId: string): boolean {
        var groupItem = this.hiddenGroupIds.find(x => x == groupId);
        return groupItem == undefined;
    }

    getFilteredGameDays(): TournamentDisplayGamePlanDayModel[] {
        if (this.gamePlanModels.gameDays == undefined) return [];

        var filteredGameDays = this.gamePlanModels.gameDays.filter(x => !this.hiddenGroupIds.find(h => h == x.groupId));

        filteredGameDays = filteredGameDays.sort((a, b) => {

            var aNumber = `000000${a.groupNumber}`;
            aNumber = aNumber.substring(aNumber.length - 6);

            var bNumber = `000000${b.groupNumber}`;
            bNumber = bNumber.substring(bNumber.length - 6);

            var aDayGroup = `${this.getTime(a.dayDate)}_${aNumber}`
            var bDayGroup = `${this.getTime(b.dayDate)}_${bNumber}`

            return aDayGroup.localeCompare(bDayGroup);
        });

        return filteredGameDays;
    }


    getEventNameById(eventId: string): string {
        return this.gamePlanModels.eventName;
    }

    getGroupIds(eventId: string): string[] {
        var groupIds: string[] = [];

        var matchingGames = this.gamePlanModels.gameDays;
        matchingGames = matchingGames.sort((a, b) => a.groupNumber - b.groupNumber);
        matchingGames.forEach(x => {
            if (!groupIds.find(e => e == x.groupId)) groupIds.push(x.groupId);
        });

        return groupIds;
    }

    getGroupNameById(groupId: string): string {
        var game = this.gamePlanModels.gameDays.find(x => x.groupId == groupId);

        return `Gruppe ${this.gamePlanModels.eventPrefix} ${game?.groupNumber}`;
    }

    // getGamePlanName(gamePlan: TournamentDisplayGamePlanModel): string {
    //     return `${this.getEventNameById(this.gamePlanModels.eventId)} ${this.getGroupNameById(gamePlan.groupId)}`;
    // }

    getGameLocationName(gamePlan: TournamentDisplayGamePlanModel): string {
        var location = `${gamePlan.imFacilityName} | ${gamePlan.imRoomName}`;

        if (gamePlan.fieldId != 0) {
            location += ` Teilfeld ${gamePlan.fieldId}`;
        }

        return location;
    }

    getDayDisplayName(dayDate: Date): string {
        var date = new Date(dayDate);
        var dayShort = this.translocoService.translate(`Day_${date.getDay()}`)

        let formattedDate = (moment(date)).format('DD.MM.YYYY')
        return `${dayShort} ${formattedDate}`;
    }

    getGameTime(gameDate: Date): string {
        let formattedDate = (moment(gameDate)).format('HH:mm')
        return formattedDate;
    }

    hasReferees(games: TournamentDisplayGameModel[]): boolean {
        var hasReferee = false;

        if (games.filter(x => x.refereeTeamId != null).length) hasReferee = true;

        return hasReferee;
    }

}

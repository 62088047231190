import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { TournamentService } from '../services/tournament.service';
import { TournamentModel } from '../models/tournament.model';
import { TournamentUpdateModel } from '../models/tournament-update.model';
import { TournamentTypeEnum } from '../enums/tournament-type.enum';

@Component({
    templateUrl: './tournament-configuration-modal.component.html',
    styleUrls: ['./tournament-configuration-modal.component.scss'],
})
export class TournamentConfigruationModalComponent implements OnInit {

    @Output() confirmed = new EventEmitter<string>();

    tournamentPlanId?: string;

    tournamentForm!: UntypedFormGroup;

    formSubmitted = false;
    TournamentTypeEnum = TournamentTypeEnum;

    constructor(
        private translocoService: TranslocoService,
        private activatedRoute: ActivatedRoute,
        private fb: FormBuilder,
        private datepipe: DatePipe,
        private modalService: BsModalService,
        private bsModalRef: BsModalRef,
        private tournamentService: TournamentService,
    ) {
    }

    ngOnInit() {
        if (this.tournamentPlanId) {
            this.loadTournament(this.tournamentPlanId!);
        } else {
            this.initTournamentForm(null);
        }
    }

    initTournamentForm(tournament: TournamentModel | null) {
        this.tournamentForm = this.fb.group({
            name: [tournament?.name, Validators.required],
            type: [tournament?.type, Validators.required],
            allowCaptainEntry: [tournament?.allowCaptainEntry ?? false, Validators.required],
            useTeamAsReferee: [tournament?.useTeamAsReferee ?? false, Validators.required],
            allowTeamRefereeEntry: [tournament?.allowTeamRefereeEntry ?? false, Validators.required],
        });
    }

    loadTournament(tournamentPlanId: string) {
        this.tournamentService.getTournamentById(tournamentPlanId).subscribe(x => {
            this.initTournamentForm(x);
        });
    }

    create() {
        this.tournamentService.createTournament(this.getTournamentUpdateModel()).subscribe(x => {
            this.confirmed.emit(x.id);
            this.bsModalRef.hide();
        });
    }

    update() {
        this.tournamentService.updateTournament(this.tournamentPlanId!, this.getTournamentUpdateModel()).subscribe(x => {
            this.confirmed.emit(x.id);
            this.bsModalRef.hide();
        });
    }

    getTournamentUpdateModel(): TournamentUpdateModel {
        var updateModel = new TournamentUpdateModel();

        updateModel.name = this.tournamentForm.controls['name'].value;
        updateModel.type = this.tournamentForm.controls['type'].value;
        updateModel.allowCaptainEntry = this.tournamentForm.controls['allowCaptainEntry'].value;
        updateModel.useTeamAsReferee = this.tournamentForm.controls['useTeamAsReferee'].value;
        updateModel.allowTeamRefereeEntry = this.tournamentForm.controls['allowTeamRefereeEntry'].value;
        return updateModel;
    }

    setType(type: TournamentTypeEnum) {
        this.tournamentForm.controls['type'].setValue(type);
    }

    isTypeSelected(type: TournamentTypeEnum) {
        var selectedValue = this.tournamentForm.controls['type'].value;

        return selectedValue == type;
    }

    confirm() {
        this.formSubmitted = true;
        if (!this.tournamentForm.valid) return;

        if (this.tournamentPlanId) {
            this.update();
        } else {
            this.create();
        }
    }

    decline() {
        this.bsModalRef.hide();
    }
}

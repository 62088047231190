<div class="modal-body"
    [ngClass]="{'access-denied': !isLoading && runValidation && !isAccessGranted(),'access-granted': !isLoading && runValidation && isAccessGranted()}">
    <app-spinner margin="100px auto" *ngIf="isLoading"></app-spinner>
    <ng-container *ngIf="!isLoading">
        <div (click)="closeModal()" (touch)="closeModal()">
            <div class="float-end" style="display:inline">
                <h3>
                    <i *ngIf="checkType === checkTypeEnum.Rfid" class="fa fa-credit-card float-end"></i>
                    <i *ngIf="checkType === checkTypeEnum.QrCode" class="fa fa-qrcode float-end"></i>
                    <i *ngIf="checkType === checkTypeEnum.Text" class="fa fa-magnifying-glass float-end"></i>
                </h3>
            </div>

        <ng-container *ngIf="runValidation">
            <div *ngIf="!isAccessGranted()">
                <!-- Show OS Message if present -->
                <h3 style="white-space: pre-line;" *ngIf="validationResult?.osResult?.message"
                    [innerHTML]="validationResult?.osResult?.message"></h3>

                <!-- Show App message if set and OS message is not present-->
                <h3 style="white-space: pre-line;"
                    *ngIf="validationResult?.qrCodeResult?.message && !validationResult?.osResult?.message"
                    [innerHTML]="validationResult?.qrCodeResult?.message"></h3>

                <!-- Show IM message if set and OS & App message is not present-->
                <h3 style="white-space: pre-line;" *ngIf="validationResult?.imResult?.message 
                && !validationResult?.qrCodeResult?.message 
                && !validationResult?.osResult?.message" [innerHTML]="validationResult?.imResult?.message"></h3>
            </div>
            <div *ngIf="isAccessGranted()">
                <h3>{{'Success' | transloco }}</h3>
            </div>
        </ng-container>

        <ng-container *ngIf="!runValidation && checkOutValidationHistory">
            <div *ngIf="!isAccessGranted()">
                <h3>{{checkOutValidationHistory.message}}</h3>
            </div>

            <div *ngIf="isAccessGranted()">
                <h3>{{'Success' | transloco }}</h3>
            </div>
        </ng-container>
                <!-- access denied -->
        <!-- profile photo -->
        <div class="row mt-3" *ngIf="person">
            <div class="col-md-12">
                <img id="image" *ngIf="picture" src="data:image/JPEG;base64,{{picture}}" class="image"
                    style="width: 100%; max-width: 160px;" />
                <ng-container *ngIf="!picture">
                    <i class="fa-solid fa-image-portrait" style="font-size: 5rem;"></i>
                    <div class="alert alert-warning mt-3">{{'HandHeldScanner.Msg.NoPicture' | transloco}}</div>
                </ng-container>
            </div>
        </div>
        <div class="mt-3" *ngIf="person">
            <div class="user-data-table">
                <table>
                    <tr>
                        <td>{{'ASVZID' | transloco}}</td>
                        <td>{{person.asvzId}}</td>
                    </tr>
                    <tr>
                        <td>{{'FirstName' | transloco}}</td>
                        <td>{{person.firstName}}</td>
                    </tr>
                    <tr>
                        <td>{{'LastName' | transloco}}</td>
                        <td>{{person.lastName}}</td>
                    </tr>
                    <tr>
                        <td>{{'BirthDate' | transloco}}</td>
                        <td>{{person.dateOfBirth | date: 'yyyy'}}</td>
                    </tr>
                </table>
            </div>

            <div class="mt-3" *ngIf="runValidation">
                <b>{{'HandHeldScanner.Offers' | transloco}}</b>
                <ul *ngIf="bookedCourses.length > 0">
                    <li *ngFor="let bookedCourse of bookedCourses">
                        {{bookedCourse}}
                    </li>
                </ul>
                <div class="alert alert-info" *ngIf="bookedCourses.length === 0">
                    {{'HandHeldScanner.NoOffers' | transloco}}
                </div>
            </div>
        </div>
    </div>
        <div class="mt-3 w-100" *ngIf="person">
            <button class="btn btn-danger w-100" (click)="openBlockPersonConfirmationModal()">
                <i class="fa-solid fa-ban me-1"></i>
                <span>{{'HandHeldScanner.Block' | transloco}}</span>
            </button>
        </div>

        <div class="mt-3 w-100" *ngIf="person && picture">
            <button class="btn btn-danger w-100" (click)="openDeletePhotoConfirmationModal()">
                <i class="fa-solid fa-trash me-1"></i>
                <span>{{'HandHeldScanner.DeletePhoto' | transloco}}</span>
            </button>
        </div>
    </ng-container>
</div>

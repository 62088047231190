import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserAuthorizationService } from 'src/app/security/user-authorization.service';

@Component({
  selector: 'app-login-button',
  templateUrl: './login-button.component.html',
  styleUrls: ['./login-button.component.scss']
})
export class LoginButtonComponent implements OnInit {

  constructor(private router: Router, private authenticationService: UserAuthorizationService) { }

  ngOnInit(): void {
  }

  login(): void {
    const routerStateSnapshot = this.router.routerState.snapshot;
    this.authenticationService.startAuthentication(routerStateSnapshot.url);
  }
}

import { Injectable, Sanitizer } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, flatMap, mergeMap, concatMap } from 'rxjs/operators';
import { Observable, merge } from 'rxjs';
import { Application, ApplicationPut, ConfirmMembershipApplication } from './models';
import { DomSanitizer } from '@angular/platform-browser';
import { FileDownloadRequest } from './models/file-download-request.model';
import { DateHandlerService } from '../shared/date-handler.service';
import { environment } from 'src/environments/environment';
import { Editor } from '../office/membership-applications/models/editor.model';

@Injectable({
  providedIn: 'root'
})
export class MembershipApplicationsService {

  constructor(private httpClient: HttpClient,
    private sanitizer: DomSanitizer,
    private dateHandler: DateHandlerService) { }

  createNewFromMembershipId(imMembershipId: number): Observable<Application> {
    return this.httpClient.post<Application>(`${environment.api}/MembershipApplications/new/${imMembershipId}`, {});
  }

  getById(membershipApplicationId: string): Observable<Application> {
    return this.httpClient.get(`${environment.api}/MembershipApplications/${membershipApplicationId}`).pipe(
      map((res) => {
        const response = res as Application;

        if (response?.applicant?.birthdate != null) {
          response.applicant.birthdate = this.dateHandler.takeAsUtcWithLocalTimeZone(response.applicant.birthdate).toDate();
        }
        response.membershipValidFromDate = this.dateHandler.takeAsUtcWithLocalTimeZone(response.membershipValidFromDate).toDate();

        return response
      })
    );
  }

  updateApplicationInfo(membershipApplicationId: string, application: ApplicationPut): Observable<void> {
    return this.httpClient.put<void>(`${environment.api}/MembershipApplications/${membershipApplicationId}`, application);
  }

  updateConfigDetailValue(membershipApplicationId: string, onlineMembershipConfigId: string, value: string): Observable<any> {
    return this.httpClient.post(`${environment.api}/MembershipApplications/${membershipApplicationId}/ConfigDetails/${onlineMembershipConfigId}/value`, { valueJson: value }).pipe(
      map((res: any) => res.data)
    );
  }

  uploadConfigDetailDocument(membershipApplicationId: string, onlineMembershipConfigId: string, file: any): Observable<any> {
    return this.httpClient.post(`${environment.api}/MembershipApplications/${membershipApplicationId}/ConfigDetails/${onlineMembershipConfigId}/Document/`, file).pipe(
      map((res: any) => res.data)
    );
  }

  deleteConfigDetailDocument(membershipApplicationId: string, documentId: string): Observable<any> {
    return this.httpClient.post(`${environment.api}/MembershipApplications/${membershipApplicationId}/Documents/${documentId}/delete`, null);
  }

  getConfigFileDownloadId(membershipApplicationId: string, documentId: string): Observable<FileDownloadRequest> {
    return this.httpClient.get(`${environment.api}/MembershipApplications/${membershipApplicationId}/Documents/${documentId}/downloadId`).pipe(
      map((res: any) => res.data)
    );
  }

  downloadFile(downloadDocId: string): Observable<any> {
    return this.httpClient.get(`${environment.api}/FileDownloads/${downloadDocId}`).pipe(
      map((res: any) => {
        return new Blob([res._body], {
          type: res.headers.get('Content-Type')
        });
      }),
      map(blob => {
        const urlCreator = window.URL;
        return this.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(blob));
      })
    );
  }

  downloadFileAsBlob(downloadDocId: any): Observable<Object> {

    const baseUrl = `${environment.api}/FileDownloads/${downloadDocId}`;

    return this.httpClient.get(baseUrl, { responseType: 'blob' as 'json' });
  }

  uploadPassPhoto(membershipApplicationId: string, file: any): Observable<any> {
    return this.httpClient.post(`${environment.api}/MembershipApplications/${membershipApplicationId}/PassPhoto`, file);
  }

  deletePassPhoto(membershipApplicationId: string): Observable<any> {
    return this.httpClient.post(`${environment.api}/MembershipApplications/${membershipApplicationId}/PassPhoto/delete`, null);
  }

  getPassPhotoDownloadId(membershipApplicationId: string): Observable<FileDownloadRequest> {
    return this.httpClient.get<FileDownloadRequest>(`${environment.api}/MembershipApplications/${membershipApplicationId}/PassPhoto/DownloadId`);
  }

  submitMembershipApplication(membershipApplicationId: string, confirmData: ConfirmMembershipApplication): Observable<any> {
    return this.httpClient.post(`${environment.api}/MembershipApplications/${membershipApplicationId}/Process/Submit`, confirmData).pipe(map((res: any) => res.data));
  }

  confirmApplicationEmail(membershipApplicationId: string, token: string): Observable<Application | null> {
    return this.httpClient.post(`${environment.api}/MembershipApplications/${membershipApplicationId}/EmailConfirmation?token=${token}`, {}).pipe(
      flatMap(val => this.getById(membershipApplicationId))
    );
  }

  resendEmailConfirmationMail(membershipApplicationId: string): Observable<any> {
    return this.httpClient.post(`${environment.api}/MembershipApplications/${membershipApplicationId}/resendEmailConfirmation`, {});
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import * as moment from 'moment';
import { environment } from '../../environments/environment';
import { Event } from './event.model';
import { RequiredSkill } from './required-skill.model';
import { RegistrationStates } from './registration/registration-states.enum';
import { UserAuthorizationService } from '../security/user-authorization.service';
import { EventState } from './event-state.enum';
import { Account } from '../account/account.model';
import { DisplayPriceType } from './display-price-type.enum';
import { Prices } from './prices.enum';
import { HttpResponseErrorHandlerService } from '../shared/services/http-response-error-handler.service';
import { LiveStream } from '../shared/models/live-stream.model';

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  registrationState = RegistrationStates;
  eventState = EventState;

  constructor(private httpService: HttpClient,
    private httpResponseErrorHandler: HttpResponseErrorHandlerService,
    private authenticationService: UserAuthorizationService) {
  }

  getAllEvents(): Observable<Array<Event>> {
    return this.httpService.get(`${environment.api}/Events`)
      .pipe(
        map((sportEvents: any): Array<Event> => sportEvents.data),
        catchError(e => this.httpResponseErrorHandler.HandleResponseError(e))
      );      
  }

  getPicture(): Observable<Blob> {
    return this.httpService.get(`${environment.api}/MemberPerson/Picture`)
        .pipe(
            map((picture: any): Blob => picture)
        )
}

  getEventById(id: number): Observable<Event> {
    return this.httpService.get(`${environment.api}/Events/${id}`)
      .pipe(
        map((event: any): Event => event.data),
        catchError(e => this.httpResponseErrorHandler.HandleResponseError(e))
      );
  }

  userCanRegisterToEvent(event: Event, missingSkills: RequiredSkill[]) {
    return this.userHasPermissionToRegister(event, missingSkills) &&
      this.hasFreeSeats(event) &&
      this.registrationDurationIsOpen(event);
  }

  eventHasUserPriceClass(event: Event, account: Account) {
    
    // if the user has no active membership and the event.externeAnmeldung is true => use price Extern
    if(!account.hasActiveMembership && event.externeAnmeldung && event.priceList.find(x => x.priceType === Prices.EXTERN)){
      return true;
    }

    return event.displayPriceType === DisplayPriceType.Complimentary
      || event.displayPriceType === DisplayPriceType.NoCost
      || event.priceList.find(i => i.priceType === account.priceClass) !== undefined;
  }

  priceOnRequest(event: Event) {
    return event.displayPriceType === DisplayPriceType.OnRequest;
  }

  userHasPermissionToRegister(event: Event, missingSkills: RequiredSkill[]): boolean {
    return missingSkills.length === 0 &&
      (
        event.status === this.registrationState.Temporary.valueOf() ||
        event.status === this.registrationState.Canceled.valueOf() ||
        this.userCanPrepareEvent(event.status)
      );
  }

  userCanPrepareEvent(status: number): boolean {
    const currentUser = this.authenticationService.getUser();
    return status === this.eventState.InPreparation &&
      (
        currentUser.hasRoleAdmin() ||
        currentUser.hasRoleOffice() ||
        currentUser.hasRoleHspl()
      );
  }

  hasFreeSeats(event: Event): boolean {
    if (event.participantsMax == null) {
      return true;
    } else if (event.participantsMax - event.participantCount <= 0) {
      return false;
    } else {
      return true;
    }
  }

  registrationDurationIsOpen(event: Event): boolean {
    return this.registrationDateIsOpen(event.registrationFrom) &&
      !this.registrationDateIsExpired(event.registrationUntil)
  }

  registrationDateIsOpen(date: Date): boolean {
    if (date != null) {
      return moment().isSameOrAfter(date);
    } else {
      return true;
    }
  }

  registrationDateIsExpired(date: Date): boolean {
    if (date != null) {
      return moment().isAfter(date);
    } else {
      return true;
    }
  }

  startDateIsInThePast(date: Date): boolean {
    if (date != null) {
      return moment().isAfter(date);
    } else {
      return true;
    }
  }

  cancelationDateIsExpired(date: Date): boolean {
    if (date != null) {
      return moment().isAfter(date);
    } else {
      return true;
    }
  }

  getLiveStream(id: number): Observable<LiveStream> {
    return this.httpService.get(`${environment.api}/Events/${id}/livestream`)
    .pipe(
      map((liveStream: any): LiveStream => liveStream)
    );
  }

  createLiveStreamLog(id: number): Observable<any> {
    return this.httpService.post(`${environment.api}/Events/${id}/livestream/log`, {});
  }
}

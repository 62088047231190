<div class="well">

  <p class="text-danger" *ngIf="passPhotoHasValidationError">
    {{'MembershipApplication.Msg.PassPhotoValidationError' | transloco}}
  </p>

  <p class="text-danger" *ngIf="passPhotoUploadFailed">
    {{'MembershipApplication.Msg.PassPhotoUploadFailed' | transloco}}
  </p>

  <span *ngIf="isDisabled && !passPhotoDownloadId">{{'MembershipApplication.Msg.NoPassportPhotoAvailable' | transloco
    }}</span>

  <label class="btn btn-primary btn-file" *ngIf="!passPhotoDownloadId && !isDisabled">
    <i class="fa fa-plus-circle me-1" aria-hidden="true" style="color: white;"></i>
    <span>{{'FileUploader.UploadPassPhoto' | transloco}}</span>
    <input type="file" name="myFile" class="form-control" style="display: none;" (change)="onFileChange($event)"
      accept='.jpg,.jpeg,.png'>
  </label>

  <div *ngIf="passPhotoDownloadId">
    <p>{{'MembershipApplication.Msg.UploadPassportPhoto' | transloco }}</p>
    <label>
      <button *ngIf="!isDisabled" (click)="showModal()" class="btn btn-danger">{{'DeletePassportPhoto' | transloco
        }}</button>
    </label>
  </div>

</div>
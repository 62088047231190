import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { OnlineMembership } from '../../models/online-membership.model';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-change-membership-modal',
  templateUrl: './change-membership-modal.component.html',
  styleUrls: ['./change-membership-modal.component.scss']
})
export class ChangeMembershipModalComponent implements OnInit {

  @Output()
  onlineMembershipChanged: EventEmitter<any> = new EventEmitter<any>();

  imMembershipId!: number;

  onlineMemberships!: OnlineMembership[];
  onlineMembershipForm!: FormGroup;

  submitted = false;

  constructor(
    private fb: FormBuilder,
    public bsModalRef: BsModalRef) {
  }

  ngOnInit() {
    this.initForm();
    this.sortOnlineMemberships();
    this.setMemberShipId();
  }

  setMemberShipId() {
    this.onlineMembershipForm.get('membership')!.setValue(this.imMembershipId);
  }

  ok() {
    if (this.onlineMembershipForm.valid) {
      this.onlineMembershipChanged.emit(this.onlineMembershipForm.get('membership')?.value);
      this.bsModalRef.hide();
    } else {
      this.submitted = true;
    }
  }

  cancel() {
    this.bsModalRef.hide();
  };

  sortOnlineMemberships() {
    this.onlineMemberships = this.onlineMemberships.sort((a, b) => {
      return a.membershipGroupNameDe?.localeCompare(b.membershipGroupNameDe);
    }
    );
  }

  initForm() {
    this.onlineMembershipForm = this.fb.group({
      membership: [{ disabled: false }, [Validators.required]],
    });
  }
}


import {mergeMap} from 'rxjs/operators';
import {Component, Input, OnInit} from '@angular/core';
import {EventsService} from '../../events.service';
import {RegistrationService} from '../registration.service';
import {RegistrationSummary} from '../registration-summary.model';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Event} from '../../event.model';


@Component({
  selector: 'app-registration-cancel-payment',
  templateUrl: './registration-cancel-payment.component.html',
  styleUrls: ['./registration-cancel-payment.component.scss']
})
export class RegistrationCancelPaymentComponent implements OnInit {
  @Input() registrationSummary!: RegistrationSummary;
  event!: Event;

  constructor(private eventsService: EventsService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private registrationService: RegistrationService) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      const registrationId = params['registrationId'];
      if (registrationId) {
        this.loadRegistrationSummary(registrationId);
      }
    })
  }

  deletePayment() {
    this.registrationService.setStateDelete(this.registrationSummary.id!).subscribe(() => {
      this.router.navigateByUrl(`/registration/overview`);
    });
  }

  cancelPayment() {
    this.registrationService.setStateCanceled(this.registrationSummary.id!).subscribe(() => {
      this.router.navigateByUrl(`/registration/overview`);
    });
  }

  private loadRegistrationSummary(registrationId: any) {
    this.registrationService.getById(registrationId).pipe(
      mergeMap((registrationSummary: RegistrationSummary) => {
        this.registrationSummary = registrationSummary;
        return this.eventsService.getEventById(this.registrationSummary.eventId!);
      }))
      .subscribe((event: Event) => this.event = event);
  }
}

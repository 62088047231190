<div class="modal-header">
    <h2>{{'Memberships.HealthInsuranceRequest.Question' | transloco}}</h2>
</div>

<div class="modal-body">
    <p>{{'Memberships.HealthInsuranceRequest.DetailList' | transloco}}</p>
    <div class="form-check">
        <input class="form-check-input" type="checkbox" id="includeDetailsCheckbox" [(ngModel)]="includeDetails" [ngModelOptions]="{standalone: true}">
        <label class="form-check-label" for="includeDetailsCheckbox">
            {{'Memberships.HealthInsuranceRequest.Option' | transloco}}
        </label>
      </div>
</div>

<div class="modal-footer">
    <button (click)="cancel()" class="btn btn-default" [disabled]="isLoading">
        {{'Cancel' | transloco}}
    </button>
    <button (click)="request()" class="btn btn-primary" [disabled]="isLoading">
        {{'Confirm' | transloco}}
    </button>
</div>
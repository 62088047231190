import { Routes } from '@angular/router';
import { AuthGuard } from 'src/app/security/guards';
import { RegistrationCancelPaymentComponent } from '../events/registration/registration-cancel-payment/registration-cancel-payment.component';
import { RegistrationGuardService } from '../events/registration/registration-guard.service';
import { RegistrationMainComponent } from '../events/registration/registration-main/registration-main.component';
import { RegistrationProvePermissionComponent } from '../events/registration/registration-prove-permission/registration-prove-permission.component';
import { RegistrationSummaryComponent } from '../events/registration/registration-summary/registration-summary.component';
import { RegistrationsMainLayoutComponent } from './registrations-main-layout/registrations-main-layout.component';
import { RegistrationsOverviewComponent } from './registrations-overview/registrations-overview.component';

export const routes: Routes = [
  {
    path: '',
    component: RegistrationsMainLayoutComponent,
    children: [
      { path: 'my', component: RegistrationsOverviewComponent },
      { path: 'prove/:eventId', component: RegistrationProvePermissionComponent },
      { path: ':registrationId/wizard', component: RegistrationMainComponent, canDeactivate: [RegistrationGuardService] },
      { path: ':registrationId/details', component: RegistrationSummaryComponent },
      { path: ':registrationId/cancel', component: RegistrationCancelPaymentComponent }
    ]
  }
]

<div class="row">
    <div class="col-md-4">
        <label>{{ 'HandHeldScanner.Scanner' | transloco }}</label>
        <app-scanner-selector [formControl]="scannerFormControl"></app-scanner-selector>
    </div>
</div>

<div class="row mt-3">
    <div class="col-md-4">
        <label>{{ 'HandHeldScanner.Search' | transloco }}</label>
        <input class="form-control" type="text" [formControl]="filterFormControl" />
    </div>
</div>

<div class="row mt-3">
    <div class="col-md-4">
        <app-spinner *ngIf="isSearching"></app-spinner>
        <ul class="list-group" *ngIf="!isSearching">
            <li *ngFor="let person of people" 
                class="list-group-item list-group-item-action cursor-pointer"
                (click)="openPersonDetailModal(person)"
            >{{person.firstName}} {{person.lastName}} ({{person.dateOfBirth | date: 'yyyy'}})</li>
        </ul>
    </div>
</div>
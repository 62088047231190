import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { TournamentFacilityModel } from '../models/tournament-facility.model';
import { TournamentGroupAddRoomModel } from '../models/tournament-group-add-room.model';
import { TournamentRoomModel } from '../models/tournament-room.model';
import { TournamentService } from '../services/tournament.service';

@Component({
  selector: 'app-tournament-group-add-room-modal',
  templateUrl: './tournament-group-add-room-modal.component.html',
  styleUrls: ['./tournament-group-add-room-modal.component.scss']
})
export class TournamentGroupAddRoomModalComponent implements OnInit {

  @Output() confirmed = new EventEmitter<boolean>();

  roomForm!: UntypedFormGroup;

  tournamentPlanId!: string;
  groupId!: string;
  tournamentFacilities!: TournamentFacilityModel[];
  tournamentRooms!: TournamentRoomModel[];

  isSaving = false;
  formSubmitted = false;

  constructor(
    private fb: FormBuilder,
    private bsModalRef: BsModalRef,
    private tournamentService: TournamentService,
    private toastrService: ToastrService,
    private translocoService: TranslocoService
  ) { }

  ngOnInit(): void {
    this.loadFacilties();
    this.initForm();
  }

  initForm() {
    this.roomForm = this.fb.group({
      facilityId: [null, Validators.required],
      roomId: [null, Validators.required]
    });
  }

  loadFacilties() {
    this.tournamentService.getTournamentFacilities(this.tournamentPlanId).subscribe(x => {
      x = x.sort((a, b) => a.name.localeCompare(b.name));
      x.forEach(f => {
        f.rooms = f.rooms.sort((a, b) => a.name.localeCompare(b.name));
      });

      this.tournamentFacilities = x;
    })
  }

  onFacilityChange() {
    this.updateRooms();
  }

  updateRooms() {
    this.tournamentRooms = [];
    this.roomForm.controls['roomId'].setValue(null);
    let facilityId = this.roomForm.controls['facilityId'].value;
    if (facilityId == null) return;

    var facility = this.tournamentFacilities.find(x => x.id == facilityId);
    this.tournamentRooms = facility!.rooms;

    if (facility!.rooms.length == 1) {
      this.roomForm.controls['roomId'].setValue(facility!.rooms[0].id);
    }
  }

  confirm() {
    this.formSubmitted = true;
    if (!this.roomForm.valid) return;

    this.isSaving = true;

    let updateModel = new TournamentGroupAddRoomModel();
    updateModel.imRoomId = this.roomForm.controls['roomId']?.value;

    this.tournamentService.addTournamentGroupRoom(this.tournamentPlanId, this.groupId, updateModel).subscribe({
      next: result => {
        this.isSaving = false;

        this.confirmed.emit(true);
        this.bsModalRef.hide();
      },
      error: error => {
        this.isSaving = false;
        this.toastrService.error(this.translocoService.translate('Msg.SaveError'));
      }
    });
  }

  decline() {
    this.bsModalRef.hide();
  }
}

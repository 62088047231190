import { Component, OnInit, ViewEncapsulation, Input, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { RegistrationService } from '../registration.service';
import { RegistrationSummary } from '../registration-summary.model';
import { RegistrationGuardService } from '../registration-guard.service';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { TranslocoService } from '@ngneat/transloco';
import { ConfirmationModalComponent } from 'src/app/shared/confirmation-modal/confirmation-modal.component';
import { RegistrationCancelPaidInformationModel } from '../models/registration-cancel-paid-information.model';
import { Event } from '../../event.model';

@Component({
    templateUrl: './registration-deregister-paid-modal.component.html',
    styleUrls: ['./registration-deregister-paid-modal.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class RegistrationDeregisterPaidModalComponent implements OnInit {
    @Output() confirmed = new EventEmitter<boolean>();

    @Input() registrationId!: number;
    @Input() event!: Event;
    @Input() information!: RegistrationCancelPaidInformationModel;

    isConfirmed = false;

    constructor(
        private bsModalRef: BsModalRef,
    ) { }

    ngOnInit() {
    }

    confirm() {
        if(!this.isConfirmed) return;
        this.confirmed.emit(true);
        this.bsModalRef.hide();
    }

    decline() {
        this.bsModalRef.hide();
    }
}

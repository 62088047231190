import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersOverviewComponent } from './users-overview/users-overview.component';
import { UsersListComponent } from './users-overview/users-list/users-list.component';
import { RouterModule } from '@angular/router';
import { UsersService } from './users.service';
import { UserDetailComponent } from './users-overview/user-detail/user-detail.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserSetPasswordComponent } from './user-set-password/user-set-password.component';
import { UserSetPasswordModalComponent } from './user-set-password/user-set-password-modal/user-set-password-modal.component';
import { UserSetAsvzIdModalComponent } from './user-set-asvz-id/user-set-asvz-id-modal/user-set-asvz-id-modal.component';
import { UserSetAsvzIdComponent } from './user-set-asvz-id/user-set-asvz-id.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
    UsersOverviewComponent,
    UsersListComponent, UserDetailComponent,
    UserSetPasswordComponent,
    UserSetPasswordModalComponent,
    UserSetAsvzIdComponent,
    UserSetAsvzIdModalComponent
  ],
  providers: [UsersService]
})
export class UsersModule { }

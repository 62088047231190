import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { MembershipService } from '../../membership.service';
import { MembershipsHealthInsuranceRequestModel } from '../../models/memberships-health-insurance-request.model';
import { ToastrService } from 'ngx-toastr';
import { TranslocoService } from '@ngneat/transloco';
import { MembershipsHealthInsuranceResponseTypeEnum } from '../../enums/memberships-health-insurance-response-type.enum';

@Component({
    templateUrl: './memberships-health-insurance-request-modal.component.html',
    styleUrls: ['./memberships-health-insurance-request-modal.component.scss']
})
export class MembershipsHealthInsuranceRequestModalComponent implements OnInit {
    @Output() confirmed = new EventEmitter<boolean>();
    @Output() error = new EventEmitter<MembershipsHealthInsuranceResponseTypeEnum>();

    personMembershipId!: number
    includeDetails: boolean = false;

    isLoading: boolean = false;

    constructor(
        private bsModalRef: BsModalRef,
        private membershipService: MembershipService,
        private translocoService: TranslocoService,
        private toastrService: ToastrService,
    ) { }

    ngOnInit(): void {
    }

    cancel() {
        this.bsModalRef.hide();
    }

    request(): void {
        this.isLoading = true;

        const request = new MembershipsHealthInsuranceRequestModel();
        request.includeDetails = this.includeDetails;

        this.membershipService.requestHealtInsuranceSummary(this.personMembershipId, request).subscribe(
            x => {
                this.isLoading = false;

                if (x.responseType == MembershipsHealthInsuranceResponseTypeEnum.Success) {
                    this.confirmed.next(true);
                } else {
                    this.error.next(x.responseType);
                }
                this.bsModalRef.hide();
            },
            err => {
                this.error.next(MembershipsHealthInsuranceResponseTypeEnum.GenericError);
                this.bsModalRef.hide();
            }
        )
    }
}

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Refund } from "../models/refund.model";
import { HttpResponseErrorHandlerService } from "src/app/shared/services/http-response-error-handler.service";
import { environment } from "src/environments/environment";

@Injectable()
export class RefundService {

    constructor(private httpService: HttpClient,
        private httpResponseErrorHandler: HttpResponseErrorHandlerService) {
    }
    getRefundList(): Observable<Refund[]> {
        return this.httpService.get<Refund[]>(`${environment.api}/Invoices/Refunds/Admin/open`)
            .pipe(
                // map((registrations: any): Array<Refund> => registrations.data),
                catchError(e => this.httpResponseErrorHandler.HandleResponseError(e))
            );
    }

    requestRefund(refunds: Refund[]){
        return this.httpService.post(`${environment.api}/Invoices/Refunds/Admin/RequestRefund`, refunds)
        .pipe(
        //   map((results: any): Refund => results.data),
          catchError(e => this.httpResponseErrorHandler.HandleResponseError(e))
        );
    }
}
import {Component, OnInit} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RegistrationList } from '../models/registration-list.model';
import { RegistrationService } from 'src/app/events/registration/registration.service';
import { RegistrationStates } from 'src/app/events/registration/registration-states.enum';
import { TranslocoService } from '@ngneat/transloco';


@Component({
  selector: 'app-registrations-overview',
  templateUrl: './registrations-overview.component.html',
  styleUrls: ['./registrations-overview.component.scss']
})
export class RegistrationsOverviewComponent implements OnInit {
  myRegistrations!: RegistrationList[];
  openRegistrations!: RegistrationList[];
  completedRegistrations!: RegistrationList[];

  constructor(private registrationService: RegistrationService,
              private titleService: Title,
              private translocoService: TranslocoService
              ) {
  }

  ngOnInit() {
    // set window title
    this.translocoService.selectTranslate('MyRegistrations').subscribe(x => {
      this.titleService.setTitle(x);
    });
    this.loadMyRegistrations();
  }

  private loadMyRegistrations() {
    this.registrationService.getMyRegistrations().subscribe((registrations: Array<RegistrationList>) => {
      this.myRegistrations = registrations;
      this.openRegistrations = this.myRegistrations.filter((registration) => registration.status !== RegistrationStates.Completed);
      this.openRegistrations = this.registrationService.sortRegistrations(this.openRegistrations);
      this.completedRegistrations = this.myRegistrations.filter((registration) => registration.status === RegistrationStates.Completed);
    });
  }

}

import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import {RegistrationService} from '../../events/registration/registration.service';
import {environment} from '../../../environments/environment';
import {EventsService} from '../../events/events.service';
import {WaitingService} from '../waiting.service';
import {WaitingListItemList} from '../waiting-list-item-list.model';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-waiting-list-table',
  templateUrl: './waiting-list-table.component.html',
  styleUrls: ['./waiting-list-table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WaitingListTableComponent implements OnInit {
  @Input() waitings!: Array<WaitingListItemList>;
  myWaitingList!: Array<WaitingListItemList>;
  externalSiteUrl: string;

  constructor(private registrationService: RegistrationService,
              private eventsService: EventsService,
              private waitingService: WaitingService,
              private translocoService: TranslocoService) {
              this.externalSiteUrl = environment.externalSite;
  }

  ngOnInit() {
    this.myWaitingList = this.waitings;
  }

  deleteRegistration(registration: WaitingListItemList) {
    this.registrationService.setStateDelete(registration.id).subscribe(() => {
      this.removeFromList(registration);
    }, (e) => {
    });
  }

  removeFromList(registration: any) {
    const index = this.myWaitingList.indexOf(registration, 0);
    if (index > -1) {
      this.myWaitingList.splice(index, 1);
      this.myWaitingList = [...this.myWaitingList];
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '@premotec/ngx-essentials';
import { HandHeldScannerService } from '../services/hand-held-scanner.service';
import { AccessSystemScannerListModel } from '../models/access-system-scanner-list.model';
import { ToastrService } from 'ngx-toastr';
import { TranslocoService } from '@ngneat/transloco';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-scanner-selector',
  templateUrl: './scanner-selector.component.html',
  styleUrls: ['./scanner-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ScannerSelectorComponent,
      multi: true
    }]
  })
export class ScannerSelectorComponent extends BaseComponent implements OnInit, ControlValueAccessor {

  isLoading = false;
  accessSystemScanners: AccessSystemScannerListModel[] = [];
  formControl = new FormControl();
  isDisabled = false;

  onChange: any = () => { };
  onTouched: any = () => { };

  constructor(
    private handHeldScannerService: HandHeldScannerService,
    private toastrService: ToastrService,
    private translocoService: TranslocoService,
    private activatedRoute: ActivatedRoute,
    private router: Router) { 
    super();
  }

  ngOnInit(): void {
    this.getAccessSystemScanners();
    this.whileImAlive(this.formControl.valueChanges).subscribe(res => {
      this.router.navigate(
        [], 
        {
          relativeTo: this.activatedRoute,
          queryParams: { selectedAccessSystemScannerId: res }, 
          queryParamsHandling: 'merge', // remove to replace all query params by provided
        });
      this.onChange(res);
    });
  }

  writeValue(obj: any): void {
    this.formControl.patchValue(obj);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    } 
  }



  getAccessSystemScanners(): void {
    this.isLoading = true;
    this.whileImAlive(this.handHeldScannerService.getAccessSystemScanners()).subscribe(res => {
        this.accessSystemScanners = res.sort((a, b) => a.addressName.localeCompare(b.addressName));
        this.isLoading = false;
    }, () => {
        this.toastrService.error(this.translocoService.translate('HandHeldScanner.LoadingError'));
        this.isLoading = false;
    });
}

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Discount } from '../models/discount-giftcard/discount.model';
import { GiftCard } from '../models/discount-giftcard/giftcard.model';
import { HasDiscountGiftCard } from '../models/discount-giftcard/has-discount-giftcard.model';
import { ValidateDiscountPost } from '../models/discount-giftcard/validate-discount-post.model';
import { ValidateGiftCardPost } from '../models/discount-giftcard/validate-giftcard-post.model';
import { ValidateZkbDiscountPost } from '../models/discount-giftcard/validate-zkb-discount-post.model';

@Injectable({
    providedIn: 'root'
  })
export class DiscountGiftcardService {

    constructor(
        private httpService: HttpClient) {
    }

    getRegistrationHasDiscount(registrationId: number): Observable<HasDiscountGiftCard> {
        return this.httpService.get(`${environment.api}/DiscountAndGiftCard/Registration/${registrationId}/HasDiscountGiftCard`)
            .pipe(
                map((res: any): HasDiscountGiftCard => res)
            );
    }

    getPersonMembershipHasDiscount(personMembershipId: number): Observable<HasDiscountGiftCard> {
        return this.httpService.get(`${environment.api}/DiscountAndGiftCard/PersonMembership/${personMembershipId}/HasDiscountGiftCard`)
            .pipe(
                map((res: any): HasDiscountGiftCard => res)
            );
    }

    getRegistrationValidateZkbDiscount(registrationId: number, zkbValidateDiscountPost: ValidateZkbDiscountPost): Observable<Discount> {
        return this.httpService.post(`${environment.api}/DiscountAndGiftCard/Registration/${registrationId}/ValidateZkbDiscount`, zkbValidateDiscountPost)
            .pipe(
                map((res: any): Discount => res)
            );
    }

    getPersonMembershipValidateZkbDiscount(personMembershipId: number, zkbValidateDiscountPost: ValidateZkbDiscountPost): Observable<Discount> {
        return this.httpService.post(`${environment.api}/DiscountAndGiftCard/PersonMembership/${personMembershipId}/ValidateZkbDiscount`, zkbValidateDiscountPost)
            .pipe(
                map((res: any): Discount => res)
            );
    }

    getRegistrationValidateDiscount(registrationId: number, validateDiscountPost: ValidateDiscountPost): Observable<Discount> {
        return this.httpService.post(`${environment.api}/DiscountAndGiftCard/Registration/${registrationId}/ValidateDiscount`, validateDiscountPost)
            .pipe(
                map((res: any): Discount => res)
            );
    }

    getPersonMembershipValidateDiscount(personMembershipId: number, validateDiscountPost: ValidateDiscountPost): Observable<Discount> {
        return this.httpService.post(`${environment.api}/DiscountAndGiftCard/PersonMembership/${personMembershipId}/ValidateDiscount`, validateDiscountPost)
            .pipe(
                map((res: any): Discount => res)
            );
    }

    getRegistrationValidateGiftCard(registrationId: number, validateGiftCardPost: ValidateGiftCardPost): Observable<GiftCard> {
        return this.httpService.post(`${environment.api}/DiscountAndGiftCard/Registration/${registrationId}/ValidateGiftCard`, validateGiftCardPost)
            .pipe(
                map((res: any): GiftCard => res)
            );
    }

    getPersonMembershipValidateGiftCard(personMembershipId: number, validateGiftCardPost: ValidateGiftCardPost): Observable<GiftCard> {
        return this.httpService.post(`${environment.api}/DiscountAndGiftCard/PersonMembership/${personMembershipId}/ValidateGiftCard`, validateGiftCardPost)
            .pipe(
                map((res: any): GiftCard => res)
            );
    }
}

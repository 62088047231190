import { Component, OnInit } from '@angular/core';
import { HttpResponseErrorHandlerService } from '../services/http-response-error-handler.service';

@Component({
  selector: 'app-page-server-error',
  templateUrl: './page-server-error.component.html',
  styleUrls: ['./page-server-error.component.scss']
})
export class PageServerErrorComponent implements OnInit {

  constructor(
    private httpResponseErrorHandlerService:HttpResponseErrorHandlerService){ }

  ngOnInit() {
  }

  getRequestId(): string {
    return this.httpResponseErrorHandlerService.RequestId;
  }

  getRequestDate(): Date {
    return new Date(this.httpResponseErrorHandlerService.RequestDate);
  }
}

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BaseComponent } from '@premotec/ngx-essentials';
import { Account } from 'src/app/account/account.model';
import { AccountService } from 'src/app/account/account.service';
import { ApplicationUser } from 'src/app/security/application-user.model';
import { UserAuthorizationService } from 'src/app/security/user-authorization.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-side-navbar',
  templateUrl: './side-navbar.component.html',
  styleUrls: ['./side-navbar.component.scss']
})
export class SideNavbarComponent extends BaseComponent implements OnInit {
  papercutUrl = environment.papercutUrl;
  hangfireUrl = environment.hangfireUrl;

  @Output() isCollapsedSidebar = new EventEmitter<boolean>();

  collapsedSidebar: boolean = false;

  public userName: string | undefined = '';
  public account?: Account;

  isNavOfficeVisible: boolean = false;
  isNavAdminVisible: boolean = false;
  isNavPersonAndEventSearchVisible: boolean = false;
  isNavTLToolVisible: boolean = false;

  isProductionEnvironment = environment.production;
  isStagingEnvironment = environment.staging;

  constructor(private authenticationService: UserAuthorizationService, private accountService: AccountService) {
    super();
  }

  ngOnInit() {
    this.authenticationService.userHasChanges$.subscribe(() => {
      this.initData(this.authenticationService.getUser());
    });

    AccountService.accountChangedEvent.subscribe((account: Account) => {
      this.getAccount();
    });

  }

  toggleNavbar() {
    this.collapsedSidebar = !this.collapsedSidebar
    this.isCollapsedSidebar.emit(!this.collapsedSidebar);
  }

  private initData(user: ApplicationUser) {
    this.userName = user.given_name;

    this.isNavOfficeVisible = user.hasRoleOffice() || user.hasRoleAdmin();
    this.isNavAdminVisible = user.hasRoleAdmin();
    this.isNavPersonAndEventSearchVisible = user.hasRoleOffice() || user.hasRoleAdmin();
    this.isNavTLToolVisible = user.hasRoleOffice() || user.hasRoleHspl() || user.hasRoleTrainingsleiter() || user.hasRoleAdmin();

    this.getAccount();
  }

  private getAccount() {
    this.whileImAlive(
      this.accountService.getAccountDetails()
    ).subscribe((data) => {
      this.account = data;
    });
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-waiting-list-main-layout',
  templateUrl: './waiting-list-main-layout.component.html',
  styleUrls: ['./waiting-list-main-layout.component.scss']
})
export class WaitingListMainComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

<div class="container-fluid">
  <div class="row">
    <div class="col offset-md-2 col-md-8">
      <h1>{{'MyWaitingList' | transloco}}</h1>
      <div>
        <div style="padding-top: 3rem;" *ngIf="waitList">
          <app-waiting-list-table *ngIf="waitList.length" [waitings]="waitList">
          </app-waiting-list-table>
          <div *ngIf="waitList && !waitList.length" class="lead">
            {{'NoEntries' | transloco}}
          </div>
        </div>
      </div>
      <div style="margin-bottom: 3rem;">
        <app-spinner *ngIf="!waitList" [margin]="'1rem auto'"></app-spinner>
      </div>
    </div>
  </div>
</div>
import {Component, Input, Output, OnInit, OnChanges, SimpleChanges, OnDestroy, EventEmitter} from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, timer as TimerObservable } from 'rxjs';
import { RegistrationGuardService } from '../registration-guard.service';
import { RegistrationService } from '../registration.service';
import { RegistrationSummary } from '../registration-summary.model';
import { RegistrationStates } from '../registration-states.enum';

@Component({
  selector: 'app-registration-timer-display',
  templateUrl: './registration-timer-display.component.html',
  styleUrls: ['./registration-timer-display.component.scss']
})
export class RegistrationTimerDisplayComponent implements OnInit, OnChanges, OnDestroy {
  @Input() registrationSummary!: RegistrationSummary;

  @Output() nextState: EventEmitter<any> = new EventEmitter();

  registrationExpirationTimerDisplay: any;
  timerInterval: any;
  syncTimerInterval: any;

  private syncTimerSubscription!: Subscription;
  private timerSubscription!: Subscription;

  secondsRemaining: any;
  refreshingTimerSubmitted = false;

  constructor(
      private registrationService: RegistrationService,
      private router: Router,
      private registrationGuardService: RegistrationGuardService
    ) {
  }

  ngOnInit() {
    this.setExpirationTime();
    this.startSyncTimer();
    this.registrationGuardService.outOfTime = false;
  }

  ngOnDestroy() {
    this.stopTimer();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['registrationSummary']?.currentValue) {
      this.registrationSummary = changes['registrationSummary'].currentValue;
    }
  }

  // The extends the expiration date
  refreshTimer() {
    this.refreshingTimerSubmitted = true;
    this.registrationService.refreshUntilExpiration(this.registrationSummary.id!).subscribe((timeUntilExpiration) => {
      this.secondsRemaining = timeUntilExpiration;

      // add 3 seconds timeout
      setTimeout(() => {
        this.refreshingTimerSubmitted = false;
      }, 3000)
      
    });
  }

  // Only for display the seconds properly
  private startTimer() {
    this.setTimerDisplay(this.secondsRemaining);
    const timer = TimerObservable(0, 1000);
    this.timerSubscription = timer.subscribe(t => {
      --this.secondsRemaining;

      if (this.secondsRemaining <= 0 && this.registrationSummary.statusName !== RegistrationStates.Expired.toFixed()) {
        this.stopTimer();
        this.registrationService.setStateExpired(this.registrationSummary.id!).subscribe(() => {
          this.registrationGuardService.outOfTime = true;
          this.router.navigate(([`/registration/${this.registrationSummary.id}/details`]));
        });
      }

      this.setTimerDisplay(this.secondsRemaining);
    });
  }

  // Retrieves the expiration time and sets it to the local var
  private setExpirationTime() {
    this.registrationService.getTimeUntilExpiration(this.registrationSummary.id!).subscribe((timeUntilExpiration) => {
      if (this.timerSubscription) {
        this.timerSubscription.unsubscribe();
      }
      this.secondsRemaining = timeUntilExpiration;
      this.startTimer();
    });
  }

  private stopTimer() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }

    if (this.syncTimerSubscription) {
      this.syncTimerSubscription.unsubscribe();
    }
  }

  // Calls expiration time every 5 seconds to stay in sync
  private startSyncTimer() {
    const syncTimer = TimerObservable(0, 5000);
    this.syncTimerSubscription = syncTimer.subscribe(t => {
      this.setExpirationTime();
    });
  }

  // For display it properly on the view
  private setTimerDisplay(secondsRemaining: number) {
    const minutes: number = Math.floor(secondsRemaining / 60);
    const seconds: number = secondsRemaining % 60;

    const minutesDisplay = (minutes < 10) ? `0${minutes}` : `${minutes}`;
    const secondsDisplay = (seconds < 10) ? `0${seconds}` : `${seconds}`;

    this.registrationExpirationTimerDisplay = `${minutesDisplay}:${secondsDisplay}`;
  }
}

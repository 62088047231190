<div class="modal-header text-light">
    <h3>{{'Tournaments.Teams.AddRoom' | transloco}}</h3>
</div>

<div class="modal-body">
    <form *ngIf="tournamentFacilities" [formGroup]="roomForm">
        <div class="row">
            <div class="row">
                <div class="col-6 mb-3">
                    <label for="facilityId" class="form-label">{{'Tournaments.PlanningData.Location.Facility' |
                        transloco}} *</label>
                    <div>
                        <ng-select formControlName="facilityId" id="facilityId" (change)="onFacilityChange()"
                            [readonly]="!tournamentFacilities">
                            <ng-option *ngFor="let facility of tournamentFacilities" [value]="facility.id">
                                {{facility.name}} ({{facility.rooms.length}})
                            </ng-option>
                        </ng-select>
                    </div>
                </div>
                <div class="col-6 mb-3">
                    <label for="roomId" class="form-label">{{'Tournaments.PlanningData.Location.Room' | transloco}}
                        *</label>
                    <div>
                        <ng-select formControlName="roomId" id="roomId"
                            [readonly]="!tournamentRooms || tournamentRooms.length <= 1">
                            <ng-option *ngFor="let room of tournamentRooms" [value]="room.id">
                                {{room.name}}
                            </ng-option>
                        </ng-select>
                    </div>
                </div>
                <div class="alert alert-danger m-0" *ngIf="!roomForm.valid && formSubmitted">
                    <p class="m-0" [innerHTML]="'FillRequired' | transloco">
                    </p>
                </div>
            </div>
        </div>
    </form>
</div>

<div class="modal-footer text-light">
    <button type="button" class="btn btn-default" (click)="decline()">{{'Cancel' | transloco}}</button>
    <button type="submit" class="btn btn-success" (click)="confirm()">
        <i *ngIf="!isSaving" class="fas fa-save"></i> 
        <span *ngIf="isSaving" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> {{'Save' | transloco}}
    </button>
    
</div>
<div class="d-flex flex-column gap-3 pb-3">

    <div class="card" *ngIf="!editMode && formattedImage">
        <div class="card-header">
            {{'FormattedPassportPhoto' | transloco }}
            <div class="d-flex gap-1">
                <button class="btn btn-default btn-sm" (click)="editImage(1)" *ngIf="!readonly">
                    <i class="fa fa-edit"></i>
                </button>
                <button class="btn btn-danger  btn-sm" (click)="deleteFilShowModal()" *ngIf="!readonly">
                    <i class="fa fa-trash text-light"></i>
                </button>
            </div>
        </div>
        <div class="card-body">
            <div class="image-container">
                <img id="image" *ngIf="formattedImage" [src]="formattedImage" />
            </div>
        </div>
    </div>

    <div class="card" *ngIf="!editMode">
        <div class="card-header">
            {{'PassportPhoto' | transloco }}
            <button class="btn btn-default btn-sm" (click)="editImage(1)" *ngIf="!readonly">
                <i class="fa fa-edit"></i>
            </button>
        </div>
        <div class="card-body">
            <div class="image-container">
                <img id="image" *ngIf="originalImage" [src]="originalImage" class="image" />
            </div>
            <div class="alert alert-primary" *ngIf="!originalImage && !isLoading">
                {{'Office.Msg.ImageCouldNotBeLoaded' | transloco}}
            </div>
            <app-spinner *ngIf="isLoading && !originalImage"></app-spinner>
        </div>
    </div>

    <div class="card" *ngIf="editMode">
        <div class="card-header">
            <button class="btn btn-default btn-sm" (click)="editMode = !editMode">
                <i class="fa fa-arrow-left"></i>
            </button>
            {{'Office.EditPassportPhoto' | transloco }}
        </div>
        <div class="card-body">
            <div class="d-flex gap-1">
                <div class="btn-group" role="group">
                    <button class="btn btn-default btn-sm" (click)="rotateLeft()">
                        <i class="fa fa-rotate-left"></i>
                    </button>
                    <button class="btn btn-default btn-sm" (click)="rotateRight()">
                        <i class="fa fa-rotate-right"></i>
                    </button>
                </div>

                <button class="btn btn-primary btn-sm" (click)="saveChanges()">
                    {{'Save' | transloco}}
                </button>
            </div>

            <image-cropper *ngIf="base64Data" [imageBase64]="base64Data" [maintainAspectRatio]="true"
                [canvasRotation]="canvasRotation" [aspectRatio]="3 / 4" [resizeToWidth]="300" [onlyScaleDown]="true"
                (format)="originalFileFormat" (imageCropped)="imageCropped($event)" alignImage="left"
                style="padding:none"></image-cropper>
        </div>
    </div>
</div>
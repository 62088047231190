<div class="well" style="margin-bottom:0;" [ngClass]="ngClass">

    <table class="table table-striped" *ngIf="files.length || applicationFiles.length">
        <tr>
          <td>Filename</td>
          <td>Filetype</td>
          <td style="width: 1px;"></td>
        </tr>
        <tr *ngFor="let file of applicationFiles;" class="file">
          <td>
            <button class="btn btn-link" (click)="downloadFile(file)">{{file.fileName}}</button></td>
          <td>{{file.fileName.split('.').pop()}}</td>
          <td>
              <button class="btn btn-danger btn-sm text-light" (click)="showDeleteModal(file)" *ngIf="!isDisabled">
                  <i class="fa fa-trash text-light"></i>
              </button>
          </td>
        </tr>
      </table>

<label class="btn btn-primary btn-file" *ngIf="!isDisabled">
  <i class="fa fa-plus-circle me-1" aria-hidden="true" style="color: white;"></i>
  <span>{{'FileUploader.BrowseFiles' | transloco}}</span>
  <input type="file" name="myFile" class="form-control" style="display: none;" multiple (change)="onFileChange($event)">
</label>
</div>


import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RequiredSkill } from 'src/app/events/required-skill.model';
import { Membership } from 'src/app/memberships/models';
import { UserAuthorizationService } from 'src/app/security/user-authorization.service';
import { EnrollmentCreateResponse } from '../../models/enrollment-create-response.model';
import { Enrollment } from '../../models/enrollment.model';
import { Lesson } from '../../models/lesson.model';
import { EnrollmentsService } from '../../services/enrollments.service';
import { BaseComponent } from '@premotec/ngx-essentials';
import { EnrollmentMessageStateService } from '../../services/enrollment-message-state.service';
import { EnrollmentStateService } from '../../services/enrollment-state.service';
import { EnrollmentStateProperties } from '../../models/enrollment-state-properties.model';
import { MembershipHelperService } from '../../services/membership-helper.service';
import { LessonsService } from '../../services/lessons.service';
import { exhaustMap, takeWhile, timer } from 'rxjs';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Router } from '@angular/router';
import { CurrentEnrollment } from '../../models/current-enrollment.model';
import { LessonStatusEnum } from '../../enums/lesson-status.enum';
import { LotteryHeaderStatusEnum } from '../../enums/lottery-header-status.enum';
import { differenceInMilliseconds } from 'date-fns';
import { DateHelperService } from 'src/app/shared/services/date-helper.service';

@Component({
  selector: 'app-enrollment-container',
  templateUrl: './enrollment-container.component.html',
  styleUrls: ['./enrollment-container.component.scss'],
  providers: [
    EnrollmentMessageStateService,
    EnrollmentStateService,
    MembershipHelperService]
})
export class EnrollmentContainerComponent extends BaseComponent implements OnInit {

  @Input() missingSkills!: RequiredSkill[];
  @Input() memberships!: Membership[];
  @Input() lesson!: Lesson;

  @Output() enrollmentChanges: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() liveStreamShow: EventEmitter<void> = new EventEmitter<void>();

  lessonStatusEnum = LessonStatusEnum;

  dataLoaded!: boolean;
  isWithdrawing = false;
  isEnrolling = false;
  isDrawing = false;

  enrollmentProperties$ = this.enrollmentStateService.enrollmentStateProperties$;
  enrollmentProperties: EnrollmentStateProperties | null = null;

  get userIsLoggedIn(): boolean {
    return this.authenticationService.getUser().isAuthenticated();
  }

  constructor(
    private authenticationService: UserAuthorizationService,
    private enrollmentService: EnrollmentsService,
    private enrollmentStateService: EnrollmentStateService,
    private enrollmentMessageStateService: EnrollmentMessageStateService,
    private lessonService: LessonsService,
    private router: Router,
    private dateHelperService: DateHelperService
  ) {
    super();
  }

  ngOnChanges() {
    this.loadEnrollmentData();
  }

  ngOnInit() {
    this.whileImAlive(this.enrollmentStateService.enrollmentStateProperties$).subscribe((properties) => {
      this.enrollmentProperties = properties;
    });
  }

  loadEnrollmentData(): void {
    if (this.userIsLoggedIn) {
      this.loadEnrollmentByLessonId(this.lesson.id);
    } else {
      this.enrollmentStateService.setData(this.lesson, this.missingSkills, this.memberships, null);
      this.startOpenEnrollmentTimer();
      this.dataLoaded = true;
    }
  }

  private loadEnrollmentByLessonId(lessonId: number) {
    this.whileImAlive(
      this.enrollmentService.getEnrollmentByLessonId(lessonId)
    ).subscribe({
      next: (enrollment: Enrollment) => {

        const currentEnrollment: CurrentEnrollment = {
          placeNumber: enrollment.placeNumber,
          status: enrollment.status
        };
        this.enrollmentStateService.setData(this.lesson, this.missingSkills, this.memberships, currentEnrollment);
        this.startOpenEnrollmentTimer();
        this.dataLoaded = true;
      },
      error: (res) => {
        if (res.status === HttpStatusCode.NotFound) {
          this.enrollmentStateService.setData(this.lesson, this.missingSkills, this.memberships, null);
          this.startOpenEnrollmentTimer();
          this.dataLoaded = true;
        } else {
          this.router.navigate(['/error']);
        }
      }
    });
  }


  public showLiveStream(): void {
    this.liveStreamShow.emit();
  }

  withdrawFromLesson(): void {
    this.dataLoaded = false;
    if (!this.isWithdrawing) {
      this.isWithdrawing = true;
      this.enrollmentMessageStateService.resetMessages();
      this.lessonService.cancelEnrollment(this.lesson.id).subscribe({
        next: () => {
          this.isWithdrawing = false;
          this.enrollmentMessageStateService.setShowWithdrawSuccessMsg(true);
          this.enrollmentChanges.next(true);
        },
        error: (error) => {
          this.isWithdrawing = false;

          // get the error from the response
          switch (error.error.errorStatus) {
            case 'ValidationFailure':
            case 'NotUpdateable':
              if (error.error && error.error.errors.length > 0) {
                this.enrollmentMessageStateService.setValidationError(error.error.errors[0].message);
              } else {
                // we didn't get a validation error. Show the general one
                this.enrollmentMessageStateService.setShowWithdrawErrorMsg(true);
              }
              break;
            default:
              this.enrollmentMessageStateService.setShowWithdrawErrorMsg(true);
          }
        }
      });
    }
  }

  enrollForLesson(): void {
    if (!this.isEnrolling) {
      this.isEnrolling = true;
      this.dataLoaded = false;
      this.enrollmentMessageStateService.resetMessages();
      this.whileImAlive(
        this.lessonService.enrollForLesson(this.lesson.id)
      ).subscribe({
        next: (enrollmentCreateResponse: EnrollmentCreateResponse) => {
          if (enrollmentCreateResponse.lottery?.lotteryStatus === LotteryHeaderStatusEnum.Open) {
            this.enrollmentStateService.setCurrentEnrollmentLottery(enrollmentCreateResponse.lottery);
            this.checkLotteryState();
          } else {
            this.successfullyEnrolled();
          }
        },
        error: (error: HttpErrorResponse) => {
          this.handleEnrollmentError(error);
        }
      });
    }
  }

  handleEnrollmentError(error: any): void {
    this.isEnrolling = false;
    switch (error.error.errorStatus) {
      case 'ValidationFailure':
      case 'NotUpdateable':
        if (error.error && error.error.errors.length > 0) {
          this.enrollmentMessageStateService.setValidationError(error.error.errors[0].message);
        } else {
          // we didn't get a validation error. Show the general one
          this.enrollmentMessageStateService.setShowEnrollErrorMsg(true);
        }
        break;
      default:
        if (error?.error?.errors?.[0]) {
          this.enrollmentMessageStateService.setValidationError(error.error.errors[0].message);
        } else {
          this.enrollmentMessageStateService.setShowEnrollErrorMsg(true);
        }
    }
    this.enrollmentChanges.next(true);
  }

  checkLotteryState(): void {
    this.isDrawing = true;
    const lotteryDurationInSeconds = this.enrollmentStateService.getLotteryDurationInSeconds();
    this.whileImAlive(timer((lotteryDurationInSeconds) * 1000, 1000)
      .pipe(
        exhaustMap(() => this.enrollmentService.getEnrollmentLotteryState(this.lesson.id)),
        takeWhile((res) => res.lotteryStatus === LotteryHeaderStatusEnum.Open, true),
      )
    ).subscribe({
      next: (res) => {
        if (res.lotteryStatus === LotteryHeaderStatusEnum.Finished) {
          if (res.hasSeat === true) {
            this.successfullyEnrolled();
          } else {
            this.enrollmentMessageStateService.setShowLotteryErrorMsg(true)
            this.enrollmentChanges.next(true);
          }
          this.isDrawing = false;
          this.isEnrolling = false;
          this.enrollmentStateService.setCurrentEnrollmentLottery(null);
        }
      },
      error: (error) => {
        this.isDrawing = false;
        this.enrollmentStateService.setCurrentEnrollmentLottery(null);
        this.handleEnrollmentError(error);
      }
    });
  }

  private successfullyEnrolled(): void {
    this.isEnrolling = false;
    this.enrollmentMessageStateService.setShowEnrollSuccessMsg(true);
    this.enrollmentChanges.next(true);
  }

  startOpenEnrollmentTimer(): void {
    if (!this.enrollmentProperties?.enrollmentIsOpen && !this.enrollmentProperties?.enrollmentIsExpired) {

      const delay = this.dateHelperService.differenceInSeconds(new Date(this.lesson.enrollmentFrom), new Date());

      // start timer if difference is <= 1day
      if (delay <= 8.64e+7) {
        const enrollmentTimer = setInterval(() => {
          const now = this.dateHelperService.startOfSecond(new Date());
          const enrollmentFrom = this.dateHelperService.startOfSecond(new Date(this.lesson.enrollmentFrom));
          const diff = differenceInMilliseconds(enrollmentFrom, now);
          if (diff <= 0) {
            if (this.enrollmentStateService?.enrollmentIsOpen) {
              clearInterval(enrollmentTimer);
              this.enrollmentStateService.setEnrollmentStateProperties();
            }
          }
        }, 250);
      }
    }
  }
}

<div class="modal-header">
    <h2>{{title}}</h2>
</div>
<div class="modal-body">
    <ng-content></ng-content>
</div>
<div class="modal-footer">
    <button class="btn btn-default" [title]="'Cancel' | transloco" (click)="close()" [disabled]="isSaving">{{'Cancel' |
        transloco}}</button>
    <button class="btn btn-primary" [title]="'Save' | transloco" (click)="save()" appLoadingButton [loading]="isSaving">
        {{'Save' | transloco}}
    </button>
</div>
import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { Router } from '@angular/router';
import { RegistrationService } from '../registration.service';
import { RegistrationSummary } from '../registration-summary.model';
import { RegistrationGuardService } from '../registration-guard.service';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { TranslocoService } from '@ngneat/transloco';
import { ConfirmationModalComponent } from 'src/app/shared/confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-registration-cancel-modal',
  templateUrl: './registration-cancel-modal.component.html',
  styleUrls: ['./registration-cancel-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RegistrationCancelModalComponent implements OnInit {

  @Input() registration!: RegistrationSummary;
  constructor(
    private registrationService: RegistrationService,
    private registrationGuardService: RegistrationGuardService,
    private router: Router,
    private modalService: BsModalService,
    private translocoService: TranslocoService) { }

  ngOnInit() {
  }

  cancel() {
    this.registrationService.setStateDelete(this.registration.id!).subscribe(() => {
      this.registrationGuardService.formIsDirty = false;
      this.router.navigateByUrl('/events/' + this.registration.eventId + '?action=cancelled')
    });
  }

  showModal() {
    const initialstate: ModalOptions = {
      initialState: {
        body: this.translocoService.translate('EventActions.ConfirmCancellation'),
        confirmText: this.translocoService.translate('Yes'),
        declineText: this.translocoService.translate('No'),
      }
    }

    const bsModalRef = this.modalService.show(ConfirmationModalComponent, initialstate);

    bsModalRef.content!.confirmed.subscribe(() => {
      this.cancel();
    })
  }

}

import { Component, OnInit, EventEmitter, Output, ViewChild, Input } from '@angular/core';
import { MembershipState } from '../../membership-state.enum';
import { AccountChangeDataFormComponent } from '../../../shared/account-change-data-form/account-change-data-form.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AccountService } from '../../../account/account.service';
import { BaseComponent } from '@premotec/ngx-essentials';
import { forkJoin as observableForkJoin, Subject, Observable, forkJoin } from 'rxjs';
import { MembershipExtension, ConfirmMembership } from '../../models';
import { MembershipService } from '../../membership.service';
import { MembershipCheckPastSchoolComponent } from '../membership-check-past-school/membership-check-past-school.component';
import { LastSchoolUpdateModel } from '../../models/last-school-update.model';


@Component({
  selector: 'app-membership-check-user-data',
  templateUrl: './membership-check-user-data.component.html',
  styleUrls: ['./membership-check-user-data.component.scss']
})
export class MembershipCheckUserDataComponent extends BaseComponent implements OnInit {
  @ViewChild('accountChangeDataFromComponent') accountChangeDataFromComponent!: AccountChangeDataFormComponent;
  @ViewChild('membershipCheckPastSchoolComponent') membershipCheckPastSchoolComponent!: MembershipCheckPastSchoolComponent;

  @Input() membershipExtension!: MembershipExtension;
  @Output() nextState: EventEmitter<number> = new EventEmitter();
  @Output() updateMembershipExtension: EventEmitter<MembershipExtension> = new EventEmitter();

  confirmForm!: FormGroup;
  disableSendButton = false;
  submitted = false;

  constructor(private accountService: AccountService, private membershipService: MembershipService, private fb: FormBuilder) {
    super();
  }

  ngOnInit() {
    this.formInit();
  }

  formInit() {
    this.confirmForm = this.fb.group({
      acceptCorrectContactDetails: [false, Validators.requiredTrue],
      acceptAvb: [false, Validators.requiredTrue],
    });
  }


  sendForm() {
    if (this.membershipExtension.isLastSchoolRequired) this.membershipCheckPastSchoolComponent.validate();

    const confirm = {
      acceptAvb: true,
      acceptAusschreibung: true,
      acceptMembership: true,
      acceptCorrectContactDetails: true,
    };
    if (this.accountChangeDataFromComponent.getForm().valid && this.confirmForm.valid && this.isLastSchoolValid()) {
      this.disableSendButton = true;

      const requests = [];
      requests.push(this.accountService.updateAccountDetails(this.accountChangeDataFromComponent.getUpdatedUserData()));
      requests.push(this.membershipService.confirmExtension(this.membershipExtension.id, confirm));
      if (this.membershipExtension.isLastSchoolRequired) {
        const lastSchoolId = this.membershipCheckPastSchoolComponent.getValue();
        requests.push(this.membershipService.setLastSchool(this.membershipExtension.id, { schoolId: lastSchoolId }));
      }

      this.whileImAlive(forkJoin(requests))
        .subscribe(data => {
          this.nextState.emit(MembershipState.Temporary);
        });
    } else {
      this.submitted = true;
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }
  }

  isLastSchoolValid() {
    if (!this.membershipExtension.isLastSchoolRequired) return true;
    return this.membershipCheckPastSchoolComponent.isValid();
  }

  back() {
    this.nextState.emit(MembershipState.PassPhoto);
  }
}

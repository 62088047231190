import {NgModule} from '@angular/core';
import {CommonModule, DecimalPipe} from '@angular/common';
import {SharedModule} from '../shared/shared.module';
import {RouterModule} from '@angular/router';
import {eventRoutes} from './events.routes';
import {EventsService} from './events.service';
import {RegistrationMainComponent} from './registration/registration-main/registration-main.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RegistrationService} from './registration/registration.service';
import { RegistrationGuardService } from './registration/registration-guard.service';
import { SportsModule } from '../sports/sports.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgxEssentialsModule } from '@premotec/ngx-essentials';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { AccountChangeDataFormComponent } from '../shared/account-change-data-form/account-change-data-form.component';
import { AccountPassPhotoUploaderComponent } from '../shared/account-pass-photo-uploader/account-pass-photo-uploader.component';
import { StatusWorkflowComponent } from '../shared/status-workflow/status-workflow.component';
import { MomentPipe } from '../shared/moment.pipe';
import { EventPropertiesDisplayComponent } from './event-properties-display/event-properties-display.component';
import { RegistrationTemporaryComponent } from './registration/registration-temporary/registration-temporary.component';
import { RegistrationCheckUserDataComponent } from './registration/registration-check-user-data/registration-check-user-data.component';
import { RegistrationDetailsViewComponent } from './registration/registration-details/registration-details-view/registration-details-view.component';
import { RegistrationProvePermissionComponent } from './registration/registration-prove-permission/registration-prove-permission.component';
import { RegistrationDetailsComponent } from './registration/registration-details/registration-details/registration-details.component';
import { RegistrationTimerDisplayComponent } from './registration/registration-timer-display/registration-timer-display.component';
import { RegistrationCancelModalComponent } from './registration/registration-cancel-modal/registration-cancel-modal.component';
import { RegistrationInsuranceInfoComponent } from './registration/registration-insurance-info/registration-insurance-info.component';
import { RegistrationDetailsQuestionComponent } from './registration/registration-details/registration-details-question/registration-details-question.component';
import { CheckboxComponent } from './registration/registration-details/controls/checkbox/checkbox.component';
import { TriStateComponent } from './registration/registration-details/controls/tri-state/tri-state.component';
import { SwitchComponent } from './registration/registration-details/controls/switch/switch.component';
import { RegistrationSummaryComponent } from './registration/registration-summary/registration-summary.component';
import { RegistrationCancelPaymentComponent } from './registration/registration-cancel-payment/registration-cancel-payment.component';
import { EventDetailsComponent } from './event-details/event-details.component';
import { EventRegistrationButtonComponent } from './event-registration-button/event-registration-button.component';
import { FacilitiesService } from '../office/facilities/services/facilities.service';
import { NgxDatepickerModule } from '@premotec/ngx-datepicker';
import { RegistrationDeregisterPaidModalComponent } from './registration/registration-deregister-paid-modal/registration-deregister-paid-modal.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    SportsModule,
    RouterModule.forChild(eventRoutes),
    ReactiveFormsModule,
    NgxDatepickerModule,
    FormsModule
  ],
  declarations: [
    RegistrationMainComponent,
    EventDetailsComponent,
    EventPropertiesDisplayComponent,
    RegistrationDetailsComponent,
    RegistrationTimerDisplayComponent,
    RegistrationTemporaryComponent,
    RegistrationInsuranceInfoComponent,
    RegistrationCancelPaymentComponent,
    RegistrationSummaryComponent,
    CheckboxComponent,
    SwitchComponent,
    TriStateComponent,
    RegistrationDetailsQuestionComponent,
    RegistrationDetailsViewComponent,
    RegistrationProvePermissionComponent,
    RegistrationCheckUserDataComponent,
    RegistrationCancelModalComponent,
    EventRegistrationButtonComponent,
    RegistrationDeregisterPaidModalComponent
    // TabOrderDirective,
  ],
  exports: [ ],
  providers: [
    DecimalPipe,
    EventsService,
    FacilitiesService,
    RegistrationService,
    RegistrationGuardService,
  ]
})
export class EventsModule {
}

<div class="modal-header text-light">
    <h3>{{'Tournaments.GamePlans.Change.Title' | transloco}}</h3>
</div>
<div class="modal-body" *ngIf="!slotData">
    <app-spinner [margin]="'25px'"></app-spinner>
</div>

<div class="modal-body" *ngIf="slotData">
    <div class="row">
        <div class="col-12">
            <h4>{{'Tournaments.GamePlans.Change.Game' | transloco}}</h4>
            <div class="row">
                <div class="col-6">
                    <label for="currentHomeTeamName" class="form-label">{{'Tournaments.GamePlans.Change.Home' |
                        transloco}}</label>
                    <div>
                        <input id="currentHomeTeamName" class="form-control" [value]="slotData.homeTeamName"
                            [disabled]="true">
                    </div>
                </div>
                <div class="col-6">
                    <label for="currentGuestTeamName" class="form-label">{{'Tournaments.GamePlans.Change.Guest' |
                        transloco}}</label>
                    <div>
                        <input id="currentHomeTeamName" class="form-control" [value]="slotData.guestTeamName"
                            [disabled]="true">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr>
    <div class="row mt-3">
        <div class="col-6">
            <h4>{{'Tournaments.GamePlans.Change.Current' | transloco}}</h4>
            <div class="row">
                <div class="col-12">
                    <label for="currentFacility" class="form-label">
                        {{'Tournaments.GamePlans.Change.Favility' | transloco}}
                    </label>
                    <div>
                        <input id="currentFacility" class="form-control" [value]="slotData.currentFacility"
                            [disabled]="true">
                    </div>
                </div>
                <div class="col-12 mt-3">
                    <label for="currentRoom" class="form-label">
                        {{'Tournaments.GamePlans.Change.Room' | transloco}}
                    </label>
                    <div>
                        <input id="currentRoom" class="form-control" [value]="slotData.currentRoom" [disabled]="true">
                    </div>
                </div>
                <div class="col-12 mt-3">
                    <label for="currentField" class="form-label">
                        {{'Tournaments.GamePlans.Change.Field' | transloco}}
                    </label>
                    <div>
                        <input id="currentField" class="form-control"
                            [value]="tournamentDisplayService.getFieldName(slotData.currentField)" [disabled]="true">
                    </div>
                </div>
                <div class="col-12 mt-3">
                    <label for="currentPeriod" class="form-label">
                        {{'Tournaments.GamePlans.Change.Period' | transloco}}
                    </label>
                    <div>
                        <input id="currentPeriod" class="form-control"
                            [value]="tournamentDisplayService.getTimeSlotName(slotData.from, slotData.to)"
                            [disabled]="true">
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6">
            <form [formGroup]="changeSlotForm">
                <h4>{{'Tournaments.GamePlans.Change.New' | transloco}}</h4>
                <div class="row">
                    <div class="col-12">
                        <label for="newFacilityId" class="form-label">
                            {{'Tournaments.GamePlans.Change.Favility' | transloco}}
                        </label>
                        <div>
                            <ng-select formControlName="newFacilityId" id="newFacilityId"
                                [ngClass]="{'border-danger':  !changeSlotForm.controls['newFacilityId'].valid && formSubmitted}"
                                [readonly]="optionsFacilities.length <= 1" (change)="onFacilityChanged()">
                                <ng-option *ngFor="let option of optionsFacilities" [value]="option.facilityId">
                                    {{option.facilityName}}
                                </ng-option>
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-12 mt-3">
                        <label for="newRoomId" class="form-label">
                            {{'Tournaments.GamePlans.Change.Room' | transloco}}
                        </label>
                        <div>
                            <ng-select formControlName="newRoomId" id="newRoomId" [readonly]="optionsRooms.length <= 1"
                                [ngClass]="{'border-danger':  !changeSlotForm.controls['newRoomId'].valid && formSubmitted}"
                                (change)="onRoomChanged()">
                                <ng-option *ngFor="let option of optionsRooms" [value]="option.roomId">
                                    {{option.roomName}}
                                </ng-option>
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-12 mt-3">
                        <label for="newFieldId" class="form-label">
                            {{'Tournaments.GamePlans.Change.Field' | transloco}}
                        </label>
                        <div>
                            <ng-select formControlName="newFieldId" id="newFieldId"
                                [readonly]="optionsFields.length <= 1" (change)="onFieldChanged()"
                                [ngClass]="{'border-danger':  !changeSlotForm.controls['newFieldId'].valid && formSubmitted}">
                                <ng-option *ngFor="let option of optionsFields" [value]="option.field">
                                    {{tournamentDisplayService.getFieldName(option.field)}}
                                </ng-option>
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-12 mt-3">
                        <label for="newSlotId" class="form-label">
                            {{'Tournaments.GamePlans.Change.Period' | transloco}}
                        </label>
                        <div>
                            <ng-select formControlName="newSlotId" id="newSlotId" [readonly]="optionsSlots.length <= 1"
                                [ngClass]="{'border-danger':  !changeSlotForm.controls['newSlotId'].valid && formSubmitted}">
                                <ng-option *ngFor="let option of optionsSlots" [value]="option.slotId">
                                    {{tournamentDisplayService.getTimeSlotName(option.from, option.to)}}
                                </ng-option>
                            </ng-select>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>


</div>

<div class="modal-footer text-light">
    <button type="button" class="btn btn-default ms-2" (click)="decline()">
        {{'Cancel' | transloco}}
    </button>

    <button type="submit" class="btn btn-success ms-2" (click)="confirm()">
        {{'Tournaments.GamePlans.Change.Change' | transloco}}
    </button>
</div>
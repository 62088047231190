<div class="modal-header text-light">
    <h3>{{'Tournaments.Administrators.Add' | transloco}}</h3>
</div>

<div class="modal-body">
    <div class="input-group col col-6 mb-3">
        <span class="input-group-text">
            <i class="fa-solid fa-filter"></i>
        </span>
        <input type="text" placeholder="Person filtern" class="form-control ng-valid ng-dirty ng-touched"
            [(ngModel)]="personFilter" (ngModelChange)="filterPersons()">
        <button type="button" class="btn btn-outline-danger" (click)="clearFilter()"><i class="fa-solid fa-xmark"></i>
        </button>
    </div>

    <ul class="list-group list-group-flush" *ngIf="possibleAdministrators != undefined">
        <li class="list-group-item" *ngFor="let administrator of possibleAdministrators">
            <div class="d-flex">
                <div class="flex-grow-1">
                    <b>{{administrator.firstName}} {{administrator.lastName}}</b> - {{administrator.asvzId}}
                </div>
                <div>
                    <button class="btn btn-sm btn-success" (click)="addPersonAsAdministrator(administrator.personId)"
                        [disabled]="isAdding">
                        <i class="fa-solid fa-plus"></i>
                    </button>
                </div>
            </div>
        </li>
    </ul>
    <app-spinner *ngIf="possibleAdministrators == undefined"></app-spinner>
    <div *ngIf="possibleAdministrators == undefined">

    </div>
</div>

<div class="modal-footer text-light">
    <button type="button" class="btn btn-default" (click)="close()">{{'Close' | transloco}}</button>
</div>
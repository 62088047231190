<app-spinner *ngIf="!availableSlotsModel" [margin]="'50px'"></app-spinner>

<div class="container" *ngIf="availableSlotsModel">
    <div class="row">
        <div class="col-12 col-lg-5 mb-2">
            <div class="card">
                <div class="card-header">
                    <b>Spielorte</b>
                </div>
                <ul class="list-group list-group-flush">
                    <li class="list-group-item" *ngFor="let availableRoom of availableSlotsModel.rooms">
                        <div class="d-flex">
                            <div class="pe-2 align-self-center" [ngStyle]="{'color': availableRoom.color}"><i
                                    class="fa-solid fa-house"></i></div>
                            <div class="flex-grow-1">
                                {{displayService.getLocationName(availableRoom.facilityName,availableRoom.roomName,0,false)}}
                            </div>
                            <div class="ps-2 fw-bold align-self-center"> {{availableRoom.availableSlots}} </div>
                            <div class="ps-2 align-self-center">
                                <span *ngIf="availableRoom.isVisible"
                                    (click)="toggleRoomVisbibility(availableRoom.roomId)" type="button">
                                    <i class="fa-solid fa-eye"></i>
                                </span>
                                <span *ngIf="!availableRoom.isVisible"
                                    (click)="toggleRoomVisbibility(availableRoom.roomId)" type="button">
                                    <i class="fa-solid fa-eye-slash"></i>
                                </span>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-12 col-lg-7">
            <div class="card">
                <div class="card-header">
                    <div class="d-flex">
                        <div class="flex-grow-1">
                            <b>Freie Spielzeiten</b>
                        </div>
                        <div>
                            <div class="form-check form-switch">
                                <label class="form-check-label text-end"
                                    for="showOld">{{'Tournaments.Teams.TeamGames.ShowOld' |
                                    transloco}}</label>
                            </div>
                        </div>
                        <div class="ps-1">
                            <div class="form-check form-switch align-self-center">
                                <input class="form-check-input align-self-lg-center" type="checkbox" id="showOld"
                                    [(ngModel)]="showPast" (ngModelChange)="filterGames()">
                            </div>
                        </div>
                    </div>
                </div>
                <ul class="list-group list-group-flush">
                    <li class="list-group-item" *ngFor="let availableSlot of filteredSlots">
                        <div class="d-flex">
                            <div class="pe-2 align-self-center" [ngStyle]="{'color': availableSlot.color}">

                                <span class="fw-bold" *ngIf="availableSlot.fieldId != 0">
                                    {{availableSlot.fieldId}}
                                </span>
                                <span class="fw-bold" *ngIf="availableSlot.fieldId == 0">
                                    <i class="fa-solid fa-house"></i>
                                </span>

                            </div>
                            <div class="flex-grow-1">
                                <div class="d-flex flex-column flex-lg-row">
                                    <div class=" align-self-start align-self-lg-center pe-2"
                                        style="white-space: nowrap;">
                                        <b>{{displayService.getTimeSlotName(availableSlot.from, availableSlot.to)}}</b>
                                    </div>
                                    <div class="flex-grow-1">
                                        {{displayService.getLocationName(availableSlot.facilityName,availableSlot.roomName,availableSlot.fieldId,false)}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                <div class="card-body" *ngIf="!filteredSlots.length">
                    <div class="well">Es sind keine freien Spielzeiten verfügbar.</div>
                </div>
            </div>
        </div>
    </div>
</div>
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { TournamentService } from '../services/tournament.service';
import { TournamentDisplayService } from '../services/tournament-display.service';
import { TournamentTeamGamesModel } from '../models/tournament-team-games.model';
import { TournamentTeamGameModel } from '../models/tournament-team-game.model';
import * as moment from 'moment';

@Component({
    selector: 'app-tournament-team-referee-games',
    templateUrl: './tournament-team-referee-games.component.html',
    styleUrls: ['./tournament-team-referee-games.component.scss']
})
export class TournamentTeamRefereeGamesComponent implements OnInit {

    tournamentPlanId!: string;
    teamId!: string;
    secret!: string;

    refereeGames!: TournamentTeamGamesModel;
    filteredRefereeGames!: TournamentTeamGameModel[];

    from: Date = new Date();
    to: Date = new Date();

    showPast = false;

    constructor(
        public tournamentDisplayService: TournamentDisplayService,
        private tournamentService: TournamentService,
        private activatedRoute: ActivatedRoute,
    ) {
    }

    ngOnInit(): void {
        // get params
        this.activatedRoute.params.subscribe((params: Params) => {

            this.tournamentPlanId = params['tournamentPlanId'];
            this.teamId = params['teamId'];
            this.secret = params['secret'];

            // get secret
            this.activatedRoute.queryParams.subscribe((params: Params) => {
                this.secret = params['secret'];
                this.loadTeamGames();
            });
        });
    }

    loadTeamGames() {
        this.tournamentService.getTournamentTeamRefereeGames(this.tournamentPlanId, this.teamId, this.secret).subscribe({
            next: (teamDataModel: TournamentTeamGamesModel) => {
                this.refereeGames = teamDataModel;
                this.filterGames();
            },
            error: (error) => {

            }
        });
    }

    filterGames() {
        if (this.refereeGames == undefined) return;
        var currentDate = new Date();

        var games = this.refereeGames.games.sort((a, b) => new Date(a.gameFrom).getTime() - new Date(b.gameFrom).getTime());

        if (!this.showPast) {
            games = games.filter(x => new Date(x.gameTo) > currentDate);
        }

        this.filteredRefereeGames = games;
    }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { TournamentService } from '../services/tournament.service';
import { TournamentDisplayService } from '../services/tournament-display.service';
import { TournamentDisplayAvailableSlotsModel } from '../models/tournament-display-available-slots.model';
import { TournamentDisplayAvailableSlotRoomModel } from '../models/tournament-display-available-slot-room.model';
import { TournamentDisplayAvailableSlotModel } from '../models/tournament-display-available-slot.model';

@Component({
    templateUrl: './tournament-public-available-slots.component.html',
    styleUrls: ['./tournament-public-available-slots.component.scss']
})
export class TournamentPublicAvailableSlotsComponent implements OnInit {

    tournamentPlanId!: string;

    availableSlotsModel!: TournamentDisplayAvailableSlotsModel;
    filteredSlots!: TournamentDisplayAvailableSlotModel[];

    roomColors!: string[];

    showPast = false;

    constructor(
        private translocoService: TranslocoService,
        private activatedRoute: ActivatedRoute,
        private tournamentService: TournamentService,
        public displayService: TournamentDisplayService
    ) {
    }
    ngOnInit() {
        this.roomColors = this.displayService.getRoomColors();
        this.activatedRoute.params.subscribe((params: Params) => {
            this.tournamentPlanId = params['tournamentPlanId'];
            this.loadData();
        });
    }

    formatValues(model: TournamentDisplayAvailableSlotsModel): TournamentDisplayAvailableSlotsModel {

        model.rooms = model.rooms.sort((a, b) => {
            var aName = this.displayService.getLocationName(a.facilityName, a.roomName, 0, false);
            var bName = this.displayService.getLocationName(b.facilityName, b.roomName, 0, false);
            return aName.localeCompare(bName);
        })

        model.rooms.forEach((room, index) => {
            var colorIndex = index;

            while (colorIndex >= 10) {
                colorIndex -= 10;
            }

            room.color = this.roomColors[colorIndex];
            room.isVisible = true;
        });

        model.slots.forEach((slot, index) => {
            var colorIndex = index;
            var room = model.rooms.find(x => x.roomId == slot.roomId);
            while (colorIndex >= 10) {
                colorIndex -= 10;
            }
            slot.color = room?.color ?? "";
        });

        model.slots = model.slots.sort((a, b) => {
            var aTime = new Date(a.from).getTime();
            var bTime = new Date(b.from).getTime();

            var aName = `${aTime}${this.displayService.getLocationName(a.facilityName, a.roomName, a.fieldId, false)}`;
            var bName = `${bTime}${this.displayService.getLocationName(b.facilityName, b.roomName, b.fieldId, false)}`;
            return aName.localeCompare(bName);
        });

        return model;
    }

    filterSlots(availableSlotsModel: TournamentDisplayAvailableSlotsModel): TournamentDisplayAvailableSlotModel[] {

        var filteredSlots: TournamentDisplayAvailableSlotModel[] = [];
        var currentDate = new Date();

        availableSlotsModel.slots.forEach(x => {
            var room = availableSlotsModel.rooms.find(r => r.roomId == x.roomId);
            if (room?.isVisible && (this.showPast || new Date(x.to) > currentDate)) filteredSlots.push(x);
        });

        return filteredSlots;
    }

    loadData() {
        this.tournamentService.getAvailableTournamentSlots(this.tournamentPlanId).subscribe(x => {
            this.availableSlotsModel = this.formatValues(x);
            this.filteredSlots = this.filterSlots(this.availableSlotsModel);
        });
    }

    toggleRoomVisbibility(roomId: number) {
        var room = this.availableSlotsModel.rooms.find(x => x.roomId == roomId);
        if (room == undefined) return;
        room.isVisible = !room.isVisible;

        this.filteredSlots = this.filterSlots(this.availableSlotsModel);
    }

    filterGames() {
        this.filteredSlots = this.filterSlots(this.availableSlotsModel);
    }

}


<ng-container *ngIf="!isLoading">
    <div class="card">
        <div class="card-header">
            {{document.fileName}}
            <button (click)="downloadFile()" class="btn btn-default btn-sm float-end"><i class="fa fa-download"></i></button>
        </div>
        <div class="card-body">
            <object [data]="base64Data" *ngIf="mimeType === 'application/pdf' && base64Data" class="iframe" [type]="mimeType" [ngClass]="{'bigger': mimeType === 'application/pdf' }">
                <div class="alert alert-primary">
                    {{'Office.Msg.NoPreviewPossible' | transloco}}
                </div>
                <button class="btn btn-default btn-sm" (click)="downloadFile()" style="margin-left:0.5em;"><i class="fa fa-download"></i></button>
                <button (click)="downloadFile()" class="btn btn-link">{{document.fileName}}</button>
            </object>
            <img [src]="base64Data" *ngIf="type === 'image'" style="max-width:100%;"/>

            <div *ngIf="type !== 'image' && mimeType !== 'application/pdf'">
                <button class="btn btn-default btn-sm" (click)="downloadFile()" style="margin-left:0.5em;"><i class="fa fa-download"></i></button>
                <button (click)="downloadFile()" class="btn btn-link">{{document.fileName}}</button>
            </div>
        </div>
    </div>
</ng-container>

<app-spinner *ngIf="isLoading" [margin]="'0 auto'"></app-spinner>

<ng-container *ngIf='maintenances'>
  <div>
    <div class="sidebar"  pmtAutoWindowHeight [marginTop]="65">
      <app-maintenance-list [maintenances]="maintenances"></app-maintenance-list>

    </div><!-- /sidebar -->

    <div class="sidebar-content" pmtAutoWindowHeight [marginTop]="65" style="padding: 1rem;">
      <router-outlet></router-outlet>
    </div><!-- /content -->
  </div>
</ng-container>
<ng-container *ngIf='!maintenances'>
  <app-spinner></app-spinner>
</ng-container>
import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Maintenance} from './maintenance.model';
import {environment} from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { HttpResponseErrorHandlerService } from 'src/app/shared/services/http-response-error-handler.service';

@Injectable()
export class MaintenanceService {
  public static maintenanceChangedEvent: EventEmitter<Maintenance> = new EventEmitter<Maintenance>();

  constructor(private httpService: HttpClient,
    private httpResponseErrorHandler: HttpResponseErrorHandlerService) {
  }

  getAllFuture(): Observable<Array<Maintenance>> {
    return this.httpService.get<Maintenance[]>(`${environment.api}/MaintenanceInfos`)
    .pipe(
      catchError(e => this.httpResponseErrorHandler.HandleResponseError(e))
    );
  }

  getMaintenanceById(id: string): Observable<Maintenance> {
    return this.httpService.get<Maintenance>(`${environment.api}/MaintenanceInfos/${id}`)
    .pipe(
      catchError(e => this.httpResponseErrorHandler.HandleResponseError(e))
    );
  }

  update(maintenance: Maintenance): Observable<Maintenance> {
    return this.httpService.put(`${environment.api}/MaintenanceInfos/${maintenance.id}`, maintenance)
    .pipe(
      map((facility: any): Maintenance => maintenance),
      catchError(e => this.httpResponseErrorHandler.HandleResponseError(e))
    );
  }

  create(maintenance: Maintenance): Observable<Maintenance> {
    return this.httpService.post<Maintenance>(`${environment.api}/MaintenanceInfos`, maintenance)
    .pipe(
      catchError(e => this.httpResponseErrorHandler.HandleResponseError(e))
    );
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '@premotec/ngx-essentials';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { Account } from '../account.model';
import { AccountService } from '../account.service';

@Component({
  selector: 'app-account-main-layout',
  templateUrl: './account-main-layout.component.html',
  styleUrls: ['./account-main-layout.component.scss']
})
export class AccountMainLayoutComponent extends BaseComponent implements OnInit {
  tabId = 0;

  account?: Account;

  constructor(
    private route: ActivatedRoute,
    private accountService: AccountService
  ) {
    super();
  }

  ngOnInit() {
    this.tabId = +this.route.snapshot.data['tab'];
    console.log('tabId:', this.tabId)

    this.whileImAlive(
      this.accountService.getAccountDetails()
    ).subscribe((data) => {
       this.account = data;
    });
  }

}

export enum MemebershipApplicationStatus {
    new = '9990e797-32be-4cfa-ad94-a1994ecf560a',
    verificationInProcess = '4c265215-e38f-4e7f-a2f8-e4142e2a4ca4',
    accepted = 'e475998e-b486-480f-8965-6f8cf4b8722e',
    revisionRequested = 'fb68413a-2c9d-4ea7-97b6-c76de2e16bce',
    declined = 'acdfb19c-a78b-41a2-bc0f-bf2c478bc6f1',
    completed = '69829ae1-196b-418c-828b-586278596671',
    removed = '209fb220-db72-4722-8f78-946b4821510b',
    emailVerificationInProgress = 'b18e01f5-7653-4584-a24d-1389cf016681'
}

<div class="container-fluid">
  <div class="row">
    <div class="col offset-md-2 col-md-8">
      <h1>{{'MyEnrollments' | transloco}}</h1>
      <div>
        <div style="padding-top: 3rem;" >
          <app-my-enrollments-table *ngIf="myEnrollments"  [enrollments]="myEnrollments" [noEntries]="noEntries"></app-my-enrollments-table>
          <app-spinner *ngIf="!myEnrollments && !noEntries" [margin]="'1rem auto'"></app-spinner>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="row">
    <div class="col-md-12">
        <div class="alert alert-danger mt-3" *ngIf="httpError">{{'GeneralErrorMsg' | transloco}}</div>
        <div class="alert alert-danger mt-3" *ngIf="submitted && (accountChangeDataFormComponent.getForm().invalid)">
            <ul class="m-0">
                <li>{{'ValidationText.FillInAllRequiredFields' | transloco}}</li>
            </ul>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
<app-account-change-data-form #accountChangeDataFromComponent [passPhotoUploadEnabled]="true" [passwordChangeEnabled]="true"></app-account-change-data-form>
        </div>
    </div>
<div class="well well-sm">
    <button class="btn btn-primary" (click)="sendForm()" [disabled]="submitted">{{ 'Save' | transloco}}</button>
</div>


import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { EnrollmentMessageStateService } from '../../services/enrollment-message-state.service';

@Component({
  selector: 'app-enrollment-messages',
  templateUrl: './enrollment-messages.component.html',
  styleUrls: ['./enrollment-messages.component.scss']
})
export class EnrollmentMessagesComponent implements OnInit {

  showEnrollSuccessMsg = false;
  showEnrollErrorMsg = false;
  showWithdrawSuccessMsg = false;
  showWithdrawErrorMsg = false;
  showLotteryErrorMsg = false;
  showValidationError = false;

  validationError: string | null = null;

  private subscriptions = new Subscription();

  constructor(private enrollmentMessageStateService: EnrollmentMessageStateService) {
  }

  ngOnInit(): void {
    this.subscribeToStateChanges();
  }

  private subscribeToStateChanges() {
    this.subscriptions.add(this.enrollmentMessageStateService.showEnrollSuccessMsg$.subscribe(value => {
      this.showEnrollSuccessMsg = value;
    }));
    this.subscriptions.add(this.enrollmentMessageStateService.showEnrollErrorMsg$.subscribe(value => {
      this.showEnrollErrorMsg = value;
    }));
    this.subscriptions.add(this.enrollmentMessageStateService.showWithdrawSuccessMsg$.subscribe(value => {
      this.showWithdrawSuccessMsg = value;
    }));
    this.subscriptions.add(this.enrollmentMessageStateService.showWithdrawErrorMsg$.subscribe(value => {
      this.showWithdrawErrorMsg = value;
    }));
    this.subscriptions.add(this.enrollmentMessageStateService.validationError$.subscribe(value => {
      this.validationError = value;
    }));

    this.subscriptions.add(this.enrollmentMessageStateService.showLotteryErrorMsg$.subscribe(value => {
      this.showLotteryErrorMsg = value;
    }));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}

<ag-grid-angular
        #agGrid
        style="width: 100%; height:600px;"
        class="ag-theme-alpine"
        [rowData]="rowData"
        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        (gridReady)="onGridReady($event)"
        [tooltipShowDelay]="0"
        [enableBrowserTooltips]="true"
        [enableCellTextSelection]=true
        >
</ag-grid-angular>
import { Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-users-overview',
  templateUrl: './users-overview.component.html',
  styleUrls: ['./users-overview.component.scss']
})
export class UsersOverviewComponent implements OnInit {

  constructor(
    private translocoService: TranslocoService,
    private titleService: Title

  ) { }

  ngOnInit() {
    this.translocoService.selectTranslate('Users').subscribe((x: any) => {
      this.titleService.setTitle(x);
    });
  }

}

import { Injectable } from '@angular/core';
import isAfter from 'date-fns/isAfter';
import { addSeconds, differenceInSeconds, isEqual, startOfSecond } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class DateHelperService {

  constructor() { }

  isAfterCurrentDate(date: Date): boolean {
    const currentDate = new Date();
    return isAfter(date, currentDate);
  }

  /**
   * Is the first date after the second one?
   * @param date The first date to compare.
   * @param dateToCompare The second date to compare.
   * @returns True if the first date is after the second one, false otherwise.
   */
  isAfter(date: Date, dateToCompare: Date): boolean {
    return isAfter(date, dateToCompare);
  }

  /** 
   * Are the given dates equal?
   * @param dateLeft The first date to compare.
   * @param dateRight The second date to compare.
   * @returns True if the dates are equal, false otherwise.
   */
  isEqual(dateLeft: Date, dateRight: Date): boolean {
    return isEqual(dateLeft, dateRight);
  }

  differenceInSeconds(laterDate: Date, earlierDate: Date): number {
    return differenceInSeconds(laterDate, earlierDate);
  }

  addSeconds(date: Date, amount: number): Date {
    return addSeconds(date, amount);
  }

  startOfSecond(date: Date | number): Date {
    return startOfSecond(date);
  }
}

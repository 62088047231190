import {Pipe, PipeTransform} from '@angular/core';
import {DecimalPipe} from '@angular/common';

@Pipe({
  name: 'currencyFormat'
})
export class CurrencyFormatPipe implements PipeTransform {

  constructor(private decimalPipe: DecimalPipe) {
  }

  transform(value: any, args?: any): any {
    if (value != null) {
      return this.getFormattedPrice(value);
    } else {
      return this.getFormattedPrice(0);
    }
  }

  private getFormattedPrice(number: number) {
    // TODO: Replace this maybe with locale instead of replacing comma with period
    return 'CHF ' + this.decimalPipe.transform(number, '1.2-2')!.replace(',', '.');
  }
}

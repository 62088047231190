import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MembershipApplicationAdminService } from '../services/membership-application-admin.service';
import { BaseComponent } from '@premotec/ngx-essentials';
import { ApplicationList } from '../models';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';
import { AgGridAngular } from 'ag-grid-angular';
import { GridReadyEvent } from 'ag-grid-community';
import { GridService } from 'src/app/shared/ag-grid/grid.service';
import { DatePipe } from '@angular/common';
import { AgGridDetailButtonComponent } from '../ag-grid-detail-button/ag-grid-detail-button.component';
import { MembershipApplicationProcessService } from '../services/membership-application-process.service';
import { Editor } from '../models/editor.model';
import { forkJoin } from 'rxjs';
@Component({
  selector: 'app-ma-filter-list',
  templateUrl: './ma-filter-list.component.html',
  styleUrls: ['./ma-filter-list.component.scss'],
  providers: [GridService]
})
export class MaFilterListComponent extends BaseComponent implements OnInit {

  @Input() type!: string;

  isLoading = true;
  applications!: ApplicationList[];
  editors: Editor[] = [];

  @ViewChild('agGrid') agGrid!: AgGridAngular;

  rowData: any = [];
  columnDefs: any = [];
  defaultColDef = {
    sortable: true,
    filter: true,
    floatingFilter: true,
    resizable: true,
  }

  constructor(
    private gridService: GridService,
    private translocoService: TranslocoService,
    private membershipApplicationAdminService: MembershipApplicationAdminService,
    private membershipApplicationProcessService: MembershipApplicationProcessService,
    private route: ActivatedRoute,
    private datepipe: DatePipe
  ) {
    super();
  }

  ngOnInit() {

    this.whileImAlive(this.route.data).subscribe(res => {
      if (res['type']) {
        this.type = res['type'];
      }

      if (this.type === 'archived') {
        this.getMemebershipApplicationsByStatus('archived');
      } else if (this.type === 'all') {
        this.getMemebershipApplicationsByStatus('viewable');
      } else {
        return;
      }


      this.initGrid();
    });
  }

  onGridReady(parameters: GridReadyEvent) {
    // this.gridApi = parameters.api;
  }

  initGrid() {
    this.columnDefs = [
      { field: 'firstName', headerName: this.translocoService.translate('FirstName'), minWidth: 150, },
      { field: 'lastName', headerName: this.translocoService.translate('LastName'), minWidth: 150, },
      { field: 'birthdateString', headerName: this.translocoService.translate('Birthdate'), minWidth: 150, },
      { field: 'city', headerName: this.translocoService.translate('City'), minWidth: 150, },
      {
        field: 'actions',
        headerName: '',
        filter: false,
        sortable: false,
        cellRenderer: AgGridDetailButtonComponent,
        cellEditorPopupPosition: 'under',
        tooltipField: "edit",
        minWidth: 75,
        maxWidth: 75,
        pinned: 'left',
        type: 'client'
      },
      {
        field: 'reasonForDeclineOrRevision', 
        headerName: this.translocoService.translate('MembershipApplication.RejectionReason'), 
        minWidth: 150, 
        valueGetter: (params: any) => {
            return params.data.reasonForAcceptance && params.data.reasonForAcceptance.trim() !== ''
                ? params.data.reasonForAcceptance
                : params.data.reasonForDeclineOrRevision;
        }
    }
    ];

    if (this.type == "all") this.columnDefs.push({ field: 'statusString', headerName: this.translocoService.translate('Status'), minWidth: 150, });
    this.columnDefs.push({ field: 'membershipValidFromDateString', headerName: this.translocoService.translate('MembershipValidFromDate'), minWidth: 150, });
    if (this.type == "archived") this.columnDefs.push({ field: 'archiveReasonString', headerName: this.translocoService.translate('Reason'), minWidth: 150, });
  
    const col = [
      { 
        field: 'statusChangeDate', headerName: this.translocoService.translate('MembershipApplication.LastChangeDate'), minWidth: 150,
        valueGetter: (params: any) => this.dateToString(params.data.statusChangeDate)
      },
      {
        field: 'editor', headerName: this.translocoService.translate('MembershipApplication.InProgressBy'), minWidth: 150,
        valueGetter: (params: any) => this.editorFormatter(params),
      },
      {
        field: 'membershipNameDe',
        headerName: this.translocoService.translate('Membership'),
        minWidth: 150,
      },
    ]

    this.columnDefs.push(...col);
  
  }

  getMemebershipApplicationsByStatus(status: string): void {
    forkJoin({
      editors: this.membershipApplicationAdminService.getEditors(),
      membershipApplications: this.membershipApplicationProcessService.getMembershipApplicationsByStatus(status),
    })
    .subscribe(res => {
      this.applications = this.setApplicationListStrings(res.membershipApplications);
      this.editors = res.editors;
      this.gridService.processGrid(this.agGrid, this.applications, this.constructor.name);
      this.isLoading = false;
    });
  }

  private setApplicationListStrings(applicationList: ApplicationList[]): ApplicationList[] {
    applicationList.forEach(x => {
      x.statusString = this.translocoService.translate('MembershipApplication.Status.' + x.statusId);
      x.birthdateString = this.dateToString(x.birthdate);
      x.membershipValidFromDateString = this.dateToString(x.membershipValidFromDate);
      if (x.isArchived) x.archiveReasonString = this.translocoService.translate('MembershipApplication.ArchivedReason.' + x.archiveReason);
    });

    return applicationList;
  }


  private dateToString(date: any): string {
    if (date == undefined || date == null || date == "Invalid Date") return "";
    return this.datepipe.transform(date, 'dd.MM.yyyy')!;
  }

  editorFormatter(params: any): string {
    const editor = this.membershipApplicationAdminService.getEditorModelByAsvzId(this.editors, params.data.editorAsvzId);
    if (editor != null) {
      return `${editor.firstName} ${editor.lastName} (${editor.asvzId})`;
    }
    return '';
  }

}

import {
  Component, EventEmitter, Input, OnInit, Output,
} from '@angular/core';

import { Subject } from 'rxjs';
import {
  CalendarEvent,
  CalendarView,
} from 'angular-calendar';
import { TournamentSlotDisplayRoomModel } from '../models/tournament-slot-display-room.model';
import { User } from '../tournament-planning-slots-calender-day/tournament-planning-slots-calender-day.component';
import { TournamentSlotDisplayDayModel } from '../models/tournament-slot-display-day.model';
import { TournamentSlotDisplayModel } from '../models/tournament-slot-display.model';
import { TranslocoService } from '@ngneat/transloco';
import { DatePipe } from '@angular/common';
import { TournamentSlotRoomOverviewModel } from '../models/tournament-slot-room-overview.model';
import { TournamentDisplayService } from '../services/tournament-display.service';

@Component({
  selector: 'app-tournament-planning-slots-calender',
  templateUrl: './tournament-planning-slots-calender.component.html',
  styleUrls: ['./tournament-planning-slots-calender.component.scss']
})
export class TournamentPlanningSlotsCalenderComponent implements OnInit {
  @Output() confirmed = new EventEmitter<boolean>();

  @Input() viewDate: Date = new Date();
  @Input() rooms!: TournamentSlotDisplayRoomModel[];
  @Input() roomOverviews!: TournamentSlotRoomOverviewModel[];
  @Input() selectedSlotIds!: string[];

  @Input() minHour: number = 8;
  @Input() maxHour: number = 22;

  constructor(
    private translocoService: TranslocoService,
    private tournamentDisplayService: TournamentDisplayService,
    private datePipe: DatePipe
  ) {
  }

  ngOnInit(): void {
    this.calcWith();
    this.initEvents();
  }

  view: CalendarView = CalendarView.Day;

  viewDateIntern?: Date = undefined;

  refresh = new Subject<void>();


  width: number = 100;

  users!: User[];
  events!: CalendarEvent[];

  initEvents() {
    this.events = [];
    this.users = [];

    var events: CalendarEvent[] = [];

    this.rooms.forEach(x => {
      var roomColor = this.roomOverviews.find(r => r.imRoomId == x.imRoomId)?.color ?? "#fdae61"

      const user: User = {
        id: x.imRoomId.toString() + "_" + x.fieldId.toString(),
        name: `${x.imFacilityName} | ${x.imRoomName} | ${this.tournamentDisplayService.getFieldName(x.fieldId)}`,
        color: {
          primary: roomColor,
          secondary: '#FFFFFF',
        },
        fieldId: x.fieldId
      };

      this.users.push(user);

      x.slots.forEach(s => {
        const roomId = x.imRoomId.toString() + "_" + x.fieldId.toString();
        const index = this.users.findIndex(u => u.id === roomId);

        var dateRange = `${this.datePipe.transform(new Date(s.from), "HH:mm")} - ${this.datePipe.transform(new Date(s.to), "HH:mm")}`;

        const newEvent = {} as CalendarEvent;
        newEvent.start = new Date(s.from);
        newEvent.end = new Date(s.to);
        newEvent.title = '';
        newEvent.meta = {
          user: this.users[index],
          slotId: s.id,
          roomId: roomId,
          isSelected: false,
          isOccupied: s.isOccupied,
          fromTo: dateRange,
        }
        events.push(newEvent);
      });
    });

    this.users = this.users.sort((a, b) => a.name.localeCompare(b.name))

    this.events = events;
    this.viewDateIntern = new Date(this.viewDate)
  }

  calcWith() {
    var basicWith = 70;
    this.width = basicWith + (this.rooms.length * 30);
  }
}
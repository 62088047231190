<h3>{{'MyDetails' | transloco}}</h3>
<div>
  <app-registration-details-view *ngIf="registrationDetails" [registrationDetails]="registrationDetails">
  </app-registration-details-view>

  <app-registration-insurance-info></app-registration-insurance-info>

  <app-discount-form *ngIf="registrationDetails != undefined" [registrationId]="this.registrationSummary.id!"
    [invoiceAmount]="this.registrationSummary!.invoiceAmount!" [registrationDetails]="registrationDetails">
  </app-discount-form>

  <div style="margin-bottom:1rem">
    <strong>{{'RequiredDetailsMsg' | transloco }}</strong>
  </div>
</div>

<div class="alert alert-light">
  <div class="row">
    <div class="col-md-12">
      <div class="float-start">
        <button class="btn btn-default" (click)="back()" [disabled]="backSubmitted">
          <span class="fa fa-chevron-left"></span> {{'Back' | transloco}}
        </button>
      </div>
      <div class="float-end">
        <!-- cancelling enrollment-->
        <app-registration-cancel-modal [registration]="registrationSummary"></app-registration-cancel-modal>
        <!-- when it costs show different button -->
        <button *ngIf="isPaymentRequired" class="btn btn-primary ms-2" (click)="processPayment()"
          [disabled]="submitted">{{'Pay' | transloco}}</button>

        <!-- when its free show different button -->
        <button *ngIf="!isPaymentRequired" class="btn btn-primary ms-2" (click)="processEnrollment()"
          [disabled]="submitted">{{'FinishEnrollment' | transloco}}</button>
      </div>
    </div>
  </div>
</div>
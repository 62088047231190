import { Component, OnInit } from '@angular/core';
import { mergeMap } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { MembershipApplicationsService } from 'src/app/membership-application/membership-applications.service';

@Component({
  selector: 'app-iframe-modal',
  templateUrl: './iframe-modal.component.html',
  styleUrls: ['./iframe-modal.component.scss']
})
export class IframeModalComponent implements OnInit {

  title = '';
  source = '';
  applicationId: any;
  documentId: any;
  base64Data: any;
  fileType: any;
  isLoading!: boolean;
  type: any;

  constructor(public bsModalRef: BsModalRef, private applicationService: MembershipApplicationsService, private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.getFile();
  }

  closeModal() {
    this.bsModalRef.hide();
  }

  getFile() {
    this.isLoading = true;
    this.applicationService.getConfigFileDownloadId(this.applicationId, this.documentId)
      .pipe(
        mergeMap(res => this.applicationService.downloadFileAsBlob(res.downloadId))
      )
      .subscribe((blob: any) => {
        const reader = new FileReader();
        this.fileType = blob.type;
        this.type = blob.type.split('/')[0];

        reader.readAsDataURL(blob);
        reader.onloadend = x => {
          const base64Data = reader.result;
          this.base64Data = this.sanitizer.bypassSecurityTrustResourceUrl(base64Data?.toString()!);
          this.isLoading = false;
        }
        
      }
      );
  }

}

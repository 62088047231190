<div class="container" style="min-height: 500px;" *ngIf="application">
  <h1>Antrag: {{application.onlineMembership.nameDe}}</h1>

  <ng-container *ngIf="application.isArchived">
    <div class="alert alert-primary">
      <span [innerHTML]="'MembershipApplication.Msg.ApplicationArchived' | transloco"></span>
    </div>
  </ng-container>

  <ng-container *ngIf="!application.isArchived">
    <div class="alert alert-primary" *ngIf="application.statusId === applicationStatus.verificationInProcess">
      <span *ngIf="!application.showDocumentsInPerson">{{'MembershipApplication.Msg.NewApplication' | transloco}}</span>
      <span *ngIf="application.showDocumentsInPerson"
        [innerHTML]="'MembershipApplication.Msg.NewApplicationShowDocumentsInPerson' | transloco"></span>
    </div>

    <div class="alert alert-primary" *ngIf="application.statusId === applicationStatus.emailVerificationInProgress">
      <span *ngIf="!application.showDocumentsInPerson">{{'MembershipApplication.Msg.NewApplicationValidateEmail' |
        transloco}}</span>
      <span *ngIf="application.showDocumentsInPerson"
        [innerHTML]="'MembershipApplication.Msg.NewApplicationValidateEmailShowDocumentsInPerson' | transloco"></span>
    </div>

    <ng-container *ngIf="currentStep !== 3">
      <div class="alert alert-primary"
        *ngIf="application.reasonForDeclineOrRevision && application.statusId === applicationStatus.revisionRequested">
        {{application.reasonForDeclineOrRevision}}
      </div>

      <div class="alert alert-primary"
      *ngIf="application.commentFromEditor && application.statusId === applicationStatus.accepted">
      {{application.commentFromEditor}}
    </div>
    </ng-container>

    <app-application-configurations *ngIf="currentStep === 1" (currentStepChanged)="nextStep($event)"
      (applicationChanged)="updateApplication($event)" [application]="application"></app-application-configurations>
    <app-basic-data-form *ngIf="currentStep === 2" (currentStepChanged)="nextStep($event)"
      (applicationChanged)="updateApplication($event)" [application]="application"></app-basic-data-form>

  </ng-container>


</div>

<app-spinner *ngIf="!application" margin="10px auto"></app-spinner>
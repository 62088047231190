import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { BaseComponent } from '@premotec/ngx-essentials';
import { base64ToFile, ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { AccountService } from 'src/app/account/account.service';
import { MembershipService } from 'src/app/memberships/membership.service';
import { MembershipExtension } from 'src/app/memberships/models';

@Component({
  selector: 'app-account-pass-photo-uploader',
  templateUrl: './account-pass-photo-uploader.component.html',
  styleUrls: ['./account-pass-photo-uploader.component.scss']
})
export class AccountPassPhotoUploaderComponent extends BaseComponent implements OnInit {

  @ViewChild(ImageCropperComponent) imageCropper!: ImageCropperComponent;

  @Output() passPhotoChangedEvent = new EventEmitter<string>();

  @Input() membershipExtension!: MembershipExtension;

  isDisabled = false;
  fileLoaded = false;
  fileUploaded = false;

  base64Data : any = null;
  originalFileFormat!: string;
  croppedImage: any = '';
  updatedImage : any;
  originalFileName!: string;
  originalFileType!: string;

  constructor( private accountService: AccountService,
    private membershipService: MembershipService,
    private toastrService: ToastrService,
    private translocoService: TranslocoService) {
    super();
  }

  ngOnInit() {
  }


  onFileChange(event: any) {
    if (event.target.files.length > 0) {
      this.uploadFile(event.target.files[0]);
    }
  }

  uploadFile(data: File) {
    const formData = new FormData();
    formData.append('formFile', data);

    const reader = new FileReader();

    this.originalFileName = data.name;
    this.originalFileType = data.type;

    reader.readAsDataURL(data);
    reader.onloadend = (f) => {
      this.base64Data = reader.result;
      this.updatedImage = data;
      this.fileLoaded = true;
      this.fileUploaded = false;
    };
  }

  deleteFile() {

  }

  saveChanges() {
    const formData = this.setFormData();

    // Check if we are in the user-data or membership extension
    if (this.membershipExtension !== undefined && this.membershipExtension !== null) {
      this.membershipService.uploadMembershipExtensionPicture(formData, this.membershipExtension.personMembershipId).subscribe(res => {
        this.fileUploaded = true;
        this.passPhotoChangedEvent.emit();
        this.reset();
      },
      (error : any) => {
        this.toastrService.error(this.translocoService.translate('Msg.SaveError'));
        this.reset();
      });
    } else {
      this.accountService.uploadPicture(formData).subscribe(res => {
        this.fileUploaded = true;
        this.passPhotoChangedEvent.emit();
        this.reset();
      },
      (error  : any) => {
        this.toastrService.error(this.translocoService.translate('Msg.SaveError'));
        this.reset();
      });
    }
  }

  setFormData(): FormData {
    this.isDisabled = true;
    let blob = this.updatedImage;
    blob = new Blob([blob], {type: this.originalFileType}); // pass the mime type from the original
    blob['lastModified'] = Date.now();
    blob['name'] = this.originalFileName; // use the original file name
    const formData = new FormData();
    formData.append('formFile', blob, this.originalFileName);

    return formData;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.updatedImage = base64ToFile(this.croppedImage);
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  reset() {
    this.base64Data = null;
    this.fileLoaded = false;
    this.isDisabled = false;
  }
}

<ng-template #refundDetailModal>
    <div class="modal-header">
        <h4 class="modal-title float-start">
            <span>Rückerstattungsinformationen</span>
        </h4>
    </div>
    <div class="modal-body">
        <table class="table table-striped table-responsive table-bordered">
            <tbody>
                <tr>
                    <td>{{'BillingID' | transloco}}</td>
                    <td>{{refund.paymentId}}</td>
                </tr>
                <tr>
                    <td>{{'BillingNumberASVZ' | transloco}}</td>
                    <td>{{refund.invoiceId}}</td>
                </tr>
                <tr>
                    <td>{{'LastName' | transloco}}</td>
                    <td>{{refund.lastname}}</td>
                </tr>
                <tr>
                    <td>{{'FirstName' | transloco}}</td>
                    <td>{{refund.firstname}}</td>
                </tr>
                <tr>
                    <td>{{'State' | transloco}}</td>
                    <td *ngIf="isProcessing">{{refund.pspPaymentRefund.status | displayRefundRequestStatus}}</td>
                    <td *ngIf="!isProcessing">{{refund.imStatus | displayRefundStatus}}</td>
                </tr>
                <tr>
                    <td>{{'ASVZID' | transloco}}</td>
                    <td>{{refund.asvzId}}</td>
                </tr>
                <tr>
                    <td>{{'PaydRefund' | transloco}}</td>
                    <td>{{refund.paidAtRegistration  | currency:'CHF' }}</td>
                </tr>
                <tr>
                    <td>{{'RefundValue' | transloco}}</td>
                    <td>{{refund.amountRefund  | currency:'CHF' }}</td>
                </tr>
                <tr>
                    <td>{{'CancelationReason' | transloco}}</td>
                    <td>{{refund.cancellationReason}}</td>
                </tr>
                <tr>
                    <td>{{'CancelationBy' | transloco}}</td>
                    <td>{{refund.cancellationDate | date}}</td>
                </tr>
                <tr>
                    <td>{{'CancelationAt' | transloco}}</td>
                    <td>{{refund.canellationOnDate | date}}</td>
                </tr>
                <tr>
                    <td>{{'CancelationFrom' | transloco}}</td>
                    <td>{{refund.cancellationBy}}</td>
                </tr>
                <tr>
                    <td>{{'CancelationRemark' | transloco}}</td>
                    <td>{{refund.cancellationRemark}}</td>
                </tr>
                <tr>
                    <td>{{'RefundType' | transloco}}</td>
                    <td>{{refund.refundType}}</td>
                </tr>
                <tr>
                    <td>{{'RefundRule' | transloco}}</td>
                    <td>{{refund.refundRule}}</td>
                </tr>
                <tr>
                    <td>{{'RefundRemark' | transloco}}</td>
                    <td>{{refund.refundRemark}}</td>
                </tr>
                <tr>
                    <td>{{'RefundInformation' | transloco}}</td>
                    <td>{{refund.refundInfoText}}</td>
                </tr>
                <tr *ngIf="isProcessing">
                    <td>{{'ProcessDate' | transloco}}</td>
                    <td>{{refund.pspPaymentRefund.requestedDateTime | date}}</td>
                </tr>
            </tbody>
          </table>
    </div>
    <div class="modal-footer">
        <button class="btn btn-danger" *ngIf="refund.isSelected && !isProcessing"  (click)="toggleSelection()">{{'Deselect' | transloco}}</button>
        <button class="btn btn-primary" *ngIf="!refund.isSelected && !isProcessing"  (click)="toggleSelection()">{{'Select' | transloco}}</button>
        <button class="btn btn-default" (click)="close()">{{'Done' | transloco}}</button>
    </div>
</ng-template>
import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DateHandlerService {

  constructor() { }

  /*
    The provided dateTime will come from an API and contains a timezone. This timezone is sometimes incorrect.
    This method takes the current time as UTC and adds the local timezone.

    Especially for birthdays this behaviour is needed.

    Eg. 02.10.1994
    The daylight saving in 1994 was at 25.09.1994 (https://www.schulferien.org/schweiz/zeit/zeitumstellung/1994/)
    The API will respond with '1994-10-02T00:00:00+02:00' but the timezone should be +01:00 as it was summer time.
    The UI handels the timezone correctly according to the year. Consequently, the UI displays '1994-10-01 23:00:00' (1 day earlier).
  */
  takeAsUtcWithLocalTimeZone(dateTime: Date): moment.Moment {
    const dateWithoutTimezone = moment(dateTime).utcOffset(0, true);

    return dateWithoutTimezone;
  }
}

import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { TournamentService } from '../services/tournament.service';
import { TournamentModel } from '../models/tournament.model';
import { TournamentUpdateModel } from '../models/tournament-update.model';
import { TournamentTypeEnum } from '../enums/tournament-type.enum';
import { TournamentAdministratorModel } from '../models/tournament-administrator.model';
import { timer } from 'rxjs';
import { TournamentCaptainMailTextUpdateModel } from '../models/tournament-captain-mail-text-update.model';
import { TournamentCaptainMailTextModel } from '../models/tournament-captain-mail-text.model';

@Component({
    templateUrl: './tournament-captain-mail-text-modal.component.html',
    styleUrls: ['./tournament-captain-mail-text-modal.component.scss'],
})
export class TournamentCaptainMailTextModalComponent implements OnInit {

    @Output() confirmed = new EventEmitter<boolean>();

    tournamentPlanId!: string;

    editorModules: any;

    tournamentCaptainMailTextForm!: UntypedFormGroup;

    formSubmitted!: boolean;

    constructor(
        private fb: FormBuilder,
        private bsModalRef: BsModalRef,
        private tournamentService: TournamentService,
    ) {
    }

    ngOnInit() {
        this.setModules();
        this.loadTournamentCaptainMailText();
    }

    confirm() {
        this.formSubmitted = true;
        if (this.tournamentCaptainMailTextForm.controls['isLinkEnabled'].value) {
            this.tournamentCaptainMailTextForm.controls['linkText'].addValidators(Validators.required);
            this.tournamentCaptainMailTextForm.controls['linkText'].updateValueAndValidity();
        } else {
            this.tournamentCaptainMailTextForm.controls['linkText'].removeValidators(Validators.required);
            this.tournamentCaptainMailTextForm.controls['linkText'].updateValueAndValidity();
        }

        this.tournamentCaptainMailTextForm.markAllAsTouched();
        if (!this.tournamentCaptainMailTextForm.valid) return;

        this.updateTournamentCaptainMailText();
    }

    decline() {
        this.bsModalRef.hide();
    }

    initTournamentForm(model: TournamentCaptainMailTextModel) {
        this.tournamentCaptainMailTextForm = this.fb.group({
            text: [model.text, Validators.required],
            isLinkEnabled: [model.isLinkEnabled ?? false, Validators.required],
            linkText: [model.linkText],
        });
    }

    loadTournamentCaptainMailText() {
        this.tournamentService.getCaptainMailText(this.tournamentPlanId).subscribe(x => {
            this.initTournamentForm(x);
        });
    }

    updateTournamentCaptainMailText() {
        var updateModel = new TournamentCaptainMailTextUpdateModel();
        updateModel.text = this.tournamentCaptainMailTextForm.controls['text'].value;
        updateModel.isLinkEnabled = this.tournamentCaptainMailTextForm.controls['isLinkEnabled'].value;
        updateModel.linkText = null;
        if (updateModel.isLinkEnabled) {
            updateModel.linkText = this.tournamentCaptainMailTextForm.controls['linkText'].value;
        }

        this.tournamentService.updateCaptainMailText(this.tournamentPlanId, updateModel).subscribe(x => {
            this.confirmed.next(true);
            this.bsModalRef.hide();
        });
    }

    setModules() {
        this.editorModules = {
            toolbar: [
                [{ 'header': 1 }, { 'header': 2 }],
                ['bold', 'italic', 'underline', 'strike'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                ['link']
            ]
        };
    }
}

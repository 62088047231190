<div class="modal-header text-light">
    <h3>{{'Tournaments.Events.Game.Edit' | transloco}}</h3>
</div>

<div class="modal-body">

    <form *ngIf="tournamentGameSettingsForm" [formGroup]="tournamentGameSettingsForm">

        <div class="row">
            <div class="col-12 mb-3">
                <label for="setCount" class="form-label">
                    {{'Tournaments.Events.Game.SetCount' | transloco}} *
                </label>
                <div>
                    <input type="number" class="form-control" id="setCount" min="1" formControlName="setCount">
                </div>
            </div>
            <div class="col-12 mb-3">
                <label for="pointsPerWin" class="form-label">
                    {{'Tournaments.Events.Game.PointsPerWin' | transloco}} *
                </label>
                <div>
                    <input type="number" class="form-control" id="pointsPerWin" min="0" formControlName="pointsPerWin">
                </div>
            </div>
            <div class="col-12 mb-3">
                <label for="pointsPerDraw" class="form-label">
                    {{'Tournaments.Events.Game.PointsPerDraw' | transloco}} *
                </label>
                <div>
                    <input type="number" class="form-control" id="pointsPerDraw" min="0" formControlName="pointsPerDraw">
                </div>
            </div>
            <div class="col-12 mb-3">
                <label for="isCalculationPerSet" class="form-label">{{'Tournaments.Events.Game.IsCalculationPerSet' |
                    transloco}}</label>

                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" id="isCalculationPerSet"
                        formControlName="isCalculationPerSet" />
                    <label class="form-check-label fw-normal" for="isCalculationPerSet">
                        {{'Tournaments.Configuration.BasicData.AllowCaptainResultEntry' | transloco}}
                    </label>
                </div>
            </div>
        </div>
    </form>

</div>

<div class="modal-footer  text-light">
    <button type="button" class="btn btn-default" (click)="decline()">{{'Cancel' | transloco}}</button>
    <button type="submit" class="btn btn-success" (click)="confirm()">{{'Save' | transloco}}</button>
</div>
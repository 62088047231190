<div class="modal-header text-light">
    <h3>{{'Tournaments.CaptainMailText.Edit' | transloco}}</h3>
</div>

<div class="modal-body" *ngIf="tournamentCaptainMailTextForm">
    <form [formGroup]="tournamentCaptainMailTextForm">
        <div class="d-flex flex-column gap-2">
            <div>
                <label class="form-label" for="mailText">
                    {{'Tournaments.CaptainMailText.Text' | transloco}}
                </label>
                <quill-editor id="mailText" class="w-100" [modules]="editorModules" placeholder="Text hier eingeben..."
                    spellcheck="false" formControlName="text" autocorrect="off">
                </quill-editor>
            </div>
            <div>
                <label class="form-label">
                    {{'Tournaments.CaptainMailText.SendTeamLink' | transloco}}
                </label>

                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" id="isLinkEnabled"
                        formControlName="isLinkEnabled" />
                    <label class="form-check-label fw-normal" for="isLinkEnabled">
                        <span *ngIf="tournamentCaptainMailTextForm.controls['isLinkEnabled'].value">
                            {{'Tournaments.CaptainMailText.SendTeamLinkEnabled' | transloco}}
                        </span>
                        <span *ngIf="!tournamentCaptainMailTextForm.controls['isLinkEnabled'].value">
                            {{'Tournaments.CaptainMailText.SendTeamLinkDisabled' | transloco}}
                        </span>
                    </label>
                </div>
            </div>
            <div *ngIf="tournamentCaptainMailTextForm.controls['isLinkEnabled'].value">
                <label for="linkText" class="form-label">
                    {{'Tournaments.CaptainMailText.TeamLinkName' | transloco}}
                </label>
                <input type="string" class="form-control" id="linkText" formControlName="linkText" [ngClass]="{'is-invalid': tournamentCaptainMailTextForm.get('linkText')?.errors && formSubmitted}">
                <div id="linkTextFeedback" class="invalid-feedback">
                    {{'ValidationText.Required' | transloco}}
                </div>
            </div>
        </div>
    </form>
</div>

<div class="modal-body" *ngIf="!tournamentCaptainMailTextForm">
    <app-spinner></app-spinner>
</div>

<div class="modal-footer text-light">
    <button type="button" class="btn btn-default" (click)="decline()">{{'Cancel' | transloco}}</button>
    <button type="submit" class="btn btn-success" (click)="confirm()">{{'Save' | transloco}}</button>
</div>
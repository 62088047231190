import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Injectable, Input, LOCALE_ID, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { CalendarUtils, CalendarWeekViewComponent, DateAdapter, getWeekViewPeriod } from 'angular-calendar';

import { CalendarEvent, EventColor, GetWeekViewArgs, WeekView, WeekViewAllDayEvent, WeekViewTimeEvent } from 'calendar-utils';
import { TournamentSlotDisplayDayModel } from '../models/tournament-slot-display-day.model';
import { TournamentSlotDisplayModel } from '../models/tournament-slot-display.model';

export interface User {
    id: string;
    name: string;
    color: EventColor;
    fieldId: number;
}

interface DayViewScheduler extends WeekView {
    users: User[];
}

interface GetWeekViewArgsWithUsers extends GetWeekViewArgs {
    users: User[];
}

@Injectable()
export class DayViewSchedulerCalendarUtils extends CalendarUtils {
    override getWeekView(args: GetWeekViewArgsWithUsers): DayViewScheduler {
        const { period } = super.getWeekView(args);
        const view: DayViewScheduler = {
            period,
            allDayEventRows: [],
            hourColumns: [],
            users: [...args.users],
        };

        const getWeekViewArgs: GetWeekViewArgs = {

            excluded: args.excluded,
            absolutePositionedEvents: args.absolutePositionedEvents,
            dayEnd: args.dayEnd,
            dayStart: args.dayStart,
            events: args.events,
            hourDuration: args.hourDuration,
            hourSegments: args.hourSegments,
            minimumEventHeight: args.minimumEventHeight,
            precision: args.precision,
            segmentHeight: args.segmentHeight,
            viewDate: args.viewDate,
            viewEnd: args.viewEnd,
            viewStart: args.viewStart,
            weekStartsOn: args.weekStartsOn,
            weekendDays: args.weekendDays
        };

        view.users.forEach((user, columnIndex) => {
            const events = args.events!.filter(
                (event) => event.meta.user.id === user.id
            );
            const columnView = super.getWeekView({
                ...getWeekViewArgs,
                events,
            });
            view.hourColumns.push(columnView.hourColumns[0]);
        });

        view.allDayEventRows = [];

        return view;
    }
}

@Component({
    selector: 'mwl-tournament-slot-calender',
    templateUrl: 'tournament-planning-slots-calender-day.component.html',
    styleUrls: ['./tournament-planning-slots-calender-day.component.scss'],
    providers: [DayViewSchedulerCalendarUtils],
})
export class TournamentPlanningSlotsCalenderDayComponent
    extends CalendarWeekViewComponent
    implements OnChanges {

    @Input() selectedSlotIds!: string[];

    @Input() users: User[] = [];
    @ViewChild('try') myRef!: ElementRef;

    @Output() userChanged = new EventEmitter();

    override view!: DayViewScheduler;

    override daysInWeek = 1;

    constructor(
        protected override cdr: ChangeDetectorRef,
        protected override utils: DayViewSchedulerCalendarUtils,
        @Inject(LOCALE_ID) locale: string,
        protected override dateAdapter: DateAdapter,
        protected override element: ElementRef<HTMLElement>
    ) {
        super(cdr, utils, locale, dateAdapter, element);
        this.hourSegmentHeight = 15;
    }

    onClicked(event: CalendarEvent) {
        event.meta.isSelected = !event.meta.isSelected;
        var slotId = event.meta.slotId;

        if (event.meta.isSelected) {
            if (!this.selectedSlotIds.some(x => x == slotId)) this.selectedSlotIds.push(slotId)
        } else {
            const index: number = this.selectedSlotIds.indexOf(slotId);
            if (index !== -1) this.selectedSlotIds.splice(index, 1);
        }
    }

    onRoomClicke(roomId: string) {
        var filterRooms = this.view.hourColumns.filter(c => c.events.some(x => x.event.meta.roomId == roomId));
        if (!filterRooms) return;

        var events = filterRooms[0].events;

        if (events.some(x => !x.event.meta.isSelected)) {
            events.forEach(e => {
                if (e.event.meta.isSelected) return;
                e.event.meta.isSelected = true;
                if (!this.selectedSlotIds.some(x => x == e.event.meta.slotId)) this.selectedSlotIds.push(e.event.meta.slotId)
            });

        } else {
            events.forEach(e => {
                if (!e.event.meta.isSelected) return;
                e.event.meta.isSelected = false;
                const index: number = this.selectedSlotIds.indexOf(e.event.meta.slotId);
                if (index !== -1) this.selectedSlotIds.splice(index, 1);
            });
        }

    }

    trackByUserId = (index: number, row: User) => row.id;

    override ngOnChanges(changes: SimpleChanges): void {
        super.ngOnChanges(changes);

        if (changes['users']) {
            this.refreshBody();
            this.emitBeforeViewRender();
        }
    }

    override getDayColumnWidth(eventRowContainer: HTMLElement): number {
        return Math.floor(eventRowContainer.offsetWidth / this.users.length);
    }

    protected override getWeekView(events: CalendarEvent[]) {
        return this.utils.getWeekView({
            events,
            users: this.users,
            viewDate: this.viewDate,
            weekStartsOn: this.weekStartsOn,
            excluded: this.excludeDays,
            precision: this.precision,
            absolutePositionedEvents: true,
            hourSegments: this.hourSegments,
            dayStart: {
                hour: this.dayStartHour,
                minute: this.dayStartMinute,
            },
            dayEnd: {
                hour: this.dayEndHour,
                minute: this.dayEndMinute,
            },
            segmentHeight: this.hourSegmentHeight,
            weekendDays: this.weekendDays,
            ...getWeekViewPeriod(
                this.dateAdapter,
                this.viewDate,
                this.weekStartsOn,
                this.excludeDays,
                this.daysInWeek
            ),
        });
    }

}

import { TournamentSlotValidationTypeEnum } from "../enums/tournament-slot-validation-type.enum";

export class TournamentSlotValidationModel {
    public slotId?: string;
    public validationType!: TournamentSlotValidationTypeEnum;
    public from!: Date;
    public to!: Date;

    public facilityId!: number;
    public facilityName!: string;
    public roomId!: number;
    public roomName!: string;
    public fieldId!: number;
}
import { Component, OnInit, ViewChild } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { EnrollmentLotteryHeaderModel } from '../../models/lottery-header.model';
import { LotteryService } from '../../services/lottery.service';
import { ActivatedRoute } from '@angular/router';
import { LotteryItemModel } from '../../models/lottery-item.model';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-lottery-items-grid',
  templateUrl: './lottery-items-grid.component.html',
  styleUrls: ['./lottery-items-grid.component.scss'],
  providers: [DatePipe]
})
export class LotteryItemsGridComponent implements OnInit {
  @ViewChild('agGrid') agGrid!: AgGridAngular;
  data: LotteryItemModel[] = [];
  columnDefs: any = [];
  defaultColDef = {
    sortable: true,
    filter: true,
    floatingFilter: true,
    resizable: true,
  }
  
  constructor(
    private lotteryService: LotteryService,
    private activatedRoute: ActivatedRoute,
    private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      const id = params['id'];
      this.lotteryService.getLotteryItems(id).subscribe((data: any) => {
        this.data = data;
        this.initGrid();
      });  
    });  
  }

  initGrid() {
    this.columnDefs = [
      // { headerName: 'ASVZ ID', field: 'asvzId' },
      { headerName: 'Address ID', field: 'addressId', width: 100 },
      // { headerName: 'Lesson ID', field: 'lessonId' },
      { headerName: 'Has Seat', field: 'hasSeat', sortable: true, filter: true,  width: 100  },
      { headerName: 'Status', field: 'status',  width: 100  },
      { headerName: 'Enrollment ID', field: 'enrollmentId',  width: 100  },
      { headerName: 'Enrollment Status', field: 'enrollmentStatus',  width: 100  },
      { headerName: 'Place Number', field: 'placeNumber',  width: 100  },
      { headerName: 'R Number', field: 'rNumber',  width: 100  },
      { headerName: 'Processed', field: 'processed', sortable: true, filter: true, width: 100  },
      { headerName: 'Process Start', field: 'processStart', valueFormatter: (params: any) => this.datePipe.transform(params.value, 'yyyy-MM-dd HH:mm:ss') },
      { headerName: 'Process End', field: 'processEnd', valueFormatter: (params: any) => this.datePipe.transform(params.value, 'yyyy-MM-dd HH:mm:ss') },
      { headerName: 'Duration',  field: 'asvzId', valueGetter: function(params: any) {
        const start = new Date(params.data.processStart);
        const end = new Date(params.data.processEnd);
        return (end.getTime() - start.getTime()) / 1000;
      }},
      { headerName: 'Drawing Error', field: 'drawingError' },
      { headerName: 'Drawing Regelfehler', field: 'drawingRegelfehler' },
    ];
  }

  onGridReady(params: any) {
    // this.agGrid.api.sizeColumnsToFit();
  }

  enrolled() {
    return this.data.filter(item => item.hasSeat).length;
  }

  noSeat() {
    return this.data.filter(item => !item.hasSeat).length;
  }

  error() {
    return this.data.filter(item => item.drawingError).length;
  }




  participants() {
    return this.data.length;
  }
}

import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { MembershipState } from 'src/app/memberships/membership-state.enum';
import { Membership } from 'src/app/memberships/models';
import { Lesson } from '../models/lesson.model';
import { EnrollmentStateProperties } from '../models/enrollment-state-properties.model';

@Injectable()
export class MembershipHelperService {
    constructor() { }


    public userHasValidMembershipForLesson(memberships: Membership[], lessonStartDate: Date): boolean {
        let hasValidMembershipForStartDate = false;
        
        memberships.forEach(x => {
          if (x.status === MembershipState.Completed
            && moment(lessonStartDate).startOf('day').isSameOrBefore(x.validUntil)
            && moment(lessonStartDate).startOf('day').isSameOrAfter(x.validFrom)) {
                hasValidMembershipForStartDate = true;
          }
        });
    
        return hasValidMembershipForStartDate;
      }
  
}
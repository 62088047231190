import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { WebAppInfoService } from '../web-app-info.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-impressum',
  templateUrl: './impressum.component.html',
  styleUrls: ['./impressum.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ImpressumComponent implements OnInit {

  serverName!: string;
  assemblyVersion!: string;

  constructor(private webAppInfoService: WebAppInfoService,
              private titleService: Title) { }

  ngOnInit() {
    this.titleService.setTitle('Impressum');
    this.webAppInfoService.getWebAppInfos().subscribe(info => {
      this.assemblyVersion = info.assemblyVersion.substring(0, info.assemblyVersion.lastIndexOf('.'));
      this.serverName = info.serverName;
    });
  }

}

<div class="card">
  <div class="card-body">
    <app-details-view *ngIf="!(detail.type == controlType.Checkbox && !changeCheckboxToSwitch(detail))"
      [details]="detail.description"><span *ngIf="detail.isRequired" class="field-required">*</span>
    </app-details-view>
    <div [formGroup]="form">
      <ng-container [ngSwitch]="detail.type">

        <div *ngSwitchCase="controlType.Info">
          {{detail.defaultValue}}
        </div>

        <div *ngSwitchCase="controlType.Text" class="form-group"
          [ngClass]="controlHasError(detail.id) ? 'has-error' : ''">
          <label *ngIf="controlHasError(detail.id)" class="control-label text-danger" for="control_{{detail.id}}">
            {{'ValidationText.Required' | transloco}}
          </label>
          <input id="control_{{detail.id}}" formControlName="{{detail.id}}" type="text" class="form-control">
        </div>

        <div *ngSwitchCase="controlType.Date" class="form-group"
          [ngClass]="controlHasError(detail.id) ? 'has-error' : ''">
          <label *ngIf="controlHasError(detail.id)" class="control-label" for="control_{{detail.id}}">
            <span *ngIf="form.get(detail.id.toString())?.errors!['required']">
              {{'ValidationText.Date' | transloco}}
            </span>
            <span *ngIf="form.get(detail.id.toString())?.errors!['invalidDate']">
              {{'ValidationText.Date' | transloco}}
            </span>
          </label>
          <pmt-datepicker id="'control_{{detail.id}}'" formControlName="{{detail.id}}">
          </pmt-datepicker>
        </div>

        <div *ngSwitchCase="controlType.Dropdown" class="form-group"
          [ngClass]="controlHasError(detail.id) ? 'has-error' : ''">
          <label *ngIf="controlHasError(detail.id)" class="control-label text-danger" for="control_{{detail.id}}">
            {{'ValidationText.Dropdown' | transloco}}
          </label>
          <select id="control_{{detail.id}}" formControlName="{{detail.id}}" class="form-control">
            <option value=''></option>
            <option *ngFor="let option of detail.dropdownOptions" value='{{option.option}}'>
              <span *ngIf="option?.price == null || option!.price == 0">{{option.option}}</span>
              <span *ngIf="option?.price != null && option!.price > 0">{{option.option}} (CHF {{option.price | number :
                '1.2-2'}})</span>
            </option>
          </select>
        </div>

        <div *ngSwitchCase="controlType.TriState" class="form-group"
          [ngClass]="controlHasError(detail.id) ? 'has-error' : ''">
          <label *ngIf="controlHasError(detail.id)" class="control-label text-danger" for="control_{{detail.id}}">
            {{'ValidationText.Dropdown' | transloco}}
          </label>
          <app-tri-state id="control_{{detail.id}}" formControlName="{{detail.id}}" [required]="detail.isRequired"
            [yesPrice]="detail.yesPrice" [noPrice]="detail.noPrice" [undefinedPrice]="detail.undefinedPrice">
          </app-tri-state>
        </div>

        <div *ngSwitchCase="controlType.Number" [ngClass]="controlHasError(detail.id) ? 'has-error' : ''">
          <label *ngIf="controlHasError(detail.id)" class="control-label" for="control_{{detail.id}}">
            <span *ngIf="form.get(detail.id.toString())?.errors!['required']" class="text-danger">
              {{'ValidationText.Required' | transloco}}
            </span>
            <span *ngIf="form.get(detail.id.toString())?.errors!['pattern']" class="text-danger">
              {{'ValidationText.Number' | transloco}}
            </span>
          </label>
          <input id="control_{{detail.id}}" formControlName="{{detail.id}}" type="text" class="form-control">
        </div>

        <div *ngSwitchCase="controlType.Checkbox">
          <div *ngIf="!changeCheckboxToSwitch(detail)" class="checkbox" style="margin-top: 0; margin-bottom: 0;">
            <p *ngIf="controlHasError(detail.id)" class="hasError">{{'ValidationText.Checkbox' | transloco}}</p>
            <div class="d-flex">
              <div class="pe-1">
                <app-checkbox formControlName="{{detail.id}}" [Id]="'checkbox_'+detail.id"
                  style="display: inline-block;"></app-checkbox>
              </div>
              <div>
                <label class="fw-normal" for="checkbox_{{detail.id}}">{{detail.description}}</label>
              </div>
            </div>
          </div>

          <div *ngIf="changeCheckboxToSwitch(detail)" class="checkbox" style="margin-top: 0; margin-bottom: 0;">
            <p *ngIf="controlHasError(detail.id)" class="hasError">{{'ValidationText.Checkbox' | transloco}}</p>
            <app-switch formControlName="{{detail.id}}" [yesPrice]="detail.yesPrice" [noPrice]="detail.noPrice">
            </app-switch>
          </div>
        </div>

      </ng-container>
    </div>
  </div>
  <div *ngIf="detail.footer" class="card-footer">{{detail.footer}}</div>
</div>
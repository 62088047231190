import { Component, OnInit } from '@angular/core';
import { InvoiceService } from '../invoice.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { InvoiceType } from '../invoice-type.model';
import { InvoiceTypes } from '../invoice-types.enum';
import { PostFinanceCheckoutTransaction } from '../post-finance-checkout-transaction.model';
import { PostFinanceCheckoutTransactionStatus } from '../post-finance-checkout-transaction-status.enum';
import { PostFinanceCheckoutTransactionFailureCategory } from '../post-finance-checkout-transaction-failure-category.enum';

@Component({
  selector: 'app-checkout-payment-processing',
  templateUrl: './checkout-payment-processing.component.html',
  styleUrls: ['./checkout-payment-processing.component.scss']
})
export class CheckoutPaymentProcessingComponent implements OnInit {

  invoiceType!: InvoiceType;
  invoiceId!: number;

  invoiceRegistrationType: InvoiceTypes = InvoiceTypes.Registration;
  invoiceTypes = InvoiceTypes;
  postFinanceCheckoutTransaction!: PostFinanceCheckoutTransaction;
  isPaymentDenied: Boolean = false;

  postFinanceCheckoutTransactionStatus = PostFinanceCheckoutTransactionStatus;
  postFinanceCheckoutTransactionFailureCategory = PostFinanceCheckoutTransactionFailureCategory;

  constructor(
    private invoiceService: InvoiceService,
    private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.invoiceId = params['invoiceId'];

      if (this.invoiceId) {
        this.loadInvoiceType(this.invoiceId);
        this.getPostFinanceCheckoutTransaction(this.invoiceId);
      }
    });
  }

  private loadInvoiceType(invoiceId: number) {
    this.invoiceService.getInvoiceType(this.invoiceId).subscribe((invoiceType: InvoiceType) => this.invoiceType = invoiceType);
  }

  private getPostFinanceCheckoutTransaction(invoiceId: number) {
    this.invoiceService.getPostFinanceCheckoutTransaction(invoiceId).subscribe((transaction: PostFinanceCheckoutTransaction) => {
      this.postFinanceCheckoutTransaction = transaction;
      this.setPaymentStatus();
    });
  }

  private setPaymentStatus() {
    if (this.postFinanceCheckoutTransaction.status === PostFinanceCheckoutTransactionStatus.DECLINE) {
      this.isPaymentDenied = true;
    }

    if (this.postFinanceCheckoutTransaction.status === PostFinanceCheckoutTransactionStatus.FAILED
      && this.postFinanceCheckoutTransaction.failureCategory !== PostFinanceCheckoutTransactionFailureCategory.END_USER) {
      this.isPaymentDenied = true;
    }

    if (this.postFinanceCheckoutTransaction.status === PostFinanceCheckoutTransactionStatus.FAILED
      && this.postFinanceCheckoutTransaction.failureCategory === PostFinanceCheckoutTransactionFailureCategory.END_USER) {
      this.isPaymentDenied = true;
    }
  }
}


<form [formGroup]="basicDataForm">
  <div class="card">
    <div class="card-header clearfix">
      {{'Office.Applicant' | transloco}}
      <div class="float-end" *ngIf="!application.isArchived">
        <button *ngIf="basicDataForm.disabled" class="btn btn-primary btn-sm" (click)="changeMembership()"
          [disabled]="isSaving">{{'Office.ChangeMembership' | transloco }}</button>
        <button *ngIf="basicDataForm.disabled" class="btn btn-primary btn-sm ms-2" (click)="toggleForm()"
          [disabled]="isSaving">{{'Edit' | transloco }}</button>
        <button *ngIf="!basicDataForm.disabled" class="btn btn-primary btn-sm ms-2" (click)="submitForm()"
          [disabled]="isSaving">{{'Save' | transloco }}</button>
      </div>
    </div>
    <div class="card-body">

      <div class="row">
        <div class="col-md-3">
          <label class="control-label" for="Salutation">{{'Gender' | transloco}}</label>
          <select class="form-control" formControlName="salutation"
            [ngClass]="{'is-invalid': submitted && basicDataForm.get('salutation')?.errors}">
            <option>Divers</option>
            <option>Frau</option>
            <option>Herr</option>
          </select>
          <span class="invalid-feedback" *ngIf="submitted && basicDataForm.get('salutation')?.errors">
            <span *ngIf="basicDataForm.get('salutation')?.getError('required')">
              {{'ValidationText.Required' | transloco}}
            </span>
            <span *ngIf="basicDataForm.get('salutation')?.getError('maxlength')">
              {{'ValidationText.MaxLength' | transloco: {maxLength: 250} }}
            </span>
          </span>
        </div>
        <div class="col-md-3">
          <label class="control-label" for="Firstname">{{'FirstName' | transloco}}</label>
          <input formControlName="firstName" class="form-control" id="Firstname" name="firstName" type="text"
            [ngClass]="{'is-invalid': submitted && basicDataForm.get('firstName')?.errors}">
          <span class="invalid-feedback" *ngIf="submitted && basicDataForm.get('firstName')?.errors">
            <span *ngIf="basicDataForm.get('firstName')?.getError('required')">
              {{'ValidationText.Required' | transloco}}
            </span>
            <span *ngIf="basicDataForm.get('firstName')?.getError('maxlength')">
              {{'ValidationText.MaxLength' | transloco: {maxLength: 250} }}
            </span>
          </span>
        </div>
        <div class="col-md-3">
          <label class="control-label" for="LastName">{{'LastName' | transloco}}</label>
          <input formControlName="lastName" class="form-control" id="LastName" name="lastName" type="text"
            [ngClass]="{'is-invalid': submitted && basicDataForm.get('lastName')?.errors}">
          <span class="invalid-feedback" *ngIf="submitted && basicDataForm.get('lastName')?.errors">
            <span *ngIf="basicDataForm.get('lastName')?.getError('required')">
              {{'ValidationText.Required' | transloco}}
            </span>
            <span *ngIf="basicDataForm.get('lastName')?.getError('maxlength')">
              {{'ValidationText.MaxLength' | transloco: {maxLength: 250} }}
            </span>
          </span>
        </div>
        <div class="col-md-3">
          <label class="control-label" for="DateOfBirth">{{'BirthDate' | transloco}}</label>
          <pmt-datepicker [id]="'DateOfBirth'" name="DateOfBirth" formControlName="dateOfBirth"
            [ngClass]="{'is-invalid': submitted && basicDataForm.get('dateOfBirth')?.errors}"></pmt-datepicker>
          <span class="invalid-feedback" *ngIf="submitted && basicDataForm.get('dateOfBirth')?.errors">
            <span *ngIf="basicDataForm.get('dateOfBirth')?.getError('required')">
              {{'ValidationText.Required' | transloco}}
            </span>
            <span *ngIf="basicDataForm.get('dateOfBirth')?.hasError('maxlength')">
              {{'ValidationText.MaxLength' | transloco: {maxLength: 250} }}
            </span>
            <span *ngIf="basicDataForm.get('dateOfBirth')?.hasError('wrongDate')">
              {{'ValidationText.AgeMustBeOlderThan14' | transloco }}
            </span>
          </span>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-3">
          <label class="control-label" for="Address">{{'StreetNr' | transloco}}</label>
          <input formControlName="address" class="form-control" id="Address" name="address" type="text"
            [ngClass]="{'is-invalid': submitted && basicDataForm.get('address')?.errors}">
          <span class="invalid-feedback" *ngIf="submitted && basicDataForm.get('address')?.errors">
            <span *ngIf="basicDataForm.get('address')?.getError('required')">
              {{'ValidationText.Required' | transloco}}
            </span>
            <span *ngIf="basicDataForm.get('address')?.getError('maxlength')">
              {{'ValidationText.MaxLength' | transloco: {maxLength: 250} }}
            </span>
          </span>
        </div>
        <div class="col-md-3" *ngIf="applicant.additionalAddress">
          <label class="control-label" for="AdditionalAddress">{{'AdditionalAddress' | transloco}}</label>
          <input formControlName="additionalAddress" class="form-control" id="AdditionalAddress"
            name="additionalAddress" type="text"
            [ngClass]="{'is-invalid': submitted && basicDataForm.get('additionalAddress')?.errors}">
          <span class="invalid-feedback" *ngIf="submitted && basicDataForm.get('additionalAddress')?.errors">
            <span *ngIf="basicDataForm.get('additionalAddress')?.getError('maxlength')">
              {{'ValidationText.MaxLength' | transloco: {maxLength: 250} }}
            </span>
          </span>
        </div>
        <div class="col-md-3">
          <label class="control-label" for="PostCode">{{'Postcode' | transloco}}</label>
          <input formControlName="postCode" class="form-control" id="PostCode" name="postCode" type="text"
            [ngClass]="{'is-invalid': submitted && basicDataForm.get('postCode')?.errors}">
          <span class="invalid-feedback" *ngIf="submitted && basicDataForm.get('postCode')?.errors">
            <span *ngIf="basicDataForm.get('postCode')?.getError('required')">
              {{'ValidationText.Required' | transloco}}
            </span>
            <span *ngIf="basicDataForm.get('postCode')?.getError('maxlength')">
              {{'ValidationText.MaxLength' | transloco: {maxLength: 10} }}
            </span>
          </span>
        </div>
        <div class="col-md-3">
          <label class="control-label" for="City">{{'City' | transloco}}</label>
          <input formControlName="city" class="form-control" id="City" name="city" type="text"
            [ngClass]="{'is-invalid': submitted && basicDataForm.get('city')?.errors}">
          <span class="invalid-feedback" *ngIf="submitted && basicDataForm.get('city')?.errors">
            <span *ngIf="basicDataForm.get('city')?.getError('required')">
              {{'ValidationText.Required' | transloco}}
            </span>
            <span *ngIf="basicDataForm.get('city')?.getError('maxlength')">
              {{'ValidationText.MaxLength' | transloco: {maxLength: 50} }}
            </span>
          </span>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-3">
          <label class="control-label" style="margin-right: 0.25em;"
            for="Email">{{application.onlineMembership.emailLabel}}</label>
          <input [checked]="applicant.emailConfirmed" class="check-box" disabled="disabled" type="checkbox"
            [title]="'Verified' | transloco">
          <button *ngIf="!applicant.emailConfirmed" class="btn btn-default btn-sm" (click)="verificationShowModal()">
            {{'Verify' | transloco}}
          </button>

          <div>
            <input formControlName="email" class="form-control" id="Email" name="Email" type="email"
              [ngClass]="{'is-invalid': submitted && basicDataForm.get('email')?.errors}">
            <span class="help-block" *ngIf="application?.onlineMembership?.emailDescription">
              {{application.onlineMembership.emailDescription}}
            </span>
            <span class="invalid-feedback">
              <span *ngIf="basicDataForm.get('email')?.getError('required')">
                {{'ValidationText.Email.Required' | transloco}}
              </span>
              <span *ngIf="basicDataForm.get('email')?.hasError('maxlength')">
                {{'ValidationText.MaxLength' | transloco: {maxLength: 250} }}
              </span>
              <span
                *ngIf="!basicDataForm.get('email')?.getError('required') && basicDataForm.get('email')?.getError('email')">
                {{'ValidationText.Email.Valid' | transloco}}
              </span>
            </span>
          </div>
        </div>

        <div class="col-md-9">
          <div class="row" formGroupName="phoneGroup"
            [ngClass]="{'is-invalid': submitted && basicDataForm.get('phoneGroup')?.errors}">

            <div class="col-md-4">
              <label class="control-label" for="PrivatePhone">{{'PrivatePhone' | transloco}}</label>
              <input formControlName="privatePhone" class="form-control" id="PrivatePhone" name="privatePhone"
                type="text"
                [ngClass]="{'is-invalid': submitted && (basicDataForm.get('phoneGroup.privatePhone')?.errors || basicDataForm.get('phoneGroup')?.errors)}">

              <span class="invalid-feedback"
                *ngIf="basicDataForm.get('phoneGroup.privatePhone')?.getError('maxlength')">
                {{'ValidationText.MaxLength' | transloco: {maxLength: 50} }}
              </span>
            </div>

            <div class="col-md-4">
              <label class="control-label" for="BusinessPhone">{{'BusinessPhone' | transloco}}</label>
              <input formControlName="businessPhone" class="form-control" id="BusinessPhone" name="businessPhone"
                type="text"
                [ngClass]="{'is-invalid': submitted && (basicDataForm.get('phoneGroup.businessPhone')?.errors || basicDataForm.get('phoneGroup')?.errors)}">
              <span class="invalid-feedback" *ngIf="submitted && basicDataForm.get('phoneGroup.businessPhone')?.errors">
                <span *ngIf="basicDataForm.get('phoneGroup.businessPhone')?.getError('maxlength')">
                  {{'ValidationText.MaxLength' | transloco: {maxLength: 50} }}
                </span>
              </span>
            </div>

            <div class="col-md-4">
              <label class="control-label" for="MobilePhone">{{'MobilePhone' | transloco}}</label>
              <input formControlName="mobilePhone" class="form-control" id="MobilePhone" name="mobilePhone" type="text"
                [ngClass]="{'is-invalid': submitted && (basicDataForm.get('phoneGroup.mobilePhone')?.errors || basicDataForm.get('phoneGroup')?.errors)}">
              <span class="help-block" *ngIf="submitted && basicDataForm.get('phoneGroup.mobilePhone')?.errors">
                <span *ngIf="basicDataForm.get('phoneGroup.mobilePhone')?.getError('maxlength')">
                  {{'ValidationText.MaxLength' | transloco: {maxLength: 50} }}
                </span>
              </span>
            </div>

          </div>

          <span class="invalid-feedback" *ngIf="basicDataForm.get('phoneGroup')?.errors">
            {{'ValidationText.AtLeastOnePhoneNumber' | transloco }}
          </span>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-3">
          <label class="control-label" for="MembershipValidFromDate">{{'MembershipValidFromDate' | transloco}}</label>
          <pmt-datepicker [id]="'MembershipValidFromDate'" name="MembershipValidFromDate"
            formControlName="membershipValidFromDate"
            [ngClass]="{'is-invalid': submitted && basicDataForm.get('membershipValidFromDate')?.errors}"></pmt-datepicker>
          <span class="invalid-feedback">
            <span *ngIf="basicDataForm.get('membershipValidFromDate')?.hasError('required')">
              {{'ValidationText.Required' | transloco}}
            </span>
            <span *ngIf="basicDataForm.get('membershipValidFromDate')?.hasError('wrongDate')">
              {{'ValidationText.DateMustNotBeInPast' | transloco }}
            </span>
          </span>
        </div>
        <div class="col-md-6">
          <label class="control-label" for="Membership"> {{ 'Office.Membership' | transloco }}</label><br>
          <span class="clearfix">{{ application.onlineMembership.nameDe }}</span>
        </div>
        <div class="col-md-3">
          <label class="control-label" for="Membership"> {{ 'Office.Status' | transloco }}</label><br>
          <span class="clearfix">{{ 'MembershipApplication.Status.'+ application.statusId | transloco }}</span>
        </div>
      </div>

      <dl class="mt-3">
        <dt>{{'Message' | transloco}}</dt>
        <dd>{{application.commentFromApplicant}}</dd>
      </dl>

    </div>
  </div>
</form>
import { Component, EventEmitter, Output, TemplateRef, ViewChild, Input } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Refund } from '../../models/refund.model';

@Component({
    selector: 'refund-detail-modal',
    templateUrl: './refund-detail-modal.component.html',
    styleUrls: ['./refund-detail-modal.component.scss'],
})
export class RefundDetailModalComponent {
    refund!: Refund;
    @Input() isProcessing!: boolean;

    modalRef!: BsModalRef;
    @ViewChild('refundDetailModal') refundDetailModal!: TemplateRef<any>;

    constructor(private modalService: BsModalService) { }

    open(refund: Refund) {
        this.refund = refund;
        this.modalRef = this.modalService.show(this.refundDetailModal);
    }

    close() {
        this.modalRef.hide();
    }

    toggleSelection(){
        this.refund.isSelected = !this.refund.isSelected;
    }
}

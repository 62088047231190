import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { OpeningHoursComponent } from './components/opening-hours/opening-hours.component';
import { ScreenComponent } from './components/screen/screen.component';
import { SpecialAssignmentsComponent } from './components/special-assignments/special-assignments.component';
import { SportsSchedulesComponent } from './components/sports-schedules/sports-schedules.component';
import { FreeRoomsComponent } from './components/free-rooms/free-rooms.component';
import { ClosuresComponent } from './components/closures/closures.component';
import { InformationScreenRoutingModule } from './information-screen-routing';
import { InformationScreenService } from './services/information-screen.service';
import { AnnouncementsComponent } from './components/announcements/announcements.component';
import { AnnotationsComponent } from './components/annotations/annotations.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    InformationScreenRoutingModule
  ],
  declarations: [
    OpeningHoursComponent,
    ScreenComponent,
    SpecialAssignmentsComponent,
    SportsSchedulesComponent,
    FreeRoomsComponent,
    ClosuresComponent,
    AnnouncementsComponent,
    AnnotationsComponent
  ],
  providers: [
    InformationScreenService,
  ]
})
export class InformationScreenModule { }
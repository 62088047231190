import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, NgForm, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { TournamentEventGroupSettingsUpdateModel } from '../models/tournament-event-group-settings-update.model';
import { TournamentEventModel } from '../models/tournament-event.model';
import { TournamentService } from '../services/tournament.service';
import { forkJoin } from 'rxjs';
import { TournamentEventDataMappingImFieldModel } from '../models/tournament-event-data-mapping-im-field.model';
import { TournamentEventDataMappingImFieldOptionModel } from '../models/tournament-event-data-mapping-im-field-option.model';
import { ar, da } from 'date-fns/locale';
import { TournamentEventDataMappingModel } from '../models/tournament-event-data-mapping.model';
import { TournamentEventDataMappingSlotModel } from '../models/tournament-event-data-mapping-slot.model';

@Component({
    templateUrl: './tournament-event-data-mapping-modal.component.html',
    styleUrls: ['./tournament-event-data-mapping-modal.component.scss'],
})
export class TournamentEventDataMappingModalComponent implements OnInit {

    @Output() confirmed = new EventEmitter<boolean>();

    tournamentPlanId!: string;
    eventId!: string;

    eventDataMappingForm!: UntypedFormGroup;

    formSubmitted = false;

    generalMappingFields!: TournamentEventDataMappingImFieldModel[];
    dayMappingFields!: TournamentEventDataMappingImFieldModel[];

    mapping!: TournamentEventDataMappingModel;

    get monSlotGroup() { return this.eventDataMappingForm.get("monGroup") as FormGroup; }
    get tueSlotGroup() { return this.eventDataMappingForm.get("tueGroup") as FormGroup; }
    get wedSlotGroup() { return this.eventDataMappingForm.get("wedGroup") as FormGroup; }
    get thuSlotGroup() { return this.eventDataMappingForm.get("thuGroup") as FormGroup; }
    get friSlotGroup() { return this.eventDataMappingForm.get("friGroup") as FormGroup; }
    get satSlotGroup() { return this.eventDataMappingForm.get("satGroup") as FormGroup; }
    get sunSlotGroup() { return this.eventDataMappingForm.get("sunGroup") as FormGroup; }

    constructor(
        private fb: FormBuilder,
        private bsModalRef: BsModalRef,
        private tournamentService: TournamentService,
    ) {
    }

    ngOnInit() {
        this.loadData();
    }

    initEventDataMappingForm(model: TournamentEventDataMappingModel | null) {
        this.eventDataMappingForm = this.fb.group({
            teamName: [model?.teamNameFieldId, Validators.required],
            teamCaptainName: [model?.teamCaptainNameFieldId],
            teamCaptainPhone: [model?.teamCaptainPhoneFieldId],
            addition: [model?.additionFieldId],
            additionName: [model?.additionName ?? ""],
            monGroup: this.fb.group({
                slot: [model?.mondayFieldId],
                slotConfig: this.fb.array([]),
            }),
            tueGroup: this.fb.group({
                slot: [model?.tuesdayFieldId],
                slotConfig: this.fb.array([]),
            }),
            wedGroup: this.fb.group({
                slot: [model?.wednesdayFieldId],
                slotConfig: this.fb.array([]),
            }),
            thuGroup: this.fb.group({
                slot: [model?.thursdayFieldId],
                slotConfig: this.fb.array([]),
            }),
            friGroup: this.fb.group({
                slot: [model?.fridayFieldId],
                slotConfig: this.fb.array([]),
            }),
            satGroup: this.fb.group({
                slot: [model?.saturdayFieldId],
                slotConfig: this.fb.array([]),
            }),
            sunGroup: this.fb.group({
                slot: [model?.sundayFieldId],
                slotConfig: this.fb.array([]),
            }),
        });
    }

    loadData() {
        forkJoin({
            mapping: this.tournamentService.getTournamentEventMappingById(this.tournamentPlanId, this.eventId),
            fields: this.tournamentService.getTournamentEventMappingFieldsById(this.tournamentPlanId, this.eventId)
        }).subscribe(x => {

            this.mapping = x.mapping;

            // ImType 2 = text fields for user input in registration
            this.generalMappingFields = x.fields.filter(x => x.imType == 2).sort((a, b) => a.sort - b.sort);

            // ImType 2 = text dropdown fields for user selection in registration
            this.dayMappingFields = x.fields.filter(x => x.imType == 7).sort((a, b) => a.sort - b.sort);

            this.initEventDataMappingForm(x.mapping);
        })
    }

    mapFormToModel(): TournamentEventDataMappingModel {
        var postModel = new TournamentEventDataMappingModel();
        postModel.teamNameFieldId = this.eventDataMappingForm.controls['teamName'].value;
        postModel.teamCaptainNameFieldId = this.eventDataMappingForm.controls['teamCaptainName'].value;
        postModel.teamCaptainPhoneFieldId = this.eventDataMappingForm.controls['teamCaptainPhone'].value;
        postModel.additionFieldId = this.eventDataMappingForm.controls['addition']?.value;
        postModel.additionName = this.eventDataMappingForm.controls['additionName']?.value;

        postModel.mondayFieldId = this.monSlotGroup.controls['slot'].value;
        postModel.mondaySlots = this.mapDaySlotArrayToModelArray(this.monSlotGroup.controls['slotConfig'] as FormArray);
        postModel.tuesdayFieldId = this.tueSlotGroup.controls['slot'].value;
        postModel.tuesdaySlots = this.mapDaySlotArrayToModelArray(this.tueSlotGroup.controls['slotConfig'] as FormArray);
        postModel.wednesdayFieldId = this.wedSlotGroup.controls['slot'].value;
        postModel.wednesdaySlots = this.mapDaySlotArrayToModelArray(this.wedSlotGroup.controls['slotConfig'] as FormArray);
        postModel.thursdayFieldId = this.thuSlotGroup.controls['slot'].value;
        postModel.thursdaySlots = this.mapDaySlotArrayToModelArray(this.thuSlotGroup.controls['slotConfig'] as FormArray);
        postModel.fridayFieldId = this.friSlotGroup.controls['slot'].value;
        postModel.fridaySlots = this.mapDaySlotArrayToModelArray(this.friSlotGroup.controls['slotConfig'] as FormArray);
        postModel.saturdayFieldId = this.satSlotGroup.controls['slot'].value;
        postModel.saturdaySlots = this.mapDaySlotArrayToModelArray(this.satSlotGroup.controls['slotConfig'] as FormArray);
        postModel.sundayFieldId = this.sunSlotGroup.controls['slot'].value;
        postModel.sundaySlots = this.mapDaySlotArrayToModelArray(this.sunSlotGroup.controls['slotConfig'] as FormArray);
        return postModel;
    }

    mapDaySlotArrayToModelArray(formArray: FormArray): TournamentEventDataMappingSlotModel[] {
        var models: TournamentEventDataMappingSlotModel[] = [];
        formArray.controls.forEach(x => {
            var model = new TournamentEventDataMappingSlotModel();
            model.optionId = x.value['id'];
            model.fromTime = x.value['from'];
            model.toTime = x.value['to'];

            // allow "." but using ":"
            model.fromTime = model.fromTime.replace(".", ":");
            model.toTime = model.toTime.replace(".", ":");

            models.push(model);
        });

        return models;
    }

    saveMapping() {
        var postmodel = this.mapFormToModel();
        this.tournamentService.updateTournamentEventMappingBy(this.tournamentPlanId, this.eventId, postmodel).subscribe(x => {
            this.confirmed.emit(true);
            this.bsModalRef.hide();
        });
    }

    confirm() {
        this.formSubmitted = true;
        if (!this.eventDataMappingForm.valid) return;
        if (this.eventDataMappingForm.controls['addition'].value != null && this.eventDataMappingForm.controls['additionName'].value == "") return;
        this.saveMapping();
    }

    decline() {
        this.bsModalRef.hide();
    }
}

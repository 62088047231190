export class Membership {
    membershipId!: number;
    status!: number;
    description!: string;
    validFrom!: Date;
    validUntil!: Date;
    personMembershipId!: number;
    priceClass!: number;
    isDurationDisplayed!: boolean;
    isHealthInsuranceEligible!: boolean;
}

<ng-container *ngIf="accountForm">
  <form [formGroup]="accountForm">
    <div class="row mb-5" *ngIf="showPictureUpload && passPhotoUploadEnabled">
      <div class="col-md-12">
        <label class="control-label">{{'PassportPhotoUpload' | transloco}}</label>
        <app-account-pass-photo-uploader (passPhotoChangedEvent)="passPhotoChanged()"></app-account-pass-photo-uploader>
      </div>
      <br>
    </div>
    <!-- spacer -->
    <div class="row" *ngIf="showPictureUpload">
      <div class="col-md-12">&nbsp;</div>
    </div>

    <div class="alert-primary alert" role="alert">{{'CheckUserDataMsg' | transloco}}
    </div>
    <div class="row">
      <div class="col-md-2 col-sm-4 col-xs-4">
        <dl>
          <dd>
            <img id="image" *ngIf="currentPicture" src="data:image/JPEG;base64,{{currentPicture}}" class="image"
              style="width: 100%;" />
            <i *ngIf="!currentPicture" style="width: 100%; text-align: left; font-size: 120px;"
              class="glyphicon glyphicon-user"></i>
            <div *ngIf="validatePicture" class="form-group"
              [ngClass]="{'has-error': accountForm.get('picture')?.errors}">
              <span class="help-block" *ngIf="accountForm.get('picture')?.errors">
                <span class="has-error" *ngIf="accountForm.get('picture')?.hasError('missingPicture')">
                  {{'PassportPhotoMandatory' | transloco}}
                </span>
              </span>
            </div>
          </dd>
        </dl>
      </div>
      <div class="col-md-6 col-sm-6 col-xs-6 col offset-md-4 offset-sm-2 col-xs-offset-2">
        <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12" *ngIf="account && account.showAsvzId">
            <dl>
              <dt>ASVZ-ID</dt>
              <dd>{{account.personId}}</dd>
            </dl>
          </div>
          <div class="col-md-12 col-sm-12 col-xs-12">
            <dl>
              <dt>{{'BirthDate' | transloco}}</dt>
              <dd>{{account.birthDate! | dateFormat: "DD.MM.YYYY"}}</dd>
            </dl>
          </div>
        </div>

      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="alert alert-primary">
          <i class="fa fa-info-circle" aria-hidden="true"></i> {{ 'PassPhoto.IntendedUse' | transloco }}
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="row">
          <div class="col-md-6 col-sm-6 col-xs-6">
            <dl>
              <dt>{{'FirstName' | transloco}}</dt>
              <dd>{{account.firstName}}</dd>
            </dl>
          </div>
          <div class="col-md-6 col-sm-6 col-xs-6">
            <dl>
              <dt>{{'LastName' | transloco}}</dt>
              <dd>{{account.lastName}}</dd>
            </dl>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group" [ngClass]="{'has-error': formControlHasError('email')}">
              <label class="control-label" for="Email">{{'Email' | transloco}} {{requiredPropertyIndicator}}</label>
              <!-- input group if the user can edit email-->
              <div class="input-group" *ngIf="account.canChangeEmail">
                <input formControlName="email" class="form-control" id="Email" name="Email" type="email">
                <span class="input-group-btn">
                  <button class="btn btn-primary" (click)="showChangeEmailModal()" style="border-bottom: 0;"><i
                      class="fa fa-edit" style="color: white;"></i></button>
                </span>
              </div>

              <!-- only input, if the user can not edit email. Separated to preserve the width -->
              <input *ngIf="!account.canChangeEmail" formControlName="email" class="form-control" id="Email"
                name="Email" type="email">
              <span class="help-block">
                <ng-container *ngIf="account.emailPrivateNew">
                  {{'UnconfirmedEmail' | transloco}}: {{account.emailPrivateNew}} <br />
                </ng-container>
              </span>

              <span class="help-block" *ngIf="accountForm.get('email')?.errors">
                <span *ngIf="accountForm.get('email')?.errors!['required']">
                  {{'ValidationText.Email.Required' | transloco}}
                </span>
                <span *ngIf="accountForm.get('email')?.hasError('maxlength')">
                  {{'ValidationText.MaxLength' | transloco: {maxLength: 250} }}
                </span>
                <span
                  *ngIf="!accountForm.get('email')?.errors!['required'] && accountForm.get('email')?.errors!['email']">
                  {{'ValidationText.Email.Valid' | transloco}}
                </span>
              </span>
            </div>
          </div>

          <div class="col-md-6" *ngIf="passwordChangeEnabled">
            <label class="control-label" for="password">{{'Password' | transloco}} {{requiredPropertyIndicator}}</label>

            <div class="input-group">
              <input class="form-control" id="password" type="password" value="************" disabled>
              <button class="btn btn-primary" (click)="showChangePasswordModal()" ><i class="fa fa-edit" style="color: white;"></i></button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 mt-3">
        <div class="form-group" [ngClass]="{'has-error': accountForm.get('street1')?.errors}">
          <label class="control-label" for="Street1">{{'StreetNr' | transloco}} {{requiredPropertyIndicator}}</label>
          <input formControlName="street1" class="form-control" id="Street1" name="Street1" type="text">
          <span class="help-block" *ngIf="accountForm.get('street1')?.errors">
            <span *ngIf="accountForm.get('street1')?.errors!['required']" class="text-danger">
              {{'ValidationText.Required' | transloco}}
            </span>
            <span *ngIf="accountForm.get('street1')?.hasError('maxlength')" class="text-danger">
              {{'ValidationText.MaxLength' | transloco: {maxLength: 250} }}
            </span>
          </span>
        </div>
      </div>
      <div class="col-md-6 mt-3">
        <div class="form-group" [ngClass]="{'has-error': accountForm.get('street2')?.errors}">
          <label class="control-label" for="Street2">Adresszusatz</label>
          <input formControlName="street2" class="form-control" id="Street2" name="Street2" type="text">
          <span class="help-block" *ngIf="accountForm.get('street2')?.errors">
            <span *ngIf="accountForm.get('street2')?.hasError('maxlength')">
              {{'ValidationText.MaxLength' | transloco: {maxLength: 250} }}
            </span>
          </span>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4 mt-3">
        <div class="form-group" [ngClass]="{'has-error': accountForm.get('country')?.errors}">
          <label class="control-label" for="Country">Land {{requiredPropertyIndicator}}</label>
          <select formControlName="country" id="Country" name="Country" title="Land" class="form-control">
            <option *ngFor="let country of countries" value="{{country.nameShort}}">{{country.nameLong}}</option>
            <!-- country list -->
          </select>
          <span class="help-block" *ngIf="accountForm.get('country')?.errors" class="text-danger">
            {{ 'ValidationText.Required' | transloco}}
          </span>
        </div>
      </div>
      <div class="col-md-2 mt-3">
        <div class="form-group" [ngClass]="{'has-error': accountForm.get('postcode')?.errors}">
          <label class="control-label" for="Postcode">PLZ {{requiredPropertyIndicator}}</label>
          <input formControlName="postcode" class="form-control" id="Postcode" name="Postcode" type="text">
          <span class="help-block" *ngIf="accountForm.get('postcode')?.errors">
            <span *ngIf="accountForm.get('postcode')?.errors!['required']" class="text-danger">
              {{'ValidationText.Required' | transloco}}
            </span>
            <span *ngIf="accountForm.get('postcode')?.hasError('maxlength')" class="text-danger">
              {{'ValidationText.MaxLength' | transloco: {maxLength: 10} }}
            </span>
          </span>
        </div>
      </div>
      <div class="col-md-6 mt-3">
        <div class="form-group" [ngClass]="{'has-error': accountForm.get('city')?.errors}">
          <label class="control-label" for="City">Ort {{requiredPropertyIndicator}}</label>
          <input formControlName="city" class="form-control" id="City" name="City" type="text">
          <span class="help-block" *ngIf="accountForm.get('city')?.errors">
            <span *ngIf="accountForm.get('city')?.errors!['required']" class="text-danger">
              {{'ValidationText.Required' | transloco}}
            </span>
            <span *ngIf="accountForm.get('city')?.hasError('maxlength')" class="text-danger">
              {{'ValidationText.MaxLength' | transloco: {maxLength: 50} }}
            </span>
          </span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 mt-3">
        <label class="control-label no-margin-bottom">{{ 'Phone' | transloco}} {{requiredPropertyIndicator}} </label>
        <p class="help-block no-margin-top m-0">{{'ValidationText.AtLeastOnePhoneNumber' | transloco }}</p>
      </div>
    </div>
    <div class="row" formGroupName="phoneGroup" [ngClass]="{'has-error': formControlHasError('phoneGroup')}">
      <div class="col-md-4 mt-3">
        <div class="form-group" [ngClass]="{'has-error': formControlHasError('phoneGroup.phonePrivate')}">
          <label class="control-label" for="PhonePrivate">{{ 'Private' | transloco }}</label>
          <input formControlName="phonePrivate" class="form-control" id="PhonePrivate" name="PhonePrivate" type="tel">
          <span class="help-block" *ngIf="accountForm.get('phoneGroup.phonePrivate')?.errors">
            <span *ngIf="accountForm.get('phoneGroup.phonePrivate')?.hasError('maxlength')">
              {{'ValidationText.MaxLength' | transloco: {maxLength: 50} }}
            </span>
          </span>
        </div>
      </div>
      <div class="col-md-4 mt-3">
        <div class="form-group" [ngClass]="{'has-error': formControlHasError('phoneGroup.phoneOffice')}">
          <label class="control-label" for="PhoneOffice">{{ 'Business' | transloco}}</label>
          <input formControlName="phoneOffice" class="form-control" id="PhoneOffice" name="PhoneOffice" type="tel">
          <span class="help-block" *ngIf="accountForm.get('phoneGroup.phoneOffice')?.errors">
            <span *ngIf="accountForm.get('phoneGroup.phoneOffice')?.hasError('maxlength')">
              {{'ValidationText.MaxLength' | transloco: {maxLength: 50} }}
            </span>
          </span>
        </div>
      </div>
      <div class="col-md-4 mt-3">
        <div class="form-group" [ngClass]="{'has-error': formControlHasError('phoneGroup.mobile')}">
          <label class="control-label" for="Mobile">{{ 'Mobile' | transloco }}</label>
          <input formControlName="mobile" class="form-control" id="Mobile" name="Mobile" type="tel">
          <span class="help-block" *ngIf="accountForm.get('phoneGroup.mobile')?.errors">
            <span *ngIf="accountForm.get('phoneGroup.mobile')?.hasError('maxlength')">
              {{'ValidationText.MaxLength' | transloco: {maxLength: 50} }}
            </span>
          </span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        &nbsp;
      </div>
    </div>
  </form>
</ng-container>
<app-spinner *ngIf="!accountForm"></app-spinner>

<ng-template #changeEmailModal>
  <app-change-email-modal [account]="account" (closeModalEvent)="closeChangeModal()"></app-change-email-modal>
</ng-template>
<ng-template #changePasswordModal>
  <app-change-password-modal [account]="account" (closeModalEvent)="closeChangeModal()">
  </app-change-password-modal>
</ng-template>
<div class="information-screen">
  <div class="header">
    <div class="row">
      <div class="col col-sm-6">
        <h1 *ngIf="informationScreen.facility"><img alt="asvz-logo" src="assets/img/logo_blue.png">
          {{informationScreen.facility.name}}
        </h1>
      </div>
      <div class="col col-sm-6 text-end align-bottom">
        <span class="current-time">{{informationScreen.displayDatetime}}</span>
      </div>
    </div>
  </div>

  <div>
    <div class="row">
      <div class="col col-sm-5">
        <div class="screen-component component-fixed-large">
          <app-information-screen-sports-schedules [sportsSchedules]="informationScreen.sportsSchedules">
          </app-information-screen-sports-schedules>
        </div>
      </div>
      <div class="col col-sm-4">
        <div class="screen-component component-fixed-mid">
          <app-information-screen-special-assignments [specialAssignments]="informationScreen.specialAssignments">
          </app-information-screen-special-assignments>
        </div>
        <div class="screen-component component-fixed-mid">
          <app-information-screen-free-rooms [freeRooms]="informationScreen.freeRooms">
          </app-information-screen-free-rooms>
        </div>

      </div>
      <div class="col col-sm-3" style="height:81vh; max-height:81vh; position: relative; overflow: hidden;">
        <div class="screen-component component-flex-shrink">
          <app-information-screen-opening-hours [openingHours]="informationScreen.openingHours">
          </app-information-screen-opening-hours>
        </div>
        <div class="screen-component component-flex-shrink" id="closing-screen-component"
          [ngClass]="{'component-fixed-large': hideAnnotation}">
          <app-information-screen-closures [closings]="informationScreen.closings"></app-information-screen-closures>
        </div>
        <div class="screen-component screen-component-announcements screen-component-bottom-fixed"
          id="announcements-screen-component" *ngIf="informationScreen.announcements.length > 0">
          <app-information-screen-announcements-component [announcements]="informationScreen.announcements">
          </app-information-screen-announcements-component>
        </div>
        <div class="screen-component screen-component-annotation screen-component-bottom-fixed"
          id="annotation-screen-component" *ngIf="informationScreen.announcements.length == 0 && !hideAnnotation">
          <app-information-screen-annotations-component></app-information-screen-annotations-component>
        </div>
      </div>
    </div>
  </div>

  <div class="footer">
    <div class="row">
      <div class="col col-sm-6 text-left">
        <span class="footer-annotation">For brain, body and soul.</span>
      </div>
      <div class="col col-sm-6 text-left p-0 m-0">
        <span class="sponsoring-images">
          <ul class="text-end">
            <li><img alt="asvz-logo" src="assets/img/logos/sponsors/craft-logo.jpg"></li>
            <li><img alt="asvz-logo" src="assets/img/logos/sponsors/zkb-logo.jpg"></li>
          </ul>
        </span>
      </div>
    </div>
  </div>
</div>
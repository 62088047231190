<div class="container">
  <div class="row">
    <div class="col-md-12">
      <h1>{{'Memberships.Title' | transloco}}</h1>

      <app-pass-photo-required-msg *ngIf="personWithMemberships" [account]="personWithMemberships"
        [messageToken]="'PassportPhotoNeededDigitalCheckIn'" [checkProperty]="'hasPrintCard'">
      </app-pass-photo-required-msg>

      <ul class="nav nav-tabs">
        <li class="nav-item nav-pills-asvz" role="presentation"><a routerLinkActive="active" class="nav-link"
            routerLink="/account/user-data">{{'MyUserData' | transloco }}</a></li>
        <li class="nav-item nav-pills-asvz" role="presentation"><a routerLinkActive="active" class="nav-link"
            routerLink="/memberships">{{'Memberships.Title' | transloco }}</a></li>
      </ul>

      <div style="padding-top:1rem;">
        <ng-container *ngIf="myMembershipsDefinite">
          <div class="d-flex align-items-center mb-3 gap-2">
            <div>
              <button class="btn btn-primary" routerLink="/memberships/new"
                [disabled]="hasNonCompleteStatusMemberships">{{'Memberships.New' | transloco }}</button>
            </div>
            <div>
              <span *ngIf="hasNonCompleteStatusMemberships">{{
                'MembershipExtension.MultipleCreateExtendMembershipsNotAllowed' | transloco }}</span>
            </div>
          </div>
          <div *ngIf="myMembershipNonComplete" class="mb-5">
            <h2>{{'PurchaseMembership' | transloco}}</h2>

            <div class="row p">
              <div class="col-sm-6">
                <div class="card">
                  <div class="card-body">
                    <strong>{{ myMembershipNonComplete.description }}</strong> <br />
                    {{ myMembershipNonComplete.validFrom | dateFormat: "DD.MM.YYYY"}} - {{
                    myMembershipNonComplete.validUntil | dateFormat: "DD.MM.YYYY"}} <br />

                    <br />

                    <div>
                      <a class="btn btn-primary text-light"
                        routerLink="/memberships/{{myMembershipNonComplete.personMembershipId}}/wizard">
                        <i class="fa fa-chevron-right text-light"></i>
                        {{ 'StartPaymentProcess' | transloco }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <h2>{{'Definite' | transloco}}</h2>
          <p *ngIf="checkHasActiveStudentMembership()"
            [innerHTML]="'Memberships.StudentSwitchLoginMembershipNotice' | transloco"></p>
          <table class="table table-striped table-responsive table-bordered">
            <thead style="font-weight: bold;">
              <tr>
                <td style="width:24px"></td>
                <td>{{'Labeling' | transloco}}</td>
                <td>{{'ValidFrom' | transloco}}</td>
                <td>{{'ValidTo' | transloco}}</td>
                <td>Status</td>
                <td *ngIf="isAnyMembershipisHealthInsuranceEligible()" style="width: 1px;">{{'Memberships.HealthInsuranceRequest.HealthInsurance' | transloco}}</td>
              </tr>
            </thead>
            <tbody>
              <tr *ngIf="noMemberships">
                <td colspan="5">{{'NoMemberships' | transloco}}</td>
              </tr>
              <tr *ngFor="let entry of myMembershipsDefinite">
                <td>
                  <i *ngIf="entry.status" [tooltip]="entry.statusTooltip" [ngClass]="entry.statusTooltipClass"
                    aria-hidden="true"></i>
                </td>
                <td>{{entry.description}}</td>
                <td><span *ngIf="entry.isDurationDisplayed">{{entry.validFrom | dateFormat: "DD.MM.YYYY"}}</span></td>
                <td><span *ngIf="entry.isDurationDisplayed">{{entry.validUntil | dateFormat: "DD.MM.YYYY"}}</span></td>
                <td>{{ entry.statusInfo }}</td>
                <td *ngIf="isAnyMembershipisHealthInsuranceEligible()" class="text-center">
                  <button class="btn btn-sm btn-primary" *ngIf="entry.isHealthInsuranceEligible"
                    (click)="requestHealthInsuranceSummary(entry.personMembershipId)">
                    {{'Memberships.HealthInsuranceRequest.Request' | transloco}}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>

        </ng-container>
        <app-spinner *ngIf="!myMembershipsDefinite"></app-spinner>
      </div>
    </div>
  </div>
</div>
<div class="modal-header">
    <h4>{{title ?? 'Confirm' | transloco}}</h4>
</div>
<div class="modal-body">
    <form [formGroup]="formGroup">
    <div>
        <label class="form-label">{{confirmMessage}}</label>
        <textarea class="form-control" rows="3" formControlName="message"
        [ngClass]="{'is-invalid': formGroup.get('message')?.invalid && submitted}"
        ></textarea>
    </div>
    </form>
</div>
<div class="modal-footer">
    <button class="btn btn-default" [title]="'Cancel' | transloco" (click)="close()" [disabled]="isSaving">{{'Cancel' |
        transloco}}</button>
    <button class="btn btn-primary" [title]="confirmButtonText" (click)="save()" appLoadingButton [loading]="isSaving">
        {{confirmButtonText}}
    </button>
</div>
<h1>History</h1>
<app-spinner *ngIf="isLoading"></app-spinner>
<table class="table table-striped" *ngIf="!isLoading">
    <thead>
      <tr>
        <th scope="col">{{'Name' | transloco }}</th>
        <th scope="col">{{'BirthDate' | transloco }}</th>
        <th scope="col">{{'HandHeldScanner.CheckType' | transloco }}</th>
        <th scope="col">{{'Time' | transloco }}</th>
        <th scope="col">{{'HandHeldScanner.Result' | transloco }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let checkoutValidationHistory of checkoutValidationHistories" >
        <td (click)="openPersonDetailModal(checkoutValidationHistory)"><span>
          {{checkoutValidationHistory.person?.firstName}} {{checkoutValidationHistory.person?.lastName}}
          </span>
        </td>
        <td (click)="openPersonDetailModal(checkoutValidationHistory)">{{checkoutValidationHistory.person?.dateOfBirth| date: 'yyyy'}}</td>
        <td (click)="openPersonDetailModal(checkoutValidationHistory)">{{'HandHeldScanner.CheckTypeEnum.' + checkoutValidationHistory.checkType | transloco }}</td>
        <td (click)="openPersonDetailModal(checkoutValidationHistory)">{{checkoutValidationHistory.createDate | date: 'HH:mm'}}</td>
        <td class="text-center">
          <div class="btn btn-light btn-sm" [tooltip]="checkoutValidationHistory.message"
            placement="left"
            container="body"
            triggers="click">
              <i *ngIf="checkoutValidationHistory.accessGranted" class="fa-sharp fa-solid fa-circle-check text-success"></i>
              <i *ngIf="!checkoutValidationHistory.accessGranted" class="fa-sharp fa-solid fa-circle-xmark text-danger"></i>
          </div>

        </td>
      </tr>
    </tbody>
  </table>
  <div class="alert alert-info" *ngIf="!checkoutValidationHistories?.length&& !isLoading">
    {{'HandHeldScanner.Msg.NoHistory' | transloco}}
  
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Title } from '@angular/platform-browser';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { TournamentService } from '../services/tournament.service';
import { TournamentModel } from '../models/tournament.model';
import { TournamentTypeEnum } from '../enums/tournament-type.enum';
import { TournamentConfigruationModalComponent } from '../tournament-configuration-modal/tournament-configuration-modal.component';
import { ToastrService } from 'ngx-toastr';

@Component({
    templateUrl: './tournament-details.component.html',
    styleUrls: ['./tournament-details.component.scss'],
})
export class TournamentDetailsComponent implements OnInit {

    tournamentPlanId!: string;
    tournament!: TournamentModel | null;

    TournamentTypeEnum = TournamentTypeEnum;

    constructor(
        private translocoService: TranslocoService,
        private activatedRoute: ActivatedRoute,
        private tournamentService: TournamentService,
        private titleService: Title,
        private modalService: BsModalService) {
    }

    ngOnInit() {
        this.activatedRoute.params.subscribe((params: Params) => {
            if (params['tournamentPlanId']) {
                this.tournament = null;
                this.tournamentPlanId = params['tournamentPlanId'];
                this.loadTournament();
            }
        });

    }

    setTitle(name?: string) {
        var pageTitle = "";

        if (name != null) {
            pageTitle += `${name} | `
        }
        pageTitle += `${this.translocoService.translate('Tournaments.Title')}`;
        pageTitle += ` | ${this.translocoService.translate('Tournaments.Management')}`;

        this.titleService.setTitle(pageTitle);
    }

    loadTournament() {
        this.tournamentService.getTournamentById(this.tournamentPlanId).subscribe(x => {
            this.tournament = x;
            this.setTitle(x.name);
        });

    }

    openBasicDataModal() {
        const initialState: ModalOptions = {
            backdrop: 'static',
            // class: 'modal-lg',
            keyboard: false,
            initialState: {
                tournamentPlanId: this.tournamentPlanId,
            },
        };

        const bsModalRef = this.modalService.show(TournamentConfigruationModalComponent, initialState);
        bsModalRef.content?.confirmed.subscribe(x => {
            this.loadTournament();
        });
    }
}

<div class="p-3">
    <ng-container *ngIf="tournaments">


        <div class="card">
            <div class="card-header">
                <span class="badge bg-secondary ">{{getTournamentsLength()}}</span>
                <span><a class="btn btn-default btn-sm float-end" (click)="newTournament()"><i
                            class="fa fa-plus"></i></a></span>
            </div>

            <div class="card-body" *ngIf="!tournaments.length">
                Es wurden noch keine Turnierplanungen erstellt.
            </div>

            <div class="list-group">
                <span *ngFor="let tournament of tournaments | orderBy: 'imEventId' ">
                    <a class="list-group-item list-group-item-action" *ngIf="tournament.isActive || showInactive"
                        [routerLink]="['/tournaments/admin/'+tournament.id]+'/configuration'"
                        [routerLinkActive]="'list-group-item-info'">
                        {{ tournament.name }}
                        <div class="badge bg-warning float-end ng-binding ng-scope"
                            *ngIf="tournament.isActive === false">
                            {{'Inactive' | transloco}}</div>
                    </a>
                </span>
            </div>
        </div>
    </ng-container>
    <app-spinner *ngIf="!tournaments" [margin]="'100px'"></app-spinner>
</div>
import {Routes} from '@angular/router';
import {EventDetailsComponent} from './event-details/event-details.component';
import {RegistrationMainComponent} from './registration/registration-main/registration-main.component';
import { RegistrationGuardService } from './registration/registration-guard.service';
import { AuthGuard } from '../security/guards';
import { RegistrationProvePermissionComponent } from './registration/registration-prove-permission/registration-prove-permission.component';
import { RegistrationSummaryComponent } from './registration/registration-summary/registration-summary.component';
import { RegistrationCancelPaymentComponent } from './registration/registration-cancel-payment/registration-cancel-payment.component';

export const eventRoutes: Routes = [
  {
    path: ':eventId',
    component: EventDetailsComponent
  },
]


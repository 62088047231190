import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';

/**
 * This pipes takes a start and an end date. If the days are equal, it will only display the date with from time and end time
 * If the dates are not equal, it will display from date + time and end date + time
 * e.x
 * 09.11.2017 13:00 - 11.11.2017 18.00
 * or
 * 009.11.2017 13:00 - 18:00
 */
@Pipe({
  name: 'fromToDate'
})
export class FromToDatePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {
  }

  transform(fromDate: any, toDate: any, format: string, fromDateOnly: boolean): any {
    // moment()
    const isSameDay = moment(fromDate).isSame(toDate, 'day');
    moment.locale('de_DE');

    if (isSameDay) {
      if (fromDateOnly) {
        return `${moment(fromDate).format(format)}`;
      }
      return `${moment(fromDate).format('dd, DD.MM.YYYY HH:mm')} - ${moment(toDate).format('HH:mm')}`;
    }

    if (format) {
      return `${moment(fromDate).format(format)} - ${moment(toDate).format(format)}`;
    } else {
      return `${moment(fromDate).format('dd, DD.MM.YYYY HH:mm')} - ${moment(toDate).format('dd, DD.MM.YYYY HH:mm')}`;
    }
  }

}

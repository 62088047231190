<div class="modal-header text-light">
    <h3 *ngIf="!tournamentPlanId">{{'Tournaments.Create' | transloco}}</h3>
    <h3 *ngIf="tournamentPlanId">{{'Tournaments.Edit' | transloco}}</h3>
</div>

<div class="modal-body">
    <form *ngIf="tournamentForm" [formGroup]="tournamentForm">
        <div class="row">
            <div class="col-12 mb-3">
                <label for="name" class="form-label">
                    {{'Tournaments.Configuration.BasicData.Name' | transloco}}*
                </label>
                <div>
                    <input type="string" class="form-control" id="name" formControlName="name">
                </div>
            </div>
        </div>

        <div class="row">
            <label>{{'Tournaments.Configuration.BasicData.Types' | transloco}} *</label>

            <div class="col-12 mb-3 mt-1">
                <button class="btn btn-light flex-shrink border no-shadow me-2"
                    (click)="setType(TournamentTypeEnum.Day)"
                    [ngClass]="{'bg-secondary text-light border-secondary': isTypeSelected(TournamentTypeEnum.Day), 'border-danger':  !tournamentForm.controls['type'].valid && formSubmitted}">{{'Tournaments.Configuration.BasicData.Type_Day'
                    | transloco}}</button>
                <button class="btn btn-light flex-shrink border no-shadow"
                    (click)="setType(TournamentTypeEnum.Semester)"
                    [ngClass]="{'bg-secondary text-light border-secondary': isTypeSelected(TournamentTypeEnum.Semester), 'border-danger':  !tournamentForm.controls['type'].valid && formSubmitted}">{{'Tournaments.Configuration.BasicData.Type_Semester'
                    | transloco}}</button>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <label class="form-label">{{'Tournaments.Configuration.BasicData.ResultEntry' | transloco}}</label>

                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" id="allowCaptainEntry"
                        formControlName="allowCaptainEntry" />
                    <label class="form-check-label fw-normal" for="allowCaptainEntry">
                        {{'Tournaments.Configuration.BasicData.AllowCaptainResultEntry' | transloco}}
                    </label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <label class="form-label">
                    {{'Tournaments.Configuration.BasicData.UseTeamAsReferee' | transloco}}
                </label>
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" id="useTeamAsReferee"
                        formControlName="useTeamAsReferee" />
                    <label class="form-check-label fw-normal" for="useTeamAsReferee">
                        {{'Tournaments.Configuration.BasicData.AllowCaptainResultEntry' | transloco}}
                    </label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <label class="form-label">
                    {{'Tournaments.Configuration.BasicData.TeamRefereeResultentry' | transloco}}
                </label>
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" id="allowTeamRefereeEntry"
                        formControlName="allowTeamRefereeEntry" />
                    <label class="form-check-label fw-normal" for="allowTeamRefereeEntry">
                        {{'Tournaments.Configuration.BasicData.AllowTeamRefereeResultentry' | transloco}}
                    </label>
                </div>
            </div>
        </div>
    </form>


</div>

<div class="modal-footer text-light">
    <button type="button" class="btn btn-default" (click)="decline()">{{'Cancel' | transloco}}</button>
    <button type="submit" class="btn btn-success" (click)="confirm()">{{'Save' | transloco}}</button>
</div>
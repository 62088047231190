import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { BaseComponent } from '@premotec/ngx-essentials';
import { ToastrService } from 'ngx-toastr';
import { Account } from 'src/app/account/account.model';
import { AccountService } from 'src/app/account/account.service';
import { UpdatePassword } from 'src/app/account/update-password.model';

@Component({
    selector: 'app-change-password-modal',
    templateUrl: './change-password-modal.component.html',
    styleUrls: ['./change-password-modal.component.scss']
})
export class ChangePasswordModalComponent extends BaseComponent implements OnInit {

    @Input() account!: Account;

    @Output() closeModalEvent: EventEmitter<null> = new EventEmitter<null>();
    @Output() saveModalEvent: EventEmitter<null> = new EventEmitter<null>();

    passwordForm!: FormGroup;

    passwordChanged: boolean = false;
    isSaving: boolean = false;
    isSubmitted: boolean = false;

    constructor(
        private fb: FormBuilder,
        private accountService: AccountService,
        private translocoService: TranslocoService,
        private toastrService: ToastrService) {
        super();
    }

    ngOnInit() {
        this.initForm();
    }

    initForm() {
        this.passwordForm = this.fb.group({
            oldPassword: [null, [Validators.required]],
            newPassword: [null, [Validators.required, Validators.maxLength(100), Validators.minLength(8)]],
            newPasswordConfirmed: [null, [Validators.required]]
        });
    }

    close(): void {
        this.closeModalEvent.emit();
    }

    changePassword(): void {
        this.isSubmitted = true;


        if (this.passwordForm.valid) {

            if (this.passwordForm.get('newPassword')!.value != this.passwordForm.get('newPasswordConfirmed')!.value) {
                this.passwordForm.get('newPasswordConfirmed')!.setErrors({ notSame: true })
                return;
            }

            this.isSaving = true;

            var passwordModel = new UpdatePassword();
            passwordModel.oldPassword = this.passwordForm.get('oldPassword')!.value;
            passwordModel.newPassword = this.passwordForm.get('newPassword')!.value;
            passwordModel.newPasswordConfirmed = this.passwordForm.get('newPasswordConfirmed')!.value;

            this.accountService.updatePassword(passwordModel).subscribe(result => {
                this.isSaving = false;
                this.passwordChanged = true;
            }, () => {
                this.isSaving = false;
                this.toastrService.error(this.translocoService.translate('Msg.SaveError'));
            });
        }
    }
}

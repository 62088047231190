<div class="modal-header bg-youth text-light">
    <h3>{{'DeRegister.Title' | transloco}}</h3>
</div>
<div class="modal-body">

    <app-spinner *ngIf="!information" [margin]="'25px'"></app-spinner>

    <ng-container *ngIf="information">
        <p>
            <b>{{'DeRegister.Question' | transloco}}</b> <br>
            <span>{{'DeRegister.QuestionEn' | transloco}}</span>
        </p>

        <dl>
            <dt>{{'Sportoffer' |transloco}}</dt>
            <dd>{{event.title}}</dd>
        </dl>
        <dl>
            <dt>{{'RefundAmount' |transloco}} <span class="fw-normal" [innerHTML]="'RefundInfo' | transloco"></span></dt>
            <dd>{{information.returnAmount | currencyFormat}}</dd>
        </dl>
        
        <p>
            <b>{{'DeRegister.Information' | transloco}}</b> <br>
            <span>{{'DeRegister.InformationEn' | transloco}}</span>
        </p>

        <div class="form-check">
            <input class="form-check-input" type="checkbox" id="isConfirmed" [(ngModel)]="isConfirmed">
            <label class="form-check-label" for="isConfirmed">
                <b>{{'DeRegister.Confirmation' | transloco}}</b> <br>
                <span class="fw-normal">{{'DeRegister.ConfirmationEn' | transloco}}</span>
            </label>
          </div>
    </ng-container>
</div>

<div class="modal-footer text-light">
    <button type="button" class="btn btn-default" (click)="decline()">Nein</button>
    <button type="submit" class="btn btn-primary" (click)="confirm()" [disabled]="!information || !isConfirmed">Ja</button>
</div>
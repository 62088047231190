import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NavigationBarComponent} from './navigation-bar/navigation-bar.component';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../shared/shared.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { SideNavbarComponent } from './side-navbar/side-navbar.component';
import { LinkItemComponent } from './side-navbar/link-item/link-item.component';
import { SectionComponent } from './side-navbar/section/section.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { AdminNavBarComponent } from './admin-navbar/admin-navbar.component';

@NgModule({
  imports: [
    BsDropdownModule.forRoot(),
    SharedModule,
    RouterModule,
    CommonModule,
    
    CollapseModule.forRoot(),
  ],
  declarations: [
    NavigationBarComponent,
    AdminNavBarComponent,
    SideNavbarComponent,
    LinkItemComponent,
    SectionComponent
  ],
  exports: [
    NavigationBarComponent,
    AdminNavBarComponent,
    SideNavbarComponent,
    LinkItemComponent,
    SectionComponent
  ]
})
export class NavigationModule {
}

import { Component, OnInit, Input } from '@angular/core';
import { FacilityAnnouncementList } from '../../models/facility-announcement-list.model';

@Component({
  selector: 'app-information-screen-announcements-component',
  templateUrl: './announcements.component.html',
  styleUrls: ['./announcements.component.scss']
})
export class AnnouncementsComponent implements OnInit {

  @Input() announcements!: FacilityAnnouncementList[];

  constructor() { }

  ngOnInit() {
  }

}

<ng-template #confirmActionModal>
    <div class="modal-header">
      <h4 class="modal-title float-start">
        <span>{{'Confirm' | transloco}}</span>
      </h4>
    </div>
    <div class="modal-body">
      <app-spinner *ngIf="isLoading" [margin]="'0 auto'"></app-spinner>
      <label class="control-label" *ngIf="body">{{body}}</label>
    </div>
    <div class="modal-footer">
      <button class="btn btn-default" [disabled]="isLoading" (click)="cancel()">{{'Cancel' | transloco }}</button>
      <button class="btn btn-primary" [disabled]="isLoading" (click)="confirm()">
        <span *ngIf="confirmText">{{confirmText}}</span>
        <span *ngIf="!confirmText">{{'Confirm' | transloco}}</span>
      </button>
    </div>
    </ng-template>
import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-show-live-stream-button',
  templateUrl: './show-live-stream-button.component.html',
  styleUrls: ['./show-live-stream-button.component.scss']
})
export class ShowLiveStreamButtonComponent implements OnInit {

  @Output() buttonClicked: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }

  showLiveStream(): void {
    this.buttonClicked.next();
  }
}

<table class="table table-striped table-responsive table-bordered">
  <thead style="font-weight: bold;">
  <tr>
    <td>{{'Title' | transloco}}</td>
    <td>{{'Sport' | transloco}}</td>
    <td>{{'Date' | transloco}}</td>
    <td>{{'Place' | transloco}}</td>
    <td>{{'SeatNr' | transloco}}</td>
  </tr>
  </thead>
  <tbody>
    <tr *ngFor="let enrollment of enrollments">
      <td><a [routerLink]="'/lessons/'+enrollment.lessonId">{{enrollment.lessonName}}</a></td>
      <td>{{enrollment.sportName}}</td>
      <td>{{enrollment.lessonStart | fromToDate: enrollment.lessonEnd : 'DD.MM.YYYY': false}}</td>
      <td>
        <span *ngIf="enrollment.location">{{enrollment.location}}</span>
        <span *ngIf="!enrollment.location && enrollment.room">{{enrollment.room}}</span>
      </td> 
      <td>{{enrollment.placeNumber}}</td>
    </tr>
    <tr *ngIf="noEntries">
      <td colspan="5">{{'NoEntries' | transloco}}</td>
    </tr>
  </tbody>
</table>


import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { TournamentGroupListModel } from '../models/tournament-group-list.model';
import { TournamentService } from '../services/tournament.service';
import { TournamentGroupAddTeamModel } from '../models/tournament-group-add-team.model';
import { TournamentGameChangeSlotModel } from '../models/tournament-game-change-slot.model';
import * as moment from 'moment';
import { TournamentGameChangeSlotOptionModel } from '../models/tournament-game-change-slot-option.model';
import { TournamentGameNewSlotModel } from '../models/tournament-game-new-slot.model';
import { TournamentGameResultModel } from '../models/tournament-game-result.model';
import { TournamentDisplayService } from '../services/tournament-display.service';
import { TournamentGameSetResultModel } from '../models/tournament-game-set-result-model';
import { TournamentGameSetResultUpdateModel } from '../models/tournament-game-set-result-update.model';

@Component({
    templateUrl: './tournament-game-result-modal.component.html',
    styleUrls: ['./tournament-game-result-modal.component.scss']
})
export class TournamentGameResultModalComponent implements OnInit {

    @Output() confirmed = new EventEmitter<boolean>();

    resultsForm!: UntypedFormGroup;

    tournamentPlanId!: string;
    gameId!: string;
    teamSecret?: string;

    results!: TournamentGameResultModel;

    formSubmitted = false;

    processing = false;

    constructor(
        private fb: FormBuilder,
        private bsModalRef: BsModalRef,
        private tournamentService: TournamentService,
        public tournamentDisplayService: TournamentDisplayService,
        private toastrService: ToastrService,
        private translocoService: TranslocoService
    ) { }

    ngOnInit(): void {
        this.initForm();

        if (this.teamSecret == null) {
            this.loadData();
        } else {
            this.loadDataWithSecret();
        }

    }

    initForm() {
        this.resultsForm = this.fb.group({
            gameSets: this.fb.array([])
        });
    }

    getGameSets(): FormArray {
        return this.resultsForm.get("gameSets") as FormArray
    }

    loadData() {
        this.tournamentService.getGameResult(this.tournamentPlanId, this.gameId).subscribe(x => {
            this.results = x;
            x.sets = x.sets.sort((a, b) => a.number - b.number)
            this.setGameSets(x.sets);
        })
    }

    loadDataWithSecret() {
        this.tournamentService.getGameResultWithSecret(this.tournamentPlanId, this.gameId, this.teamSecret!).subscribe(x => {
            this.results = x;
            x.sets = x.sets.sort((a, b) => a.number - b.number)
            this.setGameSets(x.sets);
        })
    }

    setGameSets(gameSets: TournamentGameSetResultModel[]) {

        var gameSetsForm = this.getGameSets();

        gameSets.forEach(x => {
            var gameSetForm = this.fb.group({
                id: new FormControl(x.id),
                homePoints: new FormControl(x.homePoints, [Validators.required]),
                guestPoints: new FormControl(x.guestPoints, [Validators.required]),
            });
            gameSetsForm.push(gameSetForm);
        });
    }

    getGameSetModels(): TournamentGameSetResultUpdateModel[] {
        var models: TournamentGameSetResultUpdateModel[] = [];

        var gameSetsForm = this.getGameSets();

        for (let control of gameSetsForm.controls) {
            var formGroup = control as FormGroup;

            var model = new TournamentGameSetResultUpdateModel();
            model.id = formGroup.controls['id'].value;
            model.homePoints = formGroup.controls['homePoints'].value;
            model.guestPoints = formGroup.controls['guestPoints'].value;
            models.push(model);
        }

        return models;
    }

    isResultFormGroupValid(group: AbstractControl<any, any>, name: string): boolean {
        var formGroup = group as FormGroup;
        return formGroup.controls[name].valid;
    }

    save() {
        this.processing = true;

        var models = this.getGameSetModels();

        if (this.teamSecret == null) {
            this.saveAsAdmin(models);
        } else {
            this.saveWithSecret(models);
        }
    }

    saveWithSecret(models: TournamentGameSetResultUpdateModel[]) {
        this.tournamentService.setGameResultWithSecret(this.tournamentPlanId, this.gameId, this.teamSecret!, models).subscribe(x => {
            this.confirmed.emit(true);
            this.bsModalRef.hide();
        }, err => {
            this.processing = false;
            if (err.status == 401) {
                this.confirmed.emit(false);
                this.bsModalRef.hide();
            }
        });

    }

    saveAsAdmin(models: TournamentGameSetResultUpdateModel[]) {
        this.tournamentService.setGameResult(this.tournamentPlanId, this.gameId, models).subscribe(x => {
            this.confirmed.emit(true);
            this.bsModalRef.hide();
        }, err => {
            this.processing = false;
        });
    }

    confirm() {
        this.formSubmitted = true;
        if (!this.resultsForm.valid) return;

        this.save();
    }

    decline() {
        this.bsModalRef.hide();
    }
}

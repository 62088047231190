<div *ngIf="occupancyPlanViews">
  <div class="card">
    <div class="card-header">
      <span><a class="btn btn-default btn-sm" [routerLink]="['/office/occupancyplanviews/new']"><i class="fa fa-plus"></i></a></span>
      <span class="badge  bg-secondary float-end">{{(occupancyPlanViews| filterBy: ['name'] : query).length  }}</span>
    </div>

    <div class="card-body">
      <div class="input-group">
        <input type="text" class="form-control" [(ngModel)]="query" placeholder="Filter"/>
        <span class="input-group-text"><i class="fa fa-filter form-control-feedback" style="font-size:16px;"></i></span>
      </div>
    </div>

    <div class="list-group" >
      <span *ngFor="let occupancyPlanView of occupancyPlanViews | orderBy: 'name' | filterBy: ['name'] : query; let i = index" >
        <a class="list-group-item list-group-item-action" 
           [routerLink]="['/office/occupancyplanviews/'+occupancyPlanView.id]"
           [routerLinkActive]="'list-group-item-info'">
          {{ occupancyPlanView.name }}
        </a>
      </span>
    </div>
  </div>
</div>

<div class="card">
    <div class="card-header">
        <div class="d-flex">
            <div class="flex-grow-1">
                <b>{{'Tournaments.Teams.TeamRefereeGames.Title' | transloco}}</b>
            </div>
            <div>
                <div class="form-check form-switch">
                    <label class="form-check-label text-end" for="showOldReferee">
                        {{'Tournaments.Teams.TeamRefereeGames.ShowOld' | transloco}}
                    </label>
                </div>
            </div>
            <div class="ps-1 align-self-center">
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" id="showOldReferee" [(ngModel)]="showPast"
                        (ngModelChange)="filterGames()">
                </div>
            </div>
        </div>
    </div>
    <div class="card-body pb-0">
        <div *ngIf="filteredRefereeGames">
            <div *ngFor="let refereeGame of filteredRefereeGames" class="mb-3">
                <app-tournament-team-game [allowCaptainEntry]="refereeGames.allowResultEntry" [showResults]="refereeGames.showResults" [game]="refereeGame" (resultChanges)="loadTeamGames()"
                    [tournamentPlanId]="tournamentPlanId" [teamSecret]="secret">
                </app-tournament-team-game>
            </div>
            <div *ngIf="filteredRefereeGames.length == 0" class="well mb-3">
                {{'Tournaments.Teams.TeamRefereeGames.NoMoreGames' | transloco}}
            </div>
        </div>

        <div *ngIf="!filteredRefereeGames" class="p-3">
            <app-spinner></app-spinner>
        </div>
    </div>
</div>
import { ChangeDetectorRef, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { BaseComponent } from '@premotec/ngx-essentials';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { HandHeldScannerResultModalComponent } from '../hand-held-scanner-result-modal/hand-held-scanner-result-modal.component';
import { AccessSystemScannerListModel } from '../models/access-system-scanner-list.model';
import { HandHeldScannerGetPicturePostModel } from '../models/hand-held-scanner-get-picture-post.model';
import { HandHeldScannerValidatePostModel } from '../models/hand-held-scanner-validate-post.model';
import { HandHeldScannerService } from '../services/hand-held-scanner.service';
import { FormControl, Validators } from '@angular/forms';
import { HandHeldScannerCheckType } from '../models/hand-held-scanner-check-type.enum';
import { HandHeldPersonDetailModalComponent } from '../hand-held-person-detail-modal/hand-held-person-detail-modal.component';
import { take } from 'rxjs';

declare namespace AsvzNfcScanner {
    function scan(): void;
    function stopScanning(): void;
};

@Component({
    selector: 'app-hand-held-scanner-nfc',
    templateUrl: './hand-held-scanner-nfc.component.html',
    styleUrls: ['./hand-held-scanner-nfc.component.scss']
})
export class HandHeldScannerNfcComponent extends BaseComponent implements OnInit {

    modalRef!: BsModalRef | undefined;

    isLoading = false;

    accessSystemScanners!: AccessSystemScannerListModel[];
    selectedAccessSystemScannerId = new FormControl<number | null>(null, Validators.required);

    picture!: Blob | null;

    @HostListener('window:nfcScanned', ['$event'])
    nfcScanned(event: any) {
        if ((this.modalRef === null || this.modalRef === undefined) && !this.isLoading) {
            this.nfcDetected(event.detail);
        }
    }

    @HostListener('window:nfcScannedFailed', ['$event'])
    nfcScannedFailed() {
        this.toastrService.error(this.translocoService.translate('HandHeldScanner.ScanFailed'));
    }

    @HostListener('window:nfcNotAvailable', ['$event'])
    nfcNotAvailable() {
        this.toastrService.error('Web NFC is not available. Use Chrome 98+ on Android.');
    }

    constructor(
        private handHeldScannerService: HandHeldScannerService,
        private modalService: BsModalService,
        private cd: ChangeDetectorRef,
        private router: Router,
        private route: ActivatedRoute,
        private toastrService: ToastrService,
        private translocoService: TranslocoService,
        private titleService: Title
    ) {
        super();
    }

    ngOnInit(): void {
        this.translocoService.selectTranslate('HandHeldScanner.HandHeldScanner').subscribe((x: any) => {
            this.titleService.setTitle(x);
        });

        this.route.queryParams.subscribe((params) => {
            // if reload is true, reload page. Else nfc won't work with certain devices
            if (params['reload'] !== null) {
                if (params['reload'] === 'true') {

                    // remove reload query parameter
                    var snapshot = this.route.snapshot;
                    const params = { ...snapshot.queryParams };
                    delete params['reload'];
                    this.router.navigate([], { queryParams: params }).then(() => {
                        // reload page
                        window.location.reload();
                    });
                }
            }

            // set scanner
            if (params['selectedAccessSystemScannerId'] != null) {
                this.selectedAccessSystemScannerId.patchValue(+params['selectedAccessSystemScannerId']);
            }
        });

        // start nfc scanning
        AsvzNfcScanner.scan();
    }

    switchToQrCode() {
        if (this.selectedAccessSystemScannerId !== null && this.selectedAccessSystemScannerId !== undefined) {
            this.router.navigate(['handheldscanner/qr'], { queryParams: { selectedAccessSystemScannerId: this.selectedAccessSystemScannerId } });
        } else {
            this.router.navigate(['handheldscanner/qr']);
        }
    }

    nfcDetected(serialNumber: string): void {

        // we need a scanner id to validate
        if (this.selectedAccessSystemScannerId.invalid) return;

        // transform serial number, s000000000000 is standard prefix for nTree
        serialNumber = serialNumber.split(':').reverse().join('');
        serialNumber = serialNumber.replace(new RegExp(':', 'g'), '');
        serialNumber = "s000000000000" + serialNumber;

        // validate model
        let validateModel = new HandHeldScannerValidatePostModel();
        validateModel.rfId = serialNumber;
        validateModel.checkOnly = true;
        validateModel.checkType = HandHeldScannerCheckType.Rfid;
        validateModel.scannerId = this.selectedAccessSystemScannerId.value!;

        const modalOptions: ModalOptions<Partial<HandHeldPersonDetailModalComponent>> = {
            backdrop: 'static',
            keyboard: false,
            class: 'hand-held-scanner-modal',
            initialState: {
              validateModel: validateModel,
              checkType: HandHeldScannerCheckType.Rfid,
              picturePostModel: {rfid: serialNumber}
            },
        };
        
        this.modalRef = this.modalService.show(HandHeldPersonDetailModalComponent, modalOptions);

        this.modalRef.onHidden?.pipe(take(1)).subscribe(() => {
            this.modalRef = undefined;
        })
    }

}

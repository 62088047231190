export const environment = {
    production: true,
    staging: true,
    baseHref: 'tn',
    api: 'https://test.schalter.asvz.ch/tn-api/api',
    apiExt: 'https://test.schalter.asvz.ch/tn-apiext/api',
    externalSite: 'https://test.schalter.asvz.ch/tn-mvc',
    hangfireUrl: 'https://test.schalter.asvz.ch/tn-hangfire/dashboard',
    papercutUrl: 'https://test.schalter.asvz.ch/mails',
    loginPage: 'https://test.auth.asvz.ch',
  
    identity: {
      client_id: 'c238896e-a033-4bcd-8b57-09bad18b38ce',
      authority: 'https://test.auth.asvz.ch',
      api: 'https://test.auth.asvz.ch/api',
      redirect_uri: 'https://test.schalter.asvz.ch/tn/assets/oidc-login-redirect.html',
      scope: 'openid profile stage.tn-api stage.tn-apiext stage.tn-auth stage.tn-hangfire',
      response_type: 'id_token token',
      post_logout_redirect_uri: 'https://test.schalter.asvz.ch/tn/?postLogout=true',
      silent_redirect_uri: 'https://test.schalter.asvz.ch/tn/assets/silent-redirect.html',
    },
  
    localSite: 'https://test.schalter.asvz.ch/tn',
    timerInterval: 5000,
    tlToolBaseUrl:  'https://test.schalter.asvz.ch/tl'
  };
  
import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { CheckAppVersionChangeService } from '../../config/check-app-version-change.service';
import { UserAuthorizationService } from '../../security/user-authorization.service';
import { Router } from '@angular/router';
import { BaseComponent } from '@premotec/ngx-essentials';
import { AccountService } from 'src/app/account/account.service';
import { Account } from 'src/app/account/account.model';
import { ApplicationUser } from 'src/app/security/application-user.model';

@Component({
  selector: 'admin-navbar',
  templateUrl: './admin-navbar.component.html',
  styleUrls: ['./admin-navbar.component.scss'],
})
export class AdminNavBarComponent extends BaseComponent implements OnInit {
  collapse = true;
  externalSiteUrl = environment.externalSite;
  hangfireUrl = environment.hangfireUrl;
  papercutUrl = environment.papercutUrl;
  identitySiteUrl = environment.identity.authority;
  loginPageUrl = environment.loginPage;
  toolTapUrl = environment.tlToolBaseUrl;

  public userName: string | undefined = '';
  public account?: Account;

  isNavOfficeVisible: boolean = false;
  isNavAdminVisible: boolean = false;
  isNavPersonAndEventSearchVisible: boolean = false;
  isNavStatisticsVisible: boolean = false;
  isNavTLToolVisible: boolean = false;

  isProductionEnvironment = environment.production;
  isStagingEnvironment = environment.staging;

  constructor(
    private authenticationService: UserAuthorizationService,
    private accountService: AccountService,
    private checkAppVersionChangeService: CheckAppVersionChangeService,
    public router: Router
    ) {
    super();
  }

  ngOnInit() {
    this.authenticationService.userHasChanges$.subscribe(() => {
      this.initData(this.authenticationService.getUser());
    });

    AccountService.accountChangedEvent.subscribe((account: Account) => {
      this.getAccount();
    });

  }

  leaveAdminMode(){
    this.router.navigateByUrl('');
  }

  login() {
    const routerStateSnapshot = this.router.routerState.snapshot;
    this.authenticationService.startAuthentication(routerStateSnapshot.url);
  }

  logout() {
    this.authenticationService.logout();
  }

  isLoggedIn(): boolean {
    return this.authenticationService.isLoggedIn();
  }

  hasNewRelease() {
    return this.checkAppVersionChangeService.hasNewRelease;
  }

  reloadNewRelease() {
    window.location.reload();
  }

  private initData(user: ApplicationUser) {
    this.userName = user.given_name;

    this.isNavOfficeVisible = user.hasRoleOffice() || user.hasRoleAdmin();
    this.isNavAdminVisible = user.hasRoleAdmin();
    this.isNavPersonAndEventSearchVisible = user.hasRoleOffice() || user.hasRoleAdmin();
    this.isNavStatisticsVisible = user.hasRoleOffice() || user.hasRoleAdmin();
    this.isNavTLToolVisible = user.hasRoleOffice() || user.hasRoleHspl() || user.hasRoleTrainingsleiter() || user.hasRoleAdmin();

    this.getAccount();
  }

  private getAccount() {
    this.whileImAlive(
      this.accountService.getAccountDetails()
    ).subscribe((data) => {
      this.account = data;
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '@premotec/ngx-essentials';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpResponseStatus } from 'src/app/shared/http-status-code.enum';
import { MembershipApplicationsService } from '../../membership-applications.service';

@Component({
  selector: 'app-email-confirmation',
  templateUrl: './email-confirmation.component.html',
  styleUrls: ['./email-confirmation.component.scss']
})
export class EmailConfirmationComponent extends BaseComponent implements OnInit {

  isLoading = true;
  emailConfirmationTokenExpired = false;
  success = false;
  emailConfirmationTokenInvalid = false;
  membershipApplicationId: any;
  resendEmailConfirmationMailSuccess = false;
  emailAlreadyConfirmed = false;
  emailConfirmationNotInitialized = false;

  showDocumentsInPerson = false;

  constructor(
    private route: ActivatedRoute,
    private applicationService: MembershipApplicationsService,
    private router: Router
  ) {
    super();
  }

  ngOnInit() {
    this.whileImAlive(this.route.params).subscribe(params => {
      const membershipApplicationId = params['membershipApplicationId'];

      if (membershipApplicationId != null) {
        this.membershipApplicationId = membershipApplicationId;
        this.whileImAlive(this.route.queryParams).subscribe(queryParams => {
          const token = queryParams['token'];

          if (token != null) {
            this.confirmApplicationEmail(membershipApplicationId, token);
          } else {
            this.router.navigateByUrl('notFound');
          }
        });
      } else {
        this.router.navigateByUrl('notFound');
      }
    });
  }

  confirmApplicationEmail(membershipApplicationId: any, token: any) {
    this.whileImAlive(this.applicationService.confirmApplicationEmail(membershipApplicationId, token)).subscribe((application) => {
      if (application != null) {
        this.showDocumentsInPerson = application.showDocumentsInPerson;
      }
      this.success = true;
    },
    (error: HttpErrorResponse) => {
      if (error.status === HttpResponseStatus.CODE_400_BAD_REQUEST) {
        switch (error.error.errorStatus) {
          case 'EmailConfirmationTokenExpired':
            this.emailConfirmationTokenExpired = true;
            break;
          case 'EmailConfirmationNotInitialized':
            this.emailConfirmationNotInitialized = true;
            break;
          case 'EmailAlreadyConfirmed':
            this.emailAlreadyConfirmed = true;
            break;
          case 'EmailConfirmationTokenInvalid':
              this.emailConfirmationTokenInvalid = true;
            break;
          default:
            this.emailConfirmationTokenInvalid = true;
        }
      } else {
        this.router.navigateByUrl('error');
      }
    }).add(() => {
      this.isLoading = false;
    });
  }

  resendEmailConfirmationMail() {
    this.isLoading = true;
    this.whileImAlive(this.applicationService.resendEmailConfirmationMail(this.membershipApplicationId)).subscribe(() => {
      this.isLoading = false;
      this.resendEmailConfirmationMailSuccess = true;
    },
    () => {
      this.router.navigateByUrl('error');
    });
  }

}

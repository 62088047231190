<div class="modal-header">
  <h2>{{ 'ChangeEmailAddress' | transloco}}</h2>
</div>
<div class="modal-body">

  <form *ngIf="!emailAddressChanged" [formGroup]="emailForm">
    <div class="form-group" [ngClass]="{'has-error': this.emailForm.get('email')!.errors}">
      <label for="email">Email</label>
      <input formControlName="email" id="Email" name="Email" class="form-control" type="email"
        placeholder="you@example.com">
      <span class="help-block" *ngIf="emailForm.get('email')?.errors && isSubmitted">
        <span *ngIf="emailForm.get('email')?.errors!['required']">
          {{'ValidationText.Email.Required' | transloco}}
        </span>
        <span *ngIf="emailForm.get('email')?.hasError('maxlength')">
          {{'ValidationText.MaxLength' | transloco: {maxLength: 250} }}
        </span>
        <span *ngIf="!emailForm.get('email')?.errors!['required'] && emailForm.get('email')?.errors!['email']">
          {{'ValidationText.Email.Valid' | transloco}}
        </span>
        <span *ngIf="emailForm.get('email')?.hasError('sameEmail')">
          {{'ValidationText.Email.SameEmail' | transloco}}
        </span>
      </span>
    </div>
  </form>
  <div *ngIf="emailAddressChanged" class="alert alert-success" role="alert">
    {{ 'EmailChangePending' | transloco }}
  </div>
</div>
<div class="modal-footer">
  <button *ngIf="!emailAddressChanged" class="btn btn-default" (click)="close()" [disabled]="isSaving">{{'Cancel' |
    transloco}}</button>
  <button *ngIf="!emailAddressChanged" class="btn btn-primary" (click)="changeEmail()"
    [disabled]="this.emailForm.get('email')!.errors || isSaving">{{'ChangeEmailBtn' | transloco}}</button>

  <button *ngIf="emailAddressChanged" class="btn btn-primary" (click)="close()">{{'Ok' | transloco}}</button>
</div>
<div class="container" style="min-height: 500px;">
  <div class="page-header">
    <h1>
      {{'Payment' | transloco}}
    </h1>
  </div>
  <div *ngIf="hasError" class="alert alert-danger">
    {{ 'RedirectionToPaymentError' | transloco }}
  </div>
  <div *ngIf="hasError">
    <ng-container [ngSwitch]="invoiceType.typeId">
      <ng-template [ngSwitchCase]="invoiceRegistrationType">
        <a class="btn btn-default" [routerLink]="'/registration/'+invoiceType.recordId+'/details'" >{{'GotoRegistration' | transloco}}</a>
      </ng-template>
      <ng-template [ngSwitchCase]="invoiceTypes.Membership">
        <a class="btn btn-default" [routerLink]="'/memberships'" >{{'GoToMemberships' | transloco}}</a>
      </ng-template>
    </ng-container>
  </div>
  <form *ngIf="pspInstruction" [action]="pspInstruction.url" id="formSave" method="post">
    <div>
      <ng-container *ngFor="let o of parameters">
        <input [name]="o.key" [value]="o.value" type="hidden">
      </ng-container>
    </div>
    <div>
      <p>{{'RedirectionToPayment' | transloco}}</p>
      <button type="submit" id="btn-submit" class="btn btn-primary">{{'Next' | transloco}}</button>
    </div>
  </form>
</div>

import { CommonModule, DatePipe } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgSelectModule } from "@ng-select/ng-select";
import { TranslocoModule } from "@ngneat/transloco";
import { NgxEssentialsModule } from "@premotec/ngx-essentials";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { TabsModule } from "ngx-bootstrap/tabs";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { ImageCropperModule } from "ngx-image-cropper";
import { NgxMdModule } from "ngx-md";
import { NgPipesModule } from "ngx-pipes";
import { AccountChangeDataFormComponent } from "./account-change-data-form/account-change-data-form.component";
import { AccountPassPhotoUploaderComponent } from "./account-pass-photo-uploader/account-pass-photo-uploader.component";
import { ChangeEmailModalComponent } from "./change-email-modal/change-email-modal.component";
import { ChangePasswordModalComponent } from "./change-password-modal/change-password-modal.component";
import { ConfirmActionModalComponent } from "./confirm-action-modal/confirm-action-modal.component";
import { ConfirmLeaveComponent } from "./confirm-leave/confirm-leave.component";
import { CurrencyFormatPipe } from "./currencyFormat.pipe";
import { DetailsViewComponent } from "./details-view/details-view.component";
import { DiscountFormComponent } from "./discount-form/discount-form.component";
import { EmptyComponent } from "./empty/empty.component";
import { ExternalUrlDirective } from "./external-url.directive";
import { FooterComponent } from "./footer/footer.component";
import { FromToDatePipe } from "./from-to-date.pipe";
import { MomentPipe } from "./moment.pipe";
import { PageNoPermissionComponent } from "./page-no-permission/page-no-permission.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { PageServerErrorComponent } from "./page-server-error/page-server-error.component";
import { PassPhotoRequiredMsgComponent } from "./pass-photo-required-msg/pass-photo-required-msg.component";
import { FacilityClosedPipe } from "./pipes/facility-closed.pipe";
import { TextLocalizationPipe } from "./pipes/text-localization.pipe";
import { PrerequisitesViewComponent } from "./prerequisites-view/prerequisites-view.component";
import { PrerequisitesService } from "./prerequisites-view/prerequisites.service";
import { RejectModalComponent } from "./reject-modal/reject-modal.component";
import { CountryService } from "./services/country.service";
import { DiscountGiftcardService } from "./services/discount-giftcard.service";
import { HttpResponseErrorHandlerService } from "./services/http-response-error-handler.service";
import { PriceService } from "./services/price.service";
import { ShowLiveStreamComponent } from "./show-live-stream/show-live-stream.component";
import { SpinnerComponent } from "./spinner/spinner.component";
import { StatusWorkflowComponent } from "./status-workflow/status-workflow.component";
import { StatusesService } from "./status-workflow/statuses.service";
import { UndatedPipe } from "./undated.pipe";
import { EditModalComponent } from './edit-modal/edit-modal.component';
import { LoadingButtonDirective } from "./loading-button.directive";
import { BSControlStatusDirective } from "./bs-control-status.directive";
import { ReadonlyInputComponent } from './readonly-input/readonly-input.component';
import { EditButtonComponent } from './edit-button/edit-button.component';
import { NgxDatepickerModule } from "@premotec/ngx-datepicker";
import { DeleteButtonComponent } from './delete-button/delete-button.component';
import { ConfirmWithMessageModalComponent } from './modals/confirm-with-message-modal/confirm-with-message-modal.component';
import { LoadingModalComponent } from "./loading-modal/loading-modal.component";
import { FooterSponsorsComponent } from './footer-sponsors/footer-sponsors.component';

const pipes = [
  TextLocalizationPipe,
  MomentPipe
];

const sharedComponents = [
  RejectModalComponent,
  ConfirmActionModalComponent,
  LoadingModalComponent,
  SpinnerComponent,
  EditModalComponent,
  ReadonlyInputComponent,
  EditButtonComponent,
  DeleteButtonComponent
];

const sharedDirectives = [
  LoadingButtonDirective,
  BSControlStatusDirective
];

const sharedModules = [
  NgxDatepickerModule
]

@NgModule({
  imports: [
    RouterModule,
    TranslocoModule,
    CommonModule,
    NgxEssentialsModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    NgPipesModule,
    NgSelectModule,
    TooltipModule.forRoot(),
    NgxMdModule.forRoot(),
    ImageCropperModule,
    sharedModules,
  ],
  declarations: [
    FooterComponent,
    DetailsViewComponent,
    PrerequisitesViewComponent,
    PageServerErrorComponent,
    EmptyComponent,
    PageNoPermissionComponent,
    ExternalUrlDirective,
    CurrencyFormatPipe,
    UndatedPipe,
    FromToDatePipe,
    FacilityClosedPipe,
    AccountPassPhotoUploaderComponent,
    ShowLiveStreamComponent,
    PassPhotoRequiredMsgComponent,
    DiscountFormComponent,
    ChangeEmailModalComponent,
    PageNotFoundComponent,
    StatusWorkflowComponent,
    ConfirmLeaveComponent,
    AccountChangeDataFormComponent,
    ChangePasswordModalComponent,
    sharedComponents,
    pipes,
    sharedDirectives,
    ConfirmWithMessageModalComponent,
    FooterSponsorsComponent,
    
  ],
  exports: [
    sharedComponents,
    pipes,
    sharedDirectives,
    sharedModules,
    TranslocoModule,
    NgPipesModule,
    ReactiveFormsModule,
    NgxMdModule,
    TooltipModule,
    AccountPassPhotoUploaderComponent,
    DetailsViewComponent,
    EmptyComponent,
    FooterComponent,
    PrerequisitesViewComponent,
    ExternalUrlDirective,
    CurrencyFormatPipe,
    FacilityClosedPipe,
    FromToDatePipe,
    UndatedPipe,
    ShowLiveStreamComponent,
    PassPhotoRequiredMsgComponent,
    DiscountFormComponent,
    StatusWorkflowComponent,
    AccountChangeDataFormComponent,
    TabsModule,
    NgxEssentialsModule,
    BsDropdownModule,
    ConfirmLeaveComponent,
    AccountChangeDataFormComponent
  ],
  providers: [
    UndatedPipe,
    PrerequisitesService,
    DatePipe,
    CountryService,
    PriceService,
    StatusesService,
    DiscountGiftcardService
  ],
  entryComponents: [
    ConfirmLeaveComponent
  ]
})
export class SharedModule {
}

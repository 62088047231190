<div *ngIf="!showLoadingIndicator">

  <div class="alert alert-danger mt-3" *ngIf="httpError">{{'GeneralErrorMsg' | transloco}}</div>

  <div class="alert alert-danger" *ngIf="submitted && !registrationDetailsForm.pristine && registrationDetailsForm.invalid" style="margin-top:1rem;">
    <ul class="m-0">
      <li>{{'ValidationText.FillInAllRequiredFields' | transloco}}</li>
    </ul>
  </div>

  <form *ngIf="showForm" [formGroup]="registrationDetailsForm">
    <div *ngFor="let detail of registrationDetails" class="mb-3">
      <app-registration-details-question [detail]="detail" [form]="registrationDetailsForm"></app-registration-details-question>
    </div>

    <div style="padding:1rem 0;">
      <strong>{{'RequiredDetailsMsg' | transloco }}</strong>
    </div>

    <div class="alert">
      <div class="row">
        <div class="col-md-12">
          <div class="float-end">
            <app-registration-cancel-modal [registration]="registrationSummary"></app-registration-cancel-modal>
            <button type="submit" id="wizardSubmit" class="btn btn-primary ms-2" (click)="submit()" [disabled]="disableSendButton">{{'Next' |
              transloco}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<app-spinner *ngIf="showLoadingIndicator"  margin="100px auto"></app-spinner>


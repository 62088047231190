<ng-container *ngIf="accountForm">

  <form [formGroup]="accountForm">

    <div class="alert alert-danger" *ngIf="submitted && (!accountForm.valid || !confirmForm.valid)">
      <ul class="m-0">
        <li *ngIf="!accountForm.valid">{{'ValidationText.FillInAllRequiredFields' | transloco}}</li>
        <li [innerHTML]="'ValidationText.AVB' | transloco" *ngIf="!confirmForm.get('acceptAvb')?.valid"></li>
        <li *ngIf="!confirmForm.get('acceptCorrectContactDetails')?.valid">
          {{'ValidationText.VerifyCorrectContactDetails' | transloco}}</li>
      </ul>

    </div>

    <ng-container formGroupName="applicant">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label class="control-label" for="Salutation">{{'Gender' | transloco}} {{requiredPropertyIndicator}}</label>
            <select class="form-control" formControlName="salutation"
              [ngClass]="{'is-invalid': submitted && applicantGroup.get('salutation')?.errors}">
              <option></option>
              <option>Divers</option>
              <option>Frau</option>
              <option>Herr</option>
            </select>
            <span class="invalid-feedback">
              <span *ngIf="applicantGroup.get('salutation')?.getError('required')">
                {{'ValidationText.Required' | transloco}}
              </span>
              <span *ngIf="applicantGroup.get('salutation')?.getError('maxlength')">
                {{'ValidationText.MaxLength' | transloco: {maxLength: 250} }}
              </span>
            </span>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-6">
          <div class="form-group">
            <label class="control-label" for="FirstName">{{'FirstName' | transloco}}
              {{requiredPropertyIndicator}}</label>
            <input formControlName="firstName" class="form-control" id="FirstName" name="FirstName" type="text"
              [ngClass]="{'is-invalid': submitted && applicantGroup.get('firstName')?.errors}">
            <span class="invalid-feedback">
              <span *ngIf="applicantGroup.get('firstName')?.getError('required')">
                {{'ValidationText.Required' | transloco}}
              </span>
              <span *ngIf="applicantGroup.get('firstName')?.getError('maxlength')">
                {{'ValidationText.MaxLength' | transloco: {maxLength: 250} }}
              </span>
            </span>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="control-label" for="LastName">{{'LastName' | transloco}} {{requiredPropertyIndicator}}</label>
            <input formControlName="lastName" class="form-control" id="LastName" name="LastName" type="text"
              [ngClass]="{'is-invalid': submitted && applicantGroup.get('lastName')?.errors}">
            <span class="invalid-feedback">
              <span *ngIf="applicantGroup.get('lastName')?.getError('required')">
                {{'ValidationText.Required' | transloco}}
              </span>
              <span *ngIf="applicantGroup.get('lastName')?.getError('maxlength')">
                {{'ValidationText.MaxLength' | transloco: {maxLength: 250} }}
              </span>
            </span>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-6">
          <div class="form-group">
            <label class="control-label" for="DateOfBirth">{{'BirthDate' | transloco}}
              {{requiredPropertyIndicator}}</label>
            <pmt-datepicker [id]="'DateOfBirth'" formControlName="dateOfBirth"
            [ngClass]="{'is-invalid border border-danger rounded': submitted && applicantGroup.get('dateOfBirth')?.errors}"
            >
            </pmt-datepicker>

            <span class="invalid-feedback">
              <span *ngIf="applicantGroup.get('dateOfBirth')?.getError('required')">
                {{'ValidationText.Required' | transloco}}
              </span>
              <span *ngIf="applicantGroup.get('dateOfBirth')?.hasError('maxlength')">
                {{'ValidationText.MaxLength' | transloco: {maxLength: 250} }}
              </span>
              <span *ngIf="applicantGroup.get('dateOfBirth')?.hasError('wrongDate')">
                {{'ValidationText.AgeMustBeOlderThan14' | transloco }}
              </span>
            </span>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-6">
          <div class="form-group">
            <label class="control-label" for="Street1">{{'StreetNr' | transloco}} {{requiredPropertyIndicator}}</label>
            <input formControlName="street1" class="form-control" id="Street1" name="Street1" type="text"
              [ngClass]="{'is-invalid': submitted && applicantGroup.get('street1')?.errors}">
            <span class="invalid-feedback">
              <span *ngIf="applicantGroup.get('street1')?.getError('required')">
                {{'ValidationText.Required' | transloco}}
              </span>
              <span *ngIf="applicantGroup.get('street1')?.hasError('maxlength')">
                {{'ValidationText.MaxLength' | transloco: {maxLength: 250} }}
              </span>
            </span>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="control-label" for="Street2">{{'AdditionalAddress' | transloco }}</label>
            <input formControlName="street2" class="form-control" id="Street2" name="Street2" type="text"
              [ngClass]="{'is-invalid': submitted && applicantGroup.get('street2')?.errors}">
            <span class="invalid-feedback">
              <span *ngIf="applicantGroup.get('street2')?.hasError('maxlength')">
                {{'ValidationText.MaxLength' | transloco: {maxLength: 250} }}
              </span>
            </span>
          </div>
        </div>
      </div>



      <div class="row mt-3">
        <div class="col-md-4">
          <div class="form-group">
            <label class="control-label" for="Country">{{'Country' | transloco}} {{requiredPropertyIndicator}}</label>
            <select formControlName="country" id="Country" name="Country" title="Land" class="form-control"
              [ngClass]="{'is-invalid': submitted && applicantGroup.get('country')?.errors}">
              <option></option>
              <option [ngValue]="'CH'">{{'Switzerland' | transloco}}</option>
              <option [ngValue]="'AT'">{{'Germany' | transloco}}</option>
              <option [ngValue]="'DE'">{{'Austria' | transloco}}</option>
              <option disabled>──────────</option>
              <option *ngFor="let country of countries" value="{{country.nameShort}}">{{country.nameLong}}</option>
            </select>
            <span class="invalid-feedback">
              {{ 'ValidationText.Required' | transloco}}
            </span>
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <label class="control-label" for="Postcode">PLZ {{requiredPropertyIndicator}}</label>
            <input formControlName="postcode" class="form-control" id="Postcode" name="Postcode" type="text"
              [ngClass]="{'is-invalid': submitted && applicantGroup.get('postcode')?.errors}">
            <span class="invalid-feedback">
              <span *ngIf="applicantGroup.get('postcode')?.getError('required')">
                {{'ValidationText.Required' | transloco}}
              </span>
              <span *ngIf="applicantGroup.get('postcode')?.hasError('maxlength')">
                {{'ValidationText.MaxLength' | transloco: {maxLength: 10} }}
              </span>
            </span>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="control-label" for="City">Ort {{requiredPropertyIndicator}}</label>
            <input formControlName="city" class="form-control" id="City" name="City" type="text"
              [ngClass]="{'is-invalid': submitted && applicantGroup.get('city')?.errors}">
            <span class="invalid-feedback">
              <span *ngIf="applicantGroup.get('city')?.getError('required')">
                {{'ValidationText.Required' | transloco}}
              </span>
              <span *ngIf="applicantGroup.get('city')?.hasError('maxlength')">
                {{'ValidationText.MaxLength' | transloco: {maxLength: 50} }}
              </span>
            </span>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-6">
          <div class="form-group">
            <label class="control-label" for="Email">{{application.onlineMembership.emailLabel}}
              {{requiredPropertyIndicator}}</label>
            <input formControlName="email" class="form-control" id="Email" name="Email" type="email"
              [ngClass]="{'is-invalid': submitted && applicantGroup.get('email')?.errors}">

            <div *ngIf="application?.onlineMembership?.emailDescription" class="form-text">
              {{application.onlineMembership.emailDescription}}
            </div>

            <span class="invalid-feedback">
              <span *ngIf="applicantGroup.get('email')?.getError('required')">
                {{'ValidationText.Email.Required' | transloco}}
              </span>
              <span *ngIf="applicantGroup.get('email')?.hasError('maxlength')">
                {{'ValidationText.MaxLength' | transloco: {maxLength: 250} }}
              </span>
              <span
                *ngIf="!applicantGroup.get('email')?.getError('required') && applicantGroup.get('email')?.getError('email')">
                {{'ValidationText.Email.Valid' | transloco}}
              </span>
            </span>
          </div>
        </div>
      </div>

      <div class="row mt-3" formGroupName="phoneGroup">
          <div class="col-md-4">
            <div class="form-group">
              <label class="control-label" for="PhonePrivate">{{'PrivatePhone' | transloco }}</label>
              <input formControlName="phonePrivate" class="form-control" id="PhonePrivate" name="PhonePrivate" type="tel"
              [ngClass]="{'is-invalid': phoneNumberIsInvalid('phonePrivate')}"
              >
              <span class="invalid-feedback" *ngIf="submitted && applicantGroup.get('phoneGroup.phonePrivate')?.errors">
                  {{'ValidationText.MaxLength' | transloco: {maxLength: 50} }}
              </span>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="control-label" for="PhoneOffice">{{'BusinessPhone' | transloco }}</label>
              <input formControlName="phoneOffice" class="form-control" id="PhoneOffice" name="PhoneOffice" type="tel"
              [ngClass]="{'is-invalid': phoneNumberIsInvalid('phoneOffice')}"
              >
              <span class="invalid-feedback" *ngIf="submitted && applicantGroup.get('phoneGroup.phoneOffice')?.errors">
                {{'ValidationText.MaxLength' | transloco: {maxLength: 50} }}
              </span>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="control-label" for="Mobile">{{'MobilePhone' | transloco}}</label>
              <input formControlName="mobilePhone" class="form-control" id="Mobile" name="Mobile" type="tel"
              [ngClass]="{'is-invalid': phoneNumberIsInvalid('mobilePhone')}"
              >
              <span class="invalid-feedback" *ngIf="submitted && applicantGroup.get('phoneGroup.mobilePhone')?.errors">
                {{'ValidationText.MaxLength' | transloco: {maxLength: 50} }}
              </span>
            </div>
          </div>
      
        <div class="col-md-12">
          <div [ngClass]="{'is-invalid': submitted && applicantGroup.get('phoneGroup')?.hasError('phoneNumberMissing')}"></div>
          <span class="invalid-feedback">{{'ValidationText.AtLeastOnePhoneNumber' | transloco }}</span>
        </div>
      </div>
    </ng-container>

    <div class="row mt-3">
      <div class="col-md-6">
        <div class="form-group">
          <label class="control-label" for="MembershipValidFromDate">{{'MembershipValidFromDate' | transloco}}
            {{requiredPropertyIndicator}}</label>
          <pmt-datepicker [id]="'MembershipValidFromDate'" formControlName="membershipValidFromDate"
            [ngClass]="{'is-invalid border border-danger rounded': submitted && accountForm.get('membershipValidFromDate')?.errors}"></pmt-datepicker>
          <span class="invalid-feedback">
            <span *ngIf="accountForm.get('membershipValidFromDate')?.hasError('required')">
              {{'ValidationText.Required' | transloco}}
            </span>
            <span *ngIf="accountForm.get('membershipValidFromDate')?.hasError('wrongDate')">
              {{'ValidationText.DateMustNotBeInPast' | transloco }}
            </span>
            <span *ngIf="accountForm.get('membershipValidFromDate')?.hasError('afterNMonths')">
              {{'ValidationText.DateCannotBeAfterNMonths' | transloco:translateParam }}
            </span>
          </span>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-md-6">
        <div class="form-group">
          <label class="control-label">{{'Message' | transloco}}</label>
          <textarea class="form-control" formControlName="commentFromApplicant"></textarea>
        </div>
      </div>
    </div>

    <form [formGroup]="confirmForm" *ngIf="confirmForm" class="mt-3">
      <div class="form-check">
        <input type="checkbox" class="form-check-input" id="acceptCorrectContactDetails" formControlName="acceptCorrectContactDetails"  [ngClass]="{'is-invalid': submitted && confirmForm.get('acceptCorrectContactDetails')?.errors}" />
        <label class="form-check-label" for="acceptCorrectContactDetails">
          {{'YesIAcceptCorrectContactDetails' | transloco}}
        </label>
      </div>
      <div class="form-check">
        <input type="checkbox" id="acceptAvbCheckbox" class="form-check-input" formControlName="acceptAvb" [ngClass]="{'is-invalid': submitted && confirmForm.get('acceptAvb')?.errors}"/>
        <label class="form-check-label" for="acceptAvbCheckbox" [innerHTML]="'YesIAcceptDataPrivacyStatement' | transloco: {url: application.onlineMembership.avbUrl}">
        </label>
      </div>
    </form>

    <div class="well well-sm mt-3">
      <button class="btn btn-default" (click)="back()" [disabled]="isSaving">{{'BackToDetails' | transloco}}</button>
      <button class="btn btn-primary float-end" (click)="submitForm()" *ngIf="!readonly"
        [disabled]="isSaving">{{'HandIn' | transloco}}</button>
    </div>
  </form>
</ng-container>
<app-spinner *ngIf="!accountForm" margin="1rem auto"></app-spinner>
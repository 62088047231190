import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Account } from '../account.model';
import { BaseComponent } from '@premotec/ngx-essentials';
import { AccountService } from '../account.service';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { AccountChangeDataFormComponent } from 'src/app/shared/account-change-data-form/account-change-data-form.component';

@Component({
  selector: 'app-account-change-data',
  templateUrl: './account-change-data.component.html',
  styleUrls: ['./account-change-data.component.scss']
})
export class AccountChangeDataComponent extends BaseComponent implements OnInit {

  @ViewChild('accountChangeDataFromComponent') accountChangeDataFormComponent!: AccountChangeDataFormComponent;

  submitted = false;
  httpError = false;

  constructor(private translocoService: TranslocoService,
              private accountService: AccountService,
              private titleService: Title,
              private router: Router,
              private fb: FormBuilder) {
                super();
              }

  ngOnInit() {
    this.translocoService.selectTranslate('UserData').subscribe(x => {
      this.titleService.setTitle(x);
    });
  }

  sendForm() {
    this.submitted = true;
    this.httpError = false;
    if (this.accountChangeDataFormComponent.getForm().valid) {
      this.whileImAlive(
        this.accountService.updateAccountDetails(this.accountChangeDataFormComponent.getUpdatedUserData())
      ).subscribe(
        () => {
          this.router.navigateByUrl('/my-registrations');
        },
        (error) => {
          console.log(error);
          this.submitted = false;
          this.httpError = true;
        }
      );
    }
  }
}


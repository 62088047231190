import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { RegistrationService } from '../registration.service';
import { RegistrationSummary } from '../registration-summary.model';
import { DiscountGiftCardPut } from 'src/app/shared/models/discount-giftcard/discount-giftcard-put.model';
import { DiscountFormComponent } from 'src/app/shared/discount-form/discount-form.component';
import { EventRegistrationDetail } from 'src/app/events/event-registration-detail.model';
import { RegistrationStates } from '../registration-states.enum';
import { Event } from 'src/app/events/event.model';
import { PriceService } from 'src/app/shared/services/price.service';
import { RegistrationStateService } from '../services/registration-state.service';

@Component({
  selector: 'app-registration-temporary',
  templateUrl: './registration-temporary.component.html',
  styleUrls: ['./registration-temporary.component.scss']
})
export class RegistrationTemporaryComponent implements OnInit {

  @ViewChild(DiscountFormComponent) discountForm!: DiscountFormComponent;

  @Input() registrationSummary!: RegistrationSummary;

  @Input() event!: Event;
  @Output() nextWizardState: EventEmitter<number> = new EventEmitter();

  submitted = false;
  backSubmitted = false;

  statuses: Array<any> = new Array<any>();
  registrationDetails!: EventRegistrationDetail[];

  isPaymentRequired!: boolean;

  constructor(
    private registrationService: RegistrationService,
    private priceService: PriceService,
    private router: Router,
    private registrationStateService: RegistrationStateService
  ) {
  }

  ngOnInit() {
    this.registrationDetails = this.registrationService.getRegistrationDetails(this.event, this.registrationSummary);
    this.isPaymentRequired = this.calculateIfPaymentIsRequired();
  }

  back() {
    this.registrationStateService.stopStatusTimer();
    this.backSubmitted = true;
    this.registrationService.setStateInitial(this.registrationSummary.id!).subscribe(registration => {

      this.backSubmitted = false;
      registration.status = RegistrationStates.UserData; // set to next status userData
      this.nextWizardState.emit(registration.status);
    }),
      () => {
        this.registrationStateService.startStatusChangedTimer(this.registrationSummary.id!, this.registrationSummary.status!);
      }
  }

  calculateIfPaymentIsRequired(): boolean {
    if (this.registrationSummary.invoiceAmount != 0) return true;
    if (this.registrationDetails == null) return false;

    var detailsPrice = this.priceService.calcItemPrices(this.registrationDetails)

    return detailsPrice > 0
  }

  processPayment() {

    this.registrationStateService.stopStatusTimer();
    this.submitted = true;

    const discountGiftCardPutModel = new DiscountGiftCardPut();

    discountGiftCardPutModel.discount = this.discountForm.discount;
    discountGiftCardPutModel.giftCard = this.discountForm.giftCard;

    this.registrationService.setStateStartPayment(this.registrationSummary.id!, discountGiftCardPutModel).subscribe((registration) => {

      this.submitted = false;

      if (registration.status === RegistrationStates.Completed) {
        this.router.navigateByUrl(`/registration/${registration.id}/details`);
      } else {
        this.router.navigateByUrl('/invoice/' + registration.invoiceId + '/payment');
      }
    }, () => {
      this.registrationStateService.startStatusChangedTimer(this.registrationSummary.id!, this.registrationSummary.status!);
    
    });
  }

  processEnrollment() {
    this.registrationStateService.stopStatusTimer();
    this.submitted = true;
    this.registrationService.setStateAcceptFree(this.registrationSummary.id!).subscribe((registration) => {
      this.submitted = false;
      this.router.navigateByUrl(`/registration/${registration.id}/details`);
    }
    ,() => {
      this.registrationStateService.startStatusChangedTimer(this.registrationSummary.id!, this.registrationSummary.status!);
    
    });
  }
}

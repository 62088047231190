import { Component, Input, OnInit } from '@angular/core';
import { RegistrationStates } from '../../events/registration/registration-states.enum';
import { WaitingService } from '../waiting.service';
import { WaitingListItemList } from '../waiting-list-item-list.model';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';


@Component({
  selector: 'app-waiting-list-overview',
  templateUrl: './waiting-list-overview.component.html',
  styleUrls: ['./waiting-list-overview.component.scss']
})
export class WaitingListOverviewComponent implements OnInit {

  waitList!: WaitingListItemList[];
  registrationState = RegistrationStates;

  constructor(private waitingService: WaitingService,
    private translocoService: TranslocoService,
    private titleService: Title,
    private router: Router) {
  }

  ngOnInit() {
    this.translocoService.selectTranslate('MyWaitingList').subscribe(x => {
      this.titleService.setTitle(x);
    });

    this.loadMyRegistrations();
  }

  private loadMyRegistrations() {
    this.waitingService.getMyWaitingList().subscribe((waitings: Array<WaitingListItemList>) => {
      this.waitList = waitings;
    });
  }
}

import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { RegistrationDetail } from '../../../registration-details.model';
import { ControlType } from '../control-type.enum';

@Component({
  selector: 'app-registration-details-view',
  templateUrl: './registration-details-view.component.html',
  styleUrls: ['./registration-details-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RegistrationDetailsViewComponent implements OnInit {

  @Input() registrationDetails!: RegistrationDetail[];
  controlType = ControlType;

  constructor() {
  }

  ngOnInit() {
    this.registrationDetails.sort((a, b) => a.sort - b.sort);
  }

  getDropDownvalue(detail: RegistrationDetail): string {
    let option = detail.dropdownOptions.find(i => i.option == detail.defaultValue);
    if (!option) return "";
    if(option.price == null || option.price == 0) return option.option;
    return `${option.option} (CHF ${Number(option.price).toFixed(2)})`;
  }

  changeCheckboxToSwitch(detail: RegistrationDetail) {
    if (detail.yesPrice != null && detail.yesPrice > 0) return true
    if (detail.noPrice != null && detail.noPrice > 0) return true
    return false;
  }

  stringToDate(dateString: string): Date{
    return new Date(dateString);
  }

}

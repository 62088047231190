<div class="alert alert-danger" *ngIf="showErrorMessage">
  {{'GeneralErrorMsg' | transloco}}
</div>
<div class="alert alert-danger" *ngIf="hasValidationErrors">
  <span [innerHTML]="validationErrorsHtml"></span>
</div>

<div class="row" *ngIf="isLoaded">
  <div class="col-md-12 membership-product-list">
    <!-- Student -->
    <div class="card membership-product">
      <div class="card-header">{{'Students' | transloco}}</div>
      <div class="card-body">
        <div class="content-container">
          <div>
            <span *ngIf="membershipExtensions.studentMembershipExtension.hasStudentMembership">
              {{'Memberships.Extensions.Student.MsgActive' | transloco}}
            </span>
            <ng-container *ngIf="!membershipExtensions.studentMembershipExtension.hasStudentMembership">
              <span>
                {{'Memberships.Extensions.Student.MsgInactive' | transloco}}
              </span>
            </ng-container>
          </div>
          <div>
            <br />
            <button (click)="showInfoModal()" class="btn btn-primary"
              *ngIf="!membershipExtensions.studentMembershipExtension.hasStudentMembership">{{'Memberships.Extensions.LoginWithSWITCHaai' |
              transloco}}</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Employee -->
    <div class="membership-product-spacing"></div>
    <div class="card membership-product">
      <div class="card-header">{{'Memberships.Extensions.Employee.Title' | transloco}}</div>
      <div class="card-body">
        <div class="content-container">
          <div>
            <span *ngIf="!isOnlineMembershipAllowed">
              {{'Memberships.Extensions.Employee.MsgInactive' | transloco}}<br />
              <br />
              Falls du deinen ASVZ-Account gerade eben registriert hast, bitten wir dich um einen Logout und erneuten
              Login. Klicke dazu bitte auf den untenstehenden Logout-Button.
            </span>
            <ng-container *ngIf="isOnlineMembershipAllowed">
              <span><b>{{membershipExtensions.employeeMembershipExtension.description}}</b></span> <br />
              <span *ngIf="membershipExtensions.employeeMembershipExtension.validityInDays % 30 == 0">
                {{membershipExtensions.employeeMembershipExtension.validityInDays / 30}}
                {{'Months' | transloco }} -
                {{membershipExtensions.employeeMembershipExtension.price | currencyFormat}}
              </span>
              <span *ngIf="membershipExtensions.employeeMembershipExtension.validityInDays % 30 != 0">
                {{membershipExtensions.employeeMembershipExtension.validityInDays}}
                {{'Days' | transloco }} -
                {{membershipExtensions.employeeMembershipExtension.price | currencyFormat}}
              </span>
              <br />
              <br />
              <label for="EmployeeMembershipValidFrom">{{ 'ValidStartingFrom' | transloco }}</label>
              <input class="form-control" [disabled]="isCreatingMembership" id="EmployeeMembershipValidFrom"
                [(ngModel)]="defaultEmployeeValidFrom" (ngModelChange)="validFromDateChanged($event)">
            </ng-container>
          </div>
          <div>
            <br />
            <button *ngIf="isOnlineMembershipAllowed" class="btn btn-primary"
              (click)="createMembershipWithValidFrom(membershipExtensions.employeeMembershipExtension.membershipId, defaultEmployeeValidFrom)">
              {{'Create' | transloco }}
            </button>
            <button *ngIf="!isOnlineMembershipAllowed" (click)="showInfoModal()" class="btn btn-primary">{{'Memberships.Extensions.LoginWithSWITCHaai' |
              transloco}}</button>
          </div>
        </div>
      </div>
    </div>
    <!-- Alumni -->
    <div class="membership-product-spacing" *ngIf="membershipExtensions.alumniMembershipExtension.isAllowedForAlumni">
    </div>
    <div class="card membership-product" *ngIf="membershipExtensions.alumniMembershipExtension.isAllowedForAlumni">
      <div class="card-header">{{'Alumni' | transloco}}</div>
      <div class="card-body">
        <div class="content-container">
          <div>
            <span>
              {{'Memberships.Extensions.Alumni.MsgActive' | transloco}}
            </span>
            <div>
              <select class="form-control mt-3 mb-3" [(ngModel)]="selectedAlumniOption"
                [disabled]="isCreatingMembership">
                <option [ngValue]="option.membershipId"
                  *ngFor="let option of membershipExtensions?.alumniMembershipExtension?.membershipExtensionOptions">
                  <span *ngIf="option.validityInDays % 30 == 0">
                    {{option.validityInDays / 30}}
                    {{'Months' | transloco }}
                  </span>
                  <span *ngIf="option.validityInDays % 30 != 0">
                    {{option.validityInDays}}
                    {{'Days' | transloco }}
                  </span>

                  - <span>{{option.price | currency: "CHF"}}</span>
                </option>
              </select>
              <br />
              <label for="AlumniMembershipValidFrom">{{ 'ValidFrom' | transloco }}</label>
              <input class="form-control" id="AlumniMembershipValidFrom" [(ngModel)]="defaultAlumniValidFrom"
                [disabled]="isCreatingMembership" (ngModelChange)="validFromDateChanged($event)">
            </div>
          </div>
          <div>
            <br />

            <button class="btn btn-primary" [disabled]="isCreatingMembership"
              (click)="createMembershipWithValidFrom(selectedAlumniOption, defaultAlumniValidFrom)">
              {{'Create' | transloco }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- RetiredEmployee -->
    <div class="membership-product-spacing"
      *ngIf="membershipExtensions.retiredEmployeeMembershipExtension.isAllowedForRetiredEmployeeMembership"></div>
    <div class="card membership-product"
      *ngIf="membershipExtensions.retiredEmployeeMembershipExtension.isAllowedForRetiredEmployeeMembership">
      <div class="card-header">{{'Retired' | transloco}}</div>
      <div class="card-body">
        <div class="content-container">
          <div>
            <span>
              {{'Memberships.Extensions.Retired.MsgActive' | transloco}}
            </span>
            <div>
              <input *ngIf="membershipExtensions.retiredEmployeeMembershipExtension.membershipExtensionOption.validityInDays % 30 == 0" type="text" class="form-control mt-3 mb-3" [disabled]="true"
                value="{{membershipExtensions.retiredEmployeeMembershipExtension.membershipExtensionOption.validityInDays / 30}} {{'Months' | transloco }} - {{membershipExtensions.retiredEmployeeMembershipExtension.membershipExtensionOption.price | currency: 'CHF'}}" />
              <input *ngIf="membershipExtensions.retiredEmployeeMembershipExtension.membershipExtensionOption.validityInDays % 30 != 0" type="text" class="form-control mt-3 mb-3" [disabled]="true"
                value="{{membershipExtensions.retiredEmployeeMembershipExtension.membershipExtensionOption.validityInDays}} {{'Days' | transloco }} - {{membershipExtensions.retiredEmployeeMembershipExtension.membershipExtensionOption.price | currency: 'CHF'}}" />
              <br />
              <label for="RetiredEmployeeMembershipValidFrom">{{ 'ValidFrom' | transloco }}</label>
              <input class="form-control" id="RetiredEmployeeMembershipValidFrom"
                [(ngModel)]="defaultRetiredEmployeeValidFrom" [disabled]="isCreatingMembership"
                (ngModelChange)="validFromDateChanged($event)">
            </div>
          </div>
          <div>
            <br />

            <button class="btn btn-primary" [disabled]="isCreatingMembership"
              (click)="createMembershipWithValidFrom(selectedRetiredEmployeeOption,defaultRetiredEmployeeValidFrom)">
              {{'Create' | transloco }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Others -->
    <div class="membership-product-spacing"></div>
    <div class="card membership-product">
      <div class="card-header">{{'OtherCategories' | transloco}}</div>
      <div class="card-body">
        <div class="content-container">
          <div>
            <p>{{'Memberships.Extensions.OtherCategories.MsgPart1' | transloco}}</p>
            <p>{{'Memberships.Extensions.OtherCategories.MsgPart2' | transloco}}</p>
          </div>
          <div>
            <a class="btn btn-primary" href=" https://asvz.ch/teilnahmeberechtigung">
              {{'Memberships.Extensions.OtherCategories.LinkText' | transloco }}
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="membership-product-spacing"></div>
  </div>
</div>

<div class="center-block">
  <app-spinner *ngIf="!isLoaded"></app-spinner>
</div>

<div class="row mt-3">
  <div class="col-md-4">
    <a class="btn btn-primary" [routerLink]="['/memberships']">{{'Back' | transloco }}</a>
  </div>
</div>
<div>
  <div class="modal-header">
    <h4 class="modal-title float-start">{{ 'Office.ChangeMembership' | transloco }}</h4>
  </div>
  <div class="modal-body">
    <div class="alert alert-warning" role="alert">
      {{ 'Office.ChangeMembershipNotice' | transloco }}
    </div>
    <form [formGroup]="onlineMembershipForm">
      <div class="form-group" [ngClass]="{'has-error': submitted && onlineMembershipForm.get('membership')?.errors}">
        <label class="control-label" for="Membership">{{'Office.ChooseNewMembership' | transloco}}</label>
        <select class="form-control" formControlName="membership">
          <option *ngFor="let onlineMembership of onlineMemberships" [value]="onlineMembership.imMembershipId">{{
            onlineMembership.nameDe }}</option>
        </select>
        <span class="help-block" *ngIf="submitted && onlineMembershipForm.get('membership')?.errors">
          <span *ngIf="onlineMembershipForm.get('membership')!.getError('required')" class="text-danger">
            {{'ValidationText.Required' | transloco}}
          </span>
        </span>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button class="btn btn-default" type="button" (click)="cancel()">{{ "Cancel" | transloco }}</button>
    <button class="btn btn-primary" type="button" (click)="ok()">{{ "Ok" | transloco }}</button>
  </div>
</div>
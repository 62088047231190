import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { EventRegistrationDetail } from '../../../event-registration-detail.model';
import { FormGroup } from '@angular/forms';
import { ControlType } from '../control-type.enum';
@Component({
  selector: 'app-registration-details-question',
  templateUrl: './registration-details-question.component.html',
  styleUrls: ['./registration-details-question.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RegistrationDetailsQuestionComponent implements OnInit {

  @Input() form!: FormGroup;
  @Input() detail!: EventRegistrationDetail;
  @Input() readOnly!: boolean;

  controlType = ControlType;

  constructor() { }

  ngOnInit() {
  }

  controlHasError(id: number): boolean {
    return (this.form.get(id.toString())!.touched || this.form.get(id.toString())!.dirty) &&
      !!this.form.get(id.toString())!.errors;
  }

  changeCheckboxToSwitch(detail: EventRegistrationDetail) {
    if (detail.yesPrice != null && detail.yesPrice > 0) return true
    if (detail.noPrice != null && detail.noPrice > 0) return true
    return false;
  }

}

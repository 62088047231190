<div class="container">
  <div class="page-header">
    <h1>Fehler</h1>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div style="margin-bottom: 40px;">
        <h2><strong>Es ist ein Fehler aufgetreten.</strong></h2>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-8">
        <p>Bitte entschuldige, beim Laden der gesuchten Website ist ein unvorhergesehener Fehler aufgetreten und wir können
        die Seite daher aktuell nicht anzeigen. Dies kann verschiedene Gründe haben:</p>
      <ul>
        <li>Möglicherweise wurde die URL der Website nicht korrekt eingegeben.</li>
        <li>Vielleicht wurde die Website, die du suchst, verschoben, aktualisiert oder gelöscht.</li>
        <li>Eventuell mussten wir den Inhalt dieser URL löschen.</li>
      </ul>
      <br/>

      <p>Du hast nun folgende Möglichkeiten:</p>
      <ul>
        <li>Vergewissere dich, dass die URL keine Fehler enthält.</li>
        <li>Führe die Aktion erneut aus.</li>
        <li>Schreibe uns eine E-Mail an <a href="mailto:info@asvz.ch">info@asvz.ch</a> und schildere dein Problem unter
          Angabe des von dir verwendeten Browsers (inkl. Browserversion).
        </li>
        <li *ngIf="getRequestId()">
          Bitte erwähne beim Kontakt mit uns die Request-ID <code>{{ getRequestId() }}</code> und den Zeitstempel <code>{{ getRequestDate() | dateFormat: "DD.MM.YYYY HH:mm" }}</code>. 
          Dies vereinfacht uns die Fehlersuche.</li>
      </ul>

      <div style="margin-top: 40px;">
        <a [routerLink]="'/'" class="btn btn-default">Zur Startseite</a>
      </div>
    </div>
    <div class="col-lg-4">
      <img src="assets/img/error/error.jpg" class="img-rounded">
    </div>
  </div>
</div>

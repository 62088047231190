<div class="modal-header text-light">
    <h3 >{{'Tournaments.Events.Add.Title' | transloco}}</h3>
</div>

<div class="modal-body">
    <form *ngIf="tournamentEventForm" [formGroup]="tournamentEventForm">
        <div class="row">
            <div class="col-12 mb-3">
                <label for="eventId" class="form-label">{{'Tournaments.Events.Add.Event' | transloco}}</label>
                <div>
                    <ng-select formControlName="eventId" id="eventId"
                        [ngClass]="{'border-danger':  !tournamentEventForm.controls['eventId'].valid && formSubmitted}">
                        <ng-option *ngFor="let option of tournamentEventListItems" [value]="option.eventId">
                            {{option.eventNumber}} - {{option.eventTitle}} - {{option.category}}
                        </ng-option>
                    </ng-select>
                </div>
                
            </div>
        </div>
    </form>


</div>

<div class="modal-footer text-light">
    <button type="button" class="btn btn-default" (click)="decline()">{{'Cancel' | transloco}}</button>
    <button type="submit" class="btn btn-success" (click)="confirm()">{{'Add' | transloco}}</button>
</div>

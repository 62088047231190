<div class="modal-header">
    {{'Memberships.Extensions.LoginWithSWITCHaai' |
              transloco}}
</div>

<div class="modal-body">
    <p>{{'Memberships.Extensions.InfoModal.MsgPart1' | transloco}}</p>
    <img src="assets/img/infos/switch-login.png" alt="SWITCH Login" class="w-100" />
    <p>{{'Memberships.Extensions.InfoModal.MsgPart2' | transloco}}</p>
</div>

<div class="modal-footer">
    <button (click)="cancel()" class="btn btn-default">{{'Cancel' |
        transloco}}</button>
    <button (click)="logout()" class="btn btn-primary">{{'Memberships.Extensions.LoginWithSWITCHaai' |
    transloco}}</button>
</div>
import { Component, Input } from '@angular/core';
import { TournamentDisplayEventGroupRankingModel } from '../models/tournament-display-event-group-ranking.model';

@Component({
    selector: 'app-tournament-event-group-ranking-table',
    templateUrl: './tournament-event-group-ranking-table.component.html',
    styleUrls: ['./tournament-event-group-ranking-table.component.scss'],
})
export class TournamentEventGroupRankingTableComponent {

    @Input() rankingModel!: TournamentDisplayEventGroupRankingModel;

    constructor() { }
}

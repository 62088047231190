import { Component, OnInit, Input, ViewEncapsulation, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormGroup, FormBuilder, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true
    }
  ]
})
export class CheckboxComponent implements OnInit, ControlValueAccessor {

  @Input() Id!: string;
  @Input() edit!: boolean;
  @Input() readOnly!: boolean;
  conformityForm: FormGroup;
  private _value: any;

  yes: FormControl;

  @Input() set value(value: string) {
    if (value == null) {
      this.setValue('false');
    } else {
      if (typeof value == 'string') {
        if (value.includes('true')) {
          this.setValue('true');
        } else {
          this.setValue('false');
        }
      } else {
        this.setValue('false');
      }
    }
  }
  get value() {
    return this._value;
  }

  constructor(private formBuilder: FormBuilder) {
    this.conformityForm = this.formBuilder.group({
      'IsAdequatequalificationsYes': [''],
    });
    this.yes = <FormControl>this.conformityForm.get('IsAdequatequalificationsYes');
  }

  ngOnInit() {
  }

  setValue(value: string) {
    this._value = value;
    var boolValue = value == 'true'
    this.conformityForm.get('IsAdequatequalificationsYes')?.setValue(boolValue);
    this.propagateChange(this._value);
  }

  propagateChange = (_: any) => { };
  onTouched: any = () => { };

  writeValue(obj: any): void {
    this.value = obj;
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  toggleCheckbox(): void {
    if (this.value.includes('false')) {
      this.setValue('true');
    } else {
      this.setValue('false');
    }
  }

}

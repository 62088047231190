import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { EnrollmentLotteryHeaderModel } from '../models/lottery-header.model';

@Injectable()

export class LotteryService {
    constructor(private httpClient: HttpClient) { }
    
    getLotteryHeaders(): Observable<EnrollmentLotteryHeaderModel[]> {
        return this.httpClient.get<EnrollmentLotteryHeaderModel[]>(`${environment.api}/lotteries`);
    }

    getLotteryItems(headerId: string): Observable<EnrollmentLotteryHeaderModel[]> {
        return this.httpClient.get<EnrollmentLotteryHeaderModel[]>(`${environment.api}/lotteries/${headerId}/items`);
    }
}
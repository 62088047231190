<h3 *ngIf="this.invoiceAmount != this.calcTotalAmount()">{{'PriceCalculation' | transloco}}</h3>
<dl>
  <dd>
    <table>
      <tr *ngIf="this.invoiceAmount != this.calcTotalAmount()">
        <td style="padding-right: 10px;">{{'BasicPrice' | transloco}}: </td>
        <td style="text-align: right;">{{commerciallyRound(invoiceAmount) | currencyFormat}}</td>
      </tr>
      <tr *ngIf="this.calcAdditionalAmmount() > 0">
        <td style="padding-right: 10px;">{{'AdditionalPrice' | transloco}}: </td>
        <td style="text-align: right;">+ {{commerciallyRound(calcAdditionalAmmount()) | currencyFormat}}</td>
      </tr>
      <tr *ngIf="this.calcReductionAmount() > 0">
        <td style="padding-right: 10px;">{{'ReductionPrice' | transloco}}:</td>
        <td style="text-align: right;">- {{commerciallyRound(calcReductionAmount()) | currencyFormat}}</td>
      </tr>
      <tr>
        <td style="padding-right: 10px; font-weight: bold;">{{'TotalPrice' | transloco}}:</td>
        <td style="text-align: right;  font-weight: bold;">{{commerciallyRound(this.calcTotalAmount()) |
          currencyFormat}}</td>
      </tr>
    </table>
  </dd>
</dl>

<dl *ngIf="this.discount && this.discount.valid">
  <dt>{{'Discount.Discount' | transloco}}</dt>
  <dd *ngIf="this.discount.discountAmount && !this.discount.discountPercent">{{ this.discount.invoiceText }} ( -{{
    commerciallyRound(discount.discountAmount) | currencyFormat }})</dd>
  <dd *ngIf="!this.discount.discountAmount && this.discount.discountPercent">{{ this.discount.invoiceText }} ( -{{
    this.discount.discountPercent }}%)</dd>
</dl>

<dl *ngIf="this.giftCard && this.giftCard.isValid">
  <dt>{{'Discount.GiftCard' | transloco}}</dt>
  <dd>{{ this.giftCard.invoiceText }} ({{ 'Discount.OutstandingAmount' | transloco }} {{
    getNewGiftCardOutstandingAmount() | currencyFormat }})</dd>
</dl>


<div class="card"
  *ngIf="(hasDiscount && hasDiscount.hasZkbDiscount && !discount) || (discount && discount.valid && discount.isZkbDiscount)">
  <div class="card-body">
    <dl>
      <dt>{{ 'Discount.ZkbCustomer' | transloco }}</dt>
      <dd>
        <select class="form-control" (change)="zkbCustomerChanged()" [disabled]="this.discount">
          <option value="0" [selected]="!this.zkbCustomer">{{ 'No' | transloco}}</option>
          <option value="1">{{ 'Yes' | transloco}}</option>
        </select>
      </dd>

      <dt *ngIf="this.zkbCustomer">{{ 'Discount.ZkbCardNumber' | transloco }} *</dt>
      <dd *ngIf="this.zkbCustomer">
        <div class="form-group" [ngClass]="{'has-error': this.showZkbCardNumberError}">
          <div class="row">
            <div class="col-md-12">
              <div class="input-group">
                <span *ngIf="this.discount && discount.valid && this.discount.isZkbDiscount"
                  class="input-group-prepend">
                  <i class="fa fa-check-circle checkmark"></i>
                </span>
                <input class="form-control" [(ngModel)]="this.zkbCardNumber" type="text" maxlength="5"
                  (keypress)="stripText($event)" [disabled]="this.discount != null">
                <span class="input-group-append">
                  <button *ngIf="!this.discount" (click)="zkbCardNumberRedeem()" type="button"
                    class="btn btn-primary">{{ 'Discount.Redeem' | transloco }}</button>
                  <button *ngIf="this.discount" (click)="zkbCardNumberRemove()" type="button" class="btn btn-primary">{{
                    'Discount.Remove' | transloco }}</button>
                </span>
              </div>
            </div>
          </div>
          <span class="help-block" *ngIf="this.showZkbCardNumberError">
            <span>
              {{'Discount.InvalidCardNumber' | transloco}}
            </span>
          </span>
        </div>
      </dd>
    </dl>
  </div>
</div>

<div class="card"
  *ngIf="(hasDiscount && hasDiscount.hasDiscount && !discount) || (discount && discount.valid && !discount.isZkbDiscount)">
  <div class="card-body">
    <dl>
      <dt>{{ 'Discount.DiscountCode' | transloco }}</dt>
      <dd>
        <div class="form-group" [ngClass]="{'has-error': this.showDiscountCodeError}">
          <div class="row">
            <div class="col-md-12">
              <div class="input-group">
                <span *ngIf="this.discount && discount.valid && !this.discount.isZkbDiscount" class="input-group-addon">
                  <i class="fa fa-check-circle checkmark"></i>
                </span>
                <input class="form-control" [(ngModel)]="this.discountCode" type="text"
                  [disabled]="this.discount != null">
                <span class="input-group-btn">
                  <button *ngIf="!this.discount" (click)="discountCodeRedeem()" type="button" class="btn btn-primary">{{
                    'Discount.Redeem' | transloco }}</button>
                  <button *ngIf="this.discount" (click)="discountCodeRemove()" type="button" class="btn btn-primary">{{
                    'Discount.Remove' | transloco }}</button>
                </span>
              </div>
            </div>
          </div>
          <span class="help-block" *ngIf="this.showDiscountCodeError">
            <span>
              {{'Discount.InvalidDiscountCode' | transloco}}
            </span>
          </span>
        </div>
      </dd>
    </dl>
  </div>
</div>

<div class="card" *ngIf="hasDiscount && hasDiscount.giftCardEnabled">
  <div class="card-body">
    <dl>
      <dt>{{ 'Discount.GiftCard' | transloco }}</dt>
      <dd>
        <div class="form-group" [ngClass]="{'has-error': this.showGiftCardError}">
          <div class="row">
            <div class="col-md-12">
              <div class="input-group">
                <span *ngIf="this.giftCard && giftCard.isValid" class="input-group-addon">
                  <i class="fa fa-check-circle checkmark"></i>
                </span>
                <input class="form-control" [(ngModel)]="this.giftCardCode" type="text"
                  [disabled]="this.giftCard != null">
                <span class="input-group-btn">
                  <button *ngIf="!this.giftCard" (click)="giftCardRedeem()" type="button"
                    class="btn btn-primary float-end">{{ 'Discount.Redeem' | transloco }}</button>
                  <button *ngIf="this.giftCard" (click)="giftCardRemove()" type="button"
                    class="btn btn-primary float-end">{{ 'Discount.Remove' | transloco }}</button>
                </span>
              </div>
            </div>
          </div>
          <span class="help-block" *ngIf="this.showGiftCardError">
            <span>
              {{'Discount.InvalidGiftCard' | transloco}}
            </span>
          </span>
        </div>
      </dd>
    </dl>
  </div>
</div>
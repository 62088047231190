import { Directive, ElementRef, Input, } from '@angular/core';

@Directive({
  selector: '[appLoadingButton]',
})
export class LoadingButtonDirective {

  buttonWidth: number = 0;
  buttonHeigth: number = 0;
  content: string | null = null;
  constructor(
    private el:ElementRef
  ) {
  }

  @Input() set loading(condition: boolean) {
    if (condition === true) {
      this.buttonWidth = this.el.nativeElement.offsetWidth;
      this.buttonHeigth = this.el.nativeElement.offsetHeight;
      this.content = this.el.nativeElement.innerHTML;
      this.el.nativeElement.innerHTML = 
      `<div class="spinner-border spinner-border-sm" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>`;
      this.el.nativeElement.style.width = this.buttonWidth + 'px';
      this.el.nativeElement.style.height = this.buttonHeigth + 'px';
      this.el.nativeElement.disabled = true;
    } else {
      if (this.content != null) {
        this.el.nativeElement.innerHTML = this.content;
        this.el.nativeElement.disabled = false;
      }
    }
  }
}

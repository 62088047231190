import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { MembershipService } from '../../membership.service';
import { BaseComponent } from '@premotec/ngx-essentials';
import { MembershipExtensionTypes, MembershipExtension } from '../../models';
import { MembershipType } from './membership-type.enum';
import { MembershipState } from '../../membership-state.enum';
import { Router } from '@angular/router';
import { UserAuthorizationService } from '../../../security/user-authorization.service';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { NoMembershipStartAfterNMonths } from '../../models/no-membership-start-after-n-months.model';
import { TranslocoService } from '@ngneat/transloco';
import { BsModalService } from 'ngx-bootstrap/modal';
import { MembershipProductsInfoModalComponent } from '../membership-products-info-modal/membership-products-info-modal.component';

@Component({
  selector: 'app-membership-products',
  templateUrl: './membership-products.component.html',
  styleUrls: ['./membership-products.component.scss']
})
export class MembershipProductsComponent extends BaseComponent implements OnInit {

  @Input() membershipExtensions!: MembershipExtensionTypes;
  @Output() validFromDateChangedEvent: EventEmitter<Date> = new EventEmitter();

  isLoaded = false;
  isCreatingMembership = false;

  membershipState = MembershipState;
  selectedAlumniOption!: number;
  selectedRetiredEmployeeOption!: number;
  membershipType = MembershipType;

  isOnlineMembershipAllowed!: boolean;
  changeStartDateNoticeParam: any;

  showErrorMessage = false;
  hasValidationErrors = false;
  validationErrorsHtml = '';

  defaultEmployeeValidFrom!: string;
  defaultAlumniValidFrom!: string;
  defaultRetiredEmployeeValidFrom!: string;

  switchLoginUrl = `${environment.identity.authority}?returnUrl=${environment.localSite}/memberships/new`;

  noMembershipStartAfterNMonths!: NoMembershipStartAfterNMonths;

  constructor(
    private membershipService: MembershipService,
    private authenticationService: UserAuthorizationService,
    private translocoService: TranslocoService,
    private router: Router,
    private bsModalService: BsModalService) {
    super();
  }

  ngOnInit() {
    if (this.membershipExtensions.alumniMembershipExtension.membershipExtensionOptions && this.membershipExtensions.alumniMembershipExtension.membershipExtensionOptions.length) {
      this.membershipExtensions.alumniMembershipExtension.membershipExtensionOptions.sort((a, b) => {
        return a.validityInDays - b.validityInDays;
      });

      this.selectedAlumniOption = this.membershipExtensions.alumniMembershipExtension.membershipExtensionOptions[0].membershipId;
    }

    if (this.membershipExtensions.retiredEmployeeMembershipExtension.membershipExtensionOption) {
      this.selectedRetiredEmployeeOption = this.membershipExtensions.retiredEmployeeMembershipExtension.membershipExtensionOption.membershipId;
    }

    // get NoMembershipStartAfterNMonths from the configuration
    this.whileImAlive(this.membershipService.getNoMembershipStartAfterNMonths()).subscribe(
      (noMembershipStartAfterNMonths) => {
        this.noMembershipStartAfterNMonths = noMembershipStartAfterNMonths;
      },
      (errResponse) => {
        this.showErrorMessage = true;
      }
    );

    this.isLoaded = true;
    this.isOnlineMembershipAllowed = this.membershipExtensions.employeeMembershipExtension.isOnlineMembershipAllowed;

    if(this.changeStartDateNoticeParam === null || this.changeStartDateNoticeParam === undefined) {
      this.changeStartDateNoticeParam = {
        'currentStartFromDate': moment(this.membershipExtensions.proposedValidFromDate).format('DD.MM.YYYY'),
      };
    }

    if(this.defaultEmployeeValidFrom === null || this.defaultEmployeeValidFrom === undefined) {
      this.defaultEmployeeValidFrom = moment(this.membershipExtensions.proposedValidFromDate).format('DD.MM.YYYY');
    }
    if(this.defaultAlumniValidFrom === null || this.defaultAlumniValidFrom === undefined) {
      this.defaultAlumniValidFrom = moment(this.membershipExtensions.proposedValidFromDate).format('DD.MM.YYYY');
    }
    if(this.defaultRetiredEmployeeValidFrom === null || this.defaultRetiredEmployeeValidFrom === undefined) {
      this.defaultRetiredEmployeeValidFrom = moment(this.membershipExtensions.proposedValidFromDate).format('DD.MM.YYYY');
    }
  }

  showInfoModal(): void {
    this.bsModalService.show(MembershipProductsInfoModalComponent);
  }

  createMembershipWithValidFrom(membershipId: any, validFrom: any): void {
    this.isCreatingMembership = true;

    const validFromDate = moment(validFrom, 'DD.MM.YYYY').format('YYYY-MM-DD');

    this.whileImAlive(this.membershipService.createMembershipWithValidFrom(membershipId, validFromDate)).subscribe(
      (membership) => {
        this.router.navigateByUrl(`/memberships/${membership.id}/wizard`);
      },
      (errResponse) => {
        if (errResponse.status === 422) {
          console.log('Create Error:', errResponse);
          // Show the specific validation error message
          this.hasValidationErrors = true;
          this.setValidationMessages(errResponse.error.errors);
        } else {
          // Show a general error message
          this.showErrorMessage = true;
        }
      }).add(() => this.isCreatingMembership = false);
  }

  setValidationMessages(errors: any[]): void {
    this.validationErrorsHtml = '';

    errors.forEach((err) => {

      if (err.message === 'MembershipExtension.ValidNoMembershipStartAfterNMonths') {
        this.validationErrorsHtml += this.translocoService.translate(err.message, { 'noMembershipStartAfterNMonths': this.noMembershipStartAfterNMonths.noMembershipStartAfterNMonths }) + '<br/>';
        return;
      }
      this.validationErrorsHtml += this.translocoService.translate(err.message) + '<br/>';
    })
  }

  validFromDateChanged(date: any): void {
    this.validFromDateChangedEvent.emit(date);
  }
}

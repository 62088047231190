<!-- Action messages -->
<div class="row">
    <div class="col-sm-12">
        <div class="alert alert-success" *ngIf="showEnrollSuccessMsg">{{'LessonMessages.EnrollSuccessMsg' |
            transloco}}
        </div>
        <div class="alert alert-danger" *ngIf="showEnrollErrorMsg">{{'LessonMessages.EnrollErrorMsg' | transloco}}
        </div>
        <div class="alert alert-primary" *ngIf="showWithdrawSuccessMsg">{{'LessonMessages.WithdrawSuccessMsg' |
            transloco}}
        </div>
        <div class="alert alert-danger" *ngIf="showWithdrawErrorMsg">{{'LessonMessages.WithdrawErrorMsg' |
            transloco}}
        </div>
        <div class="alert alert-danger" *ngIf="showLotteryErrorMsg">
            <span class="ms-1">{{'LessonMessages.LotteryBadLuckMsg' | transloco}}</span>
        </div>
        <div class="alert alert-danger" *ngIf="validationError">{{validationError}}</div>
    </div>
</div>
<!-- Action messages end -->
<aside class="footer-section-sponsors">
    <div class="container">
        <h2 class="sponsor-title">ASVZ-Sponsoren</h2>

        <div class="row">

            <div class="col-xl-6 sponsor">
                <a href="https://www.craftsportswear.ch" target="_blank">
                    <div class="sponsor-box-container" title="Craft" data-toggle="tooltip" data-original-title="Craft">
                        <div class="sponsor-box-image sponsor-box-image-craft">
                        </div>
                    </div>
                </a>
            </div>

            <div class="col-xl-6 sponsor">
                <a href="https://www.zkb.ch" target="_blank">
                    <div class="sponsor-box-container" title="Z&uuml;rcher Kantonalbank" data-toggle="tooltip"
                        data-original-title="Z&uuml;rcher Kantonalbank">
                        <div class="sponsor-box-image sponsor-box-image-zkb">
                        </div>
                    </div>
                </a>
            </div>

        </div>
    </div>
</aside>
import { Routes } from '@angular/router';
import { ImpressumComponent } from './impressum/impressum.component';
import { FaqComponent } from './faq/faq.component';

export let routes: Routes = [
  {
     path: 'impressum',
    component: ImpressumComponent
  },
  {
    path: 'faq', component: FaqComponent
  }
];

<ng-container>
  <div>
    <div class="sidebar" pmtAutoWindowHeight [marginTop]="65">
      <app-users-list></app-users-list>
    </div><!-- /sidebar -->

    <div class="sidebar-content" pmtAutoWindowHeight [marginTop]="65" style="padding: 16px;" >
      <router-outlet></router-outlet>
    </div><!-- /content -->
  </div>
</ng-container>

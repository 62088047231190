import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { BaseComponent } from '@premotec/ngx-essentials';
import { ToastrService } from 'ngx-toastr';
import { Account } from 'src/app/account/account.model';
import { AccountService } from 'src/app/account/account.service';

@Component({
  selector: 'app-change-email-modal',
  templateUrl: './change-email-modal.component.html',
  styleUrls: ['./change-email-modal.component.scss']
})
export class ChangeEmailModalComponent extends BaseComponent implements OnInit {

  @Input() account!: Account;

  @Output() closeModalEvent: EventEmitter<null> = new EventEmitter<null>();
  @Output() saveModalEvent: EventEmitter<null> = new EventEmitter<null>();

  emailForm!: FormGroup;
  userEmail!: string;

  emailAddressChanged: boolean = false;
  isSaving: boolean = false;
  isSubmitted: boolean = false;

  constructor(
    private fb: FormBuilder,
    private accountService: AccountService,
    private toastrService: ToastrService,
    private translocoService: TranslocoService
    ) { 
      super();
    }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    if (this.account.emailAsvz || this.account.emailAai) {
      this.userEmail = this.account.emailAsvz || this.account!.emailAai!;
    } else {
      this.userEmail = this.account.emailPrivate!;
    }

    this.emailForm = this.fb.group({
      email: [this.userEmail , [Validators.required, Validators.email, Validators.maxLength(250)]]
    });
  }

  close(): void {
    this.closeModalEvent.emit();
  }

  changeEmail(): void {
    this.isSubmitted = true;
    
    if(this.emailForm.valid) {

      if(this.emailForm.get('email')!.value == this.userEmail) {
        this.emailForm.get('email')!.setErrors({sameEmail: true})
        return;
      }

      this.isSaving = true;

      this.accountService.updateEmail(this.emailForm.get('email')!.value).subscribe(result => {
        this.isSaving = false;
        this.emailAddressChanged = true;
      },() => {
        this.isSaving = false;
        this.toastrService.error(this.translocoService.translate('Msg.SaveError'));
      });
    }
  }
}

<dl *ngIf="lesson.number">
  <dt>{{'Number' | transloco}}</dt>
  <dd>{{lesson.number}}</dd>
</dl>
<dl *ngIf="lesson.sportName">
  <dt>{{'Sportform' | transloco}}</dt>
  <!-- TODO: Get the url from adress object -->
  <dd><a href="{{lesson.sportUrl}}" target="_blank">{{lesson.sportName}}</a></dd>
</dl>

<dl *ngIf="lesson.title">
  <dt>{{'Title' | transloco}}</dt>
  <dd>{{lesson.title}}</dd>
</dl>
<dl *ngIf="lesson.starts">
  <dt>{{'DateTime' | transloco}}</dt>
  <dd>{{lesson.starts | fromToDate: lesson.ends : 'DD.MM.YYYY': false}}</dd>
</dl>

<dl *ngIf="lesson.facilities?.length">
  <dt>{{'Facility_Facility' | transloco}}</dt>
  <dd *ngFor="let facility of lesson.facilities" class="mb-0">
    <span *ngIf="facility.url"><a [href]="facility.url" target="_blank">{{facility.name}}</a></span>
    <span *ngIf="!facility.url">{{facility.name}}</span>
  </dd>
</dl>

<dl *ngIf="lesson.rooms?.length">
  <dt>{{'Room' | transloco}}</dt>
  <dd *ngFor="let room of lesson.rooms" class="mb-0">
    {{room}}
  </dd>
</dl>

<dl *ngIf="lesson.location">
  <dt>{{'Place' | transloco}}</dt>
  <dd>{{lesson.location}}</dd>
</dl>

<!-- Meeting point -->
<dl *ngIf="lesson.meetingPointInfo">
  <dt>{{'Meetingpoint' | transloco}}</dt>
  <dd>
    <span *ngIf="!lesson.meetingPointCoordinates">{{lesson.meetingPointInfo}}</span>
    <a *ngIf="lesson.meetingPointCoordinates"
      [href]="'https://www.google.com/maps/search/?api=1&query='+lesson.meetingPointCoordinates"
      target="_blank">{{lesson.meetingPointInfo}}</a>
  </dd>
</dl>

<!-- seats display -->
<dl *ngIf="canShowSeatingInfo">
  <dt>{{'FreePlaces' | transloco}}</dt>
  <dd>
    <span class="badge bg-secondary" style="font-size: 14px; line-height: 1.1;"
      *ngIf="!lesson.participantsMax && lesson.participantsMax != 0">{{'Unlimited' | transloco}}</span>
    <ng-container>
      <span class="badge bg-secondary" style="font-size: 14px; line-height: 1.1;"
        *ngIf="lesson.participantsMax">{{lesson.participantsMax - lesson.participantCount}}</span>
      <i style="font-size: 15px;" *ngIf="lesson.participantsMin && getRemainingMinimumParticipants() > 0">
        <br />{{'SeatsRemaining' | transloco: {seats: getRemainingMinimumParticipants()} }}
      </i>
    </ng-container>
  </dd>
</dl>

<dl *ngIf="lesson.levelInfo">
  <dt>{{'Level' | transloco}}</dt>
  <dd>{{lesson.levelInfo}}</dd>
</dl>

<dl *ngIf="lesson.languageInfo">
  <dt>{{'LanguageInfo' | transloco}}</dt>
  <dd>{{lesson.languageInfo}}</dd>
</dl>



<dl *ngIf="lesson.instructors?.length || lesson.tlComment">
  <dt>{{'Instructors' | transloco}}</dt>
  <div *ngIf="lesson.instructors?.length" class="mb-3">
    <dd *ngFor="let instructor of lesson.instructors" class="mb-0">
      {{instructor.name}}
    </dd>
  </div>

  <i *ngIf="lesson.tlComment">
    {{'TlComment' | transloco}}: {{lesson.tlComment}}
  </i>
</dl>

<dl *ngIf="lesson.enrollmentEnabled">
  <dt>{{'EnrollmentPeriod' | transloco}}</dt>
  <dd>{{lesson.enrollmentFrom | fromToDate: lesson.enrollmentUntil : '': false}}</dd>
</dl>
<dl *ngIf="lesson.cancelationUntil">
  <dt>{{'CancelationUntil' | transloco}}</dt>
  <dd>
    {{lesson.cancelationUntil | dateFormat: "DD.MM.YYYY HH:mm"}}
  </dd>
</dl>
<dl>
  <dt>{{'Insurance' | transloco}}</dt>
  <dd>{{'InsuranceDescription' | transloco}}</dd>
</dl>
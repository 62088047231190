<div class="container">
    <ng-container *ngIf="!isLoading">
        <div class="row">
            <div class="col-md-12">
                <app-status-workflow [statuses]="statuses"></app-status-workflow>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <app-membership-products *ngIf="!membershipExtension.status"
                    [membershipExtensions]="membershipExtensions"
                    (validFromDateChangedEvent)="validFromDateChangedEvent($event)"></app-membership-products>
                <app-membership-check-pass-photo *ngIf="membershipExtension.status === membershipState.PassPhoto"
                    [membershipExtension]="membershipExtension" (nextState)="updateWizardState($event)">
                </app-membership-check-pass-photo>
                <app-membership-check-user-data *ngIf="membershipExtension.status === membershipState.New"
                    [membershipExtension]="membershipExtension" (nextState)="updateWizardState($event)">
                </app-membership-check-user-data>
                <app-membership-temporary *ngIf="membershipExtension.status === membershipState.Temporary"
                    [membershipExtensions]="membershipExtensions" [membershipExtension]="membershipExtension"
                    (nextState)="updateWizardState($event)"
                    (updateMembershipExtension)="updateMembershipExtension($event)"></app-membership-temporary>
            </div>
        </div>
    </ng-container>
    <app-spinner *ngIf="isLoading" margin="100px auto"></app-spinner>

    <div class="alert-primary alert" role="alert" *ngIf="isLoading">
        {{ 'MembershipExtension.LoadingProducts' | transloco }}
    </div>
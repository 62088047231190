<div class="container">
    <div class="row">
        <div class="col-12 p-2 mb-3 bg-primary d-flex flex-column flex-md-row gap-1">
            <div class="flex-grow-1 m-0">
                <h3 class="text-light">Gruppe {{rankingModel.groupPrefix}}{{rankingModel.groupNumber}}
                </h3>
            </div>
            <div>
                <a class="btn btn-default w-100"
                    *ngIf="tournamentEvent && tournamentEvent.additionLink && tournamentEvent.additionLinkTitle"
                    [href]="tournamentEvent.additionLink">{{tournamentEvent.additionLinkTitle}}
                </a>
            </div>
            <div>
                <a class="btn btn-default w-100"
                    [routerLink]="'/tournaments/'+tournamentPlanId+'/events/'+teamDataModel.tournamentEventId+'/games'">{{'Tournaments.LinksToPages.Games' | transloco}}
                </a>
            </div>
            <div>
                <a class="btn btn-default w-100"
                    [routerLink]="'/tournaments/'+tournamentPlanId+'/availableSlots'">{{'Tournaments.LinksToPages.AvailableSlots' | transloco}}
                </a>
            </div>
            <div>
                <a class="btn btn-default w-100"
                    [routerLink]="'/tournaments/'+tournamentPlanId+'/events/'+teamDataModel.tournamentEventId+'/groups'">{{'Tournaments.LinksToPages.Groups' | transloco}}</a>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6 col-sm-12 mb-3">
            <div>
                <app-tournament-team-data></app-tournament-team-data>
            </div>
            <div class="mt-3">
                <div class="card border-bottom-0">
                    <div class="card-header">
                        <div class="d-flex">
                            <div class="flex-grow-1">
                                <b>{{'Tournaments.Teams.TeamRanking.Title' | transloco}}</b>
                            </div>
                            <div *ngIf="rankingModel">
                                <b>{{'Tournaments.Teams.TeamRanking.Group' | transloco}}
                                    {{rankingModel.groupPrefix}}{{rankingModel.groupNumber}}</b>
                            </div>
                        </div>
                    </div>
                    <div class="card-body p-0 border-0" *ngIf="rankingModel">
                        <app-tournament-event-group-ranking-table
                            [rankingModel]="rankingModel"></app-tournament-event-group-ranking-table>
                    </div>
                    <div class="card-body border-bottom" *ngIf="!rankingModel">
                        <app-spinner></app-spinner>
                    </div>
                </div>
            </div>
            <div class="mt-3" *ngIf="tournamentData?.useTeamAsReferee">
                <app-tournament-team-referee-games></app-tournament-team-referee-games>
            </div>
        </div>
        <div class="col-lg-6 col-sm-12 mb-3">
            <app-tournament-team-games>
            </app-tournament-team-games>
        </div>
    </div>
</div>

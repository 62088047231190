<div class="modal-header text-light">
    <h3>{{'Tournaments.Events.Mapping.Mapp' | transloco}}</h3>
</div>

<div class="modal-body">
    <form *ngIf="eventDataMappingForm" [formGroup]="eventDataMappingForm">
        <div class="row">

            <div class="col-6">
                <div class="row">
                    <div class="col-12 mb-3">
                        <label for="teamName" class="form-label">
                            {{'Tournaments.Events.Mapping.Fields.TeamName' | transloco}} *
                        </label>
                        <div>
                            <ng-select formControlName="teamName" id="teamName"
                                [ngClass]="{'border-danger':  !eventDataMappingForm.controls['teamName'].valid && formSubmitted}">
                                <ng-option *ngFor="let option of generalMappingFields" [value]="option.imId">
                                    {{option.imName}}
                                </ng-option>
                            </ng-select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 mb-3">
                        <label for="teamCaptainPhone" class="form-label">
                            {{'Tournaments.Events.Mapping.Fields.CaptainPhone' | transloco}}
                        </label>
                        <div>
                            <ng-select formControlName="teamCaptainPhone" id="teamCaptainPhone"
                                [ngClass]="{'border-danger':  !eventDataMappingForm.controls['teamCaptainPhone'].valid && formSubmitted}">
                                <ng-option *ngFor="let option of generalMappingFields" [value]="option.imId">
                                    {{option.imName}}
                                </ng-option>
                            </ng-select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 mb-3">
                        <label for="teamCaptainName" class="form-label">
                            {{'Tournaments.Events.Mapping.Fields.CaptainName' | transloco}}
                        </label>
                        <div>
                            <ng-select formControlName="teamCaptainName" id="teamCaptainName"
                                [ngClass]="{'border-danger':  !eventDataMappingForm.controls['teamCaptainName'].valid && formSubmitted}">
                                <ng-option *ngFor="let option of generalMappingFields" [value]="option.imId">
                                    {{option.imName}}
                                </ng-option>
                            </ng-select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 mb-3">
                        <label for="addition" class="form-label">{{'Tournaments.Events.Mapping.Fields.AdditionalField' |
                            transloco}}</label>
                        <div>
                            <ng-select formControlName="addition" id="addition"
                                [ngClass]="{'border-danger':  !eventDataMappingForm.controls['addition'].valid && formSubmitted}">
                                <ng-option *ngFor="let option of generalMappingFields" [value]="option.imId">
                                    {{option.imName}}
                                </ng-option>
                            </ng-select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 mb-3">
                        <label for="additionName"
                            class="form-label">{{'Tournaments.Events.Mapping.Fields.AdditionalFieldName' |
                            transloco}}</label>
                        <div>
                            <input type="text" class="form-control" formControlName="additionName" id="additionName"
                                [ngClass]="{'border-danger':  eventDataMappingForm.controls['addition'].value != null && eventDataMappingForm.controls['additionName'].value == '' && formSubmitted}">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-6" style="max-height: 75vh; overflow-y: auto;">
                <div>
                    <div class="row">
                        <div class="col-12">
                            <app-tournament-event-data-mapping-slot-form [slotGroup]="monSlotGroup"
                                [formSubmitted]="formSubmitted" [dayMappingFields]="dayMappingFields"
                                [slots]="mapping.mondaySlots" [dayOfWeek]="1" [fullForm]="eventDataMappingForm"
                                [slotName]="'Tournaments.Events.Mapping.Fields.SlotMon' | transloco">
                            </app-tournament-event-data-mapping-slot-form>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <app-tournament-event-data-mapping-slot-form [slotGroup]="tueSlotGroup"
                                [formSubmitted]="formSubmitted" [dayMappingFields]="dayMappingFields"
                                [slots]="mapping.tuesdaySlots" [dayOfWeek]="2" [fullForm]="eventDataMappingForm"
                                [slotName]="'Tournaments.Events.Mapping.Fields.SlotTue' | transloco">
                            </app-tournament-event-data-mapping-slot-form>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <app-tournament-event-data-mapping-slot-form [slotGroup]="wedSlotGroup"
                                [formSubmitted]="formSubmitted" [dayMappingFields]="dayMappingFields"
                                [slots]="mapping.wednesdaySlots" [dayOfWeek]="3" [fullForm]="eventDataMappingForm"
                                [slotName]="'Tournaments.Events.Mapping.Fields.SlotWed' | transloco">
                            </app-tournament-event-data-mapping-slot-form>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <app-tournament-event-data-mapping-slot-form [slotGroup]="thuSlotGroup"
                                [formSubmitted]="formSubmitted" [dayMappingFields]="dayMappingFields"
                                [slots]="mapping.thursdaySlots" [dayOfWeek]="4" [fullForm]="eventDataMappingForm"
                                [slotName]="'Tournaments.Events.Mapping.Fields.SlotThu' | transloco">
                            </app-tournament-event-data-mapping-slot-form>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <app-tournament-event-data-mapping-slot-form [slotGroup]="friSlotGroup"
                                [formSubmitted]="formSubmitted" [dayMappingFields]="dayMappingFields"
                                [slots]="mapping.fridaySlots" [dayOfWeek]="5" [fullForm]="eventDataMappingForm"
                                [slotName]="'Tournaments.Events.Mapping.Fields.SlotFri' | transloco">
                            </app-tournament-event-data-mapping-slot-form>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <app-tournament-event-data-mapping-slot-form [slotGroup]="satSlotGroup"
                                [formSubmitted]="formSubmitted" [dayMappingFields]="dayMappingFields"
                                [slots]="mapping.saturdaySlots" [dayOfWeek]="6" [fullForm]="eventDataMappingForm"
                                [slotName]="'Tournaments.Events.Mapping.Fields.SlotSat' | transloco">
                            </app-tournament-event-data-mapping-slot-form>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <app-tournament-event-data-mapping-slot-form [slotGroup]="sunSlotGroup"
                                [formSubmitted]="formSubmitted" [dayMappingFields]="dayMappingFields"
                                [slots]="mapping.wednesdaySlots" [dayOfWeek]="0" [fullForm]="eventDataMappingForm"
                                [slotName]="'Tournaments.Events.Mapping.Fields.SlotSun' | transloco">
                            </app-tournament-event-data-mapping-slot-form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>

</div>

<div class="modal-footer text-light">
    <button type="button" class="btn btn-default" (click)="decline()">{{'Cancel' | transloco}}</button>
    <button type="submit" class="btn btn-success" (click)="confirm()">{{'Save' | transloco}}</button>
</div>
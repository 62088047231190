import { NgModule, InjectionToken } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes, ActivatedRouteSnapshot } from '@angular/router';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { AuthCallbackComponent } from './security/auth-callback/auth-callback.component';
import { AccountMainLayoutComponent } from './account/account-main-layout/account-main-layout.component';
import { AuthGuard, OfficeAuthGuard } from './security/guards';
import { MembershipsModule } from './memberships/memberships.module';
import { LogoutComponent } from './security/logout/logout.component';
import { PageNoPermissionComponent } from './shared/page-no-permission/page-no-permission.component';
import { PageServerErrorComponent } from './shared/page-server-error/page-server-error.component';
import { EmptyComponent } from './shared/empty/empty.component';
import { OfficeModule } from './office/office.module';
import { MembershipApplicationModule } from './membership-application/membership-application.module';
import { LessonsModule } from './lessons/lessons.module';
import { RegistrationsModule } from './registrations/registrations.module';
import { EventsModule } from './events/events.module';
import { HandHeldScannerModule } from './handheldscanner/handheldscanner.module';
import { TournamentsModule } from './tournaments/tournaments.module';
import { FacilityManagementAuthGuard } from './security/guards/facility-management-auth-guard.service';

export const externalUrlProvider = new InjectionToken('externalUrlRedirectResolver');

const sharedRoutes: Routes = [
  {
    path: 'login',
    component: AuthCallbackComponent,
  },
  {
    path: 'office',
    loadChildren: () => import('./office/office.module').then(m => OfficeModule),
    canActivate: [OfficeAuthGuard], // role office 
    canActivateChild: [OfficeAuthGuard], //  role office
  },
  {
    path: 'handheldscanner',
    loadChildren: () => import('./handheldscanner/handheldscanner.module').then(m => HandHeldScannerModule),
    canActivate: [FacilityManagementAuthGuard], // role hausdienst and office
  },
  {
    path: 'information-screen/:id',
    loadChildren: () => import('./information-screen/information-screen.module').then(m => m.InformationScreenModule),
  },
  {
    path: 'occupancyplanview/:occupancyPlanViewId',
    loadChildren: () => import('./occupancy-plan-view/occupancy-plan-view.module').then(m => m.OccupancyPlanViewModule),
  },
  {
    path: 'account',
    component: AccountMainLayoutComponent,
    canActivate: [AuthGuard],
    data: { tab: 0 },
    loadChildren: () => import('./account/account.module').then(m => m.AccountModule),
  },
  {
    path: 'memberships',
    loadChildren: () => import('./memberships/memberships.module').then(m => MembershipsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'membership-application',
    loadChildren: () => import('./membership-application/membership-application.module').then(m => MembershipApplicationModule),
  },
  { 'path': 'my-registrations', redirectTo: '/registration/my', pathMatch: 'full' },
  {
    path: 'registration',
    loadChildren: () => import('./registrations/registrations.module').then(m => RegistrationsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'events',
    loadChildren: () => import('./events/events.module').then(m => EventsModule),
  },
  {
    path: 'tournaments',
    loadChildren: () => import('./tournaments/tournaments.module').then(m => TournamentsModule),
  },
  {
    path: 'lessons',
    loadChildren: () => import('./lessons/lessons.module').then(m => LessonsModule),
  },
  { 'path': 'my-lessons', redirectTo: 'lessons/my' },
  { 'path': 'notFound', component: PageNotFoundComponent },
  { 'path': 'error', component: PageServerErrorComponent },
  { 'path': 'forbidden', component: PageNoPermissionComponent },
  { 'path': 'logout', component: LogoutComponent },
  {
    path: 'externalRedirect',
    resolve: {
      url: externalUrlProvider,
    },
    component: EmptyComponent,
  },
  { 'path': '', redirectTo: '/memberships', pathMatch: 'full' },

  { 'path': '**', redirectTo: 'notFound' },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(sharedRoutes),
  ],
  providers: [
    {
      provide: externalUrlProvider,
      useValue: goToLink,
    },
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }

export function goToLink(route: ActivatedRouteSnapshot) {
  const externalUrl = route.paramMap.get('externalUrl')!;
  window.open(externalUrl, '_self');
}

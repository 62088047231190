import { Component, Input, OnInit } from '@angular/core';
import { Event } from '../event.model';
import { PriceItem } from '../price-item.model';
import { Prices } from '../prices.enum';
import { UserAuthorizationService } from '../../security/user-authorization.service';
import { DisplayPriceType } from '../display-price-type.enum';
import { WebRegistrationType } from 'src/app/lessons/enums/web-registration-type.enum';

@Component({
  selector: 'app-event-properties-display',
  templateUrl: './event-properties-display.component.html',
  styleUrls: ['./event-properties-display.component.scss']
})
export class EventPropertiesDisplayComponent implements OnInit {
  studentsPriceId = Prices.STUDENTS;
  employeePriceId = Prices.EMPLOYEE;
  alumniPriceId = Prices.ALUMNI;
  externPriceId = Prices.EXTERN;

  displayPriceType = DisplayPriceType;

  showNumber: boolean; // number should just be visible for office and admin
  canShowSeatingInfo = true;

  @Input() event!: Event;

  @Input() additionalPrice!: number;

  constructor(authenticationService: UserAuthorizationService) {
    const currentUser = authenticationService.getUser();
    this.showNumber = currentUser.hasRoleAdmin() || currentUser.hasRoleOffice();
  }

  ngOnInit() {
    this.event.instructors.sort(this.sortInstructorsByName);
    this.canShowSeatingInfo = (this.event.webRegistrationType !== WebRegistrationType.NoRegistration);
  }

  sortInstructorsByName(a: any, b: any) {
    if (a.name < b.name) { return -1 };
    if (a.name > b.name) { return 1 };
    return 0;
  }

  getPriceByTypeId(id: number): PriceItem | null {
    const priceItem = this.event.priceList.filter((priceObject) => priceObject.priceType === id)[0];
    if (priceItem) {
      return priceItem;
    }
    return null;
  }

  getRemainingMinimumParticipants(): number {
    return this.event.participantsMin - this.event.participantCount;
  }

  hasUnlimitedPlaces(): boolean {
    if (this.event.participantsMax == null) {
      return true;
    } else {
      return false;
    }
  }

  calcTotalPrice(): number {
    var ammount = 0;

    this.event.priceList.forEach(price => {
      ammount += price.price;
    });

    ammount += this.additionalPrice;
    return ammount;
  }

  showPriceLabel(): boolean {
    if (this.event.displayPriceType === this.displayPriceType.NoCost) {
      return false;
    }

    if (this.event.displayPriceType === this.displayPriceType.PriceInChF) {
      if (this.event.priceList.length) {
        return true;
      } else {
        return false;
      }
    }

    return true;
  }


}

<div class="alert alert-danger mt-3"
  *ngIf="submitted && (accountChangeDataFromComponent.getForm()?.invalid || confirmForm.invalid || (membershipExtension.isLastSchoolRequired && !membershipCheckPastSchoolComponent.isValid()))">
  <ul class="m-0">
    <li>{{'ValidationText.FillInAllRequiredFields' | transloco}}</li>
    <li *ngIf="!confirmForm.get('acceptCorrectContactDetails')?.valid">{{'ValidationText.VerifyCorrectContactDetails' |
      transloco}}</li>
    <li *ngIf="!confirmForm.get('acceptAvb')?.valid" [innerHTML]="'ValidationText.Terms' | transloco"></li>
    <li *ngIf="submitted && membershipExtension.isLastSchoolRequired && !membershipCheckPastSchoolComponent.isValid()">
      {{'ValidationText.LastSchool' | transloco}}</li>
  </ul>
</div>

<app-account-change-data-form #accountChangeDataFromComponent [passPhotoUploadEnabled]="false"
  [membershipExtension]="membershipExtension"></app-account-change-data-form>

<ng-container *ngIf="membershipExtension.isLastSchoolRequired">
  <div class="mb-3">
    <app-membership-check-past-school #membershipCheckPastSchoolComponent></app-membership-check-past-school>
  </div>
</ng-container>

<form [formGroup]="confirmForm" *ngIf="confirmForm">
  <div class="form-group"
    [ngClass]="{'has-error': submitted && confirmForm.get('acceptCorrectContactDetails')?.errors}">
    <div class="checkbox">
      <label class="mb-2">
        <input type="checkbox" formControlName="acceptCorrectContactDetails" />&nbsp;
        {{'YesIAcceptCorrectContactDetails' | transloco}}
      </label>
    </div>
  </div>
  <div class="form-group" [ngClass]="{'has-error': submitted && confirmForm.get('acceptAvb')?.errors}">
    <div class="checkbox">
      <label>
        <input type="checkbox" formControlName="acceptAvb" />&nbsp;
        <span [innerHTML]="'YesIAcceptDataPrivacyStatement' | transloco: {url: membershipExtension.termsUrl}"></span>
      </label>
    </div>
  </div>
</form>

<div class="alert">
  <div class="row">
    <div class="col-md-12">
      <div class="float-start">
        <button class="btn btn-default" (click)="back()">
          <span class="fa fa-chevron-left"></span> {{'Back' | transloco}}
        </button>
      </div>
      <div class="float-end">
        <!-- cancelling enrollment-->
        <app-membership-cancel-modal class="me-2"></app-membership-cancel-modal>
        <button class="btn btn-primary" (click)="sendForm()" [disabled]="disableSendButton">{{'Next' |
          transloco}}
        </button>
      </div>
    </div>
  </div>
</div>
<div class="text-center">
    <h4 class="mt-3">
      <i class="fa-2x text-{{iconColor}}"  [ngClass]="icon ? icon : 'fas fa-circle-question'"></i>
    </h4>
  
    <div class="my-3">
      <h5 *ngIf="title">{{title}}</h5>
      <div [innerHtml]="body ? body : 'confirmationModal.default.bodyExport' | transloco"></div>
    </div>
    <div class="mb-3">
      <div class="btn btn-default border me-1" *ngIf="!onlyConfirm && !declineText" (click)="decline()">{{'Cancel' | transloco}}</div>
      <div class="btn btn-default border me-1" *ngIf="!onlyConfirm && declineText" (click)="decline()">{{declineText}}</div>
      <div class="btn btn-{{color}} me-1" *ngIf="!confirmText" (click)="confirm()">OK</div>
      <div class="btn btn-{{color}} me-1"  *ngIf="confirmText" (click)="confirm()">{{confirmText}}</div>
    </div>
  </div>
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { FileDownloadRequest } from 'src/app/membership-application/models/file-download-request.model';
import { DateHandlerService } from 'src/app/shared/date-handler.service';
import { environment } from 'src/environments/environment';
import { ApplicationList } from '../models';

@Injectable()
export class MembershipApplicationProcessService {

    constructor(private httpClient: HttpClient,
        private dateHandler: DateHandlerService) { }
    
      getMembershipApplicationsByStatus(status: any): Observable<ApplicationList[]> {
        return this.httpClient.get(`${environment.api}/MembershipApplications/Status/${status}`).pipe(
          map((responses: any) => {
                responses.forEach((response: ApplicationList) => {
    
              response.birthdate = this.dateHandler.takeAsUtcWithLocalTimeZone(response.birthdate).toDate();
              response.membershipValidFromDate = this.dateHandler.takeAsUtcWithLocalTimeZone(response.membershipValidFromDate).toDate();
            });
    
            return responses;
          })
        );
      }
    
      acceptMembershipApplication(membershipApplicationId: any, timestamp: any, message: string): Observable<any> {
        return this.httpClient.post(`${environment.api}/MembershipApplications/${membershipApplicationId}/Process/Accept?timestamp=${timestamp}`, {message}).pipe(
          map((res: any) => res.data)
        );
      }
    
      setToVerifyMembershipApplication(membershipApplicationId: any, timestamp: any) {
        return this.httpClient.post(`${environment.api}/MembershipApplications/${membershipApplicationId}/Process/SetToVerify?timestamp=${timestamp}`, {}).pipe(
          map((res: any) => res.data)
        );
      }
    
      declineMembershipApplication(membershipApplicationId: any, message: any, timestamp: any) {
        return this.httpClient.post(`${environment.api}/MembershipApplications/${membershipApplicationId}/Process/decline?timestamp=${timestamp}`, {message: message}).pipe(
          map((res: any) => res.data)
        );
      }
    
      requestRevisionMembershipApplication(membershipApplicationId: any, message: any, timestamp: any) {
        return this.httpClient.post(`${environment.api}/MembershipApplications/${membershipApplicationId}/Process/RequestRevision?timestamp=${timestamp}`, {message: message}).pipe(
          map((res: any) => res.data)
        );
      }
    
      uploadFormattedPassPhoto(membershipApplicationId: any, file: any): Observable<any> {
        return this.httpClient.post(`${environment.api}/MembershipApplications/${membershipApplicationId}/FormattedPassPhoto`, file).pipe(
          map((res: any) => res.data)
        );
      }
    
      deleteFormattedPassPhoto(membershipApplicationId: any): Observable<any> {
        return this.httpClient.delete(`${environment.api}/MembershipApplications/${membershipApplicationId}/FormattedPassPhoto`);
      }
    
      getFormattedPassPhotoDownloadId(membershipApplicationId: any): Observable<FileDownloadRequest> {
        return this.httpClient.get(`${environment.api}/MembershipApplications/${membershipApplicationId}/FormattedPassPhoto/DownloadId`).pipe(
          map((res: any) => res.data)
        );
      }
    
      sortByDateDesc(applications: ApplicationList[]): ApplicationList[] {
        applications.sort((a, b) => {
          const dateA = + new Date(a.changeDate), dateB = + new Date(b.changeDate);
          return dateB - dateA;
        });
    
        return applications;
      }
    
      sortByDateASC(applications: ApplicationList[]): ApplicationList[] {
        applications.sort((a, b) => {
          const dateA = + new Date(a.changeDate), dateB = + new Date(b.changeDate);
          return dateA - dateB;
        });
    
        return applications;
      }
    
}
<div class="cal-week-view" role="grid">
    <div class="cal-day-headers sticky" role="row">
        <div class="cal-header" *ngFor="let user of users; trackBy: trackByUserId" tabindex="0" role="columnheader"
            [mwlCalendarTooltip]="user.name" (click)="onRoomClicke(user.id)" style="cursor: pointer;">
            <b [ngStyle]="{'color': user.color.primary}">
                <span style="opacity: 1;" *ngIf="user.fieldId == 0">
                    <i class="fa-solid fa-house"></i>
                </span>
                <span style="opacity: 1;" class="fw-bold" *ngIf="user.fieldId != 0">
                    {{user.fieldId}}
                </span>
            </b>
        </div>
    </div>
    <div class="cal-time-events" mwlDroppable (dragEnter)="dragEnter('time')" (dragLeave)="dragLeave('time')">
        <div class="cal-time-label-column" *ngIf="view.hourColumns.length > 0">
            <div *ngFor="let hour of view.hourColumns[0].hours; trackBy: trackByHour; let odd = odd" class="cal-hour"
                [class.cal-hour-odd]="odd">
                <mwl-calendar-week-view-hour-segment *ngFor="let segment of hour.segments; trackBy: trackByHourSegment"
                    [style.height.px]="hourSegmentHeight" [segment]="segment" [segmentHeight]="hourSegmentHeight"
                    [locale]="locale" [customTemplate]="hourSegmentTemplate" [isTimeLabel]="true">
                </mwl-calendar-week-view-hour-segment>
            </div>
        </div>
        <div class="cal-day-columns" [class.cal-resize-active]="timeEventResizes.size > 0" #dayColumns>
            <div class="cal-day-column" *ngFor="let column of view.hourColumns; trackBy: trackByHourColumn">
                <mwl-calendar-week-view-current-time-marker [columnDate]="column.date" [dayStartHour]="dayStartHour"
                    [dayStartMinute]="dayStartMinute" [dayEndHour]="dayEndHour" [dayEndMinute]="dayEndMinute"
                    [hourSegments]="hourSegments" [hourSegmentHeight]="hourSegmentHeight"
                    [customTemplate]="currentTimeMarkerTemplate">
                </mwl-calendar-week-view-current-time-marker>
                <div *ngFor="let timeEvent of column.events; trackBy: trackByWeekTimeEvent" #event
                    class="cal-event-container"
                    [class.cal-draggable]="timeEvent.event.draggable && timeEventResizes.size === 0"
                    [class.cal-starts-within-day]="!timeEvent.startsBeforeDay"
                    [class.cal-ends-within-day]="!timeEvent.endsAfterDay" [ngClass]="timeEvent.event.cssClass"
                    [hidden]="timeEvent.height === 0 && timeEvent.width === 0" [style.top.px]="timeEvent.top"
                    [style.height.px]="timeEvent.height" [style.left.%]="timeEvent.left"
                    [style.width.%]="timeEvent.width" mwlResizable
                    [resizeSnapGrid]="{ left: dayColumnWidth, right: dayColumnWidth, top: eventSnapSize || hourSegmentHeight, bottom: eventSnapSize || hourSegmentHeight}"
                    [validateResize]="validateResize" [allowNegativeResizes]="true"
                    (resizeStart)="timeEventResizeStarted(dayColumns, timeEvent, $event)"
                    (resizing)="timeEventResizing(timeEvent, $event)" (resizeEnd)="timeEventResizeEnded(timeEvent)"
                    mwlDraggable dragActiveClass="cal-drag-active" [ghostDragEnabled]="!snapDraggedEvents"
                    [validateDrag]="validateDrag" (dragEnd)="onClicked(timeEvent.event)">

                    <div class="cal-resize-handle cal-resize-handle-before-start"
                        *ngIf="timeEvent.event?.resizable?.beforeStart && !timeEvent.startsBeforeDay" mwlResizeHandle
                        [resizeEdges]="{left: true,top: true}">
                    </div>
                    <mwl-calendar-week-view-event [locale]="locale" [weekEvent]="timeEvent"
                        [tooltip]="timeEvent.event.meta.fromTo" [tooltipTemplate]="tooltipTemplate"
                        [tooltipAppendToBody]="tooltipAppendToBody"
                        [tooltipDisabled]="dragActive || timeEventResizes.size > 0" [tooltipDelay]="tooltipDelay"
                        [customTemplate]="eventTemplate" [eventTitleTemplate]="eventTitleTemplate"
                        [eventActionsTemplate]="eventActionsTemplate" [column]="column"
                        (eventClicked)="onClicked(timeEvent.event)"
                        [ngClass]="{ 'occupied-event' : timeEvent.event.meta.isOccupied && !timeEvent.event.meta.isSelected,'selected-event': timeEvent.event.meta.isSelected}">
                    </mwl-calendar-week-view-event>
                    <div class="cal-resize-handle cal-resize-handle-after-end"
                        *ngIf="timeEvent.event?.resizable?.afterEnd && !timeEvent.endsAfterDay" mwlResizeHandle
                        [resizeEdges]="{right: true, bottom: true}">
                    </div>
                </div>

                <div *ngFor="let hour of column.hours; trackBy: trackByHour; let odd = odd" class="cal-hour"
                    [class.cal-hour-odd]="odd">
                    <mwl-calendar-week-view-hour-segment
                        *ngFor="let segment of hour.segments; trackBy: trackByHourSegment"
                        [style.height.px]="hourSegmentHeight" [segment]="segment" [segmentHeight]="hourSegmentHeight"
                        [locale]="locale" [customTemplate]="hourSegmentTemplate"
                        (mwlClick)=" hourSegmentClicked.emit({ date: segment.date, sourceEvent: $event})"
                        [clickListenerDisabled]="hourSegmentClicked.observers.length === 0" mwlDroppable
                        dragActiveClass="cal-drag-active" (drop)="eventDropped($event, segment.date, false)">
                    </mwl-calendar-week-view-hour-segment>
                </div>
            </div>
        </div>
    </div>
</div>
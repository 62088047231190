import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard, OfficeAuthGuard } from "../security/guards";
import { TournamentDetailsComponent } from "./tournament-details/tournament-details.component";
import { TournamentListComponent } from "./tournament-list/tournament-list.component";
import { TournamentTeamComponent } from "./tournament-team/tournament-team.component";
import { TournamentPublicEventGroupsComponent } from "./tournament-public-event-groups/tournament-public-event-groups.component";
import { TournamentPublicEventGamesComponent } from "./tournament-public-event-games/tournament-public-event-games.component";
import { TournamentConfigurationDetailsComponent } from "./tournament-configuration-details/tournament-configuration-details.component";
import { TournamentEventsDetailsComponent } from "./tournament-events-details/tournament-events-details.component";
import { TournamentPlanningDetailsComponent } from "./tournament-planning-details/tournament-planning-details.component";
import { TournamentGroupsDetailsComponent } from "./tournament-groups-details/tournament-groups-details.component";
import { TournamentGamePlansDetailsComponent } from "./tournament-game-plans-details/tournament-game-plans-details.component";
import { TournamentAdminGamesComponent } from "./tournament-admin-games/tournament-admin-games.component";
import { TournamentPublicEventGroupRankingComponent } from "./tournament-public-event-group-ranking/tournament-public-event-group-ranking.component";
import { TournamentPublicAvailableSlotsComponent } from "./tournament-public-available-slots/tournament-public-available-slots.component";

const routes: Routes = [
    {
        path: 'admin/:tournamentPlanId',
        component: TournamentDetailsComponent,
        canActivate: [OfficeAuthGuard], // role office
        canActivateChild: [OfficeAuthGuard], // role office

        children: [
            { path: 'configuration', component: TournamentConfigurationDetailsComponent },
            { path: 'events', component: TournamentEventsDetailsComponent },
            { path: 'planning', component: TournamentPlanningDetailsComponent },
            { path: 'groups', component: TournamentGroupsDetailsComponent },
            { path: 'games', component: TournamentGamePlansDetailsComponent },
        ]
    },
    {
        path: ':tournamentPlanId/admin/games',
        component: TournamentAdminGamesComponent,
        canActivate: [AuthGuard], // no role
    },
    {
        path: 'admin',
        component: TournamentListComponent,
        canActivate: [OfficeAuthGuard], // role office
    },
    {
        path: ':tournamentPlanId/availableSlots',
        component: TournamentPublicAvailableSlotsComponent
    },
    {
        path: ':tournamentPlanId/teams/:teamId',
        component: TournamentTeamComponent
    },
    {
        path: ':tournamentPlanId/events/:eventId/groups/:groupId/ranking',
        component: TournamentPublicEventGroupRankingComponent,
    },
    {
        path: ':tournamentPlanId/events/:eventId/groups',
        component: TournamentPublicEventGroupsComponent,
    },
    {
        path: ':tournamentPlanId/events/:eventId/games',
        component: TournamentPublicEventGamesComponent,
    }
];


@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
    ],
    exports: [RouterModule],
})
export class TournamentsRoutingModule { }

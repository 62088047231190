import { Component, OnInit, Input } from '@angular/core';
import { FacilityClosingList } from '../../models/facility-closing-list.model';

@Component({
  selector: 'app-information-screen-closures',
  templateUrl: './closures.component.html',
  styleUrls: ['./closures.component.scss']
})
export class ClosuresComponent implements OnInit {

  @Input() closings!: FacilityClosingList[];

  constructor() { }

  ngOnInit() {
  }

}

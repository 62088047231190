<div class="container-fluid">
  <div class="row mt-3">
    <div class="col">
      <h1>{{'Refunds' | transloco}}</h1>
      <div class="alert alert-warning" role="alert">
        <p class="p-0 m-0">Beim Ausführen der Rückerstattungen wird die Anfrage an den PSP gesendet. Sobald der PSP uns das OK liefert,
          wird die Rückerstattung im IM ausgeglichen. Dieser Vorgang kann einige Minuten dauern.</p>
      </div>

      <tabset style="margin-top:3rem">
        <tab [heading]="'OpenRefunds' | transloco" (selectTab)="loadRefunds()" [disabled]="isLoading">
          <div class="row mt-3">
            <div class="float-end">
              <button class="btn btn-primary float-end" (click)="confirmModal.open()"
                [tooltip]="'RunAction' | transloco" [disabled]="countSelected() == 0 || isLoading">
                <i class="fa fa-bolt" aria-hidden="true" style="color: #FFF;"></i> {{'RunAction' | transloco}}
                ({{countSelected()}})
              </button>
              <button class="btn btn-danger float-end me-2" (click)="deselectAll()" [tooltip]="'DeselectAll' | transloco" [disabled]="isLoading">
                <i class="fa fa-plus" aria-hidden="true" style="color: #FFF;"></i> {{'DeselectAll' | transloco}}
              </button>
              <button class="btn btn-primary float-end me-2" (click)="selectAll()" [tooltip]="'SelectAll' | transloco" [disabled]="isLoading">
                <i class="fa fa-plus" aria-hidden="true" style="color: #FFF;"></i> {{'SelectAll' | transloco}}
              </button>
            </div>
          </div>
          <div class="row mt-3">
            <app-refund-table [refundList]="openRefundList" [isProcessing]="false" [isLoading]="isLoading"></app-refund-table>
          </div>
        </tab>
        <tab [heading]="'ProcessedRefunds' | transloco" (selectTab)="loadRefunds()" [disabled]="isLoading">
          <div class="row mt-3">
            <app-refund-table [refundList]="processedRefundList" [isProcessing]="true" [isLoading]="isLoading"></app-refund-table>
          </div>
        </tab>
      </tabset>
    </div>
  </div>
</div>
<app-confirm-action-modal #confirmModal [body]="'ConfirmRefundRequest' | transloco" (onEventConfirmed)="runSelected()">
</app-confirm-action-modal>
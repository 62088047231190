import { Injectable } from "@angular/core";
import { TranslocoService } from "@ngneat/transloco";
import * as moment from "moment";

@Injectable({
    providedIn: 'root'
})
export class TournamentDisplayService {

    constructor(
        private translocoService: TranslocoService
    ) {
    }

    getLocationName(facilityName: string, roomName: string, fieldId: number, displayFullField: boolean): string {
        var name = `${facilityName} | ${roomName}`;

        if (displayFullField || fieldId != 0) {
            name += ` | ${this.getFieldName(fieldId)}`
        }

        return name;
    }

    getFieldName(fieldId: number): string {
        if (fieldId == 0) return this.translocoService.translate(`Tournaments.PlanningData.Location.FullField`);
        return `${this.translocoService.translate(`Tournaments.PlanningData.Location.PartField`)} ${fieldId}`;
    }

    getTimeSlotName(from: Date, to: Date): string {

        var day = new Date(from).getDay();

        const fromDate = moment(from).format('DD.MM.YYYY');
        const fromTime = moment(from).format('HH:mm');
        const toTime = moment(to).format('HH:mm');

        var dayShort = this.translocoService.translate(`Day_${day}`);

        return `${dayShort} ${fromDate} ${fromTime} - ${toTime}`;
    }

    getRoomColors(): string[] {
        return ["#9e0142", "#f46d43", "#fee08b", "#abdda4", "#3288bd", "#d53e4f", "#fdae61", "#e6f598", "#66c2a5", "#5e4fa2"];
    }

    getAdditionLink(additionLink: string): string {
        return !additionLink.toLocaleLowerCase().startsWith('https://') 
            ? 'https://' + additionLink 
            : additionLink;
    }
}
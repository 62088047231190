<div class="alert alert-primary">
  Hier kann ein neues Passwort für den Benutzer gesetzt werden.
</div>

<div class="alert alert-danger" *ngIf="(passwordFormGroup.get('password')?.errors || passwordFormGroup.errors) && submitted">
  <ul>
    <li *ngIf="passwordFormGroup.get('password')?.errors">Das Passwort muss mindestens 8 Zeichen und darf nicht länger als 100 Zeichen sein.</li>
    <li *ngIf="passwordFormGroup.getError('password') && submitted">Die Passwörter stimmen nicht überein.</li>
  </ul>
</div>

<form [formGroup]="passwordFormGroup"  [ngClass]="{'has-error': passwordFormGroup.errors && submitted}">
  <div class="form-group" [ngClass]="{'has-error': passwordFormGroup.get('password')?.errors && submitted}">
    <label class="control-label" for="password">{{'Password' | transloco}}</label>
    <input formControlName="password" class="form-control" id="password" name="password" type="password">
  </div>
  <div class="form-group" [ngClass]="{'has-error': passwordFormGroup.get('passwordConfirm')?.errors  && submitted}">
    <label class="control-label" for="Email">{{'PasswordConfirm' | transloco}}</label>
    <input formControlName="passwordConfirm" class="form-control" id="Email" name="Email" type="password"> 
  </div>
</form>
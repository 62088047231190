<div class="btn-group mt-1" role="group">
    <button class="btn btn-sm btn-secondary" (click)="openChangeSlotModal()">
        <i class="fa-solid fa-shuffle"></i>
    </button>
    <button class="btn btn-sm btn-secondary" (click)="openResultModal()">
        <i class="fa-solid fa-bullseye"></i>
    </button>
    <button class="btn btn-sm btn-danger" (click)="openDeleteResultModal()" [disabled]="!showDeleteResult">
        <i class="fa-solid fa-delete-left"></i>
    </button>
</div>
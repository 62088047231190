import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from '@premotec/ngx-essentials';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-edit-modal',
  templateUrl: './edit-modal.component.html',
  styleUrls: ['./edit-modal.component.scss']
})
export class EditModalComponent extends BaseComponent implements OnInit {

  @Input() title = '';

  @Output() closeModalEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() saveModalEvent: EventEmitter<void> = new EventEmitter<void>();

  @Input() isSaving: boolean = false;
  isSubmitted: boolean = false;

  constructor(private bsModalRef: BsModalRef) {
      super();
  }

  ngOnInit() {
  }

  close(): void {
    this.closeModalEvent.next();
  }

  save() {
    this.saveModalEvent.next();
  }

}

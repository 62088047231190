<div class="alert alert-primary">
  {{'ManageUsers.AsvzIdInfoText' | transloco}}
</div>

<form [formGroup]="asvzIdFormGroup"  [ngClass]="{'has-error': asvzIdFormGroup.errors && submitted}">
  <!-- <div class="form-group">
    <label class="control-label" for="password">{{'ManageUsers.CurrentAsvzId' | transloco}}</label>
    <input class="form-control" type="input" disabled [value]="user.userName">
  </div> -->
  <div class="form-group" [ngClass]="{'has-error': asvzIdFormGroup.get('asvzId')?.errors  && submitted}">
    <label class="control-label" for="Email">{{'ManageUsers.AsvzId' | transloco}}</label>
    <input formControlName="asvzId" class="form-control" id="Email" name="Email" type="input"> 
  </div>
  <div class="form-group" [ngClass]="{'has-error': asvzIdFormGroup.get('addressId')?.errors  && submitted}">
    <label class="control-label" for="Email">{{'ManageUsers.AddressId' | transloco}}</label>
    <input formControlName="addressId" class="form-control" id="Email" name="Email" type="input"> 
  </div>
</form>
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, FormGroup, FormBuilder, Validators, ValidatorFn } from '@angular/forms';
import { BaseComponent, ObjectHelper } from '@premotec/ngx-essentials';
import { CountryService } from '../../../shared/services/country.service';
import { Country } from '../../../shared/models/country.model';
import { RegistrationGuardService } from '../../../events/registration/registration-guard.service';
import { MembershipApplicationsService } from '../../membership-applications.service';
import { Applicant, ApplicationPut, Application, ConfirmMembershipApplication } from '../../models';
import * as moment from 'moment';
import { MemebershipApplicationStatus } from '../../enums';
import { TranslocoService } from '@ngneat/transloco';
import { ToastrService } from 'ngx-toastr';

function phoneIsDefined(c: AbstractControl) {
  const phonePrivate = c.get('phonePrivate');
  const phoneOffice = c.get('phoneOffice');
  const mobile = c.get('mobilePhone');

  if (phonePrivate?.value || phoneOffice?.value || mobile?.value) {
    return null;
  }

  return { 'phoneNumberMissing': true};
}


export function validateMembershipValidFromDate(noMembershipStartAfterNMonths: number): ValidatorFn {
  return (c: AbstractControl): {[key: string]: any} | null => {
    if (moment(c.value).isAfter(moment(moment()).add(noMembershipStartAfterNMonths, 'months'))) {
      return { 'afterNMonths': true};
    }

    if (moment(c.value).isSameOrAfter(new Date(), 'day')) {
      return null;
    }

    return { 'wrongDate': true};
  };
}



function dateIsAtLeast14YearsAgo(c: AbstractControl) {

  if (!c.value) {
    return null;
  }
  if (moment(c.value).isSameOrBefore(moment().subtract(14, 'years'), 'day')) {
    return null;
  }

  return { 'wrongDate': true};
}


@Component({
  selector: 'app-basic-data-form',
  templateUrl: './basic-data-form.component.html',
  styleUrls: ['./basic-data-form.component.scss']
})
export class BasicDataFormComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {

  accountForm!: FormGroup;
  confirmForm!: FormGroup;
  countries!: Country[];
  requiredPropertyIndicator = '*';

  submitted = false;
  isSaving = false;

  dateToday = new Date();

  @Input() applicationId!: string;
  @Input() application!: Application;
  @Output() currentStepChanged: EventEmitter<number> = new EventEmitter();
  @Output() applicationChanged: EventEmitter<Application> = new EventEmitter();

  readonly = true;

  translateParam: any;

  get applicantGroup(): FormGroup {
    const ag = <FormGroup>this.accountForm.get('applicant');
    return ag;
  }

  constructor(
              private countryService: CountryService,
              private registrationGuardService: RegistrationGuardService,
              private membershipApplicationsService: MembershipApplicationsService,
              private fb: FormBuilder,
              private toastrService: ToastrService,
              private translocoService: TranslocoService) {
                super();
              }

  ngOnInit() {
    this.whileImAlive( this.countryService.getCountries()).subscribe(data => {
      const indexOfEmptyEntry = data.findIndex(i => i.nameShort === ' ' && i.nameLong === ' ');
      if (indexOfEmptyEntry !== -1) {
        console.log(indexOfEmptyEntry);
        data.splice(indexOfEmptyEntry, 1)
      };
      this.countries = data.sort((a, b) => {
        return a.nameLong.localeCompare(b.nameLong);
      });
    });

    this.initForm();
  }

  ngOnChanges(changes: SimpleChanges): void {

  }


  override ngOnDestroy() {
    this.registrationGuardService.formIsDirty = false;
  }

  initForm() {

    if (this.application.statusId === MemebershipApplicationStatus.new || this.application.statusId === MemebershipApplicationStatus.revisionRequested) {
      this.readonly = false;
    }

    this.translateParam = {
      'noMembershipStartAfterNMonths': this.application.noMembershipStartAfterNMonths
    };

    let membershipValidFromDate, dateOfBirth = undefined;

    if (moment(this.application.membershipValidFromDate).isValid()) {
      membershipValidFromDate = new Date(this.application.membershipValidFromDate);
    } else {
      membershipValidFromDate = new Date();
    }

    if (this.application?.applicant?.birthdate != null) {
      dateOfBirth = new Date(this.application.applicant.birthdate);
    }

    let acceptAvb, acceptCorrectContactDetails;
    if (this.readonly) {
      acceptAvb = true;
      acceptCorrectContactDetails = true;
    }

    this.confirmForm = this.fb.group({
      acceptAvb: [{value: acceptAvb, disabled: this.readonly}, Validators.requiredTrue],
      acceptCorrectContactDetails: [{value: acceptCorrectContactDetails, disabled: this.readonly}, Validators.requiredTrue],
    });

    this.accountForm = this.fb.group({
      applicant: this.fb.group({
        salutation: [{value: this.application.applicant.salutation, disabled: this.readonly}, [Validators.required, Validators.maxLength(250)]],
        firstName: [{value: this.application.applicant.firstName, disabled: this.readonly}, [Validators.required, Validators.maxLength(250)]],
        lastName: [{value: this.application.applicant.lastName, disabled: this.readonly}, [Validators.required, Validators.maxLength(250)]],
        dateOfBirth: [{value: dateOfBirth, disabled: this.readonly}, [Validators.required, dateIsAtLeast14YearsAgo]],
        email: [{value: this.application.applicant.email, disabled: this.readonly}, [Validators.required, Validators.email, Validators.maxLength(250)]],
        street1: [{value: this.application.applicant.address, disabled: this.readonly}, [Validators.required, Validators.maxLength(250)]],
        street2: [{value: this.application.applicant.additionalAddress, disabled: this.readonly}, Validators.maxLength(250)],
        postcode: [{value: this.application.applicant.postCode, disabled: this.readonly}, [Validators.required, Validators.maxLength(10)]],
        city: [{value: this.application.applicant.city, disabled: this.readonly}, [Validators.required, Validators.maxLength(50)]],
        country: [{value: this.application.applicant.country, disabled: this.readonly}, [Validators.required, Validators.minLength(2)]],
        phoneGroup: this.fb.group({
          phonePrivate: [{value: this.application.applicant.privatePhone, disabled: this.readonly}, Validators.maxLength(50)],
          phoneOffice: [{value: this.application.applicant.businessPhone, disabled: this.readonly}, Validators.maxLength(50)],
          mobilePhone: [{value: this.application.applicant.mobilePhone, disabled: this.readonly}, Validators.maxLength(50)]
        }, {validator: phoneIsDefined})
      }),
      commentFromApplicant: [{value: this.application.commentFromApplicant, disabled: this.readonly}],
      membershipValidFromDate: [{value: membershipValidFromDate, disabled: this.readonly}, [Validators.required, validateMembershipValidFromDate(this.application.noMembershipStartAfterNMonths)]]
    });

    this.accountForm.statusChanges.subscribe(() => {
      this.registrationGuardService.formIsDirty = this.accountForm.dirty;
    })

  }

  phoneNumberIsInvalid(formControlName: string): boolean {
    const phoneGroup = this.accountForm.get(['applicant', 'phoneGroup']) as FormGroup;
  
    if (!phoneGroup) {
      return false;
    }
  
    const control = phoneGroup.get(formControlName);
  
    const hasError = control?.errors ? true : false;  
    const phoneNumberMissing = phoneGroup.hasError('phoneNumberMissing');
    return this.submitted && (hasError || phoneNumberMissing);
  }

  resetForm() {
    this.accountForm.reset();
    this.initForm();
  }

  emailIsInvalid(formControlName: string): boolean {
    return this.accountForm.get(formControlName)!.errors!['email'];
  }

  getForm(): FormGroup {
    return this.accountForm;
  }

  getUpdatedApplicantData(): Applicant {
    return {
      address: this.applicantGroup.get('street1')?.value,
      additionalAddress: this.applicantGroup.get('street2')?.value,
      postCode: this.applicantGroup.get('postcode')?.value,
      city: this.applicantGroup.get('city')?.value,
      country: this.applicantGroup.get('country')?.value,
      privatePhone: this.applicantGroup.get('phoneGroup.phonePrivate')?.value,
      businessPhone: this.applicantGroup.get('phoneGroup.phoneOffice')?.value,
      mobilePhone: this.applicantGroup.get('phoneGroup.mobilePhone')?.value,
      email: this.applicantGroup.get('email')?.value,
      birthdate: this.applicantGroup.get('dateOfBirth')?.value,
      firstName: this.applicantGroup.get('firstName')?.value,
      lastName: this.applicantGroup.get('lastName')?.value,
      salutation: this.applicantGroup.get('salutation')?.value,
    }
  }

  getUpdatedApplicationData(): ApplicationPut {
    return {
      onlineMembershipId: this.application.onlineMembership.id,
      applicant: this.getUpdatedApplicantData(),
      commentFromApplicant: this.accountForm.controls['commentFromApplicant']?.value,
      membershipValidFromDate: this.accountForm.controls['membershipValidFromDate']?.value,
      showDocumentsInPerson: this.application.showDocumentsInPerson
    }
  }

  submitForm() {
    if (this.accountForm.valid && this.confirmForm.valid) {
      this.isSaving = true;
      const confirmData: ConfirmMembershipApplication = this.confirmForm.value;
      const data = this.getUpdatedApplicationData();

      this.whileImAlive(this.membershipApplicationsService.updateApplicationInfo(this.application.id, data)).subscribe(res => {
        this.submitMembershipApplication(confirmData);
      },
      () => {
        this.showErrorNotification();
        this.isSaving = false;
      });

    } else {
      this.submitted = true;
      window.scrollTo(0, 0);
      console.log('applicationForm is invalid');
    }
  }

  submitMembershipApplication(confirmData: any) {

    this.whileImAlive(this.membershipApplicationsService.submitMembershipApplication(this.application.id, confirmData)).subscribe((res) => {
      this.isSaving = false;

      if (res != null && res.statusId != null) {
        this.application.statusId = res.statusId;
        this.applicationChanged.emit(this.application);
      }

      this.currentStepChanged.emit(3);
    },
    () => {
      this.showErrorNotification();
      this.isSaving = false;
    });
  }

  showErrorNotification() {
    this.toastrService.error(this.translocoService.translate('Msg.SaveError'));
  }

  back() {
    this.currentStepChanged.emit(1);
  }
}

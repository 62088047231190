<div class="field">
  <div class="field-heading">{{title}}</div>
</div>

<table class="table table-striped table-responsive table-bordered" *ngIf="registrations?.length">
  <thead style="font-weight: bold;">
    <tr>
      <td style="width: 10px;"></td>
      <td style="width: 10px;"></td>
      <td>{{'Title' | transloco}}</td>
      <td>{{'Sport' | transloco}}</td>
      <td>{{'Date' | transloco}}</td>
      <td>{{'Place' | transloco}}</td>
      <td style="min-width: 100px;">{{'Price' | transloco}}</td>
      <td style="min-width: 100px;">{{'State' | transloco}}</td>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let registration of registrations">
      <td>
        <i *ngIf="registration.status" [tooltip]="'StateMessages.State_'+registration.status+'.Msg' | transloco"
          [ngClass]="getCorrespondingIcon(registration.status)" aria-hidden="true"></i>
      </td>
      <td>
        <div class="btn-group" dropdown>
          <button dropdownToggle type="button" class="btn btn-default btn-sm dropdown-toggle">
          </button>
          <ul *dropdownMenu class="dropdown-menu" role="menu">
            <li role="menuitem">
              <a class="dropdown-item" [routerLink]="getRegistrationRoute(registration.id, registration.status)">
                <i class="fa fa-chevron-right"></i> {{'Enrollment' | transloco}}
              </a>
            </li>
          </ul>
        </div>
      </td>
      <td><a [routerLink]="'/events/'+registration.eventId">{{registration.eventName}}</a></td>
      <td>{{registration.sportName}}</td>
      <td>{{registration.eventStart | fromToDate: registration.eventEnd : 'DD.MM.YYYY': false}}</td>
      <td>
        <span *ngIf="registration.location.De">{{registration.location.De}}</span>
        <span *ngIf="!registration.location.De && registration.room.De">{{registration.room.De}}</span>
      </td>
      <td *ngIf="registration.status != registrationStateCompleted">{{registration.totalPrice | currencyFormat}}</td>
      <td *ngIf="registration.status == registrationStateCompleted">{{registration.paidAmount | currencyFormat}}</td>
      <td>
        {{'StateMessages.State_'+registration.status+'.Name' | transloco}}
        <span *ngIf="registration.expirationDate">({{registration.expirationDate | dateFormat: "DD.MM.YYYY"}})</span>
        <i class="fa fa-info-circle" [tooltip]="'StateMessages.State_'+registration.status+'.Msg' | transloco"
          container="body" placement="left"></i>
      </td>
    </tr>
  </tbody>
</table>


<p class="well" *ngIf="registrations.length <= 0">{{'EmptyRegistration' | transloco}}</p>
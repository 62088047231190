import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { ScreenComponent } from "./components/screen/screen.component";
import { TranslocoService } from "@ngneat/transloco";

const routes: Routes = [
    { path: '', component: ScreenComponent},
  ];
  
  @NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
  })
  export class InformationScreenRoutingModule {
    constructor (translate: TranslocoService) {
      // this language will be used as a fallback when a translation isn't found in the current language
      translate.setDefaultLang('de');
  
      // the lang to use, if the lang isn't available, it will use the current loader to get them
      // translate.use('de');
    }
   }
  
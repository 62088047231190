import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { TournamentEventGameSettingsUpdateModel } from '../models/tournament-event-game-settings-update.model';
import { TournamentEventModel } from '../models/tournament-event.model';
import { TournamentService } from '../services/tournament.service';
import { TournamentEventGameLinksUpdateModel } from '../models/tournament-event-game-links-update.model';
@Component({
    templateUrl: './tournament-event-game-links-modal.component.html',
    styleUrls: ['./tournament-event-game-links-modal.component.scss'],
})
export class TournamentEventGameLinksModalComponent implements OnInit {

    @Output() confirmed = new EventEmitter<boolean>();

    tournamentPlanId!: string;
    eventId!: string;

    tournamentGameLinksForm!: UntypedFormGroup;

    formSubmitted = false;

    constructor(
        private fb: FormBuilder,
        private bsModalRef: BsModalRef,
        private tournamentService: TournamentService,
    ) {
    }

    ngOnInit() {
        if (this.tournamentPlanId && this.eventId) {
            this.loadTournamentEvent(this.tournamentPlanId, this.eventId);
        }
    }

    initGroupSettingsForm(tournamentEvent: TournamentEventModel) {

        this.tournamentGameLinksForm = this.fb.group({
            additionLinkTitle: [tournamentEvent.additionLinkTitle],
            additionLink: [tournamentEvent.additionLink],
        });
    }

    loadTournamentEvent(tournamentPlanId: string, eventId: string) {
        this.tournamentService.getTournamentEventById(tournamentPlanId, eventId).subscribe(x => {
            this.initGroupSettingsForm(x);
        });
    }

    update() {
        this.tournamentService.updateTournamentGameLinks(this.tournamentPlanId, this.eventId, this.getTournamentGameLinksUpdateModel()).subscribe(x => {
            this.confirmed.emit(true);
            this.bsModalRef.hide();
        });
    }

    getTournamentGameLinksUpdateModel(): TournamentEventGameLinksUpdateModel {
        var updateModel = new TournamentEventGameLinksUpdateModel();

        updateModel.additionLinkTitle = this.tournamentGameLinksForm.controls['additionLinkTitle'].value;
        updateModel.additionLink = this.tournamentGameLinksForm.controls['additionLink'].value;

        return updateModel;
    }

    confirm() {
        this.formSubmitted = true;
        if (!this.tournamentGameLinksForm.controls['additionLinkTitle'].value && this.tournamentGameLinksForm.controls['additionLink'].value) return;
        if (!this.tournamentGameLinksForm.controls['additionLink'].value && this.tournamentGameLinksForm.controls['additionLinkTitle'].value) return;
        if (this.tournamentGameLinksForm.controls['additionLink'].value
            && !this.tournamentGameLinksForm.controls['additionLink'].value.toLocaleLowerCase().startsWith('www.')
            && !this.tournamentGameLinksForm.controls['additionLink'].value.toLocaleLowerCase().startsWith('https://')) return;
        if (!this.tournamentGameLinksForm.valid) return;

        this.update();
    }

    decline() {
        this.bsModalRef.hide();
    }
}

import { Component, OnInit } from '@angular/core';
import { MembershipExtensionTypes, MembershipExtension } from '../models';
import { MembershipService } from '../membership.service';
import { BaseComponent } from '@premotec/ngx-essentials';
import { StatusesService } from '../../shared/status-workflow/statuses.service';
import { MembershipState } from '../membership-state.enum';
import { Params, ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-memberships-wizard',
  templateUrl: './memberships-wizard.component.html',
  styleUrls: ['./memberships-wizard.component.scss']
})
export class MembershipsWizardComponent extends BaseComponent implements OnInit {

  membershipExtensions!: MembershipExtensionTypes;
  membershipExtension = new MembershipExtension();
  membershipState = MembershipState;
  statuses: any;
  membershipId!: number;
  isLoading = false;

  // only temporary until is possible to create a membership
  currentState!: number;

  constructor(
    private membershipService: MembershipService,
    private statusesService: StatusesService,
    private activatedRoute: ActivatedRoute,
    private router: Router) {
    super();
  }

  ngOnInit() {
    this.isLoading = true;

    this.activatedRoute.params.subscribe((params: Params) => {
      if (params['membershipId']) {
        this.membershipId = params['membershipId'];
      }
    });

    this.statuses = this.statusesService.createStatusListMemberships();

    // no ValidFromDate on inital load
    this.getMembershipExtensions(null);
  }

  loadMembership(membershipExtensionId: number): void {
    this.membershipService.getMembershipExtension(membershipExtensionId).subscribe(membershipExtension => {
      this.membershipExtension = membershipExtension;

      // NOTE: 'NavigateByUrl' returns a Promise, so it is important not to initiate multiple calls
      if (this.membershipExtension.status === this.membershipState.ProcessPayment) {
        // If the status is already in ProcessPayment, then redirect to (PSP) Payment page
        this.router.navigateByUrl('/invoice/' + membershipExtension.invoiceId + '/payment');
      } else if (!this.hasSupportedStatus(this.membershipExtension.status)) {
        // The Wizard should only display those Memberships with a supported Status
        // Redirect all others to the 'Memberships' display
        this.router.navigateByUrl('/memberships');
      } else {

        // If statis is new, set status PassPhot to allow passphoto step
        if (this.membershipExtension.status === MembershipState.New) {
          this.membershipExtension.status = MembershipState.PassPhoto;
        }

        this.statusesService.updateWorkflowStatuses(this.membershipExtension.status);
      }

      this.isLoading = false;
    });
  }

  hasSupportedStatus(status: number): any {
    return this.membershipService.isWizardSupportedStatus(status);
  }

  getMembershipExtensions(validFrom: Date | null) {

    if (validFrom == null) {
      // intial load
      this.whileImAlive(this.membershipService.getMembershipExtensions()).subscribe(data => {
        this.membershipExtensions = data;

        if (this.membershipId != null) {
          this.loadMembership(this.membershipId);
        } else {
          this.isLoading = false;
        }
      });
    } else {
      // changed ValidFromDate
      const validFromDate = moment(validFrom, 'DD.MM.YYYY').format('YYYY-MM-DD');
      this.whileImAlive(this.membershipService.getMembershipExtensionsWithValidFromDate(validFromDate)).subscribe(data => {
        this.membershipExtensions = data;
        this.isLoading = false;
      });
    }
  }

  updateWizardState(state: number) {
    this.currentState = state;
    this.membershipExtension.status = state;
    this.statusesService.updateWorkflowStatuses(state);
  }

  updateMembershipExtension(membershipExtension: any) {
    this.membershipExtension = membershipExtension;
  }

  validFromDateChangedEvent(validFromDate: Date): void {
    this.getMembershipExtensions(validFromDate);
  }
}

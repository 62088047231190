import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { WaitingListItemList } from './waiting-list-item-list.model';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpResponseErrorHandlerService } from '../shared/services/http-response-error-handler.service';


@Injectable()
export class WaitingService {

  constructor(private httpService: HttpClient,
              private httpResponseErrorHandler: HttpResponseErrorHandlerService) {
  }

 getMyWaitingList(): Observable<any[]> {
    return this.httpService.get(`${environment.api}/Registrations/Status/WaitList`)
      .pipe(
        map((registrations: any): Array<WaitingListItemList> => registrations),
        catchError(e => this.httpResponseErrorHandler.HandleResponseError(e))
      );
    }

  removeFromEventoWaitlist(id: any): Observable<any[]> {
    return this.httpService.post(`${environment.api}/EventoRegistrations/${id}/RemoveFromWaitlist`, {})
      .pipe(
        map((registrations: any): Array<any> => registrations.data),
        // catchError(e => this.httpResponseErrorHandler.HandleResponseError(e))
      );
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { ReferenceDateId, RequiredSkill } from 'src/app/events/required-skill.model';
import { UserAuthorizationService } from '../../security/user-authorization.service';

@Component({
  selector: 'app-prerequisites-view',
  templateUrl: './prerequisites-view.component.html',
  styleUrls: ['./prerequisites-view.component.scss']
})
export class PrerequisitesViewComponent implements OnInit {

  @Input()
  requiredSkills!: Array<RequiredSkill>

  @Input() eventSport!: string;
  @Input() eventTitle!: string

  @Input() eventStartDate!: Date;
  @Input() eventEndDate!: Date;
  @Input() registrationStartDate!: Date;

  translateParam: any;

  referenceDateId = ReferenceDateId;

  get userIsLoggedIn(): boolean {
    return this.authenticationService.isLoggedIn();
  }

  constructor(private authenticationService: UserAuthorizationService) { }

  ngOnInit() {
    this.translateParam = {
      'eventSport': this.eventSport,
      'eventTitle': this.eventTitle,
    };
  }

  getReferenceDate(referenceDateId: number): Date | undefined {
    switch (referenceDateId) {
      case this.referenceDateId.OnEventStart:
        return this.eventStartDate;
      case this.referenceDateId.OnEventEnd:
        return this.eventEndDate;
      case this.referenceDateId.OnRegistrationDate:
        return new Date();
        default:
          return undefined;
    }
  }
}

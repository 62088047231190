<ng-container *ngIf="!isLoading">
  <div class="container">
      <h1>{{'EmailConfirmation' | transloco }}</h1>

      <div class="alert alert-success" *ngIf="success">
        <span *ngIf="!showDocumentsInPerson" >{{'MembershipApplication.EmailConfirmation.Msg.Success' | transloco }}</span>
        <span *ngIf="showDocumentsInPerson" [innerHTML]="'MembershipApplication.EmailConfirmation.Msg.SuccessShowDocumentsInPerson' | transloco "></span>  
      </div>

      <div class="alert alert-success" *ngIf="emailAlreadyConfirmed ">
        {{'MembershipApplication.EmailConfirmation.Msg.AlreadyConfirmed' | transloco }}
      </div>  

      <div *ngIf="(emailConfirmationTokenExpired || emailConfirmationNotInitialized) && !resendEmailConfirmationMailSuccess" class="alert alert-primary">
        <p>{{'MembershipApplication.EmailConfirmation.Msg.TokenExpired' | transloco}}</p>
        <button class="btn btn-default" style="margin-top:1em" (click)="resendEmailConfirmationMail()">{{'ResendConfirmationMail' | transloco }}</button>
      </div>

      <div *ngIf="resendEmailConfirmationMailSuccess" class="alert alert-primary">
        <p>{{'MembershipApplication.EmailConfirmation.Msg.ResendConfirmationMailSuccess' | transloco}}</p>
      </div>

      <div *ngIf="emailConfirmationTokenInvalid" class="alert alert-danger">
        {{'MembershipApplication.EmailConfirmation.Msg.TokenIsInvalid' | transloco}}
      </div>
  </div>
  
</ng-container>

<app-spinner *ngIf="isLoading" margin="0 auto"></app-spinner>
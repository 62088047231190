import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { User } from '../models';

@Component({
  selector: 'app-user-set-asvz-id',
  templateUrl: './user-set-asvz-id.component.html',
  styleUrls: ['./user-set-asvz-id.component.scss']
})
export class UserSetAsvzIdComponent implements OnInit {

  @Input() user!: User;
  @Input() asvzIdFormGroup!: FormGroup;
  @Input() submitted!: boolean;

  constructor() { }

  ngOnInit() {
  }

}

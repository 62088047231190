<h2>Sonderbelegungen</h2>
<div>
    <table class="table information-table">
        <tr *ngFor="let specialAssignment of specialAssignments">
            <td class="nospace-right font-normal">{{ specialAssignment.from  | dateFormat: "HH:mm" }} - {{ specialAssignment.to  | dateFormat: "HH:mm" }} </td>
            <td class="nospace-left font-normal">&nbsp;Uhr</td>
            <td class="font-normal">{{ specialAssignment.title }}</td>
            <td class="font-normal">{{ specialAssignment.room }}</td>
        </tr>
    </table>
</div>
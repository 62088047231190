<div class="container mt-3">
    <div class="d-flex justify-content-between">
        <h1>
            {{ 'HandHeldScanner.HandHeldScanner' | transloco }}
        </h1>
        <div>
            <div class="btn-group">
                <a routerLink="/handheldscanner/qr" title="QR" queryParamsHandling="preserve" routerLinkActive='active' class="btn btn-primary">
                    <i class="fa fa-qrcode text-white"></i>
                </a>
                <a routerLink="/handheldscanner/nfc" title="NFC" queryParamsHandling="preserve" routerLinkActive='active' class="btn btn-primary">
                    <i class="fa fa-credit-card text-white"></i>
                </a>
                <a routerLink="/handheldscanner/text" title="Text" queryParamsHandling="preserve" routerLinkActive='active' class="btn btn-primary">
                    <i class="fa fa-magnifying-glass text-white"></i>
                </a>
            </div>
        </div>
    </div>

    <router-outlet></router-outlet>

    <app-checkout-validation-history class="mt-3"></app-checkout-validation-history>
</div>
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LessonsService } from '../../services/lessons.service';
import { UserAuthorizationService } from '../../../security/user-authorization.service';
import { environment } from '../../../../environments/environment';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { AccountService } from '../../../account/account.service';
import { Account } from '../../../account/account.model';
import { RequiredSkill, ReferenceDateId } from '../../../events/required-skill.model';
import { PrerequisitesService } from '../../../shared/prerequisites-view/prerequisites.service';
import { BaseComponent } from '@premotec/ngx-essentials';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { Lesson } from '../../models/lesson.model';
import { LiveStream } from 'src/app/shared/models/live-stream.model';
import { TranslocoService } from '@ngneat/transloco';
import { Instructor } from 'src/app/events/instructor.model';
import { LiveStreamStatus } from 'src/app/shared/enum/live-stream-status.enum';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ConfirmationModalComponent } from 'src/app/shared/confirmation-modal/confirmation-modal.component';
import { Membership } from 'src/app/memberships/models';
import { MembershipService } from 'src/app/memberships/membership.service';
import { LessonStatusEnum } from '../../enums/lesson-status.enum';

@Component({
  selector: 'app-lesson-details',
  templateUrl: './lesson-details.component.html',
  styleUrls: ['./lesson-details.component.scss']
})
export class LessonDetailsComponent extends BaseComponent implements OnInit, OnDestroy {

  @ViewChild('lessonTabs') lessonTabs!: TabsetComponent;

  lessonId!: number;
  lesson!: Lesson;
  account!: Account;
  memberships!: Membership[];
  missingSkills: RequiredSkill[] = [];
  liveStream!: LiveStream;

  activeTab = "offer";

  referenedDateId = ReferenceDateId;
  lessonStatusEnum = LessonStatusEnum;

  lessonIsActive = false;
  webRegistrationTypeIsEnrollment = false;

  loginPageUrl = environment.loginPage;
  dataLoaded = false;

  displayTlToolTap = false;

  private subscription!: Subscription;

  get userIsLoggedIn(): boolean {
    return this.authenticationService.getUser().isAuthenticated();
  }

  get hasRoleOffice(): boolean {
    return this.authenticationService.getUser().hasRoleOffice();
  }

  get hasRoleAdmin(): boolean {
    return this.authenticationService.getUser().hasRoleAdmin();
  }

  constructor(private activatedRoute: ActivatedRoute,
    private lessonsService: LessonsService,
    private membershipService: MembershipService,
    private authenticationService: UserAuthorizationService,
    private accountService: AccountService,
    private prerequisitesService: PrerequisitesService,
    private titleService: Title,
    private translocoService: TranslocoService,
    private router: Router,
    private modalService: BsModalService) {
    super();
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.lessonId = +params['lessonId'];
      if (this.lessonId) {

        if (this.userIsLoggedIn) {
          // load memberships first, else the membership check could fail (if the check is performed befor memberships are loaded)
          // lesson is loaded within loadMemberships()
          this.loadMembershipsAndLesson();
        } else {
          this.loadLesson();
        }
      }
    });
  }

  override ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  // Opening the tl tool in a new tab
  openTlTool() {
    window.open(`${environment.tlToolBaseUrl}${this.lesson.tlToolUrl}`, '_blank');
  }

  private getTlToolTapPermission(instructors: Instructor[]): boolean {
    const currentUser = this.authenticationService.getUser();

    if (currentUser.hasRoleAdmin() || currentUser.hasRoleHspl() || currentUser.hasRoleOffice()) {
      return true;
    } else if (currentUser.hasRoleTrainingsleiter() && instructors) {
      return !!instructors.find(i => (i.asvzId === currentUser.asvzId));
    } else {
      return false;
    }
  }

  private loadMembershipsAndLesson() {
    this.membershipService.getMyMemberships().subscribe(x => {
      this.memberships = x.memberships;
      this.loadLesson();
    });
  }

  public loadLesson() {
    this.lessonsService.getLessonById(this.lessonId).subscribe(
      (lesson: Lesson) => {

        this.displayTlToolTap = this.getTlToolTapPermission(lesson.instructors);

        // set window title
        this.titleService.setTitle(`${lesson.sportName}: ${lesson.title}`);

        // lesson and enrollmentinfos before login
        this.lesson = lesson;

        if (this.userIsLoggedIn) {

          this.accountService.getAccountDetails().subscribe((account: Account) => {
            this.account = account;
            this.missingSkills = this.prerequisitesService.getMissingSkills(
              this.lesson.requiredSkills,
              account.skills!,
              this.lesson.enrollmentFrom,
              this.lesson.starts,
              this.lesson.ends
            );
            this.dataLoaded = true;
          });

          // load live stream if query param is present
          if (this.activatedRoute.snapshot.queryParamMap.get('livestream')) {
            this.loadLiveStream();
          }
        } else {
          this.missingSkills = lesson.requiredSkills;
          this.dataLoaded = true;
        }
      },
      (error => {
        if (error.status === 404) {
          this.router.navigate((['/notFound']));
        }
      })
    );
  }

  public loadLiveStream() {
    this.lessonsService.getLiveStream(this.lessonId).subscribe(
      (liveStream: LiveStream) => {
        this.liveStream = liveStream;

        // checks status and act accordingly
        if (this.liveStream.status === LiveStreamStatus.NotStartedYet) {
          this.activeTab = 'offer';

          const initialstate: ModalOptions = {
            initialState: {
              body: this.translocoService.translate('LiveStream.NotStartedYet', { 'ShowBeforeStartMinutes': this.liveStream.showBeforeStartInSeconds / 60 }),
              title: this.translocoService.translate('LiveStream.LiveStream'),
              icon: 'fa-solid fa-circle-info'
            }
          }

          const bsModalRef = this.modalService.show(ConfirmationModalComponent, initialstate);

        } else if (this.liveStream.status === LiveStreamStatus.Ended) {
          this.activeTab = 'offer';

          const initialstate: ModalOptions = {
            initialState: {
              body: this.translocoService.translate('LiveStream.Ended'),
              title: this.translocoService.translate('LiveStream.LiveStream'),
              icon: 'fa-solid fa-circle-info'
            }
          }

          const bsModalRef = this.modalService.show(ConfirmationModalComponent, initialstate);

        } else if (this.liveStream.status === LiveStreamStatus.Live || this.liveStream.status === LiveStreamStatus.StartSoon) {
          this.activeTab = 'livestream';
        }
      },
      (error => {
        this.activeTab = 'offer';
      })
    );
  }

  public showLiveStreamTab() {
    this.loadLiveStream();
  }
}

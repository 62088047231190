import { Component, OnInit, ViewChild } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { LotteryService } from '../../services/lottery.service';
import { EnrollmentLotteryHeaderModel } from '../../models/lottery-header.model';
import { AgGridDetailButtonComponent } from '../ag-grid-detail-button/ag-grid-detail-button.component';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-lottery-header-grid',
  templateUrl: './lottery-header-grid.component.html',
  styleUrls: ['./lottery-header-grid.component.scss'],
  providers: [DatePipe]
})
export class LotteryHeaderGridComponent implements OnInit {

  @ViewChild('agGrid') agGrid!: AgGridAngular;
  rowData: EnrollmentLotteryHeaderModel[] = [];
  columnDefs: any = [];
  defaultColDef = {
    sortable: true,
    filter: true,
    floatingFilter: true,
    resizable: true,
  }
  
  constructor(private lotteryService: LotteryService, private datePipe: DatePipe) { }

  ngOnInit(): void {

    this.lotteryService.getLotteryHeaders().subscribe((data: any) => {
      this.rowData = data;
      this.initGrid();
    });

  }

  onGridReady(params: any) {
    this.agGrid.api.sizeColumnsToFit();
  }

  initGrid() {
    this.columnDefs = [
      {
        field: 'actions',
        headerName: '',
        filter: false,
        sortable: false,
        cellRenderer: AgGridDetailButtonComponent,
        cellEditorPopupPosition: 'under',
        tooltipField: "edit",
        minWidth: 75,
        maxWidth: 75,
        pinned: 'left',
        type: 'client'
      },
      { headerName: 'lessonId', field: 'lessonId' },
      { headerName: 'status', field: 'status' },
      { headerName: 'enrollmentFrom', field: 'enrollmentFrom', valueFormatter: (params: any) => this.datePipe.transform(params.value, 'yyyy-MM-dd HH:mm:ss') },
      { headerName: 'lotteryTo', field: 'lotteryTo', valueFormatter: (params: any) => this.datePipe.transform(params.value, 'yyyy-MM-dd HH:mm:ss') },
      { headerName: 'Process Start', field: 'processStart', valueFormatter: (params: any) => this.datePipe.transform(params.value, 'yyyy-MM-dd HH:mm:ss') },
      { headerName: 'Process End', field: 'processEnd', valueFormatter: (params: any) => this.datePipe.transform(params.value, 'yyyy-MM-dd HH:mm:ss') },
      { headerName: 'Duration',  field: 'asvzId', valueGetter: function(params: any) {
        const start = new Date(params.data.processStart);
        const end = new Date(params.data.processEnd);
        return (end.getTime() - start.getTime()) / 1000;
      }}
    ];
  }


}

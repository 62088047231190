import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import * as moment from 'moment';

import { RegistrationStates } from './registration-states.enum';
import { environment } from '../../../environments/environment';
import { Registration } from './registration.model';
import { RegistrationSummary } from './registration-summary.model';
import { Event } from '../event.model';
import { RegistrationStatusChange } from './RegistrationStatusChange';
import { RegistrationKey } from './RegistrationKey';
import { HttpResponseErrorHandlerService } from 'src/app/shared/services/http-response-error-handler.service';
import { DiscountGiftCardPut } from 'src/app/shared/models/discount-giftcard/discount-giftcard-put.model';
import { EventRegistrationDetail } from '../event-registration-detail.model';
import { RegistrationList } from 'src/app/registrations/models/registration-list.model';
import { RegistrationCancelPaidInformationModel } from './models/registration-cancel-paid-information.model';


@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  constructor(private httpService: HttpClient,
    private httpResponseErrorHandler: HttpResponseErrorHandlerService) {
  }

  getRegistrationByEventId(eventId: number): Observable<RegistrationKey> {
    return this.httpService.get(`${environment.api}/Events/${eventId}/MyRegistration`)
      .pipe(
        map((registrations: any): RegistrationKey => registrations.data)
      );
  }

  getMyRegistrations(): Observable<Array<RegistrationList>> {
    return this.httpService.get(`${environment.api}/Registrations`)
      .pipe(
        map((registrations: any): Array<RegistrationList> => registrations)
      );
  }

  sortRegistrations(registration: RegistrationList[]): RegistrationList[] {
    return registration.sort((a, b) => {
      if (a.status === RegistrationStates.Temporary || a.status === RegistrationStates.New || a.status === RegistrationStates.ProcessPayment) {
        return -1;
      }
      if (a.status === b.status) {
        return 0;
      }
      return 1;
    });
  }

  registerForEvent(eventId: number): Observable<RegistrationStatusChange> {
    return this.httpService.post(`${environment.api}/Events/${eventId}/Register`, {})
      .pipe(
        map((registrations: any): RegistrationStatusChange => registrations.data)
      );
  }

  registerForWaitlist(eventId: number): Observable<Registration> {
    return this.httpService.post(`${environment.api}/Events/${eventId}/Waitlist`, {})
      .pipe(
        map((registrations: any): Registration => registrations.data),
        catchError(e => this.httpResponseErrorHandler.HandleResponseErrorWithNotification(e))
      );
  }

  getById(registrationId: number): Observable<RegistrationSummary> {
    return this.httpService.get(`${environment.api}/Registrations/${registrationId}`)
      .pipe(
        map((registrationSummary: any): RegistrationSummary => registrationSummary.data),
        catchError(e => this.httpResponseErrorHandler.HandleResponseError(e))
      );
  }

  setStateAcceptFree(registrationId: number): Observable<RegistrationStatusChange> {
    return this.setState(registrationId, 'ConfirmGratisRegistration');
  }

  setStateInitial(registrationId: number): Observable<RegistrationStatusChange> {
    return this.setState(registrationId, 'Initial');
  }

  setStateDelete(registrationId: number): Observable<RegistrationStatusChange> {
    return this.setState(registrationId, 'Deleted');
  }

  setStateCanceled(registrationId: number): Observable<RegistrationStatusChange> {
    return this.setState(registrationId, 'Canceled');
  }

  setStateDetails(registrationId: number, data: any): Observable<RegistrationStatusChange> {
    return this.setState(registrationId, 'Details', data);
  }

  setStateExpired(registrationId: number): Observable<RegistrationStatusChange> {
    return this.setState(registrationId, 'Expired');
  }

  setStateStartPayment(registrationId: number, discountGiftCardPut: DiscountGiftCardPut): Observable<RegistrationStatusChange> {
    return this.setState(registrationId, 'StartPayment', discountGiftCardPut);
  }

  /**
   * This method sets the state on the server and emits the state retrieved from the server
   */
  private setState(registrationId: number, state: string, data?: any): Observable<RegistrationStatusChange> {
    return this.httpService.put(`${environment.api}/Registrations/${registrationId}/${state}`, data)
      .pipe(
        map((response: any): RegistrationStatusChange => response.data),
        map((registration) => {
          return registration;
        }),
        catchError(e => this.httpResponseErrorHandler.HandleResponseError(e))
      );
  }

  /**
   * Used by the timer display
   * @param {number} registrationId
   * @returns {Observable<number>}
   */
  getTimeUntilExpiration(registrationId: number): Observable<number> {
    return this.httpService.get(`${environment.api}/Registrations/${registrationId}/ExpirationTimer`)
      .pipe(
        map((expirationDate: any): any => expirationDate.data),
        map((expirationDateObject: any): any => {
          const expiresOnDate = moment(expirationDateObject.expiresOn);
          return Math.floor(expiresOnDate.diff(moment()) / 1000);
        })
      );
    // don't catch the error as there could errors happen when changing sites (e.g. from temporary to payment when status gets payment)
    // .catch(e => this.httpResponseErrorHandler.HandleResponseError(e))
  }

  refreshUntilExpiration(registrationId: number): Observable<number> {
    return this.httpService.post(`${environment.api}/Registrations/${registrationId}/RefreshTimer`, {})
      .pipe(
        map((expirationDate: any): any => expirationDate.data),
        map((expirationDateObject: any): any => {
          const expiresOnDate = moment(expirationDateObject.expiresOn);
          return Math.floor(expiresOnDate.diff(moment()) / 1000);
        }),
        catchError(e => this.httpResponseErrorHandler.HandleResponseError(e))
      );
  }

  getIfStatusChanged(registrationId: number, currentStatus: number): Observable<boolean> {
    return this.httpService.get<boolean>(`${environment.api}/Registrations/${registrationId}/StatusChanged?status=${currentStatus}`);
  }

  getRegistrationDetails(event: Event, registrationSummary: RegistrationSummary) {
    let registrationDetails: EventRegistrationDetail[] = [];
    if (registrationSummary.registrationDetailValues!.length) {
      event.registrationDetails.forEach(detail => {
        const registrationDetailValues = registrationSummary.registrationDetailValues!
          .find(registrationDetailValue => detail.id === registrationDetailValue.eventRegistrationDetailId);
        if (registrationDetailValues) {
          detail.sort = registrationDetailValues.sort!;
          detail.defaultValue = registrationDetailValues.value!;
          registrationDetails.push(detail);
        }
      })
    } else {
      registrationDetails = event.registrationDetails;
    }
    registrationDetails.sort((a, b) => a.sort - b.sort);
    return registrationDetails;
  }

  confirmRegistration(registrationId: number, termsAndConditions: any): Observable<RegistrationStatusChange> {
    return this.httpService.put(`${environment.api}/Registrations/${registrationId}/ConfirmRegistration`, termsAndConditions)
      .pipe(
        map((registrations: any): RegistrationStatusChange => registrations.data)
      )
      ;
  }

  cancelPaidRegistration(registrationId: number): Observable<RegistrationStatusChange> {
    return this.httpService.put<RegistrationStatusChange>(`${environment.api}/Registrations/${registrationId}/CancelPaid`, 'cancel');
  }

  getCancelPaidRegistrationInformation(registrationId: number): Observable<any> {
    return this.httpService.get<any>(`${environment.api}/Registrations/${registrationId}/CancelPaid`);
  }

  registrationHasPayment(registration: RegistrationSummary) {
    if (registration != null && registration.paidAmount != null) {
      return registration.paidAmount > 0;
    }

    return false;
  }

  getCorrespondingRegistrationViewUrl(status: number, id: number, invoiceId: number, eventId: number): string {
    switch (status) {
      case RegistrationStates.New:
      case RegistrationStates.UserData:
      case RegistrationStates.Temporary:
        return `/registration/${id}/wizard`;
      case RegistrationStates.Canceled:
      case RegistrationStates.Expired:
        return `/events/${eventId}`;
      case RegistrationStates.ProcessPayment:
        return `/invoice/${invoiceId}/payment`;
      case RegistrationStates.Error:
      case RegistrationStates.Completed:
        return `/registration/${id}/details`;
      default:
        return `/events/${eventId}`
    }
  }
}

import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { TournamentService } from '../services/tournament.service';
import { TournamentFacilityModel } from '../models/tournament-facility.model';
import { TournamentRoomModel } from '../models/tournament-room.model';
import { SlotAddStateEnum } from './slot-add-state.enum';
import { TournamentSlotsCreateModel } from '../models/tournament-slots-create.model';
import * as moment from 'moment';
import { TournamentSlotsSeriesTypeEnum } from '../enums/tournament-slots-series-type.enum';
import { TournamentSlotsSeriesEndTypeEnum } from '../enums/tournament-slots-series-end-type.enum';
import { TournamentSlotValidationModel } from '../models/tournament-slot-validation.model';
import { TournamentSlotValidationTypeEnum } from '../enums/tournament-slot-validation-type.enum';
import { TournamentLocationModel } from '../models/tournament-location.model';
import { TournamentDisplayService } from '../services/tournament-display.service';

@Component({
    templateUrl: './tournament-planning-slots-add-modal.component.html',
    styleUrls: ['./tournament-planning-slots-add-modal.component.scss'],
})
export class TournamentPlanningSlotsAddModalComponent implements OnInit {

    @Output() confirmed = new EventEmitter<boolean>();

    tournamentPlanId!: string;

    tournamentFacilities!: TournamentFacilityModel[];
    tournamentRooms!: TournamentRoomModel[];

    tournamentSlotsForm!: UntypedFormGroup;
    tournamentSlotFacilityForm!: UntypedFormGroup;
    tournamentSlotSeriesForm!: UntypedFormGroup;

    formSubmitted = false;

    state: SlotAddStateEnum = SlotAddStateEnum.SelectFacility;

    locations: TournamentLocationModel[] = [];

    validatedSlots!: TournamentSlotValidationModel[];
    isSeries: boolean = false;

    SlotAddStateEnum = SlotAddStateEnum;
    TournamentSlotValidationTypeEnum = TournamentSlotValidationTypeEnum;

    isLoading!: boolean;

    constructor(
        private fb: FormBuilder,
        private bsModalRef: BsModalRef,
        private tournamentService: TournamentService,
        public tournamentdisplayService: TournamentDisplayService,
        private datePipe: DatePipe
    ) {
    }

    ngOnInit() {
        if (this.tournamentPlanId) {
            this.loadFacilties();

            this.initForms();
        }
    }

    initForms() {

        this.tournamentSlotFacilityForm = this.fb.group({
            facilityId: [null, Validators.required],
            roomId: [null, Validators.required],
            fieldId: [0, Validators.required],
        });

        this.tournamentSlotsForm = this.fb.group({
            startDate: [null, Validators.required],
            startTime: [null, [Validators.pattern(/^(0[0-9]|1[0-9]|2[0-3])[:.][0-5][0-9]$/), Validators.required]],
            length: [null, Validators.required],
            breakLength: [null],
            count: [null, Validators.required],
        });

        this.tournamentSlotSeriesForm = this.fb.group({
            type: [1, Validators.required],
            dayInterval: [],
            weekInterval: [],
            weekMon: [true, Validators.required],
            weekTue: [true, Validators.required],
            weekWed: [true, Validators.required],
            weekThu: [true, Validators.required],
            weekFri: [true, Validators.required],
            weekSat: [true, Validators.required],
            weekSun: [true, Validators.required],
            endIntervalCount: [],
            endIntervalDate: [],
            endType: [1, Validators.required],
        });

        this.seriesTypeUpdated();
        this.seriesEndTypeUpdated();
    }

    getSlotsCreateModel(): TournamentSlotsCreateModel {
        var model = new TournamentSlotsCreateModel();
        // locations
        model.locations = this.locations;

        // basic data
        var startDate = this.tournamentSlotsForm.controls['startDate']?.value;
        var startTime = this.tournamentSlotsForm.controls['startTime']?.value;
        startTime = startTime.replace(".", ":");
        model.startDateTime = this.mergingTimeAndDate(startDate, startTime)!;

        model.length = this.tournamentSlotsForm.controls['length']?.value;
        model.breakLength = this.tournamentSlotsForm.controls['breakLength']?.value ?? 0;
        model.count = this.tournamentSlotsForm.controls['count']?.value;

        // series
        if (this.isSeries) {
            model.seriesType = this.tournamentSlotSeriesForm.controls['type']?.value;
            model.seriesEndType = this.tournamentSlotSeriesForm.controls['endType']?.value;
            model.dayInterval = this.tournamentSlotSeriesForm.controls['dayInterval']?.value;
            model.weekInterval = this.tournamentSlotSeriesForm.controls['weekInterval']?.value;
            model.endIntervalCount = this.tournamentSlotSeriesForm.controls['endIntervalCount']?.value;
            model.endIntervalDate = this.tournamentSlotSeriesForm.controls['endIntervalDate']?.value;
            model.dayIntervalWeekMon = this.tournamentSlotSeriesForm.controls['weekMon']?.value;
            model.dayIntervalWeekTue = this.tournamentSlotSeriesForm.controls['weekTue']?.value;
            model.dayIntervalWeekWed = this.tournamentSlotSeriesForm.controls['weekWed']?.value;
            model.dayIntervalWeekThu = this.tournamentSlotSeriesForm.controls['weekThu']?.value;
            model.dayIntervalWeekFri = this.tournamentSlotSeriesForm.controls['weekFri']?.value;
            model.dayIntervalWeekSat = this.tournamentSlotSeriesForm.controls['weekSat']?.value;
            model.dayIntervalWeekSun = this.tournamentSlotSeriesForm.controls['weekSun']?.value;
        } else {
            model.seriesType = TournamentSlotsSeriesTypeEnum.Daily;
            model.seriesEndType = TournamentSlotsSeriesEndTypeEnum.Count;
            model.dayInterval = 1;
            model.endIntervalCount = 1;
        }

        return model;
    }

    loadFacilties() {
        this.tournamentService.getTournamentFacilities(this.tournamentPlanId).subscribe(x => {
            x = x.sort((a, b) => a.name.localeCompare(b.name));
            x.forEach(f => {
                f.rooms = f.rooms.sort((a, b) => a.name.localeCompare(b.name));
            });

            this.tournamentFacilities = x;
        })
    }

    validateSlots() {
        this.isLoading = true;
        this.validatedSlots = [];
        var model = this.getSlotsCreateModel();

        this.tournamentService.validateTournamentSlots(this.tournamentPlanId, model).subscribe(x => {
            this.validatedSlots = x;
            this.isLoading = false;
        }, err => {
            this.isLoading = false;
        })
    }

    createSlots() {
        this.isLoading = true;
        var model = this.getSlotsCreateModel();
        this.tournamentService.createTournamentSlots(this.tournamentPlanId, model).subscribe(x => {
            this.isLoading = false;
            this.confirm();
        }, err => {
            this.isLoading = false;
        });
    }

    onFacilityChange() {
        this.updateRooms();
    }

    updateRooms() {
        this.tournamentRooms = [];
        this.tournamentSlotFacilityForm.controls['roomId'].setValue(null);
        let facilityId = this.tournamentSlotFacilityForm.controls['facilityId'].value;
        if (facilityId == null) return;

        var facility = this.tournamentFacilities.find(x => x.id == facilityId);
        this.tournamentRooms = facility!.rooms;

        if (facility!.rooms.length == 1) {
            this.tournamentSlotFacilityForm.controls['roomId'].setValue(facility!.rooms[0].id);
        }
    }

    seriesTypeUpdated() {
        this.tournamentSlotSeriesForm.controls['dayInterval'].clearValidators();
        this.tournamentSlotSeriesForm.controls['dayInterval'].updateValueAndValidity();

        this.tournamentSlotSeriesForm.controls['weekInterval'].clearValidators();
        this.tournamentSlotSeriesForm.controls['weekInterval'].updateValueAndValidity();

        if (this.tournamentSlotSeriesForm.controls['type']?.value == TournamentSlotsSeriesTypeEnum.Daily) {
            this.tournamentSlotSeriesForm.controls['dayInterval'].addValidators(Validators.required);
            this.tournamentSlotSeriesForm.controls['dayInterval'].updateValueAndValidity();
        }

        if (this.tournamentSlotSeriesForm.controls['type']?.value == TournamentSlotsSeriesTypeEnum.Weekly) {
            this.tournamentSlotSeriesForm.controls['weekInterval'].addValidators(Validators.required);
            this.tournamentSlotSeriesForm.controls['weekInterval'].updateValueAndValidity();
        }
    }

    seriesEndTypeUpdated() {
        this.tournamentSlotSeriesForm.controls['endIntervalCount'].clearValidators();
        this.tournamentSlotSeriesForm.controls['endIntervalCount'].updateValueAndValidity();

        this.tournamentSlotSeriesForm.controls['endIntervalDate'].clearValidators();
        this.tournamentSlotSeriesForm.controls['endIntervalDate'].updateValueAndValidity();

        if (this.tournamentSlotSeriesForm.controls['endType']?.value == TournamentSlotsSeriesEndTypeEnum.Count) {
            this.tournamentSlotSeriesForm.controls['endIntervalCount'].addValidators(Validators.required);
            this.tournamentSlotSeriesForm.controls['endIntervalCount'].updateValueAndValidity();
        }

        if (this.tournamentSlotSeriesForm.controls['endType']?.value == TournamentSlotsSeriesEndTypeEnum.Date) {
            this.tournamentSlotSeriesForm.controls['endIntervalDate'].addValidators(Validators.required);
            this.tournamentSlotSeriesForm.controls['endIntervalDate'].updateValueAndValidity();
        }
    }

    startTimeUpdate() {
        this.tournamentSlotsForm.controls['startDate']?.clearValidators();

        var value = this.tournamentSlotsForm.controls['startDate']?.value
        if (value == null || value == undefined) return;

        this.tournamentSlotsForm.controls['startDate']?.setErrors(Validators.pattern);
    }

    addLocation() {
        var model = new TournamentLocationModel();


        model.imFacilityId = this.tournamentSlotFacilityForm.controls['facilityId']?.value;
        model.imRoomId = this.tournamentSlotFacilityForm.controls['roomId']?.value;
        model.fieldId = this.tournamentSlotFacilityForm.controls['fieldId']?.value;

        var facility = this.tournamentFacilities.filter(x => x.id == model.imFacilityId)[0];
        var room = this.tournamentRooms.filter(x => x.id == model.imRoomId)[0];

        model.facilityName = facility.name;
        model.roomName = room.name;

        this.locations.push(model);
        this.locations = this.locations.sort((a, b) => `${a.facilityName} ${a.roomName} ${a.fieldId + 10}`.localeCompare(`${b.facilityName} ${b.roomName} ${b.fieldId + 10}`));
    }

    removeLocation(location: TournamentLocationModel) {
        this.locations = this.locations.filter(x => x.fieldId != location.fieldId || x.imFacilityId != location.imFacilityId || x.imRoomId != location.imRoomId);
    }

    canAddLocation(): boolean {
        var imFacilityId = this.tournamentSlotFacilityForm.controls['facilityId']?.value;
        var imRoomId = this.tournamentSlotFacilityForm.controls['roomId']?.value;
        var fieldId = this.tournamentSlotFacilityForm.controls['fieldId']?.value;

        if (imFacilityId == null || imRoomId == null || fieldId == null) return false;

        var matching = this.locations.filter(x => x.fieldId == fieldId && x.imFacilityId == imFacilityId && x.imRoomId == imRoomId);
        if (matching.length) return false;

        return true;
    }

    next() {
        if (this.state == SlotAddStateEnum.SelectFacility) {

            this.formSubmitted = true;
            if (!this.locations.length) return;
            this.formSubmitted = false;

            this.state = SlotAddStateEnum.BasicSettings;
            return;
        }
        if (this.state == SlotAddStateEnum.SeriesSettings) {
            this.formSubmitted = true;

            this.tournamentSlotSeriesForm.markAllAsTouched();
            console.log(this.tournamentSlotSeriesForm.valid);
            if (!this.tournamentSlotSeriesForm.valid) return;
            this.formSubmitted = false;

            this.validateSlots();
            this.state = SlotAddStateEnum.Overview;
            return;
        }
    }

    back() {
        if (this.state == SlotAddStateEnum.Overview && this.isSeries) {

            this.state = SlotAddStateEnum.SeriesSettings;
            return;
        }

        if (this.state == SlotAddStateEnum.Overview && !this.isSeries) {

            this.state = SlotAddStateEnum.BasicSettings;
            return;
        }

        if (this.state == SlotAddStateEnum.BasicSettings) {

            this.state = SlotAddStateEnum.SelectFacility;
            return;
        }

        if (this.state == SlotAddStateEnum.SeriesSettings) {

            this.state = SlotAddStateEnum.BasicSettings;
            return;
        }
    }

    checkCreate() {
        this.formSubmitted = true;
        this.tournamentSlotsForm.markAllAsTouched();
        if (!this.tournamentSlotsForm.valid) return;
        this.formSubmitted = false;

        this.isSeries = false;
        this.validateSlots();
        this.state = SlotAddStateEnum.Overview;
    }

    create() {
        this.createSlots();
    }

    createSeries() {
        this.formSubmitted = true;
        this.tournamentSlotsForm.markAllAsTouched();
        if (!this.tournamentSlotsForm.valid) return;
        this.formSubmitted = false;

        this.isSeries = true;
        this.state = SlotAddStateEnum.SeriesSettings;
    }

    confirm() {
        this.confirmed.next(true);
        this.bsModalRef.hide();
    }

    decline() {
        this.bsModalRef.hide();
    }

    getAllSlotLocations(): TournamentSlotValidationModel[] {
        var locations: TournamentSlotValidationModel[] = [];
        if (!this.validatedSlots) return locations;

        this.validatedSlots.forEach(x => {
            var matching = locations.filter(l => l.roomId == x.roomId && l.facilityId == x.facilityId && l.fieldId == x.fieldId);
            if (!matching.length) {
                var facility = this.tournamentFacilities.filter(f => f.id == x.facilityId)[0];
                var room = facility.rooms.filter(r => r.id == x.roomId)[0];

                x.facilityName = facility.name;
                x.roomName = room.name;
                locations.push(x);
            }
        });

        this.locations = this.locations.sort((a, b) => `${a.facilityName} ${a.roomName} ${a.fieldId + 10}`.localeCompare(`${b.facilityName} ${b.roomName} ${b.fieldId + 10}`));

        return locations;
    }

    getAllSlotDays(facilityId: number, roomId: number, fieldId: number): Date[] {
        var days: Date[] = [];
        if (!this.validatedSlots) return days;

        var matchingSlots = this.validatedSlots.filter(x => x.facilityId == facilityId && x.roomId == roomId && x.fieldId == fieldId);
        if (!matchingSlots) return days;

        matchingSlots.forEach(x => {
            var newDate = new Date(x.from);
            newDate = this.mergingTimeAndDate(newDate, '00:00')!;

            if (!this.isDateInArray(newDate, days)) days.push(newDate);
        });

        return days;
    }

    getAllSlotFromDay(day: Date, facilityId: number, roomId: number, fieldId: number): TournamentSlotValidationModel[] {
        var slots: TournamentSlotValidationModel[] = [];
        if (!this.validatedSlots) return slots;

        var matchingSlots = this.validatedSlots.filter(x => x.facilityId == facilityId && x.roomId == roomId && x.fieldId == fieldId);
        if (!matchingSlots) return slots;

        let checkDay = new Date(day);

        let checkTodayDay = this.mergingTimeAndDate(checkDay, '00:00')!;
        let checkNextDay = this.mergingTimeAndDate(checkDay.setDate(checkDay.getDate() + 1), '00:00')!;

        matchingSlots.forEach(x => {
            let startDate = new Date(x.from);
            if (new Date(startDate) >= checkTodayDay && startDate < checkNextDay) {
                slots.push(x);
            }
        });

        return slots;
    }

    isDateInArray(item: Date, items: Date[]) {
        for (var i = 0; i < items.length; i++) {
            if (item.getTime() === items[i].getTime()) {
                return true;
            }
        }
        return false;
    }

    private mergingTimeAndDate(date: any, time: string): Date | null {

        // Validate if time has the following formats: H:mm or HH:mm
        const timePattern = /^([01]\d|2[0-3]):?([0-5]\d)$/gm
        if (!timePattern.test(time)) {
            return null;
        }

        const mergedDateTime = `${moment(date).format('YYYY-MM-DD')} ${time}`;
        return moment(mergedDateTime).toDate();
    }
}

import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import { BaseComponent } from '@premotec/ngx-essentials';
import { Router } from '@angular/router';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgClass } from '@angular/common';
import { environment } from 'src/environments/environment';

import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { TranslocoService } from '@ngneat/transloco';
import { ConfirmationModalComponent } from 'src/app/shared/confirmation-modal/confirmation-modal.component';
import { MembershipApplicationsService } from '../../membership-applications.service';
import { Application, Configuration, ApplicationDocument } from '../../models';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FileUploaderComponent,
      multi: true
    }
  ]
})
export class FileUploaderComponent extends BaseComponent implements OnInit, ControlValueAccessor {

  @Input() componentId: any;
  @Input() uploadURL = `${environment.api}`;
  @Input() ngClass: any;

  applicationFiles: any[] = [];
  files = [];
  onChange: any;
  isDisabled = false;

  @Input() application!: Application;
  @Input() config!: Configuration;

  constructor(
    private applicationService: MembershipApplicationsService,
    private router: Router,
    private modalService: BsModalService,
    private translocoService: TranslocoService) {
    super();
  }

  writeValue(obj: any): void {
    if (Array.isArray(obj)) {
      this.applicationFiles = obj;
    } else {
      this.applicationFiles.push(obj);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  ngOnInit() {
    this.applicationFiles = this.applicationFiles.sort((a, b) => a.index - b.index);
  }

  onFileChange(event: any) {
    if (event.target.files.length > 0) {
      const files = event.target.files;
      for (let i = 0; i < files.length; i++) {
        this.uploadFile(files[i]);
      }
    }
  }

  uploadFile(data: any) {
    const formData = new FormData();
    formData.append('formFile', data);

    this.whileImAlive(this.applicationService.uploadConfigDetailDocument(this.application.id, this.config.id, formData)).subscribe(res => {
      this.writeValue(res);
      this.onChange(res);
    });

  }

  deleteFile(file: ApplicationDocument) {
    const id = file.id;
    this.whileImAlive(this.applicationService.deleteConfigDetailDocument(this.application.id, id)).subscribe(res => {
      this.applicationFiles = this.applicationFiles.filter(f => f.id !== file.id);
    });
  }

  downloadFile(file: any) {
    this.whileImAlive(this.applicationService.getConfigFileDownloadId(this.application.id, file.id))
      .subscribe((res: any) => {
        window.open(`${environment.api}/FileDownloads/${res.downloadId}`, '_blank')
      });
  }

  downloadNewFile(file: any) {
    this.router.navigateByUrl(file.name);
  }

  showDeleteModal(file: any) {
    const initialstate: ModalOptions = {
      initialState: {
        body: this.translocoService.translate('ConfirmDeleteAction'),
      }
    }

    const bsModalRef = this.modalService.show(ConfirmationModalComponent, initialstate);


    bsModalRef.content!.confirmed.subscribe(() => {
      this.deleteFile(file);
    })

  }

}

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { TournamentService } from '../services/tournament.service';
import { TournamentModel } from '../models/tournament.model';
import { TournamentTypeEnum } from '../enums/tournament-type.enum';
import { TournamentConfigruationModalComponent } from '../tournament-configuration-modal/tournament-configuration-modal.component';
import { TournamentEventGroupConfigruationModalComponent } from '../tournament-event-group-configuration-modal/tournament-event-group-configuration-modal.component';
import { TournamentEventModel } from '../models/tournament-event.model';
import { TournamentEventsAddModalComponent } from '../tournament-events-add-modal/tournament-events-add-modal.component';
import { TournamentEventGameConfigruationModalComponent } from '../tournament-event-game-configuration-modal/tournament-event-game-configuration-modal.component';
import { TournamentEventDataMappingModalComponent } from '../tournament-event-data-mapping-modal/tournament-event-data-mapping-modal.component';
import { environment } from 'src/environments/environment';
import { ConfirmationModalComponent } from 'src/app/shared/confirmation-modal/confirmation-modal.component';
import { ToastrService } from 'ngx-toastr';
import { TournamentEventGameLinksModalComponent } from '../tournament-event-game-links-modal/tournament-event-game-links-modal.component';
import { TournamentDisplayService } from '../services/tournament-display.service';

@Component({
    selector: 'app-tournament-events-details',
    templateUrl: './tournament-events-details.component.html',
    styleUrls: ['./tournament-events-details.component.scss'],
})
export class TournamentEventsDetailsComponent implements OnInit {

    @Input() tournamentPlanId!: string;

    tournamentEvents!: TournamentEventModel[];

    baseUrl: string = environment.localSite;

    constructor(
        private translocoService: TranslocoService,
        private tournamentDisplayService: TournamentDisplayService,
        private activatedRoute: ActivatedRoute,
        private tournamentService: TournamentService,
        private fb: FormBuilder,
        private datepipe: DatePipe,
        private toastrService: ToastrService,
        private modalService: BsModalService) {
    }

    ngOnInit() {
        if (this.activatedRoute.parent) {
            this.activatedRoute.parent.params.subscribe((params: Params) => {
                this.tournamentPlanId = params['tournamentPlanId'];
                this.loadEvents();
            });
        }
    }

    loadEvents() {
        this.tournamentService.getAllTournamentEvents(this.tournamentPlanId).subscribe(x => {
            this.tournamentEvents = x.sort((a, b) => a.eventTitle.localeCompare(b.eventTitle));
            this.tournamentEvents.forEach(x => {
                if (x.additionLink) {
                    x.additionLink = this.tournamentDisplayService.getAdditionLink(x.additionLink);
                }
            });
        });
    }

    openRemoveEventConfirmModal(id: string) {
        const initialstate: ModalOptions = {
            initialState: {
                body: this.translocoService.translate('Tournaments.Events.ConfirmDelete'),
                color: 'danger',
                confirmText: this.translocoService.translate('Delete')
            }
        }

        const bsModalRef = this.modalService.show(ConfirmationModalComponent, initialstate);

        bsModalRef.content!.confirmed.subscribe(() => {
            this.removeEvent(id);
        })
    }

    removeEvent(id: string) {
        this.tournamentService.removeTournamentEvent(this.tournamentPlanId, id).subscribe(x => {
            if (x) {
                this.toastrService.success(this.translocoService.translate('Tournaments.Events.DeleteSuccess'));
                this.loadEvents();
            } else {
                this.toastrService.error(this.translocoService.translate('Tournaments.Events.DeleteError'));
            }
        });
    }

    openAdditionalLinkModal(eventId: string) {


        const initialState: ModalOptions = {
            backdrop: 'static',
            // class: 'modal-lg',
            keyboard: false,
            initialState: {
                tournamentPlanId: this.tournamentPlanId,
                eventId: eventId
            },
        };

        const bsModalRef = this.modalService.show(TournamentEventGameLinksModalComponent, initialState);
        bsModalRef.content?.confirmed.subscribe(x => {
            this.loadEvents();
        });
    }

    openMappingModal(eventId: string) {
        const initialState: ModalOptions = {
            backdrop: 'static',
            class: 'modal-xl',
            keyboard: false,
            initialState: {
                tournamentPlanId: this.tournamentPlanId,
                eventId: eventId,
            },
        };

        const bsModalRef = this.modalService.show(TournamentEventDataMappingModalComponent, initialState);
        bsModalRef.content?.confirmed.subscribe(x => {
            this.loadEvents();
        });
    }

    openAddEventModal() {
        const initialState: ModalOptions = {
            backdrop: 'static',
            // class: 'modal-lg',
            keyboard: false,
            initialState: {
                tournamentPlanId: this.tournamentPlanId,
            },
        };

        const bsModalRef = this.modalService.show(TournamentEventsAddModalComponent, initialState);
        bsModalRef.content?.confirmed.subscribe(x => {
            this.loadEvents();
        });
    }

    openGroupSettingsModal(id: string) {
        const initialState: ModalOptions = {
            backdrop: 'static',
            // class: 'modal-lg',
            keyboard: false,
            initialState: {
                tournamentPlanId: this.tournamentPlanId,
                eventId: id
            },
        };

        const bsModalRef = this.modalService.show(TournamentEventGroupConfigruationModalComponent, initialState);
        bsModalRef.content?.confirmed.subscribe(x => {
            this.loadEvents();
        });
    }

    openGameSettingsModal(id: string) {
        const initialState: ModalOptions = {
            backdrop: 'static',
            // class: 'modal-lg',
            keyboard: false,
            initialState: {
                tournamentPlanId: this.tournamentPlanId,
                eventId: id
            },
        };

        const bsModalRef = this.modalService.show(TournamentEventGameConfigruationModalComponent, initialState);
        bsModalRef.content?.confirmed.subscribe(x => {
            this.loadEvents();
        });
    }



}

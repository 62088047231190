import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { PspInstruction } from './psp-instruction.model';

import { InvoiceType } from './invoice-type.model';
import { PostFinanceCheckoutTransaction } from './post-finance-checkout-transaction.model';
import { HttpResponseErrorHandlerService } from '../shared/services/http-response-error-handler.service';

@Injectable()
export class InvoiceService {
  constructor(private httpService: HttpClient,
    private router: Router,
    private httpResponseErrorHandler: HttpResponseErrorHandlerService) {
  }

  putPaymentCancelByInvoiceId(invoiceId: number): Observable<InvoiceType> {
    return this.httpService.put(`${environment.api}/Invoices/${invoiceId}/CancelPayment`, null)
      .pipe(
        map((response: any): InvoiceType => response.data)
      );
    // NOTE: Don't capture any errors here as the UI component will handle an error response
    // .catch(e => this.httpResponseErrorHandler.HandleResponseError(e))
  }

  passOnPaymentResponse(parameters: string): Observable<object> {
    return this.httpService.get(`${environment.api}/Invoices/PostFinanceResponse/${parameters}`);

    // NOTE: Don't capture any errors here as the UI component will handle an error response
    // .catch(e => this.httpResponseErrorHandler.HandleResponseError(e))
  }

  getPspInstructionByInvoiceId(invoiceId: number): Observable<PspInstruction> {
    return this.httpService.get(`${environment.api}/Invoices/${invoiceId}/PspInstructions`)
      .pipe(
        map((response: any): PspInstruction => response),
      );
  }

  getInvoiceType(invoiceId: number): Observable<InvoiceType> {
    return this.httpService.get(`${environment.api}/Invoices/${invoiceId}/Type`)
      .pipe(
        map((response: any): InvoiceType => response.data),
        catchError(e => this.httpResponseErrorHandler.HandleResponseError(e))
      );
  }

  getPostFinanceCheckoutTransaction(invoiceId: number): Observable<PostFinanceCheckoutTransaction> {
    return this.httpService.get(`${environment.api}/Invoices/${invoiceId}/PostFinanceCheckoutTransaction`)
      .pipe(
        map((response: any): PostFinanceCheckoutTransaction => response),
        catchError(e => this.httpResponseErrorHandler.HandleResponseError(e))
      );
  }
}


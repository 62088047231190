<dl *ngIf="event.number">
  <dt>{{'Number' | transloco}}</dt>
  <dd>{{event.number}}</dd>
</dl>
<dl *ngIf="event.sportName">
  <dt>{{'Sportform' | transloco}}</dt>
  <!-- TODO: Get the url from adress object -->
  <dd><a href="{{event.sportUrl}}" target="_blank">{{event.sportName}}</a></dd>
</dl>

<dl>
  <dt>{{'Title' | transloco}}</dt>
  <dd>{{event.title}}</dd>
</dl>
<dl *ngIf="event.starts">
  <dt>{{'Date' | transloco}}</dt>
  <dd>{{event.starts | fromToDate: event.ends : 'DD.MM.YYYY': false}}</dd>
</dl>

<dl *ngIf="event.subEvents?.length">
  <dt>{{'Lessons' | transloco}}</dt>
  <dd *ngFor="let subEvent of event.subEvents" class="mb-0">
    {{subEvent.starts | fromToDate: subEvent.ends : 'DD.MM.YYYY': false}}<span *ngIf="subEvent.title">,
      {{subEvent.title}}</span>
  </dd>
</dl>

<dl *ngIf="event.facilities?.length">
  <dt>{{'Facility_Facility' | transloco}}</dt>
  <dd *ngFor="let facility of event.facilities" class="mb-0">
    <span *ngIf="facility.url"><a [href]="facility.url" target="_blank">{{facility.name}}</a></span>
    <span *ngIf="!facility.url">{{facility.name}}</span>
  </dd>
</dl>

<dl *ngIf="event.rooms?.length">
  <dt>{{'Room' | transloco}}</dt>
  <dd *ngFor="let room of event.rooms" class="mb-0">
    {{room}}
  </dd>
</dl>

<dl *ngIf="event.location">
  <dt>{{'Place' | transloco}}</dt>
  <dd>{{event.location}}</dd>
</dl>

<!-- Meeting point -->
<dl *ngIf="event.meetingPointInfo">
  <dt>{{'Meetingpoint' | transloco}}</dt>
  <dd>
    <span *ngIf="!event.meetingPointCoordinates">{{event.meetingPointInfo}}</span>
    <a *ngIf="event.meetingPointCoordinates"
      [href]="'https://www.google.com/maps/search/?api=1&query='+event.meetingPointCoordinates"
      target="_blank">{{event.meetingPointInfo}}</a>
  </dd>
</dl>

<dl *ngIf="showPriceLabel()">
  <dt>{{'Price' | transloco}}</dt>
  <dd *ngIf="event.displayPriceType === displayPriceType.PriceInChF">
    <ul class="list-unstyled">
      <li *ngFor="let price of event.priceList">
        {{'PriceTypes.Type_'+price.priceType | transloco}}: {{price.price | currencyFormat}}
      </li>
      <li *ngIf="additionalPrice != null && additionalPrice > 0">
        {{'AdditionalPrice' | transloco}}: + {{additionalPrice | currencyFormat}}
      </li>
      <li *ngIf="additionalPrice != null && additionalPrice > 0">
        <span style="font-weight: bold;">{{'TotalPrice' | transloco}}:</span> {{calcTotalPrice() | currencyFormat}}
      </li>
    </ul>
  </dd>
  <dd *ngIf="event.displayPriceType !== displayPriceType.PriceInChF">
    {{event.displayPriceInfo}}
  </dd>
</dl>

<dl *ngIf="canShowSeatingInfo">
  <dt>{{'FreePlaces' | transloco}}</dt>
  <dd>
    <span class="badge bg-secondary" style="font-size: 14px; line-height: 1.1;"
      *ngIf="hasUnlimitedPlaces()">{{'Unlimited' | transloco}}</span>
    <span class="badge bg-secondary" style="font-size: 14px; line-height: 1.1;"
      *ngIf="!hasUnlimitedPlaces()">{{event.participantsMax - event.participantCount}}</span>
    <i style="font-size: 15px;" *ngIf="event.participantsMin && getRemainingMinimumParticipants() > 0">
      <br />{{'SeatsRemaining' | transloco: {seats: getRemainingMinimumParticipants()} }}
    </i>
  </dd>
</dl>

<dl *ngIf="event.levelInfo">
  <dt>{{'Level' | transloco}}</dt>
  <dd>{{event.levelInfo}}</dd>
</dl>

<dl *ngIf="event.languageInfo">
  <dt>{{'LanguageInfo' | transloco}}</dt>
  <dd>{{event.languageInfo}}</dd>
</dl>


<dl *ngIf="event.instructors?.length || event.tlComment">
  <dt>{{'Instructors' | transloco}}</dt>
  <div *ngIf="event.instructors?.length" class="mb-3">
    <dd *ngFor="let instructor of event.instructors" class="mb-0">
      {{instructor.name}}
    </dd>
  </div>


  <i *ngIf="event.tlComment">
    {{'TlComment' | transloco}}: {{event.tlComment}}
  </i>
</dl>


<dl *ngIf="event.registrationFrom && event.registrationUntil">
  <dt>{{'RegistrationPeriod' | transloco}}</dt>
  <dd><abbr>
      {{event.registrationFrom | fromToDate: event.registrationUntil : '': false}}</abbr>
  </dd>
</dl>

<dl *ngIf="event.cancelationUntil">
  <dt>{{'CancelationUntil' | transloco}}</dt>
  <dd><abbr>
      {{event.cancelationUntil | dateFormat: "DD.MM.YYYY HH:mm"}}</abbr>
  </dd>
</dl>

<dl>
  <dt>{{'AGB' | transloco}}</dt>
  <dd><a href="http://asvz.ch/avb" target="_blank">AVB</a></dd>
</dl>
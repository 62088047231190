import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BaseComponent } from '@premotec/ngx-essentials';
import { AccountService } from 'src/app/account/account.service';
import { AccountPassPhotoUploaderComponent } from 'src/app/shared/account-pass-photo-uploader/account-pass-photo-uploader.component';
import { Account } from '../../../account/account.model';
import { MembershipState } from '../../membership-state.enum';
import { MembershipService } from '../../membership.service';
import { MembershipExtension } from '../../models';

@Component({
  selector: 'app-membership-check-pass-photo',
  templateUrl: './membership-check-pass-photo.component.html',
  styleUrls: ['./membership-check-pass-photo.component.scss']
})
export class MembershipCheckPassPhotoComponent extends BaseComponent implements OnInit {

  @Output() nextState: EventEmitter<number> = new EventEmitter();
  @Input() membershipExtension!: MembershipExtension;

  @ViewChild(AccountPassPhotoUploaderComponent) accountPassPhotoUploader!: AccountPassPhotoUploaderComponent;

  disableSendButton = false;

  account!: Account;
  currentPicture! : any;
  currentMembershipExtensionPicture! : any;

  constructor(
    private accountService: AccountService,
    private membershipService: MembershipService) {
    super();
  }

  ngOnInit() {
    this.getAccount();
  }

  next() {

    // check if a file was loaded and not uploaded yet. If this is the case => upload the file first
    if (!this.accountPassPhotoUploader.fileUploaded && this.accountPassPhotoUploader.fileLoaded) {
      const formData = this.accountPassPhotoUploader.setFormData()

      this.membershipService.uploadMembershipExtensionPicture(formData, this.membershipExtension.personMembershipId).subscribe(res => {
        this.nextState.emit(MembershipState.New);
      });
    } else {
      this.nextState.emit(MembershipState.New);
    }
  }

  getAccount() {
    this.whileImAlive(
      this.accountService.getAccountDetails()
    ).subscribe((data) => {
       this.account = data;
       this.getCurrentPicture();
       this.getcurrentMembershipExtensionPicture();
    });
  }

  getCurrentPicture() {
    this.whileImAlive(this.accountService.getPicture()).subscribe(pictureBlob => {
      this.currentPicture = pictureBlob;
    },
    (error: HttpErrorResponse) => {
      // user has no picture

      if (this.account.hasPrintCard) {
        this.disableSendButton = true;
      }
    });
  }

  getcurrentMembershipExtensionPicture() {
    this.whileImAlive(this.membershipService.getMembershipExtensionPicture(this.membershipExtension.id)).subscribe(pictureBlob => {
      this.currentMembershipExtensionPicture = pictureBlob;
      this.disableSendButton = false;
    },
    (error: HttpErrorResponse) => {
      // user has no membership extension picture
    });
  }

  passPhotoChanged() {
    this.getcurrentMembershipExtensionPicture();
  }
}

<ng-container *ngIf="account">
  <div *ngIf="account.hasPrintCard && !currentPicture" class="alert alert-warning" role="alert">
    {{ 'PassportPhotoNeeded' | transloco }}
  </div>
  <div class="alert-primary alert" role="alert">{{'MembershipExtension.CanChooseNewPassportPhoto' | transloco}}
  </div>
  <div class="row">
    <div *ngIf="currentPicture" class="col-md-6 col-sm-6 col-xs-12">
      <dl>
        <dt>{{'PassportPhoto' | transloco}}</dt>
        <dd>
          <img id="image" *ngIf="currentPicture" src="data:image/JPEG;base64,{{currentPicture}}" class="image"
            width="200" />
        </dd>
      </dl>
    </div>
    <div *ngIf="currentMembershipExtensionPicture" class="col-md-6 col-sm-6 col-xs-12">
      <dl>
        <dt>{{'MembershipExtension.PassportPhotoNew' | transloco}}</dt>
        <dd><img id="image" *ngIf="currentMembershipExtensionPicture" src="data:image/JPEG;base64,{{currentMembershipExtensionPicture}}"
            class="image" width="200" /></dd>
      </dl>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <app-account-pass-photo-uploader (passPhotoChangedEvent)="passPhotoChanged()"
        [membershipExtension]="membershipExtension"></app-account-pass-photo-uploader>
    </div>
  </div>
  <!-- spacer -->
  <div class="row">
    <div class="col-md-12">&nbsp;</div>
  </div>

  <div class="alert alert-light">
    <div class="row">
      <div class="col-md-12">
        <div class="float-start">
        </div>
        <div class="float-end">
          <!-- cancelling enrollment-->
          <app-membership-cancel-modal class="me-2"></app-membership-cancel-modal>
          <button class="btn btn-primary" (click)="next()" [disabled]="disableSendButton">{{'Next' |
            transloco}}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
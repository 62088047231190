import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { BaseComponent } from '@premotec/ngx-essentials';
import { timer as TimerObservable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { InformationScreen } from '../../models/information-screen.model';
import { InformationScreenService } from '../../services/information-screen.service';
import { EventAndLessonList } from '../../models/event-and-lesson-list.model';
import { FacilityClosingList } from '../../models/facility-closing-list.model';
import { FacilityAnnouncementList } from '../../models/facility-announcement-list.model';
import { SpecialAssignments } from '../../models/special-assignments.model';
import { FreeRoom } from '../../models/free-room.model';
import { CheckAppVersionChangeService } from 'src/app/config/check-app-version-change.service';

@Component({
  templateUrl: './screen.component.html',
  styleUrls: ['./screen.component.scss']
})
export class ScreenComponent extends BaseComponent implements OnInit {

  informationScreen: InformationScreen = new InformationScreen();

  hideAnnotation: boolean = false;
  openingHoursLoaded: boolean = false;
  annotationTop?: number;

  constructor(
    private informationScreenService: InformationScreenService,
    private activatedRoute: ActivatedRoute,
    private checkAppVersionChangeService: CheckAppVersionChangeService) {
    super();
    this.activatedRoute.params.subscribe(param => {
      this.informationScreen.facilityId = param['id'];
    })
    this.informationScreen.updatedTime = moment().lang("de").format('dd, DD.MM.YYYY');
    this.informationScreen.displayDatetime = moment().lang("de").format('dd, DD.MM.YYYY - HH:mm');
  }

  ngOnInit() {
    this.informationScreenService.getFacilityById(this.informationScreen.facilityId).subscribe(facility => {
      this.informationScreen.facility = facility;

      // OpeningHours
      this.informationScreen.openingHours.openingHoursCounter = facility.openingHoursCounter;
      this.informationScreen.openingHours.openingHoursSports = facility.openingHoursSports;

      this.startClock();
      this.startDailyReloadTimer();
      this.startUpdateTimer();
      this.startUpdateDisplayTimer();
    });
  }

  checkClosingAndAnnotationOverlapping() {

    if (this.informationScreen.announcements == null) return;
    if (this.informationScreen.closings == null) return;
    if (this.informationScreen.openingHours == null) return;

    let closingElement = document.getElementById("closing-screen-component");
    const closingRect = closingElement!.getBoundingClientRect();
    var closingBottom = closingRect.bottom;

    if (this.informationScreen.announcements.length) {
      let announcmentsElement = document.getElementById("announcements-screen-component");
      if (announcmentsElement == null) return;

      const announcmentsRect = announcmentsElement!.getBoundingClientRect();
      var announcmentsTop = announcmentsRect.top;

      if (announcmentsTop < (closingBottom + 50)) {
        this.informationScreen.closings.splice(-1);
      }
    } else {
      let annotationElement = document.getElementById("annotation-screen-component");
      if (annotationElement == null && this.annotationTop == null) return;

      if (annotationElement != null) {
        const annotationRect = annotationElement!.getBoundingClientRect();
        this.annotationTop = annotationRect.top;
      }

      this.hideAnnotation = (this.annotationTop! < (closingBottom + 50));
    }

  }

  getClosings() {
    this.informationScreenService.getFacilityClosings(this.informationScreen.facilityId, 6).subscribe(closings => {
      this.informationScreen.closings = closings.sort(this.sortByClosingDateASC).slice(0, 8);
    },
      (error) => {
        this.handlePageError();
      });
  }

  getAnnouncements() {
    this.informationScreenService.getFacilityAnnouncements(this.informationScreen.facilityId).subscribe(announcements => {
      this.informationScreen.announcements = announcements.sort(this.sortByAnnouncementDateASC);
    },
      (error) => {
        this.handlePageError();
      });
  }

  getSpecialAssignments() {
    this.informationScreenService.getFacilitySpecialAssignments(this.informationScreen.facilityId, 17).subscribe(specialAssignmen => {
      this.informationScreen.specialAssignments = specialAssignmen.sort(this.sortBySpecialAssignmentsDateASC);
    },
      (error) => {
        this.handlePageError();
      });
  }

  getFreeRooms() {
    this.informationScreenService.getFacilityFreeRooms(this.informationScreen.facilityId, 17).subscribe(freeRooms => {
      this.informationScreen.freeRooms = freeRooms.sort(this.sortByFreeRooms);
    },
      (error) => {
        this.handlePageError();
      });
  }

  startUpdateTimer() {
    // 300000ms = 5min
    this.whileImAlive(TimerObservable(0, 300000)).subscribe(() => {
      this.checkForUpdatedAssembly();
      this.getEventsAndLessons();
      // Closings
      this.getClosings();
      this.getAnnouncements();
      this.getSpecialAssignments();
      this.getFreeRooms();
    });
  }

  startUpdateDisplayTimer() {
    // 500ms = 0.1sec
    this.whileImAlive(TimerObservable(0, 500)).subscribe(() => {
      this.checkClosingAndAnnotationOverlapping();
    });
  }

  checkForUpdatedAssembly() {
    if (this.checkAppVersionChangeService.checkAgain) {
      this.checkAppVersionChangeService.getAssemblyVersion().subscribe((assemblyVersion: any) => {
        // Handle update when new release is available
        if (this.checkAppVersionChangeService.hasNewRelease) {
          window.location.reload();
        }
      });
    }
  }


  startClock() {
    this.whileImAlive(TimerObservable(1000, 1000)).subscribe(() => {
      this.informationScreen.displayDatetime = moment().lang("de").format('dd, DD.MM.YYYY - HH:mm');
    });
  }

  startErrorPageReloadTimer() {
    // NOTE: Timer started once an error occurs and will reload the page
    // 15'000ms = 15 seconds
    this.whileImAlive(TimerObservable(15000, 15000)).subscribe(t => {
      if (this.informationScreen.pageInErrorState) {
        this.getEventsAndLessons();
        // window.location.reload();
      }
    });
  }

  startDailyReloadTimer() {
    // 1'800'000ms = 30min
    this.whileImAlive(TimerObservable(1800000, 1800000)).subscribe(t => {
      if (moment().format('HH').includes('00')) {
        window.location.reload();
      }
    });
  }

  getEventsAndLessons() {
    const today = moment().subtract(5, 'minutes').format('YYYY-MM-DDTHH:mm');
    const tomorrow = moment().add(1, 'd').format('YYYY-MM-DD');

    this.whileImAlive(this.informationScreenService.getEventsAndLessonsByFacilityId(this.informationScreen.facilityId, 12)).subscribe(
      eventsAndLessons => {
        this.informationScreen.pageInErrorState = false;
        this.informationScreen.oldDate = moment().toDate();
        this.informationScreen.sportsSchedules.eventsAndLessons = Object.assign([], eventsAndLessons);

        this.informationScreen.sportsSchedules.eventsAndLessons.sort(this.sortByLessonTimeASC);

        this.informationScreen.sportsSchedules.eventsAndLessons.forEach(eventAndLesson => {
          eventAndLesson.room = eventAndLesson.room.replace(this.informationScreen.facility.name, '');
        });

        this.informationScreen.updatedTime = moment().format('dd, DD.MM.YYYY');

      },
      (error) => {
        this.handlePageError();
      }
    );
  }

  getTime(dateTime: Date): string {
    return moment(dateTime).format('HH:mm');
  }

  isNewDate(dateTime: Date) {
    if (moment(dateTime).isAfter(this.informationScreen.oldDate, 'day')) {
      this.informationScreen.oldDate = dateTime;
      return true;
    } else {
      return false;
    }
  }

  sortByLessonTimeASC(a: EventAndLessonList, b: EventAndLessonList) {
    const aFrom = a.fromDateTime;
    const aTo = a.toDateTime;
    const bFrom = b.fromDateTime;
    const bTo = b.toDateTime;

    if (aFrom === bFrom) {
      return aTo > bTo ? 1 : aTo < bTo ? -1 : 0;
    } else {
      return aFrom > bFrom ? 1 : aFrom < bFrom ? -1 : 0;
    }
  }

  sortByClosingDateASC(a: FacilityClosingList, b: FacilityClosingList) {
    return a.from > b.from ? 1 : a.from < b.from ? -1 : 0;
  }

  sortByAnnouncementDateASC(a: FacilityAnnouncementList, b: FacilityAnnouncementList) {
    return a.fromDateTime > b.fromDateTime ? 1 : a.fromDateTime < b.fromDateTime ? -1 : 0;
  }

  sortBySpecialAssignmentsDateASC(a: SpecialAssignments, b: SpecialAssignments) {
    return a.from > b.from ? 1 : a.from < b.from ? -1 : 0;
  }

  sortByFreeRooms(a: FreeRoom, b: FreeRoom) {
    let aFrom = String(a.from);
    let bFrom = String(b.from);
    let aTo = String(a.to);
    let bTo = String(b.to);

    let aCompareValue = aFrom + aTo + a.room;
    let bCompareValue = bFrom + bTo + b.room;

    return aCompareValue > bCompareValue ? 1 : aCompareValue < bCompareValue ? -1 : 0;
  }

  getClosedTypeResourceKey(value: any) {
    if (value === undefined || value === null || value === '' || value === 'null') {
      return 'NotRelevant';
    } else if (value === 'true' || value === true) {
      return 'Closed';
    }

    return 'Opened';
  };

  handlePageError(): void {
    this.informationScreen.pageInErrorState = true;
    this.startErrorPageReloadTimer();
  }

}

import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { OnlineMembershipUpdate } from '../models/online-membership-update.model';
import { OnlineMembership } from '../models/online-membership.model';
import { environment } from 'src/environments/environment';
import { ApplicantUpdate } from '../models/applicant-update.model';
import { Editor } from '../models/editor.model';

@Injectable()
export class MembershipApplicationAdminService {

  private _editors?: Editor[];

  constructor(private httpClient: HttpClient) { }

  updateApplicant(membershipApplicationId: string, applicantUpdate: ApplicantUpdate) {
    return this.httpClient.put(`${environment.api}/MembershipApplications/Admin/${membershipApplicationId}/UpdateApplicant`, applicantUpdate);
  }

  updateOnlineMembership(membershipApplicationId: string, onlineMembershipUpdate: OnlineMembershipUpdate) {
    return this.httpClient.put(`${environment.api}/MembershipApplications/Admin/${membershipApplicationId}/UpdateOnlineMembership`, onlineMembershipUpdate);
  }

  getOnlineMemberships(): Observable<OnlineMembership[]> {
    return this.httpClient.get(`${environment.api}/MembershipApplications/Admin/OnlineMemberships`).pipe(
      map((res: any) => res)
    );
  }

  verifyEmail(membershipApplicationId: string): Observable<null> {
    const url = `${environment.api}/MembershipApplications/Admin/${membershipApplicationId}/VerifyEmail`;
    return this.httpClient.put<null>(url, {});
  }

  updateEditor(membershipApplicationId: string, editorAsvzId: number): Observable<void> {
    return this.httpClient.put<void>(`${environment.api}/MembershipApplications/Admin/${membershipApplicationId}/editor`, {editorAsvzId});
  }

  getEditors(): Observable<Editor[]> {
    if (this._editors) {
      return of(this._editors);
    }
    return this.httpClient.get<Editor[]>(`${environment.api}/MembershipApplications/admin/editors`)
    .pipe(
      tap(editors => this._editors = editors)
    );
  }

  getEditorModelByAsvzId(editors: Editor[], editorAsvzId: number): Editor | undefined {
    if (!editorAsvzId) {
      return undefined;
    }
    return editors.find(e => e.asvzId === editorAsvzId);
  }
}

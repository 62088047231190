<div class="col-12 col-lg-6">
    <label for="lastSchoolId">{{'LastSchool' | transloco}} *</label>
    <app-spinner *ngIf="loading"></app-spinner>
    <div *ngIf="!loading">
        <ng-select id="lastSchoolId" [items]="selectItems" [formControl]="formControl" bindValue="schoolId"
            bindLabel="name" [ngClass]="{'is-invalid': formControl.invalid && submitted }"></ng-select>
        <div class="invalid-feedback text-danger">
            {{ 'ValidationText.Required' | transloco }}
        </div>
    </div>
</div>
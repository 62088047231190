<ng-container *ngIf='occupancyPlanViews'>
  <div>
    <div class="sidebar"  pmtAutoWindowHeight [marginTop]="65">
      <app-list [occupancyPlanViews]="occupancyPlanViews"></app-list>
    </div><!-- /sidebar -->

    <div class="sidebar-content" pmtAutoWindowHeight [marginTop]="65" style="padding: 1rem;">
      <router-outlet></router-outlet>
    </div><!-- /content -->
  </div>
</ng-container>
<ng-container *ngIf='!occupancyPlanViews'>
  <app-spinner></app-spinner>
</ng-container>
<app-spinner *ngIf="!dataLoaded" margin="100px auto"></app-spinner>

<div *ngIf="dataLoaded">
  <div class="container" style="min-height: 500px;">
    <div class="page-header">
      <h1 *ngIf="registration.status !== registrationStates.Expired" class="mb-5">{{'Summary' | transloco}}</h1>
      <h1 *ngIf="registration.status === registrationStates.Expired" class="mb-5">{{'Expired' | transloco}}</h1>
    </div>


    <div class="alert" [ngClass]="{'alert-primary': !isErrorStatus(), 'alert-danger': isErrorStatus()}">
      <strong>{{'StateMessages.State_' + registration.status + '.Name' | transloco}}</strong>
      <br>{{'StateMessages.State_' + registration.status + '.Msg' | transloco}}
    </div>

    <div class="alert alert-danger" *ngIf="showValidationError">{{validationError}}</div>

    <div *ngIf="canDeregister" style="margin-bottom:3em;">
      <button class="btn btn-default" (click)="showModal()" id="btnRegister">
        <i class="fa fa-user-times" aria-hidden="true"></i> {{'EventActions.Deregister' | transloco}}
      </button>
      <span style="margin-left:0.5em;">{{'EventMessages.DeregisterMsg' | transloco:translateParam }}</span>
    </div>

    <div *ngIf="canDeregisterPaid && !showValidationError" style="margin-bottom:3em;">
      <button class="btn btn-default" (click)="checkDeRegister()" id="btnRegister"  [disabled]="cancelPaidLoading">
        <i class="fa fa-user-times" aria-hidden="true"></i> {{'EventActions.Deregister' | transloco}}
      </button>
      <span style="margin-left:0.5em;" [innerHTML]="'EventMessages.DeregisterPaidMsg' | transloco"></span>
    </div>

    <div style="margin-bottom: 20px;" *ngIf="registration.status === registrationStates.Expired">
      <div class="alert alert-primary" *ngIf="!hasSeats">
        {{'EventMessages.EventFullyBookedMsg' | transloco}}
      </div>

      <button *ngIf="canRegister" class="btn btn-default" (click)="continueEnrollment()">
        <span class="fa fa-book"></span> {{'EventActions.ContinueRegistration' | transloco}}
      </button>

      <button *ngIf="registrationPeriodIsActive && !hasSeats" class="btn btn-default" id="btnRegisterWaitList"
        (click)="registerForWaitlist(event.id)" [disabled]="submitted">
        {{'EventMessages.PutOnWaitlist' | transloco }}
      </button>
    </div>

    <ul class="nav nav-tabs my-3">
      <li style="cursor: pointer;" (click)="activeTab = 'offer'" class="nav-item nav-pills-asvz" role="presentation"><a
          [ngClass]="{'active': activeTab == 'offer'}" class="nav-link">{{'Sportoffer' | transloco }}</a></li>
      <li style="cursor: pointer;" (click)="activeTab = 'registration'" class="nav-item nav-pills-asvz"
        role="presentation"><a [ngClass]="{'active': activeTab == 'registration'}" class="nav-link">{{'RegistrationInfo'
          | transloco }}</a></li>
    </ul>

    <ng-container *ngIf=" activeTab == 'offer'">
      <div class="tab-pane active" id="eventDetails">
        <div class="row">
          <div class="col-sm-4">
            <div class="panel-group" id="accordDescr_details">
              <div class="card">
                <div class="card-header">{{'Sportoffer' | transloco}}</div>
                <div class="card-body">
                  <app-event-properties-display *ngIf="event" [event]="event"></app-event-properties-display>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-8">
            <div class="panel-group mb-3" id="prerequisites" *ngIf='event.requiredSkills?.length'>
              <div class="card">
                <div class="card-header">{{'Prerequisites' | transloco}}</div>
                <div class="card-body">
                  <app-prerequisites-view [requiredSkills]='missingSkills' [eventSport]="event.sportName"
                    [eventTitle]="event.title" [eventStartDate]="event.starts" [eventEndDate]="event.ends"
                    [registrationStartDate]="event.registrationFrom">
                  </app-prerequisites-view>
                </div>
              </div>
            </div>

            <div class="panel-group">
              <div class="card">
                <div class="card-header">{{'Details' | transloco}}</div>
                <div class="card-body">
                  <app-details-view [details]="event.details"></app-details-view>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf=" activeTab == 'registration'">
      <div class=" pt-3">
        <app-registration-details-view [registrationDetails]="registrationDetails"></app-registration-details-view>
        <div style="padding:1rem 0;">
          <strong>{{'RequiredDetailsMsg' | transloco }}</strong>
        </div>
      </div>
    </ng-container>
  </div>
</div>
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { mergeMap } from 'rxjs/operators';
import * as moment from 'moment';
import { RegistrationService } from '../registration.service';
import { RegistrationSummary } from '../registration-summary.model';
import { EventsService } from '../../events.service';
import { RegistrationStates } from '../registration-states.enum';
import { Event } from '../../event.model';
import { EventRegistrationDetail } from '../../event-registration-detail.model';
import { RequiredSkill } from '../../required-skill.model';
import { AccountService } from '../../../account/account.service';
import { Account } from '../../../account/account.model';
import { PrerequisitesService } from '../../../shared/prerequisites-view/prerequisites.service';
import { DisplayPriceType } from '../../display-price-type.enum';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { TranslocoService } from '@ngneat/transloco';
import { ConfirmationModalComponent } from 'src/app/shared/confirmation-modal/confirmation-modal.component';
import { RegistrationDeregisterPaidModalComponent } from '../registration-deregister-paid-modal/registration-deregister-paid-modal.component';
import { HttpErrorResponse } from '@angular/common/http';
import { RegistrationCancelPaidInformationModel } from '../models/registration-cancel-paid-information.model';
import { DateHelperService } from 'src/app/shared/services/date-helper.service';

@Component({
  selector: 'app-registration-summary',
  templateUrl: './registration-summary.component.html',
  styleUrls: ['./registration-summary.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RegistrationSummaryComponent implements OnInit {
  eventId?: number;
  registrationId!: number;
  registration!: RegistrationSummary;
  event!: Event;
  registrationDetails!: EventRegistrationDetail[];
  missingSkills: RequiredSkill[] = [];
  translateParam: any;
  dataLoaded!: boolean;
  hasSeats!: boolean;

  cancelPaidLoading: boolean = false;
  cancelPaidInformation!: RegistrationCancelPaidInformationModel;

  displayPriceType = DisplayPriceType;
  canDeregister!: boolean;
  canDeregisterPaid!: boolean;
  canRegister!: boolean;
  registrationPeriodIsActive!: boolean;

  activeTab = "offer";

  submitted!: boolean;
  registrationStates = RegistrationStates;

  showValidationError = false;
  validationError!: string;

  constructor(
    private registrationService: RegistrationService,
    private activatedRoute: ActivatedRoute,
    private eventsService: EventsService,
    private accountService: AccountService,
    private prerequisitesService: PrerequisitesService,
    private router: Router,
    private modalService: BsModalService,
    private translocoService: TranslocoService,
    private dateHelperService: DateHelperService) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.registrationId = + params['registrationId'];
      if (this.registrationId) {
        this.loadDetails();
      }
    })
  }

  private loadDetails() {
    this.registrationService.getById(this.registrationId).pipe(mergeMap((registrationSummary: RegistrationSummary) => {
      this.eventId = registrationSummary.eventId;
      this.registration = registrationSummary;
      return this.eventsService.getEventById(this.registration.eventId!);
    })).subscribe((event: Event) => {
      this.event = event;
      this.hasSeats = this.eventsService.hasFreeSeats(this.event);
      this.registrationDetails = this.registrationService.getRegistrationDetails(event, this.registration);
      this.checkCanDeregister();
      this.checkCanDeregisterPaid();
      this.checkMissingSkills();
      this.checkCanRegister();
      this.dataLoaded = true;

    });
  }

  showModal() {
    const initialstate: ModalOptions = {
      initialState: {
        body: this.translocoService.translate('EventActions.ConfirmDeregistration'),
        icon: 'fa-solid fa-circle-info'
      }
    }

    const bsModalRef = this.modalService.show(ConfirmationModalComponent, initialstate);

    bsModalRef.content!.confirmed.subscribe(() => {
      this.deregister();
    })
  }


  showDeregisterPaidModal() {
    const initialstate: ModalOptions = {
      initialState: {
        registrationId: this.registrationId,
        event: this.event,
        information: this.cancelPaidInformation,
      }
    }

    const bsModalRef = this.modalService.show(RegistrationDeregisterPaidModalComponent, initialstate);

    bsModalRef.content!.confirmed.subscribe(() => {
      this.deregisterPaid();
    })
  }

  checkCanDeregister() {
    if (
      this.registration != null &&
      this.registration.status === RegistrationStates.Completed &&
      !this.registrationService.registrationHasPayment(this.registration) &&
      this.event.cancelationUntil != null &&
      !this.eventsService.cancelationDateIsExpired(this.event.cancelationUntil) &&
      !this.eventsService.startDateIsInThePast(this.event.starts)
    ) {
      this.canDeregister = true;
      this.translateParam = {
        'cancelationUntil': moment(this.event.cancelationUntil).format('DD.MM.YYYY HH:mm'),
      };
    } else {
      this.canDeregister = false;
    }
  }

  checkCanDeregisterPaid(): void {
    if (
      this.registration != null &&
      this.registration.status === RegistrationStates.Completed &&
      this.cancelationUntilDateIsEmptyOrAfterCurrentDate(this.event.cancelationUntil) &&
      !this.eventsService.startDateIsInThePast(this.event.starts)
    ) {
      this.canDeregisterPaid = true;
    } else {
      this.canDeregisterPaid = false;
    }
  }

  private cancelationUntilDateIsEmptyOrAfterCurrentDate(cancelationUntil: Date): boolean {
    if (cancelationUntil == null) return true;
    const parsedCancelationUntilDate = new Date(cancelationUntil);
    if (this.dateHelperService.isAfterCurrentDate(parsedCancelationUntilDate)) return true;
    return false;
  }


  checkMissingSkills() {
    this.accountService.getAccountDetails().subscribe((account: Account) => {
      this.missingSkills = this.prerequisitesService.getMissingSkills(
        this.event.requiredSkills,
        account.skills!,
        this.event.registrationFrom,
        this.event.starts,
        this.event.ends);
    });
  }

  checkCanRegister() {
    this.registrationPeriodIsActive = this.eventsService.registrationDurationIsOpen(this.event);
    this.canRegister = this.registrationPeriodIsActive
      && this.hasSeats
      && this.missingSkills.length === 0
      && !this.isErrorStatus();
  }

  isErrorStatus(): boolean {
    return (this.registration.statusName == "Error");
  }

  continueEnrollment() {
    this.submitted = true;
    this.registrationService.setStateInitial(this.registration.id!).subscribe(() => {
      this.router.navigate(([`/registration/${this.registration.id}/wizard`]));
      this.submitted = false;
    });
  }

  deregister() {
    this.dataLoaded = false;
    this.registrationService.setStateCanceled(this.registration.id!).subscribe(() => {
      this.loadDetails();
    });
  }

  deregisterPaid() {
    this.dataLoaded = false;
    this.registrationService.cancelPaidRegistration(this.registration.id!).subscribe(
      (registration) => {
        this.loadDetails();
      },
      (error: HttpErrorResponse) => {
        this.dataLoaded = true;
        // get the error from the response
        switch (error.error.errorStatus) {
          case 'ValidationFailure':
          case 'NotUpdateable':
            if (error.error && error.error.errors.length > 0) {
              this.showValidationError = true;
              this.validationError = error.error.errors[0].message;
            }
            break;
          default:
        }
      }
    );
  }

  checkDeRegister(): void {
    this.cancelPaidLoading = true;

    this.registrationService.getCancelPaidRegistrationInformation(this.registrationId).subscribe(
      (information) => {
        this.cancelPaidInformation = information.data;
        this.showDeregisterPaidModal();
        this.cancelPaidLoading = false;
      },
      (error: HttpErrorResponse) => {
        // get the error from the response
        switch (error.error.errorStatus) {
          case 'ValidationFailure':
          case 'NotUpdateable':
            this.cancelPaidLoading = false;
            if (error.error && error.error.errors.length > 0) {
              this.showValidationError = true;
              this.validationError = error.error.errors[0].message;
            }
            break;
          default:
        }
      }
    );
  }

  registerForWaitlist(eventId: number): void {
    this.submitted = true;
    this.registrationService.registerForWaitlist(eventId).subscribe(
      () => {
        this.router.navigate(([`/waiting-list/overview`]));
        this.submitted = false;
      },
      (error) => {
        this.submitted = false;
      }
    );
  }
}

<ng-template #template>
    <div class="modal-header">
      <h4 class="modal-title float-start">{{'ChangePassword' | transloco}}</h4>
      <button type="button" class="close float-end btn" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <app-user-set-password [passwordFormGroup]="passwordFormGroup" [submitted]="submitted"></app-user-set-password>
    </div>
    <div class="modal-footer">
        <button class="btn btn-default" (click)="savePassword()">{{'SaveChanges' | transloco}}</button>
    </div>
</ng-template>
<button class="btn btn-default" (click)="openModal(template)"><i class="fa fa-key"></i> {{'ChangePassword' | transloco}}</button>

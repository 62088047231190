<div class="container" style="min-height: 500px;">
  <div style="margin-top: 34px;"></div>

  <div class="row">
    <div *ngIf="!isPaymentDenied" class="col-sm-12">
      <h3>{{'PaymentProcessing' | transloco}}</h3>
      {{'YourPaymentIsProcessing' | transloco}}
    </div>
    <div *ngIf="isPaymentDenied">
      <div *ngIf="this.postFinanceCheckoutTransaction.status === postFinanceCheckoutTransactionStatus.FAILED && this.postFinanceCheckoutTransaction.failureCategory !== this.postFinanceCheckoutTransactionFailureCategory.END_USER" class="col-sm-12">
        <h3 class="text-danger">{{'PaymentDenied_0' | transloco}}</h3>
        {{'YourPaymentIsDeniedMsg_0' | transloco}}
      </div>
      <div *ngIf="this.postFinanceCheckoutTransaction.status === postFinanceCheckoutTransactionStatus.FAILED && this.postFinanceCheckoutTransaction.failureCategory === this.postFinanceCheckoutTransactionFailureCategory.END_USER" class="col-sm-12">
        <h3 class="text-danger">{{'PaymentDenied_1' | transloco}}</h3>
        {{'YourPaymentIsDeniedMsg_1' | transloco}}
      </div>
      <div *ngIf="this.postFinanceCheckoutTransaction.status === postFinanceCheckoutTransactionStatus.DECLINE" class="col-sm-12">
        <h3 class="text-danger">{{'PaymentDenied' | transloco}}</h3>
        {{'YourPaymentIsDeniedMsg' | transloco}}
      </div>
    </div>
  </div>
  <div class="row" *ngIf="invoiceType">
    <div class="col-sm-12">
      <br />
      <ng-container [ngSwitch]="invoiceType.typeId">
        <ng-template [ngSwitchCase]="invoiceRegistrationType">
          <a class="btn btn-default" [routerLink]="'/registration/'+invoiceType.recordId+'/details'" >{{'GotoRegistration' | transloco}}</a>
        </ng-template>
        <ng-template [ngSwitchCase]="invoiceTypes.Membership">
          <a class="btn btn-default" [routerLink]="'/memberships'" >{{'GoToMemberships' | transloco}}</a>
        </ng-template>
      </ng-container>
    </div>
  </div>
</div>

import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { TournamentEventGroupSettingsUpdateModel } from '../models/tournament-event-group-settings-update.model';
import { TournamentEventModel } from '../models/tournament-event.model';
import { TournamentService } from '../services/tournament.service';

@Component({
    templateUrl: './tournament-event-group-configuration-modal.component.html',
    styleUrls: ['./tournament-event-group-configuration-modal.component.scss'],
})
export class TournamentEventGroupConfigruationModalComponent implements OnInit {

    @Output() confirmed = new EventEmitter<boolean>();

    tournamentPlanId!: string;
    eventId!: string;

    tournamentGroupSettingsForm!: UntypedFormGroup;

    formSubmitted = false;

    constructor(
        private fb: FormBuilder,
        private bsModalRef: BsModalRef,
        private tournamentService: TournamentService,
    ) {
    }

    ngOnInit() {
        if (this.tournamentPlanId && this.eventId) {
            this.loadTournamentEvent(this.tournamentPlanId, this.eventId);
        }
    }

    initGroupSettingsForm(tournamentEvent: TournamentEventModel | null) {
        this.tournamentGroupSettingsForm = this.fb.group({
            groupCount: [tournamentEvent?.groupCount, Validators.required],
            qualifyingTeamCount: [tournamentEvent?.qualifyingTeamCount, Validators.required],
            prefix: [tournamentEvent?.prefix ?? ""],
        });
    }

    loadTournamentEvent(tournamentPlanId: string, eventId: string) {
        this.tournamentService.getTournamentEventById(tournamentPlanId, eventId).subscribe(x => {
            this.initGroupSettingsForm(x);
        });
    }

    update() {
        this.tournamentService.updateTournamentGroupSettings(this.tournamentPlanId, this.eventId, this.getTournamentGroupSettingsUpdateModel()).subscribe(x => {
            this.confirmed.emit(true);
            this.bsModalRef.hide();
        });
    }

    getTournamentGroupSettingsUpdateModel(): TournamentEventGroupSettingsUpdateModel {
        var updateModel = new TournamentEventGroupSettingsUpdateModel();

        updateModel.groupCount = this.tournamentGroupSettingsForm.controls['groupCount'].value;
        updateModel.qualifyingTeamCount = this.tournamentGroupSettingsForm.controls['qualifyingTeamCount'].value;
        updateModel.prefix = this.tournamentGroupSettingsForm.controls['prefix'].value;

        return updateModel;
    }

    confirm() {
        this.formSubmitted = true;
        if (!this.tournamentGroupSettingsForm.valid) return;
        this.update();
    }

    decline() {
        this.bsModalRef.hide();
    }
}

import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { TournamentService } from '../services/tournament.service';
import { TournamentModel } from '../models/tournament.model';
import { TournamentUpdateModel } from '../models/tournament-update.model';
import { TournamentTypeEnum } from '../enums/tournament-type.enum';
import { TournamentAdministratorModel } from '../models/tournament-administrator.model';
import { timer } from 'rxjs';

@Component({
    templateUrl: './tournament-administrators-modal.component.html',
    styleUrls: ['./tournament-administrators-modal.component.scss'],
})
export class TournamentAdministratorsModalComponent implements OnInit {

    @Output() confirmed = new EventEmitter<boolean>();

    tournamentPlanId!: string;

    tournamentForm!: UntypedFormGroup;

    formSubmitted = false;
    TournamentTypeEnum = TournamentTypeEnum;

    possibleAdministrators?: TournamentAdministratorModel[] = [];

    personFilter?: string;

    timeSubscriber: any;

    anyAdded!: boolean;
    isAdding!: boolean;

    constructor(
        private translocoService: TranslocoService,
        private activatedRoute: ActivatedRoute,
        private fb: FormBuilder,
        private datepipe: DatePipe,
        private modalService: BsModalService,
        private bsModalRef: BsModalRef,
        private tournamentService: TournamentService,
    ) {
    }

    ngOnInit() {
    }

    loadData() {
        if (this.personFilter == undefined || this.personFilter == "") return;
        this.possibleAdministrators = undefined;
        this.tournamentService.getPossibleAdministrators(this.tournamentPlanId!, this.personFilter!).subscribe(x => {
            x = x.sort((a, b) => (a.firstName + a.lastName + a.asvzId).localeCompare(b.firstName + b.lastName + b.asvzId));
            this.possibleAdministrators = x;
        })
    }

    filterPersons() {
        this.possibleAdministrators = undefined;

        if (this.personFilter == undefined || this.personFilter == "")  this.possibleAdministrators = [];

        if (this.timeSubscriber) this.timeSubscriber.unsubscribe();

        let source = timer(1000);
        this.timeSubscriber = source.subscribe(val => {
            this.loadData();
            this.timeSubscriber.unsubscribe();
        });
    }

    clearFilter() {
        if (this.personFilter == undefined) return;
        this.personFilter = undefined;
        this.filterPersons();
    }

    addPersonAsAdministrator(personId: number) {
        this.anyAdded = true;
        this.isAdding = true;
        this.tournamentService.addAdministrators(this.tournamentPlanId, personId).subscribe(x => {
            this.loadData();
            this.isAdding = false;
        });
    }

    removePersonAsAdministrator(personId: number) {
        this.tournamentService.addAdministrators(this.tournamentPlanId, personId).subscribe(x => {
            this.loadData();
        });
    }

    close() {
        this.confirmed.emit(this.anyAdded);
        this.bsModalRef.hide();
    }
}

import { Component, OnInit, Input } from '@angular/core';
import { base64ToFile, ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { MembershipApplicationAdminService } from '../services/membership-application-admin.service';
import { BaseComponent } from '@premotec/ngx-essentials';
import { MembershipApplicationsService } from 'src/app/membership-application/membership-applications.service';
import { ToastrService } from 'ngx-toastr';
import { TranslocoService } from '@ngneat/transloco';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ConfirmationModalComponent } from 'src/app/shared/confirmation-modal/confirmation-modal.component';
import { MembershipApplicationProcessService } from '../services/membership-application-process.service';

@Component({
  selector: 'app-ma-image-cropper',
  templateUrl: './ma-image-cropper.component.html',
  styleUrls: ['./ma-image-cropper.component.scss']
})
export class MaImageCropperComponent extends BaseComponent implements OnInit {

  @Input() applicationId!: string;
  @Input() readonly!: boolean;

  originalImage: any;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  base64Data = null;
  formattedImage: any;
  updatedImage: any;

  originalFileName!: string;
  originalFileFormat!: string;
  originalFileType!: string;

  canvasRotation = 0;

  editMode = false;
  isLoading = true;

  constructor(
    private applicationService: MembershipApplicationsService,
    private membershipApplicationProcessService: MembershipApplicationProcessService,
    private maService: MembershipApplicationAdminService,
    private translocoService: TranslocoService,
    private toastrService: ToastrService,
    private modalService: BsModalService,
  ) {
    super();
  }

  ngOnInit() {
    this.getPassPhoto();
    this.getFormattedPassPhoto();
  }

  editImage(type: any) {
    if (type === 1) {
      this.base64Data = this.originalImage;
    } else {
      this.base64Data = this.formattedImage;
    }
    this.editMode = true;
  }


  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.updatedImage = base64ToFile(this.croppedImage);
  }

  rotateLeft() {
    this.canvasRotation--;
  }

  rotateRight() {
    this.canvasRotation++;
  }

  downloadFile() {
    const blob = this.updatedImage;
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }

  getPassPhoto() {
    this.originalImage = null;
    this.isLoading = true;
    this.whileImAlive(this.applicationService.getPassPhotoDownloadId(this.applicationId)).subscribe(downloadRequest => {

      this.originalFileName = downloadRequest.fileName;
      if (downloadRequest.fileExtension) {
        this.originalFileFormat = downloadRequest.fileExtension.substring(1);
      }
      this.originalFileType = downloadRequest.contentType;

      this.applicationService.downloadFileAsBlob(downloadRequest.downloadId).subscribe(
        (blob: any) => {
          const reader = new FileReader();

          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            const base64Data = reader.result;
            this.originalImage = base64Data;
            this.isLoading = false;
          }
        }
      );
    },
      () => this.isLoading = false
    );
  }

  getFormattedPassPhoto() {
    this.whileImAlive(this.membershipApplicationProcessService.getFormattedPassPhotoDownloadId(this.applicationId)).subscribe(downloadRequest => {
      if (downloadRequest == null) return;
      this.applicationService.downloadFileAsBlob(downloadRequest.downloadId).subscribe(
        (blob: any) => {
          const reader = new FileReader();

          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            const base64Data = reader.result;
            this.formattedImage = base64Data;
          }
        }
      );
    });
  }


  saveChanges() {
    let blob = this.updatedImage;
    blob = new Blob([blob], { type: this.originalFileType }); // pass the mime type from the original
    blob['lastModified'] = Date.now();
    blob['name'] = this.originalFileName; // use the original file name
    const formData = new FormData();
    formData.append('formFile', blob, this.originalFileName);

    this.membershipApplicationProcessService.uploadFormattedPassPhoto(this.applicationId, formData).subscribe(res => {
      this.getFormattedPassPhoto();
      this.editMode = false;
    },
      (error) => {
        this.toastrService.error(this.translocoService.translate('Msg.SaveError'));
      })
  }

  deleteFormattedPassPhoto() {
    this.membershipApplicationProcessService.deleteFormattedPassPhoto(this.applicationId).subscribe(res => {
      this.formattedImage = null;
    })
  }

  deleteFilShowModal() {
    const initialstate: ModalOptions = {
      initialState: {
        body: this.translocoService.translate('ConfirmDeleteAction'),
        icon: 'fa-solid fa-circle-info',
      }
    }

    const bsModalRef = this.modalService.show(ConfirmationModalComponent, initialstate);

    bsModalRef.content!.confirmed.subscribe(() => {
      this.deleteFormattedPassPhoto();
    })
  }

}

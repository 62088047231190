import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OccupancyPlanViewsOverviewComponent } from './occupancy-plan-views-overview/occupancy-plan-views-overview.component';
import { OccupancyPlanViewDetailComponent } from './occupancy-plan-views-overview/occupancy-plan-view-detail/occupancy-plan-view-detail.component';
import { ListComponent } from './occupancy-plan-views-overview/occupancy-plan-views-list/occupancy-plan-views-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { OccupancyPlanViewAdminService } from './occupancy-plan-view-admin.service';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgSelectModule,
    TabsModule.forRoot()
  ],
  declarations: [
    OccupancyPlanViewsOverviewComponent,
    OccupancyPlanViewDetailComponent,
    ListComponent],
  providers: [
    OccupancyPlanViewAdminService
  ],
  exports: [
    OccupancyPlanViewsOverviewComponent,
    RouterModule
  ]
})
export class OccupancyPlanViewsAdminModule { }

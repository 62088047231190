import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OccupancyPlanView } from './occupancy-plan-view.model';
import { catchError, map } from 'rxjs/operators';
import { FacilityRoom } from './facility-room.model';
import { OccupancyPlanViewRoom } from './occupancy-plan-view-room.model';
import { HttpResponseErrorHandlerService } from 'src/app/shared/services/http-response-error-handler.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class OccupancyPlanViewAdminService {

    public static occupancyPlanViewChangedEvent: EventEmitter<OccupancyPlanView> = new EventEmitter<OccupancyPlanView>();

    constructor(private httpService: HttpClient,
        private httpResponseErrorHandler: HttpResponseErrorHandlerService) {
    }

    getAll(): Observable<Array<OccupancyPlanView>> {
        return this.httpService.get(`${environment.api}/OccupancyPlanViewsAdmin`)
            .pipe(
                map((results: any): Array<OccupancyPlanView> => results),
            );
    }

    getById(occupancyPlanViewId: string): Observable<OccupancyPlanView> {
        return this.httpService.get(`${environment.api}/OccupancyPlanViewsAdmin/` + occupancyPlanViewId)
            .pipe(
                map((result: any): OccupancyPlanView => result),
            );
    }

    create(occupancyPlanView: OccupancyPlanView): Observable<OccupancyPlanView> {
        return this.httpService.post(`${environment.api}/OccupancyPlanViewsAdmin`, occupancyPlanView)
            .pipe(
                map((result: any): OccupancyPlanView => result),
            );
    }

    update(occupancyPlanView: OccupancyPlanView): Observable<OccupancyPlanView> {
        return this.httpService.put(`${environment.api}/OccupancyPlanViewsAdmin/` + occupancyPlanView.id, occupancyPlanView)
            .pipe(
                map((result: any): OccupancyPlanView => result),
            );
    }

    delete(occupancyPlanViewId: string): any {
        return this.httpService.delete(`${environment.api}/OccupancyPlanViewsAdmin/` + occupancyPlanViewId)
            .pipe(
                map((result: any): any => result)
            );
    }

    getAllFacilityRooms(): Observable<Array<FacilityRoom>> {
        return this.httpService.get(`${environment.api}/FacilityRooms`)
            .pipe(
                map((results: any): Array<FacilityRoom> => results)
            );
    }

    addRoom(occupancyPlanViewId: string, facilityRoomId: string): Observable<OccupancyPlanViewRoom> {
        return this.httpService.post(`${environment.api}/OccupancyPlanViewsAdmin/` + occupancyPlanViewId + '/Rooms', {'facilityRoomId': facilityRoomId})
            .pipe(
                map((result: any): OccupancyPlanViewRoom => result)
            );
    }

    updateRoom(occupancyPlanViewId: string, occupancyPlanViewRoomId: string, sort: number): Observable<OccupancyPlanViewRoom> {
        return this.httpService.put(`${environment.api}/OccupancyPlanViewsAdmin/` + occupancyPlanViewId + '/Rooms/' + occupancyPlanViewRoomId, {'sort': sort})
            .pipe(
                map((result: any): OccupancyPlanViewRoom => result)
            );
    }

    removeRoom(occupancyPlanViewId: string, occupancyPlanViewRoomId: string): Observable<OccupancyPlanViewRoom> {
        return this.httpService.delete(`${environment.api}/OccupancyPlanViewsAdmin/` + occupancyPlanViewId + '/Rooms/' + occupancyPlanViewRoomId)
            .pipe(
                map((result: any): any => result)
            );
    }

    getOccupancyPlanViewRooms(occupancyPlanViewId: string): Observable<Array<OccupancyPlanViewRoom>> {
        return this.httpService.get(`${environment.api}/OccupancyPlanViewsAdmin/` + occupancyPlanViewId + '/Rooms')
            .pipe(
                map((results: any): Array<OccupancyPlanViewRoom> => results)
            );
    }
}

<div class="modal-header text-light">
    <h3>{{'Tournaments.Events.Groups.Edit' | transloco}}</h3>
</div>

<div class="modal-body">

    <form *ngIf="tournamentGroupSettingsForm" [formGroup]="tournamentGroupSettingsForm">
        <div class="row">
            <div class="col-12 mb-3">
                <label for="groupCount" class="form-label">
                    {{'Tournaments.Events.Groups.GroupCount' | transloco}} *
                </label>
                <div>
                    <input type="number" class="form-control" min="1" id="groupCount" formControlName="groupCount">
                </div>
            </div>
            <div class="col-12 mb-3">
                <label for="qualifyingTeamCount" class="form-label">
                    {{'Tournaments.Events.Groups.QualifyingTeamCount' | transloco}} *
                </label>
                <div>
                    <input type="number" class="form-control" id="qualifyingTeamCount"
                        formControlName="qualifyingTeamCount">
                </div>
            </div>
            <div class="col-12 mb-3">
                <label for="prefix" class="form-label">{{'Tournaments.Events.Groups.Prefix' | transloco}}</label>
                <div>
                    <input type="text" class="form-control" id="prefix" formControlName="prefix">
                </div>
            </div>
        </div>
    </form>

</div>

<div class="modal-footer text-light">
    <button type="button" class="btn btn-default" (click)="decline()">{{'Cancel' | transloco}}</button>
    <button type="submit" class="btn btn-success" (click)="confirm()">{{'Save' | transloco}}</button>
</div>
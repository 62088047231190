import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, Subscription, timer } from 'rxjs';
import * as moment from 'moment';
import { LiveStreamStatus } from '../enum/live-stream-status.enum';
import { LiveStream } from '../models/live-stream.model';
import { Lesson } from 'src/app/lessons/models/lesson.model';
import { Event } from 'src/app/events/event.model';
import { Instructor } from 'src/app/events/instructor.model';
import { EventsService } from 'src/app/events/events.service';
import { LessonsService } from 'src/app/lessons/services/lessons.service';

@Component({
  selector: 'app-show-live-stream',
  templateUrl: './show-live-stream.component.html',
  styleUrls: ['./show-live-stream.component.scss']
})
export class ShowLiveStreamComponent implements OnInit {

  // intercept change to prevent reloading with ngOnInit
  @Input() set liveStream(value: LiveStream) {

    // set only if the stream isn't live or starting soon to prevent reload
    if (this._liveStream != null && (this._liveStream.status === LiveStreamStatus.Live || this._liveStream.status === LiveStreamStatus.StartSoon)) {
      return;
    }

    this._liveStream = value;
  }

  @Input() lesson!: Lesson;
  @Input() event!: Event;

  hasEnded!: boolean;
  showStillImage!: boolean;
  safeUrl: any;
  safeUrlStillImage: any;
  public _liveStream!: LiveStream;

  // properties
  sportName!: string;
  title!: string;
  starts!: Date;
  ends!: Date;
  languageInfo!: string;
  instructors!: Instructor[];

  subscription!: Subscription;

  constructor(
    private domSanitizer: DomSanitizer,
    private lessonsService: LessonsService,
    private eventsService: EventsService) { }

  ngOnInit() {
    this.hasEnded = false;
    this.showStillImage = false;
    this.setProperties()
    this.checkLiveStreamStartLog();
    this.setLiveStreamUrl();
  }

  private setProperties() {
    if (this.lesson != null) {
      // from lesson
      this.sportName = this.lesson.sportName;
      this.title = this.lesson.title;
      this.starts = this.lesson.starts;
      this.ends = this.lesson.ends;
      this.languageInfo = this.lesson.languageInfo;
      this.instructors = this.lesson.instructors;
    } else if (this.event != null) {
      // from event
      this.sportName = this.event.sportName;
      this.title = this.event.title;
      this.starts = this.event.starts;
      this.ends = this.event.ends;
      this.languageInfo = this.event.languageInfo;
      this.instructors = this.event.instructors;
    }
  }

  public setLiveStreamUrl() {
    if (this._liveStream != null && this._liveStream.link != null) {

      // url needs to be sanitized
      this.safeUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this._liveStream.link);
      this.safeUrlStillImage = this.domSanitizer.bypassSecurityTrustResourceUrl(this._liveStream.stillImageUrl);

      // check if we need to show the still image
      if (this._liveStream.status === LiveStreamStatus.StartSoon && moment().isBefore(moment(this.starts).subtract(this._liveStream.showStillImageUntilBeforeStartInSeconds, 'seconds'))) {
        this.showStillImage = true;
      }

      // timer to check if stream ended or the still image should be removed
      this.subscription = timer(0, 5000).subscribe(() => {

        // check has endeed
        if (moment(this.ends).add(this._liveStream.showBeforeStartInSeconds, 'seconds') < moment() && this.ends !== null) {
          this.hasEnded = true;
        }

        // chek still image
        if (this._liveStream.status === LiveStreamStatus.StartSoon && moment().isSameOrAfter(moment(this.starts).subtract(this._liveStream.showStillImageUntilBeforeStartInSeconds, 'seconds'))) {
          this.showStillImage = false;
        }
      });
    }
  }

  private checkLiveStreamStartLog() {
    // log stream start
    if (this._liveStream != null && (this._liveStream.status === LiveStreamStatus.Live || this._liveStream.status === LiveStreamStatus.StartSoon)) {
      this.logLiveStreamStart();
    }
  }

  private logLiveStreamStart() {
    if (this.lesson != null) {
      // log lesson
      this.lessonsService.createLiveStreamLog(this.lesson.id).subscribe((result => {

      }));
    } else if (this.event != null) {
      // log event
      this.eventsService.createLiveStreamLog(this.event.id).subscribe((result => {

      }));
    }
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { MembershipApplicationAdminService } from '../services/membership-application-admin.service';
import { Observable, map } from 'rxjs';
import { Editor } from '../models/editor.model';

@Pipe({
  name: 'editorDisplayName',
  pure: true
})
export class EditorDisplayNamePipe implements PipeTransform {

  constructor(private membershipApplicationAdminService: MembershipApplicationAdminService) { }

  transform(value: number | undefined | null, ...args: unknown[]): Observable<string> {
    if (value === undefined || value === null) {
      return new Observable<string>();
    } else {
      return this.membershipApplicationAdminService.getEditors().pipe(
        map((editors: Editor[]): string => {
          const editor = editors.find((x: Editor) => x.asvzId === value);
          if (editor) {
            return `${editor.firstName} ${editor.lastName} (${editor.asvzId})`;
          } else {
            return value.toString();
          }
        })
        
      )
    }
  }

}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import {AccountMainLayoutComponent} from './account-main-layout/account-main-layout.component';
import {AccountChangePasswordComponent} from './account-change-password/account-change-password.component';
import {AccountChangeDataComponent} from './account-change-data/account-change-data.component';
import {SharedModule} from '../shared/shared.module';
import { AccountService } from './account.service';
import { AccountRoutingModule } from './account-routing.modules';
import { TranslocoService } from '@ngneat/transloco';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    AccountRoutingModule
  ],
  declarations: [
    AccountMainLayoutComponent,
    AccountChangePasswordComponent,
    AccountChangeDataComponent,
  ],
  providers: [AccountService]
})
export class AccountModule {
  constructor (transloco: TranslocoService) {
    // this language will be used as a fallback when a translation isn't found in the current language
    transloco.setDefaultLang('de');

  }
}

export class StatusStep {

    _current: boolean;
    _completed: boolean;
    _skipped: boolean;
    _stepName: string;
    _next?: boolean;
    _status: number;
    sort: number;

    constructor(status: number, stepName: string, sort: number, current = false) {
        this._status = status;
        this._stepName = stepName;
        this._completed = false;
        this._skipped = false;
        this._current = current;
        this.sort = sort;
    }

    set current(current) {
        this._current = current;
    }

    get current() {
        return this._current;
    }

    set completed(completed) {
        this._completed = completed;
    }

    get completed() {
        return this._completed;
    }

    get stepName() {
        return this._stepName;
    }

    set next(next) {
        this._next = next;
    }
    get next() {
        return this._next;
    }

    set status(status) {
        this._status = status;
    }
    get status() {
        return this._status;
    }

}

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { TournamentService } from '../services/tournament.service';
import { TournamentTypeEnum } from '../enums/tournament-type.enum';
import { forkJoin } from 'rxjs';
import { TournamentTeamChangeGroupModalComponent } from '../tournament-team-change-group-modal/tournament-team-change-group-modal.component';
import { TournamentGroupAddRoomModalComponent } from '../tournament-group-add-room-modal/tournament-group-add-room-modal.component';
import { ConfirmationModalComponent } from 'src/app/shared/confirmation-modal/confirmation-modal.component';
import { ToastrService } from 'ngx-toastr';
import { TournamentGroupRoomListModel } from '../models/tournament-group-room-list.model';
import { TournamentEventGroupsModel } from '../models/tournament-event-groups.model';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
    selector: 'app-tournament-groups-details',
    templateUrl: './tournament-groups-details.component.html',
    styleUrls: ['./tournament-groups-details.component.scss'],
})
export class TournamentGroupsDetailsComponent implements OnInit {

    @Input() tournamentPlanId!: string;

    loadingData: boolean = true;
    hasError: boolean = false;

    tournamentEventGroups?: TournamentEventGroupsModel;

    TournamentTypeEnum = TournamentTypeEnum;

    constructor(
        private translocoService: TranslocoService,
        private tournamentService: TournamentService,
        private activatedRoute: ActivatedRoute,
        private toastrService: ToastrService,
        private modalService: BsModalService) {
    }

    ngOnInit() {
        if (this.activatedRoute.parent) {
            this.activatedRoute.parent.params.subscribe((params: Params) => {
                this.tournamentPlanId = params['tournamentPlanId'];
                this.loadData();
            });
        }
    }

    loadData(): void {
        this.loadingData = true;
        this.tournamentEventGroups = undefined;

        forkJoin({
            tournamentEventGroups: this.tournamentService.getTournamentGroups(this.tournamentPlanId),
        }).subscribe({
            next: result => {
                var tournamentEventGroups = result.tournamentEventGroups;
                tournamentEventGroups.eventGroups = tournamentEventGroups.eventGroups.sort((a, b) => a.eventTitle.localeCompare(b.eventTitle));
                tournamentEventGroups.eventGroups.forEach(x => {
                    x.groups = x.groups.sort((a, b) => a.number < b.number ? -1 : 1);
                    x.unallocatedTeams = x.unallocatedTeams.sort((a, b) => a.teamName?.localeCompare(b.teamName));
                    x.groups.forEach(g => {
                        g.teams = g.teams.sort((a, b) => a.teamName?.localeCompare(b.teamName));
                    });
                })

                this.tournamentEventGroups = tournamentEventGroups;

                this.loadingData = false;
            },
            error: error => {
                this.loadingData = false;
                this.hasError = true;
            }
        });
    }

    openGroupCreationModal() {
        const initialstate: ModalOptions = {
            initialState: {
                body: this.translocoService.translate('Tournaments.Groups.TriggerCreateQuestion'),
            }
        }

        const bsModalRef = this.modalService.show(ConfirmationModalComponent, initialstate);

        bsModalRef.content!.confirmed.subscribe(() => {
            this.triggerGroupCreation();
        })
    }

    triggerGroupCreation() {
        this.loadingData = true;
        this.tournamentService.triggerFormingGroups(this.tournamentPlanId).subscribe({
            next: (isSuccess) => {
                this.loadingData = false;

                if (isSuccess) {

                    this.toastrService.success(this.translocoService.translate('Tournaments.Groups.TriggerCreateSuccess'));
                } else {
                    this.toastrService.error(this.translocoService.translate('Tournaments.Groups.TriggerCreateError'));
                }

                this.loadData();
            },
            error: () => {
                this.loadingData = false;
                this.toastrService.error(this.translocoService.translate('Tournaments.Groups.TriggerCreateError'));
            }
        });
    }

    openGroupDeleteModal() {
        const initialstate: ModalOptions = {
            initialState: {
                body: this.translocoService.translate('Tournaments.Groups.TriggerDeleteQuestion'),
            }
        }

        const bsModalRef = this.modalService.show(ConfirmationModalComponent, initialstate);

        bsModalRef.content!.confirmed.subscribe(() => {
            this.triggerGroupDelete();
        })
    }

    triggerGroupDelete() {
        this.loadingData = true;
        this.tournamentService.deleteGroups(this.tournamentPlanId).subscribe({
            next: (isSuccess) => {
                this.loadingData = false;

                if (isSuccess) {

                    this.toastrService.success(this.translocoService.translate('Tournaments.Groups.TriggerDeleteSuccess'));
                } else {
                    this.toastrService.warning(this.translocoService.translate('Tournaments.Groups.TriggerDeleteFailed'));
                }

                this.loadData();
            },
            error: () => {
                this.loadingData = false;
                this.toastrService.error(this.translocoService.translate('Tournaments.Groups.TriggerDeleteError'));
            }
        });
    }

    openSendEmailToCaptainsModal() {
        const initialstate: ModalOptions = {
            initialState: {
                body: this.translocoService.translate('Tournaments.CaptainEmail.SendEmailsQuestion'),
            }
        }

        const bsModalRef = this.modalService.show(ConfirmationModalComponent, initialstate);
        bsModalRef.content!.confirmed.subscribe(() => {
            this.sendEmailToCaptains();
        })
    }


    sendEmailToCaptains(): void {
        this.loadingData = true;

        this.tournamentService.sendTeamCaptainEmail(this.tournamentPlanId).subscribe({
            next: (isSuccess) => {
                this.loadingData = false;
                if (isSuccess) {
                    this.toastrService.success(this.translocoService.translate('Tournaments.CaptainEmail.EmailsSent'));
                } else {
                    this.toastrService.error(this.translocoService.translate('Tournaments.CaptainEmail.EmailsSentError'));
                }
            },
            error: () => {
                this.loadingData = false;
                this.toastrService.error(this.translocoService.translate('Tournaments.CaptainEmail.EmailsSentError'));
            }
        });
    }

    openChangeTeamGroupModal(registrationId: number, groupId: string | null, tournamentEventId: string): void {
        let tournamentEvent = this.tournamentEventGroups?.eventGroups!.find(x => x.id === tournamentEventId);
        let groups = tournamentEvent?.groups.filter(x => x.id != groupId);

        const initialState: ModalOptions = {
            backdrop: 'static',
            keyboard: false,
            initialState: {
                tournamentPlanId: this.tournamentPlanId,
                registrationId: registrationId,
                groups: groups,
                groupPrefix: tournamentEvent!.prefix
            },
        };

        const bsModalRef = this.modalService.show(TournamentTeamChangeGroupModalComponent, initialState);
        bsModalRef.content?.confirmed.subscribe(x => {
            this.loadData();
        });
    }

    openGroupAddRoomModal(groupId: string): void {
        const initialState: ModalOptions = {
            backdrop: 'static',
            keyboard: false,
            initialState: {
                tournamentPlanId: this.tournamentPlanId,
                groupId: groupId
            },
        };

        const bsModalRef = this.modalService.show(TournamentGroupAddRoomModalComponent, initialState);
        bsModalRef.content?.confirmed.subscribe(x => {
            this.loadData();
        });
    }

    deleteGroupRoom(groupId: string, groupRoomId: string): void {
        const initialstate: ModalOptions = {
            initialState: {
                body: this.translocoService.translate('ConfirmDeleteAction'),
                icon: 'fa-solid fa-circle-info',
            }
        }

        const bsModalRef = this.modalService.show(ConfirmationModalComponent, initialstate);

        bsModalRef.content!.confirmed.subscribe(() => {
            this.tournamentService.deleteTournamentGroupRoom(this.tournamentPlanId, groupId, groupRoomId).subscribe({
                next: result => {
                    this.loadData();
                },
                error: error => {
                    this.toastrService.error(this.translocoService.translate('Msg.SaveError'));
                }
            });
        });
    }

    sortRooms(rooms: TournamentGroupRoomListModel[]): TournamentGroupRoomListModel[] {
        return rooms.sort((a, b) => a.roomName?.localeCompare(b.roomName)).sort((a, b) => a.facilityName?.localeCompare(b.facilityName));
    }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Facility } from '../models/facility.model';
import { EventAndLessonList } from '../models/event-and-lesson-list.model';
import { Sport } from '../models/sport.model';
import { FacilityClosingList } from '../models/facility-closing-list.model';
import { FacilityAnnouncementList } from '../models/facility-announcement-list.model';
import { SpecialAssignments } from '../models/special-assignments.model';
import { FreeRoom } from '../models/free-room.model';
import { environment } from 'src/environments/environment';

@Injectable()
export class InformationScreenService {

  constructor(private httpService: HttpClient) {

    }

  getEventsAndLessonsByFacilityId(id: string, take: number): Observable<EventAndLessonList[]> {
    return this.httpService.get(`${environment.api}/InfoScreen/${id}/EventsAndLessons?Take=${take}`)
      .pipe(
        map((results: any): EventAndLessonList[] => results)
      );
  }

  getFacilityById(id: string): Observable<Facility> {
    return this.httpService.get(`${environment.api}/InfoScreen/${id}`)
      .pipe(
        map((facility: any): Facility => facility)
      );
  }

  getFacilityClosings(id: string, take: number): Observable<FacilityClosingList[]> {
    return this.httpService.get(`${environment.api}/InfoScreen/${id}/Closings?Take=${take}`)
      .pipe(
        map((results: any): FacilityClosingList[] => results)
      );
  }

  getFacilityAnnouncements(id: string): Observable<FacilityAnnouncementList[]> {
    return this.httpService.get(`${environment.api}/InfoScreen/${id}/Announcements`)
      .pipe(
        map((results: any): FacilityAnnouncementList[] => results)
      );
  }

  getFacilitySpecialAssignments(id: string, take: number): Observable<SpecialAssignments[]> {
    return this.httpService.get(`${environment.api}/InfoScreen/${id}/SpecialAssignments?Take=${take}`)
      .pipe(
        map((results: any): SpecialAssignments[] => results)
      );
  }

  getFacilityFreeRooms(id: string, take: number): Observable<FreeRoom[]> {
    return this.httpService.get(`${environment.api}/InfoScreen/${id}/FreeRooms?Take=${take}`)
      .pipe(
        map((results: any): FreeRoom[] => results)
      );
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MembershipApplicationRoutingModule } from './membership-application-routing.module';
import { MembershipApplicationComponent } from './components/membership-application/membership-application.component';
import { BasicDataFormComponent } from './components/basic-data-form/basic-data-form.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ApplicationConfigurationsComponent } from './components/application-configurations/application-configurations.component';

import { TranslocoService } from '@ngneat/transloco';
import { SharedModule } from '../shared/shared.module';
import { NgxDatepickerModule } from '@premotec/ngx-datepicker';
import { EmailConfirmationComponent } from './components/email-confirmation/email-confirmation.component';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { PassPhotoUploaderComponent } from './components/pass-photo-uploader/pass-photo-uploader.component';

@NgModule({
  imports: [
    CommonModule,
    MembershipApplicationRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    NgxDatepickerModule
  ],
  declarations: [
    MembershipApplicationComponent,
    BasicDataFormComponent,
    ApplicationConfigurationsComponent,
    PassPhotoUploaderComponent,
    FileUploaderComponent,
    EmailConfirmationComponent
  ]
})
export class MembershipApplicationModule {
  constructor (translate: TranslocoService) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('de');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    // translate.use('de');
  }
 }

import { Component, Input, EventEmitter, Output } from '@angular/core';
import { BaseComponent } from '@premotec/ngx-essentials';
import { EnrollmentStateProperties } from '../../models/enrollment-state-properties.model';

@Component({
  selector: 'app-lessons-enrollment-button',
  templateUrl: './lessons-enrollment-button.component.html',
  styleUrls: ['./lessons-enrollment-button.component.scss']
})
export class LessonsEnrollmentButtonComponent extends BaseComponent {

  @Input() enrollmentProperties!: EnrollmentStateProperties;

  @Output() buttonClicked: EventEmitter<void> = new EventEmitter<void>();

  constructor(
  ) {
    super();
  }

  enrollForLesson(): void {
    this.buttonClicked.next();
  }


}

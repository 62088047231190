export class RegistrationSummary {
  id?: number;
  eventId?: number;
  addressId?: number;
  status?: number;
  statusName?: string;
  webRegistrationTypeId?: number;
  webRegistrationType?: string;
  invoiceId?: number;
  invoiceAmount?: number;
  paidAmount?: number;
  expiresOn?: Date;
  paymentId?: number;
  placeNumber?: number;
  changeDate?: Date;
  registrationDetailValues?: RegistrationDetailValue[];
}

export class RegistrationDetailValue {
  id?: number;
  eventRegistrationDetailId?: number;
  sort?: number;
  value?: string;
  price?: number;
}

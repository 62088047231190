import {Component, Input, OnInit} from '@angular/core';
import {Maintenance} from '../../maintenance.model';

@Component({
  selector: 'app-maintenance-list',
  templateUrl: './maintenance-list.component.html',
  styleUrls: ['./maintenance-list.component.scss']
})
export class MaintenanceListComponent implements OnInit {

  @Input()
  maintenances!: Array<Maintenance>;

  showInactive = false;

  constructor() {
  }

  ngOnInit() {
  }

  getMaintenancesLength() {
    return (this.showInactive ? this.maintenances.length : this.maintenances.filter((maintenance) => maintenance.active).length);
  }
}

import { Facility } from './facility.model';
import { SportsSchedules } from './sports-schedules.model';
import { SpecialAssignments } from './special-assignments.model';
import { FreeRoom } from './free-room.model';
import { OpeningHours } from './opening-hours.model';
import { FacilityClosingList } from './facility-closing-list.model';
import { FacilityAnnouncementList } from './facility-announcement-list.model';

export class InformationScreen {
    facilityId!: string;
    facility!: Facility;
    oldDate!: Date;
    updatedTime!: string;
    displayDatetime!: string;
    showSponsor = false;
    pageInErrorState = false;
    closings!: FacilityClosingList[];
    announcements: FacilityAnnouncementList[] = [];

    sportsSchedules = new SportsSchedules();
    specialAssignments: SpecialAssignments[] = [];
    freeRooms :FreeRoom[] = [];
    openingHours = new OpeningHours();
}

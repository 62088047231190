import { Injectable } from '@angular/core';
import { Subscription, switchMap, timer } from 'rxjs';
import { RegistrationStates } from '../registration-states.enum';
import { RegistrationService } from '../registration.service';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { TranslocoService } from '@ngneat/transloco';
import { ConfirmationModalComponent } from 'src/app/shared/confirmation-modal/confirmation-modal.component';
import { Router } from '@angular/router';

@Injectable()

export class RegistrationStateService {

    private statusTimerSubscription?: Subscription;

    constructor(
        private registrationService: RegistrationService,
        private translocoService: TranslocoService,
        private modalService: BsModalService,
        private router: Router) { }

    stopStatusTimer(): void {
        this.statusTimerSubscription?.unsubscribe();
    }

    startStatusChangedTimer(registrationId: number, status: RegistrationStates): void {
        this.stopStatusTimer(); // Ensure any existing timer is stopped before starting a new one

        let statusToCheck = status;
        if (statusToCheck === RegistrationStates.UserData) {
            statusToCheck = RegistrationStates.New;
        }

        this.statusTimerSubscription = timer(0, 5000)
            .pipe(
                switchMap(() => this.registrationService.getIfStatusChanged(registrationId, statusToCheck))
            )
            .subscribe((res) => {
                if (res === true) {
                    this.stopStatusTimer(); // Stop the timer as the status has changed
                    this.showRegistrationChangedModal();
                }
            });
    }

    private showRegistrationChangedModal() {
        const initialstate: ModalOptions = {
            initialState: {
                body: this.translocoService.translate('RegistrationChanged'),
                icon: 'fa-solid fa-circle-info',
                onlyConfirm: true
            }
        }
        const bsModalRef = this.modalService.show(ConfirmationModalComponent, initialstate);

        bsModalRef.onHidden?.subscribe(x => { this.navigate() })
    }

    navigate() {
        this.router.navigateByUrl("my-registrations");
    }


}
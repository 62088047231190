import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { TournamentGroupListModel } from '../models/tournament-group-list.model';
import { TournamentService } from '../services/tournament.service';
import { TournamentGroupAddTeamModel } from '../models/tournament-group-add-team.model';

@Component({
  selector: 'app-tournament-team-change-group-modal',
  templateUrl: './tournament-team-change-group-modal.component.html',
  styleUrls: ['./tournament-team-change-group-modal.component.scss']
})
export class TournamentTeamChangeGroupModalComponent implements OnInit {

  @Output() confirmed = new EventEmitter<boolean>();

  groupForm!: UntypedFormGroup;

  groups!: TournamentGroupListModel[];
  registrationId!: number;
  tournamentPlanId!: string;
  groupPrefix!: string;

  isSaving = false;

  constructor(
    private fb: FormBuilder,
    private bsModalRef: BsModalRef,
    private tournamentService: TournamentService,
    private toastrService: ToastrService,
    private translocoService: TranslocoService
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.groupForm = this.fb.group({
      groupId: [this.getSortedGroups()[0].id, Validators.required]
    });
  }

  getSortedGroups(): TournamentGroupListModel[] {
    return this.groups.sort((a, b) => a.number < b.number ? -1 : 1);
  }

  confirm() {
    if (!this.groupForm.valid) return;

    this.isSaving = true;

    let updateModel = new TournamentGroupAddTeamModel();
    updateModel.registrationId = this.registrationId;
    var groupId = this.groupForm.get('groupId')?.value;

    this.tournamentService.addTeamToGroup(this.tournamentPlanId, groupId, updateModel).subscribe({
      next: result => {
        this.isSaving = false;
        this.confirmed.emit(true);
        this.bsModalRef.hide();
      },
      error: error => {
        this.isSaving = false;
        this.toastrService.error(this.translocoService.translate('Msg.SaveError'));
      }
    });
  }

  decline() {
    this.bsModalRef.hide();
  }
}

<app-spinner *ngIf="!gamePlanModels" [margin]="'50px'"></app-spinner>

<div class="container" *ngIf="gamePlanModels">
    <div class="row">
        <div class="col-12 p-2 mb-3 bg-primary d-flex flex-column flex-md-row gap-1">
            <div class="flex-grow-1 m-0">
                <h3 class="text-light">Spielplan {{getEventNameById(gamePlanModels.eventId)}}</h3>
            </div>
            <div>
              <a class="btn btn-default w-100"
                 *ngIf="gamePlanModels.additionLink && gamePlanModels.additionLinkTitle"
                 [href]="gamePlanModels.additionLink">
                {{gamePlanModels.additionLinkTitle}}
              </a>
            </div>
            <div>
                <a class="btn btn-default w-100"
                    [routerLink]="'/tournaments/'+tournamentPlanId+'/availableSlots'">{{'Tournaments.LinksToPages.AvailableSlots' | transloco}}
                </a>
            </div>
            <div>
                <a class="btn btn-default w-100"
                    [routerLink]="'/tournaments/'+tournamentPlanId+'/events/'+eventId+'/groups'">{{'Tournaments.LinksToPages.Groups' | transloco}}
                </a>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-xxl-3 mb-3">
            <div class="card">
                <div class="card-header">
                    Gruppen
                </div>
                <ul class="list-group  list-group-flush">
                    <li class="list-group-item" *ngFor="let groupId of getGroupIds(eventId)">

                        <div class="d-flex">
                            <div class="flex-grow-1">
                                <a class=""
                                    [routerLink]="'/tournaments/'+tournamentPlanId+'/events/'+eventId+'/groups/'+groupId+'/ranking'">{{getGroupNameById(groupId)}}</a>
                            </div>
                            <div class="">
                                <span *ngIf="isGroupVisible(groupId)" (click)="toggleGroupVisbibility(groupId)"
                                    type="button">
                                    <i class="fa-solid fa-eye"></i>
                                </span>
                                <span *ngIf="!isGroupVisible(groupId)" (click)="toggleGroupVisbibility(groupId)"
                                    type="button">
                                    <i class="fa-solid fa-eye-slash"></i>
                                </span>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-12 col-xxl-9">
            <div *ngFor="let gameDay of getFilteredGameDays()">
                <div class="well card-header rounded border-bottom mb-3">
                    <div class="d-flex flex-lg-row flex-column">
                        <div class="flex-grow-1">
                            {{getGroupNameById(gameDay.groupId)}}
                        </div>
                        <div class="flex-grow-1 text-lg-end">
                            <i class="fa-solid fa-calendar-days"></i> {{getDayDisplayName(gameDay.dayDate)}}
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 mb-3" *ngFor="let location of gameDay.gameLocations">
                        <div class="card border-0">
                            <div class="well border bg-light text-dark">
                                <div class="d-flex flex-lg-row flex-column">
                                    <div class="flex-grow-1">
                                        <i class="fa-solid fa-location-dot me-1"></i> <b>{{getGameLocationName(location)}}</b>
                                    </div>
                                    <div class="flex-grow-1 text-lg-end pt-1 pt-lg-0" *ngIf="gamePlanModels.additionalFieldName != undefined || hasReferees(location.games)">
                                        <span class="pe-2" *ngIf="gamePlanModels.additionalFieldName != undefined">
                                            <i class="fa-solid fa-circle-info text-secondary"
                                                [tooltip]="gamePlanModels.additionalFieldName"></i>
                                            {{gamePlanModels.additionalFieldName}}
                                        </span>
                                        <span *ngIf="hasReferees(location.games)">
                                            <i class="fa-solid fa-shield text-secondary"
                                                [tooltip]="'Tournaments.Public.Referee' | transloco"></i> Schiedsrichter
                                        </span>
                                    </div>
                                </div>

                            </div>

                            <ul class="list-group  list-group-flush border-0">
                                <li class="list-group-item p-0 mt-1 border" *ngFor="let game of location.games">
                                    <div class="d-flex">
                                        <div class="p-2 align-self-center">
                                            <b>{{getGameTime(game.from)}}</b>
                                        </div>
                                        <div class="flex-grow-1 border-start">
                                            <div class="p-2 d-flex">
                                                <div class="flex-grow-1 align-self-center">
                                                    <div>
                                                        <b>{{game.homeTeamName}}</b>
                                                        <span class="ps-1"
                                                            *ngIf="gamePlanModels.additionalFieldName != undefined">
                                                            <i class="fa-solid fa-circle-info text-secondary"
                                                                [tooltip]="gamePlanModels.additionalFieldName"></i>
                                                            {{game.homeTeamAddition}}
                                                        </span>

                                                    </div>
                                                    <div style="line-height: 10px;">
                                                        -
                                                    </div>
                                                    <div>
                                                        <b>{{game.guestTeamName}}</b>
                                                        <span class="ps-1"
                                                            *ngIf="gamePlanModels.additionalFieldName != undefined">
                                                            <i class="fa-solid fa-circle-info text-secondary"
                                                                [tooltip]="gamePlanModels.additionalFieldName"></i>
                                                            {{game.guestTeamAddition}}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="align-self-center ps-3" *ngIf="game.sets.length">
                                                    <div *ngFor="let set of game.sets">
                                                        {{set.homePoints}}:{{set.guestPoints}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="p-2 bg-light border-top"
                                                *ngIf="game.refereeTeamId != undefined">
                                                <i class="fa-solid fa-shield text-secondary"
                                                    [tooltip]="'Tournaments.Public.Referee' | transloco"></i>
                                                <b> {{game.refereeTeamName}}</b>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

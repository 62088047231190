<div class="row">
    <div class="col-sm-12">
        <button *ngIf="!enrollmentProperties.withdrawIsExpired" class="btn btn-primary"
            (click)="showWithdrawConfirmationModal()" id="btnRegister">
            <i class="fa fa-user-times color-white" aria-hidden="true"></i>
            {{'LessonActions.WithdrawFromLesson' | transloco | uppercase}}
        </button>

        <button *ngIf="enrollmentProperties.withdrawIsExpired" class="btn btn-default disabled" id="btnRegister">
            <i class="fa fa-user-times" aria-hidden="true"></i> {{'LessonActions.WithdrawFromLesson' |
            transloco |
            uppercase}}
        </button>

        <span style="white-space: nowrap;">
            {{'SeatNrMsg' | transloco }} {{enrollmentProperties.currentEnrollment?.placeNumber}}
        </span>
    </div>
</div>
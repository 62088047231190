import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {map, catchError} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {Sport} from './sport.model';
import {SportSimpleInfo} from './sport-simple-info.model';
import { HttpResponseErrorHandlerService } from '../shared/services/http-response-error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class SportsService {

  constructor(private httpService: HttpClient,
    private httpResponseErrorHandler: HttpResponseErrorHandlerService) {
  }

  getAllSports(): Observable<Sport[]> {
    return this.httpService.get(`${environment.externalSite}/Sports`)
      .pipe(
        map((sports: any): Array<Sport> => sports),
        catchError(e => this.httpResponseErrorHandler.HandleResponseError(e))
      );
  }

  getAllSportsSimpleInfo(): Observable<SportSimpleInfo[]> {
    return this.httpService.get(`${environment.api}/Sports`)
      .pipe(
        map((sports: any): Array<SportSimpleInfo> => sports.data),
        catchError(e => this.httpResponseErrorHandler.HandleResponseError(e))
      );
  }

  getSportById(id: string): Observable<Sport> {
    return this.httpService.get(`${environment.externalSite}/Sports/${id}`)
    .pipe(
      map((sport: any): Sport => sport),
      catchError(e => this.httpResponseErrorHandler.HandleResponseError(e))
    );
  }
}

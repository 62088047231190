import { Component, OnInit, Input } from '@angular/core';
import { EnrollmentList } from 'src/app/lessons/models/enrollment-list.model';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-my-enrollments-table',
  templateUrl: './my-enrollments-table.component.html',
  styleUrls: ['./my-enrollments-table.component.scss']
})
export class MyEnrollmentsTableComponent implements OnInit {

  @Input()
  enrollments!: Array<EnrollmentList>;
  @Input()
  noEntries!: boolean;

  title = 'My Enrollments';
  externalSiteUrl: string;

  constructor() {
    this.externalSiteUrl = environment.externalSite;
   }

  ngOnInit() {
  }

}

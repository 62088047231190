import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FaqComponent implements OnInit {

  constructor(private titleService: Title) {
    this.titleService.setTitle('FAQ Online-Schalter und Online-Einschreibung');
  }

  ngOnInit() {
  }

}

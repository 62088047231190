import { Component, OnInit, Input } from '@angular/core';
import { OpeningHours } from '../../models/opening-hours.model';
import { SimplifiedOpeningHours } from '../../models/simplified-opening-hours.model';

@Component({
  selector: 'app-information-screen-opening-hours',
  templateUrl: './opening-hours.component.html',
  styleUrls: ['./opening-hours.component.scss']
})
export class OpeningHoursComponent implements OnInit {

  @Input() openingHours!: OpeningHours;

  constructor() { }

  ngOnInit() {
    
  }

  simplifyOpeningHours(weeks: string[], openingHours: any): SimplifiedOpeningHours[] {
    let simplified: SimplifiedOpeningHours[] = [];

    weeks.forEach(function (value) {
      let from = openingHours[value+"From"]
      let to = openingHours[value+"To"]
      if(simplified.length == 0|| simplified[simplified.length-1].from != from || simplified[simplified.length-1].to != to){
        let newSimplified = new SimplifiedOpeningHours()
        newSimplified.from = from;
        newSimplified.to = to;
        newSimplified.firstDay = value;

        simplified.push(newSimplified);
      }else{
        simplified[simplified.length-1].secondDay = value;
      }
      
    });
    return simplified;
  }

  displayOpeningHoursCounter():SimplifiedOpeningHours[]{
    return this.simplifyOpeningHours(this.openingHours.weekdays, this.openingHours.openingHoursCounter);
  }

  displayOpeningHoursSports():SimplifiedOpeningHours[]{
    return this.simplifyOpeningHours(this.openingHours.weekdays, this.openingHours.openingHoursSports);
  }
}

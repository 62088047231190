import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl, AbstractControl, ValidatorFn } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { EventRegistrationDetail } from 'src/app/events/event-registration-detail.model';
import { ControlType } from '../control-type.enum';
import { RegistrationDetailValue, RegistrationSummary } from '../../registration-summary.model';
import { RegistrationService } from '../../registration.service';
import { Event } from 'src/app/events/event.model';
import { RegistrationStates } from '../../registration-states.enum';
import { RegistrationStateService } from '../../services/registration-state.service';

@Component({
  selector: 'app-registration-details',
  templateUrl: './registration-details.component.html',
  styleUrls: ['./registration-details.component.scss'],
})
export class RegistrationDetailsComponent implements OnInit {

  @Input() event!: Event;
  @Input() registrationSummary!: RegistrationSummary;
  @Output() nextWizardState: EventEmitter<number> = new EventEmitter();
  @Output() dirtyState: EventEmitter<boolean> = new EventEmitter();

  registrationDetailsForm: FormGroup = new FormGroup({});
  registrationDetails!: EventRegistrationDetail[];

  showForm = false;

  submitted = false;
  disableSendButton = false;
  controlType = ControlType;

  showLoadingIndicator = true;
  httpError = false;

  constructor(private registrationService: RegistrationService,
    private registrationStateService: RegistrationStateService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.registrationDetails = this.registrationService.getRegistrationDetails(this.event, this.registrationSummary);
    this.registrationDetailsForm.valueChanges.subscribe(x => this.detailChanged());
    this.initForm();
    this.showLoadingIndicator = false;

    this.registrationDetailsForm.statusChanges.subscribe(() => {
      this.dirtyState.emit(this.registrationDetailsForm.dirty);
    })
  }

  submit() {
    this.submitted = true;
    this.disableSendButton = true;

    if (this.registrationDetailsForm.valid) {
      const details = {
        'details': this.readDetails(),
      };
      // On completion, set the registration details and all accepted conditions
      this.submitRegistration(details);
    } else {
      this.disableSendButton = false;
      Object.keys(this.registrationDetailsForm.controls).forEach(key => {
        this.registrationDetailsForm!.get(key)!.markAsDirty();
      });

      // this.translateService.get('InvalidRegistrationForm').subscribe((value) => console.log(value));

      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }
  }

  readAcceptAvb(): boolean {
    const conditionControl = this.registrationDetailsForm.get('acceptingAvb')!;
    return conditionControl && conditionControl.value === true;
  }

  readAcceptAusschreibung(): boolean {
    const conditionControl = this.registrationDetailsForm.get('acceptingInfo')!;
    return conditionControl && conditionControl.value === true;
  }

  readAcceptMembership(): boolean {
    const conditionControl = this.registrationDetailsForm.get('acceptingEnrollment')!;
    return conditionControl && conditionControl.value === true;
  }

  submitRegistration(details: any) {
    this.registrationStateService.stopStatusTimer();
    this.httpError = false;
    this.registrationService.setStateDetails(this.registrationSummary.id!, details)
      .subscribe(
        () => {
          this.submitted = false;
          this.disableSendButton = true;
          this.setRegistrationDetailsToLocalRegistration(details['details']);
          this.nextWizardState.emit(RegistrationStates.Temporary);
        },
        (error) => {
          this.httpError = true;
          this.disableSendButton = false;
          this.submitted = false;
          this.registrationStateService.startStatusChangedTimer(this.registrationSummary.id!, this.registrationSummary.status!);
        }
      );
  }

  detailChanged(){
    const details = {
      'details': this.readDetails(),
    };
    // On completion, set the registration details and all accepted conditions
    this.setRegistrationDetailsToLocalRegistration(details['details']);
  }

  // we need this as we don't get the updated registration details back from the server
  setRegistrationDetailsToLocalRegistration(details: any[]) {

    if (!details || !Array.isArray(details)) { return };

    // for a "new" registration, no registrationDetails are provided. To persist the data, init the details on registrationSummary
    if (this.registrationSummary.registrationDetailValues!.length === 0) {
      this.event.registrationDetails.forEach(registrationDetail => {
        const detail = new RegistrationDetailValue();
        detail.eventRegistrationDetailId = registrationDetail.id;
        this.registrationSummary.registrationDetailValues!.push(detail);
      })
    }

    details.forEach(detail => {
      this.registrationSummary.registrationDetailValues!.forEach(registrationDetail => {
        if (registrationDetail.eventRegistrationDetailId === +detail['id']) {
          registrationDetail.value = detail['value'];
          return;
        }
      });
    });
  }

  private initForm() {

    this.registrationDetails.forEach(detail => {

      let controlValue: string | Date = detail.defaultValue;

      if (detail.type !== this.controlType.Info) {

        const validators = [];

        if (detail.type === this.controlType.Date) {
          validators.push(this.dateValidator(detail.defaultValue));
          controlValue = new Date(detail.defaultValue);
        }

        if (detail.isRequired) {
          validators.push(Validators.required);

          if (detail.type === this.controlType.Number) {
            validators.push(Validators.pattern('^[0-9]+\,?[0-9]*$'));
          }

          if (detail.type === this.controlType.Checkbox) {
            validators.push(this.validateRequiredCheckbox);
          }

        }

        this.registrationDetailsForm.addControl(
          detail.id.toString(),
          new FormControl(controlValue, validators)
        );
      }
    });

    this.showForm = true;

  }

  readDetails() {
    // TODO: Create a class for the details to be updated. It's very error prone this way!
    const details : any[] = [];
    this.event.registrationDetails.forEach(detail => {

      switch (detail.type) {
        case this.controlType.Info: {
          details.push(
            {
              'id': detail.id,
              'value': ''
            }
          );
          break;
        }
        case this.controlType.Date: {
          // this hack is necessary because of a problem in the datepicker package
          let value: any = this.registrationDetailsForm.get(detail.id.toString())!.value;
          if (value != null) {
            if (value instanceof Date) {
              value = moment(value).format('YYYY-MM-DD');
            } else {
              value = value === '01.01.1900' ? '' : value;
            }
          }
          details.push(
            {
              'id': detail.id,
              'value': value
            }
          );
          break;
        }
        default: {
          details.push(
            {
              'id': detail.id,
              'value': this.registrationDetailsForm.get(detail.id.toString())?.value
            }
          );
          break;
        }
      }
    });

    return details;
  }

  getValue(id: number) {
    return this.registrationDetailsForm.get(id.toString())?.value;
  }

  validateRequiredCheckbox(control: AbstractControl) {
    if (!control.value.includes('true')) {
      return { 'invalidCheckbox': true };
    }
    return null;
  }

  dateValidator(defaultValue: String): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value !== defaultValue &&
        !moment(control.value, moment.ISO_8601, true).isValid() && control.value !== null && control.value !== '') {
        return { 'invalidDate': true };
      }
      return null;
    };
  }

  // findInvalidControls method is for testing-purposes only
  public findInvalidControls() {
    const invalid = [];
    const controls = this.registrationDetailsForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }
}

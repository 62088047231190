<!-- List as Table and Preview in Modal-->
<!-- <table class="table table-striped" *ngIf="applicationFiles.length">
  <tr>
    <td></td>
    <td>Filename</td>
    <td>Filetype</td>
  </tr>
  <tr *ngFor="let file of applicationFiles;" class="file">
    <td>
      <button class="btn btn-default btn-sm" (click)="openIframeModal(file.id)"><i class="fa fa-eye"></i></button>
      <button class="btn btn-default btn-sm" (click)="downloadFile(file)" style="margin-left:0.5em;"><i class="fa fa-download"></i></button>
    </td>
    <td>
      <button (click)="downloadFile(file)" class="btn btn-link">{{file.fileName}}</button></td>
    <td>{{file.fileName.split('.').pop()}}</td>
  </tr>
</table>

 -->

 <!-- List as embedded files -->
<div class="row" *ngFor="let file of applicationFiles">
  <div class="col-md-8" >
    <app-ma-file-display [applicationId]="applicationId"  [document]="file"></app-ma-file-display>
  </div>
</div>
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Account } from 'src/app/account/account.model';

@Component({
  selector: 'app-pass-photo-required-msg',
  templateUrl: './pass-photo-required-msg.component.html',
  styleUrls: ['./pass-photo-required-msg.component.scss']
})
export class PassPhotoRequiredMsgComponent implements OnInit {

  @Input() account!: Account;
  @Input() messageToken!: string;
  @Input() checkProperty!: string;

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
  }

  public showMessage() {

    if (this.checkProperty === 'hasPrintCard') {
      if (this.account && this.account.hasPrintCard && !this.account.hasPicture) {
        return true;
      }
    }

    if (this.checkProperty === 'passPhotoRequired') {
      if (this.account && this.account.passPhotoRequired && !this.account.hasPicture) {
        return true;
      }
    }

    return false;
  }

  createLinkToUserData () : string {
    // router.createUrlTree ignores base url, therefore:
    // https://stackoverflow.com/questions/41447305/how-to-get-an-absolute-url-by-a-route-name-in-angular-2

    const internalUrl = '/account/user-data';
    // Resolve the base url as the full absolute url subtract the relative url.
    var currentAbsoluteUrl = window.location.href;
    var currentRelativeUrl = this.router.url;
    var index = currentAbsoluteUrl.indexOf(currentRelativeUrl);
    var baseUrl = currentAbsoluteUrl.substring(0, index);

    return baseUrl+internalUrl;
  }
}

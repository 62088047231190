import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CheckAppVersionChangeService {
  private _loadedAssemblyVersion: string | null = null; // The loaded assembly version.
  private _actualAssemblyVersion: string | null = null; // The lastest available assembly version.

  private _hasNewRelease = false; // Status indicating whether a new lease exists.
  private _checkAgain = true;   // Definable status indicating whether the service should continue to check for a new release.

  private _notifyAgain = true;  // User definable status indicating whether to issue a notification when a release is available.
  private _updateWithoutConfirmation?: boolean;  // User definable status indicating whether to auto-update when a new release is available.

  constructor(private httpService: HttpClient) {
    // Set the loaded assembly version
    this.getAssemblyVersion().subscribe(assemblyVersion => {
      this._actualAssemblyVersion = assemblyVersion;
      this._loadedAssemblyVersion = assemblyVersion;
    });
  }

  getAssemblyVersion(): Observable<string> {
    return this.httpService.get(`${environment.api}/WebAppInfos`)
      .pipe(

        map((response: any) => response),
        map((info: any): string => {
          this._actualAssemblyVersion = info.data.assemblyVersion;

          // NOTE: Skip this check for the initial load because the 'loadedAssemblyVersion' is always null
          if (this._loadedAssemblyVersion !== null) {
            this._hasNewRelease = !this._loadedAssemblyVersion?.match(this._actualAssemblyVersion!);
          }

          return this._actualAssemblyVersion!;
        }),
        catchError((error) => this.catchError(error))
      );
  }

  catchError(error: any): Observable<string> {
    console.log(`ERROR: Request for WebAppInfo failed.`, error);
    throw error;
  }

  getLoadedAssemblyVersion(): string {
    return this._loadedAssemblyVersion ?? "";
  }
  getActualAssemblyVersion(): string {
    return this._actualAssemblyVersion ?? "";
  }

  get notifyAgain() {
    return this._notifyAgain;
  }
  set notifyAgain(notifyAgain) {
    this._notifyAgain = notifyAgain;
  }

  get checkAgain() {
    return this._checkAgain;
  }
  set checkAgain(checkAgain) {
    this._checkAgain = checkAgain;
  }

  get hasNewRelease() {
    return this._hasNewRelease;
  }

  get updateWithoutConfirmation() {
    return this._updateWithoutConfirmation;
  }
  set updateWithoutConfirmation(updateWithoutConfirmation) {
    this._updateWithoutConfirmation = updateWithoutConfirmation;
  }
}

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Editor } from '../models/editor.model';
import { MembershipApplicationAdminService } from '../services/membership-application-admin.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-ma-editor-select-modal',
  templateUrl: './ma-editor-select-modal.component.html',
  styleUrls: ['./ma-editor-select-modal.component.scss']
})
export class MaEditorSelectModalComponent implements OnInit {

  isSaving = false;
  applicationId!: string;
  editorAsvzId?: number;
  editors: Editor[] = [];
  isLoading = false;

  selectItems: { value: number, label: string }[] = [];
  formControl: FormControl = this.fb.control(null, Validators.required);
  submitted = false;

  @Output() editorAsvzIdChanged = new EventEmitter<number | undefined>();

  constructor(
    private bsModalRef: BsModalRef,
    private membershipApplicationAdminService: MembershipApplicationAdminService,
    private fb: FormBuilder,
    private toastrService: ToastrService,
    private translocoService: TranslocoService) { }

  ngOnInit(): void {
    this.isLoading = true;

    if (this.editorAsvzId != null) {
      this.formControl.patchValue(this.editorAsvzId);
    }

    this.membershipApplicationAdminService.getEditors().subscribe((res: any) => {
      this.selectItems = res.map((editor: Editor) => {
        return {
          value: editor.asvzId,
          label: `${editor.firstName} ${editor.lastName} (${editor.asvzId})`
        }
      });
      this.isLoading = false;
    });
  }

  close() {
    this.bsModalRef.hide();
  }

  save() {

    if (this.formControl.invalid) {
      this.submitted = true;
      return;
    }

    this.isSaving = true;
    this.membershipApplicationAdminService.updateEditor(this.applicationId, this.formControl.value).subscribe({
      next: () => {
        this.isSaving = false;
        this.editorAsvzIdChanged.next(this.formControl.value);
        this.bsModalRef.hide();
      },
      error: () => {
        this.isSaving = false;
        this.toastrService.error(this.translocoService.translate('Msg.SaveError'));
      }
    });
  }

}

import { Component, OnInit } from '@angular/core';
import { HandHeldPerson } from '../models/hand-held-person.model';
import { HandHeldScannerService } from '../services/hand-held-scanner.service';
import { HandHeldScannerGetPicturePostModel } from '../models/hand-held-scanner-get-picture-post.model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { HandHeldScannerValidatePostModel } from '../models/hand-held-scanner-validate-post.model';
import { HandHeldScannerValidateResponseModel } from '../models/hand-held-scanner-validate-response.model';
import { HandHeldScannerBlacklistSaveModel } from '../models/hand-held-scanner-blacklist-save.model';
import { ConfirmationModalComponent } from 'src/app/shared/confirmation-modal/confirmation-modal.component';
import { TranslocoService } from '@ngneat/transloco';
import { ToastrService } from 'ngx-toastr';
import { HandHeldScannerCheckType } from '../models/hand-held-scanner-check-type.enum';
import { CheckOutValidationHistory } from '../models/check-out-validation-history.model';

@Component({
  selector: 'app-hand-held-person-detail-modal',
  templateUrl: './hand-held-person-detail-modal.component.html',
  styleUrls: ['./hand-held-person-detail-modal.component.scss']
})
export class HandHeldPersonDetailModalComponent implements OnInit {

  get isLoading(): boolean {
    return this.isRunningValidation || this.isLoadingImage;
  }

  checkType?: HandHeldScannerCheckType;
  checkTypeEnum = HandHeldScannerCheckType;

  isRunningValidation = true;
  isLoadingImage = false;

  person!: HandHeldPerson;
  accessSystemScannerId!: number;

  picture?: Blob;
  validationResult?: HandHeldScannerValidateResponseModel;
  bookedCourses: string[] = [];
  runValidation = true;
  validateModel?: HandHeldScannerValidatePostModel;

  checkOutValidationHistory?: CheckOutValidationHistory;
  picturePostModel?: HandHeldScannerGetPicturePostModel;

  constructor(
    private handHeldScannerService: HandHeldScannerService,
    private bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private translateService: TranslocoService,
    private toastrService: ToastrService) { }

  ngOnInit(): void {

    if (this.runValidation) {
      this.validate();
    } else {
      this.isRunningValidation = false;
    }

    this.loadPersonPicture();
  }

  loadPersonPicture(): void {
    if (this.picturePostModel == null) {
      this.toastrService.error(this.translateService.translate('Msg.Error'));
      this.closeModal();
      return;
    }

    this.isLoadingImage = true;

    this.handHeldScannerService.getPicture(this.picturePostModel!).subscribe({
      next: (res) => {
        this.picture = res;
        this.isLoadingImage = false;
      },
      error: () => {
        this.isLoadingImage = false;
      }
    });
  }

  closeModal() {
    this.bsModalRef.hide();
  }

  validate(): void {
    if (this.validateModel == null) {
      this.toastrService.error(this.translateService.translate('Msg.Error'));
      this.closeModal();
    }

    this.handHeldScannerService.validate(this.validateModel!).subscribe(
      {
        next: (res) => {
          this.validationResult = res;
          this.person = this.validationResult.person;
          this.bookedCourses = this.validationResult.imResult.bookedCourses;
          this.isRunningValidation = false;
        },
        error: () => {
          this.toastrService.error(this.translateService.translate('Msg.Error'));
          this.closeModal();
        }
      });
  }

  isAccessGranted(): boolean {
    // check if all results are ok
    if (this.validationResult != null) {
      return this.validationResult.imResult.accessGranted;
    } else {
      return this.checkOutValidationHistory?.accessGranted ?? false;
    }
  }

  private blockPerson() {
    const model: HandHeldScannerBlacklistSaveModel = {
      addressId: this.person.id,
      scannerId: this.accessSystemScannerId,
      reasonId: 1
    };

    this.handHeldScannerService.blockPerson(model).subscribe({
      next: () => {
        this.toastrService.success(this.translateService.translate('HandHeldScanner.PersonBlockSuccess'));
        this.closeModal();
      },
      error: () => {
        this.toastrService.error(this.translateService.translate('Msg.Error'));
      }
    });
  }

  private deletePhoto() {
    const addressId = this.person.id;

    this.handHeldScannerService.deletePhoto(addressId).subscribe({
      next: () => {
        this.toastrService.success(this.translateService.translate('HandHeldScanner.PhotoDeleteSuccess'));
        this.closeModal();
      },
      error: () => {
        this.toastrService.error(this.translateService.translate('Msg.Error'));
      }
    });
  }

  openBlockPersonConfirmationModal() {
    const initialState = {
      body: this.translateService.translate('HandHeldScanner.BlockPersonConfirmation')
    };

    const modal = this.modalService.show(ConfirmationModalComponent, { initialState });

    modal.content?.confirmed.subscribe(() => {
      this.blockPerson();
    });
  }

  openDeletePhotoConfirmationModal() {
    const initialState = {
      body: this.translateService.translate('HandHeldScanner.DeletePhotoConfirmation')
    };

    const modal = this.modalService.show(ConfirmationModalComponent, { initialState });

    modal.content?.confirmed.subscribe(() => {
      this.deletePhoto();
    });
  }


}

import { Directive, Input, Self } from "@angular/core";
import { NgControl } from "@angular/forms";

const controlStatusHost = {
    // "[class.is-valid]": "ngClassValid",
    "[class.is-invalid]": "ngClassInvalid"
};

// Selector is disabled to avoid side effects on existing forms
// @Directive({ selector: "[formControlName],[ngModel],[formControl]", host: controlStatusHost })
@Directive({ selector: "[appBsValidation]", host: controlStatusHost })
export class BSControlStatusDirective {

    _formShowValidation = false;

    public constructor(@Self() private control: NgControl) {
    }

    @Input() set formShowValidation(condition: boolean) {
      this._formShowValidation = condition;
    }

    get ngClassValid(): boolean {
        if (this.control.control == null) {
            return false;
        }
        return this.control.control.valid;
    }

    get ngClassInvalid(): boolean {
        if (this.control.control == null) {
            return false;
        }
        return (this.control.control.invalid && this._formShowValidation === true)
        || (this.control.control.invalid && this.control.control.touched);
    }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { TournamentService } from '../services/tournament.service';
import { TournamentDisplayService } from '../services/tournament-display.service';
import { TournamentTeamGamesModel } from '../models/tournament-team-games.model';
import { TournamentTeamGameModel } from '../models/tournament-team-game.model';
import { TournamentGameResultModalComponent } from '../tournament-game-result-modal/tournament-game-result-modal.component';
import { ToastrService } from 'ngx-toastr';
import { TranslocoService } from '@ngneat/transloco';
import * as moment from 'moment';
import { TournamentGameModel } from '../models/tournament-game.model';

@Component({
    selector: 'app-tournament-team-game',
    templateUrl: './tournament-team-game.component.html',
    styleUrls: ['./tournament-team-game.component.scss']
})
export class TournamentTeamGameComponent {

    @Input() allowCaptainEntry!: boolean;
    @Input() showResults!: boolean;
    @Input() tournamentPlanId!: string;
    @Input() teamSecret!: string;
    @Input() game!: TournamentTeamGameModel;

    @Output() resultChanges: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        public tournamentDisplayService: TournamentDisplayService,
        private modalService: BsModalService,
        private toastrService: ToastrService,
        private translocoService: TranslocoService,
    ) {

    }

    isDateTodayOrPast(date: Date): boolean {
        var checkDate = moment(date);
        if (checkDate.isSame(new Date(), "day")) return true;
        if (checkDate.diff(new Date()) < 0) return true;
        return false;
    }

    openResultModal(gameId: string) {
        const initialState: ModalOptions = {
            backdrop: 'static',
            keyboard: false,
            initialState: {
                gameId: gameId,
                tournamentPlanId: this.tournamentPlanId,
                teamSecret: this.teamSecret,
            },
        };

        const bsModalRef = this.modalService.show(TournamentGameResultModalComponent, initialState);
        bsModalRef.content?.confirmed.subscribe(x => {
            this.resultChanges.next(x);

            if (x) {
                this.toastrService.success(this.translocoService.translate('Tournaments.Teams.TeamGames.EnterResultSuccess'));
            } else {
                this.toastrService.error(this.translocoService.translate('Tournaments.Teams.TeamGames.EnterResultError'));
            }
        });
    }
}

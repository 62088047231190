import { Component, OnInit, Input } from '@angular/core';
import { Refund } from '../../models/refund.model';

@Component({
  selector: 'app-refund-table',
  templateUrl: './refund-table.component.html',
  styleUrls: ['./refund-table.component.scss']
})
export class RefundTableComponent implements OnInit {
  @Input() refundList!: Refund[] | null;
  @Input() isProcessing!: boolean;
  @Input() isLoading!: boolean;

  constructor() { }

  ngOnInit() {
  }

  toggleSelection(refund: Refund) {
    refund.isSelected = !refund.isSelected;
  }

}

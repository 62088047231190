import { TournamentLocationModel } from "./tournament-location.model";
import { TournamentSlotsSeriesEndTypeEnum } from "../enums/tournament-slots-series-end-type.enum";
import { TournamentSlotsSeriesTypeEnum } from "../enums/tournament-slots-series-type.enum";

export class TournamentSlotsCreateModel {
    public locations!: TournamentLocationModel[];
    public startDateTime!: Date;
    public length!: number;
    public breakLength?: number;
    public count!: number;

    // Series
    public seriesType?: TournamentSlotsSeriesTypeEnum;
    public seriesEndType?: TournamentSlotsSeriesEndTypeEnum;

    public dayInterval?: number;
    public weekInterval?: number;

    public dayIntervalWeekMon?: boolean;
    public dayIntervalWeekTue?: boolean;
    public dayIntervalWeekWed?: boolean;
    public dayIntervalWeekThu?: boolean;
    public dayIntervalWeekFri?: boolean;
    public dayIntervalWeekSat?: boolean;
    public dayIntervalWeekSun?: boolean;


    public endIntervalCount?: number;
    public endIntervalDate?: Date;
}

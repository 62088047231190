import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { BaseComponent } from '@premotec/ngx-essentials';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { environment } from 'src/environments/environment';
import { AccessSystemScannerListModel } from '../models/access-system-scanner-list.model';
import { HandHeldScannerGetPicturePostModel } from '../models/hand-held-scanner-get-picture-post.model';
import { HandHeldScannerValidatePostModel } from '../models/hand-held-scanner-validate-post.model';
import { HandHeldScannerValidateQrCodePost } from '../models/hand-held-scanner-validate-qr-code-post.model';
import { FormControl, Validators } from '@angular/forms';
import { HandHeldScannerCheckType } from '../models/hand-held-scanner-check-type.enum';
import { HandHeldPersonDetailModalComponent } from '../hand-held-person-detail-modal/hand-held-person-detail-modal.component';
import { take } from 'rxjs';

@Component({
    selector: 'app-hand-held-scanner-qr',
    templateUrl: './hand-held-scanner-qr.component.html',
    styleUrls: ['./hand-held-scanner-qr.component.scss']
})
export class HandHeldScannerQrComponent extends BaseComponent implements OnInit {

    @ViewChild('qrScanner') qrScanner!: ZXingScannerComponent;

    modalRef!: BsModalRef | undefined;

    isLoading = false;

    accessSystemScanners!: AccessSystemScannerListModel[];
    selectedAccessSystemScannerId: FormControl = new FormControl(null, Validators.required);

    cameras!: MediaDeviceInfo[];

    selectedCamera: MediaDeviceInfo | null = null;

    constructor(
        private modalService: BsModalService,
        private cd: ChangeDetectorRef,
        private route: ActivatedRoute,
        private translocoService: TranslocoService,
        private titleService: Title
    ) {
        super();
    }

    ngOnInit(): void {
        this.translocoService.selectTranslate('HandHeldScanner.HandHeldScanner').subscribe((x: any) => {
            this.titleService.setTitle(x);
        });

        this.route.queryParams.subscribe((params) => {
            // set scanner
            if (params['selectedAccessSystemScannerId'] != null) {
                this.selectedAccessSystemScannerId.patchValue(+params['selectedAccessSystemScannerId']);
            }
        });
    }

    camerasFoundHandler($event: any): void {
        // only set if the cameras aren't already loaded
        if (this.cameras === undefined || this.cameras === null) {
            if ($event.length > 0) {

                this.cameras = $event;

                // try to set back camera
                const backCamera = this.cameras.find(x => x.label.includes('back'));

                if (backCamera !== undefined && backCamera !== null) {
                    // set back camera
                    this.selectedCamera = backCamera;
                    this.cd.markForCheck();
                    this.qrScanner.device = backCamera;
                }
                else {
                    // set first camera
                    this.selectedCamera = this.cameras[0];
                    this.cd.markForCheck();
                }
            }
        }
        else {
            //set selected camera
            this.qrScanner.device = this.selectedCamera!;
        }
    }

    cameraChanged(): void {
        this.qrScanner.device = this.selectedCamera!;
        this.cd.markForCheck();
    }

    qrCodeScanSuccessHandler($event: any) {
        if (this.modalRef != null || this.isLoading) return;
        // decode base64 with atob
        this.qrCodeDetected(atob($event));
    }

    qrCodeDetected(qrCodeString: any): void {

        if (this.selectedAccessSystemScannerId.invalid) return;

        let qrCode = JSON.parse(qrCodeString);

        // validate model
        let validateModel = new HandHeldScannerValidatePostModel();

        validateModel.qrCode = new HandHeldScannerValidateQrCodePost();

        validateModel.qrCode.addressId = qrCode.addressId;
        validateModel.qrCode.signaturePayload = qrCode.signaturePayload;
        validateModel.qrCode.signature = qrCode.signature;
        validateModel.qrCode.dev = environment.production;;

        validateModel.checkType = HandHeldScannerCheckType.QrCode;
        validateModel.checkOnly = true;
        validateModel.scannerId = this.selectedAccessSystemScannerId.value;

        const modalOptions: ModalOptions<Partial<HandHeldPersonDetailModalComponent>> = {
            backdrop: 'static',
            keyboard: false,
            class: 'hand-held-scanner-modal',
            initialState: {
                accessSystemScannerId: this.selectedAccessSystemScannerId.value,
                validateModel: validateModel,
                checkType: HandHeldScannerCheckType.QrCode,
                picturePostModel: {addressId: qrCode.addressId}
            },
        };

        this.modalRef = this.modalService.show(HandHeldPersonDetailModalComponent, modalOptions);

        this.modalRef.onHidden?.pipe(take(1)).subscribe(() => {
            this.modalRef = undefined;
        })
    }

}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { map, tap } from "rxjs/operators";
import { HttpResponseErrorHandlerService } from "src/app/shared/services/http-response-error-handler.service";
import { environment } from "src/environments/environment";
import { AccessSystemScannerListModel } from "../models/access-system-scanner-list.model";
import { HandHeldScannerGetPicturePostModel } from "../models/hand-held-scanner-get-picture-post.model";
import { HandHeldScannerValidatePostModel } from "../models/hand-held-scanner-validate-post.model";
import { HandHeldScannerValidateResponseModel } from "../models/hand-held-scanner-validate-response.model";
import { HandHeldPerson } from "../models/hand-held-person.model";
import { HandHeldScannerBlacklistSaveModel } from "../models/hand-held-scanner-blacklist-save.model";
import { CheckOutValidationHistory } from "../models/check-out-validation-history.model";

@Injectable()
export class HandHeldScannerService {

    newValidationTriggered$: Subject<void> = new Subject<void>();

    constructor(private httpService: HttpClient,
        private httpResponseErrorHandler: HttpResponseErrorHandlerService) {
    }

    get newValidationTriggered(): Observable<void> {
        return this.newValidationTriggered$.asObservable();
    }

    validate(handHeldScannerValidatePostModel: HandHeldScannerValidatePostModel): Observable<HandHeldScannerValidateResponseModel> {
        return this.httpService.post(`${environment.api}/HandHeldScanner/Validate`, handHeldScannerValidatePostModel)
            .pipe(
                map((result: any): HandHeldScannerValidateResponseModel => {
                    // transform messages
                    if (result.qrCodeResult !== undefined && result.qrCodeResult !== null) {
                        if (result.qrCodeResult.message != null && result.qrCodeResult.message !== undefined) {
                            result.qrCodeResult.message = result.qrCodeResult.message.replace(new RegExp('\\\\n', 'g'), '&nbsp;');
                        }
                    }

                    if (result.osResult !== undefined && result.osResult !== null) {
                        if (result.osResult.message != null && result.osResult.message !== undefined) {
                            result.osResult.message = result.osResult.message.replace(new RegExp('\\\\n', 'g'), '&nbsp;');
                        }
                    }

                    return result;
                }),
                tap(() => this.newValidationTriggered$.next()
            )
            );
    }

    getPicture(handHeldScannerGetPicturePostModel: HandHeldScannerGetPicturePostModel): Observable<Blob> {
        return this.httpService.post(`${environment.api}/HandHeldScanner/Picture`, handHeldScannerGetPicturePostModel)
            .pipe(
                map((result: any): Blob => result)
            );
    }

    getAccessSystemScanners(): Observable<AccessSystemScannerListModel[]> {
        return this.httpService.get(`${environment.api}/HandHeldScanner/AccessSystemScanner`)
            .pipe(
                map((result: any): AccessSystemScannerListModel[] => result)
            );
    }

    getPeopleByFilter(filter: string): Observable<HandHeldPerson[]> {
        return this.httpService.get<HandHeldPerson[]>(`${environment.api}/HandHeldScanner/People?filter=${filter}`);
    }

    getRegistrations(addressId: number): Observable<any[]> {
        return this.httpService.get<any[]>(`${environment.api}/HandHeldScanner/Registrations?addressId=${addressId}`);
    }

    blockPerson(model: HandHeldScannerBlacklistSaveModel): Observable<void> {
        return this.httpService.post<void>(`${environment.api}/HandHeldScanner/BlockPerson`, model);
    }

    getCheckoutValidationHistory(imFacilityId: number): Observable<CheckOutValidationHistory[]> {
        return this.httpService.get<CheckOutValidationHistory[]>(`${environment.api}/HandHeldScanner/history?imFacilityId=${imFacilityId}`);
    }

    deletePhoto(addressId: number): Observable<void> {
        return this.httpService.delete<void>(`${environment.api}/HandHeldScanner/address/${addressId}/picture`);
    }
}
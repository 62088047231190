import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MembershipExtensionTypes, Membership, MembershipExtension, ConfirmMembership } from './models';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { MembershipState } from './membership-state.enum';
import * as moment from 'moment';
import { NoMembershipStartAfterNMonths } from './models/no-membership-start-after-n-months.model';
import { PersonWithMembership } from './models/person-with-membership.model';
import { environment } from 'src/environments/environment';
import { DiscountGiftCardPut } from '../shared/models/discount-giftcard/discount-giftcard-put.model';
import { LastSchoolModel } from './models/last-school.model';
import { LastSchoolUpdateModel } from './models/last-school-update.model';
import { MembershipsHealthInsuranceResponseModel } from './models/memberships-health-insurance-response.model';
import { MembershipsHealthInsuranceRequestModel } from './models/memberships-health-insurance-request.model';
@Injectable({
  providedIn: 'root',
})
export class MembershipService {

  constructor(private http: HttpClient) { }

  getMyMemberships(): Observable<PersonWithMembership> {
    return this.http.get(`${environment.api}/memberships/my`).pipe(
      map((object: any): PersonWithMembership => object)
    );
  }

  getMembershipExtensions(): Observable<MembershipExtensionTypes> {
    return this.http.get(`${environment.api}/memberships/my/Extensions`).pipe(
      map((object: any): MembershipExtensionTypes => object.data)
    );
  }

  getMembershipExtensionsWithValidFromDate(validFrom: string): Observable<MembershipExtensionTypes> {
    return this.http.get(`${environment.api}/memberships/my/Extensions/${validFrom}`).pipe(
      map((object: any): MembershipExtensionTypes => object.data)
    );
  }

  getMembershipExtension(membershipExtensionId: number): Observable<MembershipExtension> {
    return this.http.get(`${environment.api}/MembershipExtensions/${membershipExtensionId}`).pipe(
      map((object: any): MembershipExtension => object)
    );
  }

  getAvailableLastSchools(): Observable<LastSchoolModel[]> {
    return this.http.get<LastSchoolModel[]>(`${environment.api}/MembershipExtensions/AvailableLastSchools`)
  }

  setLastSchool(membershipExtensionId: number, updateModel: LastSchoolUpdateModel): Observable<void> {
    return this.http.post<void>(`${environment.api}/MembershipExtensions/${membershipExtensionId}/LastSchool`, updateModel)
  }

  getNoMembershipStartAfterNMonths(): Observable<NoMembershipStartAfterNMonths> {
    return this.http.get(`${environment.api}/MembershipExtensions/NoMembershipStartAfterNMonths`).pipe(
      map((object: any): NoMembershipStartAfterNMonths => object)
    );
  }


  modifyNewMembership(membershipExtensionId: number, newValidFromDate: string): Observable<MembershipExtension> {
    return this.http.put(`${environment.api}/MembershipExtensions/${membershipExtensionId}/ValidFrom/${newValidFromDate}`, {}).pipe(
      map((object: any): MembershipExtension => object.data)
    );
  }

  // createMembership(membershipId: number): Observable<MembershipExtension> {
  //   return this.http.post(`${environment.api}/memberships/${membershipId}/create`, {}).pipe(
  //     map((object: any): MembershipExtension => object.data)
  //   );
  // }

  createMembershipWithValidFrom(membershipId: number, validFrom: string): Observable<MembershipExtension> {
    return this.http.post(`${environment.api}/memberships/${membershipId}/create/${validFrom}`, {}).pipe(
      map((object: any): MembershipExtension => object.data)
    );
  }

  createRetiredEmployeeMembershipWithValidFrom(validFrom: string): Observable<MembershipExtension> {
    return this.http.post(`${environment.api}/memberships/retiredEmployee/create/${validFrom}`, {}).pipe(
      map((object: any): MembershipExtension => object.data)
    );
  }

  deleteTemporaryMembership(membershipId: number): Observable<any> {
    return this.http.delete(`${environment.api}/MembershipExtensions/${membershipId}`, {});
  }

  confirmExtension(membershipId: number, confirmMembership: ConfirmMembership): Observable<MembershipExtension> {
    return this.http.put(`${environment.api}/MembershipExtensions/${membershipId}/ConfirmExtension`, confirmMembership).pipe(
      map((object: any): MembershipExtension => object.data)
    );
  }

  startPayment(personMembershipId: number, discountGiftCardPut: DiscountGiftCardPut): Observable<MembershipExtension> {
    return this.http.put(`${environment.api}/MembershipExtensions/${personMembershipId}/StartPayment`, discountGiftCardPut).pipe(
      map((object: any): MembershipExtension => object.data)
    );
  }

  canMembershipBeDeleted(status: number): boolean {
    return status === MembershipState.Temporary;
  }

  isStatusInProcess(status: MembershipState): boolean {
    if (this.isWizardSupportedStatus(status)
      || (status === MembershipState.ProcessPayment)) {
      return true;
    }

    return false;
  }

  isWizardSupportedStatus(status: number): any {
    if ((status === MembershipState.New)
      || (status === MembershipState.UserData)
      || (status === MembershipState.Temporary)) {
      return true;
    }

    return false;
  }

  getActiveMemberships(memberships: Membership[]): Membership[] {
    return memberships.filter(membership => {
      return membership.status === MembershipState.Completed
        && moment().isSameOrBefore(membership.validUntil)
        && moment().isSameOrAfter(membership.validFrom);
    });
  }

  getMembershipExtensionPicture(personMembershipId: number): Observable<Blob> {
    return this.http.get(`${environment.api}/MembershipExtensions/${personMembershipId}/Picture`)
      .pipe(
        map((picture: any): Blob => picture)
      )
  }

  uploadMembershipExtensionPicture(file: any, personMembershipId: number): Observable<any> {
    return this.http.post(`${environment.api}/MembershipExtensions/${personMembershipId}/Picture`, file).pipe(
      map((res: any) => res)
    );
  }


  // HealthInsuranceRequest
  requestHealtInsuranceSummary(personMembershipId: number, requestModel: MembershipsHealthInsuranceRequestModel): Observable<MembershipsHealthInsuranceResponseModel> {
    return this.http.post<MembershipsHealthInsuranceResponseModel>(`${environment.api}/Memberships/${personMembershipId}/HealthInsuranceSummary/Request`, requestModel);
  }
}

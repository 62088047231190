import { Component, OnInit, ViewEncapsulation, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormGroup, FormControl, FormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-tri-state',
  templateUrl: './tri-state.component.html',
  styleUrls: ['./tri-state.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TriStateComponent),
      multi: true
    }
  ]
})
export class TriStateComponent implements OnInit, ControlValueAccessor {

  @Input() required!: boolean;
  @Input() readOnly!: boolean;

  @Input() yesPrice!: number;
  @Input() noPrice!: number;
  @Input() undefinedPrice!: number;

  triStateControl: FormControl = new FormControl('');
  private _value : any;

  @Input() set value(value) {
    this._value = value;
    this.propagateChange(this._value);
  }
  get value() {
    return this._value;
  }

  constructor(private formBuilder: FormBuilder) {

  }

  ngOnInit() {
    this.required = true;
  }

  ngOnChange() {
  }

  updateValue(event : Event) {

    const value = (event.target as HTMLInputElement).value;
    this.writeValue(value);
  }


  propagateChange = (_: any) => { };
  onTouched: any = () => { };

  writeValue(value: any): void {
    this.value = value;
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {

  }

  isSelected(value: number) {
    if (this.value == null || typeof this.value != 'string') {
      return false;
    }
    if (value === 0) {
      return this.value.includes('false');
    }
    if (value === 1) {
      return this.value.includes('true');
    }

    return false;
  }

}

<div *ngIf="maintenances">
  <div class="card">
    <div class="card-header">
      <span><a class="btn btn-default btn-sm" [routerLink]="['/office/maintenance/new']"><i
            class="fa fa-plus"></i></a></span>
      <span class="badge bg-secondary float-end">{{getMaintenancesLength()}}</span>
    </div>

    <div class="card-body">
      <label style="cursor: pointer;"><input type="checkbox" [(ngModel)]="showInactive" />&nbsp;&nbsp;{{'ShowInactives'
        | transloco}}</label>
    </div>

    <div class="list-group">
      <span *ngFor="let maintenance of maintenances | orderBy: '-publicationDate' ">
        <a class="list-group-item list-group-item-action" *ngIf="maintenance.active || showInactive"
          [routerLink]="['/office/maintenance/'+maintenance.id]" [routerLinkActive]="'list-group-item-info'">
          {{'PublicationOn' | transloco}} {{ maintenance.publicationDate | date : 'dd.MM.yyyy'}}


          <div class="badge bg-warning float-end ng-binding ng-scope" *ngIf="maintenance.active === false">
            {{'Inactive' | transloco}}</div>
        </a>
      </span>
    </div>
  </div>
</div>
import {Component, Input, OnInit} from '@angular/core';
import {Lesson} from '../../models/lesson.model';
import { WebRegistrationType } from '../../enums/web-registration-type.enum';

@Component({
  selector: 'app-lesson-properties-display',
  templateUrl: './lesson-properties-display.component.html',
  styleUrls: ['./lesson-properties-display.component.scss']
})
export class LessonPropertiesDisplayComponent implements OnInit {
  @Input() lesson!: Lesson;

  canShowSeatingInfo = true;

  constructor() { }

  ngOnInit() {
    this.lesson.instructors.sort(this.sortInstructorsByName);
    this.canShowSeatingInfo = (this.lesson.webRegistrationType !== WebRegistrationType.NoRegistration);
  }

  sortInstructorsByName(a: any, b: any) {
    if (a.name < b.name) { return -1 };
    if (a.name > b.name) { return 1 };
    return 0;
  }

  getRemainingMinimumParticipants(): number {
    return this.lesson.participantsMin - this.lesson.participantCount;
  }

}

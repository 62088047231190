import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { TournamentTeamDataModel } from '../models/tournament-team-data.model';
import { TournamentService } from '../services/tournament.service';

@Component({
  selector: 'app-tournament-team-data',
  templateUrl: './tournament-team-data.component.html',
  styleUrls: ['./tournament-team-data.component.scss']
})
export class TournamentTeamDataComponent implements OnInit {

  tournamentPlanId!: string;
  teamId!: string;
  secret!: string;
  teamDataModel!: TournamentTeamDataModel;

  showNotFound = false;

  constructor(
    private modalService: BsModalService,
    private tournamentService: TournamentService,
    private activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    // get params
    this.activatedRoute.params.subscribe((params: Params) => {

      this.tournamentPlanId = params['tournamentPlanId'];
      this.teamId = params['teamId'];
      this.secret = params['secret'];

      // get secret
      this.activatedRoute.queryParams.subscribe((params: Params) => {
        this.secret = params['secret'];
        this.loadTeamData();
      });
    });
  }

  loadTeamData() {
    this.tournamentService.getTournamentTeamData(this.tournamentPlanId, this.teamId, this.secret).subscribe({
      next: (teamDataModel: TournamentTeamDataModel) => {
        this.teamDataModel = teamDataModel;
      },
      error: (error) => {
        if (error.status === 404) {
          this.showNotFound = true;
        }
      }
    });
  }

  displayPreferences(): boolean {
    if (this.teamDataModel.monPreference) return true;
    if (this.teamDataModel.tuePreference) return true;
    if (this.teamDataModel.wedPreference) return true;
    if (this.teamDataModel.thuPreference) return true;
    if (this.teamDataModel.friPreference) return true;
    if (this.teamDataModel.satPreference) return true;
    if (this.teamDataModel.sunPreference) return true;

    return false;
  }
}

<div class="modal-header text-light">
    <h3>{{'Tournaments.PlanningData.CopySlots' | transloco}}</h3>
</div>

<div class="modal-body">

    <div class="row">
        <div class="col-6">
            <h4>{{'Tournaments.PlanningData.Location.Title'| transloco}}</h4>

            <form *ngIf="tournamentSlotCopyForm" [formGroup]="tournamentSlotCopyForm">
                <div class="row">
                    <div class="col-12 mb-3">
                        <label for="facilityId" class="form-label">
                            {{'Tournaments.PlanningData.Location.Facility' | transloco}} *
                        </label>
                        <div>
                            <ng-select formControlName="facilityId" id="facilityId" (change)="onFacilityChange()"
                                [disabled]="checked" [readonly]="!tournamentFacilities || checked"
                                [ngClass]="{'border-danger':  !tournamentSlotCopyForm.controls['facilityId'].valid && formSubmitted}">
                                <ng-option *ngFor="let facility of tournamentFacilities" [value]="facility.id">
                                    {{facility.name}} ({{facility.rooms.length}})
                                </ng-option>
                            </ng-select>
                        </div>
                    </div>

                    <div class="col-12 mb-3">
                        <label for="roomId" class="form-label">
                            {{'Tournaments.PlanningData.Location.Room' | transloco}} *
                        </label>
                        <div>
                            <ng-select formControlName="roomId" id="roomId"
                                [readonly]="!tournamentRooms || tournamentRooms.length <= 1 || checked"
                                [ngClass]="{'border-danger':  !tournamentSlotCopyForm.controls['roomId'].valid && formSubmitted}">
                                <ng-option *ngFor="let room of tournamentRooms" [value]="room.id">
                                    {{room.name}}
                                </ng-option>
                            </ng-select>
                        </div>
                    </div>

                    <div class="col-12 mb-3">
                        <label for="fieldId" class="form-label">
                            {{'Tournaments.PlanningData.Location.Field' | transloco}} *
                        </label>
                        <div>
                            <ng-select formControlName="fieldId" id="fieldId" [readonly]="checked"
                                [ngClass]="{'border-danger':  !tournamentSlotCopyForm.controls['fieldId'].valid && formSubmitted}">
                                <ng-option [value]="0">
                                    {{tournamentdisplayService.getFieldName(0)}}
                                </ng-option>

                                <ng-option *ngFor="let item of [].constructor(12); let i = index" [value]="i + 1">
                                    {{tournamentdisplayService.getFieldName(i+1)}}
                                </ng-option>

                            </ng-select>
                        </div>
                    </div>

                    <div class="col-12 mb-3">
                        <label for="date" class="form-label">
                            {{'Tournaments.PlanningData.ReferenceDay.Date' | transloco}} *
                        </label>
                        <div *ngIf="!checked">
                            <pmt-datepicker [id]="'date'" formControlName="date" [disabled]="checked">
                            </pmt-datepicker>
                        </div>
                        <div *ngIf="checked">
                            <div class="input-group flex-nowrap">
                                <input type="text" class="form-control border border-end-0"
                                    [value]="tournamentSlotCopyForm.controls['date'].value | date" disabled>
                                <button type="button" class="btn btn-hover border border-start-0" [disabled]="true">
                                    <i class="fas fa-calendar text-secondary"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div class="alert alert-danger m-0 mb-3"
                *ngIf="tournamentSlotCopyForm && !tournamentSlotCopyForm.valid && formSubmitted">
                <p class="m-0" [innerHTML]="'FillRequired' | transloco">
                </p>
            </div>
        </div>
        <div class="col-6">
            <h4>{{'Tournaments.PlanningData.Slots' | transloco}}</h4>
            <div *ngIf="todaySlots" style="max-height:25vh; overflow-x: hidden; overflow-y: auto;">
                <div *ngFor="let slot of todaySlots">
                    <span *ngIf="slot.validationType ==TournamentSlotValidationTypeEnum.Unknown" class="text-secondary">
                        <i class="fa-solid fa-circle-question"
                            [tooltip]="'Tournaments.PlanningData.Status.Unknown' | transloco"></i>
                    </span>
                    <span *ngIf="slot.validationType ==TournamentSlotValidationTypeEnum.Valid" class="text-success"><i
                            [tooltip]="'Tournaments.PlanningData.Status.Valid' | transloco"
                            class="fa-solid fa-circle-check"></i></span>
                    <span *ngIf="slot.validationType ==TournamentSlotValidationTypeEnum.Overlapping"
                        class="text-danger"><i class="fa-solid fa-circle-exclamation"
                            [tooltip]="'Tournaments.PlanningData.Status.Overlapping' | transloco"></i></span>
                    <span *ngIf="slot.validationType ==TournamentSlotValidationTypeEnum.OverlappingClosing"
                        class="text-danger"><i class="fa-solid fa-circle-xmark"
                            [tooltip]="'Tournaments.PlanningData.Status.OverlappingClosing' | transloco"></i></span>
                    {{slot.from| date : 'HH:mm'}} - {{slot.to| date : 'HH:mm'}}
                </div>
            </div>
            <h4>{{'Tournaments.PlanningData.Legend' | transloco}}</h4>
            <div class="text-secondary">
                <i class="fa-solid fa-circle-question"></i> {{'Tournaments.PlanningData.Status.Unknown' | transloco}}
            </div>
            <div class="text-success">
                <i class="fa-solid fa-circle-check"></i> {{'Tournaments.PlanningData.Status.Valid' | transloco}}
            </div>
            <div class="text-danger">
                <i class="fa-solid fa-circle-exclamation"></i> {{'Tournaments.PlanningData.Status.Overlapping' |
                transloco}}
            </div>
            <div class="text-danger">
                <i class="fa-solid fa-circle-xmark"></i> {{'Tournaments.PlanningData.Status.OverlappingClosing' |
                transloco}}
            </div>
        </div>
    </div>


</div>

<div class="modal-footer text-light">
    <div class="w-100 d-flex">
        <button type="button" class="btn btn-link" (click)="back()" *ngIf="checked" [disabled]="processing"  [disabled]="processing">
            {{'Back' | transloco}}
        </button>

        <div class="flex-grow-1"> </div>

        <button type="button" class="btn btn-default ms-2" (click)="decline()" [disabled]="processing"  [disabled]="processing">
            {{'Cancel' | transloco}}
        </button>
        <button *ngIf="!checked" type="submit" class="btn btn-success ms-2" (click)="check()" [disabled]="processing"  [disabled]="processing">
            {{'Check' | transloco}}
        </button>
        <button *ngIf="checked" type="submit" class="btn btn-primary ms-2" (click)="copy()" [disabled]="processing"  [disabled]="processing">
            {{'Copy' | transloco}}
        </button>
    </div>
</div>
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { User, UserDetail, UserLogin, Role, UserNamePutModel } from './models';
import { UserPasswordPut } from './models/user-password-put.model';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class UsersService {

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) { }

  getUsers(searchString?: string): Observable<User[]> {
    return this.http.get<User[]>(`${environment.identity.api}/ApplicationUsers?search=${searchString}`)
      .pipe(map((res: any) => res.data));
  };

  getRoles(): Observable<Role[]> {
    return this.http.get<Role[]>(`${environment.identity.api}/ApplicationUsers/roles`)
    .pipe(map((res: any) => res.data));
  };

  getUserById(id: string): Observable<UserDetail> {
    return this.http.get<UserDetail>(`${environment.identity.api}/ApplicationUsers/${id}`)
    .pipe(map((res: any) => res.data));
  };

  getUserLoginsByUserId(id: string): Observable<UserLogin[]> {
    return this.http.get<UserLogin[]>(`${environment.identity.api}/ApplicationUsers/${id}/logins`)
    .pipe(map((res: any) => res.data));
  };

  updateUserName(id: string, userNamePutModel: UserNamePutModel) {
    return this.http.put(`${environment.identity.api}/ApplicationUsers/${id}/UserInfo`, userNamePutModel)
    .pipe(map((res: any) => res.data));
  };

  deleteUser(id: string): Observable<any> {
    return this.http.delete(`${environment.identity.api}/ApplicationUsers/${id}`);
  };

  deleteUserLoginLog(id: string, userLogin: UserLogin): Observable<any> {
    const providerKey = encodeURIComponent(userLogin.providerKey);
    return this.http.delete(`${environment.identity.api}/ApplicationUsers/${id}/logins?LoginProvider=${userLogin.loginProvider}&ProviderKey=${providerKey}`)
    .pipe(map((res: any) => res.data));
  };

  resetLockoutDate(id: string): Observable<UserDetail> {
    return this.http.put<UserDetail>(`${environment.identity.api}/ApplicationUsers/${id}/ResetLockout`, {})
    .pipe(map((res: any) => res.data));
  };

  verifyUserEmail(id: string): Observable<UserDetail> {
    return this.http.put<UserDetail>(`${environment.identity.api}/ApplicationUsers/${id}/VerifyUserEmail`, {})
    .pipe(map((res: any) => res.data));
  };

  setUserPassword(id: string, password: UserPasswordPut): Observable<UserDetail> {
    return this.http.put<UserDetail>(`${environment.identity.api}/ApplicationUsers/${id}/setPassword`, password)
    .pipe(map((res: any) => res.data));
  };

  sendPasswordResetRequestMail(id: string): Observable<any> {
    return this.http.put(`${environment.identity.api}/ApplicationUsers/${id}/sendPasswordResetRequestMail`, {})
    .pipe(map((res: any) => res.data));
  };
}

<div *ngIf="dataLoaded">
  <div class="container" style="min-height: 500px;">

    <div class="page-header mb-5">
      <h1>{{event.sportName}}: {{event.title}}</h1>
    </div>

    <app-pass-photo-required-msg [account]="account" [messageToken]="'PassportPhotoNeededDigitalCheckIn'"
      [checkProperty]="'hasPrintCard'"></app-pass-photo-required-msg>

    <app-event-registration-button [event]="event" [missingSkills]="missingSkills" [account]="account"
      (eventNeedsReload)="loadEvent($event)" (liveStreamShow)="showLiveStreamTab()"></app-event-registration-button>


    <ul class="nav nav-tabs my-3">
      <li style="cursor: pointer;" (click)="activeTab = 'offer'" class="nav-item nav-pills-asvz" role="presentation"><a
          [ngClass]="{'active': activeTab == 'offer'}" class="nav-link">{{'Sportoffer' | transloco }}</a></li>
      <li style="cursor: pointer;" (click)="activeTab = 'registration'" class="nav-item nav-pills-asvz"
        role="presentation"><a [ngClass]="{'active': activeTab == 'registration'}" class="nav-link">{{'RegistrationInfo'
          | transloco }}</a></li>
      <li style="cursor: pointer;" (click)="showLiveStreamTab()" class="nav-item nav-pills-asvz" role="presentation"
        *ngIf="event.isLiveStream && userIsLoggedIn && account.hasActiveMembership && event.status != eventState.Canceled">
        <a [ngClass]="{'active': activeTab == 'livestream'}" class="nav-link">{{'LiveStream.LiveStream' | transloco
          }}</a>
      </li>
      <li style="cursor: pointer;" (click)="activeTab = 'tlTool'; openTlTool()" class="nav-item nav-pills-asvz"
        role="presentation"><a [ngClass]="{'active': activeTab == 'tlTool'}" class="nav-link"><img
            src="assets/img/tl-logo.svg" style="height: 18px;" /> {{'TlToolTab' | transloco}}</a></li>
    </ul>

    <ng-container *ngIf=" activeTab == 'offer'">
      <div class="tab-pane active" id="eventDetails">
        <div class="row">
          <div class="col-sm-4">
            <div class="card">
              <div class="card-header">{{'Sportoffer' | transloco}}</div>
              <div class="card-body event-properties">
                <app-event-properties-display *ngIf="event" [event]="event"></app-event-properties-display>
              </div>
            </div>
          </div>
          <div class="col-sm-8">
            <!-- Prerequisites -->
            <div class="card mb-3" *ngIf='missingSkills?.length || event.requiredSkills?.length'>
              <div class="card-header">{{'Prerequisites' | transloco}}</div>
              <div class="card-body">
                <app-prerequisites-view [requiredSkills]='missingSkills' [eventSport]="event.sportName"
                  [eventTitle]="event.title" [eventStartDate]="event.starts" [eventEndDate]="event.ends"
                  [registrationStartDate]="event.registrationFrom">
                </app-prerequisites-view>
              </div>
            </div>

            <div class="card">
              <div class="card-header">{{'Details' | transloco}}</div>
              <div class="card-body">
                <app-details-view [details]="event.details"></app-details-view>
              </div>
              <div class="card-footer">
                <app-details-view [details]="event.detailsAddition"></app-details-view>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf=" activeTab == 'registration'">
      <div class="alert-primary alert" role="alert">{{'OfferImmediatePaymentInfo' | transloco}}</div>
      <p>
        <strong>{{'NeededInformationText' | transloco}}</strong>
      </p>
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              {{'NeededInformation' | transloco}}
            </div>
            <ul class="list-group">
              <li *ngFor="let item of event.registrationDetails" class="list-group-item">
                <div class="row">
                  <div class="col-md-6">
                    <app-details-view [details]="getRegistrationDetailDescription(item)"></app-details-view>
                  </div>
                  <div class="col-md-6">
                    <span>{{ getRegistrationDetailInfoLabel(item) | transloco }}</span>
                    <span>
                      <ul *ngIf="item.type == 7">
                        <li *ngFor="let option of item.dropdownOptions">
                          {{option.option}} <span *ngIf="option.price != null && option.price > 0">(CHF {{option.price
                            | number : '1.2-2'}})</span>
                        </li>
                      </ul>
                      <ul *ngIf="item.type == 5">
                        <li>
                          {{'EventRegistrationResponse.Type_DetailYes' | transloco}} <span
                            *ngIf="item.yesPrice != null && item.yesPrice > 0">(CHF {{item.yesPrice | number :
                            '1.2-2'}})</span>
                        </li>
                        <li *ngIf="!item.isRequired">
                          {{'EventRegistrationResponse.Type_DetailNo' | transloco}} <span
                            *ngIf="item.noPrice != null && item.noPrice > 0">(CHF {{item.noPrice | number :
                            '1.2-2'}})</span>
                        </li>
                      </ul>
                      <ul *ngIf="item.type == 6">
                        <li>
                          {{'EventRegistrationResponse.Type_DetailYes' | transloco}} <span
                            *ngIf="item.yesPrice != null && item.yesPrice > 0">(CHF {{item.yesPrice | number :
                            '1.2-2'}})</span>
                        </li>
                        <li *ngIf="!item.isRequired">
                          {{'EventRegistrationResponse.Type_DetailNo' | transloco}} <span
                            *ngIf="item.noPrice != null && item.noPrice > 0">(CHF {{item.noPrice | number :
                            '1.2-2'}})</span>
                        </li>
                        <li *ngIf="!item.isRequired">
                          {{'EventRegistrationResponse.Type_DetailUndefined' | transloco}} <span
                            *ngIf="item.undefinedPrice != null && item.undefinedPrice > 0">(CHF {{item.undefinedPrice |
                            number :
                            '1.2-2'}})</span>
                        </li>
                      </ul>
                    </span>
                  </div>
                </div>
              </li>
            </ul>
            <div *ngIf="hasRequiredRegistrationDetail()" class="card-footer text-muted">
              <i>{{'RequiredDetailsMsg' | transloco }}</i>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf=" activeTab == 'livestream'">
      <div class="tab-content">
        <div class="tab-pane active" id="liveStream" *ngIf="liveStream">
          <app-show-live-stream [liveStream]="liveStream" [event]="event"></app-show-live-stream>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf=" activeTab == 'tlTool'">
      <div class="well well-sm">
        <div>{{'RedirectToTlToolMsg' | transloco}}</div>
      </div>
    </ng-container>
  </div>
</div>
<app-spinner *ngIf="!dataLoaded" [margin]="'100px'"></app-spinner>

export enum EnrollmentStatus {
    None = 0,
    WaitList = 1,
    Temporary = 2,
    ProcessPayment = 3,
    Completed = 4,
    AdditionalPayment = 5,
    Canceled = 6,
    New = 90,
    Expired = 91,
    Error = 92,
    Deleted = 93,
}

import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { OccupancyPlanView } from '../occupancy-plan-view.model';
import { OccupancyPlanViewAdminService } from '../occupancy-plan-view-admin.service';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-occupancy-plan-views-overview',
  templateUrl: './occupancy-plan-views-overview.component.html',
  styleUrls: ['./occupancy-plan-views-overview.component.scss']
})
export class OccupancyPlanViewsOverviewComponent implements OnInit {

  occupancyPlanView!: OccupancyPlanView;
  occupancyPlanViews!: Array<OccupancyPlanView>;

  constructor(private occupancyPlanViewService: OccupancyPlanViewAdminService,
              private activatedRoute: ActivatedRoute,
              private translocoService: TranslocoService,
              private titleService: Title) {
  }

  ngOnInit() {
    this.translocoService.selectTranslate('Navigation.Office.OccupancyPlanViews').subscribe(x => {
      this.titleService.setTitle(x);
    });

    this.loadOccupancyPlanViews();

    OccupancyPlanViewAdminService.occupancyPlanViewChangedEvent.subscribe(() => {
      this.loadOccupancyPlanViews();
    });
  }

  loadOccupancyPlanViews() {
    this.occupancyPlanViewService.getAll().subscribe((occupancyPlanViews: Array<OccupancyPlanView>) => {
      this.occupancyPlanViews = occupancyPlanViews;
    });
  }
}

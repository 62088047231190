import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FacilityRoom } from '../../facility-room.model';
import { OccupancyPlanViewRoom } from '../../occupancy-plan-view-room.model';
import { OccupancyPlanViewAdminService } from '../../occupancy-plan-view-admin.service';
import { OccupancyPlanView } from '../../occupancy-plan-view.model';
import { TranslocoService } from '@ngneat/transloco';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ConfirmationModalComponent } from 'src/app/shared/confirmation-modal/confirmation-modal.component';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'app-occupancy-plan-view-detail',
  templateUrl: './occupancy-plan-view-detail.component.html',
  styleUrls: ['./occupancy-plan-view-detail.component.scss']
})
export class OccupancyPlanViewDetailComponent implements OnInit {

  occupancyPlanView!: OccupancyPlanView | null;
  occupancyPlanViewRooms!: Array<OccupancyPlanViewRoom>;
  facilityRooms!: Array<FacilityRoom>
  ngSelectItems: Array<any> = [];

  selectedFacilityRoom!: FacilityRoom | undefined | null;

  occupancyPlanViewForm!: FormGroup;

  test: any;

  @ViewChild(NgSelectComponent) selectRoom!: NgSelectComponent;

  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private occupancyPlanViewService: OccupancyPlanViewAdminService,
    private translocoService: TranslocoService,
    private toastrService: ToastrService,
    private router: Router,
    private modalService: BsModalService
  ) { }

  ngOnInit() {
    this.loadFacilityRooms();

    this.activatedRoute.params.subscribe((params: Params) => {
      this.initFormGroup();
      const occupancyPlanViewId: string = params['occupancyPlanViewId'];

      if (!this.isNew(occupancyPlanViewId)) {
        this.occupancyPlanViewService.getById(occupancyPlanViewId).subscribe((occupancyPlanView: OccupancyPlanView) => {
          this.occupancyPlanView = occupancyPlanView;

          this.occupancyPlanViewForm.patchValue({
            'id': this.occupancyPlanView.id,
            'name': this.occupancyPlanView.name,
          });

          this.loadOccupancyPlanViewRooms(occupancyPlanViewId);
        });

      } else {
        this.occupancyPlanViewForm.reset();
        this.occupancyPlanView = null;

        this.occupancyPlanViewForm.patchValue({
          'id': null,
          'name': this.translocoService.translate('Office.OccupancyPlanView.OccupancyPlanView'),
        });
      }
    });
  }

  private loadFacilityRooms(): void {
    this.occupancyPlanViewService.getAllFacilityRooms().subscribe((facilityRooms: Array<FacilityRoom>) => {
      this.facilityRooms = facilityRooms.sort((a, b) => a.name.localeCompare(b.name)).sort((a, b) => a.facilityName.localeCompare(b.facilityName));
    });
  }

  private loadOccupancyPlanViewRooms(occupancyPlanViewId: string): void {
    this.occupancyPlanViewService.getOccupancyPlanViewRooms(occupancyPlanViewId).subscribe((occupancyPlanViewRooms: Array<OccupancyPlanViewRoom>) => {
      this.occupancyPlanViewRooms = occupancyPlanViewRooms;
      const newItems: Array<any> = [];

      // add to ng-select if not already aassigned
      if (this.facilityRooms !== null && this.facilityRooms !== undefined) {
        this.facilityRooms.forEach((facilityRoom: FacilityRoom) => {

          if (!this.occupancyPlanViewRooms.find(x => x.facilityRoomId === facilityRoom.id)) {
            newItems.push({
              id: facilityRoom.id,
              text: facilityRoom.name + ' | ' + facilityRoom.facilityName
            });
          }
        });
        this.ngSelectItems = newItems;
      }
    });
  }

  public save(occupancyPlanViewForm: FormGroup): void {
    // If its updating an existing maintenance
    if (this.occupancyPlanViewForm.value.id) {
      this.occupancyPlanViewService.update(occupancyPlanViewForm.value).subscribe((results) => {
        OccupancyPlanViewAdminService.occupancyPlanViewChangedEvent.emit(results);
        this.toastrService.success(this.translocoService.translate('Office.OccupancyPlanView.SaveSuccess'));
      }, (e) => {
        this.toastrService.error(this.translocoService.translate('Office.OccupancyPlanView.SaveError'));
      });

    } else {
      this.occupancyPlanViewService.create(occupancyPlanViewForm.value).subscribe((results) => {
        OccupancyPlanViewAdminService.occupancyPlanViewChangedEvent.emit(occupancyPlanViewForm.value);

        this.toastrService.success(this.translocoService.translate('Office.OccupancyPlanView.CreateSuccess'));
        this.router.navigate([`/office/occupancyplanviews/${results.id}`]);
      }, (e) => {
        this.toastrService.error(this.translocoService.translate('Office.OccupancyPlanView.CreateError'));
      });
    }
  }

  public delete(): void {
    this.occupancyPlanViewService.delete(this.occupancyPlanView!.id).subscribe(() => {
      this.router.navigate([`/office/occupancyplanviews`]);
      OccupancyPlanViewAdminService.occupancyPlanViewChangedEvent.emit(this.occupancyPlanView!);
      this.toastrService.success(this.translocoService.translate('Office.OccupancyPlanView.DeleteSuccessful'));
    }, (e: any) => {
      this.toastrService.error(this.translocoService.translate('Office.OccupancyPlanView.DeleteError'));
    });
  }

  private initFormGroup(): void {
    if (!this.occupancyPlanViewForm) {
      this.occupancyPlanViewForm = this.formBuilder.group({
        id: [],
        name: [],
      });
    }
  }

  public isNew(occupancyPlanViewId: any): boolean {
    return (occupancyPlanViewId === 'new');
  }

  public selectedFacilityRoomChanged(selectedItem: any): void {
    if (selectedItem == undefined) 
    {
      this.selectedFacilityRoom = null;
      return;
    }

    this.selectedFacilityRoom = this.facilityRooms.find(x => x.id === selectedItem);
  }

  public addRoom(): void {
    console.log(this.selectedFacilityRoom);
    if (this.selectedFacilityRoom !== null && this.selectedFacilityRoom !== undefined) {
      this.occupancyPlanViewService.addRoom(this.occupancyPlanView!.id, this.selectedFacilityRoom.id).subscribe((results) => {
        this.loadOccupancyPlanViewRooms(this.occupancyPlanView!.id);

        this.selectRoom.handleClearClick();
        this.selectedFacilityRoom = null;

        this.toastrService.success(this.translocoService.translate('Office.OccupancyPlanView.SaveSuccess'));
      }, (e) => {
        this.toastrService.error(this.translocoService.translate('Office.OccupancyPlanView.SaveError'));
      });
    }
  }

  public removeRoom(occupancyPlanViewRoom: OccupancyPlanViewRoom) {
    this.occupancyPlanViewService.removeRoom(occupancyPlanViewRoom.occupancyPlanViewId, occupancyPlanViewRoom.occupancyPlanViewRoomId).subscribe((results) => {
      this.loadOccupancyPlanViewRooms(this.occupancyPlanView!.id);

      this.toastrService.success(this.translocoService.translate('Office.OccupancyPlanView.DeleteSuccessful'));
    }, (e) => {
      this.toastrService.error(this.translocoService.translate('Office.OccupancyPlanView.DeleteError'));
    });
  }

  public moveRoomUp(occupancyPlanViewRoom: OccupancyPlanViewRoom) {
    this.occupancyPlanViewService.updateRoom(occupancyPlanViewRoom.occupancyPlanViewId, occupancyPlanViewRoom.occupancyPlanViewRoomId, (occupancyPlanViewRoom.sort - 1)).subscribe((results) => {
      this.loadOccupancyPlanViewRooms(this.occupancyPlanView!.id);
    }, (e) => {
      this.toastrService.error(this.translocoService.translate('Office.OccupancyPlanView.DeleteError'));
    });
  }

  public moveRoomDown(occupancyPlanViewRoom: OccupancyPlanViewRoom) {
    this.occupancyPlanViewService.updateRoom(occupancyPlanViewRoom.occupancyPlanViewId, occupancyPlanViewRoom.occupancyPlanViewRoomId, (occupancyPlanViewRoom.sort + 1)).subscribe((results) => {
      this.loadOccupancyPlanViewRooms(this.occupancyPlanView!.id);
    }, (e) => {
      this.toastrService.error(this.translocoService.translate('Office.OccupancyPlanView.DeleteError'));
    });
  }

  public copyLinkToClipboard() {
    // get url

    // router.createUrlTree ignores base url, therefore:
    // https://stackoverflow.com/questions/41447305/how-to-get-an-absolute-url-by-a-route-name-in-angular-2

    const internalUrl = '/occupancyplanview/' + this.occupancyPlanView!.id;
    // Resolve the base url as the full absolute url subtract the relative url.
    const currentAbsoluteUrl = window.location.href;
    const currentRelativeUrl = this.router.url;
    const index = currentAbsoluteUrl.indexOf(currentRelativeUrl);
    const baseUrl = currentAbsoluteUrl.substring(0, index);

    // coüy to clipboard
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = baseUrl + internalUrl;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  showModal() {
    const initialstate: ModalOptions = {
      initialState: {
        body: this.translocoService.translate('ConfirmAction'),
        icon: 'fa-solid fa-circle-info',
      }
    }

    const bsModalRef = this.modalService.show(ConfirmationModalComponent, initialstate);
    bsModalRef.content!.confirmed.subscribe(() => {
      this.delete();
    })
  }
}

<div *ngIf="!isLoading">

  <div class="alert alert-danger mt-3"
    *ngIf="submitted && (accountChangeDataFromComponent.getForm().invalid || confirmForm.invalid)">
    <ul class="m-0">
      <li>{{'ValidationText.FillInAllRequiredFields' | transloco}}</li>
      <li *ngIf="!confirmForm.get('acceptAvb')?.valid">{{'ValidationText.AVB' | transloco}}</li>
      <li *ngIf="!confirmForm.get('acceptAusschreibung')?.valid">{{'ValidationText.Details' | transloco}}</li>
      <li *ngIf="!confirmForm.get('acceptMembership')?.valid">{{'ValidationText.Eligibility' | transloco}}</li>
      <li *ngIf="!confirmForm.get('acceptCorrectContactDetails')?.valid">{{'ValidationText.VerifyCorrectContactDetails'
        | transloco}}</li>
    </ul>
  </div>

  <div class="alert alert-danger mt-3" *ngIf="httpError">{{'GeneralErrorMsg' | transloco}}</div>

  <app-pass-photo-required-msg *ngIf="this.accountChangeDataFromComponent"
    [account]="this.accountChangeDataFromComponent.account" [messageToken]="'PassportPhotoNeededDigitalCheckIn'"
    [checkProperty]="'passPhotoRequired'">
  </app-pass-photo-required-msg>

  <app-account-change-data-form #accountChangeDataFromComponent [passPhotoUploadEnabled]="enablePassPhotoUpload()"
    [validatePicture]="true"></app-account-change-data-form>

  <form [formGroup]="confirmForm" *ngIf="confirmForm">
    <div class="form-group" [ngClass]="{'has-error': submitted && confirmForm.get('acceptAvb')?.errors}">
      <div class="checkbox">
        <label class="mb-2">
          <input type="checkbox" formControlName="acceptAvb" />&nbsp;
          <span class="fw-bold" [innerHTML]="'YesIAcceptAGB' | transloco"></span>
        </label>
      </div>
    </div>

    <div class="form-group" [ngClass]="{'has-error': submitted && confirmForm.get('acceptAusschreibung')?.errors}">
      <div class="checkbox">
        <label class="mb-2">
          <input type="checkbox" formControlName="acceptAusschreibung" />&nbsp;
          <span class="fw-bold">
            {{'YesIAcceptInformation' | transloco}}
          </span>
        </label>
      </div>
    </div>
    <div class="form-group" [ngClass]="{'has-error': submitted && confirmForm.get('acceptMembership')?.errors}">
      <div class="checkbox">
        <label class="mb-2">
          <input type="checkbox" formControlName="acceptMembership" />&nbsp;
          <span class="fw-bold">
            {{'YesIAcceptEnrollment' | transloco}}
          </span>
        </label>
      </div>
    </div>
    <div class="form-group"
      [ngClass]="{'has-error': submitted && confirmForm.get('acceptCorrectContactDetails')?.errors}">
      <div class="checkbox">
        <label>
          <input type="checkbox" formControlName="acceptCorrectContactDetails" />&nbsp;
          <span class="fw-bold">
            {{'YesIAcceptCorrectContactDetails' | transloco}}
          </span>
        </label>
      </div>
    </div>
    <div class="mt-3">
      <app-registration-insurance-info></app-registration-insurance-info>
    </div>
  </form>


  <div class="alert">
    <div class="row">
      <div class="col-md-12">
        <div class="float-start">
          <button class="btn btn-default" (click)="back()" *ngIf="!disableBackButton" [disabled]="backSubmitted">
            <span class="fa fa-chevron-left"></span> {{'Back' | transloco}}
          </button>
        </div>
        <div class="float-end">
          <!-- cancelling enrollment-->
          <app-registration-cancel-modal [registration]="registrationSummary"></app-registration-cancel-modal>
          <button class="btn btn-primary ms-2" (click)="sendForm()" [disabled]="disableSendButton">{{'Next' |
            transloco}}
          </button>
        </div>
      </div>
    </div>
  </div>
  <app-spinner *ngIf="isLoading"></app-spinner>
import { Component, OnInit, Input } from '@angular/core';
import { SpecialAssignments } from '../../models/special-assignments.model';

@Component({
  selector: 'app-information-screen-special-assignments',
  templateUrl: './special-assignments.component.html',
  styleUrls: ['./special-assignments.component.scss']
})
export class SpecialAssignmentsComponent implements OnInit {

  @Input() specialAssignments!: SpecialAssignments[];

  constructor() { }

  ngOnInit() {
  }

}

import { Prices } from '../../events/prices.enum';

export class MembershipExtension {
    id!: number;
    priceClass!: Prices;
    invoiceId!: number;
    invoiceAmount!: number;
    paidAmount!: number;
    personMembershipId!: number;
    description!: string;
    price!: number;
    status!: number;
    termsUrl!: string;
    validFrom!: Date;
    validUntil!: Date;

    //defines if last school have to be selected when paying membership
    isLastSchoolRequired!: boolean;
}


import { Component, OnInit, Input, ViewEncapsulation, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormGroup, FormBuilder, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-switch',
    templateUrl: './switch.component.html',
    styleUrls: ['./switch.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SwitchComponent),
            multi: true
        }
    ]
})
export class SwitchComponent implements OnInit, ControlValueAccessor {

    @Input() edit!: boolean;
    @Input() readOnly!: boolean;
    @Input() yesPrice!: number;
    @Input() noPrice!: number;

    conformityForm: FormGroup;
    private _value : any;

    yes: FormControl;
    no: FormControl;

    @Input() set value(value: string) {
        if (value == null) {
            this._value = 'false';
        } else {
            if (typeof value == 'string') {
                if (value.includes('true')) {
                    this._value = value;
                } else {
                    this._value = 'false';
                }
            } else {
                this._value = 'false';
            }
        }
        this.propagateChange(this._value);
    }
    get value() {
        return this._value;
    }

    constructor(private formBuilder: FormBuilder) {
        this.conformityForm = this.formBuilder.group({
            'IsAdequatequalificationsYes': [''],
            'IsAdequatequalificationsNo': ['']
        });
        this.yes = <FormControl>this.conformityForm.get('IsAdequatequalificationsYes');
        this.no = <FormControl>this.conformityForm.get('IsAdequatequalificationsNo');
    }

    ngOnInit() {
    }

    propagateChange = (_: any) => { };
    onTouched: any = () => { };

    writeValue(obj: any): void {
        this.value = obj;
    }
    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {

    }

    yesClicked() {
        this.writeValue('true');
    }

    noClicked() {
        this.writeValue('false');
    }

    isYesSelected(): boolean{
        return this._value == 'true';
    }

    isNoSelected(): boolean{
        return this._value == 'false';
    }

    isChecked() {
        return this.value.includes('true');
    }


}

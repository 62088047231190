import { Component, OnInit } from '@angular/core';
import { ApplicationList } from '../models';
import { MembershipApplicationAdminService } from '../services/membership-application-admin.service';
import { BaseComponent } from '@premotec/ngx-essentials';
import { TranslocoService } from '@ngneat/transloco';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-ma-overview',
  templateUrl: './ma-overview.component.html',
  styleUrls: ['./ma-overview.component.scss']
})
export class MaOverviewComponent extends BaseComponent implements OnInit {

  isLoading = true;

  constructor(
    private translocoService: TranslocoService,
    private titleService: Title,
    private membershipApplicationAdminService: MembershipApplicationAdminService
  ) {
    super();
  }

  ngOnInit() {
    this.membershipApplicationAdminService.getEditors().subscribe().add(() => this.isLoading = false);
    this.translocoService.selectTranslate('Office.NewMembershipApplications').subscribe((x: any) => {
      this.titleService.setTitle(x);
    });
  }

}

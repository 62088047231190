import { Injectable } from '@angular/core';
import {
    HttpEvent, HttpRequest, HttpHandler,
    HttpInterceptor, HttpErrorResponse, HttpResponse
} from '@angular/common/http';
import { empty, Observable, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { RequestRateModalComponent } from '../request-rate-modal/request-rate-modal.component';

@Injectable({
    providedIn: 'root'
})
export class RequestRateInterceptor implements HttpInterceptor {

    isModalOpen = false;

    constructor(
        private modalService: BsModalService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status == 429) {
                    this.showModal(error.error);
                    return empty();
                }
                return throwError(error); 
            }));
    }

    showModal(timeout: number) {
        if (this.isModalOpen) return;
        this.isModalOpen = true;
        const initialState: ModalOptions = {
            backdrop: 'static',
            keyboard: false,
            initialState: {

            },
        };

        const bsModalRef = this.modalService.show(RequestRateModalComponent, initialState);
        bsModalRef.content?.startTime(timeout);
        bsModalRef.content?.confirmed.subscribe(x => {
            this.isModalOpen = false;
        })
    }
}
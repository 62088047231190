import { Component, OnInit, ViewEncapsulation, TemplateRef, ViewChild, EventEmitter, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription, timer } from 'rxjs';

@Component({
    selector: 'app-request-rate-modal',
    templateUrl: './request-rate-modal.component.html',
    styleUrls: ['./request-rate-modal.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class RequestRateModalComponent {

    @Output() confirmed = new EventEmitter<boolean>();
    timerSeconds = 0;
    countDown!: Subscription | null;

    constructor(private bsModalRef: BsModalRef) {

    }

    ngOnDestroy() {
        this.stopTimer();
    }


    ngOnInit() {
        
    }

    confirm() {
        this.confirmed.emit(true);
        window.location.reload();
        this.bsModalRef.hide();
    }


    startTime(time: number) {
        this.stopTimer();
        this.timerSeconds = time;
        this.countDown = timer(0, 1000).subscribe(() => {
            if (this.timerSeconds <= 0) {
                this.stopTimer();
                return;
            }
            --this.timerSeconds
        })
    }

    stopTimer() {
        if (this.countDown != null) this.countDown.unsubscribe();
        this.countDown = null;
    }

}


import {Component, Input, OnInit} from '@angular/core';
import { StatusStep } from './status-step.class';

@Component({
  selector: 'app-status-workflow',
  templateUrl: './status-workflow.component.html',
  styleUrls: ['./status-workflow.component.scss']
})
export class StatusWorkflowComponent implements OnInit {
  @Input()
  statuses?: StatusStep[];

  constructor() {
  }

  ngOnInit() {
  }

}

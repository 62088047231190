import { Component, OnInit, Input } from '@angular/core';
import { SportsSchedules } from '../../models/sports-schedules.model';

@Component({
  selector: 'app-information-screen-sports-schedules',
  templateUrl: './sports-schedules.component.html',
  styleUrls: ['./sports-schedules.component.scss']
})
export class SportsSchedulesComponent implements OnInit {

  @Input() sportsSchedules!: SportsSchedules;

  constructor() { }

  ngOnInit() {
  }

}

import { Component, OnInit } from '@angular/core';
import { RefundService } from '../../services/refund.service';
import { Refund } from '../../models/refund.model';
import { TranslocoService } from '@ngneat/transloco';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-refund-list',
  templateUrl: './refund-list.component.html',
  styleUrls: ['./refund-list.component.scss']
})
export class RefundListComponent implements OnInit {
  openRefundList!: Refund[] | null;
  processedRefundList!: Refund[] | null;

  isLoading = false;

  constructor(
    private refundService: RefundService,
    private translocoService: TranslocoService,
    private titleService: Title
  ) { }

  ngOnInit() {
    this.translocoService.selectTranslate('Refunds').subscribe((x: any) => {
      this.titleService.setTitle(x);
    });

    this.loadRefunds();
  }

  loadRefunds() {
    this.isLoading = true;
    this.processedRefundList = null;
    this.openRefundList = null;

    var refundRequest = this.refundService.getRefundList().subscribe({next: (res) =>{
      // filter processed refunds
      let fullListProcessedRefunds = res.filter(function (refund) {
        return refund.pspPaymentRefund != null;
      });
      this.processedRefundList = this.sortRefunds(fullListProcessedRefunds);

      // filter open refunds
      let fullListOpenRefunds = res.filter(function (refund) {
        return refund.pspPaymentRefund == null;
      });
      this.openRefundList = this.sortRefunds(fullListOpenRefunds);

      refundRequest.unsubscribe();
      this.isLoading = false;
    },
    error: () => {
      this.isLoading = false;
    }});
  }

  selectAll() {
    this.openRefundList!.forEach(refund => { refund.isSelected = true })
  }

  deselectAll() {
    this.openRefundList!.forEach(refund => { refund.isSelected = false })
  }

  runSelected() {
    let selectedRefunds = this.openRefundList!.filter(function (refund) {
      return refund.isSelected;
    });

    this.refundService.requestRefund(selectedRefunds).subscribe(_ => {
      this.loadRefunds();
    });
  }

  countSelected(): number {
    if (this.openRefundList == null) {
      return 0;
    }
    return this.openRefundList.filter(function (refund) {
      return refund.isSelected;
    }).length;
  }

  private sortRefunds(refunds: Refund[]): Refund[] {
    return refunds.sort((a, b) => (`${this.extractPipe(a.refundInfoText)} ${a.lastname}` > `${this.extractPipe(b.refundInfoText)} ${b.lastname}`) ? 1 : -1);
  }


  private extractPipe(value: string): string {
    var splited = value.split(' | ');

    if (splited.length < 3) {
      return value;
    }
    return splited[2];
  }

}

<ng-container *ngIf="!isLoading">
<div class="container-fluid mt-3">
  <h1>{{'Office.NewMembershipApplications' | transloco }}</h1>
  <ul class="nav nav-tabs pointer">
    <li class="nav-item nav-pills-asvz" role="presentation"><a class="nav-link" routerLink="/office/membership-applications/current/online" routerLinkActive="active">{{'Office.OnlineVerification' | transloco }}</a></li>
    <li class="nav-item nav-pills-asvz" role="presentation"><a class="nav-link" routerLink="/office/membership-applications/current/physical" routerLinkActive="active">{{'Office.PhysicalVerification' | transloco }}</a></li>
    <li class="nav-item nav-pills-asvz" role="presentation"><a class="nav-link" routerLink="/office/membership-applications/all" routerLinkActive="active">{{'All' | transloco }}</a></li>
    <li class="nav-item nav-pills-asvz" role="presentation"><a class="nav-link"  routerLink="/office/membership-applications/archived" routerLinkActive="active">{{'Office.Archived' | transloco }}</a></li>
  </ul>

  <div style="margin-top:1rem;">
    <router-outlet></router-outlet>
  </div>
</div>
</ng-container>


<app-spinner *ngIf="isLoading" class="mt-3"></app-spinner>
<div class="card">
    <div class="card-header">
        <div class="d-flex">
            <div class="flex-grow-1"><b>{{'Tournaments.Teams.TeamData.Title' | transloco}}</b></div>
        </div>
    </div>
    <div class="card-body p-3" *ngIf="!teamDataModel && !showNotFound">
        <app-spinner></app-spinner>
    </div>
    <div class="card-body" *ngIf="showNotFound">
        {{ 'Tournaments.Teams.TeamData.TeamNotFound' | transloco}}
    </div>
    <div class="card-body" *ngIf="teamDataModel && !showNotFound">
        <div class="row">
            <div class="col-12">
                <label class="form-label">{{'Tournaments.Teams.TeamData.TeamName' | transloco}}</label>
                <input class="form-control" [(ngModel)]="teamDataModel.teamName" disabled="true" />
            </div>
        </div>
        <div class="row" *ngIf="teamDataModel.captainFullName">
            <div class="col-12 mt-3">
                <label class="form-label">{{'Tournaments.Teams.TeamData.CaptainFullName' | transloco}}</label>
                <input class="form-control" [(ngModel)]="teamDataModel.captainFullName" disabled="true" />
            </div>
        </div>
        <div class="row" *ngIf="teamDataModel.captainMobile">
            <div class="col-12 mt-3">
                <label class="form-label">{{'Tournaments.Teams.TeamData.CaptainMobile' | transloco}}</label>
                <input class="form-control" [(ngModel)]="teamDataModel.captainMobile" disabled="true" />
            </div>
        </div>
        <div class="row">
            <div class="col-12 mt-3">
                <label class="form-label">{{'Tournaments.Teams.TeamData.CaptainEmail' | transloco}}</label>
                <input class="form-control" [(ngModel)]="teamDataModel.captainEmail" disabled="true" />
            </div>
        </div>
        <div class="row" *ngIf="teamDataModel.additionalFieldName != null && teamDataModel.additionalFieldName != ''">
            <div class="col-12 mt-3">
                <label class="form-label">{{teamDataModel.additionalFieldName}}</label>
                <input class="form-control" [(ngModel)]="teamDataModel.additionalFieldValue" disabled="true" />
            </div>
        </div>
        <div class="row" *ngIf="displayPreferences()">
            <div class="col-12 mt-3">
                <label class="form-label mb-1">{{'Tournaments.Teams.TeamData.Preferences' | transloco}}</label>
                <div>
                    <span class="badge bg-secondary p-2 me-2 mt-1" *ngIf="teamDataModel.monPreference">
                        {{'Day_1' | transloco}}: {{teamDataModel.monPreference}}
                    </span>
                    <span class="badge bg-secondary p-2 me-2 mt-1" *ngIf="teamDataModel.tuePreference">
                        {{'Day_2' | transloco}}: {{teamDataModel.tuePreference}}
                    </span>
                    <span class="badge bg-secondary p-2 me-2 mt-1" *ngIf="teamDataModel.wedPreference">
                        {{'Day_3' | transloco}}: {{teamDataModel.wedPreference}}
                    </span>
                    <span class="badge bg-secondary p-2 me-2 mt-1" *ngIf="teamDataModel.thuPreference">
                        {{'Day_4' | transloco}}: {{teamDataModel.thuPreference}}
                    </span>
                    <span class="badge bg-secondary p-2 me-2 mt-1" *ngIf="teamDataModel.friPreference">
                        {{'Day_5' | transloco}}: {{teamDataModel.friPreference}}
                    </span>
                    <span class="badge bg-secondary p-2 me-2 mt-1" *ngIf="teamDataModel.satPreference">
                        {{'Day_6' | transloco}}: {{teamDataModel.satPreference}}
                    </span>
                    <span class="badge bg-secondary p-2 me-2 mt-1" *ngIf="teamDataModel.sunPreference">
                        {{'Day_0' | transloco}}: {{teamDataModel.sunPreference}}
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
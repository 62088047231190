<div class="container" style="min-height: 500px;">
  <div style="margin-top: 34px;"></div>

  <div class="row">
    <div *ngIf="isCanceling" class="col-sm-12">
        <div class="alert alert-primary">{{ 'InvoiceMessage.CancelingPayment' | transloco }}</div>
        <app-spinner margin="100px auto"></app-spinner>
    </div>
    <div *ngIf="!isPaymentDenied && !isCanceling" class="col-sm-12">
      <h3>{{'PaymentProcessing' | transloco}}</h3>
      {{'YourPaymentIsProcessing' | transloco}}
    </div>
    <div *ngIf="isPaymentDenied && !isCanceling">
      <div *ngIf="paymentStatus === 0" class="col-sm-12">
        <h3 class="text-danger">{{'PaymentDenied_0' | transloco}}</h3>
        {{'YourPaymentIsDeniedMsg_0' | transloco}}
      </div>
      <div *ngIf="paymentStatus === 1" class="col-sm-12">
        <h3 class="text-danger">{{'PaymentDenied_1' | transloco}}</h3>
        {{'YourPaymentIsDeniedMsg_1' | transloco}}
      </div>
      <div *ngIf="paymentStatus === 2" class="col-sm-12">
        <h3 class="text-danger">{{'PaymentDenied' | transloco}}</h3>
        {{'YourPaymentIsDeniedMsg' | transloco}}
      </div>
      <div *ngIf="paymentStatus === 93" class="col-sm-12">
        <h3 class="text-danger">{{'PaymentDenied' | transloco}}</h3>
        {{'YourPaymentIsDeniedMsg' | transloco}}
      </div>
    </div>
  </div>
  <div class="row" *ngIf="invoiceType && !isCanceling">
    <div class="col-sm-12">
      <br />
      <ng-container [ngSwitch]="invoiceType.typeId">
        <ng-template [ngSwitchCase]="invoiceRegistrationType">
          <a class="btn btn-default" [routerLink]="'/registration/'+invoiceType.recordId+'/details'" >{{'GotoRegistration' | transloco}}</a>
        </ng-template>
        <ng-template [ngSwitchCase]="invoiceTypes.Membership">
          <a class="btn btn-default" [routerLink]="'/memberships'" >{{'GoToMemberships' | transloco}}</a>
        </ng-template>
      </ng-container>
    </div>
  </div>
</div>

import {Component, OnInit} from '@angular/core';
import {MaintenanceService} from '../maintenance.service';
import {Maintenance} from '../maintenance.model';
import {ActivatedRoute} from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-maintenance-overview',
  templateUrl: './maintenance-overview.component.html',
  styleUrls: ['./maintenance-overview.component.scss']
})
export class MaintenanceOverviewComponent implements OnInit {

  maintenance!: Maintenance;
  maintenances!: Array<Maintenance>;
  // public toasterconfig: ToasterConfig =
  //   new ToasterConfig({
  //     tapToDismiss: true,
  //     timeout: 4000
  //   });

  constructor(private maintenanceService: MaintenanceService,
              private activatedRoute: ActivatedRoute,
              private translocoService: TranslocoService,
              private titleService: Title) {
  }

  ngOnInit() {
    this.translocoService.selectTranslate('MaintenanceWindow').subscribe(x => {
      this.titleService.setTitle(x);
    });

    this.loadAllMaintenanceList();

    MaintenanceService.maintenanceChangedEvent.subscribe(() => {
      console.log('Reloading maintenances');
      this.loadAllMaintenanceList();
    });
  }

  loadAllMaintenanceList() {
    this.maintenanceService.getAllFuture().subscribe((maintenances: Array<Maintenance>) => {
      this.maintenances = maintenances;
    });
  }
}

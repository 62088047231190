import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../shared/shared.module';
import { OfficeMainLayoutComponent } from './office-main-layout/office-main-layout.component';
import {MaintenanceModule} from './maintenance/maintenance.module';
import {OfficeRoutingModule} from './office-routing.module';
import { UsersModule } from './users/users.module';
import { MembershipApplicationsModule } from './membership-applications/membership-applications.module';
import { RefundsModule } from './refunds/refunds.module';
import { OccupancyPlanViewsAdminModule } from './occupancy-plan-views-admin/occupancy-plan-views-admin.module';
import { TranslocoService } from '@ngneat/transloco';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
@NgModule({
  imports: [
    CommonModule,
    OccupancyPlanViewsAdminModule,
    MaintenanceModule,
    SharedModule,
    UsersModule,
    OfficeRoutingModule,
    MembershipApplicationsModule,
    RefundsModule,
    NgSelectModule,
    FormsModule
  ],
  declarations: [
    OfficeMainLayoutComponent,
  ],
  exports: [],
  providers: []
})
export class OfficeModule {
  constructor (translate: TranslocoService) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('de');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    // translate.use('de');
  }
}

import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { DatePipe } from '@angular/common';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { TournamentService } from '../services/tournament.service';
import { TournamentTypeEnum } from '../enums/tournament-type.enum';
import { AgGridAngular } from 'ag-grid-angular';
import { GridReadyEvent } from 'ag-grid-community';
import { GridService } from 'src/app/shared/ag-grid/grid.service';
import * as moment from 'moment';
import { TournamentGamePlanModel } from '../models/tournament-game-plan.model';
import { TournamentGameOptionsAgGridComponent } from '../tournament-game-options-ag-grid/tournament-game-options-ag-grid.component';
import { TournamentDisplayService } from '../services/tournament-display.service';
import { ConfirmationModalComponent } from 'src/app/shared/confirmation-modal/confirmation-modal.component';
import { ToastrService } from 'ngx-toastr';
import { TournamentGameStatusAgGridComponent } from '../tournament-game-status-ag-grid.component/tournament-game-status-ag-grid.component';
import { TournamentGamePlanStatusEnum } from '../enums/tournament-game-plan-status.enum';
import { ExcelExportService } from 'src/app/shared/services/excel-export.service';
import { TournamentModel } from '../models/tournament.model';
import { filter, forkJoin } from 'rxjs';
import { TournamentGamesExchangeSlotsModel } from '../models/tournament-games-exchange-slots.model';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-tournament-game-plans-details',
    templateUrl: './tournament-game-plans-details.component.html',
    styleUrls: ['./tournament-game-plans-details.component.scss'],
    providers: [GridService]
})
export class TournamentGamePlansDetailsComponent implements OnInit, OnDestroy {

    @ViewChild('agGrid') agGrid!: AgGridAngular;
    rowData: any = [];
    columnDefs: any = [];
    defaultColDef = {
        sortable: true,
        filter: true,
        floatingFilter: true,
        resizable: true,
    }
    gridOptions = {
        rowSelection: 'multiple'
    };

    enableGamesExchange: boolean = false;
    selectedTournamentIds: string[] = [];

    @Input() tournamentPlanId!: string;

    context!: TournamentGamePlansDetailsComponent;

    gamePlan!: TournamentGamePlanModel;
    tournament!: TournamentModel;

    loadingData: boolean = true;

    TournamentTypeEnum = TournamentTypeEnum;
    TournamentGamePlanStatusEnum = TournamentGamePlanStatusEnum;

    checkStatusTimer: any;

    constructor(
        private translocoService: TranslocoService,
        private activatedRoute: ActivatedRoute,
        private tournamentService: TournamentService,
        private tournamentDisplayService: TournamentDisplayService,
        private fb: FormBuilder,
        private datepipe: DatePipe,
        private modalService: BsModalService,
        private gridService: GridService,
        private toastrService: ToastrService,
        private excelExportService: ExcelExportService,
    ) {
    }
    ngOnDestroy(): void {
        if (this.checkStatusTimer) clearInterval(this.checkStatusTimer);
    }


    ngOnInit() {
        this.context = this;
        if (this.activatedRoute.parent) {
            this.activatedRoute.parent.params.subscribe((params: Params) => {
                this.tournamentPlanId = params['tournamentPlanId'];

                this.translocoService.selectTranslate('Tournaments.Events.Game.ResultEntry.Title').subscribe(x => {
                    this.initGrid();
                    this.loadData();
                });
            });
        }
    }

    loadData() {
        forkJoin({
            tournament: this.tournamentService.getTournamentById(this.tournamentPlanId),
            gamePlan: this.tournamentService.getGamePlan(this.tournamentPlanId)
        }).subscribe(x => {
            this.tournament = x.tournament;

            x.gamePlan.games.forEach(g => {
                g.teams = `${g.homeTeamName} vs. ${g.guestTeamName}`;
                g.referee = g.refereeTeamName ?? '';
                g.fullLocation = `${g.facilityName} - ${g.roomName} - ${this.tournamentDisplayService.getFieldName(g.fieldId)}`;
            })

            this.gamePlan = x.gamePlan;
            if (this.gamePlan.gamePlanStatus == TournamentGamePlanStatusEnum.Creating) this.startGamePlanStatusCheck();

            this.gridService.processGrid(this.agGrid, this.gamePlan.games, this.constructor.name);
            this.agGrid.api.sizeColumnsToFit();
            this.loadingData = false;
        });
    }

    exportListToExcel() {
        //'any' instead of 'TournamentGameModel' because of exclusion of the 'preferenceStatus'
        var dataForExport: any[] = [];

        this.agGrid?.api.forEachNodeAfterFilterAndSort((rowNode) => {
            const data = rowNode.data;
            // Destructure to exclude the 'preferenceStatus' property
            const { preferenceStatus, from, to, ...dataWithoutPreferenceStatus } = data;
            dataForExport.push({
                ...dataWithoutPreferenceStatus,
                from: this.dateFromFormatter(rowNode),
                to: this.dateToFormatter(rowNode)
            });
        });
    
        this.excelExportService.generateExcel(this.agGrid, this.tournament.name.concat('(', this.translocoService.translate('Tournaments.GamePlans.Title'), ')'), dataForExport, { filterFor: ['preferenceStatus', 'actions'] });
    }

    openGameCreationModal() {
        const initialstate: ModalOptions = {
            initialState: {
                body: this.translocoService.translate('Tournaments.GamePlans.TriggerCreateQuestion'),
            }
        }

        const bsModalRef = this.modalService.show(ConfirmationModalComponent, initialstate);

        bsModalRef.content!.confirmed.subscribe(() => {
            this.triggeGameCreation();
        })
    }

    triggeGameCreation() {
        this.loadingData = true;

        this.tournamentService.triggerCreatingGames(this.tournamentPlanId).subscribe({
            next: (status) => {
                this.loadingData = false;
                this.gamePlan.gamePlanStatus = status;
                if (status == TournamentGamePlanStatusEnum.Creating) {
                    this.toastrService.success(this.translocoService.translate('Tournaments.GamePlans.TriggerCreateSuccess'));
                    this.startGamePlanStatusCheck();
                } else {
                    this.toastrService.error(this.translocoService.translate('Tournaments.GamePlans.TriggerCreateError'));
                }
            },
            error: () => {
                this.loadingData = false;
                this.toastrService.error(this.translocoService.translate('Tournaments.GamePlans.TriggerCreateError'));
            }
        });
    }

    openGameDeleteModal() {
        const initialstate: ModalOptions = {
            initialState: {
                body: this.translocoService.translate('Tournaments.GamePlans.TriggerDeleteQuestion'),
            }
        }

        const bsModalRef = this.modalService.show(ConfirmationModalComponent, initialstate);
        bsModalRef.content!.confirmed.subscribe(() => {
            this.triggeGameDelete();
        })
    }

    triggeGameDelete() {
        this.loadingData = true;

        this.tournamentService.deleteGamePlans(this.tournamentPlanId).subscribe({
            next: (status) => {
                this.loadingData = false;
                this.gamePlan.gamePlanStatus = status;
                if (status == TournamentGamePlanStatusEnum.Empty) {
                    this.toastrService.success(this.translocoService.translate('Tournaments.GamePlans.TriggerDeleteSuccess'));
                } else {
                    this.toastrService.error(this.translocoService.translate('Tournaments.GamePlans.TriggerDeleteError'));
                }
            },
            error: () => {
                this.loadingData = false;
                this.toastrService.error(this.translocoService.translate('Tournaments.GamePlans.TriggerDeleteError'));
            }
        });
    }

    triggeGameSimulation() {
        this.loadingData = true;
        this.tournamentService.triggerSimulateGames(this.tournamentPlanId).subscribe(x => {
            this.loadData();
        });
    }

    startGamePlanStatusCheck() {
        this.checkStatusTimer = setInterval(() => {
            if (this.gamePlan.gamePlanStatus == TournamentGamePlanStatusEnum.Creating) {
                this.checkGamePlanStatusUpdate();
            } else {
                clearInterval(this.checkStatusTimer);
            }
        }, 5000);
    }

    checkGamePlanStatusUpdate() {
        this.tournamentService.getGamePlansStatus(this.tournamentPlanId).subscribe(x => {
            this.gamePlan.gamePlanStatus = x;
            if (this.gamePlan.gamePlanStatus == TournamentGamePlanStatusEnum.Created) this.loadData();
        });
    }

    onGridReady(parameters: GridReadyEvent) {
        // this.gridApi = parameters.api;
    }

    dateFromFormatter(params: any) {
        return moment(params.data.from).format('DD.MM.yy HH:mm');
    }

    dateToFormatter(params: any) {
        return moment(params.data.to).format('DD.MM.yy HH:mm');
    }

    initGrid() {
        this.gridOptions.rowSelection = 'multiple';
        this.columnDefs = [
            {
                checkboxSelection: true,
                filter: false,
                sortable: false,
                maxWidth: 50,
                minWidth: 50, 
            },
            { field: 'imEventNumber', headerName: this.translocoService.translate('Tournaments.GamePlans.Event'), maxWidth: 150, minWidth: 150, },
            { field: 'groupName', headerName: this.translocoService.translate('Tournaments.GamePlans.Group'), minWidth: 100, },
            {
                field: 'preferenceStatus',
                headerName: '',
                filter: false,
                sortable: false,
                cellRenderer: TournamentGameStatusAgGridComponent,
                cellEditorPopupPosition: 'under',
                maxWidth: 75,
                minWidth: 75,
                type: 'client'
            },
            {
                field: 'from',
                headerName: this.translocoService.translate('Tournaments.GamePlans.From'),
                maxWidth: 150,
                minWidth: 150,
                valueFormatter: this.dateFromFormatter,
                filterValueGetter: this.dateFromFormatter,
                comparator: (valueA: Date, valueB: Date) => {
                    if (valueA == valueB) return 0;
                    return new Date(valueA).getTime() - new Date(valueB).getTime()
                }
            },
            {
                field: 'to',
                headerName: this.translocoService.translate('Tournaments.GamePlans.To'),
                maxWidth: 150,
                minWidth: 150,
                valueFormatter: this.dateToFormatter,
                filterValueGetter: this.dateToFormatter,
                comparator: (valueA: Date, valueB: Date) => {
                    if (valueA == valueB) return 0;
                    return new Date(valueA).getTime() - new Date(valueB).getTime()
                }
            },
            {
                field: 'teams',
                headerName: this.translocoService.translate('Tournaments.GamePlans.Teams'),
                minWidth: 100,
                comparator: (valueA: string, valueB: string) => {
                    if (valueA == valueB) return 0;
                    return (valueA.toLowerCase() > valueB.toLowerCase()) ? 1 : -1;
                }
            },
            { field: 'result', headerName: this.translocoService.translate('Tournaments.GamePlans.Result'), minWidth: 100, },
            {
                field: 'referee',
                headerName: this.translocoService.translate('Tournaments.GamePlans.Referee'),
                minWidth: 100,
                comparator: (valueA: string, valueB: string) => {
                    if (valueA == valueB) return 0;
                    return (valueA.toLowerCase() > valueB.toLowerCase()) ? 1 : -1;
                }
            },
            { field: 'fullLocation', headerName: this.translocoService.translate('Tournaments.GamePlans.Room'), minWidth: 100, },
            {
                field: 'actions',
                headerName: '',
                filter: false,
                sortable: false,
                cellRenderer: TournamentGameOptionsAgGridComponent,
                cellEditorPopupPosition: 'under',
                tooltipField: "edit",
                minWidth: 135,
                maxWidth: 135,
                pinned: 'right',
                type: 'client'
            },
        ];
    }

    onSelectionChanged() {
        this.selectedTournamentIds = this.agGrid.api.getSelectedNodes().map(node => node.data.id);
        if(this.selectedTournamentIds.length == 2){
            this.enableGamesExchange = true;
        }else{
            this.enableGamesExchange = false;
        }
    }

    gamesExchange(){
        if(this.enableGamesExchange && this.selectedTournamentIds.length == 2){
            
            const initialstate: ModalOptions = {
                initialState: {
                    title:this.translocoService.translate('Tournaments.GamePlans.TriggerGamesExchange'),
                    body: this.translocoService.translate('Tournaments.GamePlans.TriggerGamesExchangeQuestion'),
                }
            }
    
            const bsModalRef = this.modalService.show(ConfirmationModalComponent, initialstate);
            bsModalRef.content!.confirmed.subscribe(() => {
                let model: TournamentGamesExchangeSlotsModel = {
                    tournamentGame1Id: this.selectedTournamentIds[0],
                    tournamentGame2Id: this.selectedTournamentIds[1]
                }
                this.loadingData = true;
                this.tournamentService.updateTournamentGamesSlots(this.tournamentPlanId, model).subscribe({
                    next: (res) => {
                        if(res){
                            this.loadData();
                            this.toastrService.success(this.translocoService.translate('Tournaments.GamePlans.TriggerGamesExchangeSuccess'));
                        }else{
                            this.toastrService.error(this.translocoService.translate('Tournaments.GamePlans.TriggerGamesExchangeError'));
                        }
                        this.loadingData = false;
                    },
                    error: () => {
                        this.loadingData = false;
                        this.toastrService.error(this.translocoService.translate('Tournaments.GamePlans.TriggerGamesExchangeError'));
                    }
                })
            })
        }
    }
}

<div class="modal-header text-light">
    <h3>{{'Tournaments.Teams.ChangeGroup' | transloco}}</h3>
</div>

<div class="modal-body">
    <form *ngIf="groups" [formGroup]="groupForm">
        <div class="row">
            <div class="col-12">
                <label for="groupId" class="form-label">{{'Tournaments.Teams.Group' | transloco}}</label>
                <div>
                    <select class="form-select" formControlName="groupId">
                        <option *ngFor="let group of getSortedGroups()" [value]="group.id">{{ groupPrefix }} {{ group.number }}</option>
                      </select>
                </div>
            </div>
        </div>
    </form>
</div>

<div class="modal-footer text-light">
    <button type="button" class="btn btn-default" (click)="decline()">{{'Cancel' | transloco}}</button>
    <button type="submit" class="btn btn-success" (click)="confirm()">
        <i *ngIf="!isSaving" class="fas fa-save"></i> 
        <span *ngIf="isSaving" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> {{'Save' | transloco}}
    </button>
    
</div>
import {Routes} from '@angular/router';
import { AuthGuard } from '../security/guards';
import {WaitingListMainComponent} from './waiting-list-main-layout/waiting-list-main-layout.component';
import {WaitingListOverviewComponent} from './waiting-list-overview/waiting-list-overview.component';

export const routes: Routes = [
  {
    path: 'waiting-list',
    component: WaitingListMainComponent,
    canActivate: [AuthGuard],
    children: [
      {path: 'overview', component: WaitingListOverviewComponent}
    ]
  }
]

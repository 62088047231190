import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { TranslocoService } from '@ngneat/transloco';
import { ConfirmationModalComponent } from 'src/app/shared/confirmation-modal/confirmation-modal.component';
import { EnrollmentStateProperties } from '../../models/enrollment-state-properties.model';

@Component({
  selector: 'app-lesson-withdraw-button',
  templateUrl: './lesson-withdraw-button.component.html',
  styleUrls: ['./lesson-withdraw-button.component.scss']
})
export class LessonWithdrawButtonComponent implements OnInit {

  @Input() enrollmentProperties!: EnrollmentStateProperties;
  @Output() buttonClicked = new EventEmitter<void>();

  constructor(
    private translocoService: TranslocoService,
    private modalService: BsModalService) { }

  ngOnInit(): void {
  }

  showWithdrawConfirmationModal(): void {

    const initialstate: ModalOptions = {
      initialState: {
        body: this.translocoService.translate('LessonActions.ConfirmWithdraw'),
        icon: 'fa-solid fa-circle-info',
        confirmText: this.translocoService.translate('Yes'),
        declineText: this.translocoService.translate('No'),
      }
    }

    const bsModalRef = this.modalService.show(ConfirmationModalComponent, initialstate);

    bsModalRef.content!.confirmed.subscribe(() => {
      this.buttonClicked.next();
    });
  }

}

import { Component, OnInit, Input } from '@angular/core';
import { mergeMap } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { BaseComponent } from '@premotec/ngx-essentials';
import { MembershipApplicationsService } from 'src/app/membership-application/membership-applications.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-ma-file-display',
  templateUrl: './ma-file-display.component.html',
  styleUrls: ['./ma-file-display.component.scss']
})
export class MaFileDisplayComponent extends BaseComponent implements OnInit {

  @Input() applicationId!: string;
  @Input() document: any;

  base64Data: any;
  isLoading: any;
  mimeType: any;
  type: any;

  file: any;

  fileUrl: any;

  constructor(private applicationService: MembershipApplicationsService, private sanitizer: DomSanitizer) { 
    super();
  }

  ngOnInit() {
    this.getFile();
  }


  getFile() {
    this.isLoading = true;
    this.applicationService.getConfigFileDownloadId(this.applicationId, this.document.id)
    .pipe(
      mergeMap(res => {
        return this.applicationService.downloadFileAsBlob(res.downloadId);
      })
    )
    .subscribe((blob: any) => {
      const reader = new FileReader();
      this.mimeType = blob.type;
      this.type = blob.type.split('/')[0];
      reader.readAsDataURL(blob);

      reader.onloadend = () => {
        this.base64Data = this.sanitizer.bypassSecurityTrustResourceUrl(reader.result?.toString()!);
        this.isLoading = false;
      }

      }
    );
  }

  downloadFile() {
    this.whileImAlive(this.applicationService.getConfigFileDownloadId(this.applicationId, this.document.id))
      .subscribe(res => {
        window.open(`${environment.api}/FileDownloads/${res.downloadId}`, '_blank')
    });
  }
}



import{Pipe, PipeTransform} from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Pipe({
    name: 'displayRefundStatus'
})
export class DisplayRefundStatus implements PipeTransform{
    constructor(private translocoService: TranslocoService){

        
        this.refundStateOpen = this.translocoService.translate('RefundStateOpen');
        this.refundStateProcessed = this.translocoService.translate('RefundStateProcessed');
    }
    
    private refundStateOpen!: string;
    private refundStateProcessed!: string;


    transform(value: number): string {
        switch(value) { 
            case 0: { 
                return this.refundStateOpen;
                
            } 
            case 1: { 
                return this.refundStateProcessed;
            } 
            default: { 
               return "";
            } 
         }         
    }
    
}
import { Component, EventEmitter, Output, TemplateRef, ViewChild, Input } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-confirm-action-modal',
  templateUrl: './confirm-action-modal.component.html',
  styleUrls: ['./confirm-action-modal.component.scss'],
})
export class ConfirmActionModalComponent{

  @Input() title!: string;
  @Input() body!: string;
  @Input() confirmText!: string;

  isLoading = false;

  modalRef!: BsModalRef;
  @ViewChild('confirmActionModal') confirmActionModal!: TemplateRef<any>;
  @Output() onEventConfirmed: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private modalService: BsModalService) {}

  open() {
    this.modalRef = this.modalService.show(this.confirmActionModal);
  }

  confirm() {
    this.onEventConfirmed.emit(true);
    this.modalRef.hide();
  }

  cancel() {
    this.modalRef.hide();
  }

  close() {
    this.modalRef.hide();
  }
}

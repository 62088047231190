<h3>{{'MyDetails' | transloco}}</h3>

<div class="form-group">
    <div class="checkbox">
      <label>
        <input type="checkbox" [attr.disabled]="true" checked />
        <span [innerHTML]="'YesIAcceptDataPrivacyStatement' | transloco: {url: membershipExtension.termsUrl}" class="ms-1"></span>
      </label>
    </div>
  </div>

<h3 class="mt-3">{{'Product' | transloco}}</h3>

<table class="table table-striped table-responsive table-bordered">
    <thead style="font-weight: bold;">
    <tr>
      <td>{{'Labeling' | transloco}}</td>
      <td>{{'ValidFrom' | transloco}}</td>
      <td>{{'ValidTo' | transloco}}</td>
      <td>{{'Price' | transloco}}</td>
    </tr>
    </thead>
    <tbody>
      <tr>
        <td>{{membershipExtension.description}}</td>
        <td>
          <span class="me-1">{{membershipExtension.validFrom | dateFormat: "DD.MM.YYYY"}}</span>
          <span>
            <app-membership-change-valid-from-modal
              [membershipExtension]="membershipExtension"
              (membershipExtensionChange)= "updateValidFrom($event)">
            </app-membership-change-valid-from-modal>
          </span>
        </td>
        <td>{{membershipExtension.validUntil | dateFormat: "DD.MM.YYYY"}}</td>
        <td>{{membershipExtension.price | currencyFormat}}</td>
      </tr>
    </tbody>
  </table>
  <div *ngIf="isValidFromInThePast" class="alert alert-danger">{{ 'MembershipExtension.ValidFromDateNotAllowedToBeInThePast' | transloco }}</div>

  <app-discount-form [personMembershipId]="this.membershipExtension.id" [invoiceAmount]="this.membershipExtension.invoiceAmount"></app-discount-form>

<div class="alert alert-light">
    <div class="row">
      <div class="col-md-12">
        <div class="float-start">
          <button class="btn btn-default" (click)="back()">
            <span class="fa fa-chevron-left"></span> {{'Back' | transloco}}
          </button>
        </div>
        <div class="float-end">
          <app-membership-cancel-modal class="me-2"></app-membership-cancel-modal>
          <button
            class="btn btn-primary"
            (click)="processPayment()"
            [disabled]="submitted || isValidFromInThePast">
            {{'Pay' |  transloco}}
          </button>
        </div>
      </div>
    </div>
  </div>

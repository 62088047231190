import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MembershipState } from '../../membership-state.enum';
import { MembershipExtension, MembershipExtensionTypes } from '../../models';
import { MembershipService } from '../../membership.service';
import * as moment from 'moment';
import { Prices } from '../../../events/prices.enum';
import { BaseComponent } from '@premotec/ngx-essentials';
import { DiscountFormComponent } from 'src/app/shared/discount-form/discount-form.component';
import { TranslocoService } from '@ngneat/transloco';
import { DiscountGiftCardPut } from 'src/app/shared/models/discount-giftcard/discount-giftcard-put.model';

@Component({
  selector: 'app-membership-temporary',
  templateUrl: './membership-temporary.component.html',
  styleUrls: ['./membership-temporary.component.scss']
})
export class MembershipTemporaryComponent extends BaseComponent implements OnInit {

  @ViewChild(DiscountFormComponent) discountForm!: DiscountFormComponent;

  @Output() nextState: EventEmitter<number> = new EventEmitter();
  @Input() membershipExtension!: MembershipExtension;
  @Input() membershipExtensions!: MembershipExtensionTypes;

  submitted = false;

  noticeDateIsInThePast!: string;

  isValidFromInThePast = false;
  hasUpdateError = false;

  constructor(
    private router: Router,
    private membershipService: MembershipService,
    private translocoService: TranslocoService
    ) {
    super();
  }

  ngOnInit() {
    this.updateIsValidFromInThePast();
    this.noticeDateIsInThePast = this.translocoService.translate('MessageValidFromIsInPastNotice');
  }

  updateIsValidFromInThePast() {
    const now = moment();
    this.isValidFromInThePast = now.isAfter(this.membershipExtension.validFrom, 'day');
  }

  back() {
    this.nextState.emit(MembershipState.New);
  }

  updateValidFrom(updatedMembershipExtension: MembershipExtension) {
    Object.assign(this.membershipExtension, updatedMembershipExtension);
    this.updateIsValidFromInThePast();
  }

  processPayment() {
    this.submitted = true;

    const discountGiftCardPutModel = new DiscountGiftCardPut();

    discountGiftCardPutModel.discount = this.discountForm.discount;
    discountGiftCardPutModel.giftCard = this.discountForm.giftCard;

    this.membershipService.startPayment(this.membershipExtension.personMembershipId, discountGiftCardPutModel).subscribe((result: MembershipExtension) => {
      console.log(result);
      if (result.status === MembershipState.Completed) {
        this.router.navigateByUrl('/memberships');
      } else {
        this.router.navigateByUrl('/invoice/' + result.invoiceId + '/payment');
      }

      this.submitted = false;
    });
  }
}

import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { GridApi, ICellRendererParams } from 'ag-grid-community';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-ag-grid-detail-button',
  templateUrl: './ag-grid-detail-button.component.html',
  styleUrls: []
})
export class AgGridDetailButtonComponent implements ICellRendererAngularComp {

  bsModalRef?: BsModalRef;
  params!: ICellRendererParams;
  value!: string;
  gridApi!: GridApi;
  gridData: any;

  constructor(private router: Router, private route: ActivatedRoute) { }

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.value = params.value;
    this.gridApi = params.api;

  }

  openDetails() {
    this.router.navigate([`/office/lottery/${this.params.data.id}`]);
  }

  refresh() {
    return false;
  }
}

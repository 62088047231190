import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UserAuthorizationService } from 'src/app/security/user-authorization.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-membership-products-info-modal',
  templateUrl: './membership-products-info-modal.component.html',
  styleUrls: ['./membership-products-info-modal.component.scss']
})
export class MembershipProductsInfoModalComponent implements OnInit {

  constructor(
    private bsModalRef: BsModalRef,
    private authenticationService: UserAuthorizationService) { }

  ngOnInit(): void {
  }

  cancel() {
    this.bsModalRef.hide();
  }

  logout(): void {
    // set the redirect_url to get back here after login
    this.authenticationService.logout().then(() =>
      localStorage.setItem('redirect_url', `${environment.baseHref}/memberships`)
    );
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RefundListComponent } from './components/refund-list/refund-list.component';
import { RefundService } from './services/refund.service';
import { DisplayRefundStatus } from './pipes/display-refund-status.pipe';
import { RefundDetailModalComponent } from './components/refund-detail-modal/refund-detail-modal.component';
import { RefundTableComponent } from './components/refund-table/refund-table.component';
import { DisplayRefundRequestStatus } from './pipes/display-refund-request-status.pipe';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [
    RefundListComponent,
    DisplayRefundStatus,
    DisplayRefundRequestStatus,
    RefundDetailModalComponent,
    RefundTableComponent
  ],
  providers: [RefundService],
  exports: [RefundDetailModalComponent]
})
export class RefundsModule { }

<div class="d-flex gap-1">
    <div class="text-center flex-grow-1" *ngIf="preferenceStatus != null">
        <span *ngIf="preferenceStatus == TournamentGamePreferenceStatusEnum.Manual">
            <i class="fa-solid fa-shuffle text-secondary"></i>
        </span>
        <span *ngIf="preferenceStatus == TournamentGamePreferenceStatusEnum.Preference">
            <i class="fa-solid fa-thumbs-up text-success"></i>
        </span>
        <span *ngIf="preferenceStatus == TournamentGamePreferenceStatusEnum.Replenish">
            <i class="fa-solid fa-thumbs-down text-warning"></i>
        </span>
    </div>
    <div class="text-center flex-grow-1">
        <span *ngIf="!isSuccessivelyGame">
            <i class="fa-solid fa-link-slash text-success"></i>
        </span>
        <span *ngIf="isSuccessivelyGame">
            <i class="fa-solid fa-link text-warning"></i>
        </span>
    </div>
</div>
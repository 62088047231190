import { Component, OnInit, Input } from '@angular/core';
import { FreeRoom } from '../../models/free-room.model';

@Component({
  selector: 'app-information-screen-free-rooms',
  templateUrl: './free-rooms.component.html',
  styleUrls: ['./free-rooms.component.scss']
})
export class FreeRoomsComponent implements OnInit {

  @Input() freeRooms!: FreeRoom[];

  constructor() { }

  ngOnInit() {
  }

}

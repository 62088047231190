import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Routes, RouterModule} from '@angular/router';
import {AccountChangePasswordComponent} from './account-change-password/account-change-password.component';
import {AccountChangeDataComponent} from './account-change-data/account-change-data.component';

const routes: Routes = [
  {path: 'user-data', component: AccountChangeDataComponent},
  {path: 'change-password', component: AccountChangePasswordComponent}
]

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
  ],
  exports: [RouterModule],
})
export class AccountRoutingModule {}
